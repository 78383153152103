import React, { ReactElement, useState } from "react";
import { Form, Formik, FormikProps } from "formik";
import { StyleSheet, View } from "react-native";
import { colorPallete } from "@socion-cordio/common/src/assets/styles/colors";
import {
  Text,
  TextSize,
  FontWeight,
  FontFamily
} from "@socion-cordio/common/src/components/atoms/text";
import Button, { ButtonType } from "@socion-cordio/common/src/components/atoms/button";
import { useLocation, useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import AesUtil from "@socion-cordio/common/src/utils/encryptionHelper";
import { HTTP_STATUS_CODES } from "@socion-cordio/common/src/network/constants";
import WrapperForm from "@socion-cordio/common/src/components/organisms/signup/signupNew/wrapperForm";
import { useTranslation } from "react-i18next";
import { ForgotPasswordRepository } from "@socion-cordio/common/src/repositories/forgot-password/forgotPassword";
import Icon, { IconNames } from "@socion-cordio/common/src/components/atoms/icon";
import UserInput from "@socion-cordio/common/src/components/molecules/userInput";
import * as Yup from "yup";
import Moment from "moment";
import AddTelemetryService from "@socion-cordio/common/src/services/telemetryService";
import {
  setNewBearerToken,
  setNewUser,
  setSuperAdmin
} from "@socion-cordio/common/src/network/authHelper";
import { LoginActions } from "@socion-cordio/common/src/modules/login/actions/actions";
import { useDispatch } from "react-redux";
import { allRoutesNames as routeNames } from "@socion-cordio/web/src/navigation/allRouteNames";
import { LocalStorage } from "@socion-cordio/common/src/services/storage/storageService";

interface Props {
  fetchSignupOtp?: Function;
  history?: any;
}

export default function SetForgotPasswordNewOtp(props: Props): ReactElement {
  const history: any = useHistory();
  const location: any = useLocation();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [showPassword, setShowPassword] = useState({ create: false, reEnter: false });
  const [isLoading, setIsLoading] = useState(false);

  const formValidaionSchema = Yup.object({
    createPassword: Yup.string().required("Please enter new password.").nullable(),
    reEnterPassword: Yup.string()
      .required("Please confirm your new password")
      .nullable()
      .oneOf([Yup.ref("createPassword"), null], "Passwords entered don't match")
  });

  const resetPasswordHandler = async (values: {
    createPassword: string;
    reEnterPassword: string;
  }) => {
    setIsLoading(true);
    const aesUtil: AesUtil = new AesUtil();
    let payload = {
      verificationType: location?.state?.verificationType,
      userName: location?.state?.userName,
      countryCode: location?.state?.countryCode,
      password: aesUtil.encrypt(values.reEnterPassword)
    };
    let response = ForgotPasswordRepository.updateForgotPassword(payload);
    response
      .then((res) => {
        if (res.responseCode === HTTP_STATUS_CODES.ok) {
          toast.success("Password changed successfully.");
          history.push("/new/iam/login");
          updateTelemetryEvent("Forgot Password");
          // loginHandler(res.response); // reverted change (after forgot password success, login page)
          setIsLoading(false);
        } else {
          toast.error("Something went wrong");
          setIsLoading(false);
        }
      })
      .catch((error) => {
        toast.error("Something went wrong");
        setIsLoading(false);
      });
  };

  const updateTelemetryEvent = async (eventType: string) => {
    const body: any = {
      timestamp: Moment().toLocaleString(),
      createdAt: Moment().toLocaleString(),
      deleted: "false",
      updatedAt: Moment().toLocaleString(),
      eventType: eventType
    };
    AddTelemetryService(body, undefined, undefined, false);
  };

  const handleShowPassword = (key: string) => {
    if (key == "create") setShowPassword({ ...showPassword, create: !showPassword.create });
    else setShowPassword({ ...showPassword, reEnter: !showPassword.reEnter });
  };

  const loginHandler = (loginData: any) => {
    dispatch(LoginActions.postCredentials(loginData?.accessTokenResponseDTO?.accessToken));
    setNewBearerToken(loginData?.accessTokenResponseDTO?.accessToken);
    setNewUser(loginData?.userDetails);
    getPayload(loginData?.accessTokenResponseDTO?.accessToken);
    LocalStorage.setStorage("countryCodeObj", location?.state?.selectedCountryObject);
    LocalStorage.setStorage(
      "countryCode",
      +location?.state?.selectedCountryObject.code.replace("+", "")
    );
    LocalStorage.setStorage("IsEmailVerification", location?.state?.isEmailVerification);
    updateTelemetry(loginData?.userDetails);
    history.push(`${routeNames.app}${routeNames.workspace}`);
  };

  const updateTelemetry = async (userDetails: any) => {
    const body = {
      timestamp: Moment().toLocaleString(),
      createdAt: Moment().toLocaleString(),
      deleted: "false",
      updatedAt: Moment().toLocaleString(),
      eventType: "Login"
    };
    AddTelemetryService(body, undefined, userDetails);
  };

  function getPayload(token: string) {
    //var payload = window.atob(token.split('.')[1]);
    let base64Url = token.split(".")[1];
    let base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    let payload = JSON.parse(
      decodeURIComponent(
        atob(base64)
          .split("")
          .map(function (c) {
            return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
          })
          .join("")
      )
    );
    if (payload?.realm_access) {
      const { roles } = payload.realm_access;
      const isAdmin = roles.includes("admin");
      setSuperAdmin(isAdmin);
    }
  }

  return (
    <View>
      <WrapperForm
        footerText={t("common:alreadyHaveAnAccount")}
        footerTextType={t("common:loginText")}
        onPressFooterTextType={() => history.push("/new/iam/login")}
        component={
          <View style={styles.container}>
            <View style={styles.subContainer}>
              <Text
                fontWeight={FontWeight.Regular}
                testId="program"
                textSize={TextSize.Regular}
                textStyle={styles.subHeading}
              >
                {t("common:pdaProgram")}
              </Text>
              <Text
                fontWeight={FontWeight.Bold}
                testId="signup"
                textSize={TextSize.Large}
                textStyle={styles.heading}
              >
                {t("common:forgotPassword")}
              </Text>
              <View>
                <Formik
                  initialValues={{
                    createPassword: null,
                    reEnterPassword: null
                  }}
                  onSubmit={(values, { setSubmitting }) => {
                    resetPasswordHandler(values);
                    setSubmitting(false);
                  }}
                  validationSchema={formValidaionSchema}
                  enableReinitialize={true}
                >
                  {(
                    formikProps: FormikProps<{
                      createPassword: string;
                      reEnterPassword: string;
                    }>
                  ) => (
                    <Form>
                      <View style={styles.actionContainer}>
                        <View style={styles.flexContainer}>
                          <UserInput
                            isSecure={showPassword.create ? false : true}
                            label=""
                            handleChange={formikProps.handleChange("createPassword")}
                            handleBlur={() => formikProps.setFieldTouched}
                            userStyle={styles.userInputStyle}
                            textIcon={styles.userInputStyleHeader}
                            icon={IconNames.password}
                            textValue="Create password"
                            name="createPassword"
                            placeholder="Create Password"
                            value={formikProps.values.createPassword}
                            id="createpassword"
                            handleKeyPress={(e: any) => {
                              if (e.key === "Enter") {
                                formikProps.handleSubmit();
                              }
                            }}
                            formikPropsTouched={
                              formikProps.touched.createPassword === undefined
                                ? false
                                : formikProps.touched.createPassword
                            }
                            customChange={() =>
                              formikProps.setFieldTouched("createPassword", false)
                            }
                          />
                          <View
                            style={
                              formikProps?.errors?.hasOwnProperty("createPassword")
                                ? styles.eyeIcon1
                                : styles.eyeIcon
                            }
                          >
                            <Icon
                              testID="close"
                              name={IconNames.passwordEye}
                              onPress={() => handleShowPassword("create")}
                            />
                          </View>
                        </View>
                        <View style={styles.flexContainer}>
                          <UserInput
                            isSecure={showPassword.reEnter ? false : true}
                            label=""
                            handleChange={formikProps.handleChange("reEnterPassword")}
                            handleBlur={() => formikProps.setFieldTouched}
                            userStyle={styles.userInputStyle}
                            textIcon={styles.userInputStyleHeader}
                            icon={IconNames.password}
                            textValue="Re-enter password"
                            name="reEnterPassword"
                            placeholder="Re-enter password"
                            value={formikProps.values.reEnterPassword}
                            id="reEnterPassword"
                            handleKeyPress={(e: any) => {
                              if (e.key === "Enter") {
                                formikProps.handleSubmit();
                              }
                            }}
                            formikPropsTouched={
                              formikProps.touched.reEnterPassword === undefined
                                ? false
                                : formikProps.touched.reEnterPassword
                            }
                            customChange={() =>
                              formikProps.setFieldTouched("reEnterPassword", false)
                            }
                          />
                          <View
                            style={
                              formikProps?.errors?.hasOwnProperty("reEnterPassword")
                                ? styles.eyeIcon1
                                : styles.eyeIcon
                            }
                          >
                            <Icon
                              testID="close"
                              name={IconNames.passwordEye}
                              onPress={() => handleShowPassword("reEnter")}
                            />
                          </View>
                        </View>
                        <Button
                          type={ButtonType.Primary}
                          buttonStyles={styles.button}
                          title="Change Password"
                          onPress={() => {
                            formikProps.handleSubmit();
                          }}
                          disabled={isLoading}
                        />
                      </View>
                    </Form>
                  )}
                </Formik>
              </View>
            </View>
          </View>
        }
      />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    alignItems: "center",
    backgroundColor: colorPallete.white,
    width: "100%",
    borderRadius: 20,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0
  },
  subContainer: {
    alignItems: "center",
    marginVertical: 20,
    marginTop: 30
  },
  heading: {
    fontSize: 26,
    marginTop: 10,
    marginBottom: 80
  },
  subHeading: {
    fontSize: 19
  },
  headerText: {
    fontSize: 14,
    fontWeight: "400",
    textAlign: "center"
  },
  otpContainer: {
    marginTop: 60,
    marginBottom: 80
  },
  resendText: {
    fontSize: 14,
    fontWeight: "500"
  },
  resendOtpText: {
    fontSize: 14,
    fontWeight: "500",
    textDecorationLine: "underline",
    color: colorPallete.cordioRed
  },
  footerContainer: {
    alignItems: "center"
  },
  button: {
    height: 40,
    width: 304,
    marginTop: 60,
    marginBottom: 20
  },
  resendOtpContainer: {
    flexDirection: "row",
    marginTop: 10
  },
  resendTextAlign: {
    marginLeft: 5
  },
  actionContainer: {
    // marginTop: 50,
    // marginLeft: 80
  },
  flexContainer: {
    display: "flex",
    flexDirection: "row"
  },
  eyeIcon: {
    marginLeft: "2%",
    alignItems: "center",
    flexDirection: "row",
    marginTop: 15
  },
  eyeIcon1: {
    marginLeft: "2%",
    alignItems: "center",
    flexDirection: "row"
  },
  userInputStyle: {
    marginBottom: 20
  },
  userInputStyleHeader: {
    fontFamily: FontFamily.Bold,
    color: colorPallete.textBlack,
    marginLeft: 5
  }
});
