import React, { ReactElement, useState, useEffect } from "react";
import { StyleSheet, View } from "react-native";
import { colorPallete } from "@socion-cordio/common/src/assets/styles/colors";
import {
  Text,
  TextSize,
  FontWeight,
  FontFamily
} from "@socion-cordio/common/src/components/atoms/text";
import AesUtil from "@socion-cordio/common/src/utils/encryptionHelper";
import { ApiClient } from "@socion-cordio/common/src/network/apiClient";
import { programEndPoints } from "@socion-cordio/common/src/repositories/endPoints";
import Icon, { IconNames } from "@socion-cordio/common/src/components/atoms/icon";
import Button, { ButtonType } from "@socion-cordio/common/src/components/atoms/button";
import { auxiliaryMethods } from "@socion-cordio/common/src/utils/auxiliaryMethods";
import { Image } from "@socion-cordio/common/src/components/atoms/image";
import { LocalStorage } from "@socion-cordio/common/src/services/storage/storageService";
import { QR } from "@socion-cordio/common/src/components/atoms/qrCode";
import { toast } from "react-toastify";
import Moment from "moment";
import AddTelemetryService from "@socion-cordio/common/src/services/telemetryService";

interface Props {
  onClose: Function;
  userDetails?: any;
  qrCodeData?: string;
  getRolesDetails?: Function;
  userId?: string;
  getProgramMembers: Function;
  role: string;
  memberStatus: string;
  setModalLoading?: Function;
  getUserRoles?: Function;
}

export default function ActivateRemoveMember(props: Props): ReactElement {
  let aesUtil: AesUtil = new AesUtil();
  const [programData, setProgramData] = useState(undefined);

  useEffect(() => {
    getProgramDetails();
  }, []);

  const getProgramDetails = async () => {
    const programDetails: any = await LocalStorage.getStorage("programDetails");
    setProgramData(programDetails);
  };

  const updateTelemetryProgramMemberStatus = async (data: any) => {
    const deleted = data;
    let eventType = "";
    let date = null;
    // true "Successfully reactivated program"
    // false "Successfully deactivated program"
    const user: any = await LocalStorage.getStorage("user");
    const programDetails: any = await LocalStorage.getStorage("programDetails");
    deleted === true
      ? [(eventType = "Deactivate Program Member")]
      : [(eventType = "Reactivate Program Member")];

    const body = {
      timestamp: Moment().toLocaleString(),
      createdAt: Moment().toLocaleString(),
      deleted: "false",
      updatedAt: Moment().toLocaleString(),
      eventType: eventType,
      sessionStartDate: new Date(programDetails.program.startDate)
        .toISOString()
        .replace("T", " ")
        .replace("Z", ""),
      sessionEndDate: new Date(programDetails.program.endDate)
        .toISOString()
        .replace("T", " ")
        .replace("Z", ""),
      memberId: user.userId,
      userId: props.userId,
      programRole: props.role,
      baseLocationCity: props?.userDetails?.city,
      baseLocationDistrict: props?.userDetails?.district,
      baseLocationSubDistrict: props?.userDetails?.subDistrict,
      baseLocationState: props?.userDetails?.state,
      baseLocationCountry: props?.userDetails?.country,
      baseLocationLatitude: props?.userDetails?.latitude,
      baseLocationLongitude: props?.userDetails?.longitude,
      establishmentName: props?.userDetails?.establishmentName
    };
    AddTelemetryService(body, programDetails, user);
  };

  const handleDeactivateMember = async () => {
    const payload = {};
    try {
      const MemberStatusResponse = await ApiClient.put(
        programEndPoints.deactivateMember(programData.program.id, props.userId, props.role),
        payload
      );
      if (MemberStatusResponse) {
        toast.success("Selected member deactivated successfully");
      }
      props.onClose();
      props.getProgramMembers();
      props.getUserRoles();
      props.setModalLoading(false);
      toast.success(MemberStatusResponse.message);
      setTimeout(() => {
        window.location.reload();
      }, 1000);
      updateTelemetryProgramMemberStatus(true);
    } catch (error) {
      toast.error(error);
    }
  };

  const handleActivateMember = async () => {
    const payload = {};
    try {
      const MemberStatusResponse = await ApiClient.put(
        programEndPoints.activateMember(programData.program.id, props.userId, props.role),
        payload
      );
      if (MemberStatusResponse) {
        toast.success("Selected member reactivated successfully");
      }
      props.onClose();
      props.getProgramMembers();
      props.getUserRoles();
      props.setModalLoading(false);
      toast.success(MemberStatusResponse.message);
      setTimeout(() => {
        window.location.reload();
      }, 1000);
      updateTelemetryProgramMemberStatus(false);
    } catch (error) {
      toast.error(error);
    }
  };

  return (
    <>
      {props.userDetails && (
        <>
          <View style={styles.header}>
            <Icon
              testID="close"
              name={IconNames.crossCircle}
              customStyle={styles.crossIcon}
              onPress={() => props.onClose()}
            />
          </View>
          <View style={styles.subContainer}>
            <View style={styles.profilePictureContainer}>
              <View style={styles.profilePicture}>
                <Image
                  imageStyle={styles.profilePicture}
                  testId="socionImg"
                  source={
                    props.userDetails?.photo === ""
                      ? require("@socion-cordio/common/src/assets/images/user_circle.svg")
                      : props.userDetails?.photo
                  }
                />
              </View>
            </View>
            <View style={styles.memberInfoContainer}>
              <Text
                fontWeight={FontWeight.Bold}
                testId="addRoleText"
                textSize={TextSize.Small}
                textStyle={styles.headerText}
              >
                {props.userDetails.name}
              </Text>
              <View style={styles.userDetailsContainer}>
                <Text
                  fontWeight={FontWeight.Regular}
                  testId="phoneNumberLabel"
                  textSize={TextSize.Small}
                  textStyle={[styles.alignText]}
                >
                  {"Contact Number : "}
                </Text>
                <Text
                  fontWeight={FontWeight.Regular}
                  testId="phoneNumber"
                  textSize={TextSize.Small}
                  textStyle={[styles.alignText]}
                >
                  {props.userDetails.piiInfo
                    ? `${props.userDetails.countryCode} ${props.userDetails.phoneNumber}`
                    : `NA`}
                </Text>
              </View>
              <View style={styles.userDetailsContainer}>
                <Text
                  fontWeight={FontWeight.Regular}
                  testId="addRoleText"
                  textSize={TextSize.Small}
                  textStyle={[styles.alignText]}
                >
                  {"Contact Email : "}
                </Text>
                <Text
                  fontWeight={FontWeight.Regular}
                  testId="addRoleText"
                  textSize={TextSize.Small}
                  textStyle={[styles.alignText]}
                >
                  {props.userDetails.piiInfo
                    ? props.userDetails.emailId !== ""
                      ? props.userDetails.emailId
                      : ""
                    : `NA`}
                </Text>
              </View>
              <Text
                fontWeight={FontWeight.Regular}
                testId="addRoleText"
                textSize={TextSize.Small}
                textStyle={[styles.alignText]}
              >
                Base location :
              </Text>
              <Text
                fontWeight={FontWeight.Regular}
                testId="addRoleText"
                textSize={TextSize.Small}
                textStyle={[styles.alignText, styles.textColor]}
              >
                {auxiliaryMethods.fromatBaseLocation(props.userDetails) === ""
                  ? "None"
                  : auxiliaryMethods.fromatBaseLocation(props.userDetails)}
              </Text>
            </View>
            <View style={styles.qrContainer}>
              <QR value={aesUtil.encrypt(props.userId)} />
            </View>
          </View>
          {props.memberStatus === "deactivate" && (
            <View style={styles.buttonContainer}>
              <Button
                type={ButtonType.Primary}
                buttonStyles={styles.button}
                textStyles={styles.buttonFont}
                title="Deactivate member"
                onPress={() => handleDeactivateMember()}
              />
            </View>
          )}
          {props.memberStatus === "activate" && (
            <View style={styles.buttonContainer}>
              <Button
                type={ButtonType.Primary}
                buttonStyles={styles.button}
                textStyles={styles.buttonFont}
                title="Reactivate member"
                onPress={() => handleActivateMember()}
              />
            </View>
          )}
        </>
      )}
    </>
  );
}

const styles = StyleSheet.create({
  header: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row"
  },
  headerText: {
    fontSize: 14,
    fontFamily: FontFamily.Medium,
    lineHeight: 17
  },
  memberInfoContainer: {
    flex: 80
  },
  subContainer: {
    flexDirection: "row",
    marginBottom: 25,
    width: "680px"
  },
  profilePictureContainer: {
    flex: 20,
    alignItems: "center"
  },
  profilePicture: {
    borderRadius: 54,
    width: " 80px",
    height: "80px"
  },
  alignText: {
    fontSize: 14,
    color: colorPallete.textLight,
    marginTop: 10,
    FontFamily: FontFamily.Medium,
    fontWeight: "400"
  },
  textColor: {
    color: colorPallete.textBlack,
    fontFamily: FontFamily.Regular,
    fontWeight: "700"
  },
  button: {
    width: "167px",
    height: "50px",
    borderRadius: 10
  },
  buttonFont: {
    fontSize: 14
  },
  buttonContainer: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    marginTop: 15
  },
  crossIcon: {
    fontSize: 15,
    marginLeft: 700,
    marginBottom: 49
  },
  qrContainer: {
    marginBottom: 20,
    marginRight: 20
  },
  userDetailsContainer: {
    flexDirection: "row"
  }
});
