import React, { ReactElement, useEffect, useState } from "react";
import { ScrollView, StyleSheet, TouchableOpacity, useWindowDimensions, View } from "react-native";
import { colorPallete } from "@socion-cordio/common/src/assets/styles/colors";
import {
  Text,
  TextSize,
  FontWeight,
  FontFamily
} from "@socion-cordio/common/src/components/atoms/text";
import Icon, { IconNames } from "@socion-cordio/common/src/components/atoms/icon";
import Button, { ButtonType } from "@socion-cordio/common/src/components/atoms/button";
import ButtonTab, {
  ButtonTypeSecondary
} from "@socion-cordio/common/src/components/atoms/buttonTab";
import UserInputSecondary from "@socion-cordio/common/src/components/molecules/userInputSecondary";
import UserInput from "@socion-cordio/common/src/components/molecules/userInput";
import CreateSessionSidePanelPage from "@socion-cordio/common/src/components/organisms/session/createSessionSidePanelPage";
import { useLocation, useHistory } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { ApiClient } from "@socion-cordio/common/src/network/apiClient";
import {
  programEndPoints,
  sessionPackEndPoints
} from "@socion-cordio/common/src/repositories/endPoints";
import { LocalStorage } from "@socion-cordio/common/src/services/storage/storageService";
import { Image } from "@socion-cordio/common/src/components/atoms/image";
import AddViewRemoveMemberModal from "@socion-cordio/common/src/components/organisms/session/addViewRemoveMember";
import DateTimePicker from "@socion-cordio/common/src/components/molecules/dateTimePicker";
import RemoveMemberModal from "@socion-cordio/common/src/components/organisms/common-modals/removeMember";
import { HTTP_STATUS_CODES } from "@socion-cordio/common/src/network/constants";
import Moment from "moment";
import CancelSessionModal from "../common-modals/cancelSessionModal";
import { allRoutesNames as routeNames } from "@socion-cordio/web/src/navigation/allRouteNames";
import Loader from "@socion-cordio/common/src/components/atoms/loader";
import { useTranslation } from "react-i18next";
import AddTelemetryService from "@socion-cordio/common/src/services/telemetryService";
interface Props {
  onSubmit?: (data: { otp: number }) => void;
}

export default function CreateSessionPage(props: Props): ReactElement {
  const { t } = useTranslation();
  const { width } = useWindowDimensions();
  const [sessionDetail, setSessionDetail] = useState(null);
  const [currentUserDetails, setCurrentUserDetails] = useState(null);
  const [userTrainerList, setUserTrainerList] = useState(null);
  const [trainers, setTrainers] = useState([]);
  const [name, setName] = useState(null);
  const [displayUserListTrainers, setdisplayUserListTrainers] = useState(false);
  const [selectedUserList, setSelectedUserList] = useState([]);
  const [selectedUserData, setSelectedUserData] = useState(null);
  const [editSelectedUser, setEditSelectedUser] = useState(false);
  const [cancelCreateSession, setCancelCreateSession] = useState(false);
  const [isEditButtonClicked, setIsEditButtonClicked] = useState(false);
  const [sessionStartDate, setSessionStartDate] = useState(null);
  const [sessionEndDate, setSessionEndDate] = useState(null);
  const [sessionVenue, setSessionVenue] = useState(null);
  const [isRemoveMember, setIsRemoveMember] = useState(false);
  const [loadingRoles, setLoadingRoles] = useState(true);
  const [currentDateTime, setCurrentDateTime] = useState(null);
  const [isStartDateTimeCheck, setIsStartDateTimeCheck] = useState(false);
  const [isEndDateTimeCheck, setIsEndDateTimeCheck] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isTrainer, setIsTrainer] = useState(false);
  // const [userMemberRole,setUserMemberRole]=useState(null)
  const location: any = useLocation();
  const history: any = useHistory();

  useEffect(() => {
    setSessionDetail(location?.state?.sessionData);
    getUserList(location?.state?.sessionData?.programId);
    getUserDetails();
    setCurrentDateTime(Moment().format("YYYY-MM-DDTHH:mm"));
  }, []);

  const startSessionDateCheck = (startDate: any) => {
    const sessionStartDate = Moment(startDate).format("YYYY-MM-DDTHH:mm");
    const sessionEndDateCalcualte = Moment(sessionEndDate).format("YYYY-MM-DDTHH:mm");
    if (currentDateTime > sessionStartDate) {
      toast.error("Start time should be greater than current time!");
      setIsStartDateTimeCheck(true);
    } else {
      setIsStartDateTimeCheck(false);
    }
    if (sessionStartDate > sessionEndDateCalcualte) {
      toast.error(
        "Start date and time should be earlier than end date and time & later than current date and time!!"
      );
      setIsStartDateTimeCheck(true);
    } else {
      setIsStartDateTimeCheck(false);
    }
  };

  const sessionEndDateCheck = (date: any) => {
    const sessionStartDateCalculate = Moment(sessionStartDate).format("YYYY-MM-DDTHH:mm");
    const sessionEndDate = Moment(date).format("YYYY-MM-DDTHH:mm");
    if (sessionStartDateCalculate >= sessionEndDate && sessionEndDate <= currentDateTime) {
      toast.error("End date/time should be later than the start and current date/time!");
      setIsEndDateTimeCheck(true);
    } else if (sessionStartDateCalculate >= sessionEndDate) {
      toast.error("End date and time should be greater than start date and time!");
      setIsEndDateTimeCheck(true);
    } else {
      setIsEndDateTimeCheck(false);
    }
  };

  const getUserList = async (id: number) => {
    try {
      const response = await ApiClient.get(programEndPoints.getUserList(id), {});
      const trainers = response?.users.filter((member: any) => member.roles.includes("TRAINER"));
      setTrainers(trainers);
      setLoadingRoles(false);
    } catch (error) {
      setLoadingRoles(false);
      toast.error("Something went wrong.");
    }
  };

  const getUserDetails = async () => {
    const user: any = await LocalStorage.getStorage("user");
    setCurrentUserDetails(user);
  };

  const searchUserHandler = (value: any) => {
    setName(value);
    value?.length !== 0 ? setdisplayUserListTrainers(true) : setdisplayUserListTrainers(false);
    const user: any = trainers.filter(
      (u) => u.name.toLowerCase().includes(value.toLowerCase()) || u.phoneNumber.includes(value)
    );
    setUserTrainerList(user);
  };

  const addUserHandler = (data: any) => {
    setName(""); // clear search field
    setIsEditButtonClicked(false);
    setdisplayUserListTrainers(false);
    setSelectedUserData(data); // selected user data object
    const isAlreadyExistsCheck = selectedUserList.find((x) => x.userId === data.userId);
    if (isAlreadyExistsCheck) {
      toast.error("This user is already registered in the system.!");
    } else {
      setEditSelectedUser(true); //modal open and passing the selected data
    }
  };

  const setUserMemberRole = (roles: any) => {
    const newSelectedUserBody = {
      ...selectedUserData,
      roles
    };
    if (isEditButtonClicked === false) {
      const list = selectedUserList.concat(newSelectedUserBody);
      setSelectedUserList(list);
    }
    if (isEditButtonClicked === true) {
      const user = [...selectedUserList];
      let index = user.findIndex((obj) => obj.userId === newSelectedUserBody.userId);
      user[index] = newSelectedUserBody;
      setSelectedUserList(user);
    }
    setIsEditButtonClicked(false);
    setEditSelectedUser(false); //modal close
  };

  const formatRoles = (data: any) => {
    let formatString = "";
    let otherText = "";
    Object.fromEntries(
      Object.entries(data).filter(([key, value]) => {
        if (key === "trainer") {
          formatString += "Trainer ";
        }
        if (key === "admin") {
          formatString += "Admin ";
        }
        if (key === "otherRoleNames") {
          otherText = `${value}`;
        }
      })
    );
    let newString = formatString.split(" ").join(", ").replace(/,\s*$/, "");
    let finalString = newString !== "" ? `${newString},${otherText}` : otherText;
    return otherText == "" ? newString : finalString;
  };

  const editSelectedUserHandler = (data: any) => {
    setSelectedUserData(data);
    setEditSelectedUser(true); //modal open
    setIsEditButtonClicked(true);
  };

  const deleteSelectedUserHandler = (data: any) => {
    setIsRemoveMember(true);
    setSelectedUserData(data);
  };

  const deleteMemberResponse = (val: any) => {
    try {
      if (val === true) {
        const user = [...selectedUserList];
        const removeUser = user.filter((x) => x.userId !== selectedUserData.userId);
        setSelectedUserList(removeUser);
        setIsRemoveMember(false);
      }
    } catch (error) {
      setIsRemoveMember(false);
      toast.error("Something went wrong.");
    }
  };

  const getTimeZone = () => {
    let newData = new Date().toTimeString().split("(");
    let tempArray = newData[newData.length - 1].split(" ");
    let arr: any = [];
    tempArray.map((i) => {
      i.split("");
      arr.push(i[0]);
    });
    return `${newData[0].slice(9)}(${arr.join("")})`;
  };

  const submitSessionHandler = async () => {
    let timeZone = getTimeZone();
    try {
      const memberList = selectedUserList.map(({ roles, userId }) => ({ roles, userId }));
      const rolesList = selectedUserList.map(({ roles }) => ({ roles }));
      const isTrainerPresent = rolesList.find((x) => x.roles.hasOwnProperty("trainer"));
      const currentTimeWhileSubmitting = Moment().format("YYYY-MM-DDTHH:mm");
      const startTime = Moment(sessionStartDate).format("YYYY-MM-DDTHH:mm");
      if (currentTimeWhileSubmitting > startTime) {
        toast.error("Start time should be greater than current time!");
      } else {
        if (isTrainerPresent) {
          setLoading(true);
          const response = await ApiClient.post(sessionPackEndPoints.updateSessionDetails(), {
            address: sessionVenue,
            members: memberList,
            sessionDescription: location?.state?.sessionData?.description,
            // sessionEndDate: `${Moment(sessionEndDate).format("YYYY-MM-DD HH:mm:ss.sss")}`,
            sessionEndDate: new Date(sessionEndDate)
              .toISOString()
              .replace("T", " ")
              .replace("Z", ""),
            sessionName: location?.state?.sessionData?.name,
            // sessionStartDate: `${Moment(sessionStartDate).format("YYYY-MM-DD HH:mm:ss.sss")}`,
            sessionStartDate: new Date(sessionStartDate)
              .toISOString()
              .replace("T", " ")
              .replace("Z", ""),
            sessionTimeZone: timeZone,
            topicId: location?.state?.sessionData?.id
          });
          if (response.responseCode === HTTP_STATUS_CODES.ok) {
            setLoading(false);
            updateTelemetry(response);
            toast.success("Session created Successfully!!!");
            history.push(`${routeNames.app}${routeNames.SESSION}`);
          } else {
            toast.error("Something went wrong.");
          }
        } else {
          toast.error("At least one trainer should be added.");
        }
      }
    } catch (error) {
      toast.error("Something went wrong.");
    }
  };

  const updateTelemetry = async (data: any) => {
    const programData: any = await LocalStorage.getStorage("programDetails");
    const user: any = await LocalStorage.getStorage("user");
    // const programDetails: {} = { name: programData.program.name, id: programData.program.id };
    const body = {
      timestamp: Moment().toLocaleString(),
      createdAt: Moment().toLocaleString(),
      deleted: "false",
      updatedAt: Moment().toLocaleString(),
      eventType: "Create session",
      sessionStartDate: new Date(sessionStartDate).toISOString().replace("T", " ").replace("Z", ""),
      sessionEndDate: new Date(sessionEndDate).toISOString().replace("T", " ").replace("Z", ""),
      sessionId: data.response.sessionId,
      sessionName: location?.state?.sessionData?.name,
      venue: sessionVenue,
      topicId: sessionDetail.id,
      topicName: sessionDetail.name,
      programId: programData?.program?.id,
      programName: programData?.program?.name,
      entityId: "null",
      entityname: "null"
    };
    AddTelemetryService(body, undefined, user, true);
  };

  const cancelCreateSessionHandler = () => {
    setCancelCreateSession(true);
  };

  const cancel = (value: any) => {
    if (value === true) {
      setCancelCreateSession(false);
      history.push(`${routeNames.app}${routeNames.SESSION}`);
    }
  };

  // useEffect(() => {
  //   let tempArray: boolean[] = [];
  //   selectedUserList.map((i) => {
  //     if (i.roles.hasOwnProperty("trainer")) tempArray.push(true);
  //     else tempArray.push(false);
  //   });
  //   if (tempArray.includes(true)) setIsTrainer(true);
  //   else setIsTrainer(false);
  // }, [selectedUserList]);

  return (
    <View>
      {loading ? (
        <View style={styles.loaderContainer}>
          <View style={styles.subLoaderContainer}>
            <Loader />
          </View>
        </View>
      ) : (
        <View style={styles.container}>
          <View style={styles.rightContainer}>
            <View style={styles.subContainer}>
              <View style={styles.subContainerOne}>
                <View style={styles.headerContainer}>
                  <View style={styles.iconContainerHeader}>
                    <Icon
                      testID="down"
                      name={IconNames.down}
                      customStyle={styles.titleIcon}
                      onPress={() => {
                        history.goBack();
                      }}
                    />
                  </View>
                  <View>
                    <Text
                      fontWeight={FontWeight.Light}
                      testId="text"
                      textSize={TextSize.Small}
                      textStyle={[styles.headerText, styles.headerTextTitleSupport]}
                    >
                      {t("session:createSession")}
                    </Text>
                  </View>
                  <View>
                    <Text
                      onPress={() => {}}
                      fontWeight={FontWeight.Regular}
                      testId="text"
                      textSize={TextSize.Small}
                      textStyle={[styles.headerText, { marginLeft: 10, marginRight: 0 }]}
                    >
                      {">"}
                    </Text>
                  </View>
                  <View>
                    <Text
                      onPress={() => {}}
                      fontWeight={FontWeight.Regular}
                      testId="text"
                      textSize={TextSize.Small}
                      textStyle={[styles.headerText, styles.headerTextSupport]}
                    >
                      {sessionDetail?.name}
                    </Text>
                  </View>
                </View>

                <View
                  style={
                    width > 768 ? styles.addMemberScheduleContainer : { flexDirection: "column" }
                  }
                >
                  <View style={styles.addMemberContainer}>
                    <View style={styles.addMemberSubContainer} pointerEvents="none">
                      <ButtonTab
                        type={ButtonTypeSecondary.Secondary}
                        iconName={IconNames.addMember}
                        buttonStyles={styles.addMemberButton}
                        textStyles={{ fontSize: 12 }}
                        title={"Add member"}
                        // disabled={}
                      />
                    </View>

                    <View style={styles.wrapperContainer}>
                      <View style={styles.inputContainer}>
                        {loadingRoles ? (
                          <View style={{ height: 40 }}>
                            {" "}
                            <Text
                              fontWeight={FontWeight.Light}
                              testId="text"
                              textSize={TextSize.Small}
                              textStyle={[styles.headerText, styles.headerTextTitleSupport]}
                            >
                              {t("session:loadingMemberRoles")}
                            </Text>
                          </View>
                        ) : (
                          <UserInput
                            handleChange={(value: any) => searchUserHandler(value)}
                            handleBlur={() => {}}
                            value={name}
                            placeholder={t("common:searchByNameOrMobile")}
                            name="countryInput"
                            id="countryInput"
                            userStyle={styles.inputStylingAlignment}
                            input={styles.inputStyle}
                            noFormik
                            editable={true}
                            showEditIcon={false}
                          />
                        )}
                        <View style={{ position: "absolute", top: 70, width: "100%", height: 100 }}>
                          <ScrollView>
                            {displayUserListTrainers &&
                              userTrainerList?.map((prop: any, key: any) => {
                                return (
                                  <View key={key} style={{ display: "flex", flexDirection: "row" }}>
                                    {/* <View style={{ flex: 50 }}></View> */}
                                    <TouchableOpacity
                                      onPress={() => addUserHandler(prop)}
                                      style={[
                                        styles.listDropdownContainer,
                                        { backgroundColor: "white" }
                                      ]}
                                    >
                                      <View key={key}>
                                        <Text
                                          fontWeight={FontWeight.Light}
                                          testId="text"
                                          textSize={TextSize.Small}
                                          textStyle={[
                                            styles.headerText,
                                            styles.headerTextTitleSupport,
                                            { marginBottom: 2 }
                                          ]}
                                        >
                                          {prop.name}
                                        </Text>
                                        <Text
                                          fontWeight={FontWeight.Light}
                                          testId="text"
                                          textSize={TextSize.Small}
                                          textStyle={[
                                            styles.headerText,
                                            styles.headerColor,
                                            { fontSize: 10 }
                                          ]}
                                        >
                                          {prop.countryCode}
                                          {"-"}
                                          {prop.phoneNumber}
                                        </Text>
                                      </View>
                                    </TouchableOpacity>
                                  </View>
                                );
                              })}
                          </ScrollView>
                        </View>
                      </View>
                      <View style={styles.memberContainer}>
                        <View style={styles.profilePictureContainer}>
                          <View>
                            <Image
                              testId="socion"
                              imageStyle={styles.profileImage}
                              source={
                                currentUserDetails?.photo ||
                                require("@socion-cordio/common/src/assets/images/user_circle.svg")
                              }
                            />
                          </View>
                        </View>
                        <View style={styles.nameContainer}>
                          <Text
                            fontWeight={FontWeight.Light}
                            testId="text"
                            textSize={TextSize.Small}
                            textStyle={[styles.headerText, styles.headerTextTitleSupport]}
                          >
                            {currentUserDetails?.name}
                          </Text>
                          {/* <Text
                        fontWeight={FontWeight.Light}
                        testId="text"
                        textSize={TextSize.Small}
                        textStyle={[styles.headerText, styles.headerTextTitleSupport]}
                      >
                        Swami
                      </Text> */}
                        </View>
                        <View style={styles.memberDesignationContainer}>
                          <Text
                            fontWeight={FontWeight.Light}
                            testId="text"
                            textSize={TextSize.Small}
                            textStyle={[styles.headerText, styles.headerColor, { fontSize: 10 }]}
                          >
                            {t("session:sessionCreator")}
                          </Text>
                        </View>
                      </View>
                      {selectedUserList?.map((prop: any, key: React.Key) => {
                        return (
                          <View key={key} style={styles.userListMainContainer}>
                            <View style={styles.miniIconContainer}>
                              <Icon
                                onPress={() => {
                                  deleteSelectedUserHandler(prop);
                                }}
                                testID="delete"
                                name={IconNames.deleteFile}
                                customStyle={[styles.alignContainerButton]}
                              />
                              <Icon
                                onPress={() => {
                                  editSelectedUserHandler(prop);
                                }}
                                testID="edit"
                                name={IconNames.edit}
                                customStyle={[styles.alignContainerButton]}
                              />
                            </View>
                            <View
                              style={{
                                flexDirection: "row",
                                flex: 1,
                                alignItems: "center",
                                marginBottom: 15
                              }}
                            >
                              <View style={styles.profilePictureContainer}>
                                <View>
                                  <Image
                                    testId="socion"
                                    imageStyle={styles.profileImage}
                                    source={
                                      prop?.photo ||
                                      require("@socion-cordio/common/src/assets/images/user_circle.svg")
                                    }
                                  />
                                </View>
                              </View>
                              <View style={styles.nameContainer}>
                                <Text
                                  fontWeight={FontWeight.Light}
                                  testId="text"
                                  textSize={TextSize.Small}
                                  textStyle={[styles.headerText, styles.headerTextTitleSupport]}
                                >
                                  {prop.name}
                                </Text>
                              </View>
                              <View style={styles.memberDesignationContainer}>
                                <Text
                                  fontWeight={FontWeight.Light}
                                  testId="text"
                                  textSize={TextSize.Small}
                                  textStyle={[
                                    styles.headerText,
                                    styles.headerColor,
                                    { fontSize: 10 }
                                  ]}
                                >
                                  {formatRoles(prop?.roles)}
                                </Text>
                              </View>
                            </View>
                          </View>
                        );
                      })}
                    </View>
                  </View>
                  <View>
                    {editSelectedUser && (
                      <AddViewRemoveMemberModal
                        modalVisible={editSelectedUser}
                        selectedUserData={selectedUserData}
                        setUserMemberRole={setUserMemberRole}
                        isEditButtonClicked={isEditButtonClicked}
                        setModalVisible={() => setEditSelectedUser(!editSelectedUser)}
                      />
                    )}
                  </View>

                  <View>
                    {isRemoveMember && (
                      <RemoveMemberModal
                        modalVisible={isRemoveMember}
                        selectedUserData={deleteMemberResponse}
                        textValue={t("session:removeMemberFromSession")}
                        setModalVisible={() => setIsRemoveMember(!isRemoveMember)}
                      />
                    )}
                  </View>
                  <View style={styles.dividerContainer}>{/* Divider */}</View>

                  <View style={styles.addScheduleContainer}>
                    <View style={styles.addMemberSubContainer} pointerEvents="none">
                      <ButtonTab
                        type={ButtonTypeSecondary.Primary}
                        iconName={IconNames.schedule}
                        buttonStyles={styles.addMemberButton}
                        textStyles={{ fontSize: 12 }}
                        title={t("common:schedule")}
                      />
                    </View>

                    <View>
                      <View style={[styles.wrapperContainer, { paddingLeft: 25 }]}>
                        <View
                          style={[styles.alignUserInputFields, { justifyContent: "space-between" }]}
                        >
                          <View style={styles.alignSubTextLabel}>
                            <Text
                              fontWeight={FontWeight.Regular}
                              testId="internal"
                              textSize={TextSize.Small}
                              textStyle={[styles.subheaderText]}
                            >
                              {t("common:start")}
                            </Text>
                          </View>

                          <View style={styles.dateTimePickerContainer}>
                            <DateTimePicker
                              min={currentDateTime}
                              max="2022-12-31"
                              handleBlur={() => {}}
                              onChange={(sessionStartDate: any) => {
                                setSessionStartDate(sessionStartDate.target.value);
                                startSessionDateCheck(sessionStartDate.target.value);
                              }}
                              // value={programEndDate}
                              name="sessionStartDate"
                              id="sessionStartDate"
                              noFormik
                            />
                          </View>
                        </View>
                        <View>
                          <View
                            style={[
                              styles.alignUserInputFields,
                              { justifyContent: "space-between" }
                            ]}
                          >
                            <View style={styles.alignSubTextLabel}>
                              <Text
                                fontWeight={FontWeight.Regular}
                                testId="internal"
                                textSize={TextSize.Small}
                                textStyle={[styles.subheaderText]}
                              >
                                {t("common:end")}
                              </Text>
                            </View>

                            <View style={styles.dateTimePickerContainer}>
                              <DateTimePicker
                                min={currentDateTime}
                                max="2022-12-31"
                                handleBlur={() => {}}
                                onChange={(sessionEndDate: any) => {
                                  setSessionEndDate(sessionEndDate.target.value);
                                  sessionEndDateCheck(sessionEndDate.target.value);
                                }}
                                // value={programEndDate}
                                name="sessionEndDate"
                                id="sessionEndDate"
                                noFormik
                              />
                            </View>
                          </View>
                        </View>
                        <View style={styles.alignUserInputFields}>
                          <UserInputSecondary
                            textValue={"Venue"}
                            handleChange={(sessionVenue: string) => {
                              setSessionVenue(sessionVenue);
                            }}
                            handleBlur={() => {}}
                            value={sessionVenue}
                            // value={formikProps.values.qualificationTitle}
                            // inputStyle={[styles.inputStyling, styles.inputStylingAlignment]}
                            placeholder={t("common:enterName")}
                            name="countryInput"
                            id="countryInput"
                            titleInputContainerStyles={{ marginTop: 0 }}
                            userStyle={styles.inputStylingAlignment}
                            userStyleText={[styles.textHeaderField, { flex: 30 }]}
                            noFormik
                          />
                        </View>
                        <View style={[styles.alignButtonContainer]}>
                          <Button
                            type={ButtonType.Secondary}
                            buttonStyles={styles.submitbutton}
                            textStyles={styles.submitButtonText}
                            title={t("common:cancel")}
                            onPress={() => cancelCreateSessionHandler()}
                            // disabled={true}
                          />
                          <Button
                            type={ButtonType.Primary}
                            buttonStyles={styles.submitbutton}
                            textStyles={styles.submitButtonText}
                            title={t("session:createSession")}
                            onPress={() => submitSessionHandler()}
                            disabled={
                              sessionVenue === null ||
                              sessionVenue === "" ||
                              sessionStartDate === null ||
                              sessionEndDate === null ||
                              isStartDateTimeCheck ||
                              isEndDateTimeCheck
                            }
                          />
                        </View>
                      </View>
                    </View>
                  </View>
                </View>
              </View>
            </View>
          </View>
          {cancelCreateSession && (
            <View>
              <CancelSessionModal
                modalVisible={cancelCreateSession}
                sendData={cancel}
                setModalVisible={() => setCancelCreateSession(!cancelCreateSession)}
              />
            </View>
          )}
          <View style={styles.leftContainer}>
            <View style={{ marginTop: 60 }}>
              <CreateSessionSidePanelPage sessionDetail={sessionDetail} />
            </View>
          </View>
        </View>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flexDirection: "row"
  },
  rightContainer: {
    display: "flex",
    padding: 20,
    paddingTop: 15,
    backgroundColor: "#F8F5F0",
    flex: 3.2
  },
  loaderContainer: {
    padding: 20
  },
  subLoaderContainer: {
    backgroundColor: colorPallete.white,
    borderRadius: 10,
    padding: 20
  },
  leftContainer: {
    // height: "calc(100vh - 50px)",
    marginTop: -60,
    flex: 1,
    backgroundColor: colorPallete.cordioRedDark2
  },
  header: {
    display: "flex",
    flexDirection: "row"
  },
  iconContainer: {
    marginTop: 10
  },
  titleIcon: {
    color: colorPallete.textBlack,
    fontSize: 9,
    marginRight: 10,
    transform: [{ rotate: "90deg" }]
  },
  headerText: {
    fontSize: 12,
    fontFamily: FontFamily.Medium,
    lineHeight: 17
  },
  headerColor: {
    fontWeight: "400",
    fontSize: 12,
    fontFamily: FontFamily.Regular,
    lineHeight: 17,
    color: colorPallete.textLight
  },
  subContainer: {
    backgroundColor: colorPallete.white,
    borderRadius: 10,
    padding: 18,
    shadowColor: colorPallete.cordioRedDark1,
    shadowOpacity: 0.1,
    shadowOffset: {
      height: 10,
      width: 5
    },
    shadowRadius: 10
  },
  containerStyle: {
    width: "20%",
    marginLeft: 15
  },
  dropDownContainerStyle: {
    borderWidth: 0,
    borderTopRightRadius: 5,
    borderBottomRightRadius: 5
  },
  subContainerOne: {},

  //Header Container
  headerTextTitleSupport: {
    fontWeight: "700"
  },
  headerContainer: {
    flexDirection: "row",
    marginBottom: 20,
    alignItems: "center"
  },
  headerTextSupport: {
    fontWeight: "400",
    marginLeft: 10
  },
  iconContainerHeader: {
    marginRight: 10
  },

  //addMemberScheduleContainer
  addMemberScheduleContainer: {
    flexDirection: "row"
  },
  dividerContainer: {
    borderRightColor: colorPallete.cordioTaupe,
    borderRightWidth: 1,
    borderStyle: "solid",
    opacity: 0.1
  },
  addMemberContainer: {
    flex: 1.2,
    marginBottom: 20,
    padding: 10
  },
  addMemberSubContainer: {
    alignItems: "center",
    marginTop: 10,
    marginBottom: 50
  },
  addMemberButton: {
    width: 137,
    height: 77,
    borderRadius: 10,
    flexDirection: "column",
    justifyContent: "space-evenly"
  },
  inputStylingAlignment: {
    width: "100%",
    fontSize: 12
  },
  textHeaderField: {
    flex: 45,
    fontSize: 12
  },
  textHeaderFieldUserInputDateTime: {
    flex: 35,
    fontSize: 12
  },
  wrapperContainer: {
    paddingLeft: 15,
    paddingRight: 15
  },
  inputContainer: {
    marginBottom: 15
  },
  titleInputContainerStyles: {
    marginTop: 0
  },
  userListMainContainer: {
    // zIndex: -1, //so that the searchdropdown appears on top
    flexDirection: "column",
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: colorPallete.cordioTaupe,
    borderRadius: 10,
    marginTop: 15,
    minHeight: 77,
    // alignItems: "center",
    paddingRight: 5,
    paddingLeft: 5
  },
  memberContainer: {
    zIndex: -1, //so that the searchdropdown appears on top
    flexDirection: "row",
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: colorPallete.cordioTaupe,
    borderRadius: 10,
    marginTop: 15,
    minHeight: 77,
    alignItems: "center",
    paddingRight: 5,
    paddingLeft: 5
  },
  profilePictureContainer: {
    flex: 25,
    alignItems: "center"
  },
  nameContainer: {
    flex: 45
  },
  memberDesignationContainer: {
    flex: 30,
    marginRight: 10
  },

  //addScheduleContainer
  addScheduleContainer: {
    flex: 1,
    marginBottom: 20,
    padding: 10
  },
  alignUserInputFields: {
    marginBottom: 10,
    flexDirection: "row",
    alignItems: "center"
  },
  alignButtonContainer: {
    marginTop: 30,
    marginBottom: 30,
    justifyContent: "space-between",
    flexDirection: "row"
  },
  submitbutton: {
    minWidth: "120px",
    height: "50px",
    borderRadius: 10
    // marginLeft: 5,
    // marginRight: 5
  },
  submitButtonText: {
    fontSize: 12
  },
  profileImage: {
    width: 33,
    height: 33,
    borderRadius: 50
  },
  listDropdownContainer: {
    borderColor: "lightgrey",
    borderWidth: 1,
    borderStyle: "solid",
    padding: 5,
    paddingLeft: 10,
    flex: 80
  },
  miniIconContainer: {
    flexDirection: "row-reverse",
    marginTop: 5
  },
  alignContainerButton: {
    fontSize: 12,
    color: colorPallete.cordioTaupe,
    marginRight: 5,
    marginLeft: 25
  },
  subheaderText: {
    // flex: 12,
    fontWeight: "400",
    fontSize: 12,
    fontFamily: FontFamily.Regular,
    lineHeight: 17,
    color: colorPallete.textLight
    // marginTop: 13
  },
  alignSubTextLabel: {
    // flex: 20
    // width:'22%'
  },
  dateTimePickerContainer: {
    // flex:80,
    width: "73%"
  },
  inputStyle: {
    width: "100%"
  }
});
