import { Platform } from "react-native";
import BrowserTypeFinder from "@socion-cordio/common/src/utils/browserType";
import axios from "axios";
import {
  deviceType,
  isDesktop,
  osName,
  isMobile,
  isTablet,
  isMobileOnly,
  isSmartTV
} from "react-device-detect";
import { artefactDefaultMeta, artefactMetaFields, docMetaFields } from "./../data/configData";
import { CONFIG } from "@socion-cordio/common/src/config/envConstant";

const getDNS = () => {
  const url = CONFIG.REACT_APP_ARTEFACT_API_BASE_URL;
  return url.substring(url.indexOf("//") + 2);
};

const getIp = async () => {
  const ip = await axios.get("https://api.ipify.org/");
  const res = ip.data;
  localStorage.setItem("deviceIp", JSON.stringify(res));
};

export const getDeviceBaseLocation = () => {
  getIp();
  navigator.geolocation.getCurrentPosition(
    (position) => {
      localStorage.setItem("deviceLat", `${position?.coords?.latitude}`);
      localStorage.setItem("deviceLong", `${position?.coords?.longitude}`);
    },
    (err) => {
      console.log("navigator error: ", err);
    }
  );
};

const getDeviceType = () => {
  if (isMobileOnly) {
    return "mobile";
  }
  if (isDesktop) {
    return "desktop";
  }
  if (isTablet) {
    return "tablet";
  }
  if (isSmartTV) {
    return "smarttv";
  }
};
const getDeviceData = () => {
  return {
    deviceId: "Unknown",
    deviceOs: osName,
    deviceType: getDeviceType(),
    deviceBrowserInfo: BrowserTypeFinder(),
    deviceLat: JSON.parse(localStorage.getItem("deviceLat")),
    deviceLong: JSON.parse(localStorage.getItem("deviceLong")),
    deviceIp: JSON.parse(localStorage.getItem("deviceIp"))
  };
};

const getClientData = () => {
  return {
    clientId: `${CONFIG.REACT_APP_ARTEFACT_APPLICATION_ID || "15"}`,
    clientName: "SOCION",
    clientAppName: "Artefact Upload",
    clientAppType: "website",
    clientUrl: `${CONFIG.REACT_APP_ARTEFACT_API_BASE_URL}` + "/api/v1",
    clientDns: getDNS(),
    clientSecret: ""
  };
};

const getUserData = () => {
  const user = JSON.parse(localStorage.getItem("user"));
  return {
    userId: user?.userId,
    userName: user?.userName,
    userBaseLat: user?.baseLocationLat,
    userBaseLong: user?.baseLocationLong,
    userBaseCountry: user?.country,
    userBaseState: user?.state,
    userBaseDistrict: user?.district,
    userBaseSubDistrict: user?.subDistrict,
    userBaseCity: user?.city,
    userRole: localStorage.getItem("currentProgramRole")
  };
};

const getInternetSpeed = () => {
  const data: any = navigator.connection;
  return {
    internetSpeed: data?.downlink + "mbps",
    internetUploadSpeed: ""
  };
};

const getProgramData = () => {
  const program = JSON.parse(localStorage.getItem("programDetails"))?.program;
  return {
    programId: program?.id,
    programName: program?.name,
    programDescription: program?.description,
    userEntityId: program?.entity?.id,
    userEntityName: program?.entity?.name
  };
};

export const baseTelemetryData = () => {
  return {
    ...getDeviceData(),
    ...getClientData(),
    ...getUserData(),
    ...getProgramData(),
    ...getInternetSpeed()
  };
};

export const updateArtefactFields = (artefact: any, docsCount: number) => {
  const updatedFields: any = {};
  for (let key in artefactMetaFields) {
    console.log("artefactSubmittedDate", key);
    if (
      key === "artefactSubmittedDate" ||
      key === "artefactCreatedDate" ||
      key === "artefactUpdatedDate"
    ) {
      updatedFields[key] = artefact[artefactMetaFields[key]]
        ? new Date(artefact[artefactMetaFields[key]]).toISOString()
        : artefact[artefactMetaFields[key]];
    } else if (!artefactDefaultMeta.includes(key)) {
      updatedFields[key] = artefact[artefactMetaFields[key]] || "";
    } else if (key === "artefactSubDistrict" || key === "artefactPlaceId") {
      updatedFields[key] = artefact["sub_district"] || artefact["subDistrict"] || "";
    } else if (key === "noOfDocs") {
      updatedFields[key] = docsCount;
    } else if (key === "artefactSubmitter") {
      updatedFields[key] = JSON.stringify(artefact[artefactMetaFields[key]]?.submitterList);
    }
  }
  return updatedFields;
};

export const updateArtefactDocFields = (doc: any) => {
  const updatedFields: any = {};
  for (let key in docMetaFields) {
    if (!artefactDefaultMeta.includes(key)) {
      updatedFields[key] = doc[artefactMetaFields[key]];
    }
  }
  return updatedFields;
};
