import {
  Text,
  TextSize,
  FontWeight,
  FontFamily
} from "@socion-cordio/common/src/components/atoms/text";
import TextField from "@socion-cordio/common/src/components/atoms/textField";
import { colorPallete } from "@socion-cordio/common/src/assets/styles/colors";
import React, { ReactElement, useState } from "react";
import { View, StyleSheet, StyleProp, ImageStyle, ViewStyle } from "react-native";
import { Switch } from "@socion-cordio/common/src/components/atoms/switches";
import { ApiClient } from "@socion-cordio/common/src/network/apiClient";
import { programEndPoints } from "@socion-cordio/common/src/repositories/endPoints";
import { HTTP_STATUS_CODES } from "@socion-cordio/common/src/network/constants";

interface IProps {
  noFormik?: boolean;
  settingsDetails: any;
  userRoles: any;
  isSuperAdmin: any;
  programStatus?: Function;
  getProgramDetails?: Function;
  // isSecure?: boolean;
}

export default function SettingsDetails(props: IProps): ReactElement {
  const { programStatus, getProgramDetails } = props;
  const [accessParticipantDetails, setAccessParticipantDetails] = useState<boolean>(
    props.settingsDetails?.hyperlinked
  );
  const [acceptingVerifications, setAcceptingVerifications] = useState<boolean>(
    props.settingsDetails?.acceptingVerifications
  );
  const [sessionScanMode, setSessionScanMode] = useState(props.settingsDetails?.sessionScanMode);
  const [subContentEnabled, setSubContentEnabled] = useState<boolean>(
    props.settingsDetails?.subContentEnabled
  );
  const [topicLibraryEnabled, setTopicLibraryEnabled] = useState<boolean>(
    props.settingsDetails?.topicLibraryEnabled
  );
  const [acceptingQualifications, setQualification] = useState<boolean>(
    props.settingsDetails?.acceptingQualifications
  );
  const [acceptingRoles, setAcceptingRoles] = useState<boolean>(
    props.settingsDetails?.acceptingRoles
  );
  const [multipleScanModeAccess, setMultipleScanModeAccess] = useState<boolean>(
    props.settingsDetails?.multipleScanModeAccess
  );
  const [paSurveyEnabled, setPaSurveyEnabled] = useState<boolean>(
    props.settingsDetails?.paSurveyEnabled
  );
  const [saSurveyEnabled, setSaSurveyEnabled] = useState<boolean>(
    props.settingsDetails?.saSurveyEnabled
  );

  const [licensesIncludeTopicLibraryAccess, setLicensesIncludeTopicLibraryAccess] =
    useState<boolean>(props.settingsDetails?.licensesIncludeTopicLibraryAccess);

  const handleSettingsToggle = async (key: string, value: boolean) => {
    const settingsResponse = await ApiClient.put(
      programEndPoints.updateSettings(props.settingsDetails.id, key, value),
      {}
    );
    getProgramDetails();
  };

  const handleScanSettingsToggle = async (key: string, value: string) => {
    const scanSettingsResponse = await ApiClient.put(
      programEndPoints.updateSingleScanSettings(props.settingsDetails.id, key, value),
      {}
    );
    getProgramDetails();
  };

  const scanValueMappingOnToggle = (val: any) => {
    if (val === "SINGLE_SCAN") {
      return "MULTI_SCAN";
    } else {
      return "SINGLE_SCAN";
    }
  };

  const reverseScanStatusMapping = (val: any) => {
    if (val === "SINGLE_SCAN") {
      return true;
    } else {
      return false;
    }
  };

  return (
    <View>
      {!programStatus && (
        <view>
          {props.isSuperAdmin ? (
            <View style={styles.container}>
              <View>
                <Text
                  fontWeight={FontWeight.Light}
                  testId="headerText"
                  textSize={TextSize.Small}
                  textStyle={[styles.headerText, styles.headerAlignment]}
                >
                  Settings
                </Text>
              </View>
              <View style={styles.subContainer}>
                <View style={styles.settingContainer}>
                  <View style={styles.settingTextContainer}>
                    <Text
                      fontWeight={FontWeight.Light}
                      testId="headerText"
                      textSize={TextSize.Small}
                      textStyle={[styles.headerText, styles.headerAlign]}
                    >
                      Authorized program to access participant details
                    </Text>
                  </View>
                  <View style={styles.radioButtonContainer}>
                    <Switch
                      isOn={accessParticipantDetails}
                      onColor="#B7504A"
                      offColor="#F1DCDB"
                      labelStyle={{ color: "black", fontWeight: "900" }}
                      size="medium"
                      onToggle={() => {
                        setAccessParticipantDetails(
                          (accessParticipantDetails: boolean) => !accessParticipantDetails
                        );
                        handleSettingsToggle("hyperLinked", !accessParticipantDetails);
                      }}
                    />
                  </View>
                </View>

                <View style={styles.settingContainer}>
                  <View style={styles.settingTextContainer}>
                    <Text
                      fontWeight={FontWeight.Light}
                      testId="headerText"
                      textSize={TextSize.Small}
                      textStyle={[styles.headerText, styles.headerAlign]}
                    >
                      Authorized program to access verify module
                    </Text>
                  </View>
                  <View style={styles.radioButtonContainer}>
                    <Switch
                      isOn={acceptingVerifications}
                      onColor="#B7504A"
                      offColor="#F1DCDB"
                      labelStyle={{ color: "black", fontWeight: "900" }}
                      size="medium"
                      onToggle={() => {
                        setAcceptingVerifications(
                          (acceptingVerifications: boolean) => !acceptingVerifications
                        );
                        handleSettingsToggle("acceptingVerifications", !acceptingVerifications);
                      }}
                    />
                  </View>
                </View>

                <View style={styles.settingContainer}>
                  <View style={styles.settingTextContainer}>
                    <Text
                      fontWeight={FontWeight.Light}
                      testId="headerText"
                      textSize={TextSize.Small}
                      textStyle={[styles.headerText, styles.headerAlign]}
                    >
                      Authorized program to access single scan
                    </Text>
                  </View>
                  <View style={styles.radioButtonContainer}>
                    <Switch
                      isOn={multipleScanModeAccess}
                      onColor="#B7504A"
                      offColor="#F1DCDB"
                      labelStyle={{ color: "black", fontWeight: "900" }}
                      size="medium"
                      onToggle={() => {
                        setMultipleScanModeAccess(
                          (multipleScanModeAccess: boolean) => !multipleScanModeAccess
                        );
                        handleSettingsToggle("multipleScanModeAccess", !multipleScanModeAccess);
                      }}
                    />
                  </View>
                </View>

                <View style={styles.settingContainer}>
                  <View style={styles.settingTextContainer}>
                    <Text
                      fontWeight={FontWeight.Light}
                      testId="headerText"
                      textSize={TextSize.Small}
                      textStyle={[styles.headerText, styles.headerAlign]}
                    >
                      Enable Sub-content
                    </Text>
                  </View>
                  <View style={styles.radioButtonContainer}>
                    <Switch
                      isOn={subContentEnabled}
                      onColor="#B7504A"
                      offColor="#F1DCDB"
                      labelStyle={{ color: "black", fontWeight: "900" }}
                      size="medium"
                      onToggle={() => {
                        setSubContentEnabled((subContentEnabled: boolean) => !subContentEnabled);
                        handleSettingsToggle("subContentEnabled", !subContentEnabled);
                      }}
                    />
                  </View>
                </View>

                <View style={styles.settingContainer}>
                  <View style={styles.settingTextContainer}>
                    <Text
                      fontWeight={FontWeight.Light}
                      testId="headerText"
                      textSize={TextSize.Small}
                      textStyle={[styles.headerText, styles.headerAlign]}
                    >
                      Enable Topic Library
                    </Text>
                  </View>
                  <View style={styles.radioButtonContainer}>
                    <Switch
                      isOn={topicLibraryEnabled}
                      onColor="#B7504A"
                      offColor="#F1DCDB"
                      labelStyle={{ color: "black", fontWeight: "900" }}
                      size="medium"
                      onToggle={() => {
                        setTopicLibraryEnabled(
                          (topicLibraryEnabled: boolean) => !topicLibraryEnabled
                        );
                        handleSettingsToggle("topicLibraryEnabled", !topicLibraryEnabled);
                      }}
                    />
                  </View>
                </View>

                <View style={styles.settingContainer}>
                  <View style={styles.settingTextContainer}>
                    <Text
                      fontWeight={FontWeight.Light}
                      testId="headerText"
                      textSize={TextSize.Small}
                      textStyle={[styles.headerText, styles.headerAlign]}
                    >
                      Licenses include topic library access
                    </Text>
                  </View>
                  <View style={styles.radioButtonContainer}>
                    <Switch
                      isOn={licensesIncludeTopicLibraryAccess}
                      onColor="#B7504A"
                      offColor="#F1DCDB"
                      labelStyle={{ color: "black", fontWeight: "900" }}
                      size="medium"
                      onToggle={() => {
                        setLicensesIncludeTopicLibraryAccess(
                          (licensesIncludeTopicLibraryAccess: boolean) =>
                            !licensesIncludeTopicLibraryAccess
                        );
                        handleSettingsToggle(
                          "licensesIncludeTopicLibraryAccess",
                          !licensesIncludeTopicLibraryAccess
                        );
                      }}
                    />
                  </View>
                </View>

                <View style={styles.settingContainer}>
                  <View style={styles.settingTextContainer}>
                    <Text
                      fontWeight={FontWeight.Light}
                      testId="headerText"
                      textSize={TextSize.Small}
                      textStyle={[styles.headerText, styles.headerAlign]}
                    >
                      Enable Task
                    </Text>
                  </View>
                  <View style={styles.radioButtonContainer}>
                    <Switch
                      isOn={saSurveyEnabled}
                      onColor="#B7504A"
                      offColor="#F1DCDB"
                      labelStyle={{ color: "black", fontWeight: "900" }}
                      size="medium"
                      onToggle={() => {
                        setSaSurveyEnabled((saSurveyEnabled: boolean) => !saSurveyEnabled);
                        handleSettingsToggle("saSurveyEnabled", !saSurveyEnabled);

                        if (saSurveyEnabled) {
                          setPaSurveyEnabled((paSurveyEnabled: boolean) => false);
                          handleSettingsToggle("paSurveyEnabled", false);
                        }
                      }}
                    />
                  </View>
                </View>
              </View>
            </View>
          ) : (
            <view>
              {(props?.userRoles?.includes("PROGRAM_ADMIN") ||
                props?.userRoles?.includes("ENTITY_ADMIN")) && (
                <View style={styles.container}>
                  <View>
                    <Text
                      fontWeight={FontWeight.Light}
                      testId="headerText"
                      textSize={TextSize.Small}
                      textStyle={[styles.headerText, styles.headerAlignment]}
                    >
                      Settings
                    </Text>
                  </View>
                  <View style={styles.subContainer}>
                    {(props?.userRoles?.includes("PROGRAM_ADMIN") ||
                      props?.userRoles?.includes("ENTITY_ADMIN")) &&
                      acceptingVerifications && (
                        <View style={styles.settingContainer}>
                          <View style={styles.settingTextContainer}>
                            <Text
                              fontWeight={FontWeight.Light}
                              testId="headerText"
                              textSize={TextSize.Small}
                              textStyle={[styles.headerText, styles.headerAlign]}
                            >
                              Accept Qualification verification requests
                            </Text>
                          </View>
                          <View style={styles.radioButtonContainer}>
                            <Switch
                              isOn={acceptingQualifications}
                              onColor="#B7504A"
                              offColor="#F1DCDB"
                              labelStyle={{ color: "black", fontWeight: "900" }}
                              size="medium"
                              onToggle={() => {
                                setQualification(
                                  (acceptingQualifications: boolean) => !acceptingQualifications
                                );
                                handleSettingsToggle(
                                  "acceptingQualifications",
                                  !acceptingQualifications
                                );
                              }}
                            />
                          </View>
                        </View>
                      )}
                    {(props?.userRoles?.includes("PROGRAM_ADMIN") ||
                      props?.userRoles?.includes("ENTITY_ADMIN")) &&
                      acceptingVerifications && (
                        <View style={styles.settingContainer}>
                          <View style={styles.settingTextContainer}>
                            <Text
                              fontWeight={FontWeight.Light}
                              testId="headerText"
                              textSize={TextSize.Small}
                              textStyle={[styles.headerText, styles.headerAlign]}
                            >
                              Accept Role verification requests
                            </Text>
                          </View>
                          <View style={styles.radioButtonContainer}>
                            <Switch
                              isOn={acceptingRoles}
                              onColor="#B7504A"
                              offColor="#F1DCDB"
                              labelStyle={{ color: "black", fontWeight: "900" }}
                              size="medium"
                              onToggle={() => {
                                setAcceptingRoles((acceptingRoles: boolean) => !acceptingRoles);
                                handleSettingsToggle("acceptingRoles", !acceptingRoles);
                              }}
                            />
                          </View>
                        </View>
                      )}
                    {(props?.userRoles?.includes("PROGRAM_ADMIN") ||
                      props?.userRoles?.includes("ENTITY_ADMIN")) &&
                      multipleScanModeAccess && (
                        <View style={styles.settingContainer}>
                          <View style={styles.settingTextContainer}>
                            <Text
                              fontWeight={FontWeight.Light}
                              testId="headerText"
                              textSize={TextSize.Small}
                              textStyle={[styles.headerText, styles.headerAlign]}
                            >
                              Accept Single Scan
                            </Text>
                          </View>
                          <View style={styles.radioButtonContainer}>
                            <Switch
                              isOn={reverseScanStatusMapping(sessionScanMode)}
                              onColor="#B7504A"
                              offColor="#F1DCDB"
                              labelStyle={{ color: "black", fontWeight: "900" }}
                              size="medium"
                              onToggle={() => {
                                setSessionScanMode((sessionScanMode: any) =>
                                  scanValueMappingOnToggle(sessionScanMode)
                                );
                                handleScanSettingsToggle(
                                  "sessionScanMode",
                                  scanValueMappingOnToggle(sessionScanMode)
                                );
                              }}
                            />
                          </View>
                        </View>
                      )}
                    {props?.userRoles?.includes("PROGRAM_ADMIN") && saSurveyEnabled && (
                      <View style={styles.settingContainer}>
                        <View style={styles.settingTextContainer}>
                          <Text
                            fontWeight={FontWeight.Light}
                            testId="headerText"
                            textSize={TextSize.Small}
                            textStyle={[styles.headerText, styles.headerAlign]}
                          >
                            Accept task access
                          </Text>
                        </View>
                        <View style={styles.radioButtonContainer}>
                          <Switch
                            isOn={paSurveyEnabled}
                            onColor="#B7504A"
                            offColor="#F1DCDB"
                            labelStyle={{ color: "black", fontWeight: "900" }}
                            size="medium"
                            onToggle={() => {
                              setPaSurveyEnabled((paSurveyEnabled: boolean) => !paSurveyEnabled);
                              handleSettingsToggle("paSurveyEnabled", !paSurveyEnabled);
                            }}
                          />
                        </View>
                      </View>
                    )}
                  </View>
                </View>
              )}
            </view>
          )}
        </view>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    borderColor: "rgba(103, 88, 80,0.1)",
    borderStyle: "solid",
    borderWidth: 1,
    borderRadius: 10,
    padding: 15
  },
  headerText: {
    color: colorPallete.textLight,
    fontSize: 12,
    fontFamily: FontFamily.Medium,
    lineHeight: 17
  },
  headerAlignment: {
    color: colorPallete.textLight,
    fontFamily: FontFamily.Regular
  },
  headerAlign: {
    color: colorPallete.textBlack
  },
  subContainer: {
    marginTop: 20
  },
  settingContainer: {
    flexDirection: "row",
    alignItems: "center",
    marginTop: 5,
    marginBottom: 15
  },
  settingTextContainer: {
    flex: 85
  },
  radioButtonContainer: {
    flex: 15,
    alignItems: "center"
  }
});
