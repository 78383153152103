import React, { ReactElement, useState, useEffect, useRef } from "react";
import { StyleSheet, View, Pressable, ScrollView } from "react-native";
import { colorPallete } from "@socion-cordio/common/src/assets/styles/colors";
import {
  Text,
  TextSize,
  FontWeight,
  FontFamily
} from "@socion-cordio/common/src/components/atoms/text";
import Icon, { IconNames } from "@socion-cordio/common/src/components/atoms/icon";
import Button, { ButtonType } from "@socion-cordio/common/src/components/atoms/button";
import TextField from "@socion-cordio/common/src/components/atoms/textField";

interface Props {
  title?: string;
  onClose?: Function;
  testId: string;
  label: string;
  handleChange: Function;
  value: string;
  placeholder?: string;
  name: string;
  id: string;
  handleSubmit: Function;
  disabled?: boolean;
  error?: boolean;
  errorMessage?: string;
  serverError?: string;
  cancelPress?: Function;
  isCancelVisible?: Boolean;
  locationArray?: any;
  renderLocationSugetion?: Function;
}

function elevationShadowStyle(elevation: number) {
  return {
    elevation,
    shadowColor: "black",
    shadowOffset: { width: 3.5, height: 0.55 * elevation },
    shadowOpacity: 0.2,
    shadowRadius: 0.5 * elevation
  };
}

export default function EmailInputPrimary(props: Props): ReactElement {
  const {
    title,
    onClose,
    testId,
    label,
    handleChange,
    value,
    placeholder,
    name,
    id,
    handleSubmit,
    disabled,
    error,
    errorMessage,
    serverError,
    cancelPress,
    isCancelVisible,
    locationArray,
    renderLocationSugetion
  } = props;

  return (
    <View>
      <View style={styles.modalHeader}>
        <Text
          fontWeight={FontWeight.Bold}
          testId="addEmailText"
          textSize={TextSize.Small}
          textStyle={styles.headerText}
        >
          {title}
        </Text>
        <View>
          <Pressable onPress={() => onClose()}>
            <Icon testID="close" name={IconNames.crossCircle} />
          </Pressable>
        </View>
      </View>
      <View style={styles.emailInputContainer}>
        <View style={styles.subHeaderContainer}>
          <Text
            fontWeight={FontWeight.Regular}
            testId={testId}
            textSize={TextSize.Small}
            textStyle={styles.subheaderText}
          >
            {label}
          </Text>
        </View>
        <View>
          <View style={{ flexDirection: "row" }}>
            <TextField
              label=""
              handleChange={(value: any) => handleChange(value)}
              handleBlur={() => {}}
              value={value}
              inputStyle={styles.inputRole}
              placeholder={placeholder}
              name={name}
              id={id}
              noFormik
            />
            {isCancelVisible && (
              <View style={styles.buttonContainer}>
                <View style={styles.iconContainer}>
                  <View style={styles.iconContainer}>
                    <Icon
                      name={IconNames.close}
                      customStyle={styles.iconStyle}
                      onPress={() => cancelPress()}
                    />
                  </View>
                  <Icon
                    name={IconNames.tick}
                    customStyle={styles.iconStyle}
                    onPress={() => handleSubmit()}
                  />
                </View>
              </View>
            )}
          </View>

          {!locationArray || locationArray?.length === 0 ? null : (
            <View style={[styles.searchBoxShadow, styles.box]}>
              <ScrollView
                style={{
                  height: "120px"
                }}
                contentContainerStyle={{ flexGrow: 1 }}
              >
                <View style={{ padding: 5, paddingBottom: 0, width: "300px" }}>
                  {locationArray?.map(renderLocationSugetion)}
                </View>
                <View style={styles.alignGoogleText}>
                  <Text
                    fontWeight={FontWeight.Regular}
                    testId="signup"
                    textSize={TextSize.Small}
                    style={styles.credits}
                  >
                    {"powered by Google"}
                  </Text>
                </View>
              </ScrollView>
            </View>
          )}

          {error && (
            <Text
              testId="emailError"
              textSize={TextSize.Small}
              fontWeight={FontWeight.Regular}
              textStyle={styles.error}
            >
              {errorMessage}
            </Text>
          )}
          {!error && serverError && (
            <Text
              testId="incorrectEmail"
              textSize={TextSize.Small}
              fontWeight={FontWeight.Regular}
              textStyle={styles.error}
            >
              {serverError}
            </Text>
          )}
        </View>
      </View>
      <View style={[styles.submitButtonContainer, styles.alignSubmitButton]}>
        <Button
          type={ButtonType.Primary}
          buttonStyles={styles.submitbutton}
          title="Save"
          onPress={() => handleSubmit()}
          disabled={disabled}
        />
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  modalHeader: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row"
  },
  headerText: {
    fontWeight: "700",
    fontSize: 14,
    fontFamily: FontFamily.Medium,
    lineHeight: 17
  },
  subheaderText: {
    fontWeight: "400",
    fontSize: 14,
    fontFamily: FontFamily.Regular,
    lineHeight: 17,
    color: colorPallete.textBlack,
    marginTop: 13,
    flex: 20
  },
  submitButtonContainer: {
    alignItems: "center",
    marginTop: 30,
    marginBottom: 20
  },
  alignSubmitButton: {
    marginTop: 40,
    zIndex: -1
  },
  submitbutton: {
    width: "175px",
    height: "50px",
    borderRadius: 10,
    marginLeft: 10,
    marginRight: 10
  },
  buttonContainer: {
    flexDirection: "row"
  },
  emailInputContainer: {
    marginTop: 20,
    flexDirection: "row",
    justifyContent: "center"
  },
  subHeaderContainer: {
    // marginTop: 10,
    marginRight: 15
  },
  inputRole: {
    height: 40,
    outline: "none",
    borderRadius: 5,
    padding: 7,
    fontSize: 14,
    fontFamily: FontFamily.Regular,
    fontWeight: "400",
    width: "330px"
  },
  error: {
    position: "relative",
    color: "red",
    alignSelf: "flex-start",
    fontSize: 10,
    fontWeight: "400",
    zIndex: -1
  },
  iconStyle: {
    color: colorPallete.cordioTaupe
  },
  iconContainer: {
    justifyContent: "center",
    marginLeft: 10
  },
  credits: {
    color: colorPallete.gray,
    textAlign: "right"
  },
  alignGoogleText: {
    marginRight: 10,
    marginBottom: 10
  },
  searchBoxShadow: {
    ...elevationShadowStyle(5),
    backgroundColor: colorPallete.white
    // padding: 10
  },
  box: {
    borderWidth: 0.5,
    borderColor: colorPallete.gray,
    // margin:verticalScale(5),
    // borderRadius:verticalScale(15),
    marginTop: 0
    // padding:verticalScale(10)
  }
});
