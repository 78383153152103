import React, { ReactElement } from "react";
import DropDownPicker, { DropDownDirectionType } from "react-native-dropdown-picker";
import { StyleSheet, View, StyleProp, ViewStyle, TextStyle } from "react-native";
import { colorPallete } from "@socion-cordio/common/src/assets/styles/colors";
const styles = StyleSheet.create({
  container: {
    borderRadius: 10,
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
    width: 71,
    zIndex: 1
  },
  pickerIcon: {
    flexDirection: "row",
    paddingTop: 10,
    marginLeft: 10,
    marginRight: 5
  },
  containerStyle: {
    borderColor: colorPallete.cordioTaupe,
    borderWidth: 1,
    borderTopLeftRadius: 5,
    borderBottomLeftRadius: 5,
    height: 40,
    marginBottom: 30
  },
  dropDownContainerStyle: {
    position: "absolute",
    marginTop: 15,
    padding: 0
  },
  labelStyle: {
    paddingLeft: 5
  },
  customItemContainerStyle: {
    position: "absolute"
  },
  listItem: {}
});
interface IProps {
  items?: any;
  dropdownValue?: any;
  open?: any;
  setOpen?: (open: boolean) => void;
  setValue?: (value: any) => void;
  setItems?: (items: any) => void;
  disabled?: boolean;
  dropdownPlaceholder?: string;
  dropDownDirection?: DropDownDirectionType;
  closeAfterSelecting?: boolean;
  dropDownContainerStyle?: StyleProp<ViewStyle>;
  containerStyle?: StyleProp<ViewStyle>;
  placeholderStyle?: StyleProp<ViewStyle>;
  listMode?: string;
  onChangeValue?: (value: any) => void;
  customItemContainerStyle?: StyleProp<ViewStyle>;
  containerWidthStyle?: StyleProp<ViewStyle>;
  containerRadius?: StyleProp<ViewStyle>;
  pickerStyle?: StyleProp<ViewStyle>;
  labelStyles?: StyleProp<ViewStyle>;
  listItems?: StyleProp<ViewStyle>;
  listItemLabelStyle?: StyleProp<TextStyle>;
  arrowIconStyle?: StyleProp<ViewStyle>;
}
export default function Dropdown(props: IProps): ReactElement {
  const {
    open,
    setOpen,
    items,
    setItems,
    dropdownValue,
    setValue,
    disabled,
    dropDownDirection = "BOTTOM",
    closeAfterSelecting = true,
    dropDownContainerStyle = styles.dropDownContainerStyle,
    containerStyle = styles.containerStyle,
    placeholderStyle,
    listMode,
    onChangeValue,
    customItemContainerStyle,
    containerWidthStyle,
    containerRadius,
    pickerStyle,
    labelStyles,
    listItems,
    listItemLabelStyle,
    dropdownPlaceholder = "Select an item",
    arrowIconStyle
  } = props;
  return (
    <View style={[styles.container, containerWidthStyle]}>
      <DropDownPicker
        style={[styles.pickerIcon, pickerStyle]}
        open={open}
        value={dropdownValue}
        items={items}
        onChangeValue={onChangeValue}
        setOpen={setOpen}
        setValue={setValue}
        setItems={setItems}
        listMode="SCROLLVIEW"
        disabled={disabled}
        dropDownDirection={dropDownDirection}
        closeAfterSelecting={closeAfterSelecting}
        containerStyle={[containerStyle, containerRadius]}
        placeholderStyle={placeholderStyle}
        dropDownContainerStyle={dropDownContainerStyle}
        labelStyle={[styles.labelStyle, labelStyles]}
        customItemContainerStyle={customItemContainerStyle}
        listItemContainerStyle={[styles.listItem, listItems]}
        showTickIcon={false}
        listItemLabelStyle={listItemLabelStyle}
        placeholder={dropdownPlaceholder}
        arrowIconStyle={arrowIconStyle}
      />
    </View>
  );
}
