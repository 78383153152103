import { Signup } from "@socion-cordio/common/src/models/signup";
import { SIGNUP } from "@socion-cordio/common/src/modules/signup/actions/actionTypes";

export interface ISignupLoading {
  signupUser: boolean;
}

export interface ISignupError {
  signupUser: string;
}
export interface ISignupState {
  signupData: Signup;
  error: ISignupError;
  loading: ISignupLoading;
}

export const signupInitialState: ISignupState = {
  signupData: null,
  error: {
    signupUser: ""
  },
  loading: { signupUser: false }
};

const signupReducer = (
  state = signupInitialState,
  action: { payload: any; type: string }
): ISignupState => {
  switch (action.type) {
    case SIGNUP.SIGNUP_USER.LOADING:
      return {
        ...state,
        loading: { ...state.loading, signupUser: true },
        error: { ...state.error, signupUser: null }
      };
    case SIGNUP.SIGNUP_USER.SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, signupUser: false },
        error: { ...state.error, signupUser: null },
        signupData: action.payload
      };
    case SIGNUP.SIGNUP_USER.ERROR:
      return {
        ...state,
        loading: { ...state.loading, signupUser: false },
        error: { ...state.error, signupUser: action.payload }
      };
    default:
      return state;
  }
};
export default signupReducer;
