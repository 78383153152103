import React, { ReactElement, useEffect, useState } from "react";
import styled from "styled-components";
import {
  useTable,
  useSortBy,
  useGlobalFilter,
  usePagination,
  useRowSelect,
  useBlockLayout
} from "react-table";
import { useSticky } from "react-table-sticky";
import SearchFilter from "@socion-cordio/common/src/components/molecules/searchFilter";
import Pagination from "@socion-cordio/common/src/components/molecules/pagination";
import Icon, { IconNames } from "@socion-cordio/common/src/components/atoms/icon";
import {
  Text,
  TextSize,
  FontFamily,
  FontWeight
} from "@socion-cordio/common/src/components/atoms/text";
import { StyleSheet, TouchableOpacity } from "react-native";
import { dataHelper } from "@socion-cordio/common/src/utils/dataHelper";
import { View } from "react-native";
import Button, { ButtonType } from "@socion-cordio/common/src/components/atoms/button";
import { colorPallete } from "../../../../../common/src/assets/styles/colors";
import "./table.css";
import { useTranslation } from "react-i18next";
import Tooltip from "react-tooltip-lite";

// TABLE USED ONLY FOR HORIZONTAL SCROLL

const Styles = styled.div`
  display: block;
  max-width: 100%;
  /* padding: 1rem; */
  /* This will make the table scrollable when it gets too small */
  .tableWrap {
    display: block;
    max-width: 100%;
    overflow-x: scroll;
    overflow-y: hidden;
    /* border-bottom: 1px solid #c2bcb9; */
  }

  .tableHeader {
    display: flex;
    flex-direction: row;
    /* align-items: center; */
  }

  .searchAndPaginations {
    /* display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center; */
    /* margin-bottom: 10; */
  }

  .headerLabel {
    font-weight: "700";
    text-align: left;
  }
  table {
    display: inline-block;
    /* width: 100%; */
    border-spacing: 0;
    border-top: 1px solid hsl(20, 7%, 90%);
    border-right: 1px solid hsl(20, 7%, 90%);
    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }
    tr:hover {
      background-color: #f8f8f8;
      cursor: pointer;
    }
    tr:last-child {
      border-radius: 0 0 20px 20px;
    }
    th,
    td {
      margin: 0;
      /* padding: 1rem; */
      padding: 1rem 0.8rem 1rem 0.8rem;
      padding-right: 0.2rem;
      /* padding-left: 0.2rem; */
      border-bottom: 1px solid hsl(20, 7%, 90%);
      border-right: 1px solid hsl(20, 7%, 90%);
      /* background-color: #fff; */
      /* overflow: hidden; */
      :last-child {
        border-right: 0;
      }
    }
    .updatedTableDataCol {
      padding: 8px 10px 8px 14px;
      background-color: #fff;
      // display: flex;
      // flex-direction: row;
      // align-items: center;
    }
    .tableHeaderStyle {
      background-color: #f8f8f8;
    }
  }

  .iconLabelContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
  }

  &.sticky {
    overflow: scroll;
    .header,
    .footer {
      position: sticky;
      z-index: 1;
      width: fit-content;
    }

    .header {
      top: 0;
      box-shadow: 0px 3px 3px #ccc;
    }

    .body {
      position: relative;
      z-index: 0;
    }

    [data-sticky-td] {
      position: sticky;
    }

    [data-sticky-last-left-td] {
      box-shadow: 2px 0px 3px #ccc;
    }

    [data-sticky-first-right-td] {
      box-shadow: -2px 0px 3px #ccc;
    }
  }

  @media only screen and (max-width: 899px) {
    .tableWrap {
      display: block;
      max-width: 100%;
      overflow-x: scroll;
      overflow-y: hidden;
      border-bottom: 1px solid #c2bcb9;
    }
  }
`;

const IndeterminateCheckbox = React.forwardRef((props: any, ref) => {
  const { indeterminate, ...rest } = props;
  const defaultRef = React.useRef();
  const resolvedRef: any = ref || defaultRef;

  React.useEffect(() => {
    resolvedRef.current.indeterminate = indeterminate;
  }, [resolvedRef, indeterminate]);

  return (
    <>
      <input type="checkbox" ref={resolvedRef} {...rest} />
    </>
  );
});

interface IProps {
  columns: any;
  data: any;
  isSuperAdmin?: boolean;
  paginationAndSearch?: boolean;
  handleRowClick?: Function;
  addIcon?: ReactElement;
  download?: any;
  refreshList?: any;
  handleCreateProgram?: Function;
  textValue?: string;
  createSessionHeaderText?: any;
  searchBarCustomStyle?: any;
  dateRangePicker?: any;
  handleSelectedRows?: Function;
  showSearchField?: boolean;
  showPaginationField?: boolean;
  showSaveBadgeButton?: boolean;
  saveSelectedBadgeDetailsHandler?: Function;
  isConflictPresentInList?: boolean;
  removeFirstHeaderList?: boolean;
  filterIcon?: ReactElement;
  filterButtons?: ReactElement;
  refreshListHandler?: Function;
  displayRefreshButton?: boolean;
  noData?: any;
}

export default function TableSecondary(props: IProps) {
  const { t } = useTranslation();
  const {
    columns,
    data,
    isSuperAdmin,
    paginationAndSearch = true,
    handleRowClick,
    addIcon,
    download,
    refreshList,
    textValue,
    handleCreateProgram,
    createSessionHeaderText,
    searchBarCustomStyle,
    dateRangePicker,
    handleSelectedRows,
    showSearchField = true,
    showPaginationField = true,
    showSaveBadgeButton = false,
    saveSelectedBadgeDetailsHandler,
    isConflictPresentInList,
    removeFirstHeaderList = false,
    filterIcon,
    filterButtons,
    refreshListHandler,
    displayRefreshButton = true,
    noData = ""
  } = props;

  const defaultColumn = React.useMemo(
    () => ({
      minWidth: 30,
      width: 150,
      maxWidth: 400
    }),
    []
  );

  const filterTypes = React.useMemo(
    () => ({
      text: (rows: any, id: any, filterValue: any) => {
        return rows.filter((row: any) => {
          const rowValue = row.values[id];
          return rowValue !== undefined
            ? String(rowValue).toLowerCase().startsWith(String(filterValue).toLowerCase())
            : true;
        });
      }
    }),
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    gotoPage,
    nextPage,
    previousPage,
    state: { pageSize, pageIndex, selectedRowIds },
    prepareRow,
    state,
    setGlobalFilter,
    rows,
    selectedFlatRows
  } = useTable(
    {
      columns,
      data,
      filterTypes,
      initialState: { pageSize: paginationAndSearch ? 12 : data.length, pageIndex: 0 },
      defaultColumn
    },
    useGlobalFilter,
    useSortBy,
    useBlockLayout,
    usePagination,
    useRowSelect,
    useSticky
  );

  useEffect(() => {
    // console.log(selectedRowIds, selectedFlatRows.map(d => d.original))
    handleSelectedRows && handleSelectedRows(selectedFlatRows.map((d) => d.original));
  }, [selectedRowIds]);

  const [headerCheck, setHeaderCheck] = useState(null);
  useEffect(() => {
    checkHeader();
  }, [data, columns]);

  const checkHeader = () => {
    setHeaderCheck(headerGroups);
    removeFirstHeaderList === true ? headerGroups.splice(0, 1) : headerGroups;
  };

  return (
    <div>
      {dataHelper.isEmptyArray(data) ? (
        <>
          {isSuperAdmin && (
            <View style={styles.acceptInviteContainer}>
              <View>
                <View style={styles.noQualifications}>
                  <Text
                    fontWeight={FontWeight.Regular}
                    testId="signup"
                    textSize={TextSize.Small}
                    style={styles.noContent}
                  >
                    {`The entity does not have any programs.`}
                  </Text>
                </View>

                <View style={styles.buttonContainer}>
                  <Button
                    type={ButtonType.Primary}
                    buttonStyles={styles.button}
                    title="Create Program"
                    onPress={() => handleCreateProgram()}
                    textStyles={styles.buttonFont}
                  />
                </View>
              </View>
            </View>
          )}
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center"
            }}
          >
            {!isSuperAdmin && (
              <div
                style={{
                  padding: 25,
                  fontSize: 14,
                  fontFamily: FontFamily.Bold,
                  display: "flex"
                }}
              >
                {noData == "" ? "No data found" : noData}
              </div>
            )}

            {dateRangePicker && (
              <div style={{ display: "flex", marginRight: "1rem" }}>{dateRangePicker}</div>
            )}
          </div>
        </>
      ) : (
        <Styles>
          {paginationAndSearch && (
            <div>
              <div
                className="searchAndPaginations"
                style={
                  showSearchField === true
                    ? {
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        padding: 18
                      }
                    : { marginBottom: 20, float: "right" }
                }
              >
                <div style={{ display: "flex", flexDirection: "row" }}>
                  {showSearchField && (
                    <div style={{ flexDirection: "row" }}>
                      <SearchFilter
                        customStyle={[styles.customStyle, searchBarCustomStyle]}
                        globalFilter={state.globalFilter}
                        setGlobalFilter={setGlobalFilter}
                      />
                    </div>
                  )}
                  {props.filterIcon && (
                    <div style={{ marginLeft: 10 }}>
                      <div>{props.filterIcon}</div>
                    </div>
                  )}
                </div>
                <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                  {props.refreshList && (
                    <TouchableOpacity onPress={() => refreshListHandler()}>
                      {displayRefreshButton && (
                        <div className="iconLabelContainer">
                          <div>{props.refreshList}</div>
                          <span
                            style={{
                              fontSize: 12,
                              fontFamily: FontFamily.Regular,
                              textDecoration: "underline",
                              color: colorPallete.textBlack
                            }}
                          >
                            {t("table:refreshList")}
                          </span>
                        </div>
                      )}
                    </TouchableOpacity>
                  )}
                  {props.download && (
                    <div style={{ marginLeft: 40 }}>
                      <div>{props.download}</div>
                      {textValue}
                    </div>
                  )}

                  {showPaginationField && (
                    <div>
                      <Pagination
                        customStylePaginator={styles.customStylePaginator}
                        totalCount={data.length}
                        currentPage={pageIndex + 1}
                        pageSize={pageSize}
                        previousPage={previousPage}
                        nextPage={nextPage}
                        gotoPage={gotoPage}
                      />
                    </div>
                  )}
                  {showSaveBadgeButton && (
                    <div>
                      <Button
                        type={ButtonType.Primary}
                        buttonStyles={styles.saveButton}
                        textStyles={styles.buttonFont}
                        title="Save"
                        onPress={() => saveSelectedBadgeDetailsHandler()}
                        disabled={isConflictPresentInList}
                      />
                    </div>
                  )}
                  {dateRangePicker && <div style={{ marginLeft: "1rem" }}>{dateRangePicker}</div>}
                  <div style={{ marginLeft: 45 }}>{props.addIcon}</div>
                </div>
              </div>
              {props.createSessionHeaderText && (
                <div style={{ paddingLeft: 25, marginTop: -15, marginBottom: 15 }}>
                  {" "}
                  <span
                    style={{
                      fontSize: 12,
                      fontFamily: FontFamily.Regular,
                      color: colorPallete.textLight
                    }}
                  >
                    {t("session:selectTopicForCreateSession")}
                  </span>
                </div>
              )}
              {props.filterButtons && (
                <div className="filteredButtons">
                  <div style={{}}>
                    <div>{props.filterButtons}</div>
                  </div>
                </div>
              )}
            </div>
          )}

          <div className="tableWrap">
            <table {...getTableProps()} className="table sticky">
              <thead style={{ backgroundColor: "#F8F8F8" }}>
                {headerGroups.map((headerGroup: any) => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column: any) => (
                      <th
                        className="tableHeaderStyle"
                        {...column.getHeaderProps(column.getSortByToggleProps())}
                      >
                        <div className="tableHeader">
                          {
                            <Text
                              testId="testId"
                              fontWeight={FontWeight.Bold}
                              textSize={TextSize.Small}
                              style={{
                                fontFamily: FontFamily.Medium,
                                fontSize: 12,
                                fontWeight: "700"
                              }}
                              numberOfLines={3}
                              ellipsizeMode={"tail"}
                            >
                              {column.render("Header").length > 36 ? (
                                <Tooltip
                                  styles={{}}
                                  tipContentClassName=""
                                  distance={10}
                                  direction="left"
                                  background={colorPallete.cordioBeigeLight1}
                                  color="black"
                                  padding={10}
                                  content={column.render("Header")}
                                >
                                  {column.render("Header")}
                                </Tooltip>
                              ) : (
                                <>{column.render("Header")}</>
                              )}
                            </Text>
                          }
                          {typeof column.render("Header") === "string" && (
                            <div>
                              {!column.disableSortBy ? (
                                <Icon
                                  name={IconNames.sortIcon}
                                  customStyle={{ marginLeft: 12, fontSize: 13 }}
                                />
                              ) : null}
                            </div>
                          )}
                        </div>
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()} className="body">
                {page.map((row: any, i: any) => {
                  prepareRow(row);
                  return (
                    <tr
                      {...row.getRowProps()}
                      onClick={handleRowClick ? () => handleRowClick(row.original) : null}
                    >
                      {row.cells.map((cell: any) => {
                        return (
                          <td className="updatedTableDataCol" {...cell.getCellProps()}>
                            {cell.render("Cell")}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </Styles>
      )}
    </div>
  );
}

const styles = StyleSheet.create({
  customStyle: {
    marginLeft: 0,
    marginTop: 0
  },
  customStylePaginator: {
    marginTop: 0,
    marginLeft: 40,
    alignItems: "center"
  },
  paginationContainer: {
    // flexDirection: "row"
  },
  iconStyle: {
    fontSize: 15,
    marginLeft: 45
    // marginRight: 35
  },
  acceptInviteContainer: {
    backgroundColor: colorPallete.lightGrey,
    height: 243,
    borderBottomLeftRadius: 20,
    borderBottomRightRadius: 20,
    justifyContent: "center"
  },
  noQualifications: {
    alignItems: "center",
    justifyContent: "center"
  },
  noContent: {
    fontSize: 14,
    textAlign: "center",
    fontFamily: FontFamily.Regular
  },
  button: {
    width: "140px",
    height: "50px",
    borderRadius: 10,
    marginLeft: 20,
    marginRight: 20
  },
  buttonContainer: {
    justifyContent: "center",
    alignItems: "center",
    marginTop: 20
  },
  addPrograms: {
    alignSelf: "flex-end"
  },
  saveButton: {
    width: "140px",
    height: "50px",
    borderRadius: 10,
    marginHorizontal: 15
  },
  buttonFont: {
    fontSize: 12
  }
});
