import React, { useEffect, useState } from "react";
import { Provider } from "react-redux";
import MainRouter from "@socion-cordio/web/src/navigation/mainRouter";
import store from "@socion-cordio/common/src/store/store";
import { LocalStorage } from "@socion-cordio/common/src/services/storage/storageService";
import { StoreProviderService } from "@socion-cordio/common/src/services/StoreProviderService";
import { I18nService } from "@socion-cordio/common/src/services/internalization/I18nextService";
import { SupportedLanguages } from "@socion-cordio/common/src/services/internalization/constants";
import "react-toastify/dist/ReactToastify.css";
import "rsuite/dist/styles/rsuite-default.css";
import "./App.css";
import { preLoadScriptFiles } from "@socion-cordio/common/src/services/scriptLoad/scriptloadService";

// Initialize store
StoreProviderService.init(store);
const reduxStore = StoreProviderService.getStore();

const App = () => {
  const [isLocalizationInitialised, setLocale] = useState(false);

  useEffect(() => {
    preLoadScriptFiles();
    initLang().then();
  }, []);

  const initLang = async (): Promise<void> => {
    const storedLang: SupportedLanguages = await LocalStorage.getStorage("lang");

    if (!storedLang) {
      LocalStorage.setStorage("lang", SupportedLanguages.English).then();
      I18nService.init(SupportedLanguages.English).then();
    } else {
      await I18nService.init(storedLang);
    }
    setLocale(true);
  };

  if (!isLocalizationInitialised) return null;

  return (
    <Provider store={reduxStore}>
      <React.Fragment>
        <MainRouter />
      </React.Fragment>
    </Provider>
  );
};

export default App;
