import { LocalStorage } from "@socion-cordio/common/src/services/storage/storageService";

export const getBearerToken = async (): Promise<string> => {
  let accessToken: string = await LocalStorage.getStorage("accessToken");
  if (accessToken && accessToken.length) {
    return Promise.resolve(accessToken);
  } else {
    return Promise.resolve(null);
  }
};
export const getRefreshToken = async (): Promise<string> => {
  let refreshToken: string = await LocalStorage.getStorage("refreshToken");
  if (refreshToken && refreshToken.length) {
    return Promise.resolve(refreshToken);
  } else {
    return Promise.reject("Token Not Found");
  }
};
export const setNewBearerToken = (newToken: string): Promise<void> => {
  if (newToken && newToken.length) {
    LocalStorage.setStorage("accessToken", newToken);
    return Promise.resolve();
  } else {
    return Promise.reject();
  }
};
export const setNewUser = (newUser: object): Promise<void> => {
  if (newUser) {
    LocalStorage.setStorage("user", newUser);
    const { countryCode }: any = newUser;
    LocalStorage.setStorage("countryCode", +countryCode.replace("+", ""));
    return Promise.resolve();
  } else {
    return Promise.reject();
  }
};
export const setSuperAdmin = (status: object): Promise<void> => {
  if (status) {
    LocalStorage.setStorage("isSuperAdmin", true);
    return Promise.resolve();
  } else {
    LocalStorage.setStorage("isSuperAdmin", false);
    return Promise.reject();
  }
};
