import { allRoutesNames as routeNames } from "navigation/allRouteNames";
import ArtefactList from "@socion-cordio/web/src/modules/artefacts/components/artefactList";

const ArtefactNavRoutes = () => [
  {
    path: `${routeNames.workspace}`,
    component: ArtefactList,
    isExact: false
    // permission: [Roles.Admin, Roles.AreaManager, Roles.StoreAccess]
  }
];

const ArtefactNavRouter = () => {
  return ArtefactNavRoutes();
};
export default ArtefactNavRouter;
