import React, { ReactElement, useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Profile } from "@socion-cordio/common/src/models/profile";
import { allRoutesNames as routeNames } from "navigation/allRouteNames";
import MapRoutes from "navigation/mapRoutes";
import { IState } from "@socion-cordio/common/src/store/interfaces";
import { ProfileActions } from "@socion-cordio/common/src/modules/profile/actions/actions";
import { IProfileLoading } from "@socion-cordio/common/src/modules/profile/reducers/reducer";
import { ProfileSelectors } from "@socion-cordio/common/src/modules/profile/selectors/selectors";
import { getAllowedRoutes } from "utils/routeHelper";
// import { StyleSheet, View } from "react-native";
interface IProps {
  profileData: Profile;
  loading: IProfileLoading;
  getProfile: Function;
  updateProfile: Function;
  uploadFile: Function;
  children: Array<{}>;
  fileLink: string;
}

function Index(props: IProps): ReactElement {
  // const getProfileDetails = () => {
  //   props.getProfile({ emailId: "rajat.sahu@nineleaps.com" });
  // };
  // const saveProfileDetails = (data: Profile) => {
  //   props.updateProfile(data);
  // };
  // const uploadFile = (data: FormData) => {
  //   props.uploadFile(data);
  // };
  return (
    <div>
      <MapRoutes routes={[...props.children]} basePath={`${routeNames.app}${routeNames.PROFILE}`} />
    </div>
  );
}

const mapStateToProps = (state: IState) => {
  return {
    profileData: ProfileSelectors.selectProfileData(state),
    loading: ProfileSelectors.selectLoading(state),
    fileLink: ProfileSelectors.selectFileLink(state)
  };
};
const mapDispatchToProps = (dispatch: any) => {
  return { ...bindActionCreators(ProfileActions, dispatch) };
};

export default connect(mapStateToProps, mapDispatchToProps)(Index);
