import React, { ReactElement, useEffect, useState } from "react";
import { StyleSheet, useWindowDimensions, View } from "react-native";
import { colorPallete } from "@socion-cordio/common/src/assets/styles/colors";
import {
  Text,
  TextSize,
  FontWeight,
  FontFamily
} from "@socion-cordio/common/src/components/atoms/text";
import Icon, { IconNames } from "@socion-cordio/common/src/components/atoms/icon";
import SessionSideBarPanelPage from "@socion-cordio/common/src/components/organisms/session/sessionSideBarPanelPage";
import AdditionalLinksPage from "@socion-cordio/common/src/components/organisms/session/additionalLinksPage";
import AddMembersPage from "@socion-cordio/common/src/components/organisms/session/addMembersPage";
import ViewContentPage from "@socion-cordio/common/src/components/organisms/session/viewContentPage";
import { useLocation, useHistory } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import Loader from "@socion-cordio/common/src/components/atoms/loader";
import { useDispatch, useSelector } from "react-redux";
import { SessionsActions } from "@socion-cordio/common/src/modules/session/actions/actions";
import SessionParticipantPage from "@socion-cordio/common/src/components/organisms/session/sessionParticipantPage";
import { useTranslation } from "react-i18next";
import { LocalStorage } from "@socion-cordio/common/src/services/storage/storageService";
import { allRoutesNames as routeNames } from "@socion-cordio/web/src/navigation/allRouteNames";
interface Props {
  onSubmit?: (data: { otp: number }) => void;
}

export default function ViewSessionPage(props: Props): ReactElement {
  const { t } = useTranslation();
  const { width, height } = useWindowDimensions();
  const [sessionDetails, setsessionDetails] = useState(null);
  const [failed, setFailed] = useState(false);
  const [sessionId, setSessionId] = useState(null);
  const [sessionLinks, setSessionLinks] = useState(null);
  const [sessionMembers, setSessionMembers] = useState(null);
  const [sessionDataResponse, setSessionDataResponse] = useState(null);
  const [isSessionCompleted, setIsSessionCompleted] = useState(false);
  const [currentWindowData, setCurrentWindowData] = useState(null);
  const [isMember, setIsMember] = useState(false);
  const [isLoader, setIsLoader] = useState(false);
  const dispatch = useDispatch();
  const history: any = useHistory();
  const location: any = useLocation();

  const {
    completeSessionData: sessionData,
    loading: { getCompleteSession: loading }
  } = useSelector((state: any) => state?.completeSessionData);

  useEffect(() => {
    setsessionDetails(location?.state?.sessionData);
    getCompleteSessionDetails();
    getPageUrl();
  }, []);

  useEffect(() => {
    procsessSessionData();
  }, [sessionData?.response?.sessionLinks]);

  useEffect(() => {
    checkIsMember(sessionMembers);
  }, [sessionMembers]);

  useEffect(() => {
    setCurrentWindowData(location);
  }, [location]);

  const checkIsMember = async (data: any) => {
    const user: any = await LocalStorage.getStorage("user");
    const userId = user?.userId;
    let dataArray: any = [];
    data?.map((i: any) => {
      dataArray.push(i.userId);
    });
    if (dataArray.includes(userId)) {
      setIsMember(true);
    } else setIsMember(false);
  };

  const getPageUrl = () => {
    const currentUrl = window.location.href;
    if (currentUrl.includes("completed")) {
      setIsSessionCompleted(true);
    } else {
      setIsSessionCompleted(false);
    }
  };

  const getCompleteSessionDetails = async () => {
    try {
      const payload = {
        sessionId: location?.state?.sessionData?.session?.id
      };
      setSessionId(location?.state?.sessionData?.session?.id);
      dispatch(SessionsActions.getCompleteSession(payload));
    } catch (error) {
      toast.error("Something went wrong.");
      setFailed(true);
    }
  };

  const procsessSessionData = () => {
    if (sessionData?.responseCode === 200) {
      setSessionLinks(sessionData?.response?.sessionLinks);
      setSessionMembers(sessionData?.response?.members);
      setSessionDataResponse(sessionData?.response);
    }
    // else {
    //   toast.error("Something went wrong. 11");
    // }
    // console.log("sessionData?.responseCode",sessionData?.responseCode);
  };

  const navigateHandler = () => {
    if (window.location.href.includes("completed")) {
      history.push(`${routeNames.app}${routeNames.SESSION}`, {
        locationData: currentWindowData
      });
    } else if (window.location.href.includes("upcoming")) {
      history.push(`${routeNames.app}${routeNames.SESSION}`, {
        locationData: currentWindowData
      });
    } else {
      if (currentWindowData.state.completed === false) {
        history.push(`${routeNames.app}${routeNames.SESSION}`, {
          locationData: currentWindowData
        });
      } else {
        history.goBack();
        setCurrentWindowData(null);
      }
    }
  };

  return (
    <View>
      {loading || isLoader ? (
        <Loader />
      ) : failed ? (
        <View>Failed to load data</View>
      ) : (
        <View style={styles.container}>
          <View style={styles.rightContainer}>
            <View style={styles.subContainer}>
              <View style={styles.subContainerOne}>
                <View style={styles.headerContainer}>
                  <View style={styles.iconContainerHeader}>
                    <Icon
                      testID="down"
                      name={IconNames.down}
                      customStyle={styles.titleIcon}
                      onPress={() => {
                        navigateHandler();
                      }}
                    />
                  </View>
                  <View>
                    <Text
                      fontWeight={FontWeight.Light}
                      testId="text"
                      textSize={TextSize.Small}
                      textStyle={[styles.headerText, styles.headerTextTitleSupport]}
                    >
                      {sessionDetails?.session?.programName}
                    </Text>
                  </View>
                  <View>
                    <Text
                      onPress={() => {}}
                      fontWeight={FontWeight.Regular}
                      testId="text"
                      textSize={TextSize.Small}
                      textStyle={[styles.headerText, { marginLeft: 10, marginRight: 0 }]}
                    >
                      {">"}
                    </Text>
                  </View>
                  <View>
                    <Text
                      onPress={() => {}}
                      fontWeight={FontWeight.Regular}
                      testId="text"
                      textSize={TextSize.Small}
                      textStyle={[styles.headerText, styles.headerTextSupport]}
                    >
                      {t("session:viewSessionDetails")}
                    </Text>
                  </View>
                </View>
                <View>
                  <SessionParticipantPage sessionDetails={sessionDataResponse} />
                </View>
              </View>
            </View>
            <View style={styles.containerCollapse}>
              <View style={styles.collapseContainer}>
                <AdditionalLinksPage
                  sessionLinks={sessionLinks}
                  sessionId={sessionId}
                  sessionDetails={sessionDataResponse}
                  isSessionCompleted={isSessionCompleted}
                />
              </View>
              <View
                style={
                  width > 960 ? styles.memberViewCollapseContainer : { flexDirection: "column" }
                }
              >
                <View style={[styles.collapseContainer, { flex: 1, marginRight: 5 }]}>
                  <AddMembersPage
                    sessionDetails={sessionData?.response}
                    sessionMembers={sessionMembers}
                    sessionId={sessionId}
                    isSessionCompleted={isSessionCompleted}
                    getCompleteSessionDetails={getCompleteSessionDetails}
                    setIsLoader={setIsLoader}
                  />
                </View>

                <View style={[styles.collapseContainer, { flex: 1, marginLeft: 5 }]}>
                  <ViewContentPage
                    sessionDetails={sessionData?.response}
                    isSessionCompleted={isSessionCompleted}
                  />
                </View>
              </View>
            </View>
          </View>
          <View style={styles.leftContainer}>
            <View style={{ marginTop: 60, minWidth: "100%" }}>
              <SessionSideBarPanelPage
                sessionData={sessionData?.response}
                sessionId={sessionId}
                isSessionCompleted={isSessionCompleted}
                isMember={isMember}
              />
            </View>
          </View>
        </View>
      )}{" "}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    // position: "absolute",
    width: "100%",
    height: "100%"
  },
  rightContainer: {
    display: "flex",
    padding: 20,
    paddingTop: 15,
    backgroundColor: "#F8F5F0",
    // flex: 3.4
    // flex: 1,

    // new
    width: "calc(100% - 240px)"
  },
  leftContainer: {
    // position:'fixed',
    // position:'absolute',
    // top:0,
    // right:0,
    marginTop: -60,
    // flex: 1,
    minHeight: "100vh",
    backgroundColor: colorPallete.cordioRedDark2,

    //new
    position: "relative",
    top: 0,
    right: 0
  },
  header: {
    display: "flex",
    flexDirection: "row"
  },
  iconContainer: {
    marginTop: 10
  },
  titleIcon: {
    color: colorPallete.textBlack,
    fontSize: 9,
    transform: [{ rotate: "90deg" }]
  },
  headerText: {
    fontSize: 12,
    fontFamily: FontFamily.Medium,
    lineHeight: 17
  },
  subContainer: {
    backgroundColor: colorPallete.white,
    borderRadius: 10,
    shadowColor: colorPallete.cordioRedDark1,
    shadowOpacity: 0.1,
    shadowOffset: {
      height: 10,
      width: 5
    },
    shadowRadius: 10
  },
  containerStyle: {
    width: "30%",
    marginLeft: 15
  },
  dropDownContainerStyle: {
    borderWidth: 0,
    borderTopRightRadius: 5,
    borderBottomRightRadius: 5
  },
  subContainerOne: {},

  //Header Container
  headerTextTitleSupport: {
    fontWeight: "700"
  },
  headerContainer: {
    flexDirection: "row",
    // marginBottom: 20,
    alignItems: "center",
    padding: 18
  },
  headerTextSupport: {
    // Due to font size, increasing the size.
    fontFamily: FontFamily.Regular,
    marginLeft: 10
  },
  iconContainerHeader: {
    marginRight: 20
  },

  // collapse Container
  containerCollapse: {
    marginTop: 30,
    flexDirection: "column"
  },
  memberViewCollapseContainer: {
    flexDirection: "row"
  },
  collapseContainer: {
    marginBottom: 20
  }
});
