import { call, put, takeLatest } from "redux-saga/effects";
import { TOPICS } from "@socion-cordio/common/src/modules/topic/actions/actionTypes";
import { TopicsActions } from "@socion-cordio/common/src/modules/topic/actions/actions";
import { TopicRepository } from "@socion-cordio/common/src/repositories/topic/topic";
import { Topic } from "@socion-cordio/common/src/models/topic";
import { IFluxStandardAction } from "store/interfaces";

function* getTopicsWorkerSaga(value: IFluxStandardAction<{ programId: number }>) {
  try {
    const { payload } = value;
    const response: Topic = yield call(TopicRepository.getTopics, payload);
    yield put(TopicsActions.getTopicsSuccess(response));
  } catch (error) {
    yield put(TopicsActions.getTopicsError(error));
  }
}

export default function* TopicsWatcherSaga() {
  yield takeLatest(TOPICS.GET_TOPICS.LOADING, getTopicsWorkerSaga);
}
