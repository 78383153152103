import React from "react";
import usePagination from "@socion-cordio/common/src/utils/usePagination";
import { View, StyleSheet, TouchableOpacity } from "react-native";
import {
  Text,
  FontFamily,
  FontWeight,
  TextSize
} from "@socion-cordio/common/src/components/atoms/text";
import { colorPallete } from "@socion-cordio/common/src/assets/styles/colors";
import Icon, { IconNames } from "@socion-cordio/common/src/components/atoms/icon";

const Pagination = (props: any) => {
  const {
    totalCount,
    siblingCount = 1,
    currentPage,
    pageSize,
    previousPage,
    nextPage,
    gotoPage,
    customStylePaginator
  } = props;

  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize
  });

  if (currentPage === 0 || paginationRange.length < 2) {
    return null;
  }

  const onNext = () => {
    nextPage();
  };

  const onPrevious = () => {
    previousPage();
  };

  let lastPage = paginationRange[paginationRange.length - 1];

  return (
    <View style={[styles.paginationContainer, customStylePaginator]}>
      <Text
        fontWeight={FontWeight.Light}
        testId="entity"
        textSize={TextSize.Small}
        textStyle={[styles.headerText, styles.headerAlignment]}
        style={[styles.paginationItem, styles.button, currentPage === 1 ? styles.disabled : null]}
        onPress={onPrevious}
      >
        <TouchableOpacity>
          <Icon
            testID="down"
            name={IconNames.leftArrow}
            customStyle={
              currentPage === 1 ? [styles.iconStyle, styles.iconStyleDisabled] : styles.iconStyle
            }
            onPress={onPrevious}
          />
        </TouchableOpacity>
      </Text>
      <View style={styles.paginationRange}>
        {paginationRange.map((pageNumber: any) => {
          return (
            <Text
              key={pageNumber}
              fontWeight={FontWeight.Light}
              testId="entity"
              textSize={TextSize.Small}
              textStyle={[
                styles.paginationItem,
                pageNumber === currentPage ? styles.selected : { color: colorPallete.textLight },
                styles.headerText
              ]}
              onPress={() => gotoPage(pageNumber - 1)}
            >
              {pageNumber}
            </Text>
          );
        })}
      </View>
      <Text
        fontWeight={null}
        testId="entity"
        textSize={TextSize.Small}
        textStyle={[
          styles.paginationItem,
          styles.button,
          currentPage === lastPage ? styles.disabled : null
        ]}
        onPress={onNext}
      >
        <TouchableOpacity>
          <Icon
            testID="down"
            name={IconNames.rightArrow}
            customStyle={
              currentPage === lastPage
                ? [styles.iconStyle, styles.iconStyleDisabled]
                : styles.iconStyle
            }
            onPress={onNext}
          />
        </TouchableOpacity>
      </Text>
    </View>
  );
};

const styles = StyleSheet.create({
  paginationContainer: {
    display: "flex",
    flexDirection: "row",
    marginTop: 20
  },
  button: {
    fontWeight: "bold",
    fontSize: 22
  },
  disabled: {
    // pointerEvents: 'none'
  },
  paginationItem: {
    padding: 7,
    borderRadius: 5,
    height: 16,
    textAlign: "center",
    color: "rgba(0, 0, 0, 0.87)",
    display: "flex",
    boxSizing: "border-box",
    alignItems: "center",
    letterSpacing: 0.01071,
    lineHeight: 1.43,
    fontSize: 12
  },
  selected: {
    backgroundColor: "	hsl(0, 0%, 97%))"
  },
  paginationRange: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: "#F8F8F8",
    borderRadius: 5
  },
  headerText: {
    fontSize: 12,
    fontFamily: FontFamily.Regular,
    lineHeight: 17
  },
  blackTextColor: {
    color: colorPallete.textBlack
  },
  headerAlignment: {
    color: colorPallete.textLight,
    fontFamily: FontFamily.Regular
  },
  iconStyle: {
    fontSize: 16.5
  },
  iconStyleDisabled: {
    color: colorPallete.textLight
  }
});

export default Pagination;
