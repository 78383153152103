import React, { useEffect, useState } from "react";
import { Route, Redirect } from "react-router-dom";
import { LocalStorage } from "@socion-cordio/common/src/services/storage/storageService";
import { allRoutesNames as routeNames } from "navigation/allRouteNames";
import { ApiClient } from "@socion-cordio/common/src/network/apiClient";
import { profileEndPoints } from "@socion-cordio/common/src/repositories/endPoints";
import Loader from "@socion-cordio/common/src/components/atoms/loader";
import { StyleSheet } from "react-native";

const AuthenticatedRoute = ({ component: Component, ...rest }: any) => {
  const [isLoader, setLoader] = useState(true);
  const [isQueryParamsChanged, setQueryParams] = useState(false);
  useEffect(() => {
    getProfileData();
  }, []);

  const getProfileData = async () => {
    const search = location.search;
    setLoader(true);
    const accessToken = new URLSearchParams(search).get("accessToken");
    if (accessToken) {
      await LocalStorage.setStorage("accessToken", accessToken);
      setQueryParams(true);
    }
    const localAccessToken = await LocalStorage.getStorage("accessToken");
    if (localAccessToken) {
      const response = await ApiClient.get(profileEndPoints.getUserProfile());
      console.log("response", response);
      if (response.response) {
        LocalStorage.setStorage("user", response.response);
      }
    }
    setLoader(false);
  };

  if (isLoader) {
    return <Loader customLoadingContainer={styles.loader} />;
  }
  if (isQueryParamsChanged && !isLoader) {
    return (
      <Route
        {...rest}
        render={(props) => {
          //TODO - don't use localStorage
          const token: any = localStorage.getItem("accessToken");
          const user = JSON.parse(localStorage.getItem("user"));
          if (token && user) {
            return <Component {...props} user={user} />;
          } else {
            LocalStorage.clearStorage();
            return (
              <Redirect
                to={{
                  pathname: routeNames.HOME,
                  state: {
                    from: props.location
                  }
                }}
              />
            );
          }
        }}
      />
    );
  }
  if (!isQueryParamsChanged && !isLoader) {
    return (
      <Route
        {...rest}
        render={(props) => {
          //TODO - don't use localStorage
          const token: any = localStorage.getItem("accessToken");
          const user = JSON.parse(localStorage.getItem("user"));
          if (token && user) {
            return <Component {...props} user={user} />;
          } else if (isQueryParamsChanged) {
            return <Component {...props} user={user} />;
          } else {
            LocalStorage.clearStorage();
            return (
              <Redirect
                to={{
                  pathname: routeNames.HOME,
                  state: {
                    from: props.location
                  }
                }}
              />
            );
          }
        }}
      />
    );
  }
};

const styles = StyleSheet.create({
  loader: {
    marginTop: 30,
    width: "10%",
    height: 20,
    transform: [{ scale: 0.8 }]
  }
});
export default AuthenticatedRoute;
