import React, { ReactElement, useEffect, useState } from "react";
import { StyleSheet, View } from "react-native";
import { colorPallete } from "@socion-cordio/common/src/assets/styles/colors";
import { FontFamily } from "@socion-cordio/common/src/components/atoms/text";
import QualificationPackTable from "@socion-cordio/common/src/components/organisms/qualification-packs/qualificationPackTable";
import { useSelector, useDispatch } from "react-redux";

interface Props {}

export default function QualificationPackPage(props: Props): ReactElement {
  const programDetails: any = JSON.parse(localStorage.getItem("programDetails"));
  const userRolesData: any = useSelector((state: any) => state?.userRolesProgram);
  const [memberRole, setMemberRoles] = useState([]);

  let userRolesProgram: any = useSelector((state: any) => state?.userRolesProgram);

  useEffect(() => {
    const memberRole = userRolesProgram?.userRolesData?.response;
    setMemberRoles(memberRole);
  }, []);

  return (
    <View style={styles.container}>
      <View style={styles.subContainer}>
        <View>
          <QualificationPackTable programDetails={programDetails} userRoles={memberRole} />
        </View>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    display: "flex",
    padding: 20,
    paddingTop: 15,
    backgroundColor: "#F8F5F0",
    minHeight: "calc(100vh - 50px)"
  },
  subContainer: {
    backgroundColor: colorPallete.white,
    borderRadius: 10,
    shadowColor: colorPallete.cordioRedDark1,
    shadowOpacity: 0.1,
    shadowOffset: {
      height: 10,
      width: 5
    },
    shadowRadius: 10
  }
});
