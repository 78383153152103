import Icon, { IconNames } from "@socion-cordio/common/src/components/atoms/icon";
import {
  Text,
  TextSize,
  FontWeight,
  FontFamily
} from "@socion-cordio/common/src/components/atoms/text";
import TextField from "@socion-cordio/common/src/components/atoms/textField";
import { colorPallete } from "@socion-cordio/common/src/assets/styles/colors";
import React, { ReactElement } from "react";
import { View, StyleSheet, StyleProp, ImageStyle, ViewStyle } from "react-native";

interface IProps {
  icon?: IconNames;
  label?: string;
  textValue?: string;
  name?: string;
  placeholder?: string;
  value?: string;
  id?: string;
  handleChange?: Function;
  handleBlur?: Function;
  handleKeyPress?: Function;
  userStyle?: any;
  userStyleText?: any;
  noFormik?: boolean;
  editable?: boolean;
  titleInputContainerStyles?: StyleProp<ViewStyle>;
  textInputContainerStyles?: any;
  // isSecure?: boolean;
}

export default function UserInputSecondary(props: IProps): ReactElement {
  const {
    icon,
    label,
    placeholder,
    textValue,
    name,
    value,
    id,
    handleChange,
    handleBlur,
    userStyle,
    handleKeyPress,
    userStyleText,
    noFormik,
    editable,
    titleInputContainerStyles,
    textInputContainerStyles
    // isSecure
  } = props;

  return (
    <View style={[styles.titleInputContainer, titleInputContainerStyles]}>
      <Text
        fontWeight={FontWeight.Regular}
        testId="internal"
        textSize={TextSize.Small}
        textStyle={[styles.subheaderText, styles.alignHeaderText, userStyleText]}
      >
        {textValue}
      </Text>
      <View
        style={[
          styles.textFieldContianer,
          styles.textFieldContianerAlignment,
          textInputContainerStyles
        ]}
      >
        <TextField
          label=""
          handleChange={handleChange}
          handleBlur={handleBlur}
          value={value}
          inputStyle={[styles.inputStyling, styles.inputStylingAlignment, userStyle]}
          placeholder={placeholder}
          name={name}
          id={id}
          noFormik={noFormik}
          editable={editable}
          // handleKeyPress={(e: any) => {
          //   if (e.key === "Enter") {
          //     formikProps.handleSubmit();
          //   }
          // }}
        />
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  titleInputContainer: {
    display: "flex",
    flexDirection: "row",
    flex: 100,
    marginTop: 10
  },
  subheaderText: {
    // flex: 12,
    fontWeight: "400",
    fontSize: 14,
    fontFamily: FontFamily.Regular,
    lineHeight: 17,
    color: colorPallete.textLight,
    marginTop: 13
  },
  alignHeaderText: {
    flex: 20
  },
  textFieldContianer: {
    // marginLeft: 15,
    borderColor: colorPallete.cordioTaupe
  },
  textFieldContianerAlignment: {
    flex: 80
  },
  inputStyling: {
    height: 40,
    // outline: "none",
    borderRadius: 5,
    padding: 7,
    fontSize: 14,
    fontWeight: FontWeight.Light,
    fontFamily: FontFamily.Medium
  },
  inputStylingAlignment: {
    width: "35%"
  }
});
