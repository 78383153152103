import Icon, { IconNames } from "@socion-cordio/common/src/components/atoms/icon";
import {
  Text,
  TextSize,
  FontWeight,
  FontFamily
} from "@socion-cordio/common/src/components/atoms/text";
import TextField from "@socion-cordio/common/src/components/atoms/textField";
import { colorPallete } from "@socion-cordio/common/src/assets/styles/colors";
import React, { createRef, ReactElement, useEffect, useRef, useState } from "react";
// import { country } from "@socion-cordio/common/src/components/atoms/constants";
import { ApiClient } from "@socion-cordio/common/src/network/apiClient";
import { loginEndPoints } from "@socion-cordio/common/src/repositories/endPoints";
import ActionSheet from "react-native-actionsheet";

import {
  View,
  StyleSheet,
  Animated,
  ImageSourcePropType,
  ImageResizeMode,
  StyleProp,
  ImageStyle,
  ViewStyle,
  TextStyle,
  TouchableOpacity
} from "react-native";
import Dropdown from "@socion-cordio/common/src/components/molecules/dropdown";
import _ from "lodash";
import { number } from "yup";

interface IProps {
  icon?: IconNames;
  label?: string;
  textValue?: string;
  name?: string;
  placeholder?: string;
  value?: any;
  dropdownValue?: any;
  id?: string;
  handleKeyPress?: Function;
  inputStyle?: StyleProp<ViewStyle>;
  handleChange?: Function;
  handleBlur?: Function;
  errorStyle?: StyleProp<TextStyle>;
  onChangeValue?: (value: any) => void;
  noFormik?: boolean;
  sequence?: any;
  editable?: boolean;
  keyboardType?: string;
  formikPropsTouched?: boolean;
  customChange?: Function;
  secondaryTextValue?: string;
  secondaryIcon?: IconNames;
  emailField?: boolean;
  countryCode?: string;
}

const styles = StyleSheet.create({
  textIcon: {
    color: colorPallete.black,
    fontSize: 14,
    fontFamily: "Montserrat-Bold",
    marginLeft: 7,
    marginTop: 1
  },
  input: {
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    borderTopRightRadius: 5,
    borderBottomRightRadius: 5,
    width: 233,
    height: 40,
    // outline: "none",
    borderColor: colorPallete.lightGreyTwo
    // opacity: 0.4
  },
  label: {
    flexDirection: "row",
    marginBottom: 10
  },
  icon: {},
  inputContainer: {
    flexDirection: "row",
    marginLeft: 0
  }
});

export default function EmailMobileInput(props: IProps): ReactElement {
  const {
    icon,
    label,
    placeholder,
    textValue,
    name,
    value,
    id,
    handleBlur,
    handleChange,
    handleKeyPress,
    noFormik,
    editable,
    keyboardType,
    formikPropsTouched,
    customChange,
    secondaryTextValue,
    secondaryIcon,
    emailField,
    errorStyle = { start: emailField ? 0 : -70 },
    countryCode,
    onChangeValue
  } = props;

  useEffect(() => {
    onChangeValue(countryCode);
  }, [countryCode]);

  return (
    <View pointerEvents={editable ? "none" : null}>
      {icon && textValue && (
        <View style={styles.label}>
          {!value ? (
            <>
              <Icon testID="home" name={icon} customStyle={styles.icon} />
              <Text
                fontWeight={FontWeight.Regular}
                testId="internal"
                textSize={TextSize.Small}
                style={styles.textIcon}
              >
                {textValue}
              </Text>
              <Text
                fontWeight={FontWeight.Regular}
                testId="internal"
                textSize={TextSize.Small}
                style={[styles.textIcon, { marginRight: 10, marginLeft: 10 }]}
              >
                /
              </Text>
              <Icon testID="home" name={secondaryIcon} customStyle={styles.icon} />
              <Text
                fontWeight={FontWeight.Regular}
                testId="internal"
                textSize={TextSize.Small}
                style={styles.textIcon}
              >
                {secondaryTextValue}
              </Text>
            </>
          ) : !emailField ? (
            <>
              <Icon testID="home" name={icon} customStyle={styles.icon} />
              <Text
                fontWeight={FontWeight.Regular}
                testId="internal"
                textSize={TextSize.Small}
                style={styles.textIcon}
              >
                {textValue}
              </Text>
            </>
          ) : (
            <>
              <Icon testID="home" name={secondaryIcon} customStyle={styles.icon} />
              <Text
                fontWeight={FontWeight.Regular}
                testId="internal"
                textSize={TextSize.Small}
                style={styles.textIcon}
              >
                {secondaryTextValue}
              </Text>
            </>
          )}
        </View>
      )}

      <View style={styles.inputContainer}>
        {!emailField && (
          <TouchableOpacity
            style={{
              borderWidth: 1,
              borderRightWidth: 0,
              width: 70,
              height: 40,
              borderTopLeftRadius: 5,
              borderBottomLeftRadius: 5,
              justifyContent: "center",
              alignItems: "center",
              borderColor: colorPallete.cordioTaupeLight3,
              flexDirection: "row"
            }}
            disabled={true}
          >
            <Text
              fontWeight={FontWeight.Regular}
              testId="internal"
              textSize={TextSize.Small}
              style={[styles.textIcon, { fontFamily: "Montserrat-Regular", marginLeft: 0 }]}
            >
              {props.countryCode}
            </Text>
          </TouchableOpacity>
        )}
        <TextField
          inputStyle={[
            styles.input,
            {
              borderTopLeftRadius: emailField ? 5 : 0,
              borderBottomLeftRadius: emailField ? 5 : 0,
              width: emailField ? 304 : 233
            }
          ]}
          name={name}
          placeholder={placeholder}
          label={label}
          value={value}
          id={id}
          handleChange={handleChange}
          handleBlur={handleBlur}
          handleKeyPress={handleKeyPress}
          errorStyle={errorStyle}
          noFormik={noFormik}
          keyboardType={keyboardType}
          formikPropsTouched={formikPropsTouched}
          customChange={customChange}
          isNumeric={emailField ? false : true}
          // onChange={onChange}
        ></TextField>
      </View>
    </View>
  );
}
