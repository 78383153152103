import { colorPallete } from "@socion-cordio/common/src/assets/styles/colors";
import {
  FontFamily,
  FontWeight,
  Text,
  TextSize
} from "@socion-cordio/common/src/components/atoms/text";
import Dropdown from "@socion-cordio/common/src/components/molecules/dropdown";
import { ArtefactRepository } from "@socion-cordio/common/src/repositories/artefact/artefact";
import { dataHelper } from "@socion-cordio/common/src/utils/dataHelper";
import { compact, find, get, isEmpty, omit, orderBy, reject, replace, uniqBy } from "lodash";
import React, { useEffect, useState } from "react";
import { StyleSheet, TouchableOpacity, View } from "react-native";
import { useHistory, useLocation } from "react-router-dom";
import styled from "styled-components";
import ArtefactDocs from "./artefactDocs";
import Submitters from "./submitters";
import Workflow from "./workflow";
import { toast } from "react-toastify";
import MapLocation from "@socion-cordio/common/src/components/organisms/mapLocation";
import {
  addNotification,
  addWorkflow,
  getJoinedLocation,
  getLocation,
  STATUS
} from "../helpers/artefactHelper";
import AddSubmitters from "./addSubmitter";
import Loader from "@socion-cordio/common/src/components/atoms/loader";
import TextField from "@socion-cordio/common/src/components/atoms/textField";
import AddArtefactDocs from "./addArtefactDocs";
import DatePicker from "@socion-cordio/common/src/components/molecules/datePicker";
import { Image } from "@socion-cordio/common/src/components/atoms/image";
import ArtefactButtons from "./artefactButtons";
import { LocalStorage } from "@socion-cordio/common/src/services/storage/storageService";
import SocionModal from "@socion-cordio/common/src/components/atoms/modal";
import ConfirmModal from "@socion-cordio/common/src/components/organisms/confirmModal";
import { canAccessAll, canOnlyView, isVerifier } from "utils/artefactActionsHelper";
import {
  addArtefactDocEvent,
  deleteArtefactDocEvent,
  removeSubmitterEvent,
  updateArtefactEvent
} from "../helpers/telemetryEvents";
import { artefact_messages } from "../data/message";
import { allRoutesNames } from "navigation/allRouteNames";
import { NotificationsActions } from "@socion-cordio/common/src/modules/notifications/actions/action";
import { useDispatch } from "react-redux";
import { cancelUpload, startUploadDoc } from "../helpers/docHelper";
import DocUploadProgress from "./docUploadProgress";

const EditArtefactWrapper = styled.div`
  border-radius: 5px;
  margin: 20px;
  padding: 20px;
  border: 1px solid rgba(63, 162, 2, 0.6);
  min-height: calc(100vh - 35vh);
  position: relative;
  .close {
    position: absolute;
    right: 20px;
    top: 20px;
  }
  .EditArtefactDetailsWrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    background: "#ffffff";

    .EditArtefactRightBorder {
      border-right: 1px solid rgba(0, 0, 0, 0.12);
      width: 60%;
    }
    .formGroup {
      padding: 8px 0;
      display: flex;
      flex-direction: row;
      width: 100%;
      .title {
        width: 25%;
      }
      .submittedDateTitle {
        width: 40%;
      }
      .submittedDateField {
        width: 60%;
      }
      .field {
        width: 80%;
        padding: 0 25px;
      }
    }

    .addSubmittersWrapper {
      width: 40%;
      padding: 10px 15px;
      margin-bottom: 5px;
      .submitterImg {
        padding-right: 20px;
        img {
          margin: 0 0;
        }
      }
    }

    .submittersWrapper {
      border: 1px solid #675850;
      border-radius: 10px;
      padding: 10px 15px;
      display: flex;
      align-items: center;
      margin-bottom: 5px;
      .submitterImg {
        padding-right: 20px;
        img {
          margin: 0 0;
        }
      }
    }
  }

  .tagBtnWrapper {
    display: flex;
    align-items: center;
    padding-top: 14px;
    .tagBtn {
      border: 1px solid #b7504a;
      border-radius: 40px;
      font-family: Montserrat-Regular;
      //font-weight: 400;
      font-size: 14px;
      padding: 6px 12px;
      height: 30px;
      margin-right: 12px;
      display: flex;
      align-items: center;
      img {
        margin: 0 0;
        margin-left: 8px;
      }
    }
  }
  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black background */
    z-index: 9999; /* Ensure it's on top of everything */
  }
  .uploadDoc {
    top: 29rem;
    width: 85.5%;
    left: 102px;
  }
  @media (min-width: 767px) and (max-width: 900px) {
  }
  @media (max-width: 767px) {
    .EditArtefactDetailsWrapper {
      grid-template-columns: 1fr;
      grid-gap: 20px;
      overflow: auto;
      .EditArtefactRightBorder {
        border-right: 0px;
      }
    }
    .tagBtnWrapper {
      display: block;
      .tagBtn {
        margin-right: 0px;
        margin-bottom: 12px;
        width: fit-content;
      }
    }
  }

  @media (min-width: 1450) {
    .buttons {
      width: 20%;
    }
  }
`;

const EditArtefact = () => {
  const location = useLocation();
  const history = useHistory();
  const programData: any = JSON.parse(localStorage.getItem("programDetails"));
  const user = JSON.parse(localStorage.getItem("user"));
  const [artefactMetaData, setArtefactMetaData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const [artefactWorkFlow, setArtefactWorkFlow] = useState([]);
  const [artefactDocs, setArtefactDocs] = useState([]);
  const [open, setOpen] = useState(null);
  const [val, setVal] = useState(null);
  const [items, setItems] = useState(undefined);
  const [types, setTypes] = useState([]);
  const [updatedLocation, setUpdatedLocation] = useState(null);
  const [artefactName, setArtefactName] = useState("");
  const [exSubmitters, setExSubmitters] = useState([]);
  const [newSubmitters, setNewSubmitters] = useState([]);
  const [leftSidePanelCollapse, setLeftSidePanelCollapse] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const [isFormUpdated, setIsFormUpdated] = useState(false);
  const [artefectName, setArtefectName] = useState(null);
  const [submittedDate, setSubmittedDate] = useState(null);
  const [uploadedDocs, setUploadedDocs] = useState([]);
  const [updatedFields, setUpdatedFields] = useState(null);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [confirmMsg, setConfirmMsg] = useState(null);
  const [deleteType, setDeleteType] = useState(null);
  const [selectedDoc, setSelectedDoc] = useState(null);
  const [selectedSubmitter, setSelectedSubmitter] = useState(null);
  const [showTypeModal, setShowTypeModal] = useState(false);
  const [selectedType, setSelectedType] = useState(null);
  const [isClearLocation, setIsClearLocation] = useState(null);
  const [showProgress, setShowProgress] = useState(null);
  const [disableBtn, setDisableBtn] = useState(null);
  const [showOverlay, setShowOverlay] = useState(false);
  const [isUploadCancelled, setIsUploadCancelled] = useState(null);

  const dispatch = useDispatch();

  const leftNavCollapseHandler = async () => {
    let isSidePanelCollapsed: boolean = await LocalStorage.getStorage("isSidePanelCollapsed");
    setLeftSidePanelCollapse(isSidePanelCollapsed);
  };

  useEffect(() => {
    leftNavCollapseHandler();
  });

  useEffect(() => {
    setIsLoading(true);
    const artefactId = location.pathname.split("/").pop();
    fetchArtefactDetails(artefactId);
    fetchArtefactDocs(artefactId);
    fetchArtefactWorkFlow(artefactId);
  }, []);

  useEffect(() => {
    if (!isEmpty(selectedType) && !isEmpty(updatedLocation)) {
      search();
    }
  }, [selectedType, updatedLocation]);

  useEffect(() => {
    if (!isEmpty(updatedFields)) {
      const type = updatedFields?.artefact_type_id || val;

      const date = submittedDate === "Invalid date" ? false : submittedDate;

      if (isEmpty(updatedLocation) || !artefactName || !date || !type) {
        setIsFormUpdated(false);
      } else {
        setIsFormUpdated(true);
      }
    }
  }, [updatedFields]);

  const search = async () => {
    const request: any = {
      pid: programData?.program?.id,
      // country: updatedLocation?.country,
      // state: updatedLocation?.state,
      // district: updatedLocation?.district,
      // city: updatedLocation?.city,
      location: updatedLocation?.location,
      artefact_type_id: selectedType?.id
    };

    if (!canAccessAll() && !canOnlyView() && !isVerifier()) {
      request.userId = user?.userid || user?.userId;
    }

    const res: any = await ArtefactRepository.getArtefacts(request);
    if (res?.data && res?.data.length > 0) {
      setShowTypeModal(true);
      setIsClearLocation(false);
    }
  };

  const fetchArtefactDetails = async (artefactId: string) => {
    const data = await ArtefactRepository.getArtefactDetails(artefactId).catch((error) => {
      toast.error(replace(artefact_messages.error.m017, "{name}", `"${artefactName}"`));
    });
    if (!isEmpty(data?.data)) {
      const artefact: any = data?.data;
      setArtefactMetaData(artefact);
      setArtefectName(artefact?.artefact_name);
      const subDate = artefact?.submitted_date
        ? dataHelper.formatDate(artefact.submitted_date, "YYYY-MM-DD")
        : artefact.created_at
        ? dataHelper.formatDate(artefact.created_at, "YYYY-MM-DD")
        : "NA";
      setSubmittedDate(subDate);
      setUpdatedLocation(getLocation(artefact));
      setExSubmitters(artefact?.submitters);
      setArtefactName(artefact?.artefact_name);
      setVal(artefact?.artefact_type_id);
    }
    fetchArtefactTypes(data?.data?.artefact_type_id);

    setIsLoading(false);
  };

  const fetchArtefactTypes = async (typeId: string) => {
    const pid = programData?.program?.id;
    const filterData = await ArtefactRepository.getArtefactTypes({ pid });
    const typeList = filterData?.data?.type || [];
    const list: any = typeList.map((data: any, id: any) => {
      if ((data?.id !== typeId && data?.active === "1") || data?.id === typeId) {
        return data?.active === "0"
          ? {
              label: data?.artefact_type_title,
              value: data?.id,
              disabled: data?.active === "0",
              labelStyle: { opacity: "0.5" }
            }
          : { label: data?.artefact_type_title, value: data?.id, disabled: data?.active === "0" };
      }
    });

    const type = typeList.map((data: any, id: any) => {
      if ((data?.id !== typeId && data?.active === "1") || data?.id === typeId) {
        return data;
      }
    });
    setTypes(compact(type));
    setItems(compact(list));
  };

  const fetchArtefactDocs = (artefactId: string) => {
    ArtefactRepository.getArtefactDocs(artefactId)
      .then((response) => {
        return response;
      })
      .then((data) => {
        setArtefactDocs(data?.data);
      });
  };

  const fetchArtefactWorkFlow = (artefactId: string) => {
    ArtefactRepository.getArtefactWorkFlow(artefactId)
      .then((response) => {
        return response;
      })
      .then((data) => {
        const workflowData = orderBy(data.data, ["actedon"], ["desc"]);

        setArtefactWorkFlow(workflowData);
      });
  };

  const getDate = (date: any) => {
    const datee =
      dataHelper.formatDate(date, "YYYY-MM-DD") ||
      dataHelper.formatDate(`${new Date()}`, "YYYY-MM-DD");
    return datee;
  };

  const handleSubmit = async (type: any) => {
    setDisableBtn(true);
    setIsFormUpdated(false);
    if (type.toLowerCase() === "cancel") {
      history.push(`${allRoutesNames.app}${allRoutesNames.workspace}`);
      return;
    }

    let request = {
      ...updatedFields,
      updated_by: user?.userId,
      status: STATUS.submitted
    };

    if (type.toLowerCase() === "deletesubmitter") {
      const userId = selectedSubmitter?.submitter?.userId ?? selectedSubmitter?.submitter?.userid;
      const updatedSubList = reject(exSubmitters, { userid: userId });
      request.submitter_userid = { submitterList: updatedSubList };
    }

    if (request?.subDistrict || request?.subdistrict || request?.sub_district) {
      request.sub_district = request?.subDistrict || request?.subdistrict || request?.sub_district;
    }

    request = omit(request, "docs", "subDistrict");

    const docs = updatedFields?.docs || [];

    let existingSub = exSubmitters;
    if (!isEmpty(request.submitters) && type.toLowerCase() !== "deletesubmitter") {
      existingSub = existingSub.concat(request.submitters);
      request.submitter_userid = { submitterList: existingSub };
    }

    const updRes = await ArtefactRepository.updateArtefact(
      artefactMetaData?.artefact_meta_id,
      request
    ).catch((err) => {
      setIsFormUpdated(true);
      setDisableBtn(false);
      toast.error(replace(artefact_messages.error.m02, "{name}", `"${artefactName}"`));
    });
    if (updRes) {
      const event = { ...artefactMetaData, ...request };
      updateArtefactEvent(event, artefactDocs.length);

      if (docs?.length > 0) {
        const docRes = await uploadDocs(docs, request);
        if (!docRes) {
          await addNotification(request, artefactMetaData, user, dispatch);
          await addWorkflow(request, artefactMetaData, user);
          toast.success(replace(artefact_messages.success.m02, "{name}", `"${artefactName}"`));
          history.push(`${allRoutesNames.app}${allRoutesNames.workspace}`);
        }
      } else {
        await addNotification(request, artefactMetaData, user, dispatch);
        await addWorkflow(request, artefactMetaData, user);
        toast.success(replace(artefact_messages.success.m02, "{name}", `"${artefactName}"`));
        history.push(`${allRoutesNames.app}${allRoutesNames.workspace}`);
      }
    }
  };

  const uploadDocs = async (docs: any, request: any) => {
    try {
      if (docs?.length > 0) {
        setShowProgress(true);
        setShowOverlay(true);
        const uploadRes = await startUploadDoc(
          uploadedDocs,
          {
            id: artefactMetaData?.artefact_meta_id,
            programId: programData?.program?.id,
            type: "artefact",
            docsCount: artefactDocs?.length
          },
          request,
          artefactMetaData,
          user
        );
      }
      return "";
    } catch (err) {
      setIsUploadCancelled(true);
      toast.error("Failed to upload docs.Please try again...");
      fetchArtefactDocs(artefactMetaData?.artefact_meta_id);
      setShowProgress(false);
      setShowOverlay(false);
      return err;
    }
  };

  const deleteArtefactDoc = async (doc: any) => {
    setShowConfirmModal(false);
    const res = await ArtefactRepository.deleteDoc(
      artefactMetaData?.artefact_meta_id,
      doc?.doc?.doc_id
    ).catch((err) => {
      toast.error(artefact_messages.error.m019);
    });

    deleteArtefactDocEvent(artefactMetaData, 1, doc);

    let tempArray = [...artefactDocs];
    tempArray.splice(doc?.index, 1);
    setArtefactDocs(tempArray);
    toast.success(artefact_messages.success.m012);
  };

  const deleteArtefactSubmitter = async (submitter: any) => {
    const userId = submitter?.submitter?.userid;
    setShowConfirmModal(false);
    const res = await ArtefactRepository.deleteSubmitter(
      artefactMetaData?.artefact_meta_id,
      userId
    ).catch((err) => {
      toast.error(artefact_messages.error.m013);
    });

    const sub = find(exSubmitters, { userid: userId });

    let subList = exSubmitters.filter((user) => {
      const id = user?.userid || user?.userId || user?.user_id;
      if (id !== userId) {
        return user;
      }
    });

    subList = compact(subList);

    setExSubmitters(subList);

    removeSubmitterEvent(artefactMetaData, artefactDocs.length, sub);
    toast.success(replace(artefact_messages.success.m09, "{name}", submitter?.submitter?.name));
    handleSubmit("deletesubmitter");
  };

  const showError = (field: any, error: any) => {
    return (
      <Text
        testId={`${field}-error`}
        fontWeight={FontWeight.Regular}
        textSize={TextSize.Small}
        style={styles.errorMsg}
      >
        {error}
      </Text>
    );
  };

  const updateUploadedDocs = (docs: any) => {
    setUploadedDocs(docs);
    setUpdatedFields({
      ...updatedFields,
      docs
    });
    setIsFormUpdated(true);
    if (isUploadCancelled && docs?.length > 0) {
      setDisableBtn(false);
    }
  };

  const deleteDoc = async (doc: any) => {
    if (artefactDocs.length > 1) {
      setShowConfirmModal(true);
      setDeleteType("doc");
      setSelectedDoc(doc);
      setConfirmMsg(artefact_messages.info.m06);
    } else {
      toast.error(artefact_messages.error.m010);
    }
  };

  const deleteSubmitter = async (submitter: any) => {
    if (exSubmitters.length > 1) {
      setShowConfirmModal(true);
      setDeleteType("submitter");
      setSelectedSubmitter(submitter);
      setConfirmMsg(artefact_messages.info.m07);
    } else {
      toast.error(artefact_messages.error.m018);
    }
  };

  const showDeleteModal = () => {
    return (
      <SocionModal
        modalVisible={showConfirmModal}
        setModalVisible={() => setShowConfirmModal(!showConfirmModal)}
        component={
          <ConfirmModal
            onClose={() => setShowConfirmModal(false)}
            onConfirm={async () => {
              deleteType === "doc"
                ? await deleteArtefactDoc(selectedDoc)
                : await deleteArtefactSubmitter(selectedSubmitter);
            }}
            msg={confirmMsg}
          ></ConfirmModal>
        }
      ></SocionModal>
    );
  };

  const getMessage = () => {
    let msg = replace(artefact_messages.info.m02, "{type}", selectedType?.artefact_type_title);
    msg = replace(msg, "{location}", updatedLocation?.location);
    return msg;
  };

  const showTypeConfirmModal = () => {
    return (
      <SocionModal
        modalVisible={showTypeModal}
        setModalVisible={() => setShowTypeModal(!showTypeModal)}
        component={
          <ConfirmModal
            onClose={(value: string) => {
              if (value.toLowerCase() === "cancel") {
                setVal("");
                setUpdatedLocation({});
                setSelectedType("");
                setIsClearLocation(true);
                setUpdatedFields({
                  ...updatedFields,
                  ...getLocation({}),
                  artefact_type_id: "",
                  artefact_type_title: ""
                });
                setIsFormUpdated(false);
              }
              setShowTypeModal(false);
            }}
            onConfirm={async () => {
              setIsClearLocation(false);
              setShowTypeModal(false);
            }}
            msg={getMessage()}
          ></ConfirmModal>
        }
      ></SocionModal>
    );
  };
  const updatedSubmitters = (submitters: any[]) => {
    setNewSubmitters(submitters);
    setIsFormUpdated(true);
    setUpdatedFields({
      ...updatedFields,
      submitters: submitters
    });
  };

  const getDisabled = () => {
    let bool = true;
    if (isUploading) {
      bool = true;
    } else if (isFormUpdated) {
      bool = false;
    }
    return bool;
  };

  const FullScreenOverlay = (props: { showOverlay: any; onButtonClick: any }) => {
    return props.showOverlay ? (
      <div className="overlay">
        {/* You can place the button here if needed */}
        <DocUploadProgress docsToUpload={uploadedDocs} handleCancel={props.onButtonClick} />
      </div>
    ) : null;
  };

  const handleButtonClick = () => {
    cancelUpload();
    setIsUploadCancelled(true);
    // setTimeout(() => {
    //   history.push(`${allRoutesNames.app}${allRoutesNames.workspace}`);
    // }, 4000);
  };

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : artefactMetaData ? (
        <div>
          {artefactMetaData && (
            <View style={styles.mainContainer}>
              <View style={styles.subContainer}>
                <EditArtefactWrapper>
                  <div className="EditArtefactDetailsWrapper">
                    <View style={styles.editArtefactWrapperContainer}>
                      <div className="EditArtefactRightBorder">
                        <div
                          style={{
                            fontSize: 14,
                            fontFamily: FontFamily.Bold,
                            color: colorPallete.textBlack,
                            paddingBottom: "20px"
                          }}
                        >
                          Edit Artefact
                        </div>
                        <div>
                          <div className="formGroup">
                            <div
                              className="title"
                              style={{
                                fontSize: 16,
                                fontFamily: FontFamily.Thin,
                                fontWeight: "400",
                                // color: "#231F20",
                                color: colorPallete.textLight
                              }}
                            >
                              Location
                            </div>
                            <div className="field" style={{ fontFamily: FontFamily.Thin }}>
                              <View style={styles.modalContainer}>
                                <MapLocation
                                  showTitleAndCloseIcon={false}
                                  currentBaseLocation={
                                    updatedLocation?.country ? updatedLocation?.location : ""
                                  }
                                  locationChange={(value: any) => {
                                    setUpdatedLocation({});
                                    setUpdatedFields({
                                      ...updatedFields,
                                      ...getLocation(location)
                                    });
                                  }}
                                  clearLocation={isClearLocation}
                                  getSelectedLocation={(location: any) => {
                                    if (!isEmpty(location)) {
                                      setIsFormUpdated(true);
                                      setUpdatedLocation(getLocation(location));
                                      setUpdatedFields({
                                        ...updatedFields,
                                        ...getLocation(location)
                                      });
                                    }
                                  }}
                                />
                                {!updatedLocation?.country &&
                                  showError("formattedaddress", "Please enter location")}

                                {updatedLocation?.country && (
                                  <Text
                                    fontWeight={FontWeight.Thin}
                                    testId="joinedLocation"
                                    textSize={TextSize.Small}
                                    textStyle={{
                                      fontSize: 10,
                                      fontFamily: FontFamily.Thin,
                                      paddingStart: 10
                                    }}
                                  >
                                    {`( ${getJoinedLocation(updatedLocation)} )`}
                                  </Text>
                                )}
                              </View>
                            </div>
                          </div>

                          <div className="formGroup">
                            <div
                              className="title"
                              style={{
                                fontSize: 16,
                                fontFamily: FontFamily.Thin,
                                fontWeight: "400",
                                // color: "#231F20"
                                color: colorPallete.textLight
                              }}
                            >
                              Artefact type
                            </div>
                            <div className="field" style={{ fontFamily: FontFamily.Thin }}>
                              <View style={styles.dropDownContainer}>
                                <Dropdown
                                  open={open}
                                  dropdownValue={val}
                                  items={items}
                                  setOpen={setOpen}
                                  setValue={setVal}
                                  setItems={setItems}
                                  onChangeValue={() => {
                                    if (types) {
                                      const typed = types.find((typ) => {
                                        if (typ.id === val) {
                                          return typ;
                                        }
                                      });
                                      const d = typed;
                                      if (val && val !== artefactMetaData?.artefact_type_id) {
                                        setSelectedType(d);
                                        setIsFormUpdated(true);
                                        setUpdatedFields({
                                          ...updatedFields,
                                          artefact_type_id: d?.id,
                                          artefact_type_title: d?.artefact_type_title
                                        });
                                      }
                                    }
                                  }}
                                  containerWidthStyle={styles.containerStyle}
                                  containerRadius={styles.dropDownContainerStyle}
                                  pickerStyle={styles.alignTextHeader}
                                  labelStyles={styles.labelStyles}
                                  placeholderStyle={styles.placeholderStyle}
                                  customItemContainerStyle={styles.customItemContainerStyle}
                                  listItems={styles.listItems}
                                  listItemLabelStyle={styles.listItemLabelStyle}
                                  arrowIconStyle={styles.arrowIconStyle}
                                  closeAfterSelecting={true}
                                />
                              </View>
                            </div>
                          </div>
                          <div className="formGroup">
                            <div
                              className="title"
                              style={{
                                fontSize: 16,
                                fontFamily: FontFamily.Thin,
                                fontWeight: "400",
                                // color: "#231F20"
                                color: colorPallete.textLight
                              }}
                            >
                              Artefact name
                            </div>
                            <div className="field" style={{ fontFamily: FontFamily.Thin }}>
                              {/* <View> */}
                              <TextField
                                editable={true}
                                label=""
                                handleChange={(value: any) => {
                                  setArtefactName(value);
                                  setUpdatedFields({
                                    ...updatedFields,
                                    artefact_name: value ? value.trim() : value
                                  });

                                  setIsFormUpdated(true);
                                }}
                                handleBlur={() => {}}
                                value={artefactName}
                                inputStyle={styles.inputRole}
                                placeholder="Enter name"
                                name="addRoleTitle"
                                id="addRoleTitle"
                                noFormik
                              />
                              {!artefactName && showError("name", "Please add artefact name")}
                              {/* </View> */}
                            </div>
                          </div>
                          <div className="formGroup">
                            <div
                              className="title"
                              style={{
                                fontSize: 16,
                                fontFamily: FontFamily.Thin,
                                fontWeight: "400",
                                // color: "#231F20"
                                color: colorPallete.textLight
                              }}
                            >
                              Uploaded by
                            </div>

                            <div
                              className="field"
                              style={{
                                fontSize: 14,
                                fontFamily: FontFamily.Thin,
                                fontWeight: "400",
                                color: "#231F20",
                                paddingLeft: 40
                              }}
                            >
                              {artefactMetaData.created_by}
                            </div>
                          </div>
                        </div>
                        <div
                          style={{
                            paddingBottom: "20px",
                            paddingRight: "20px"
                          }}
                        >
                          <div
                            style={{
                              fontSize: 14,
                              fontFamily: FontFamily.Bold,
                              fontWeight: "700",
                              color: "#231F20",
                              paddingTop: "20px"
                            }}
                          >
                            Documents
                          </div>
                          {showProgress && (
                            <FullScreenOverlay
                              showOverlay={showOverlay}
                              onButtonClick={handleButtonClick}
                            />
                          )}
                          {!showProgress && (
                            <AddArtefactDocs
                              acceptedFormat={[".pdf", ".jpeg", ".jpg", ".png"]}
                              uploadedDocs={(docs: any) => updateUploadedDocs(docs)}
                              programId={programData?.program?.id}
                              type={"artefact"}
                              isUploading={(uploading: boolean) => {
                                setIsUploading(uploading);
                              }}
                              isEdit={true}
                              artefact={artefactMetaData}
                              docsCount={artefactDocs?.length}
                            ></AddArtefactDocs>
                          )}
                          <ArtefactDocs
                            id={artefactMetaData?.artefact_meta_id}
                            docs={artefactDocs}
                            isView={false}
                            type="artefact"
                            deleteDoc={(value: any) => deleteDoc(value)}
                          ></ArtefactDocs>
                        </div>
                      </div>
                      <div className="addSubmittersWrapper">
                        <div
                          style={{
                            fontSize: 14,
                            fontFamily: FontFamily.Bold,
                            fontWeight: "700",
                            color: "#231F20",
                            paddingBottom: 25
                          }}
                        >
                          Submission Details
                        </div>
                        <div>
                          <div className="formGroup">
                            <div
                              className="submittedDateTitle"
                              style={{
                                fontSize: 16,
                                fontFamily: FontFamily.Light,
                                fontWeight: "400",
                                // color: "#231F20"
                                color: colorPallete.textLight
                              }}
                            >
                              Submitted date
                            </div>
                            <div
                              className="submittedDateField"
                              style={{ fontFamily: FontFamily.Bold }}
                            >
                              {/* <View> */}
                              <DatePicker
                                min="2019-01-01"
                                max="2024-12-31"
                                onChange={(e: any) => {
                                  setSubmittedDate(getDate(e?.target?.value));
                                  setUpdatedFields({
                                    ...updatedFields,
                                    submitted_date: getDate(e?.target?.value)
                                  });
                                  setIsFormUpdated(true);
                                }}
                                value={submittedDate}
                                name="submittedDate"
                                id="submittedDate"
                                noFormik
                              />
                              {!submittedDate &&
                                showError("submittedDate", "Please add submitted date")}
                              {/* </View> */}
                            </div>
                          </div>

                          <AddSubmitters
                            programId={programData?.program?.id}
                            selectedSubmitters={(users: any[]) => updatedSubmitters(users)}
                            submitters={exSubmitters}
                          ></AddSubmitters>

                          <div
                            style={{
                              fontSize: 14,
                              fontFamily: FontFamily.Bold,
                              fontWeight: "700",
                              color: "#231F20",
                              paddingTop: "18px",
                              paddingBottom: "30px"
                            }}
                          >
                            Submitters
                          </div>

                          <View>
                            <Submitters
                              submitters={exSubmitters}
                              isView={false}
                              deleteSubmitter={(value: any) => deleteSubmitter(value)}
                            ></Submitters>
                          </View>
                          {artefactMetaData.approved_by && artefactMetaData.approved_date && (
                            <div>
                              <div
                                style={{
                                  fontSize: 14,
                                  fontFamily: FontFamily.Bold,
                                  fontWeight: "700",
                                  color: "#231F20",
                                  paddingTop: "30px",
                                  paddingBottom: "8px"
                                }}
                              >
                                Approver Details
                              </div>

                              <div className="formGroup">
                                <div
                                  className="submittedDateTitle"
                                  style={{
                                    fontSize: 16,
                                    fontFamily: FontFamily.Thin,
                                    fontWeight: "400",
                                    // color: "#231F20"
                                    color: colorPallete.textLight
                                  }}
                                >
                                  Approver name
                                </div>

                                <div
                                  className="submittedDateField"
                                  style={{
                                    fontSize: 14,
                                    fontFamily: FontFamily.Thin,
                                    fontWeight: "400",
                                    color: "#231F20"
                                  }}
                                >
                                  {artefactMetaData.approved_by}
                                </div>
                              </div>
                              <div className="formGroup">
                                <div
                                  className="submittedDateTitle"
                                  style={{
                                    fontSize: 16,
                                    fontFamily: FontFamily.Thin,
                                    fontWeight: "400",
                                    // color: "#231F20"
                                    color: colorPallete.textLight
                                  }}
                                >
                                  Approver date
                                </div>
                                <div
                                  className="submittedDateField"
                                  style={{
                                    fontSize: 14,
                                    fontFamily: FontFamily.Thin,
                                    fontWeight: "400",
                                    color: "#231F20"
                                  }}
                                >
                                  {dataHelper.formatDateUtc(artefactMetaData.approved_date)}
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </View>

                    <View
                      style={[
                        styles.editArtefactWrapperContainer,
                        {
                          display: "flex",
                          justifyContent: "flex-end",
                          alignItems: "center",
                          width: "100%"
                        },
                        showOverlay ? { zIndex: -1 } : null
                      ]}
                    >
                      <View style={{ width: "75%" }}></View>
                      <View style={styles.buttons}>
                        <ArtefactButtons
                          disabled={getDisabled()}
                          buttons={["Save"]}
                          onClick={(type: any) => handleSubmit(type)}
                        ></ArtefactButtons>
                      </View>
                    </View>
                  </div>
                </EditArtefactWrapper>

                <Workflow artefactWorkFlow={artefactWorkFlow}></Workflow>
              </View>
            </View>
          )}

          {showConfirmModal && showDeleteModal()}
          {showTypeModal && showTypeConfirmModal()}
        </div>
      ) : null}
    </>
  );
};

const styles = StyleSheet.create({
  editArtefactWrapperContainer: {
    display: "flex",
    flexDirection: "row",
    width: "100%"
  },
  mainContainer: {
    // top: 55,
    paddingBottom: 20,
    paddingHorizontal: 20,
    // paddingTop: 15,
    backgroundColor: colorPallete.cordioBeigeLight2,
    minHeight: "calc(100vh - 50px)"
  },
  subContainer: {
    backgroundColor: colorPallete.white,
    borderRadius: 10,
    shadowColor: colorPallete.cordioRedDark1,
    shadowOpacity: 0.1,
    shadowOffset: {
      height: 10,
      width: 5
    },
    shadowRadius: 10
  },
  containerStyle: {
    marginBottom: 0,
    display: "flex",
    width: "100%",
    alignItems: "center",
    height: 20,
    borderWidth: 1,
    paddingVertical: 20,
    borderRadius: 5,
    borderColor: colorPallete.cordioTaupeLight4
  },
  dropDownContainer: {
    zIndex: 10,
    width: "100%"
  },
  dropDownContainerStyle: {
    borderWidth: 0,
    borderTopRightRadius: 5,
    borderBottomRightRadius: 5,
    justifyContent: "center",
    marginBottom: 0,
    width: "100%"
  },
  alignTextHeader: {
    paddingTop: 5,
    marginLeft: 0,
    marginRight: 0,
    paddingVertical: 5
  },
  labelStyles: {
    // paddingLeft: 0,
    fontFamily: FontFamily.Thin,
    fontWeight: FontWeight.Bold,
    fontSize: 14,
    color: "#231F20",
    paddingHorizontal: 10,
    paddingLeft: 15
  },
  placeholderStyle: {
    paddingHorizontal: 10,
    fontFamily: FontFamily.Thin,
    fontSize: 16
  },
  customItemContainerStyle: {
    padding: 5,
    width: "100%"
  },
  listItems: {
    padding: 5
  },
  listItemLabelStyle: {
    fontFamily: FontFamily.Thin,
    fontWeight: FontWeight.Bold
  },
  arrowIconStyle: {
    width: 25,
    height: 19,
    marginHorizontal: 5
  },

  modalContainer: {
    width: "100%",
    zIndex: 90
  },

  inputRole: {
    height: 40,
    outline: "none",
    width: "100%",
    borderRadius: 5,
    padding: 7,
    paddingLeft: 10,
    fontSize: 16,
    fontFamily: FontFamily.Thin,
    fontWeight: "500",
    color: "#231F20",
    borderColor: colorPallete.cordioTaupeLight4
  },
  errorMsg: {
    fontSize: 12,
    color: "#FF0D10",
    padding: 5
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "flex-end"
  },
  buttons: {
    width: "25%"
    // justifyContent: "flex-end",
    // alignItems: "center"
  }
});
export default EditArtefact;
