import { colorPallete } from "@socion-cordio/common/src/assets/styles/colors";
import * as React from "react";
import { StyleSheet, View, StyleProp, ViewStyle } from "react-native";

interface IProps {
  orientation?: Orientations;
  dividerStyle?: StyleProp<ViewStyle>;
}
export enum Orientations {
  Vertical = "vertical",
  Horizontal = "horizontal"
}

export const Divider = (props: IProps): React.ReactElement => {
  const { orientation, dividerStyle } = props;
  return orientation === Orientations.Vertical ? (
    <View style={[styles.container, styles.verticalLine, dividerStyle]} />
  ) : (
    <View style={[styles.container, styles.horizontalLine, dividerStyle]} />
  );
};
const styles = StyleSheet.create({
  container: {
    borderColor: colorPallete.black,
    borderRadius: 1
  },
  verticalLine: {
    borderRightWidth: 1,
    width: 1
  },
  horizontalLine: {
    borderBottomWidth: 1,
    height: 1
  }
});
