import { call, put, takeLatest } from "redux-saga/effects";
import { IFluxStandardAction } from "store/interfaces";
import { USER } from "@socion-cordio/common/src/modules/user-role/actions/actionTypes";
import { UserRolesProgram } from "@socion-cordio/common/src/models/userRolesProgram";
import { UserRolesProgramActions } from "@socion-cordio/common/src/modules/user-role/actions/actions";
import { UserRolesProgramRepository } from "@socion-cordio/common/src/repositories/user-role-program/userRolesProgram";

function* getUserRolesProgramWorkerSaga(value: IFluxStandardAction<{ programId: number }>) {
  try {
    const { payload } = value;
    const response: UserRolesProgram = yield call(
      UserRolesProgramRepository.getUserRolesProgram,
      payload.programId
    );
    yield put(UserRolesProgramActions.getUserRolesProgramSuccess(response));
  } catch (error) {
    yield put(UserRolesProgramActions.getUserRolesProgramError(error));
  }
}

export default function* UserRoleProgramWatcherSaga() {
  yield takeLatest(USER.GET_USER_ROLES_PROGRAM.LOADING, getUserRolesProgramWorkerSaga);
}
