import React, { ReactElement } from "react";
import { StyleSheet, View, Text, TouchableOpacity } from "react-native";
import { ApiClient } from "@socion-cordio/common/src/network/apiClient";
import { topNavbarEndPoints } from "@socion-cordio/common/src/repositories/endPoints";
import { toast } from "react-toastify";
import { LocalStorage } from "@socion-cordio/common/src/services/storage/storageService";
import Icon, { IconNames } from "@socion-cordio/common/src/components/atoms/icon";

export default function ExternalApps(): ReactElement {
  const handleClick = async () => {
    let token = await LocalStorage.getStorage("accessToken");
    let payload = { token: token };
    try {
      const response = await ApiClient.post(topNavbarEndPoints.setToken(), payload);
      if (response) {
        var a = document.createElement("a");
        a.href = "/superset/login?dashboard=23";
        a.target = "_blank";
        a.click();
      }
    } catch {
      toast.error("Something went wrong");
    }
  };

  return (
    <View style={styles.externalAppsMainContainer}>
      <View style={styles.subContainer}>
        <Text style={styles.heading}>External applications</Text>
        <View style={styles.groupMainContaineer}>
          <TouchableOpacity onPress={handleClick}>
            <View style={styles.flexContainer}>
              <Icon name={IconNames.rectangle} customStyle={styles.iconStyle} />
              <Text style={styles.textContainer}>Superset Dashbaord</Text>
              <Icon name={IconNames.rightArrow} customStyle={styles.rightIconStyle} />
            </View>
          </TouchableOpacity>
        </View>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  externalAppsMainContainer: {
    // left: "1131px",
    top: "50px",
    position: "fixed"
  },
  subContainer: {
    backgroundColor: "rgba(255, 255, 255, 1)",
    height: "auto",
    // width: "309px",
    width: "150%",
    padding: "20px"
  },
  heading: {
    position: "absolute",
    width: "177px",
    height: "20px",
    fontFamily: "Montserra-Bold",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: 16,
    lineHeight: 20,
    color: "#231F20"
  },
  groupMainContaineer: {
    marginTop: "40px"
  },
  iconStyle: {
    fontSize: 40,
    width: 40,
    color: "rgba(221, 221, 221, 1)"
  },
  rightIconStyle: {
    height: 6,
    width: 12,
    marginLeft: "64.45px",
    marginTop: "-10px"
  },
  textContainer: {
    fontFamily: "Montserrat-Regular",
    fontSize: 12,
    fontStyle: "normal",
    fontWeight: "400",
    letterSpacing: 0,
    textAlign: "left",
    width: 69,
    height: 30,
    marginLeft: "14px"
  },
  flexContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center"
  }
});
