import { call, put, takeLatest } from "redux-saga/effects";
import { IFluxStandardAction } from "store/interfaces";
import { BUNDLE } from "@socion-cordio/common/src/modules/qualificationPacks/actions/actionTypes";
import { QualificationPackActions } from "@socion-cordio/common/src/modules/qualificationPacks/actions/actions";
import { ViewBundle } from "@socion-cordio/common/src/models/qualificationPacks";
import { QualificationPackRepository } from "@socion-cordio/common/src/repositories/qualificationPack/qualificationPack";

function* getQualificationPackWorkerSaga(value: IFluxStandardAction<{ bundleId: number }>) {
  try {
    const { payload }: any = value;
    const response: ViewBundle = yield call(QualificationPackRepository.getBundle, payload);
    yield put(QualificationPackActions.getBundleSuccess(response));
  } catch (error) {
    yield put(QualificationPackActions.getBundleError(error));
  }
}

export default function* QualificatioPackWatcherSaga() {
  yield takeLatest(BUNDLE.GET_BUNDLE.LOADING, getQualificationPackWorkerSaga);
}
