import { call, put, takeLatest } from "redux-saga/effects";
import { IFluxStandardAction } from "store/interfaces";
import { ViewSession } from "@socion-cordio/common/src/models/viewSession";
import { SESSION } from "@socion-cordio/common/src/modules/session/actions/actionTypes";
import { SessionsActions } from "@socion-cordio/common/src/modules/session/actions/actions";
import { SessionRepository } from "@socion-cordio/common/src/repositories/session/session";

function* getSessionWorkerSaga(value: IFluxStandardAction<{ sessionId: number }>) {
  try {
    const { payload } = value;
    const response: ViewSession = yield call(
      SessionRepository.getSessionDetails,
      payload.sessionId
    );
    yield put(SessionsActions.getCompleteSessionSuccess(response));
  } catch (error) {
    yield put(SessionsActions.getCompleteSessionError(error));
  }
}

export default function* SessionWatcherSaga() {
  yield takeLatest(SESSION.GET_COMPLETE_SESSION.LOADING, getSessionWorkerSaga);
}
