import React, { ReactElement, useState, useEffect } from "react";
import { StyleSheet, View } from "react-native";
import { colorPallete } from "@socion-cordio/common/src/assets/styles/colors";
import {
  Text,
  TextSize,
  FontWeight,
  FontFamily
} from "@socion-cordio/common/src/components/atoms/text";
import AesUtil from "@socion-cordio/common/src/utils/encryptionHelper";
import { ApiClient } from "@socion-cordio/common/src/network/apiClient";
import { springshedEndPoints } from "@socion-cordio/common/src/repositories/endPoints";
import Icon, { IconNames } from "@socion-cordio/common/src/components/atoms/icon";
import Button, { ButtonType } from "@socion-cordio/common/src/components/atoms/button";
import { auxiliaryMethods } from "@socion-cordio/common/src/utils/auxiliaryMethods";
import { Image } from "@socion-cordio/common/src/components/atoms/image";
import { LocalStorage } from "@socion-cordio/common/src/services/storage/storageService";
import { QR } from "@socion-cordio/common/src/components/atoms/qrCode";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import Moment from "moment";
import AddTelemetryService from "@socion-cordio/common/src/services/telemetryService";
import { allRoutesNames as routeNames } from "@socion-cordio/web/src/navigation/allRouteNames";

interface Props {
  onClose: Function;
  userDetails?: any;
  memberStatus?: string;
  qrCodeData?: string;
  getRolesDetails?: Function;
  userId?: string;
  setMemberStatus?: Function;
  setSelectedUser?: Function;
  setModalLoading?: Function;
  getUserRoles?: Function;
}

export default function ActivateRemoveMember(props: Props): ReactElement {
  let aesUtil: AesUtil = new AesUtil();
  const [programData, setProgramData] = useState(undefined);
  const [userData, setUserData] = useState(null);
  const [memberData, setMemberData] = useState(undefined);
  const [memberStatus, setMemberStatus] = useState(false);
  const [status, setStatus] = useState(false);
  const history: any = useHistory();
  useEffect(() => {
    getProgramDetails();
  }, []);

  useEffect(() => {
    if (memberData && status) handleStatusChange();
    setStatus(false);
  }, [status]);

  const getProgramDetails = async () => {
    const user: any = await LocalStorage.getStorage("user");
    setUserData(user);
    const programDetails: any = await LocalStorage.getStorage("programDetails");
    setProgramData(programDetails);
  };

  const handleMemberStatus = async (status: boolean) => {
    try {
      const userDetails = await ApiClient.get(
        springshedEndPoints.getUserDetails(
          programData.entityData.entity.registryEntityId,
          props.userId
        )
      );
      setMemberData(userDetails.registryEntityRolesWithOsId[0]);
      setMemberStatus(status);
      setStatus(true);
      props.userId === userData.userId
        ? history.push(`${routeNames.app}+${routeNames.workspace}`)
        : null;
    } catch (error) {
      toast.error(error);
    }
  };

  const updateTelemetryEntityMemberStatus = async (data: any) => {
    const user: any = await LocalStorage.getStorage("user");
    const programDetails: any = await LocalStorage.getStorage("programDetails");
    const deleted = data.deleted;
    let eventType = "";
    deleted === true
      ? [(eventType = "Deactivate Entity Admin")]
      : [(eventType = "Reactivate Entity Admin")];
    const body = {
      eventType: eventType,
      timestamp: Moment().toLocaleString(),
      createdAt: Moment().toLocaleString(),
      deleted: "false",
      updatedAt: Moment().toLocaleString(),
      memberId: user.userId,
      entityId: programDetails?.program?.entity?.registryEntityId,
      entityName: programDetails?.program?.entity?.name,
      userId: props.userId,
      baseLocationCity: props?.userDetails?.city,
      baseLocationDistrict: props?.userDetails?.district,
      baseLocationSubDistrict: props?.userDetails?.subDistrict,
      baseLocationState: props?.userDetails?.state,
      baseLocationCountry: props?.userDetails?.country,
      baseLocationLatitude: props?.userDetails?.latitude,
      baseLocationLongitude: props?.userDetails?.longitude,
      establishmentName: props?.userDetails?.establishmentName
    };
    AddTelemetryService(body, undefined, user);
  };

  const handleStatusChange = async () => {
    const payload = {
      osid: memberData.osid,
      entityidStr: memberData.entityidStr,
      role: "ENTITY_ADMIN",
      user_id: memberData.user_id,
      deleted: memberStatus,
      created_at: memberData.created_at,
      updated_at: memberData.updated_at,
      created_by: memberData.created_by,
      updated_by: memberData.updated_by,
      entity_id: memberData.entity_id
    };
    try {
      const MemberStatusResponse = await ApiClient.put(
        springshedEndPoints.activateRemoveMember(),
        payload
      );
      props.onClose();
      props.getRolesDetails();
      props.getUserRoles();
      props.setModalLoading(false);
      toast.success(MemberStatusResponse.message);
      if (MemberStatusResponse.responseCode === 200) {
        updateTelemetryEntityMemberStatus(payload);
      }
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    } catch (error) {
      toast.error(error);
    }
  };

  return (
    <>
      {props.userDetails && (
        <>
          <View style={styles.header}>
            <Icon
              testID="close"
              name={IconNames.crossCircle}
              customStyle={styles.crossIcon}
              onPress={() => props.onClose()}
            />
          </View>
          <View style={styles.subContainer}>
            <View style={styles.profilePictureContainer}>
              <View style={styles.profilePicture}>
                <Image
                  imageStyle={styles.profilePicture}
                  testId="socionImg"
                  source={
                    props.userDetails?.photo === ""
                      ? require("@socion-cordio/common/src/assets/images/user_circle.svg")
                      : props.userDetails?.photo
                  }
                />
              </View>
            </View>
            <View style={styles.memberInfoContainer}>
              <Text
                fontWeight={FontWeight.Bold}
                testId="addRoleText"
                textSize={TextSize.Small}
                textStyle={styles.headerText}
              >
                {props.userDetails.name}
              </Text>
              <View style={styles.userDetailsContainer}>
                <Text
                  fontWeight={FontWeight.Regular}
                  testId="phoneNumberLabel"
                  textSize={TextSize.Small}
                  textStyle={[styles.alignText]}
                >
                  {"Contact Number : "}
                </Text>
                <Text
                  fontWeight={FontWeight.Regular}
                  testId="phoneNumber"
                  textSize={TextSize.Small}
                  textStyle={[styles.alignText]}
                >
                  {props.userDetails.piiInfo
                    ? `${props.userDetails.countryCode} ${props.userDetails.phoneNumber}`
                    : `NA`}
                </Text>
              </View>
              <View style={styles.userDetailsContainer}>
                <Text
                  fontWeight={FontWeight.Regular}
                  testId="addRoleText"
                  textSize={TextSize.Small}
                  textStyle={[styles.alignText]}
                >
                  {"Contact Email : "}
                </Text>
                <Text
                  fontWeight={FontWeight.Regular}
                  testId="addRoleText"
                  textSize={TextSize.Small}
                  textStyle={[styles.alignText]}
                >
                  {props.userDetails.piiInfo
                    ? props.userDetails.emailId !== ""
                      ? props.userDetails.emailId
                      : ""
                    : `NA`}
                </Text>
              </View>
              <Text
                fontWeight={FontWeight.Regular}
                testId="addRoleText"
                textSize={TextSize.Small}
                textStyle={[styles.alignText]}
              >
                Base location :
              </Text>
              <Text
                fontWeight={FontWeight.Regular}
                testId="addRoleText"
                textSize={TextSize.Small}
                textStyle={[styles.alignText, styles.textColor]}
              >
                {auxiliaryMethods.fromatBaseLocation(props.userDetails) === ""
                  ? "None"
                  : auxiliaryMethods.fromatBaseLocation(props.userDetails)}
              </Text>
            </View>
            <View style={styles.qrContainer}>
              <QR value={aesUtil.encrypt(props.userId)} />
            </View>
          </View>
          {props.memberStatus === "deactivate" && (
            <View style={styles.buttonContainer}>
              <Button
                type={ButtonType.Primary}
                buttonStyles={styles.button}
                textStyles={styles.buttonFont}
                title="Deactivate member"
                onPress={() => handleMemberStatus(true)}
              />
            </View>
          )}
          {props.memberStatus === "activate" && (
            <View style={styles.buttonContainer}>
              <Button
                type={ButtonType.Primary}
                buttonStyles={styles.button}
                textStyles={styles.buttonFont}
                title="Reactivate member"
                onPress={() => handleMemberStatus(false)}
              />
            </View>
          )}
        </>
      )}
    </>
  );
}

const styles = StyleSheet.create({
  header: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row"
  },
  headerText: {
    fontSize: 14,
    fontFamily: FontFamily.Medium,
    lineHeight: 17
  },
  memberInfoContainer: {
    flex: 80
  },
  subContainer: {
    flexDirection: "row",
    marginBottom: 25,
    width: "680px"
  },
  profilePictureContainer: {
    flex: 20,
    alignItems: "center"
  },
  profilePicture: {
    borderRadius: 54,
    width: " 80px",
    height: "80px"
  },
  alignText: {
    fontSize: 14,
    color: colorPallete.textLight,
    marginTop: 10,
    FontFamily: FontFamily.Medium,
    fontWeight: "400"
  },
  textColor: {
    color: colorPallete.textBlack,
    fontFamily: FontFamily.Regular,
    fontWeight: "700"
  },
  button: {
    width: "167px",
    height: "50px",
    borderRadius: 10
  },
  buttonFont: {
    fontSize: 14
  },
  buttonContainer: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    marginTop: 15
  },
  crossIcon: {
    fontSize: 15,
    marginLeft: 700,
    marginBottom: 49
  },
  qrContainer: {
    marginBottom: 20,
    marginRight: 20
  },
  userDetailsContainer: {
    flexDirection: "row"
  }
});
