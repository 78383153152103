import { ArtefactRepository } from "@socion-cordio/common/src/repositories/artefact/artefact";
import { isEmpty } from "lodash";
import {
  baseTelemetryData,
  updateArtefactDocFields,
  updateArtefactFields
} from "./telemetryHelper";

export const startUploadEvent = async (data?: any, docsCount?: number) => {
  let request;
  const requestmeta = {
    ...baseTelemetryData(),
    eventName: "start-upload",
    eventType: "INTERACT"
  };

  request = {
    ...requestmeta
  };
  if (!isEmpty(data)) {
    request = {
      ...requestmeta,
      ...updateArtefactFields(data, docsCount)
    };
  }

  console.log("startUpload telemetry", JSON.stringify(request));

  await ArtefactRepository.updateArtefactsTelemetry(request).catch((err) => {
    console.log("Error while adding telemetry", err);
  });
};

export const completeUploadEvent = async (data?: any, docsCount?: number) => {
  let request;
  const requestmeta = {
    ...baseTelemetryData(),
    eventName: "complete-upload",
    eventType: "INTERACT"
  };

  request = {
    ...requestmeta
  };

  if (!isEmpty(data)) {
    request = {
      ...requestmeta,
      ...updateArtefactFields(data, docsCount)
    };
  }

  console.log("completeUpload telemetry", JSON.stringify(request));

  await ArtefactRepository.updateArtefactsTelemetry(request).catch((err) => {
    console.log("Error while adding telemetry", err);
  });
};

export const addArtefactDocEvent = async (artefact: any, docsCount = 0, doc: any) => {
  const request = {
    ...baseTelemetryData(),
    ...updateArtefactFields(artefact, docsCount),
    ...updateArtefactDocFields(doc),
    eventName: "add-artefact-doc",
    eventType: "INTERACT"
  };
  console.log("add artefact doc telemetry", JSON.stringify(request));

  await ArtefactRepository.updateArtefactsTelemetry(request).catch((err) => {
    console.log("Error while adding telemetry", err);
  });
};

export const addArtefactEvent = async (artefact: any, docsCount = 0) => {
  const request = {
    ...baseTelemetryData(),
    ...updateArtefactFields(artefact, docsCount),
    eventName: "add-artefact",
    eventType: "INTERACT"
  };

  console.log("add artefact telemetry", JSON.stringify(request));

  await ArtefactRepository.updateArtefactsTelemetry(request).catch((err) => {
    console.log("Error while adding telemetry", err);
  });
};

export const updateArtefactEvent = async (artefact: any, docsCount: number) => {
  const request = {
    ...baseTelemetryData(),
    ...updateArtefactFields(artefact, docsCount),
    eventName: "edit-artefact",
    eventType: "INTERACT"
  };

  console.log("edit artefact telemetry", JSON.stringify(request));

  await ArtefactRepository.updateArtefactsTelemetry(request).catch((err) => {
    console.log("Error while adding telemetry", err);
  });
};

export const viewArtefactEvent = async (artefact: any, docsCount = 0) => {
  const request = {
    ...baseTelemetryData(),
    ...updateArtefactFields(artefact, docsCount),
    eventName: "view-artefact",
    eventType: "INTERACT"
  };

  console.log("view artefact telemetry", JSON.stringify(request));

  await ArtefactRepository.updateArtefactsTelemetry(request).catch((err) => {
    console.log("Error while adding telemetry", err);
  });
};

export const reviewArtefactEvent = async (artefact: any, docsCount = 0) => {
  const request = {
    ...baseTelemetryData(),
    ...updateArtefactFields(artefact, docsCount),
    eventName: "review-artefact",
    eventType: "INTERACT"
  };

  console.log("review artefact telemetry", JSON.stringify(request));

  await ArtefactRepository.updateArtefactsTelemetry(request).catch((err) => {
    console.log("Error while adding telemetry", err);
  });
};

export const deleteArtefactEvent = async (artefact: any, docsCount: number) => {
  const request = {
    ...baseTelemetryData(),
    ...updateArtefactFields(artefact, docsCount),
    eventName: "delete-artefact",
    eventType: "INTERACT"
  };

  console.log("delete artefact telemetry", JSON.stringify(request));

  await ArtefactRepository.updateArtefactsTelemetry(request).catch((err) => {
    console.log("Error while adding telemetry", err);
  });
};

export const deleteArtefactDocEvent = async (artefact: any, docsCount: number, doc: any) => {
  const request = {
    ...baseTelemetryData(),
    ...updateArtefactFields(artefact, docsCount),
    ...updateArtefactDocFields(doc),
    eventName: "delete-artefact-doc",
    eventType: "INTERACT"
  };

  console.log("delete artefact doc telemetry", JSON.stringify(request));

  await ArtefactRepository.updateArtefactsTelemetry(request).catch((err) => {
    console.log("Error while adding telemetry", err);
  });
};

export const removeSubmitterEvent = async (artefact: any, docsCount: number, submitter: any) => {
  const request = {
    ...baseTelemetryData(),
    ...updateArtefactFields(artefact, docsCount),
    artefactSubmitter: JSON.stringify(submitter),
    eventName: "remove-submitter",
    eventType: "INTERACT"
  };

  console.log("remove submitter telemetry", JSON.stringify(request));

  await ArtefactRepository.updateArtefactsTelemetry(request).catch((err) => {
    console.log("Error while adding telemetry", err);
  });
};
