import { createSelector, Selector } from "reselect";
import { Profile } from "@socion-cordio/common/src/models/profile";
import { IState } from "@socion-cordio/common/src/store/interfaces";
import {
  IProfileLoading,
  IProfileState
} from "@socion-cordio/common/src/modules/profile/reducers/reducer";

const profileSelect = (state: IState): IProfileState => state.profile;
const selectProfileData: Selector<IState, Profile> = createSelector(
  profileSelect,
  (profile) => profile.profileData
);
const selectLoading: Selector<IState, IProfileLoading> = createSelector(
  profileSelect,
  (profile) => profile.loading
);
const selectFileLink: Selector<IState, string> = createSelector(
  profileSelect,
  (profile) => profile.fileLink
);
export const ProfileSelectors = { selectProfileData, selectLoading, selectFileLink };
