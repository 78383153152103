import jsonToCsv from "@socion-cordio/common/src/utils/jsonToCsvDownload";
import { dataHelper } from "@socion-cordio/common/src/utils/dataHelper";
import { startCase } from "lodash";
import { checkIfStringHasQuotes, getSubmitter, getSubmitterDetails } from "./artefactUserHelper";

export const downloadCsvArtefactList = (data: any) => {
  const header = {
    srNo: "Sr.No",
    artefactType: "Artefact Type",
    artefactName: "Artefact Name",
    location: "Location",
    village: "Village",
    sub_district: "Sub District",
    district: "District",
    city: "City",
    state: "State",
    country: "Country",
    uploadedBy: "Uploaded By",
    uploadedDate: "Uploaded Date",
    submitterName: "Submitter Name",
    submitterId: "Submitter Id",
    submitterDate: "Submitted Date",
    reviewer: "Reviewer",
    reviewDate: "Review Date",
    status: "status"
  };
  const tableData = data.map((element: any) => {
    console.log(
      "checkIfStringHasQuotescheckIfStringHasQuotes",
      checkIfStringHasQuotes(element?.created_by)
    );
    return {
      srNo: element?.serialNo,
      artefactType: checkIfStringHasQuotes(startCase(element?.artefact_type_title)),
      artefactName: checkIfStringHasQuotes(startCase(element?.artefact_name)),
      location: checkIfStringHasQuotes(element?.location),
      village: checkIfStringHasQuotes(startCase(element?.village)),
      sub_district: checkIfStringHasQuotes(startCase(element?.sub_district)),
      district: checkIfStringHasQuotes(startCase(element?.district)),
      city: checkIfStringHasQuotes(startCase(element?.city)),
      state: checkIfStringHasQuotes(startCase(element?.state)),
      country: checkIfStringHasQuotes(startCase(element?.country)),
      uploadedBy: element?.created_by ? checkIfStringHasQuotes(element.created_by) : "NA",
      uploadedDate: dataHelper.formatDateUtc(element?.created_at),
      submitterName: getSubmitterDetails(element?.submitter_userid).names
        ? getSubmitterDetails(element?.submitter_userid).names
        : "NA",
      submitterId: getSubmitterDetails(element?.submitter_userid).ids
        ? getSubmitterDetails(element?.submitter_userid).ids
        : "NA",
      submitterDate: element?.submitted_date
        ? dataHelper.formatDateUtc(element?.submitted_date)
        : element?.created_at
        ? dataHelper.formatDateUtc(element?.created_at)
        : "NA",
      reviewer: element?.approved_by ? checkIfStringHasQuotes(element.approved_by) : "NA",
      reviewDate: element?.approved_date ? dataHelper.formatDateUtc(element.approved_date) : "NA",
      status: element?.status
        ? element.status === "REVIEW_INPROGRESS"
          ? checkIfStringHasQuotes(startCase("REVIEW IN PROGRESS".toLowerCase()))
          : checkIfStringHasQuotes(startCase(element.status.toLowerCase()))
        : ""
    };
  });
  jsonToCsv(header, tableData, data[0].programname + " Artefacts List");
};
