import { colorPallete } from "@socion-cordio/common/src/assets/styles/colors";
import React, { ReactElement } from "react";
import { View, StyleSheet } from "react-native";
import QRCode from "react-native-qrcode-svg";

interface IProps {
  testId?: string;
  size?: number;
  value?: string;
  color?: string;
  backgroundColor?: string;
  quietZone?: number;
  getRef?: () => void;
}

export const QR = (props: IProps): ReactElement => {
  const {
    testId,
    size = 100,
    value,
    color = colorPallete.black,
    backgroundColor,
    quietZone,
    getRef
  } = props;

  return (
    <View style={styles.container} testID={`qr${testId}`}>
      <QRCode
        value={value}
        size={size}
        color={color}
        backgroundColor={backgroundColor}
        quietZone={quietZone}
        getRef={getRef}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center"
  }
});
