import { allRoutesNames as routeNames } from "@socion-cordio/web/src/navigation/allRouteNames";
import React, { ReactElement } from "react";
import { StyleSheet, View, Text } from "react-native";

export default function PrivacyPolicy(): ReactElement {
  console.log("PrivacyPolicyPrivacyPolicy");

  return (
    <View>
      {/* <View>
        <View>
          <View> */}
      <View style={styles.profileCol}>
        <View style={styles.textCenter}>
          <Text style={styles.title}>PRIVACY POLICY</Text>
        </View>
        <br />
        <View style={styles.body}>
          <Text style={{ lineHeight: "1.5", fontFamily: "times new roman", fontSize: "16px" }}>
            The applications ‘PDAP participant application’ (<b>“PDAP”</b>), PDAP Trainer
            application’ (<b>“PDAT”</b>), ‘PDAP Program Administration portal’ (<b>“PDAPA”</b>) ’
            and the website www.socion.io (together known as <b>“Application”</b>) are owned and
            operated by Socion Advisors LLP, a company incorporated in India under the Companies
            Act, 1956, having its registered office at 203, Classic Orchards, Bannerghatta Road,
            Bangalore 560076 (<b>“Company”</b>). The Company is engaged in the business of providing
            technology enabled services that makes it possible for organizations and individuals to
            interact with each other during training or other sessions conducted by organizations,
            and to exchange information, content, and digitally verifiable attestations or data
            relating to those interactions (<b>“Services”</b>).
          </Text>
          <View>
            <br />
            <Text style={{ lineHeight: "1.5", fontFamily: "times new roman", fontSize: "16px" }}>
              This Privacy Policy (<b>“Privacy Policy”</b>) sets out the privacy practices of the
              Company with respect to the entire content of the Application.
              <br />
              <br />
              This document is published in accordance with the provisions of the Information
              Technology Act, 2000 and the rules made thereunder that require publishing the rules
              and regulations, privacy policy and the terms of use on an online portal of the
              Company. We request you to go through this Privacy Policy and the{" "}
              <a href={routeNames.TERMSOFUSE} target="_blank">
                Terms of Use
              </a>{" "}
              carefully before you decide to access this Application.
              <br />
              <br />
              For the purposes of this Privacy Policy, the words “us”, “we”, and “our” refer to the
              Company and all references to “you”, “your” or “user”, as applicable mean the person
              who accesses, uses and/or participates in the Application in any manner or capacity.
              <br />
              <br />
              The protection and security of your personal information is one of our top priorities
              and we have taken all necessary and reasonable measures to protect the confidentiality
              of the user information and its transmission through the internet.
              <br />
              <br />
              <strong>
                BY USING OUR SERVICES AND THE APPLICATION OR BY OTHERWISE GIVING US YOUR
                INFORMATION, YOU AGREE TO THE TERMS OF THIS PRIVACY POLICY. YOU ALSO EXPRESSLY
                CONSENT TO OUR USE AND DISCLOSURE OF YOUR PERSONAL INFORMATION (AS DEFINED BELOW) IN
                THE MANNER PRESCRIBED UNDER THIS PRIVACY POLICY AND FURTHER SIGNIFY YOUR AGREEMENT
                TO THIS PRIVACY POLICY AND THE TERMS OF USE (AVAILABLE AT{" "}
                <a href={routeNames.TERMSOFUSE} target="_blank">
                  TERMS OF USE
                </a>
                ). IF YOU DO NOT AGREE TO THIS PRIVACY POLICY, DO NOT SUBSCRIBE TO THE SERVICES, USE
                THE APPLICATION AND DO NOT GIVE US ANY OF YOUR INFORMATION.
              </strong>
            </Text>
            <br />
            <ol style={{ padding: "25px", color: "black", fontFamily: "times new roman" }}>
              <li>
                <Text style={styles.heading}>COLLECTION OF INFORMATION</Text>
              </li>
              <br />
              <br />
              We only collect information to provide our Services, to communicate with you, or to
              make our Services better.
              <br />
              <br />
              We collect information in three ways, which are (a) if and when you provide
              information to us, (b) automatically through operating our Services, and (c) from
              third party sources. Let’s go over the information that we collect.
              <br />
              <br />
              <ol type="a" id="list-alpha">
                <li style={{ fontStyle: "italic", fontWeight: "bold", textAlign: "start" }}>
                  &nbsp;&nbsp;Information You Provide to Us
                </li>
                <br />
                The information provided by you to us may be provided to us in multiple ways, as
                described below:
                <br />
                <br />
                <ol type="i">
                  <li style={{ fontWeight: "bold", textAlign: "start" }}>
                    basic (individual) Account Information:
                  </li>
                  We ask for basic information from you in order to set up your account. For
                  example, we require individuals who sign up provide a mobile phone number and an
                  email address along with a username or name, to avail our Services.
                  <br />
                  <li style={{ fontWeight: "bold", textAlign: "start" }}>Entity Information:</li>
                  We require every organization or legal entity onboarded onto the Application go
                  through a self-registration process if and when they subscribe to and access the
                  Program Administration Portal, which includes uploading documents and information
                  that helps identify and verify the name and status of the entity, as well as the
                  name and contact details of an authorized person or officer of that entity and
                  entity administrators that are authorized to update or manage entity related
                  information.
                  <br />
                  <li style={{ fontWeight: "bold", textAlign: "start" }}>Program Information:</li>
                  We require every new program onboarded onto the platform to provide a detailed
                  description of the program and its objectives. Each program is also assigned one
                  or more program administrators who then onboard content administrators and
                  trainers into the program. Content administrators are then given the ability to
                  create (training or session) topics and upload content (PDF’s, Video & Audio
                  files) for each topic (<b>“Training Content”</b>).
                  <br />
                </ol>
                <li style={{ fontWeight: "bold", textAlign: "start", fontStyle: "italic" }}>
                  &nbsp;&nbsp;Information We Collect Automatically
                </li>
                <br />
                We also collect some information automatically, as further described below:
                <br />
                <ol type="i">
                  <li style={{ fontWeight: "bold", textAlign: "start" }}>Log Information:</li>
                  Like most online service providers, we collect information that web browsers,
                  mobile devices, and servers typically make available, such as the browser type, IP
                  address, unique device identifiers, language preference, referring site, the date
                  and time of access, operating system, and mobile network information. We collect
                  log information when you use our Services–for example, when you create or make
                  changes within any part of our Services.
                  <br />
                  <li style={{ fontWeight: "bold", textAlign: "start" }}>Usage Information:</li>
                  We collect information about your usage of our Services. For example, we collect
                  information about the actions that a user performs within the mobile application
                  or websites that are part of our services–in other words, who did what, when and
                  to what thing on a site (e.g., [username] changed “[scanned in to session]” at
                  [time/date], [Scanned out of session] at [time/date] etc.). We also collect
                  information about what happens when you use our Services (e.g., page views, files
                  uploaded, files downloaded, files viewed, interactions with other features such as
                  ‘view details of attestation’ or ‘notification’ and other parts of our Services)
                  along with information about your device (e.g., screen size, cellular network,
                  time taken to view or download content, and mobile device manufacturer). We use
                  this information to, for example, provide our Services to you, as well as get
                  insights on how people access and use our Services, so we can make our Services
                  better.
                  <br />
                  <li style={{ fontWeight: "bold", textAlign: "start" }}>Location Information:</li>
                  We may determine the approximate location of your device from your IP address. We
                  collect and use this information to, for example, calculate how many people visit
                  our Services from certain geographic regions. We may also collect information
                  about your precise location via our mobile apps (when, for example, you scan into
                  or out of a session) if you allow us to do so through your mobile device operating
                  system’s permissions.
                  <br />
                  <li style={{ fontWeight: "bold", textAlign: "start" }}>Stored Information:</li>
                  We may access information stored on your mobile device via our mobile apps. We
                  access this stored information through your device operating system’s permissions.
                  For example, if you give us permission to access the photographs on your mobile
                  device’s camera roll, our Services may access the photos stored on your device
                  when you choose to update your photograph on your profile page or if you choose to
                  scan a QR code stored on your mobile device.
                  <br />
                  <li style={{ fontWeight: "bold", textAlign: "start" }}>
                    Information from Cookies & other technologies:
                  </li>
                  A cookie is a string of information that a website stores on a visitor’s computer,
                  and that the visitor’s browser provides to the website each time the visitor
                  returns. Socion uses cookies and other technologies to help us identify and track
                  visitors, usage, and access preferences for our Services.
                  <br />
                </ol>
                <li style={{ fontWeight: "bold", textAlign: "start", fontStyle: "italic" }}>
                  &nbsp;&nbsp;Information We Collect from Other Sources
                </li>
                <br />
                We may also get information about you from other sources. For example, if you create
                or log into your account through any associated service, we will receive information
                from that service (such as your username, profile information, and tokens) via the
                authorization procedures used by that service. The information we receive depends on
                which services you authorize and any options that are available. (hereinafter,
                collectively referred to as <b>“Personal Information”</b> ).
                <br />
              </ol>
              <br />
              <br />
              <li>
                <Text style={styles.heading}>USE OF THE INFORMATION COLLECTED</Text>
              </li>
              <br />
              <Text style={{ fontWeight: "bold" }}>
                2.1.&nbsp;&nbsp;USE OF THE INFORMATION FOR SERVICES
              </Text>
              <br />
              The primary goal of the Company in collecting the information is to provide you the
              Services through the Application. The Company may use the Personal Information
              provided by you in the following ways:
              <br />
              <br />
              <ol type="a" id="alphabet">
                <li>To help provide you the Services;</li>
                <br />
                <li>To observe, improve and administer the quality of Service;</li>
                <br />
                <li>
                  To analyze how the Application is used, diagnose technical problems and to
                  Increase the efficiency and operation of the Application;
                </li>
                <br />
                <li>Remember the basic information provided by you for effective access;</li>
                <br />
                <li>
                  To confirm your identity in order to determine your eligibility to use the
                  Application and avail the Services;
                </li>
                <br />
                <li>To notify you about any changes to the Application;</li>
                <br />
                <li>To enable the Company to comply with its legal and regulatory obligations;</li>
                <br />
                <li>
                  For the purpose of sending administrative notices, Service-related alerts and
                  other similar communication with a view to optimizing the efficiency of the
                  Services;
                </li>
                <br />
                <li>
                  Doing market research, troubleshooting, protection against error, project
                  planning;
                </li>
                <br />
                <li>
                  To enforce the Company’s{" "}
                  <a href={routeNames.TERMSOFUSE} target="_blank">
                    Terms of Use
                  </a>
                  ;
                </li>
                <br />
                <li>
                  Compile anonymous / aggregate statistical data and analysis for use internally or
                  with third parties;
                </li>
                <br />
                <li>
                  Create and manage your account or generate a personal profile about you to make
                  future visits to the Application more personalized;
                </li>
                <br />
                <li>Email you regarding your account or order;</li>
                <br />
                <li>Enable user-to-user communications;</li>
                <br />
                <li>
                  Monitor and analyze usage and trends to improve your experience with the
                  Application;
                </li>
                <br />
                <li>
                  Offer new products, services, mobile applications, and/or recommendations to you;
                </li>
                <br />
                <li>Perform other activities as needed;</li>
                <br />
                <li>
                  Prevent fraudulent transactions, monitor against theft, and protect against
                  criminal activity;
                </li>
                <br />
                <li>Request feedback and contact you about your use of the Application;</li>
                <br />
                <li>Resolve disputes and troubleshoot problems; and</li>
                <br />
                <li>Respond to product and customer service requests.</li>
                <br />
              </ol>
              <br />
              <Text style={{ fontWeight: "bold" }}>
                2.2.&nbsp;&nbsp;SALE OF ASSETS, MERGER, ACQUISITION, BANKRUPTCY
              </Text>
              <br />
              Information collected from you may be transferred to a third party as a result of a
              sale or acquisition, merger or bankruptcy involving the Company.
              <br />
              <br />
              <Text style={{ fontWeight: "bold" }}>2.3.&nbsp;&nbsp;COOKIES AND WEB BEACONS</Text>
              <br />
              We may use cookies, web beacons, tracking pixels, and other tracking technologies on
              the Application to help customize the Application and improve your experience. When
              you access the Application, your personal information is not collected through the use
              of tracking technology. Most browsers are set to accept cookies by default. You can
              remove or reject cookies, but be aware that such action could affect the availability
              and functionality of the Application. You may not decline web beacons. However, they
              can be rendered ineffective by declining all cookies or by modifying your web
              browser’s settings to notify you each time a cookie is tendered, permitting you to
              accept or decline cookies on an individual basis.
              <br />
              <br />
              <Text style={{ fontWeight: "bold" }}>2.4.&nbsp;&nbsp;WEBSITE ANALYTICS</Text>
              <br />
              We may also partner with selected third-party vendors, such as Google Analytics and
              CleverTap to allow tracking technologies and remarketing services on the Application
              through the use of first party cookies and third-party cookies, to, among other
              things, analyze and track users’ use of the Application, determine the popularity of
              certain content, and better understand online activity. By accessing the Application,
              you consent to the collection and use of your information by these third-parties. You
              are encouraged to review their privacy policy and contact them directly for responses
              to your questions.
              <br />
              You should be aware that getting a new computer, installing a new browser, upgrading
              an existing browser, or erasing or otherwise altering your browser’s cookies files may
              also clear certain opt-out cookies, plug-ins, or settings.
              <br />
              <br />
              <li>
                <Text style={styles.heading}>SHARING OF INFORMATION</Text>
              </li>
              <br />
              <Text style={{ fontWeight: "bold" }}>3.1&nbsp;&nbsp;Sharing</Text>
              <ol type="a">
                <li>Subsidiaries, Employees, and Independent Contractors:</li>
                We may disclose information about you to our subsidiaries, our employees, and
                individuals who are our independent contractors that need to know the information in
                order to help us provide our Services or to process the information on our behalf.
                We require our subsidiaries, employees, and independent contractors to follow this
                Privacy Policy for personal information that we share with them.
                <br />
                <li>Third Party Vendors:</li>
                We may share information about you with third party vendors who need to know
                information about you in order to provide their services to us, or to provide their
                services to you or your site. This group includes vendors that help us provide our
                Services to you (like fraud prevention services that allow us to analyze fraudulent
                transactions, postal and email delivery services that help us stay in touch with
                you, customer chat and email support services that help us communicate with you,
                registrars, registries, and data escrow services that allow us to provide
                registration services, and your hosting provider if your site is not hosted by
                Socion), those that assist us with our notification or communication efforts (e.g.
                by providing tools for identifying a specific communication target group or
                improving our communication campaigns), those that help us understand and enhance
                our Services (like analytics providers), and companies that make products available
                on our websites (such as the extensions), who may need information about you in
                order to, for example, provide technical or other support services to you.
                <br />
                <li>With Your Consent:</li>
                <br />
                <ol type="i">
                  <li>
                    We offer a set of services and applications that are used by programs
                    (Participating Program) and participants of such programs, to interact with each
                    other, track participation or attendance, distribute or access training content
                    and so on. Every time you participate in a (training or other) session run by a
                    program using the Application (<b>“Participating Program”</b>), in order to
                    track interactions with participants (For example; attending a training
                    session), you are providing us consent to share your personal information, with
                    such Participating Programs. Such information once shared with the programs will
                    be outside the control of the Company and will no longer be subject to this
                    Privacy Policy or our Terms of Use. Any use of such data, once shared with each
                    program or in the possession of such program after each session, is subject to
                    the privacy agreement between you, the participant, and the entities running
                    that program.
                  </li>
                  <br />
                  <li>
                    One key element of our Services is to democratize access to knowledge and
                    training content required to solve societal challenges.All the content
                    (including Training Content) published on the Application by the Participating
                    Program, their representatives and partners will be openly available for use,
                    may be shared openly without any limitations of use, and is licensed under the
                    creative commons license framework (CC-BY 4.0) as an open learning resource.If
                    you do not wish to openly share, or have reason to believe that any content you
                    intend to use in your training session should not be shared openly, DO NOT
                    upload any such information or training content into this application.You can
                    still use and take advantage of the services minus such content.
                  </li>
                </ol>
                <br />
                <li>Aggregated or De-Identified Information:</li>
                We may share information that has been aggregated or reasonably de-identified, so
                that the information could not reasonably be used to identify you. For instance, we
                may publish aggregate statistics about the use of our Services and insights that
                help enable organizations or initiatives to track progress and improve methods or
                outcomes.
                <br />
                <li>Published Support Requests:</li>
                If you send us a request (for example, via a support email or one of our feedback
                mechanisms), we reserve the right to publish that request in order to help us
                clarify or respond to your request or to help us support other users.
                <br />
              </ol>
              <br />
              <Text style={{ fontWeight: "bold" }}>3.2&nbsp;&nbsp;Consulting</Text>
              The Company may partner with another party to provide specific Services if required.
              When you sign-up for the Services, the Company will share names, or other Personal
              Information that is necessary for the third party to provide these Services.
              <br />
              The Company does not allow any unauthorized persons or organization to use any
              information that the Company may collect from you through this Application.
              <br />
              <br />
              <Text style={{ fontWeight: "bold" }}>3.3&nbsp;&nbsp;Regulatory Disclosures</Text>
              In the event the Company is required to respond to court orders or other legal process
              or for any tax authorities, your Personal Information may be disclosed pursuant to
              such court orders or legal process or tax authorities, which may be without notice to
              you. The Company may further disclose your Personal Information to such third parties
              to whom it transfers its rights and duties under any agreement entered into with such
              third parties and may also disclose your Personal Information to any of its affiliates
              or related entity.
              <br />
              <br />
              <Text style={{ fontWeight: "bold" }}>
                3.4&nbsp;&nbsp;Interactions with Other Users
              </Text>
              If you interact with other users of the Application, those users may see your name,
              profile photo, and descriptions of your activity. For example, when you send
              invitations to other users, share attestations with other users, share your profile QR
              code with others, etc.
              <br />
              <br />
              <Text style={{ fontWeight: "bold" }}>
                3.5&nbsp;&nbsp;Link to Third Party Applications
              </Text>
              This Application or content created or uploaded by users may contain links which may
              lead you to other applications. Please note that once you leave the Company’s
              Application you will be subjected to the privacy policy of the other application. The
              linked sites are not necessarily under the control of the Company. Please be aware
              that the Company is not responsible for the privacy practices of such other sites. The
              Company encourages you to read the privacy policies of each and every website that
              collects Personal Information. If you decide to access any of the third-party sites
              linked to the Application, you do this entirely at your own risk. Any links to any
              partner of the Company should be the responsibility of the linking party, and the
              Company will not be responsible for notification of any change in name or location of
              any information on the Application.
              <br />
              <br />
              <li>
                <Text style={styles.heading}>NON-DISCLOSURE OF INFORMATION</Text>
              </li>
              <br />
              <Text>
                4.1&nbsp;&nbsp;The Company pledges that it will not sell or rent your Personal
                Information to anyone and your Personal Information will be protected and maintained
                strictly confidential by the Company except in the following cases:
              </Text>
              <ol type="a" id="list-alpha">
                <li>
                  &nbsp;The Company may disclose your Personal Information in the event it is
                  required to do so by law, rule, regulation, law, enforcement, governmental
                  official, legal or regulatory authorities and, or, to such other statutory bodies
                  who have appropriate authorization to access the same for any specific legal
                  purposes;
                </li>
                <br />
                <li>
                  &nbsp;The Company may disclose your Personal Information in order to provide you
                  the Services. This includes exchanging information with other companies or
                  agencies.
                </li>
                <br />
                <li>
                  &nbsp;The Company may disclose your Personal Information to enforce or apply the
                  <a href={routeNames.TERMSOFUSE} target="_blank">
                    Terms of Use
                  </a>
                  , or to protect the rights, property or safety of the Company, its users or
                  others. This includes exchanging information with other companies / agencies (For
                  example; an agency that provides fraud prevention services)
                </li>
                <br />
                <li>
                  &nbsp;The Company may disclose your Personal Information to such third parties to
                  whom it transfers its rights and duties under any agreement entered into with such
                  third parties; and
                </li>
                <br />
                <li>
                  &nbsp;The Company may disclose your Personal Information to any of its affiliates
                  or related entity.
                </li>
                <br />
              </ol>
              <li>
                <Text style={styles.heading}>SECURITY OF INFORMATION</Text>
              </li>
              <br />
              <Text>
                5.1&nbsp;&nbsp;The Company has put in place appropriate methods and managerial
                procedures to safeguard and secure such Personal Information. The Company only
                processes Personal Information in a way that is compatible with and relevant for the
                purpose for which it was collected or authorized by the user. The Application allows
                users access to their Personal Information and allows them to correct, amend or
                delete inaccurate information.
              </Text>
              <br />
              <br />
              <Text>
                5.2&nbsp;&nbsp;The Company uses commercially reasonable precautions to preserve the
                integrity and security of your information against loss, theft, unauthorised access,
                disclosure, reproduction, use or amendment.
              </Text>
              <br />
              <br />
              <Text>
                5.3&nbsp;&nbsp;The information that is collected from you may be transferred to,
                stored and processed at any destination within and / or outside India. By submitting
                information on the Application, you agree to this transfer, storing and / or
                processing. The Company will take such steps as it considers reasonably necessary to
                ensure that your information is treated securely and in accordance with this Privacy
                Policy.
              </Text>
              <br />
              <br />
              <Text>
                5.4&nbsp;&nbsp;In using the Application, you accept the inherent security
                implications of data transmission over the internet. Therefore, the use of the
                Application will be at your own risk and the Company assumes no liability for any
                disclosure of information due to errors in transmission, unauthorised third party
                access or other acts of third parties, or acts or omissions beyond its reasonable
                control and you agree not to hold the Company responsible for any breach of
                security.
              </Text>
              <br />
              <br />
              <Text>
                5.5&nbsp;&nbsp;In the event the Company becomes aware of any breach of the security
                of your information, it will promptly notify you and take appropriate action to the
                best of its ability to remedy such a breach.
              </Text>
              <br />
              <br />
              <li>
                <Text style={styles.heading}>EXCLUSION</Text>
              </li>
              <br />
              <Text>
                6.1&nbsp;&nbsp;This Privacy Policy does not apply to any information other than
                information collected by the Company through the Application including such
                information collected in accordance with the clause on “Collection of Information”
                above. This Privacy Policy will not apply to any unsolicited information provided by
                you through this Application or through any other means. This includes, but is not
                limited to, information posted on any public areas of the Application. All such
                unsolicited information shall be deemed to be non-confidential and the Company will
                be free to use, disclose such unsolicited information without limitation.
              </Text>
              <br />
              <br />
              <Text>
                6.2&nbsp;&nbsp;The Company also protects your Personal Information off-line other
                than as specifically mentioned in this Privacy Policy. Access to your Personal
                Information is limited to employees, agents or partners and third parties, who the
                Company reasonably believes will need that information to enable the Company to
                provide Services to you. However, the Company is not responsible for the
                confidentiality, security or distribution of your own Personal Information by any of
                our partners, Participating Program or third parties which is conducted outside the
                scope of our agreement with such partners and third parties.
              </Text>
              <br />
              <br />
              <li>
                <Text style={styles.heading}>DATA RETENTION</Text>
              </li>
              <br />
              <Text>
                7.1&nbsp;&nbsp;We shall not retain Personal Information longer than is necessary to
                fulfil the purposes for which it was collected and as permitted by applicable law.
                Even after your account is terminated, the Company may retain your Personal
                Information as needed to comply with our legal and regulatory obligations, resolve
                disputes, conclude any activities related to cancellation of an account, investigate
                or prevent fraud and other inappropriate activity, to enforce our agreements, and
                for other business reason.
              </Text>
              <br />
              <br />
              <li>
                <Text style={styles.heading}>RIGHT TO LIMIT ACCESS</Text>
              </li>
              <br />
              You have several choices available when it comes to controlling the information in our
              possession about you:
              <br />
              <br />
              <Text>
                8.1&nbsp;&nbsp;Limit the Information that You Provide: If you have an account with
                us, you can choose not to provide the optional account or profile information.
                Please keep in mind that if you do not provide this information, certain features of
                our Services may not be accessible.
              </Text>
              <br />
              <br />
              <Text>
                8.2&nbsp;&nbsp;Limit Access to Information on Your Mobile Device: Your mobile device
                operating system should provide you with the ability to discontinue our ability to
                collect stored information or location information via our mobile apps. If you do
                so, you may not be able to use certain features (like adding a location, accessing a
                file or image on your Device for example).
              </Text>
              <br />
              <br />
              <Text>
                8.3&nbsp;&nbsp;Set Your Browser to Reject Cookies: At this time, Socion does not
                respond to “do not track” signals across all of our Services. However, you can
                usually choose to set your browser to remove or reject browser cookies before using
                Socion’s websites, with the drawback that certain features of Socion’s websites may
                not function properly without the aid of cookies.
              </Text>
              <br />
              <br />
              <Text>
                8.4&nbsp;&nbsp;Close Your Account: If you no longer want to use our Services you can
                close your account. Please keep in mind that we may continue to retain your
                information after closing your account, in situation such as, when that information
                is reasonably needed to comply with or demonstrate our compliance with legal
                obligations such as law enforcement requests, or reasonably needed for our
                legitimate business interests.
              </Text>
              <br />
              <br />
              <li>
                <Text style={styles.heading}>DATA TRANSFER</Text>
              </li>
              <br />
              <Text>
                9.1&nbsp;&nbsp;The Company may transfer information that it collects about you,
                including Personal Information, to affiliated entities, or to other third parties
                across borders and from your country or jurisdiction to other countries or
                jurisdictions around the world. These countries may have data protection laws that
                are different to the laws of your country and, in some cases, may not be as
                protective. The Company shall take commercially reasonable steps or safeguards to
                require that your Personal Information will remain protected in accordance with this
                Privacy Policy.
              </Text>
              <br />
              <br />
              <li>
                <Text style={styles.heading}>POLICY FOR CHILDREN</Text>
              </li>
              <br />
              <Text>
                We do not knowingly solicit Personal Information from or market to children under
                the age of 18. If you become aware of any Personal Information, we have collected
                from children under age 18, please contact us using the contact information provided
                below.
              </Text>
              <br />
              <br />
              <li>
                <Text style={styles.heading}>NOTIFICATION OF CHANGES</Text>
              </li>
              <br />
              <Text>
                From time to time, the Company may update this Privacy Policy to reflect changes to
                its information practices. Any changes will be effective immediately upon the
                posting of the revised Privacy Policy. If the Company makes any material changes, it
                will notify you by email (sent to the e-mail address specified in your account) or
                by means of a notice on the Services prior to the change becoming effective. We
                encourage you to periodically review this page for the latest information on our
                privacy practices.
              </Text>
              <br />
              <br />
              <li>
                <Text style={styles.heading}>OPT OUT PROCEDURES</Text>
              </li>
              <br />
              <Text>
                Upon initial communication from the Company, you may opt-out of receiving further
                communications from the Company by simply selecting ‘unsubscribe’ in the
                communication. If you are using an e-mail forwarding service or other similar
                service please make sure to include the correct e-mail address or addresses.If you
                continue to receive communication from the Participating Programs., kindly get in
                touch with the respective programs or entities directly in order to opt-out of their
                mailing lists.
              </Text>
              <br />
              <br />
              <li>
                <Text style={styles.heading}>GRIEVANCE OFFICER</Text>
              </li>
              <br />
              <Text>
                In accordance with the Information Technology Act, 2000 and the rules made
                thereunder, the name and contact details of the Grievance Officer are provided
                below:
              </Text>
              <Text>
                <b>Name: </b>Ramakrishna Kalluri
              </Text>
              <Text>
                <b>Designation: </b>Partner
              </Text>
              <Text>
                <b>Contact Number: </b>
                <span>+91-9900221154</span>
              </Text>
              <Text>
                <b>Email Id: </b>
                <span>PDA-Grievances@socion.io</span>
              </Text>
              <br />
              <br />
              <li>
                <Text style={styles.heading}>ADDRESS FOR PRIVACY QUESTIONS</Text>
              </li>
              <br />
              <Text>
                Should you have questions about this Privacy Policy or Company’s information
                collection, use and disclosure practices, you may contact us at:
                <br />
                PDA-Contact @socion.io. We will use reasonable efforts to respond promptly to
                requests, questions or concerns you may have regarding our use of Personal
                Information about you.Except where required by law, the Company cannot ensure a
                response to questions or comments regarding topics unrelated to this policy or
                Company’s privacy practices.
              </Text>
              <br />
            </ol>
            <Text
              style={{
                textDecorationLine: "underline",
                textAlign: "center",
                fontWeight: "bold",
                fontSize: "16px"
              }}
            >
              YOU HAVE READ THIS PRIVACY POLICY AND AGREE TO ALL OF THE PROVISIONS CONTAINED ABOVE.
            </Text>
          </View>
          <br />
        </View>
      </View>
      {/* </View>
        </View>
      </View> */}
    </View>
  );
}
const styles = StyleSheet.create({
  profileCol: {
    marginTop: "20px",
    width: "84%",
    marginLeft: "auto",
    marginRight: "auto"
  },
  textCenter: {
    textAlign: "center"
  },
  title: {
    fontSize: 16,
    fontWeight: "bold",
    textDecorationLine: "underline"
  },
  body: {
    textAlign: "justify",
    fontSize: 16
  },
  lineHeight: {
    // lineHeight: "inherit"
  },
  heading: {
    fontSize: 16,
    fontWeight: "bold",
    textDecorationLine: "underline",
    textAlign: "start"
  }
});
