import Icon, { IconNames } from "@socion-cordio/common/src/components/atoms/icon";
import {
  Text,
  TextSize,
  FontWeight,
  FontFamily
} from "@socion-cordio/common/src/components/atoms/text";
import TextField from "@socion-cordio/common/src/components/atoms/textField";
import { colorPallete } from "@socion-cordio/common/src/assets/styles/colors";
import React, { ReactElement, useEffect, useState } from "react";
import { View, StyleSheet, StyleProp, TextStyle, ViewStyle, TouchableOpacity } from "react-native";
import Loader from "@socion-cordio/common/src/components/atoms/loader";

interface IProps {
  onChange?: (value: any) => void;
  contentText?: string;
  profileUpload?: boolean;
  customDocsContainer?: any;
  acceptedFormat?: string;
  textStyles?: any;
  isDisabled?: boolean;
}

export default function FilePicker(props: IProps): ReactElement {
  const {
    onChange,
    contentText,
    profileUpload,
    customDocsContainer,
    acceptedFormat = "",
    textStyles,
    isDisabled = false
  } = props;

  const [disabled, setDisabled] = useState(false);

  const hiddenFileInput = React.useRef(null);

  const handleClick = (event: any) => {
    hiddenFileInput.current.click();
  };

  useEffect(() => {
    setDisabled(props.isDisabled);
  }, [props.isDisabled]);

  const handleOnClick = (event: any) => {
    const { target = {} } = event || {};
    target.value = "";
  };

  return (
    <View
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        width: "100%"
      }}
    >
      <TouchableOpacity
        // style={{ zIndex: -1 }}
        onPress={handleClick}
        style={isDisabled ? { width: "90%" } : { width: "100%" }}
      >
        <View
          style={
            !isDisabled
              ? [styles.uploadDocsContainer, customDocsContainer, { width: "100%" }]
              : [styles.uploadDocsContainer, customDocsContainer, { width: "90%" }]
          }
        >
          <Text
            fontWeight={FontWeight.Regular}
            testId="addRoleTitleText"
            textSize={TextSize.Small}
            textStyle={[styles.uploadHeaderText, textStyles]}
          >
            <input
              disabled={disabled}
              style={{
                display: "none",
                zIndex: -1
              }}
              ref={hiddenFileInput}
              type="file"
              onChange={onChange}
              accept={acceptedFormat}
              onClick={handleOnClick}
            />
            {contentText || `Upload supporting documents`}
          </Text>
          {!profileUpload && (
            <Icon testID="close" name={IconNames.uploadFile} customStyle={styles.uploadDocIcon} />
          )}
        </View>
      </TouchableOpacity>
      {isDisabled && <Loader customLoadingContainer={styles.loader} />}
    </View>
  );
}

const styles = StyleSheet.create({
  uploadDocsContainer: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row",
    alignItems: "center",
    backgroundColor: "#F8F8F8",
    borderRadius: 5,
    borderColor: "#F8F8F8",
    height: "64px",
    padding: 15,
    marginTop: 15
  },
  uploadDocIcon: {
    opacity: 0.5
  },
  uploadHeaderText: {
    fontWeight: "400",
    fontSize: 12,
    fontFamily: FontFamily.Regular,
    lineHeight: 17,
    color: colorPallete.textLight
  },
  loader: {
    marginTop: 30,
    width: "10%",
    height: 20,
    transform: [{ scale: 0.8 }]
  }
});
