import { ViewSession } from "@socion-cordio/common/src/models/viewSession";
import { SESSION } from "@socion-cordio/common/src/modules/session/actions/actionTypes";

export interface ISessionLoading {
  getCompleteSession: boolean;
}
export interface ISessionError {
  getCompleteSession: string;
}
export interface ISessionState {
  completeSessionData: ViewSession;
  error: ISessionError;
  loading: ISessionLoading;
}

export const sessionsInitialState: ISessionState = {
  completeSessionData: null,
  error: {
    getCompleteSession: ""
  },
  loading: { getCompleteSession: false }
};

const sessionsReducer = (
  state = sessionsInitialState,
  action: { payload: any; type: string }
): ISessionState => {
  switch (action.type) {
    case SESSION.GET_COMPLETE_SESSION.LOADING:
      return {
        ...state,
        loading: { ...state.loading, getCompleteSession: true },
        error: { ...state.error, getCompleteSession: null }
      };
    case SESSION.GET_COMPLETE_SESSION.SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, getCompleteSession: false },
        error: { ...state.error, getCompleteSession: null },
        completeSessionData: action.payload
      };
    case SESSION.GET_COMPLETE_SESSION.ERROR:
      return {
        ...state,
        loading: { ...state.loading, getCompleteSession: false },
        error: { ...state.error, getCompleteSession: action.payload }
      };
    default:
      return state;
  }
};
export default sessionsReducer;
