import { USER } from "@socion-cordio/common/src/modules/user-role/actions/actionTypes";
import { UserRolesProgram } from "@socion-cordio/common/src/models/userRolesProgram";

export interface IUserRoleProgramLoading {
  getUserRolesProgram: boolean;
}
export interface IUserRoleProgramError {
  getUserRolesProgram: string;
}
export interface IUserRoleProgramState {
  userRolesData: UserRolesProgram;
  error: IUserRoleProgramError;
  loading: IUserRoleProgramLoading;
}

export const userRolesProgramInitialState: IUserRoleProgramState = {
  userRolesData: null,
  error: {
    getUserRolesProgram: ""
  },
  loading: { getUserRolesProgram: false }
};

const userRolesProgramReducer = (
  state = userRolesProgramInitialState,
  action: { payload: any; type: string }
): IUserRoleProgramState => {
  const rolesObject = JSON.parse(localStorage.getItem("userRoles"));
  state.userRolesData = rolesObject?.userRolesData;
  switch (action.type) {
    case USER.GET_USER_ROLES_PROGRAM.LOADING:
      return {
        ...state,
        loading: { ...state.loading, getUserRolesProgram: true },
        error: { ...state.error, getUserRolesProgram: null }
      };
    case USER.GET_USER_ROLES_PROGRAM.SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, getUserRolesProgram: false },
        error: { ...state.error, getUserRolesProgram: null },
        userRolesData: action.payload
      };
    case USER.GET_USER_ROLES_PROGRAM.ERROR:
      return {
        ...state,
        loading: { ...state.loading, getUserRolesProgram: false },
        error: { ...state.error, getUserRolesProgram: action.payload }
      };
    default:
      return state;
  }
};
export default userRolesProgramReducer;
