import { ActionHelper } from "@socion-cordio/common/src/utils/actionHelper";

const prefix = "participationDetails";
const PARTICIPATIONDETAILS = {
  GET_PARTICIPANT_LIST: ActionHelper.actionTypesCreator(prefix, "GET_PARTICIPANT_LIST"),
  GET_TRAINERS_LIST: ActionHelper.actionTypesCreator(prefix, "GET_TRAINERS_LIST"),
  GET_PARTICIPANT_ATTESTATION: ActionHelper.actionTypesCreator(
    prefix,
    "GET_PARTICIPANT_ATTESTATION"
  ),
  GET_TRAINER_ATTESTATION: ActionHelper.actionTypesCreator(prefix, "GET_TRAINER_ATTESTATION"),
  GET_TRAINER_BY_TOPIC: ActionHelper.actionTypesCreator(prefix, "GET_TRAINER_BY_TOPIC")
};

export { PARTICIPATIONDETAILS };
