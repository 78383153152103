import { Link, useHistory } from "react-router-dom";
import { allRoutesNames as routeNames } from "navigation/allRouteNames";
import React, { useEffect } from "react";
import MapRoutes from "./mapRoutes";
import { ReactElement } from "react";
import SignupOtp from "@socion-cordio/common/src/components/organisms/signup/signupOtp";
import ForgotPasswordOtp from "@socion-cordio/common/src/components/organisms/forgot-password/forgotPasswordOtp";
import ForgotPassword from "@socion-cordio/common/src/components/organisms/forgot-password/forgotPassword";
import SignupDetails from "@socion-cordio/common/src/components/organisms/signup/signupDetails";
import ResetPassword from "@socion-cordio/common/src/components/organisms/forgot-password/resetPassword";
import LoginRouter from "modules/login/navigation/loginRouter";
import TermsOfUse from "@socion-cordio/common/src/components/organisms/termsOfUse";
import PrivacyPolicy from "@socion-cordio/common/src/components/organisms/privacyPolicy";
import FileViewer from "@socion-cordio/web/src/modules/artefacts/components/fileViewer";
import LoginOtpForm from "@socion-cordio/common/src/components/organisms/login/loginOtpForm";
import SetForgotPasswordNewOtp from "@socion-cordio/common/src/components/organisms/forgot-password/forgot-password/setForgotPasswordNew";
import ForgotPasswordNewOtp from "@socion-cordio/common/src/components/organisms/forgot-password/forgot-password/forgotPasswordNewOtp";
import ForgotPasswordNew from "@socion-cordio/common/src/components/organisms/forgot-password/forgot-password/forgotPasswordNew";

export const publicRoutes = () => {
  const history = useHistory();

  useEffect(() => {
    if (
      !location.pathname.includes("/login/otp") &&
      (location.pathname.includes("/login") || location.pathname === "/")
    ) {
      history.push(routeNames.IAM.root + routeNames.IAM.login);
    }
  }, [location.pathname]);

  return [
    {
      path: routeNames.CONTACT,
      component: () => <div>Contact</div>,
      isExact: true
    },
    {
      path: routeNames.HEALTHCHECK,
      component: () => <div>HEALTHCHECK</div>,
      isExact: true
    },
    {
      path: routeNames.SIGNUPOTP,
      component: () => <SignupOtp />,
      isExact: true
    },
    {
      path: routeNames.LOGINOTP,
      component: () => <LoginOtpForm />,
      isExact: true
    },
    {
      path: routeNames.ABOUTUS,
      component: () => <div>About</div>,
      isExact: true
    },

    {
      path: routeNames.FORGOTPASSWORDOTP,
      component: () => <ForgotPasswordNewOtp />,
      isExact: true
    },
    {
      path: routeNames.FORGOTPASSWORD,
      component: () => <ForgotPasswordNew />,
      isExact: true
    },
    // {
    //   path: routeNames.SIGNUPDETAILS,
    //   component: () => <SignupDetails />,
    //   isExact: true
    // },
    {
      path: routeNames.TERMSOFUSE,
      component: () => <TermsOfUse />,
      isExact: true
    },
    {
      path: routeNames.PRIVACYPOLICY,
      component: () => <PrivacyPolicy />,
      isExact: true
    },
    // {
    //   path: routeNames.RESETPASSWORD,
    //   component: () => <ResetPassword />,
    //   isExact: true
    // },
    {
      path: routeNames.RESETPASSWORD,
      component: () => <SetForgotPasswordNewOtp />,
      isExact: true
    },
    {
      path: routeNames.PUBLIC_VIEW_PDF,
      component: () => <FileViewer />,
      isExact: false
    },

    {
      path: "/login",
      isExact: true,
      component: () => (
        <div>
          {/* Not Found Of Public Routes <br />{" "}
          <a href={`${routeNames.IAM.root}${routeNames.IAM.login}`}>
            <h3>Click here for login page</h3>
          </a> */}
        </div>
      )
    },
    {
      path: "/",
      isExact: true,
      component: () => (
        <div>
          {/* Not Found Of Public Routes <br />{" "}
          <a href={`${routeNames.IAM.root}${routeNames.IAM.login}`}>
            <h3>Click here for login page</h3>
          </a> */}
        </div>
      )
    }
  ];
};
export default function PublicRouter(): ReactElement {
  let finalPublicRoutes: Array<any> = [...LoginRouter(), ...publicRoutes()];
  return <MapRoutes routes={finalPublicRoutes} basePath="" />;
}
