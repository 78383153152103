import React, { ReactElement, useEffect, useState, useRef } from "react";
import { Form, Formik, FormikProps } from "formik";
import { StyleSheet, View, TouchableOpacity } from "react-native";
import { colorPallete } from "@socion-cordio/common/src/assets/styles/colors";
import { Text, TextSize, FontWeight } from "@socion-cordio/common/src/components/atoms/text";
import * as Yup from "yup";
import Button, { ButtonType } from "@socion-cordio/common/src/components/atoms/button";
import { Otp } from "@socion-cordio/common/src/components/atoms/otp";
import Socion from "@socion-cordio/common/src/components/organisms/Socion";
import { useLocation, useHistory } from "react-router-dom";
import { ApiClient } from "@socion-cordio/common/src/network/apiClient";
import { signupEndPoints } from "@socion-cordio/common/src/repositories/endPoints";
import { ToastContainer, toast } from "react-toastify";
import AesUtil from "@socion-cordio/common/src/utils/encryptionHelper";
import { HTTP_STATUS_CODES } from "@socion-cordio/common/src/network/constants";
import { allRoutesNames } from "@socion-cordio/web/src/navigation/allRouteNames";

interface Props {
  fetchSignupOtp?: Function;
  history?: any;
}

export default function SignupOtp(props: Props): ReactElement {
  const location: any = useLocation();
  const history: any = useHistory();
  const [mobile, setMobile] = useState("");

  const formValidaionSchema = Yup.object({
    // otp: Yup.number().required("Please enter otp").nullable()
  });

  const saveSignupOtp = async (values: { otp: number }) => {
    const {
      countryCode,
      phoneNumber,
      registerKey,
      name,
      verificationType,
      emailId,
      emailVerification
    } = location.state;
    const aesUtil: AesUtil = new AesUtil();
    let payload: any = null;
    if (verificationType === "EMAIL_ID") {
      payload = {
        phoneNumber: phoneNumber,
        otp: aesUtil.encrypt(values.otp.toString()),
        typeOfOtp: "Registration-OTP",
        countryCode: countryCode,
        emailId: emailId,
        verificationType: verificationType
      };
    } else {
      payload = {
        phoneNumber: phoneNumber,
        otp: aesUtil.encrypt(values.otp.toString()),
        typeOfOtp: "Registration-OTP",
        countryCode: countryCode,
        verificationType: verificationType
      };
    }
    const validateOtpResponse = await ApiClient.post(signupEndPoints.validateOtp(), payload);
    if (validateOtpResponse?.responseCode === HTTP_STATUS_CODES.ok) {
      if (verificationType === "EMAIL_ID") {
        history.push(allRoutesNames.SIGNUPDETAILS, {
          countryCode: countryCode,
          phoneNumber: phoneNumber,
          name: name,
          registerKey: registerKey,
          verificationType: "EMAIL_ID",
          emailId: emailId,
          emailVerification: emailVerification
        });
      } else {
        history.push(allRoutesNames.SIGNUPDETAILS, {
          countryCode: countryCode,
          phoneNumber: phoneNumber,
          name: name,
          registerKey: registerKey,
          verificationType: "PHONE_NUMBER",
          emailId: emailId,
          emailVerification: emailVerification
        });
      }
    } else {
      toast.error(validateOtpResponse.message);
    }
  };

  const resendOtp = async () => {
    const { countryCode, phoneNumber, registerKey, verificationType, emailId, emailVerification } =
      location.state;
    let otpResponse: any = null;
    let payload: any = null;
    if (verificationType === "EMAIL_ID") {
      payload = {
        phoneNumber: phoneNumber,
        countryCode: countryCode,
        emailId: emailId,
        registrationKey: registerKey,
        verificationType: verificationType
      };
    } else {
      payload = {
        phoneNumber: phoneNumber,
        countryCode: countryCode,
        registrationKey: registerKey,
        verificationType: verificationType
      };
    }

    otpResponse = await ApiClient.post(signupEndPoints.resendOtp(), payload);

    if (otpResponse?.responseCode === HTTP_STATUS_CODES.ok) {
      toast.success(otpResponse.message);
    } else {
      toast.error(otpResponse.message);
    }
  };

  const handleValidate = (values: any) => {
    let errors: any = {};
    let isValidOtp = /^\d+$/.test(values.otp);
    if (!isValidOtp) errors.otp = "Invalid characters";
    return errors;
  };

  return (
    <View style={styles.mainContainer}>
      {/* <Socion /> */}
      <View style={styles.formContainer}>
        <Text
          fontWeight={FontWeight.Bold}
          testId="login"
          textSize={TextSize.Large}
          textStyle={styles.heading}
        >
          Sign Up
        </Text>
        <View style={styles.actionContainer}>
          {location.state.emailVerification ? (
            <Text
              fontWeight={FontWeight.Regular}
              testId="forgot"
              textSize={TextSize.Small}
              textStyle={styles.message}
            >
              Please enter the code we have sent to your email
            </Text>
          ) : (
            <Text
              fontWeight={FontWeight.Regular}
              testId="forgot"
              textSize={TextSize.Small}
              textStyle={styles.message}
            >
              Please enter the code we have sent to{" "}
              {location?.state?.phoneNumber?.replace(/\d(?=\d{4})/g, "*")}
            </Text>
          )}

          <Formik
            initialValues={{ otp: null }}
            onSubmit={(values, { setSubmitting }) => {
              saveSignupOtp(values);
              setSubmitting(false);
            }}
            // validationSchema={formValidaionSchema}
            validate={handleValidate}
            enableReinitialize={true}
          >
            {(
              formikProps: FormikProps<{
                otp: number;
              }>
            ) => (
              <Form>
                <View style={styles.otpContainer}>
                  <Otp
                    id="otp"
                    name="otp"
                    handleTextChange={formikProps.handleChange("otp")}
                    //handleBlur={() => formikProps.setFieldTouched}
                  />
                </View>

                <Text
                  fontWeight={FontWeight.Regular}
                  testId="forgot"
                  textSize={TextSize.Small}
                  textStyle={styles.resend}
                >
                  Haven't received a message?
                </Text>
                <View>
                  <TouchableOpacity>
                    <Text
                      fontWeight={FontWeight.Regular}
                      testId="resendOtp"
                      textSize={TextSize.Small}
                      textStyle={styles.resendOtp}
                      onPress={resendOtp}
                    >
                      Resend OTP
                    </Text>
                  </TouchableOpacity>
                </View>
                <Button
                  type={ButtonType.Primary}
                  buttonStyles={styles.button}
                  title="Verify and proceed"
                  onPress={() => {
                    formikProps.handleSubmit();
                  }}
                  disabled={!formikProps.isValid}
                />
              </Form>
            )}
          </Formik>
        </View>
        <View style={styles.footer}>
          <Text
            fontWeight={FontWeight.Regular}
            testId="login"
            textSize={TextSize.Small}
            textStyle={styles.forgot}
          >
            Already have an account?
            {"  "}
            <View>
              <Text
                fontWeight={FontWeight.Regular}
                testId="login"
                textSize={TextSize.Small}
                textStyle={styles.login}
                onPress={() => history.push(allRoutesNames.IAM.root + allRoutesNames.IAM.login)}
              >
                Login
              </Text>
            </View>
          </Text>
        </View>
      </View>
      <ToastContainer />
    </View>
  );
}
const styles = StyleSheet.create({
  otpContainer: {
    marginTop: 20,
    marginBottom: 40,
    marginRight: 82
  },
  formContainer: {
    width: "35%",
    // height: "100vh",
    backgroundColor: colorPallete.white,
    position: "absolute",
    // right: 0,
    // borderBottomLeftRadius: 20,
    // borderTopLeftRadius: 20
    borderRadius: 20,
    border: "1px solid rgb(194, 188, 185)"
  },
  heading: {
    marginTop: 120,
    marginLeft: 80,
    fontSize: 40
  },
  button: {
    marginTop: 40,
    height: 50,
    width: 304,
    marginRight: 100
  },
  actionContainer: {
    marginTop: 80,
    marginLeft: 80
  },
  forgot: {
    fontWeight: "400",
    fontSize: 12
  },
  footer: {
    marginVertical: 50,
    marginBottom: 30,
    marginStart: 155
  },
  message: {
    fontSize: 14,
    fontWeight: "400",
    marginRight: 100
  },
  resend: {
    fontSize: 14,
    fontWeight: "500",
    marginRight: 100
  },
  resendOtp: {
    fontSize: 14,
    fontWeight: "500",
    marginRight: 100,
    textDecorationLine: "underline",
    color: colorPallete.cordioRed
  },
  login: {
    fontWeight: "400",
    fontSize: 12,
    position: "relative",
    zIndex: -1,
    textDecorationLine: "underline",
    color: colorPallete.cordioRed
  },
  mainContainer: {
    display: "flex",
    alignItems: "center",
    marginTop: 30
  }
});
