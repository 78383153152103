import BrowserTypeFinder from "@socion-cordio/common/src/utils/browserType";
import { toast } from "react-toastify";
import { TelementryRepository } from "@socion-cordio/common/src/repositories/telemetry/telemtry";

export default async function AddTelemetryService(
  telemetryItems: any,
  programDetails: any = undefined,
  userDetails: any = undefined,
  isGeoLocationCoordsPresent?: boolean
) {
  try {
    const constantsBody = {
      offlineToOnlineSync: "false",
      appType: "Web App",
      browserType: BrowserTypeFinder()
    };
    const body = {
      // ...constantsBody,
      // ...telemetryItems,

      // sent from components (which are common things)
      // if programDetails/userDetails present, then it will go
      // else will not go

      // Program Details
      programName: programDetails?.program?.name,
      programId: programDetails?.program?.id,
      entityId: programDetails?.program?.entity?.registryEntityId,
      entityName: programDetails?.program?.entity?.name,

      // User details
      userId: userDetails?.userId,
      baseLocationCity: userDetails?.city,
      baseLocationDistrict: userDetails?.district,
      baseLocationSubDistrict: userDetails?.subDistrict,
      baseLocationState: userDetails?.state,
      baseLocationCountry: userDetails?.country,
      baseLocationLatitude: userDetails?.baseLocationLat,
      baseLocationLongitude: userDetails?.baseLocationLong,
      establishmentName: userDetails?.establishmentName,

      ...constantsBody,
      ...telemetryItems
    };
    if (isGeoLocationCoordsPresent === true) {
      const showPosition = (position: any) => {
        const latLongCoords = {
          ...body,
          sessionLat: position.coords.latitude,
          sessionLon: position.coords.longitude
        };
        TelementryRepository.updateTelemetry(latLongCoords);
      };
      navigator.geolocation.getCurrentPosition(showPosition);
    } else {
      TelementryRepository.updateTelemetry(body);
    }
  } catch (error) {
    // user should not know telemtry
    toast.error("Insufficent Information.");
  }
}
