// import { useDispatch } from "react-redux";
// import { EventEmitter } from "react-native";
import { profileEndPoints } from "@socion-cordio/common/src/repositories/endPoints";
import { ApiClient } from "@socion-cordio/common/src/network/apiClient";
import { functions, get, replace } from "lodash";

interface Scripts {
  name: string;
  src: string;
}

export const ScriptStore: Scripts[] = [
  { name: "MapmyIndia", src: `https://apis.mapmyindia.com/advancedmaps/api/{key}/map_sdk_plugins` }
];

// export default function ScriptloadService() {
let scripts: any = {};
let key = "";

//   isKeyAvailable = new EventEmitter();
//   dispatch = useDispatch();

export const preLoadScriptFiles = () => {
  ScriptStore.forEach((script: any) => {
    if (script.name === "MapmyIndia") {
      getKey(script);
    }
  });
};

export const getKey = async (script: any) => {
  try {
    const response = await ApiClient.get(profileEndPoints.getMapMyIndiaToekn());
    const { access_token } = response?.response;
    const key = access_token;
    addScript(script, key);
  } catch (err) {
    console.log("getkey", err);
    // this.isKeyAvailable.emit("")
  }
};

export const addScript = (script: any, token?: any) => {
  scripts[script.name] = {
    loaded: false,
    src: token ? replace(script.src, "{key}", token) : script.src
  };
  load("MapmyIndia");
};

export const load = (...scripts: string[]) => {
  const promises: any[] = [];
  scripts.forEach((script) => promises.push(loadScript(script)));
  return Promise.all(promises);
};

export const loadScript = (name: string) => {
  return new Promise((resolve, reject) => {
    if (scripts[name].loaded) {
      resolve({ script: name, loaded: true, status: "Already Loaded" });
    } else {
      // load script
      const script = document.createElement("script");
      script.type = "text/javascript";
      script.src = scripts[name].src;
      script.onload = () => {
        scripts[name].loaded = true;
        resolve({ script: name, loaded: true, status: "Loaded" });
      };
      script.onerror = (error: any) => resolve({ script: name, loaded: false, status: "Loaded" });

      document.getElementsByTagName("body")[0].appendChild(script);
    }
  });
};
// }
