import { createSelector, Selector } from "reselect";
import { Login } from "@socion-cordio/common/src/models/login";
import { IState } from "@socion-cordio/common/src/store/interfaces";
import {
  ILoginError,
  ILoginLoading,
  ILoginState
} from "@socion-cordio/common/src/modules/login/reducers/loginReducer";

const loginSelect = (state: IState): ILoginState => state.login;
const selectLoginData: Selector<IState, Login> = createSelector(
  loginSelect,
  (login) => login.loginData
);
const selectLoading: Selector<IState, ILoginLoading> = createSelector(
  loginSelect,
  (login) => login.loading
);
const selectLoginError: Selector<IState, ILoginError> = createSelector(
  loginSelect,
  (login) => login.error
);

export const LoginSelectors = { selectLoginData, selectLoading, selectLoginError };
