import Button, { ButtonType } from "@socion-cordio/common/src/components/atoms/button";
import React, { useState } from "react";
import styled from "styled-components";
import { StyleSheet, View } from "react-native";
import {
  FontFamily,
  FontWeight,
  Text,
  TextSize
} from "@socion-cordio/common/src/components/atoms/text";
import { colorPallete } from "@socion-cordio/common/src/assets/styles/colors";
import TextArea from "@socion-cordio/common/src/components/atoms/textArea";
import TextField from "@socion-cordio/common/src/components/atoms/textField";
import { useSelector } from "react-redux";
import _, { get, replace, split, template } from "lodash";
//import { Form, Formik, FormikProps } from "formik";
import * as Yup from "yup";
import CancelSessionModal from "@socion-cordio/common/src/components/organisms/common-modals/cancelSessionModal";
import { allRoutesNames as routeNames } from "@socion-cordio/web/src/navigation/allRouteNames";
import { useHistory } from "react-router-dom";
import { ArtefactRepository } from "@socion-cordio/common/src/repositories/artefact/artefact";
import { displayname, getUserId } from "utils/artefactUserHelper";
import ArtefactDocs from "./artefactDocs";
import AddArtefactDocs from "./addArtefactDocs";
import { HTTP_STATUS_CODES } from "@socion-cordio/common/src/network/constants";
import { toast } from "react-toastify";
import { artefact_messages } from "../data/message";
import { startUploadDoc } from "../helpers/docHelper";
import { ArtefactApiClient } from "@socion-cordio/common/src/network/artefactApiClient";
import { artefactEndPoints } from "@socion-cordio/common/src/repositories/endPoints";

const AddArtefactWrapper = styled.div`
  border-radius: 5px;
  margin: 30px;
  min-height: 75vh;
  position: relative;
  .detailsWrapper {
    padding: 20px;
    border-radius: 5px;
    border: 1px solid rgba(63, 162, 2, 0.6);
    form {
      margin: 0 0;
    }
    .formGroup {
      display: grid;
      grid-template-columns: 1fr 3fr;
      grid-gap: 40px;
      padding: 6px 0;
      .artefactTypeInputWrapper {
        .hintWrapper {
          display: none;
        }
        input:focus ~ .hintWrapper {
          display: block;
        }
      }
      label {
        font-family: "Montserrat-Thin";
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
      }
      input,
      textArea {
        font-family: "Montserrat-Thin";
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        border-radius: 5px;
        border: 1px solid #675850;
        height: 40px;
        color: #231f20;
        text-indent: 10px;
        &:hover,
        &:focus,
        &:focus-visible {
          border: 1px solid #231f20;
          outline: none;
        }
      }
      textArea {
        text-indent: 0px;
        padding-left: 10px;
        height: auto;
      }
      ::placeholder {
        font-weight: 400;
      }
    }
    .toggleButton {
      display: flex;
      justify-content: flex-end;
      padding-top: 22px;
      .switch {
        position: relative;
        display: inline-block;
        width: 30px;
        height: 16px;
      }
      .switch input {
        opacity: 0;
        width: 0;
        height: 0;
      }
      .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #ccc;
        -webkit-transition: 0.4s;
        transition: 0.4s;
      }
      .slider:before {
        position: absolute;
        content: "";
        height: 14px;
        width: 14px;
        left: 2px;
        bottom: 1px;
        background-color: white;
        -webkit-transition: 0.4s;
        transition: 0.4s;
      }
      input:checked + .slider {
        background-color: #b7504a;
      }
      input:focus + .slider {
        box-shadow: 0 0 1px #b7504a;
      }
      input:checked + .slider:before {
        -webkit-transform: translateX(13px);
        -ms-transform: translateX(13px);
        transform: translateX(13px);
      }
      /* Rounded sliders */
      .slider.round {
        border-radius: 34px;
      }
      .slider.round:before {
        border-radius: 50%;
      }
    }
  }
  .buttonWrapper {
    display: flex;
    align-items: center;
    position: absolute;
    right: 20px;
    bottom: 20px;
    button {
      margin-left: 20px;
    }
  }
  @media (min-width: 767px) and (max-width: 900px) {
    .detailsWrapper {
      grid-template-columns: 1fr;
      grid-gap: 30px;
    }
  }
  @media (max-width: 767px) {
    .detailsWrapper {
      grid-template-columns: 1fr;
      grid-gap: 30px;
      .formGroup {
        grid-template-columns: 1fr;
        grid-gap: 8px;
        padding-bottom: 12px;
      }
      .tagBtnWrapper {
        display: block;
        .tagBtn {
          margin-right: 0px;
          margin-bottom: 12px;
          width: fit-content;
        }
      }
    }
  }
`;
const AddArtefactType = () => {
  const programData: any = JSON.parse(localStorage.getItem("programDetails"));
  const user = JSON.parse(localStorage.getItem("user"));
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [template, setTemplate] = useState([]);
  const [updatedFields, setUpdatedFields] = useState(null);
  const [artefactType, setArtefactType] = useState(null);
  const [cancelAddArtefactTypeSession, setCancelAddArtefactTypeSession] = useState(false);
  const history: any = useHistory();
  const [isUploading, setIsUploading] = useState(false);
  const [uploadedDocs, setUploadedDocs] = useState(true);
  const [fieldTouched, setFieldTouched] = useState(null);
  const [disabled, setDisabled] = useState(false);

  const {
    artefactTypesList: artefactTypes,
    loading: { getArtefactTypes: loading },
    error: { getArtefactTypes: artefactTypesError }
  } = useSelector((state: any) => state.artefactTypes);

  const cancel = (value: any) => {
    if (value === true) {
      setCancelAddArtefactTypeSession(false);
      // history.push(`${routeNames.app}${routeNames.workspace}`);
      history.push(`${routeNames.app}${routeNames.ARTEFACT_TYPES}`);
    }
  };

  const cancelAddArtefactTypeHandler = () => {
    setCancelAddArtefactTypeSession(true);
  };

  const addArtefactTypeHandler = async () => {
    setDisabled(true);
    let request: any = {
      title: title,
      desc: description,
      active: 1,
      created_by: displayname(),
      userId: getUserId(),
      templates: []
    };
    let response = await ArtefactRepository.addArtefactType(programData?.program?.id, request);
    console.log("response  onSaveHandler: ", response);
    if (response.code === HTTP_STATUS_CODES.created) {
      console.log("response in edit : save : ", response);
      const artefactTypeData = response?.data?.type[0];
      const docres = await uploadDocs(uploadedDocs, artefactTypeData);
      if (docres?.length > 0) {
        console.log("docresdocres", docres);
        request = {
          ...request,
          templates: docres || [],
          updated_at: new Date(),
          updated_by: displayname()
        };

        let response = await ArtefactApiClient.put(
          artefactEndPoints.editArtefactTypeDetails(programData?.program.id, artefactTypeData?.id),
          request
        );
        setDisabled(false);
        console.log("cbdsnfbdns", response);
      }
      toast.success(replace(artefact_messages.success.m04, "{name}", `"${title}"`));
      setDisabled(false);
      history.push(`${routeNames.app}${routeNames.ARTEFACT_TYPES}`);
    } else if (response.responseCode == HTTP_STATUS_CODES.conflict) {
      setDisabled(false);
      toast.error(response.message);
    } else if (response.responseCode == HTTP_STATUS_CODES.unauthorized) {
      toast.error(artefact_messages.error.m015);
      setDisabled(false);
      history.push(`${routeNames.IAM.root}${routeNames.IAM.login}`);
    } else {
      setDisabled(false);
      toast.error(artefact_messages.error.m012);
    }
  };

  const updateDocs = (docs: any) => {
    setUploadedDocs(docs);
    // console.log(" setUpdatedFields : ", updatedFields);
    // setUploadedDocsToDb(uploadDocsList);
    // setUpdatedFields({
    //   ...updatedFields,
    //   templates: uploadDocs
    // });
    // console.log(" setUploadedDocs : ", uploadDocs);
  };

  const updateTemplate = (docs: any, artefactTypeId: any) => {
    console.log("bndsbfNDSFD docs-----", docs);
    const uploadDocsList = _.map(docs, (file) => {
      const fileNameAndType = split(_.get(file, "Key"), `${artefactTypeId}/`);
      let fileName = fileNameAndType[1];
      const lastIndex = fileName.lastIndexOf("_");
      const fileNameSplit = fileName.substring(0, lastIndex);
      const extension = split(fileName.substring(lastIndex, fileName.length), ".");
      return {
        template_url: _.get(file, "Key"),
        template_name: fileNameSplit,
        display_name: fileNameSplit,
        mimetype: extension[1]
      };
    });
    console.log(" docs : ", docs);
    console.log(" uploaded docs : ", uploadDocsList);
    return uploadDocsList;
  };

  const uploadDocs = async (docs: any, request: any) => {
    try {
      if (docs?.length > 0) {
        const uploadRes = await startUploadDoc(
          docs,
          {
            id: request?.id,
            programId: programData?.program?.id,
            type: "artefact-type",
            docsCount: 0
          },
          request,
          request,
          user
        );
        return updateTemplate(uploadRes, request?.id);
      }
    } catch (err) {
      toast.error("Error while uploading docs.Please try again later...");
    }
  };

  const showError = (field: any, error: any) => {
    return (
      <Text
        testId={`${field}-error`}
        fontWeight={FontWeight.Regular}
        textSize={TextSize.Small}
        style={styles.errorMsg}
      >
        {error}
      </Text>
    );
  };

  const getDisabled = () => {
    if (isUploading || !title) {
      return true;
    }
    return false;
  };

  return (
    <View style={styles.mainContainer}>
      <View style={styles.subContainer}>
        <AddArtefactWrapper>
          <div className="detailsWrapper">
            <div>
              <div
                style={{
                  fontSize: 14,
                  fontFamily: FontFamily.Bold,
                  color: "#231F20",
                  paddingBottom: "20px"
                }}
              >
                Add Artefact Type
              </div>

              <div className="formGroup">
                <View style={styles.labelTextContainer}>
                  <Text
                    fontWeight={FontWeight.Regular}
                    testId="artefactType"
                    textSize={TextSize.Small}
                    textStyle={styles.subheaderText}
                  >
                    Artefact Type
                  </Text>
                </View>
                <View style={styles.inputTextContainer}>
                  <TextField
                    label=""
                    handleChange={(artefactType: string) => {
                      setFieldTouched({
                        ...fieldTouched,
                        title: true
                      });
                      setTitle(artefactType);
                    }}
                    handleBlur={() => {}}
                    value={title}
                    inputStyle={[styles.inputStylings, styles.inputStylingAlignment]}
                    placeholder="Enter Artefact Type Name"
                    name="artefactType"
                    id="artefactType"
                    noFormik
                  />
                  {fieldTouched?.title &&
                    !title &&
                    showError("title", "Please add artefact type title")}
                </View>
              </div>

              <div className="formGroup">
                <View style={styles.labelTextContainer}>
                  <Text
                    fontWeight={FontWeight.Regular}
                    testId="artefactTypeName"
                    textSize={TextSize.Small}
                    textStyle={styles.subheaderText}
                  >
                    Description
                  </Text>
                </View>
                <TextArea
                  inputStyle={[styles.inputStyling]}
                  numberOfLines={4}
                  multiline={true}
                  label=""
                  handleChange={(description: string) => {
                    setDescription(description);
                  }}
                  handleBlur={() => {}}
                  placeholder="Description"
                  name="description"
                  id="description"
                  noFormik
                />
              </div>

              <div className="buttonWrapper">
                <Button
                  type={ButtonType.Secondary}
                  buttonStyles={styles.button}
                  textStyles={styles.buttonFont}
                  title="Cancel"
                  onPress={() => cancelAddArtefactTypeHandler()}
                />
                <Button
                  type={ButtonType.Primary}
                  buttonStyles={styles.button}
                  textStyles={styles.buttonFont}
                  title="Save"
                  disabled={disabled || getDisabled()}
                  onPress={() => addArtefactTypeHandler()}
                />
              </div>
            </div>
          </div>
          <div
            style={{
              paddingBottom: "20px",
              paddingRight: "20px",
              width: "60%"
            }}
          >
            <div
              style={{
                fontSize: 14,
                fontFamily: FontFamily.Bold,
                color: colorPallete.textBlack,
                paddingTop: "20px"
              }}
            >
              Add Template
            </div>
            <AddArtefactDocs
              acceptedFormat={[".pdf", ".ppt", ".xls", ".txt", ".doc"]}
              uploadedDocs={(docs: any) => updateDocs(docs)}
              programId={programData?.program?.id}
              type={"artefact-type"}
              isUploading={(uploading: boolean) => {
                console.log("isUploadingisUploading", uploading);
                setIsUploading(uploading);
              }}
            ></AddArtefactDocs>
          </div>
        </AddArtefactWrapper>
      </View>

      {cancelAddArtefactTypeSession && (
        <View>
          <CancelSessionModal
            modalVisible={cancelAddArtefactTypeSession}
            sendData={cancel}
            setModalVisible={() => setCancelAddArtefactTypeSession(!cancelAddArtefactTypeSession)}
          />
        </View>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  mainContainer: {
    // top: 55,
    paddingBottom: 20,
    paddingHorizontal: 20,
    // paddingTop: 15,
    backgroundColor: colorPallete.cordioBeigeLight2,
    minHeight: "calc(100vh - 50px)"
  },
  subContainer: {
    backgroundColor: colorPallete.white,
    borderRadius: 10,
    shadowColor: colorPallete.cordioRedDark1,
    shadowOpacity: 0.1,
    shadowOffset: {
      height: 10,
      width: 5
    },
    shadowRadius: 10
  },
  button: {
    width: "140px",
    height: "50px",
    borderRadius: 10,
    marginLeft: "20px"
  },
  buttonFont: {
    fontSize: 14
  },
  labelTextContainer: {
    flex: 18
  },
  subheaderText: {
    fontWeight: "400",
    fontSize: 12,
    fontFamily: FontFamily.Regular,
    lineHeight: 17,
    color: colorPallete.textLight,
    marginTop: 13
  },
  inputStyling: {
    outline: "none",
    borderRadius: 5,
    padding: 7,
    fontSize: 12,
    fontFamily: FontFamily.Medium,
    fontWeight: "400"
  },
  inputTextContainer: {
    marginRight: 5,
    flex: 35
  },
  inputStylings: {
    height: 25,
    borderRadius: 5,
    padding: 7,
    fontSize: 12,
    fontWeight: FontWeight.Light,
    fontFamily: FontFamily.Medium,
    width: "100%"
  },
  inputStylingAlignment: {},
  errorMsg: {
    fontSize: 12,
    color: "#FF0D10",
    padding: 5
  }
});

export default AddArtefactType;
