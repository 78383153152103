import { Document, Page, pdfjs } from "react-pdf";
import React, { useState, useEffect } from "react";
import { TaskRepository } from "@socion-cordio/common/src/repositories/tasks/task";
import { useQuery } from "../hooks/useQueryHook";
import Loader from "@socion-cordio/common/src/components/atoms/loader";
import { StyleSheet, View, Text } from "react-native";
import Button, { ButtonType } from "@socion-cordio/common/src/components/atoms/button";
import { FontFamily, FontWeight } from "@socion-cordio/common/src/components/atoms/text";
import styled from "styled-components";
import { ArtefactRepository } from "@socion-cordio/common/src/repositories/artefact/artefact";
const ScrollFile = styled.div`
  overflow-x: hidden;
  overflow-y: auto;
  height: 600px;
  width: 100%;
  margin: 10px 5px 0 10px;

  ::-webkit-scrollbar {
    width: 5px;
  }

  ::-webkit-scrollbar-track {
    background: #fff;
  }

  ::-webkit-scrollbar-thumb {
    background: #bfb0a2;
  }
`;

const FileViewer = () => {
  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

  const [isLoading, setIsLoading] = useState(true);
  const [pdfUrl, setPdfUrl] = useState(null);

  const [fileName, setFileName] = useState(null);

  const query = useQuery();

  useEffect(() => {
    isWidgetRoute();
  }, []);

  const getPdfUrl = async () => {
    const res = await ArtefactRepository.setCookie().catch((err) => {
      console.log("Error", res);
      setIsLoading(false);
    });
    const url = query.get("pdfUrl");
    getFileName(url);
  };

  const getFileName = (url: any) => {
    let name: any = url.split("/")[url.split("/").length - 1];
    name = name.split("_");
    name.shift();
    setFileName(name.join("_"));
    setPdfUrl(url);
    setIsLoading(false);
  };

  const isWidgetRoute = async () => {
    if (query.get("token")) {
      const cookie = await ArtefactRepository.setCookie().catch((err) => {
        console.log("Error", res);
        setIsLoading(false);
      });
      const res = await ArtefactRepository.validateToken(query.get("token")).catch((err) => {
        console.log("Error", err);
        setIsLoading(false);
      });
      const url = res?.data?.artifactUrl;
      getFileName(url);
    } else if (query.get("pdfUrl")) {
      getPdfUrl();
    }
  };

  const downloadPdf = () => {
    const link = document.createElement("a");
    link.href = pdfUrl;
    link.target = "_top";
    // link.setAttribute("download", fileName);
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
  };

  return <>{isLoading ? <Loader /> : pdfUrl && downloadPdf()}</>;
};

const styles = StyleSheet.create({
  fileContainer: {
    display: "flex",
    flexDirection: "column",
    padding: 10,
    height: "calc(100vh - 80px)"
  },
  textStyle: {
    fontFamily: FontFamily.Medium,
    fontWeight: FontWeight.Bold,
    paddingRight: 10
  },
  buttonContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center"
  },
  submitbutton: {
    marginHorizontal: 10
  }
});

export default FileViewer;
