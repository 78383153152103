import React, { useRef } from "react";
import { useField } from "formik";
import { TextInput, View, StyleSheet, StyleProp, TextStyle, ViewStyle } from "react-native";
import {
  FontWeight,
  Label,
  TextSize,
  Text,
  FontFamily
} from "@socion-cordio/common/src/components/atoms/text";
import PlatformUtils from "@socion-cordio/common/src/utils/platformUtils";
import { colorPallete } from "@socion-cordio/common/src/assets/styles/colors";

interface IProps {
  label: string;
  multiline?: boolean;
  numberOfLines?: number;
  placeholder: string;
  name: string;
  styling?: Object;
  id: string;
  isSecure?: boolean;
  disabled?: boolean;
  value?: any;
  search?: boolean;
  focusStyle?: {};
  toggler?: boolean;
  manageFocus?: (arg: {}) => void;
  handleChange?: Function;
  handleBlur?: Function;
  handleKeyPress?: Function;
  labelStyle?: StyleProp<TextStyle>;
  errorStyle?: StyleProp<TextStyle>;
  inputStyle?: StyleProp<TextStyle>;
  fieldStyle?: StyleProp<ViewStyle>;
  customChange?: Function;
  noFormik?: boolean;
  editable?: boolean;
  onChange?: any;
  enteredValue?: any;
  isNumeric?: boolean;
  defaultValue?: any;
  formikPropsTouched?: boolean;
}
export const TextField = ({
  label,
  placeholder = "",
  id = "",
  isSecure = false,
  disabled = false,
  handleBlur,
  handleChange,
  value,
  enteredValue,
  onChange,
  ...props
}: IProps): React.ReactElement => {
  const {
    labelStyle,
    fieldStyle,
    manageFocus,
    focusStyle,
    styling,
    toggler = false,
    search = false,
    inputStyle,
    errorStyle,
    handleKeyPress,
    multiline,
    numberOfLines,
    noFormik,
    editable,
    isNumeric = false,
    defaultValue,
    formikPropsTouched
  } = props;

  let field, meta, hasError, error;
  if (!noFormik) {
    [field, meta] = useField(props);
    hasError = meta.touched && meta.error;
    error = meta.error;
  }

  const inputRef = useRef<TextInput>(null);
  const onClickFocus = () => {
    toggler && inputRef.current?.focus();
  };

  onClickFocus();

  const onFocus = () => {
    // search && (PlatformUtils.isWeb() ? manageFocus(styling) : manageFocus(styling)); // same is happening in both conditions
    search && PlatformUtils.isWeb() && manageFocus(styling);
  };
  return (
    <View style={styles.container}>
      {!search && (
        <Label
          testId={id}
          textSize={TextSize.Small}
          fontWeight={FontWeight.Regular}
          textStyle={[styles.label, labelStyle]}
        >
          {label}
        </Label>
      )}
      <View style={styles.elementsContainer}>
        <TextInput
          nativeID={id}
          ref={inputRef}
          placeholder={placeholder}
          secureTextEntry={isSecure}
          style={
            search
              ? toggler && PlatformUtils.isWeb
                ? focusStyle
                : [fieldStyle, focusStyle]
              : [styles.inputBox, inputStyle]
          }
          editable={editable}
          value={value}
          multiline={multiline}
          numberOfLines={numberOfLines}
          defaultValue={defaultValue}
          // {...field}   causing warning in web and mobile
          // onChangeText={handleChange(props.name)}
          // {...field}
          // onChangeText={(e: any) => {
          //   if (handleChange) {
          //     handleChange(e);
          //   }
          //   field.onChange(e);
          // }}
          onChangeText={(e) => {
            // CUSTOM HANDLE CHANGE GOES hERE console.log(e);
            if (props.customChange) {
              props.customChange(e);
            }
            if (e.trim()?.length == 0) {
              e = e.trim();
            }

            let data = e.replace(/[^0-9]/g, "");
            return handleChange(isNumeric ? data : e);
          }}
          onBlur={handleBlur(props.name)}
          onFocus={() => onFocus()}
          onKeyPress={(e) => handleKeyPress && handleKeyPress(e)}
          onChange={onChange}
          autoCompleteType="off"
        />
        {error && (formikPropsTouched === undefined ? true : formikPropsTouched) ? (
          <Text
            testId={id}
            textSize={TextSize.Small}
            fontWeight={FontWeight.Regular}
            textStyle={[styles.error, errorStyle]}
          >
            {(formikPropsTouched === undefined ? true : formikPropsTouched) && error}
          </Text>
        ) : null}
      </View>
    </View>
  );
};
export default TextField;

const styles = StyleSheet.create({
  container: {
    // flex: 1,
    flexDirection: "row",
    justifyContent: "flex-start"
  },
  elementsContainer: {
    flex: 1,
    flexDirection: "column"
  },
  label: {
    color: "green"
  },
  textinput_focused_mobile: {
    width: "100%",
    height: 50
  },
  inputBox: {
    borderWidth: 1,
    borderColor: colorPallete.cordioTaupeLight3,
    borderRadius: 5,
    outlineWidth: 0,
    paddingLeft: 10,
    fontFamily: FontFamily.Regular
  },
  error: {
    position: "relative",
    color: "red",
    alignSelf: "flex-start",
    fontSize: 10,
    fontWeight: "400",
    zIndex: -1
  }
});
