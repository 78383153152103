import styled from "styled-components";
import React, { useEffect, useState } from "react";
import { StyleSheet, TouchableOpacity, View } from "react-native";
import { dataHelper } from "@socion-cordio/common/src/utils/dataHelper";
import { Image } from "@socion-cordio/common/src/components/atoms/image";
import Comments from "./comments";
import SocionModal from "@socion-cordio/common/src/components/atoms/modal";
import { orderBy } from "lodash";
import { FontFamily } from "@socion-cordio/common/src/components/atoms/text";

const WorkFlowHistoryWrapper = styled.div`
  margin: 0 20px;
  margin-bottom: 27px;
  // max-height: 140px;
  // overflow: auto;
  .workFLowList {
    background-color: rgba(161, 159, 159, 0.12);
    border-radius: 10px;
    padding: 9px 22px;
    font-weight: 500;
    font-size: 14px;
    font-family: "Montserrat-Thin";
    margin-bottom: 5px;
    color: #231f20;
    // text-decoration: underline;
    display: flex;
    justify-content: space-between;
  }

  @media (max-width: 767px) {
    max-height: unset;
  }
`;

const Workflow = (props: any) => {
  const [openComments, setOpenComments] = useState(null);
  const [workFlowList, setWorkFlowList] = useState([]);

  useEffect(() => {
    let list = props?.artefactWorkFlow || [];
    list = orderBy(list, ["actedon"], ["desc"]);
    setWorkFlowList(list);
  }, [props?.artefactWorkFlow]);

  const [selectedWorkFlow, setSelectedWorkFlow] = useState(null);
  const showComments = () => {
    return (
      <>
        {openComments && (
          <SocionModal
            modalVisible={openComments}
            setModalVisible={() => setOpenComments(!openComments)}
            component={
              <Comments
                remarks={selectedWorkFlow?.remarks}
                headerTitle={"Remarks"}
                onClose={() => {
                  setOpenComments(false);
                }}
              ></Comments>
            }
          />
        )}
      </>
    );
  };

  return (
    <>
      <div
        style={{
          fontSize: 14,
          fontFamily: FontFamily.Bold,
          fontWeight: "700",
          color: "#231F20",
          padding: "15px"
          // paddingBottom: "14px"
        }}
      >
        Work Flow History
      </div>
      <WorkFlowHistoryWrapper>
        {workFlowList.map((workFlowEntry: any, index: number) => {
          return (
            <div className="workFLowList" key={workFlowEntry?.workflow_id}>
              <div>
                {index < workFlowList?.length - 1 && <span>Status changed </span>}
                {index === workFlowList?.length - 1 && <span>Status got changed to </span>}
                {index < workFlowList?.length - 1 && (
                  <>
                    <span>
                      from{" "}
                      {workFlowEntry?.previous_status === "REVIEW_INPROGRESS"
                        ? "REVIEW IN PROGRESS"
                        : workFlowEntry?.previous_status}
                    </span>
                    <span> to </span>
                  </>
                )}
                {
                  <>
                    <span>
                      {workFlowEntry?.current_status === "REVIEW_INPROGRESS"
                        ? "REVIEW IN PROGRESS"
                        : workFlowEntry?.current_status}
                    </span>
                    <span> by </span>
                    <span> {workFlowEntry?.actedby} </span>
                    <span>on {dataHelper.formatDate(workFlowEntry?.actedon)}</span>
                  </>
                }
              </div>
              {workFlowEntry?.remarks &&
                (workFlowEntry?.current_status === "REJECTED" ||
                  workFlowEntry?.current_status === "APPROVED") && (
                  <span className="remarks">
                    <TouchableOpacity
                      onPress={() => {
                        setOpenComments(true);
                        setSelectedWorkFlow(workFlowEntry);
                      }}
                    >
                      <Image
                        testId="edit"
                        source={require("@socion-cordio/common/src/assets/images/comment.png")}
                        imageStyle={{
                          width: 14,
                          height: 14
                        }}
                      />
                    </TouchableOpacity>
                  </span>
                )}
            </div>
          );
        })}
      </WorkFlowHistoryWrapper>
      {openComments && showComments()}
    </>
  );
};

export default Workflow;
