import React, { ReactElement, useEffect, useState } from "react";
import { StyleSheet, View } from "react-native";
import { colorPallete } from "@socion-cordio/common/src/assets/styles/colors";
import Icon, { IconNames } from "@socion-cordio/common/src/components/atoms/icon";
import Dropdown from "@socion-cordio/common/src/components/molecules/dropdown";
import ButtonTab, {
  ButtonTypeSecondary
} from "@socion-cordio/common/src/components/atoms/buttonTab";
import EntityDetails from "@socion-cordio/common/src/components/organisms/entity/entityDetails";
import EntityRoles from "@socion-cordio/common/src/components/organisms/entity/entityRoles";
import EntityPrograms from "@socion-cordio/common/src/components/organisms/entity/entityPrograms";
import { LocalStorage } from "@socion-cordio/common/src/services/storage/storageService";
import { useSelector, useDispatch } from "react-redux";
import { UserRolesProgramActions } from "@socion-cordio/common/src/modules/user-role/actions/actions";
import {
  Text,
  TextSize,
  FontWeight,
  FontFamily
} from "@socion-cordio/common/src/components/atoms/text";

interface Props {}

const DETAILS_TAB = "detailsTabOpen";
const ROLES_TAB = "rolesTabOpen";
const PROGRAMS_TAB = "pogramsTabOpen";

export default function EntityPage(props: Props): ReactElement {
  const [isDetails, setIsDetails] = useState<boolean>(true);
  const [isRoles, setIsRoles] = useState<boolean>(false);
  const [isPrograms, setIsPrograms] = useState<boolean>(false);
  const [programDetails, setProgramDetails] = useState(null);
  const [memberRole, setMemberRoles] = useState([]);
  const dispatch = useDispatch();
  const [entityStatus, setEntityStatus] = useState(null);
  const userRolesData: any = useSelector((state: any) => state?.userRolesProgram);
  LocalStorage.setStorage("userRoles", userRolesData);

  useEffect(() => {
    getProgramDetails();
  }, []);

  useEffect(() => {
    const memberRole = userRolesProgram?.userRolesData?.response;
    setMemberRoles(memberRole);
  }, []);

  const getProgramDetails = async () => {
    const programDetails = await LocalStorage.getStorage("programDetails");
    setProgramDetails(programDetails);
  };

  const isSuperAdmin = JSON.parse(localStorage.getItem("isSuperAdmin"));
  let userRolesProgram: any = useSelector((state: any) => state?.userRolesProgram);

  const getUserRoles = () => {
    const rolesPayload = {
      programId: programDetails.program.id
    };
    dispatch(UserRolesProgramActions.getUserRolesProgram(rolesPayload));
  };

  const getEntityStatus = (data: boolean) => {
    setEntityStatus(data);
  };

  useEffect(() => {
    checkTabSelectionHandler();
  }, []);

  const checkTabSelectionHandler = async () => {
    const tabSelected: string = await LocalStorage.getStorage("entityTabsOpen");
    tabSelected !== null ? tabHandler(tabSelected) : null;
  };

  const tabHandler = (tabSelected: string) => {
    switch (tabSelected) {
      case DETAILS_TAB:
        entityDetailsTabSelectHandler();
        break;
      case ROLES_TAB:
        entityRolesTabSelectHandler();
        break;
      case PROGRAMS_TAB:
        entityProgramsTabSelectHandler();
        break;
      default:
        null;
    }
  };

  const entityDetailsTabSelectHandler = () => {
    setIsDetails(true);
    setIsRoles(false);
    setIsPrograms(false);
    LocalStorage.setStorage("entityTabsOpen", DETAILS_TAB);
  };

  const entityRolesTabSelectHandler = () => {
    setIsRoles(true);
    setIsDetails(false);
    setIsPrograms(false);
    LocalStorage.setStorage("entityTabsOpen", ROLES_TAB);
  };

  const entityProgramsTabSelectHandler = () => {
    setIsPrograms(true);
    setIsDetails(false);
    setIsRoles(false);
    LocalStorage.setStorage("entityTabsOpen", PROGRAMS_TAB);
  };

  return (
    <View style={styles.container}>
      <View style={styles.subContainer}>
        <View style={styles.toggleContainer}>
          {/* <ButtonGroup/> */}
          <ButtonTab
            type={isDetails ? ButtonTypeSecondary.Secondary : ButtonTypeSecondary.Primary}
            buttonStyles={[
              styles.toggleButton,

              { borderBottomLeftRadius: 5, borderTopLeftRadius: 5 }
            ]}
            textStyles={styles.textStyle}
            title="Details"
            onPress={() => {
              entityDetailsTabSelectHandler();
            }}
          />
          <ButtonTab
            type={isRoles ? ButtonTypeSecondary.Secondary : ButtonTypeSecondary.Primary}
            buttonStyles={styles.toggleButton}
            textStyles={styles.textStyle}
            title="Roles"
            onPress={() => {
              entityRolesTabSelectHandler();
            }}
          />
          <ButtonTab
            type={isPrograms ? ButtonTypeSecondary.Secondary : ButtonTypeSecondary.Primary}
            buttonStyles={[
              styles.toggleButton,
              { borderBottomRightRadius: 5, borderTopRightRadius: 5 }
            ]}
            textStyles={styles.textStyle}
            title="Programs"
            onPress={() => {
              entityProgramsTabSelectHandler();
            }}
          />
        </View>
        {programDetails && isDetails ? (
          <EntityDetails
            programDetails={programDetails}
            userRoles={userRolesData}
            isSuperAdmin={isSuperAdmin}
            getUserRoles={() => getUserRoles()}
            getEntityStatus={getEntityStatus}
            entityStatus={entityStatus}
          />
        ) : isRoles ? (
          <EntityRoles
            programDetails={programDetails}
            userRoles={userRolesData}
            isSuperAdmin={isSuperAdmin}
            getUserRoles={() => getUserRoles()}
            entityStatus={entityStatus}
          />
        ) : isPrograms ? (
          <EntityPrograms
            programDetails={programDetails}
            entityStatus={entityStatus}
            setProgramDetails={setProgramDetails}
          />
        ) : null}
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    display: "flex",
    padding: 20,
    backgroundColor: "#F8F5F0",
    minHeight: "calc(100vh - 50px)",
    paddingTop: 15
  },
  header: {
    display: "flex",
    flexDirection: "row"
  },
  iconContainer: {
    marginTop: 10
  },
  titleIcon: {
    fontSize: 15
  },
  subContainer: {
    backgroundColor: colorPallete.white,
    borderRadius: 10,
    shadowColor: colorPallete.cordioRedDark1,
    shadowOpacity: 0.1,
    shadowOffset: {
      height: 10,
      width: 5
    },
    shadowRadius: 10
  },
  containerStyle: {
    width: "20%",
    marginLeft: 15
  },
  dropDownContainerStyle: {
    borderWidth: 0,
    borderTopRightRadius: 5,
    borderBottomRightRadius: 5
  },
  toggleContainer: {
    flexDirection: "row",
    borderRadius: 20,
    padding: 18,
    paddingBottom: 0
  },
  toggleButton: {
    width: 180
  },
  textStyle: {
    fontSize: 12
  },
  alignHeader: {
    marginBottom: 30
  },
  headerText: {
    fontSize: 14,
    fontFamily: FontFamily.Medium,
    lineHeight: 19.5,
    fontWeight: "700"
  },
  iconStyle: {
    fontSize: 18,
    marginRight: 35
  }
});
