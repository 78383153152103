import React, { ReactElement, useState } from "react";
import { ScrollView, StyleSheet, View, TouchableOpacity } from "react-native";
import {
  Text,
  TextSize,
  FontWeight,
  FontFamily
} from "@socion-cordio/common/src/components/atoms/text";
import Icon, { IconNames } from "@socion-cordio/common/src/components/atoms/icon";
import Button, { ButtonType } from "@socion-cordio/common/src/components/atoms/button";
import UserInputSecondary from "@socion-cordio/common/src/components/molecules/userInputSecondary";
import UserInputTextArea from "@socion-cordio/common/src/components/molecules/userInputTextArea";
import { colorPallete } from "@socion-cordio/common/src/assets/styles/colors";
import { Switch } from "@socion-cordio/common/src/components/atoms/switches";
import { dataHelper } from "@socion-cordio/common/src/utils/dataHelper";
import { ApiClient } from "@socion-cordio/common/src/network/apiClient";
import { badgesEndPoints } from "@socion-cordio/common/src/repositories/endPoints";
import { HTTP_STATUS_CODES } from "@socion-cordio/common/src/network/constants";
import { toast } from "react-toastify";
import RemoveLinkModal from "@socion-cordio/common/src/components/organisms/common-modals/removeLinkModal";

interface Props {
  onSubmit?: (data: any) => void;
  onClose?: Function;
  selectedBadge?: any;
  getBadges?: Function;
  programDetails?: any;
}

export default function BadgeDetailsModal(props: Props): ReactElement {
  const { onClose, selectedBadge, getBadges, programDetails } = props;
  const [mobilemodalVisible, setMobileModalVisible] = useState(true);
  const [isBadgeEnabled, setIsBadgeEnabled] = useState<boolean>(selectedBadge.status === "ACTIVE");
  const [isEditable, setIsEditable] = useState<boolean>(false);
  const [showRemoveModal, setShowRemoveModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [newEditValues, setNewEditValues] = useState({
    name: selectedBadge?.name,
    description: selectedBadge?.description,
    awardingCriteria: selectedBadge?.awardingCriteria
  });

  const changeHandler = (name: string, value: string) => {
    setNewEditValues({ ...newEditValues, [name]: value });
  };

  const updateBadge = async () => {
    const payload = {
      ...newEditValues,
      badgeTypeId: selectedBadge.badgeTypeId,
      programId: programDetails?.program?.id,
      badgeId: selectedBadge.badgeId
    };
    let response = await ApiClient.put(badgesEndPoints.updateBadge(), payload);
    if (response.responseCode === HTTP_STATUS_CODES.ok) {
      onClose();
      getBadges();
      toast.success(response.message);
    } else if (response.responseCode == HTTP_STATUS_CODES.conflict) {
      toast.error(response.message);
    } else {
      toast.error("Something went wrong...!!!");
    }
  };

  const handleToggle = () => {
    setShowRemoveModal(true);
  };

  const handleActivateDeactivate = () => {
    setIsBadgeEnabled((isBadgeEnabled) => !isBadgeEnabled);
    hanldleActivateReactivate(!isBadgeEnabled);
    setShowRemoveModal(false);
  };

  const hanldleActivateReactivate = async (badgeEnabled: boolean) => {
    const activateBadge = badgeEnabled || false;
    let response = await ApiClient.put(
      badgesEndPoints.activateReactivateBadge(selectedBadge.badgeId),
      {},
      { activateBadge }
    );
    if (response.responseCode === HTTP_STATUS_CODES.ok) {
      onClose();
      getBadges();
      toast.success(response.message);
    } else {
      toast.error("Something went wrong...!!!");
    }
  };

  const handleDelete = async () => {
    let response = await ApiClient.put(
      badgesEndPoints.deleteBadge(selectedBadge.badgeId, false),
      {}
    );
    if (response.responseCode === HTTP_STATUS_CODES.ok) {
      onClose();
      getBadges();
      toast.success("Badge deleted successfully");
    } else {
      toast.error("Something went wrong...!!!");
    }
  };

  return (
    <View>
      <View style={styles.container}>
        <View style={styles.header}>
          <Text
            fontWeight={FontWeight.Bold}
            testId="addRoleText"
            textSize={TextSize.Small}
            textStyle={styles.headerText}
          >
            Badge details
          </Text>
          <View>
            <Icon
              testID="close"
              name={IconNames.crossCircle}
              customStyle={styles.crossCircle}
              onPress={() => onClose()}
            />
          </View>
        </View>
        <View style={styles.alignEditButton}>
          {selectedBadge.status === "ACTIVE" && !isEditable && !selectedBadge.badgeLinked && (
            <TouchableOpacity>
              <Icon
                testID="close"
                name={IconNames.penPaper}
                customStyle={styles.penPaperIcon}
                onPress={() => setIsEditable(true)}
              />
            </TouchableOpacity>
          )}
        </View>
        <View style={styles.badgeDetailsContainer}>
          <View style={styles.badgeDetials}>
            {!isEditable && (
              <View style={!isEditable ? styles.marginAlign : null}>
                <View>
                  <View style={styles.badgeHeaderAlignmnet}>
                    <Text
                      fontWeight={FontWeight.Light}
                      testId="addRoleText"
                      textSize={TextSize.Small}
                      textStyle={[styles.headerText, styles.headerAlignment]}
                    >
                      Badge Name
                    </Text>
                  </View>
                  <View style={styles.badgeHeaderAlignment}>
                    <Text
                      fontWeight={FontWeight.Bold}
                      testId="addRoleText"
                      textSize={TextSize.Small}
                      textStyle={styles.headerText}
                    >
                      {selectedBadge?.name}
                    </Text>
                  </View>
                </View>

                <View style={(styles.badgeHeaderAlignmnet, styles.subHeaderAlignment)}>
                  <View style={styles.dateCreatedContainer}>
                    <Text
                      fontWeight={FontWeight.Light}
                      testId="addRoleText"
                      textSize={TextSize.Small}
                      textStyle={[
                        styles.headerText,
                        styles.headerAlignment,
                        styles.fontSizeText,
                        { marginRight: 10 }
                      ]}
                    >
                      Created on
                    </Text>
                    <Text
                      fontWeight={FontWeight.Light}
                      testId="addRoleText"
                      textSize={TextSize.Small}
                      textStyle={[styles.text, styles.fontSizeText]}
                    >
                      {dataHelper.formatDate(selectedBadge?.createdAt, "DD MMM YYYY")}
                    </Text>
                  </View>
                </View>

                <View>
                  <View style={styles.badgeHeaderAlignmnet}>
                    <Text
                      fontWeight={FontWeight.Light}
                      testId="addRoleText"
                      textSize={TextSize.Small}
                      textStyle={[styles.headerText, styles.headerAlignment]}
                    >
                      Description
                    </Text>
                  </View>
                  <View style={[styles.badgeHeaderAlignment, { maxHeight: 90 }]}>
                    <ScrollView>
                      <Text
                        fontWeight={FontWeight.Light}
                        testId="addRoleText"
                        textSize={TextSize.Small}
                        textStyle={[styles.headerText, styles.colorText]}
                      >
                        {selectedBadge?.description}
                      </Text>
                    </ScrollView>
                  </View>
                </View>

                <View>
                  <View style={styles.badgeHeaderAlignmnet}>
                    <Text
                      fontWeight={FontWeight.Light}
                      testId="addRoleText"
                      textSize={TextSize.Small}
                      textStyle={[styles.headerText, styles.headerAlignment]}
                    >
                      Awarding criteria
                    </Text>
                  </View>
                  <View style={[styles.badgeHeaderAlignment, { maxHeight: 90 }]}>
                    <ScrollView>
                      <Text
                        fontWeight={FontWeight.Light}
                        testId="addRoleText"
                        textSize={TextSize.Small}
                        textStyle={[styles.headerText, styles.colorText]}
                      >
                        {selectedBadge?.awardingCriteria}
                      </Text>
                    </ScrollView>
                  </View>
                </View>
              </View>
            )}

            {isEditable && (
              <View style={styles.editableContainer}>
                <View>
                  <View>
                    <UserInputSecondary
                      textValue="Badge name"
                      handleChange={(value: any) => changeHandler("name", value)}
                      handleBlur={() => {}}
                      value={newEditValues?.name}
                      // inputStyle={[styles.inputStyling, styles.inputStylingAlignment]}
                      placeholder="Enter Badge name"
                      name="badgeName"
                      id="badgeName"
                      userStyle={styles.inputStylingAlignment}
                      userStyleText={styles.textHeaderField}
                      textInputContainerStyles={{ marginLeft: 15 }}
                      noFormik
                    />
                  </View>

                  <View style={styles.dateContainerForEditable}>
                    <View style={styles.dateContainerForEditableBlank}></View>
                    <View style={styles.dateContainerForEditableDateField}>
                      <Text
                        fontWeight={FontWeight.Light}
                        testId="dateLabel"
                        textSize={TextSize.Small}
                        textStyle={[
                          styles.headerText,
                          styles.headerAlignment,
                          styles.fontSizeText,
                          { marginRight: 10 }
                        ]}
                      >
                        Created on
                      </Text>
                      <Text
                        fontWeight={FontWeight.Light}
                        testId="date"
                        textSize={TextSize.Small}
                        textStyle={[styles.headerText, styles.fontSizeText]}
                      >
                        {dataHelper.formatDate(selectedBadge?.createdAt, "DD MMM YYYY")}
                      </Text>
                    </View>
                  </View>

                  <View>
                    <UserInputTextArea
                      textValue="Description"
                      label="Description"
                      placeholder="Write a description"
                      name="description"
                      id="description"
                      handleChange={(value: any) => changeHandler("description", value)}
                      handleBlur={() => {}}
                      value={newEditValues?.description}
                      numberOfLines={5}
                      multiline={true}
                      userStyle={styles.inputStylingAlignment}
                      userStyleText={styles.textHeaderField}
                      noFormik
                    />
                  </View>
                  <View>
                    <UserInputTextArea
                      textValue="Awarding criteria"
                      label="Awarding criteria"
                      placeholder="Add criteria"
                      name="awardCriteria"
                      id="awardCriteria"
                      handleChange={(value: any) => changeHandler("awardingCriteria", value)}
                      handleBlur={() => {}}
                      value={newEditValues?.awardingCriteria}
                      numberOfLines={5}
                      multiline={true}
                      userStyle={styles.inputStylingAlignment}
                      userStyleText={styles.textHeaderField}
                      noFormik
                    />
                  </View>
                </View>
              </View>
            )}

            {selectedBadge.badgeLinked && (
              <View
                style={
                  !isEditable
                    ? [styles.marginAlign, styles.badgeStatusContainer]
                    : styles.badgeStatusContainer
                }
              >
                <View style={styles.badgeHeaderAlignmnet}>
                  <Text
                    fontWeight={FontWeight.Light}
                    testId="addRoleText"
                    textSize={TextSize.Small}
                    textStyle={[styles.headerText, styles.headerAlignment, { marginBottom: 0 }]}
                  >
                    Badge status
                  </Text>
                </View>

                <View style={styles.switchContainer}>
                  <Switch
                    isOn={isBadgeEnabled}
                    onColor="#B7504A"
                    offColor="#F1DCDB"
                    labelStyle={{ color: "black", fontWeight: "900" }}
                    size="medium"
                    onToggle={handleToggle}
                  />
                  <Text
                    fontWeight={FontWeight.Light}
                    testId="addRoleText"
                    textSize={TextSize.Small}
                    textStyle={[styles.headerText, styles.toggleButtonText, ,]}
                  >
                    {isBadgeEnabled ? "Active" : "Inactive"}
                  </Text>
                </View>
              </View>
            )}

            <View
              style={[styles.alignButtonContainer]}
              // style={{ width: '100px' }}
            >
              {!isEditable && (
                <>
                  {selectedBadge.status === "ACTIVE" && (
                    <View style={styles.buttonContainer}>
                      {/* <View>
                        <Button
                          type={ButtonType.Primary}
                          buttonStyles={styles.submitbutton}
                          textStyles={styles.buttonFont}
                          title="Edit"
                          onPress={() => {
                            setIsEditable(true);
                          }}
                          disabled={selectedBadge.badgeLinked}
                        />
                      </View> */}
                      {selectedBadge.badgeLinked ? (
                        <View>
                          {/* <Button
                            type={ButtonType.Primary}
                            buttonStyles={[styles.submitbutton, { marginLeft: "1rem" }]}
                            textStyles={styles.buttonFont}
                            title="Deactivate"
                            onPress={() => {
                              handleDelete();
                            }}
                          /> */}
                        </View>
                      ) : (
                        <View>
                          <Button
                            type={ButtonType.Primary}
                            buttonStyles={[styles.submitbutton, { marginLeft: "1rem" }]}
                            textStyles={styles.buttonFont}
                            title="Delete"
                            onPress={() => setShowDeleteModal(true)}
                          />
                        </View>
                      )}
                    </View>
                  )}
                </>
              )}
              {isEditable && (
                <Button
                  type={ButtonType.Primary}
                  buttonStyles={styles.submitbutton}
                  title="Save changes"
                  textStyles={styles.buttonFont}
                  onPress={() => updateBadge()}
                  // disabled={true}
                />
              )}
              {showRemoveModal && (
                <RemoveLinkModal
                  modalVisible={showRemoveModal}
                  selectedData={handleActivateDeactivate}
                  textValue={
                    isBadgeEnabled
                      ? "You will not be able to assign this badge anymore. Are you sure you want to deactivate this badge?"
                      : "Are you sure you want to reactivate this badge?"
                  }
                  setModalVisible={() => setShowRemoveModal(!showRemoveModal)}
                />
              )}
              {showDeleteModal && (
                <RemoveLinkModal
                  modalVisible={showDeleteModal}
                  selectedData={handleDelete}
                  textValue={"Are you sure you want to delete this badge?"}
                  setModalVisible={() => setShowDeleteModal(!showDeleteModal)}
                />
              )}
            </View>
          </View>
        </View>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    width: 680
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row",
    marginBottom: 5
  },
  headerText: {
    fontSize: 12,
    fontFamily: FontFamily.Medium,
    lineHeight: 17,
    marginBottom: 5
  },
  headerAlignment: {
    color: colorPallete.textLight,
    fontFamily: FontFamily.Regular
  },
  colorText: {
    color: colorPallete.textBlack,
    fontFamily: FontFamily.Regular
  },
  fontSizeText: {
    fontSize: 12
  },
  crossCircle: {
    fontSize: 15
  },
  badgeDetailsContainer: {
    flexDirection: "row",
    // marginTop: 15,
    marginBottom: 10
  },
  titleIcon: {
    fontSize: 15
  },
  badgeDetials: {
    flex: 100
    // marginLeft: 35,
    // marginRight: 35
  },
  marginAlign: {
    marginLeft: 35,
    marginRight: 35
  },
  badgeHeaderAlignmnet: {
    marginBottom: 0,
    flex: 20
  },
  badgeHeaderAlignment: {
    marginBottom: 5
  },
  subHeaderAlignment: {
    // flexDirection: "row",
    marginTop: 5,
    marginBottom: 10
  },
  dateCreatedContainer: {},
  badgeStatusContainer: {
    flexDirection: "row",
    alignItems: "center"
  },
  switchContainer: {
    flexDirection: "row",
    alignItems: "center",
    flex: 80,
    marginLeft: 15
  },
  toggleButtonText: {
    marginLeft: 30,
    marginBottom: 0
  },
  alignButtonContainer: {
    marginTop: 25,
    // marginBottom: 10,
    justifyContent: "center",
    alignSelf: "center"
  },
  submitbutton: {
    width: "140px",
    height: "50px",
    borderRadius: 10
  },
  inputStylingAlignment: {
    width: "100%",
    fontSize: 12
  },
  textHeaderField: {
    flex: 20,
    fontSize: 12
  },
  editableContainer: {
    marginBottom: 15
  },
  dateContainerForEditable: {
    flexDirection: "row"
  },
  dateContainerForEditableBlank: {
    flex: 20
  },
  dateContainerForEditableDateField: {
    flex: 80,
    flexDirection: "row",
    marginLeft: 15,
    marginTop: 10
  },
  buttonContainer: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center"
  },
  buttonFont: {
    fontSize: 12
  },
  alignEditButton: {
    position: "absolute",
    right: 0,
    top: 35
  },
  penPaperIcon: {
    fontSize: 15,
    opacity: 0.6
    // marginLeft: 25
  },
  text: {
    fontSize: 12,
    fontFamily: FontFamily.Regular,
    lineHeight: 17,
    marginBottom: 5,
    color: "rgb(35, 31, 32)"
  }
});
