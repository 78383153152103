import React, { ReactElement, useEffect, useState, useMemo } from "react";
import { View, StyleSheet, TouchableOpacity } from "react-native";
import { toast } from "react-toastify";
import { springshedEndPoints } from "@socion-cordio/common/src/repositories/endPoints";
import { ApiClient } from "@socion-cordio/common/src/network/apiClient";
import {
  Text,
  TextSize,
  FontWeight,
  FontFamily
} from "@socion-cordio/common/src/components/atoms/text";
import Table from "@socion-cordio/web/src/components/molecules/table/table";
import { Image } from "@socion-cordio/common/src/components/atoms/image";
import Loader from "@socion-cordio/common/src/components/atoms/loader";
import { colorPallete } from "@socion-cordio/common/src/assets/styles/colors";
import SocionModal from "@socion-cordio/common/src/components/atoms/modal";
import AddMemberModal from "@socion-cordio/common/src/components/organisms/addMemberModal";
import ActivateRemoveMember from "@socion-cordio/common/src/components/organisms/activateRemoveMember";
import ViewUserDetails from "@socion-cordio/common/src/components/organisms/common-modals/viewUserDetailsModal";

interface Props {
  programDetails: any;
  userRoles: any;
  isSuperAdmin: any;
  getUserRoles?: Function;
  entityStatus?: any;
}

export default function EntityRoles(props: Props): ReactElement {
  const [rolesResponse, setRolesResponse] = useState(null);
  const [loading, setLoading] = useState(true);
  const [failed, setFailed] = useState(false);
  const [addMemberModal, setAddMemberModal] = useState(false);
  const [userId, setUserId] = useState(null);
  const [viewButtonDetails, setViewButtonDetails] = useState(false);
  const [selectedUser, setSelectedUser] = useState(undefined);
  const [memberStatus, setMemberStatus] = useState("");
  const [modalLoading, setModalLoading] = useState(false);
  const [viewButtonUserDetails, setViewButtonUserDetails] = useState(false);
  const [selectedUserDetails, setSelectedUserDetails] = useState(null);
  const { programDetails, userRoles, isSuperAdmin, entityStatus } = props;
  const [memberRole, setMemberRoles] = useState([]);
  const [updatedColumns, setUpdatedColumns] = useState([]);

  useEffect(() => {
    getRolesDetails();
  }, []);

  useEffect(() => {
    setMemberRoles(userRoles?.userRolesData?.response);
    validateColumns(userRoles?.userRolesData?.response);
  }, []);

  const validateColumns = (userRoles: any[]) => {
    if ((isSuperAdmin || userRoles?.includes("ENTITY_ADMIN")) && !entityStatus) {
      return setUpdatedColumns(columns);
    } else {
      let newColumns = columns.pop();
      return setUpdatedColumns(columns);
    }
  };

  const getRolesDetails = async () => {
    try {
      const entityId = programDetails?.entityData?.entity.registryEntityId;
      if (!entityId) {
        setLoading(false);
        setFailed(true);
        return;
      }
      let rolesResponse = await ApiClient.get(springshedEndPoints.getEntityRoles(entityId));
      let deActiveTrue = rolesResponse.filter((i: any) => i.deactivated == true);
      let deActiveFalse = rolesResponse.filter((i: any) => i.deactivated == false);
      let result = deActiveFalse?.concat(deActiveTrue);
      let count = 0,
        position = 0;
      result?.forEach((role: any, index: number) => {
        role.serialNo = `${index + 1}.`;
        role.value = `${index + 1}.`;
        if (role.deactivated === false) {
          count++, (position = index);
        }
      });
      if (count === 1) result[position].value = 0;
      setRolesResponse(result);
      setLoading(false);
    } catch (error) {
      setRolesResponse([]);
      setLoading(false);
      setFailed(true);
    }
  };

  const formatLocation = (data: any) => {
    let formattedLocation: string = "";
    // city, subdist, district, state, country
    if (data?.city) {
      formattedLocation += `${data?.city}, `;
    }
    if (data?.subDistrict) {
      formattedLocation += `${data?.subDistrict}, `;
    }
    if (data?.district) {
      formattedLocation += `${data?.district}, `;
    }
    if (data?.state) {
      formattedLocation += `${data?.state}, `;
    }
    if (data?.country) {
      formattedLocation += `${data?.country} `;
    }
    return formattedLocation;
  };

  useEffect(() => {
    if (userId) handleMemberModal();
  }, [userId]);

  const handleMemberStatus = (role: any, status: string) => {
    setUserId(role?.userId);
    setMemberStatus(status);
    setViewButtonDetails(true);
  };

  const handleMemberModal = async () => {
    setModalLoading(true);
    const getUserDetailsResponse = await ApiClient.get(
      springshedEndPoints.getProfileByUserId(userId)
    );
    if (getUserDetailsResponse) {
      setModalLoading(false);
      setSelectedUser(getUserDetailsResponse);
    } else {
      toast.error(getUserDetailsResponse.message);
    }
  };

  const displayUserDetails = (data: any) => {
    setViewButtonUserDetails(true);
    setSelectedUserDetails(data);
  };

  const cellData = (testId: string, style: any, data: string, onPress?: Function) => (
    <Text
      fontWeight={FontWeight.Bold}
      testId={testId}
      textSize={TextSize.Small}
      style={[
        {
          fontSize: 12,
          fontFamily: FontFamily.Regular,
          fontWeight: FontFamily.Light,
          textSize: TextSize.Small
        },
        style
      ]}
      onPress={onPress ? () => onPress() : null}
    >
      {data}
    </Text>
  );

  const columns = useMemo(
    () => [
      {
        Header: "Sr.No",
        accessor: "serialNo",
        width: 30,
        maxWidth: 30,
        minWidth: 30,
        disableSortBy: true,
        Cell: ({ row: { original: role } }: { row: { original: any } }) => {
          const style = {
            color: role?.deactivated ? colorPallete.gray : colorPallete.textBlack
          };
          return cellData("serialNo", style, role.serialNo);
        }
      },
      {
        Header: "Name",
        accessor: "name",
        width: 205,
        maxWidth: 205,
        Cell: ({ row: { original: role } }: { row: { original: any } }) => {
          return (
            <View style={styles.roleName}>
              <TouchableOpacity style={styles.roleName} onPress={() => displayUserDetails(role)}>
                <Image
                  testId="roleName"
                  imageStyle={styles.image}
                  source={
                    role?.photo ||
                    require("@socion-cordio/common/src/assets/images/user_circle.svg")
                  }
                />
                <Text
                  fontWeight={FontWeight.Bold}
                  testId="profileName"
                  textSize={TextSize.Large}
                  style={{
                    fontSize: 12,
                    fontFamily: FontFamily.Medium,
                    color: role?.deactivated ? colorPallete.gray : colorPallete.textBlack
                  }}
                >
                  {role?.name}
                </Text>
              </TouchableOpacity>
            </View>
          );
        }
      },
      {
        Header: "Location",
        accessor: "location",
        width: 205,
        maxWidth: 205,
        Cell: ({ row: { original: role } }: { row: { original: any } }) => {
          const style = {
            color: role?.deactivated ? colorPallete.gray : colorPallete.textBlack
          };
          const location: string = formatLocation(role);
          return cellData("location", style, location);
        }
      },
      {
        // Header: "Add/Remove",
        Header: " ",
        accessor: "add/remove",
        width: 50,
        maxWidth: 50,
        disableSortBy: true,
        Cell: ({ row: { original: role } }: { row: { original: any } }) => {
          return (
            <View>
              <View style={styles.alignAddRemoveContainer}>
                {role.deactivated ? (
                  <TouchableOpacity onPress={() => handleMemberStatus(role, "activate")}>
                    <Image
                      testId="secondaryAddImg"
                      source={require("@socion-cordio/common/src/assets/images/secondary_add.svg")}
                      imageStyle={{
                        width: 20,
                        height: 20
                      }}
                    />
                  </TouchableOpacity>
                ) : (
                  role.value != 0 && (
                    <View>
                      <TouchableOpacity onPress={() => handleMemberStatus(role, "deactivate")}>
                        <Image
                          testId="remove"
                          source={require("@socion-cordio/common/src/assets/images/remove.svg")}
                          imageStyle={{
                            width: 20,
                            height: 20
                          }}
                        />
                      </TouchableOpacity>
                    </View>
                  )
                )}
              </View>
            </View>
          );
        }
      }
    ],
    []
  );

  return (
    <View>
      {loading ? (
        <Loader />
      ) : failed ? (
        <View>Failed to load data</View>
      ) : (
        <View>
          <View>
            <Text
              fontWeight={FontWeight.Regular}
              testId="addRoleText"
              textSize={TextSize.Small}
              textStyle={styles.mainHeaderText}
            >
              Entity administrator
            </Text>
          </View>
          <Table
            columns={updatedColumns}
            data={rolesResponse}
            addIcon={
              (isSuperAdmin || memberRole?.includes("ENTITY_ADMIN")) &&
              !entityStatus && (
                <TouchableOpacity onPress={() => [setAddMemberModal(true)]}>
                  <Image
                    testId="primaryAddImg"
                    source={require("@socion-cordio/common/src/assets/images/primary_add.svg")}
                    imageStyle={{
                      width: 30,
                      height: 30
                    }}
                  />
                </TouchableOpacity>
              )
            }
          />
          {addMemberModal && (
            <View>
              <SocionModal
                modalVisible={addMemberModal}
                setModalVisible={() => setAddMemberModal(!addMemberModal)}
                component={
                  <AddMemberModal
                    onClose={() => setAddMemberModal(false)}
                    getRolesDetails={() => getRolesDetails()}
                    getUserRoles={() => props.getUserRoles()}
                  />
                }
              />
            </View>
          )}
          {!modalLoading && viewButtonDetails && (
            <View>
              <SocionModal
                modalVisible={viewButtonDetails}
                setModalVisible={() => setViewButtonDetails(!viewButtonDetails)}
                component={
                  <ActivateRemoveMember
                    onClose={() => {
                      setViewButtonDetails(false);
                    }}
                    userDetails={selectedUser}
                    userId={userId}
                    memberStatus={memberStatus}
                    getRolesDetails={() => getRolesDetails()}
                    setMemberStatus={setMemberStatus}
                    setSelectedUser={setSelectedUser}
                    setModalLoading={setModalLoading}
                    getUserRoles={() => props.getUserRoles()}
                  />
                }
              />
            </View>
          )}
          {viewButtonUserDetails && (
            <View>
              <SocionModal
                modalVisible={viewButtonUserDetails}
                setModalVisible={() => setViewButtonUserDetails(!viewButtonUserDetails)}
                component={
                  <ViewUserDetails
                    onClose={() => {
                      setViewButtonUserDetails(false);
                    }}
                    selectedUserDetails={selectedUserDetails}
                    piiFilter={true}
                  />
                }
              />
            </View>
          )}
        </View>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  roleName: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center"
  },
  image: {
    width: 25,
    height: 25,
    borderRadius: 25 / 2,
    marginEnd: 10
  },
  mainHeaderText: {
    fontSize: 12,
    fontFamily: FontFamily.Bold,
    lineHeight: 17,
    fontWeight: "700",
    paddingLeft: 18,
    marginTop: 18
  },
  alignAddRemoveContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    marginRight: 10
  }
});
