import React, { useEffect, useState } from "react";
import { StyleSheet, TouchableOpacity, View } from "react-native";
import { colorPallete } from "@socion-cordio/common/src/assets/styles/colors";
import {
  FontFamily,
  FontWeight,
  Text,
  TextSize
} from "@socion-cordio/common/src/components/atoms/text";
import Icon, { IconNames } from "@socion-cordio/common/src/components/atoms/icon";
import { Image } from "@socion-cordio/common/src/components/atoms/image";
import { ProgressBar } from "@socion-cordio/common/src/components/atoms/progressBar";
import { getUploadProgress, uploadPercentage$ } from "../helpers/docHelper";

interface IProps {
  docsToUpload?: any;
  handleCancel?: Function;
}

const DocUploadProgress = (props: IProps) => {
  const { docsToUpload = [], handleCancel } = props;
  const [progress, setProgress] = useState(10);

  useEffect(() => {
    const subscription = uploadPercentage$.subscribe((uploadedDocs) => {
      const progres = getUploadProgress(docsToUpload, uploadedDocs);
      console.log("progresprogres", progres);
      setProgress(progres);
    });
    // Clean up the subscription when the component unmounts
    return () => {
      subscription.unsubscribe();
    };
  }, []);

  return (
    <>
      {progress > 0 && (
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            // width: "100%",
            top: 290,
            width: "85.5%",
            left: 102
          }}
        >
          <View style={styles.uploadDocsContainer}>
            <View
              style={{
                display: "flex",
                flex: 1,
                flexDirection: "column",
                alignItems: "flex-start",
                justifyContent: "center"
              }}
            >
              <Text
                fontWeight={FontWeight.Regular}
                testId="addRoleTitleText"
                textSize={TextSize.Small}
                textStyle={[styles.uploadHeaderText]}
              >
                {`Upload in progress.Please do not refresh / close the tab`}
              </Text>
              <View style={{ width: "90%" }}>
                <ProgressBar percentage={progress} progressBarStyles={styles.progressBarStyles} />
              </View>
            </View>
            {
              <TouchableOpacity
                onPress={() => {
                  handleCancel();
                }}
              >
                <Image
                  testId={"uploadremove"}
                  source={require(`@socion-cordio/common/src/assets/images/close.svg`)}
                  imageStyle={{
                    width: 26,
                    height: 26
                  }}
                />
              </TouchableOpacity>
              // <Icon testID="close" name={IconNames.close} size={26} customStyle={styles.uploadDocIcon} />
            }
          </View>
        </View>
      )}
    </>
  );
};

const styles = StyleSheet.create({
  uploadDocsContainer: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row",
    alignItems: "center",
    backgroundColor: "#F8F8F8",
    borderRadius: 5,
    borderColor: "#F8F8F8",
    height: "64px",
    padding: 15,
    marginTop: 15,
    width: "100%"
  },
  uploadHeaderText: {
    fontWeight: "400",
    fontSize: 14,
    fontFamily: FontFamily.Regular,
    lineHeight: 17,
    color: colorPallete.textLight
  },
  progressBarStyles: { height: 7, marginTop: 10 }
});
export default DocUploadProgress;
