import React, { ReactElement, useEffect, useState } from "react";
import { ScrollView, StyleSheet, TouchableOpacity, View } from "react-native";
import { colorPallete } from "@socion-cordio/common/src/assets/styles/colors";
import AddTelemetryService from "@socion-cordio/common/src/services/telemetryService";
import Moment from "moment";
import { LocalStorage } from "@socion-cordio/common/src/services/storage/storageService";
import {
  Text,
  TextSize,
  FontWeight,
  FontFamily
} from "@socion-cordio/common/src/components/atoms/text";
import Icon, { IconNames } from "@socion-cordio/common/src/components/atoms/icon";
import Button, { ButtonType } from "@socion-cordio/common/src/components/atoms/button";
import UserInputSecondary from "@socion-cordio/common/src/components/molecules/userInputSecondary";
import { Image } from "@socion-cordio/common/src/components/atoms/image";
import { auxiliaryMethods } from "@socion-cordio/common/src/utils/auxiliaryMethods";
import { dataHelper } from "@socion-cordio/common/src/utils/dataHelper";
import { ApiClient } from "@socion-cordio/common/src/network/apiClient";
import {
  programEndPoints,
  verifyEndPoints
} from "@socion-cordio/common/src/repositories/endPoints";
import { toast, ToastContainer } from "react-toastify";
import { HTTP_STATUS_CODES } from "@socion-cordio/common/src/network/constants";
import Loader from "@socion-cordio/common/src/components/atoms/loader";
import { mediaHelper } from "@socion-cordio/common/src/utils/mediaHelperMethods";
import { ScrollContainer } from "@socion-cordio/common/src/components/organisms/scrollWrapper";
interface Props {
  onClose?: Function;
  selectedRole?: any;
  selectedQualification?: any;
  programDetails?: any;
  getRolesVerification?: Function;
  getQualificationVerification?: Function;
  userRoles: any[];
}
export default function VerifyRoleQualificationModal(props: Props): ReactElement {
  const {
    onClose,
    selectedRole,
    selectedQualification,
    getRolesVerification,
    getQualificationVerification,
    userRoles
  } = props;
  const [mobilemodalVisible, setMobileModalVisible] = useState<boolean>(true);
  const [onDecline, setOnDecline] = useState<boolean>(false);
  const [verifiers, setVerifiers] = useState([]);
  const [canEdit, setCanEdit] = useState(false);
  const [reason, setReason] = useState("");
  const [loading, setLoading] = useState(false);

  const loggedInUser = JSON.parse(localStorage.getItem("user"));
  const hanldeDocumentDownload = (documentUrl: string) => {
    const array = documentUrl.split("/");
    const lastItem = array.pop();

    const ext = lastItem.slice(((lastItem.lastIndexOf(".") - 1) >>> 0) + 2);

    const validTypesImage = ["png", "jpg", "jpeg"];
    const validTypesDocs = ["pdf"];
    const validTypesVideo = ["mp4", "mpeg", "flv", "mov"];

    let type: string;
    if (validTypesImage.includes(ext)) {
      type = "image";
    }
    if (validTypesDocs.includes(ext)) {
      type = "document";
    }
    if (validTypesVideo.includes(ext)) {
      type = "video";
    }
    type ? mediaHelper.mediaDownloadVerify(type, lastItem) : toast.error("Something went wrong.");
  };

  useEffect(() => {
    getProgramMembers();
  }, []);

  const getProgramMembers = async () => {
    try {
      const membersResponse = await ApiClient.get(
        programEndPoints.getProgramMembers(
          props.programDetails?.program?.id,
          props.programDetails?.entityData?.entity?.id
        )
      );
      const verifiers = membersResponse.filter((member: any) => member.role === "VERIFIER");
      setVerifiers(verifiers);
    } catch (error) {
      toast.error(error.message);
    }
  };

  useEffect(() => {
    processEditRight();
  }, [verifiers]);

  const processEditRight = () => {
    const constentsVerifier = verifiers.filter(
      (verifier) => verifier.userId === loggedInUser.userId
    );
    if (
      constentsVerifier &&
      ((selectedRole && loggedInUser.userId !== selectedRole?.person?.userId) ||
        (selectedQualification && loggedInUser.userId !== selectedQualification?.person?.userId))
    ) {
      setCanEdit(true);
    }
  };

  const handleRoleAccept = async () => {
    setLoading(true);
    const payload = {
      verifierId: parseInt(props.programDetails?.program?.id),
      roleId: selectedRole?.roleId,
      status: "APPROVED",
      responseDate: new Date().toISOString().replace("T", " ").replace("Z", ""),
      reason: ""
    };
    const handleRoleAcceptResponse = await ApiClient.post(
      verifyEndPoints.approveRejectRole(),
      payload
    );
    if (handleRoleAcceptResponse.responseCode === HTTP_STATUS_CODES.ok) {
      toast.success(handleRoleAcceptResponse.message);
      getRolesVerification();
      updateTelemetryRole(true);
      props.onClose();
      setLoading(false);
    } else {
      setLoading(false);
      toast.error(handleRoleAcceptResponse.message);
    }
  };

  const handleRoleDecline = async () => {
    setLoading(true);
    const payload = {
      verifierId: parseInt(props.programDetails?.program?.id),
      roleId: selectedRole?.roleId,
      status: "REJECTED",
      responseDate: new Date().toISOString().replace("T", " ").replace("Z", ""),
      reason: reason
    };
    const handleRoleAcceptResponse = await ApiClient.post(
      verifyEndPoints.approveRejectRole(),
      payload
    );
    if (handleRoleAcceptResponse.responseCode === HTTP_STATUS_CODES.ok) {
      toast.success(handleRoleAcceptResponse.message);
      getRolesVerification();
      updateTelemetryRole(false);
      props.onClose();
      setLoading(false);
    } else {
      setLoading(false);
      toast.error(handleRoleAcceptResponse.message);
    }
  };

  const updateTelemetryRole = async (isAccepted: boolean) => {
    const updatedProfileInfo: any = await LocalStorage.getStorage("user");
    const programDetails: any = await LocalStorage.getStorage("programDetails");
    let eventType = "";
    let sessionStartDate: string = null;
    let sessionEndDate: string = null;
    let memberId: string = null;
    // true: send session end date, sessionstartdate, , memberId
    isAccepted === true
      ? [
          (eventType = "Approve Role Req"),
          (sessionEndDate = sessionEndDate),
          (sessionStartDate = sessionStartDate),
          (memberId = updatedProfileInfo.userId)
        ]
      : (eventType = "Decline Role Req");
    const body = {
      timestamp: Moment().toLocaleString(),
      createdAt: Moment().toLocaleString(),
      deleted: "false",
      updatedAt: Moment().toLocaleString(),
      eventType: eventType,
      programRole: selectedRole.roleTitle,
      programId: programDetails.program.id,
      programName: programDetails.program.name,
      sessionStartDate: sessionStartDate,
      sessionEndDate: sessionEndDate,
      memberId: memberId,
      userId: selectedRole.person.userId,
      baseLocationCity: selectedRole.person.city,
      baseLocationDistrict: selectedRole.person.district,
      baseLocationSubDistrict: selectedRole.person.subDistrict,
      baseLocationState: selectedRole.person.state,
      baseLocationCountry: selectedRole.person.country,
      baseLocationLatitude: selectedRole.person.latitude,
      baseLocationLongitude: selectedRole.person.longitude,
      establishmentName: selectedRole.person.establishmentName
    };
    AddTelemetryService(body, undefined, updatedProfileInfo, true);
  };

  const handleQualificationAccept = async () => {
    setLoading(true);
    const payload = {
      verifierId: parseInt(props.programDetails?.program?.id),
      qualificationId: selectedQualification?.qualificationId,
      status: "APPROVED",
      responseDate: new Date().toISOString().replace("T", " ").replace("Z", ""),
      reason: ""
    };
    const handleRoleAcceptResponse = await ApiClient.post(
      verifyEndPoints.approveRejectQualification(),
      payload
    );
    if (handleRoleAcceptResponse.responseCode === HTTP_STATUS_CODES.ok) {
      toast.success(handleRoleAcceptResponse.message);
      getQualificationVerification();
      updateTelemetryQuali(true);
      props.onClose();
      setLoading(false);
    } else {
      setLoading(false);
      toast.error(handleRoleAcceptResponse.message);
    }
  };

  const handleQualificationDecline = async () => {
    setLoading(true);
    const payload = {
      verifierId: parseInt(props.programDetails?.program?.id),
      qualificationId: selectedQualification?.qualificationId,
      status: "REJECTED",
      responseDate: new Date().toISOString().replace("T", " ").replace("Z", ""),
      reason: reason
    };
    const handleRoleAcceptResponse = await ApiClient.post(
      verifyEndPoints.approveRejectQualification(),
      payload
    );
    if (handleRoleAcceptResponse.responseCode === HTTP_STATUS_CODES.ok) {
      toast.success(handleRoleAcceptResponse.message);
      getQualificationVerification();
      updateTelemetryQuali(false);
      props.onClose();
      setLoading(false);
    } else {
      setLoading(false);
      toast.error(handleRoleAcceptResponse.message);
    }
  };

  const updateTelemetryQuali = async (isAccepted: boolean) => {
    const updatedProfileInfo: any = await LocalStorage.getStorage("user");
    const programDetails: any = await LocalStorage.getStorage("programDetails");
    let eventType = "";
    isAccepted === true ? (eventType = "Approve Qual Req") : (eventType = "Decline Qual Req");
    const body = {
      timestamp: Moment().toLocaleString(),
      createdAt: Moment().toLocaleString(),
      deleted: "false",
      updatedAt: Moment().toLocaleString(),
      eventType: eventType,
      qualification: selectedQualification.qualificationTitle,
      programId: programDetails.program.id,
      programName: programDetails.program.name,
      userId: selectedQualification.person.userId,
      baseLocationCity: selectedQualification.person.city,
      baseLocationDistrict: selectedQualification.person.district,
      baseLocationSubDistrict: selectedQualification.person.subDistrict,
      baseLocationState: selectedQualification.person.state,
      baseLocationCountry: selectedQualification.person.country,
      baseLocationLatitude: selectedQualification.person.latitude,
      baseLocationLongitude: selectedQualification.person.longitude,
      establishmentName: selectedQualification.person.establishmentName
    };
    AddTelemetryService(body, undefined, updatedProfileInfo, true);
  };

  const getDocName = (document: string) => {
    const name = document.split("/");
    const lastItem = name.pop();
    return lastItem;
  };

  return (
    <View>
      <ToastContainer />
      {loading ? (
        <Loader customLoadingContainer={styles.loaderContiner} />
      ) : (
        <View style={styles.container}>
          <View style={styles.header}>
            <Text
              fontWeight={FontWeight.Bold}
              testId="verifyRoleQualificationText"
              textSize={TextSize.Small}
              textStyle={styles.headerText}
            >
              {selectedRole ? `Verify role` : `Verify Qualification`}
            </Text>
            <View>
              <Icon
                testID="close"
                name={IconNames.crossCircle}
                customStyle={styles.crossCircle}
                onPress={() => onClose()}
              />
            </View>
          </View>
          <View style={styles.subContainer}>
            <View style={styles.profilePictureContainer}>
              {selectedRole && (
                <Image
                  imageStyle={styles.profilePicture}
                  testId="socionImg"
                  source={
                    selectedRole?.person?.photo === ""
                      ? require("@socion-cordio/common/src/assets/images/user_circle.svg")
                      : selectedRole?.person?.photo
                  }
                />
              )}
              {selectedQualification && (
                <Image
                  imageStyle={styles.profilePicture}
                  testId="socionImg"
                  source={
                    selectedQualification?.person?.photo === ""
                      ? require("@socion-cordio/common/src/assets/images/user_circle.svg")
                      : selectedQualification?.person?.photo
                  }
                />
              )}
            </View>
            <View style={styles.memberInfoContainer}>
              <View>
                <Text
                  fontWeight={FontWeight.Bold}
                  testId="personNameText"
                  textSize={TextSize.Small}
                  textStyle={[styles.headerText]}
                >
                  {selectedRole?.person?.name || selectedQualification?.person?.name}
                </Text>
              </View>

              <View style={styles.alignLabelInfoContainer}>
                <Text
                  fontWeight={FontWeight.Regular}
                  testId="phoneNumberText"
                  textSize={TextSize.Small}
                  textStyle={[styles.headerText, styles.alignText, styles.flexLabel]}
                >
                  {"Contact Number : "}
                </Text>
                <Text
                  fontWeight={FontWeight.Regular}
                  testId="phoneNumberValueText"
                  textSize={TextSize.Small}
                  textStyle={[
                    styles.headerText,
                    styles.alignText,
                    styles.textColor,
                    styles.flexLabelContent
                  ]}
                >
                  {selectedRole
                    ? selectedRole?.person?.piiInfo
                      ? `${selectedRole?.person?.countryCode} ${selectedRole?.person?.phoneNumber}`
                      : `NA`
                    : selectedQualification?.person?.piiInfo
                    ? `${selectedQualification?.person?.countryCode} ${selectedQualification?.person?.phoneNumber}`
                    : `NA`}
                </Text>
              </View>
              {/* <Text
                fontWeight={FontWeight.Regular}
                testId="phoneNumberText"
                textSize={TextSize.Small}
                textStyle={[styles.headerText, styles.alignText]}
              >
                {selectedRole
                  ? `${selectedRole?.person?.countryCode} ${selectedRole?.person?.phoneNumber}`
                  : `${selectedQualification?.person?.countryCode} ${selectedQualification?.person?.phoneNumber}`}
              </Text> */}
              {/* <Text
                fontWeight={FontWeight.Regular}
                testId="emailText"
                textSize={TextSize.Small}
                textStyle={[styles.headerText, styles.alignText]}
              >
                {selectedRole?.person?.emailId || selectedQualification?.person?.emailId}
              </Text> */}
              <View style={styles.alignLabelInfoContainer}>
                <Text
                  fontWeight={FontWeight.Regular}
                  testId="phoneNumberText"
                  textSize={TextSize.Small}
                  textStyle={[styles.headerText, styles.alignText, styles.flexLabel]}
                >
                  {"Contact Email : "}
                </Text>
                <Text
                  fontWeight={FontWeight.Regular}
                  testId="phoneNumberValueText"
                  textSize={TextSize.Small}
                  textStyle={[
                    styles.headerText,
                    styles.alignText,
                    styles.textColor,
                    styles.flexLabelContent
                  ]}
                >
                  {selectedRole
                    ? selectedRole?.person?.piiInfo
                      ? selectedRole?.person?.emailId !== ""
                        ? selectedRole?.person?.emailId
                        : ""
                      : `NA`
                    : selectedQualification?.person?.piiInfo
                    ? selectedQualification?.person?.emailId !== ""
                      ? selectedQualification?.person?.emailId
                      : ""
                    : `NA`}
                </Text>
              </View>

              <View style={styles.alignLabelInfoContainer}>
                <Text
                  fontWeight={FontWeight.Regular}
                  testId="baseLocationTitleText"
                  textSize={TextSize.Small}
                  textStyle={[styles.headerText, styles.alignText, styles.flexLabel]}
                >
                  {"Base location : "}
                </Text>
                <Text
                  fontWeight={FontWeight.Regular}
                  testId="baseLocationValueText"
                  textSize={TextSize.Small}
                  textStyle={[
                    styles.headerText,
                    styles.alignText,
                    styles.textColor,
                    styles.flexLabelContent
                  ]}
                >
                  {auxiliaryMethods.fromatBaseLocation(
                    selectedRole?.person || selectedQualification?.person
                  )}
                </Text>
              </View>
              <View style={styles.alignLabelInfoContainer}>
                <Text
                  fontWeight={FontWeight.Regular}
                  testId="appliedRoleTitleText"
                  textSize={TextSize.Small}
                  textStyle={[styles.headerText, styles.alignText, styles.flexLabel]}
                >
                  {selectedRole ? "Applied role : " : "Qualification : "}
                </Text>
                <Text
                  fontWeight={FontWeight.Regular}
                  testId="appliedRoleValueText"
                  textSize={TextSize.Small}
                  textStyle={[
                    styles.headerText,
                    styles.alignText,
                    styles.textColor,
                    styles.flexLabelContent
                  ]}
                >
                  {selectedRole?.roleTitle || selectedQualification?.qualificationTitle}
                </Text>
              </View>
              <View style={styles.alignLabelInfoContainer}>
                <Text
                  fontWeight={FontWeight.Regular}
                  testId="statusTitleText"
                  textSize={TextSize.Small}
                  textStyle={[styles.headerText, styles.alignText, styles.flexLabel]}
                >
                  {"Status : "}
                </Text>
                <Text
                  fontWeight={FontWeight.Regular}
                  testId="statusValueText"
                  textSize={TextSize.Small}
                  textStyle={[
                    styles.headerText,
                    styles.alignText,
                    styles.textColor,
                    styles.flexLabelContent
                  ]}
                >
                  {selectedRole?.status || selectedQualification?.status}
                </Text>
              </View>
              {(selectedRole?.status || selectedQualification?.status) != "APPROVED" &&
                (selectedRole?.status || selectedQualification?.status) != "PENDING" && (
                  <View style={styles.alignLabelInfoContainer}>
                    <Text
                      fontWeight={FontWeight.Regular}
                      testId="statusTitleText"
                      textSize={TextSize.Small}
                      textStyle={[styles.headerText, styles.alignText, styles.flexLabel]}
                    >
                      {"Reason : "}
                    </Text>
                    <Text
                      fontWeight={FontWeight.Regular}
                      testId="statusValueText"
                      textSize={TextSize.Small}
                      textStyle={[
                        styles.headerText,
                        styles.alignText,
                        styles.textColor,
                        styles.flexLabelContent
                      ]}
                    >
                      <ScrollContainer
                        height="50px"
                        scrollable={
                          selectedRole?.reason.length > 150 ||
                          selectedQualification?.reason.length > 150
                            ? true
                            : false
                        }
                      >
                        {/* <ScrollView style={styles.reasonScrollConatiner}> */}
                        {selectedRole?.reason || selectedQualification?.reason}
                        {/* </ScrollView> */}
                      </ScrollContainer>
                    </Text>
                  </View>
                )}
              {selectedRole && (
                <View style={styles.alignLabelInfoContainer}>
                  <Text
                    fontWeight={FontWeight.Regular}
                    testId="startDateTitleText"
                    textSize={TextSize.Small}
                    textStyle={[styles.headerText, styles.alignText, styles.flexLabel]}
                  >
                    {"Start date : "}
                  </Text>
                  <Text
                    fontWeight={FontWeight.Regular}
                    testId="startDateValueText"
                    textSize={TextSize.Small}
                    textStyle={[
                      styles.headerText,
                      styles.alignText,
                      styles.textColor,
                      styles.flexLabelContent
                    ]}
                  >
                    {selectedRole?.roleStartDate
                      ? dataHelper.formatDate(selectedRole?.roleStartDate, "DD MMM YYYY")
                      : "NA"}
                  </Text>
                </View>
              )}
              {selectedRole && (
                <View style={styles.alignLabelInfoContainer}>
                  <Text
                    fontWeight={FontWeight.Regular}
                    testId="endDateTitleText"
                    textSize={TextSize.Small}
                    textStyle={[styles.headerText, styles.alignText, styles.flexLabel]}
                  >
                    {"End date : "}
                  </Text>
                  <Text
                    fontWeight={FontWeight.Regular}
                    testId="endDateValueText"
                    textSize={TextSize.Small}
                    textStyle={[
                      styles.headerText,
                      styles.alignText,
                      styles.textColor,
                      styles.flexLabelContent
                    ]}
                  >
                    {selectedRole?.roleEndDate
                      ? dataHelper.formatDate(selectedRole?.roleEndDate, "DD MMM YYYY")
                      : "NA"}
                  </Text>
                </View>
              )}
            </View>
          </View>

          <View>
            <Text
              fontWeight={FontWeight.Regular}
              testId="supportingDocsTitleText"
              textSize={TextSize.Small}
              textStyle={[styles.headerText, styles.alignText, { marginBottom: 10 }]}
            >
              Supporting documents
            </Text>
            <View>
              {/* <ScrollView style={styles.documentsContainer}> */}
              <ScrollContainer
                scrollable={
                  selectedRole?.documents?.length > 1 ||
                  selectedQualification?.documents?.length > 1
                    ? true
                    : false
                }
                height="100px"
              >
                {(selectedRole?.documents?.length > 0 ||
                  selectedQualification?.documents?.length > 0) &&
                  (selectedRole?.documents || selectedQualification?.documents).map(
                    (document: string, key: number) => {
                      return (
                        <View key={key} style={styles.contentContainer}>
                          <View style={styles.miniContainer}>
                            <a href={document} target="_blank">
                              <Text
                                fontWeight={FontWeight.Bold}
                                testId="headerText"
                                textSize={TextSize.Small}
                                textStyle={[styles.headerText, styles.miniContainerHeaderText]}
                              >
                                {getDocName(document)}
                              </Text>
                            </a>
                          </View>
                          <TouchableOpacity>
                            <View style={styles.miniIconContainer}>
                              <Icon
                                testID="download"
                                name={IconNames.downloadList}
                                customStyle={[styles.alignContainerButton]}
                                onPress={() => hanldeDocumentDownload(document)}
                              />
                            </View>
                          </TouchableOpacity>
                        </View>
                      );
                    }
                  )}
                {/* </ScrollView> */}
              </ScrollContainer>
            </View>
          </View>

          {userRoles.includes("VERIFIER") && (
            <>
              {canEdit &&
                ((selectedRole &&
                  selectedRole?.status !== "APPROVED" &&
                  selectedRole?.status !== "DELETED BY USER" &&
                  selectedRole?.status !== "REJECTED") ||
                  (selectedQualification &&
                    selectedQualification?.status !== "APPROVED" &&
                    selectedQualification?.status !== "DELETED BY USER" &&
                    selectedQualification?.status !== "REJECTED")) && (
                  <View
                    style={[
                      styles.alignContainer,
                      { marginBottom: 30, flexDirection: "row", justifyContent: "center" }
                    ]}
                  >
                    <Button
                      type={ButtonType.Secondary}
                      buttonStyles={styles.submitbutton}
                      textStyles={styles.buttonFont}
                      title="Decline"
                      onPress={() => {
                        setOnDecline(true);
                      }}
                      disabled={onDecline}
                    />
                    <Button
                      type={ButtonType.Primary}
                      buttonStyles={styles.submitbutton}
                      textStyles={styles.buttonFont}
                      title="Accept"
                      onPress={
                        selectedRole ? () => handleRoleAccept() : () => handleQualificationAccept()
                      }
                    />
                  </View>
                )}
            </>
          )}

          <View>
            {onDecline && canEdit && (
              <View>
                <UserInputSecondary
                  textValue="Reason for declining verification"
                  handleChange={(reason: string) => {
                    setReason(reason);
                  }}
                  handleBlur={() => {}}
                  value={reason}
                  placeholder="Enter reason"
                  name="reasonToDecline"
                  id="reasonToDecline"
                  userStyle={styles.inputStylingAlignment}
                  userStyleText={styles.textHeaderField}
                  noFormik
                />

                <View style={[styles.alignContainer, { marginTop: 10 }]}>
                  <Button
                    type={ButtonType.Primary}
                    buttonStyles={styles.submitbutton}
                    title="Submit"
                    textStyles={styles.buttonFont}
                    disabled={reason?.length === 0 || reason?.charAt(0) === " "}
                    onPress={
                      selectedRole ? () => handleRoleDecline() : () => handleQualificationDecline()
                    }
                  />
                </View>
              </View>
            )}
          </View>
        </View>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  reasonText: {
    marginTop: 10,
    marginLeft: 10,
    lineHeight: 17,
    fontSize: 12,
    fontFamily: FontFamily.Regular
  },
  statusText: {
    marginLeft: -20
  },
  container: {
    width: 655
  },
  loaderContiner: {
    width: 655,
    height: 320
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row"
  },
  headerText: {
    fontSize: 12,
    fontFamily: FontFamily.Medium,
    lineHeight: 17
  },
  alignText: {
    color: colorPallete.textLight,
    marginTop: 10,
    fontSize: 12
    // marginBottom: 5
  },
  textColor: {
    color: colorPallete.textBlack
  },
  crossCircle: {
    fontSize: 15
  },
  subContainer: {
    flexDirection: "row",
    marginTop: 15,
    marginBottom: 15
  },
  profilePictureContainer: {
    flex: 16
    // alignItems: "center"
  },
  profilePicture: {
    borderRadius: 54,
    width: " 80px",
    height: "80px"
  },
  memberInfoContainer: {
    flex: 84,
    marginLeft: 15,
    marginRight: 15
  },
  alignLabelInfoContainer: {
    flexDirection: "row"
  },
  flexLabel: {
    flex: 23
  },
  flexLabelContent: {
    flex: 77
  },
  alignContainer: {
    alignItems: "center",
    marginTop: 15,
    marginBottom: 15
  },
  submitbutton: {
    width: "167px",
    height: "50px",
    borderRadius: 10,
    marginLeft: 10,
    marginRight: 10
  },
  buttonFont: {
    fontSize: 12
  },
  contentContainer: {
    minHeight: 64,
    borderRadius: 5,
    marginBottom: 10,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    paddingRight: 20,
    paddingLeft: 20,
    borderColor: colorPallete.cordioTaupe,
    borderStyle: "solid",
    borderWidth: 1,
    marginRight: 5
  },
  miniContainer: {
    flexDirection: "row",
    width: "90%"
  },
  miniContainerHeaderText: {
    color: colorPallete.black,
    fontSize: 10,
    lineHeight: 15,
    width: 580
  },
  miniIconContainer: {},
  alignContainerButton: {
    fontSize: 18,
    color: colorPallete.cordioTaupe,
    marginRight: 5,
    marginLeft: 5
  },
  inputStylingAlignment: {
    width: "100%",
    fontSize: 12
  },
  textHeaderField: {
    flex: 30,
    marginTop: 5,
    fontSize: 12
  },
  reasonScrollConatiner: {
    maxHeight: 50
  },
  documentsContainer: {
    maxHeight: 110
  }
});
