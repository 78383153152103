import profileRouter from "modules/profile/router/profileRouter";
import SpringshedRouter from "modules/springshed/router/springshedRouter";
import React, { Fragment, ReactElement, useEffect, useState } from "react";
import { Route, useHistory } from "react-router-dom";
import { allRoutesNames as routeNames } from "./allRouteNames";
import MapRoutes from "./mapRoutes";
import { Roles } from "@socion-cordio/common/src/constants/roles";
import ProfileNavbar from "@socion-cordio/common/src/components/organisms/profileNavbar";
import Header from "@socion-cordio/common/src/components/organisms/topNavbar";
import { ToastContainer } from "react-toastify";
import { TouchableWithoutFeedback } from "react-native";
import ArtefactNavRouter from "modules/artefactNavRouter/router/artefactNavRouter";
import ArtefactNavbar from "@socion-cordio/common/src/components/organisms/artefactNavbar";
import ArtefactProgramDropdown from "@socion-cordio/common/src/components/organisms/tasks/artefactProgramDropdown";
import { useQuery } from "modules/artefacts/hooks/useQueryHook";
import Button, { ButtonType } from "@socion-cordio/common/src/components/atoms/button";
import { StyleSheet } from "react-native";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { canAccessAll, canOnlyView } from "utils/artefactActionsHelper";
import ArtefactRouter from "modules/artefacts/router/artefactRouter";
import { LocalStorage } from "@socion-cordio/common/src/services/storage/storageService";

interface IProps {}

export interface IUserDetails {
  id: number;
  name: string;
  roles: Roles[];
  storeId: number;
}

const FlexCenter = styled.div`
  display: flex;
  align-items: center;
  &.p10 {
    padding: 15px;
  }
  &.p20 {
    padding: 20px;
  }
  .flexOne {
    flex: 1;
  }
`;

export default function ArtefactsPrivateRouter(props: IProps): ReactElement {
  const history: any = useHistory();
  const allPrivateRoutes = [
    ...profileRouter(),
    ...ArtefactNavRouter(),
    ...ArtefactRouter(),
    ...SpringshedRouter()
  ];
  // const allowedRoutes = getAllowedRoutes(allPrivateRoutes);
  const [isOpen, setIsOpen] = useState(null);
  const [widthCollapse, setWidthCollapse] = useState(undefined);
  const [entityName, setEntityName] = useState(null);
  const [open, setOpen] = useState(false);
  const [displayActionButtons, setDisplayActionButtons] = useState(false);
  const query = useQuery();

  const {
    userRolesData: userRoles,
    loading: { getUserRolesProgram: rolesLoader }
  } = useSelector((state: any) => state?.userRolesProgram);

  const isAllowedToAddArtefacts = !canOnlyView();
  const isAllowedToViewArtefactTypes = canAccessAll();
  const [showFilterModal, setShowFilterModal] = useState(null);

  //const [isPdaPreview, setIsPdaPreview] = useState(null);

  useEffect(() => {
    if (window.location.pathname == `${routeNames.app}${routeNames.workspace}`) {
      setDisplayActionButtons(true);
    } else {
      setDisplayActionButtons(false);
    }
    window.location.pathname == `${routeNames.app}${routeNames.workspace}`;
    //   ? [setIsOpen(false), setEntityName(null)]
    //   :
    setIsOpen(!query.get("token"));
    console.log("window.location.pathname : ", window.location.pathname);
    setShowFilterModal(
      window.location.pathname == `${routeNames.app}${routeNames.ARTEFACTS_FILTER}`
    );
  }, [window.location.pathname]);

  const getSidePanelWidth = (width: any, isExpanded: boolean) => {
    setWidthCollapse(width);
  };

  const callBackHandler = (data: any) => {
    setEntityName(data);
  };

  const getArtefactTypes = () => {
    history.push(`${routeNames.app}${routeNames.ARTEFACT_TYPES}`);
  };

  const addNewArtefact = () => {
    history.push(`${routeNames.app}${routeNames.ADD_ARTEFACT}`);
  };

  return (
    <TouchableWithoutFeedback onPress={() => setOpen(false)}>
      {allPrivateRoutes?.some((route: { path: string }) =>
        window.location.pathname.includes(route.path)
      ) ? (
        <div>
          <div>
            <Header currentEntityName={entityName} />
          </div>

          <div
            style={{
              flexDirection: "row",
              display: "flex",
              minHeight: "calc(100vh - 50px)",
              marginTop: 50,
              flexGrow: 1,
              overflow: "hidden"
            }}
          >
            <div
              style={
                isOpen
                  ? {
                      // flex: 0.2,
                      width: 60,
                      position: "fixed",
                      zIndex: 1
                    }
                  : null
              }
            >
              {window.location.pathname.includes(routeNames.PROFILE) ? (
                <ProfileNavbar getSidePanelWidth={getSidePanelWidth} />
              ) : (
                <ArtefactNavbar getSidePanelWidth={getSidePanelWidth} />
              )}
            </div>
            <div
              style={
                !isOpen
                  ? { width: "100%", flex: 1, backgroundColor: "#F8F5F0", flexGrow: 1 }
                  : {
                      marginLeft: widthCollapse,
                      width: "100%",
                      flex: 1,
                      backgroundColor: "#F8F5F0",
                      flexGrow: 1
                    }
              }
            >
              <ToastContainer />
              {isOpen && (
                <FlexCenter className="p10">
                  <div className="flexOne">
                    <ArtefactProgramDropdown
                      isDropdownOpen={open}
                      setIsDropdownOpen={setOpen}
                      callbackFromParentHandler={callBackHandler}
                    />
                  </div>
                  {displayActionButtons && (
                    <FlexCenter>
                      {isAllowedToViewArtefactTypes && (
                        <Button
                          type={ButtonType.Primary}
                          title="Artefact Type"
                          buttonStyles={styles.button}
                          textStyles={styles.buttonFont}
                          onPress={() => [getArtefactTypes()]}
                        />
                      )}
                      {isAllowedToAddArtefacts && (
                        <Button
                          type={ButtonType.Primary}
                          title="Add New Artefact"
                          buttonStyles={styles.button}
                          textStyles={styles.buttonFont}
                          onPress={() => [addNewArtefact()]}
                        />
                      )}
                    </FlexCenter>
                  )}
                </FlexCenter>
              )}
              <MapRoutes routes={allPrivateRoutes} basePath={routeNames.app} isAddNotFound />
            </div>
          </div>
        </div>
      ) : (
        <Route component={() => <div>Not Found</div>} />
      )}
    </TouchableWithoutFeedback>
  );
}

const styles = StyleSheet.create({
  button: {
    width: "167px",
    height: "50px",
    borderRadius: 10,
    marginLeft: "20px"
  },
  buttonFont: {
    fontSize: 14
  }
});
