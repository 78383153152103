import { call, put, all, take, takeLatest, fork, takeEvery } from "redux-saga/effects";
import { PARTICIPATIONDETAILS } from "@socion-cordio/common/src/modules/participationDetails/actions/actionTypes";
import { ParticipantDetailsActions } from "@socion-cordio/common/src/modules/participationDetails/actions/actions";
import { ParticipationDetailsRepository } from "@socion-cordio/common/src/repositories/participantDetails/participantDetails";
import { Login } from "@socion-cordio/common/src/models/login";
import { IFluxStandardAction, IState } from "store/interfaces";

/**
 * TODO:
 * Add models for Participation Details here
 */
function* getParticipantDetailsReducerSaga(
  value: IFluxStandardAction<{ programId: string; userType: string }>
) {
  try {
    const { payload } = value;
    const response: Login = yield call(ParticipationDetailsRepository.getParticipantList, payload);
    yield put(ParticipantDetailsActions.getParticipantListSucess(response));
  } catch (error) {
    yield put(ParticipantDetailsActions.getParticipantListError(error));
  }
}

function* getTrainersDetailsReducerSaga(
  value: IFluxStandardAction<{ programId: string; userType: string }>
) {
  try {
    const { payload } = value;
    const response: Login = yield call(ParticipationDetailsRepository.getTrainersList, payload);
    console.log("response", response);
    yield put(ParticipantDetailsActions.getTrainersListSucess(response));
  } catch (error) {
    yield put(ParticipantDetailsActions.getParticipantListError(error));
  }
}

function* getParticipantAttestationReducerSaga(
  value: IFluxStandardAction<{
    userType: string;
    startDate: string;
    endDate: string;
    programId: string;
  }>
) {
  try {
    const { payload } = value;
    const response: Login = yield call(
      ParticipationDetailsRepository.getParticipantAttestationList,
      payload
    );
    yield put(ParticipantDetailsActions.getParticipantAttestationSucess(response));
  } catch (error) {
    yield put(ParticipantDetailsActions.getParticipantAttestationError(error));
  }
}

function* getTrainerAttestationReducerSaga(
  value: IFluxStandardAction<{
    userType: string;
    startDate: string;
    endDate: string;
    programId: string;
  }>
) {
  try {
    const { payload } = value;
    const response: Login = yield call(
      ParticipationDetailsRepository.getTrainerAttestationList,
      payload
    );
    yield put(ParticipantDetailsActions.getTrainerAttestationSucess(response));
  } catch (error) {
    yield put(ParticipantDetailsActions.getTrainerAttestationError(error));
  }
}

function* getTrainerByTopicReducerSaga(value: IFluxStandardAction<{ programId: string }>) {
  try {
    const { payload } = value;
    const response: Login = yield call(
      ParticipationDetailsRepository.getTrainerByTopicList,
      payload
    );
    yield put(ParticipantDetailsActions.getTrainerByTopicSucess(response));
  } catch (error) {
    yield put(ParticipantDetailsActions.getTrainerByTopicError(error));
  }
}

export default function* participationDetailsWatcherSaga() {
  yield takeLatest(
    PARTICIPATIONDETAILS.GET_PARTICIPANT_LIST.LOADING,
    getParticipantDetailsReducerSaga
  );
  yield takeLatest(PARTICIPATIONDETAILS.GET_TRAINERS_LIST.LOADING, getTrainersDetailsReducerSaga);
  yield takeLatest(
    PARTICIPATIONDETAILS.GET_PARTICIPANT_ATTESTATION.LOADING,
    getParticipantAttestationReducerSaga
  );
  yield takeLatest(
    PARTICIPATIONDETAILS.GET_TRAINER_ATTESTATION.LOADING,
    getTrainerAttestationReducerSaga
  );
  yield takeLatest(PARTICIPATIONDETAILS.GET_TRAINER_BY_TOPIC.LOADING, getTrainerByTopicReducerSaga);
}
