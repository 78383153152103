import React, { ReactElement, useState } from "react";
import { StyleSheet, View } from "react-native";
import { Form, Formik, FormikProps } from "formik";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { colorPallete } from "@socion-cordio/common/src/assets/styles/colors";
import {
  Text,
  TextSize,
  FontWeight,
  FontFamily
} from "@socion-cordio/common/src/components/atoms/text";
import Icon, { IconNames } from "@socion-cordio/common/src/components/atoms/icon";
import Button, { ButtonType } from "@socion-cordio/common/src/components/atoms/button";
import UserInputTextArea from "@socion-cordio/common/src/components/molecules/userInputTextArea";
import UserInputSecondary from "@socion-cordio/common/src/components/molecules/userInputSecondary";
import { ApiClient } from "@socion-cordio/common/src/network/apiClient";
import { badgesEndPoints } from "@socion-cordio/common/src/repositories/endPoints";
import { HTTP_STATUS_CODES } from "@socion-cordio/common/src/network/constants";

interface Props {
  onClose?: Function;
  getBadges?: Function;
  programDetails?: any;
}
export default function AddBadgeModal(props: Props): ReactElement {
  const { onClose, getBadges, programDetails } = props;

  const formValidaionSchema = Yup.object({
    badgeName: Yup.string().required("Required"),
    description: Yup.string().required("Required"),
    awardCriteria: Yup.string().required("Required")
  });

  const onSubmitForm = async (data: {
    badgeName: string;
    description: string;
    awardCriteria: string;
  }) => {
    const payload = {
      name: data.badgeName,
      description: data.description,
      awardingCriteria: data.awardCriteria,
      badgeTypeId: 1,
      programId: programDetails?.program?.id
    };
    let response = await ApiClient.post(badgesEndPoints.addBadge(), payload);
    if (response.responseCode === HTTP_STATUS_CODES.ok) {
      onClose();
      getBadges();
      toast.success(response.message);
    } else if (response.responseCode == HTTP_STATUS_CODES.conflict) {
      toast.error(response.message);
    } else {
      toast.error("Something went wrong...!!!");
    }
  };

  return (
    <View>
      <View style={styles.container}>
        <View style={styles.header}>
          <Text
            fontWeight={FontWeight.Bold}
            testId="addRoleText"
            textSize={TextSize.Small}
            textStyle={styles.headerText}
          >
            Add badge
          </Text>
          <View>
            <Icon
              testID="close"
              name={IconNames.crossCircle}
              customStyle={styles.crossCircle}
              onPress={() => onClose()}
            />
          </View>
        </View>
        <View style={styles.subContainer}>
          <Formik
            initialValues={{ badgeName: "", description: "", awardCriteria: "" }}
            validationSchema={formValidaionSchema}
            onSubmit={(values) => {
              onSubmitForm(values);
            }}
          >
            {(
              formikProps: FormikProps<{
                badgeName: string;
                description: string;
                awardCriteria: string;
              }>
            ) => (
              <Form>
                <View>
                  <View>
                    <UserInputSecondary
                      textValue="Badge name"
                      handleChange={formikProps.handleChange("badgeName")}
                      handleBlur={() => formikProps.setFieldTouched}
                      value={formikProps.values.badgeName}
                      // inputStyle={[styles.inputStyling, styles.inputStylingAlignment]}
                      textInputContainerStyles={{ marginLeft: 15 }}
                      placeholder="Enter Badge name"
                      name="badgeName"
                      id="badgeName"
                      userStyle={styles.inputStylingAlignment}
                      userStyleText={styles.textHeaderField}
                    />
                  </View>
                  <View>
                    <UserInputTextArea
                      textValue="Description"
                      label="Description"
                      placeholder="Write a description"
                      name="description"
                      id="description"
                      handleChange={formikProps.handleChange("description")}
                      handleBlur={() => formikProps.setFieldTouched}
                      value={formikProps.values.description}
                      numberOfLines={5}
                      multiline={true}
                      userStyle={styles.inputStylingAlignment}
                      userStyleText={styles.textHeaderField}
                    />
                  </View>
                  <View>
                    <UserInputTextArea
                      textValue="Awarding criteria"
                      label="Awarding criteria"
                      placeholder="Add criteria"
                      name="awardCriteria"
                      id="awardCriteria"
                      handleChange={formikProps.handleChange("awardCriteria")}
                      handleBlur={() => formikProps.setFieldTouched}
                      value={formikProps.values.awardCriteria}
                      numberOfLines={5}
                      multiline={true}
                      userStyle={styles.inputStylingAlignment}
                      userStyleText={styles.textHeaderField}
                    />
                  </View>
                  <View style={[styles.alignContainer]}>
                    <Button
                      type={ButtonType.Primary}
                      buttonStyles={styles.submitbutton}
                      title="Add badge"
                      onPress={formikProps.submitForm}
                      disabled={!formikProps.dirty}
                    />
                  </View>
                </View>
              </Form>
            )}
          </Formik>
        </View>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    width: 680
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row"
  },
  headerText: {
    fontSize: 14,
    fontFamily: FontFamily.Medium,
    lineHeight: 17
  },
  alignText: {
    color: colorPallete.textLight,
    marginTop: 10
  },
  textColor: {
    color: colorPallete.textBlack
  },
  crossCircle: {
    fontSize: 15
  },
  subContainer: {
    marginTop: 15,
    marginBottom: 15
  },
  alignContainer: {
    alignItems: "center",
    marginTop: 25,
    marginBottom: 0
  },
  submitbutton: {
    width: "167px",
    height: "50px",
    borderRadius: 10,
    marginLeft: 10,
    marginRight: 10
  },
  inputStylingAlignment: {
    width: "100%"
  },
  textHeaderField: {
    flex: 25
  }
});
