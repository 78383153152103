import { Profile } from "@socion-cordio/common/src/models/profile";
import { UserProfile } from "@socion-cordio/common/src/models/userProfile";
import { UserRole } from "@socion-cordio/common/src/models/userRole";
import { UserQualification } from "@socion-cordio/common/src/models/userQualification";
import { EligibleProgramsForRole } from "@socion-cordio/common/src/models/eligibleProgramsForRole";
import { PROFILE } from "@socion-cordio/common/src/modules/profile/actions/actionTypes";
import { EligibleProgramsForQualification } from "@socion-cordio/common/src/models/eligibleProgramsForQualification";

export interface IProfileLoading {
  profileGet: boolean;
  profileUpdate: boolean;
}
export interface IProfileState {
  profileData: Profile;
  userProfileData: UserProfile;
  fileLink: string;
  userRoleData: UserRole;
  userQualificationData: UserQualification;
  userEligibleProgramsForRoleData: EligibleProgramsForRole;
  userEligibleProgramsForQualificationData: EligibleProgramsForQualification;
  error: {
    profileGet: string;
    userProfileGet: string;
    profileUpdate: string;
    fileUpload: string;
    userRoleGet: string;
    userQualificationGet: string;
    userEligibleProgramsForRoleGet: string;
    userEligibleProgramsForQualificationGet: string;
  };
  loading: {
    profileGet: boolean;
    userProfileGet: boolean;
    profileUpdate: boolean;
    fileUpload: boolean;
    userRoleGet: boolean;
    userQualificationGet: boolean;
    userEligibleProgramsForRoleGet: boolean;
    userEligibleProgramsForQualificationGet: boolean;
  };
}

export const profileInitialState: IProfileState = {
  profileData: {
    id: "",
    fName: "",
    lName: "",
    email: "",
    age: 0
  },
  userProfileData: {
    response: {
      state: "",
      district: "",
      city: "",
      country: "",
      subDistrict: "",
      fullName: "",
      email: "",
      salutation: "",
      phoneNumber: "",
      photo: "",
      userId: "",
      createdAt: "",
      updatedAt: "",
      active: "",
      profile: "",
      countryCode: "",
      piiInfo: "",
      latitude: "",
      longitude: "",
      baseLocation: "",
      pushNotificationStatus: "",
      establishmentName: "",
      osid: "",
      profileCardGenerated: ""
    },
    message: "",
    responseCode: null
  },
  fileLink: "",
  userRoleData: {
    response: [
      {
        roleId: "",
        rolePrograms: [],
        documents: [],
        roleTitle: "",
        userId: "",
        osid: ""
      }
    ],
    message: "",
    responseCode: null
  },
  userQualificationData: {
    response: [
      {
        qualificationId: "",
        qualificationPrograms: [],
        documents: [],
        qualificationTitle: "",
        userId: "",
        osid: ""
      }
    ],
    message: "",
    responseCode: null
  },
  userEligibleProgramsForRoleData: {
    response: [
      {
        createdAt: "",
        updatedAt: "",
        createdBy: "",
        updatedBy: "",
        deleted: "",
        startDate: "",
        endDate: "",
        email: "",
        userLimit: null,
        acceptingVerifications: "",
        acceptingQualifications: "",
        acceptingRoles: "",
        notificationStatus: "",
        geoLevel: "",
        topicLibraryEnabled: "",
        subContentEnabled: "",
        licensesIncludeTopicLibraryAccess: "",
        sessionScanMode: "",
        multipleScanModeAccess: "",
        saSurveyEnabled: "",
        paSurveyEnabled: "",
        hyperlinked: "",
        id: null,
        name: "",
        description: ""
      }
    ],
    message: "",
    responseCode: null
  },
  userEligibleProgramsForQualificationData: {
    response: [
      {
        createdAt: "",
        updatedAt: "",
        createdBy: "",
        updatedBy: "",
        deleted: "",
        startDate: "",
        endDate: "",
        email: "",
        userLimit: null,
        acceptingVerifications: "",
        acceptingQualifications: "",
        acceptingRoles: "",
        notificationStatus: "",
        geoLevel: "",
        topicLibraryEnabled: "",
        subContentEnabled: "",
        licensesIncludeTopicLibraryAccess: "",
        sessionScanMode: "",
        multipleScanModeAccess: "",
        saSurveyEnabled: "",
        paSurveyEnabled: "",
        hyperlinked: "",
        id: null,
        name: "",
        description: ""
      }
    ],
    message: "",
    responseCode: null
  },
  error: {
    profileGet: "",
    userProfileGet: "",
    profileUpdate: "",
    fileUpload: "",
    userRoleGet: "",
    userQualificationGet: "",
    userEligibleProgramsForRoleGet: "",
    userEligibleProgramsForQualificationGet: ""
  },
  loading: {
    profileGet: false,
    userProfileGet: false,
    profileUpdate: false,
    fileUpload: false,
    userRoleGet: false,
    userQualificationGet: false,
    userEligibleProgramsForRoleGet: false,
    userEligibleProgramsForQualificationGet: false
  }
};

const profileReducer = (
  state = profileInitialState,
  action: { payload: any; type: string }
): IProfileState => {
  switch (action.type) {
    case PROFILE.GET.LOADING:
      return {
        ...state,
        loading: { ...state.loading, profileGet: true },
        error: { ...state.error, profileGet: null }
      };
    case PROFILE.GET.SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, profileGet: false },
        profileData: action.payload
      };
    case PROFILE.GET.ERROR:
      return {
        ...state,
        loading: { ...state.loading, profileGet: false },
        error: { ...state.error, profileGet: action.payload }
      };
    case PROFILE.GET_USER_PROFILE.LOADING:
      return {
        ...state,
        loading: { ...state.loading, userProfileGet: true },
        error: { ...state.error, userProfileGet: null }
      };
    case PROFILE.GET_USER_PROFILE.SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, userProfileGet: false },
        userProfileData: action.payload
      };
    case PROFILE.GET_USER_PROFILE.ERROR:
      return {
        ...state,
        loading: { ...state.loading, userProfileGet: false },
        error: { ...state.error, userProfileGet: action.payload }
      };
    case PROFILE.UPDATE.LOADING:
      return {
        ...state,
        loading: { ...state.loading, profileUpdate: true },
        error: { ...state.error, profileUpdate: null }
      };
    case PROFILE.UPDATE.SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, profileUpdate: false },
        profileData: action.payload
      };
    case PROFILE.UPDATE.ERROR:
      return {
        ...state,
        loading: { ...state.loading, profileUpdate: false },
        error: { ...state.error, profileUpdate: action.payload }
      };
    case PROFILE.FILE_UPLOAD.LOADING:
      return {
        ...state,
        loading: { ...state.loading, fileUpload: true },
        error: { ...state.error, fileUpload: null }
      };
    case PROFILE.FILE_UPLOAD.SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, fileUpload: false },
        fileLink: action.payload
      };
    case PROFILE.FILE_UPLOAD.ERROR:
      return {
        ...state,
        loading: { ...state.loading, fileUpload: false },
        error: { ...state.error, fileUpload: action.payload }
      };
    case PROFILE.GET_USER_ROLE.LOADING:
      return {
        ...state,
        loading: { ...state.loading, userRoleGet: true },
        error: { ...state.error, userRoleGet: null }
      };
    case PROFILE.GET_USER_ROLE.SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, userRoleGet: false },
        userRoleData: action.payload
      };
    case PROFILE.GET_USER_ROLE.ERROR:
      return {
        ...state,
        loading: { ...state.loading, userRoleGet: false },
        error: { ...state.error, userRoleGet: action.payload }
      };
    case PROFILE.GET_USER_QUALIFICATION.LOADING:
      return {
        ...state,
        loading: { ...state.loading, userQualificationGet: true },
        error: { ...state.error, userQualificationGet: null }
      };
    case PROFILE.GET_USER_QUALIFICATION.SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, userQualificationGet: false },
        userQualificationData: action.payload
      };
    case PROFILE.GET_USER_QUALIFICATION.ERROR:
      return {
        ...state,
        loading: { ...state.loading, userQualificationGet: false },
        error: { ...state.error, userQualificationGet: action.payload }
      };

    case PROFILE.GET_USER_ELIGIBLE_PROGRAMS_FOR_ROLE.LOADING:
      return {
        ...state,
        loading: { ...state.loading, userEligibleProgramsForRoleGet: true },
        error: { ...state.error, userEligibleProgramsForRoleGet: null }
      };
    case PROFILE.GET_USER_ELIGIBLE_PROGRAMS_FOR_ROLE.SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, userEligibleProgramsForRoleGet: false },
        userEligibleProgramsForRoleData: action.payload
      };
    case PROFILE.GET_USER_ELIGIBLE_PROGRAMS_FOR_ROLE.ERROR:
      return {
        ...state,
        loading: { ...state.loading, userEligibleProgramsForRoleGet: false },
        error: { ...state.error, userEligibleProgramsForRoleGet: action.payload }
      };

    case PROFILE.GET_USER_ELIGIBLE_PROGRAMS_FOR_QUALIFICATION.LOADING:
      return {
        ...state,
        loading: { ...state.loading, userEligibleProgramsForQualificationGet: true },
        error: { ...state.error, userEligibleProgramsForQualificationGet: null }
      };
    case PROFILE.GET_USER_ELIGIBLE_PROGRAMS_FOR_QUALIFICATION.SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, userEligibleProgramsForQualificationGet: false },
        userEligibleProgramsForQualificationData: action.payload
      };
    case PROFILE.GET_USER_ELIGIBLE_PROGRAMS_FOR_QUALIFICATION.ERROR:
      return {
        ...state,
        loading: { ...state.loading, userEligibleProgramsForQualificationGet: false },
        error: { ...state.error, userEligibleProgramsForQualificationGet: action.payload }
      };

    default:
      return state;
  }
};
export default profileReducer;
