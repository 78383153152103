import { dataHelper } from "@socion-cordio/common/src/utils/dataHelper";
import AddTelemetryService from "@socion-cordio/common/src/services/telemetryService";
import { LocalStorage } from "@socion-cordio/common/src/services/storage/storageService";
import Moment from "moment";
import moment from "moment-timezone";

const fromatBaseLocation = (data: any) => {
  let formattedBaseLocation: string = "";
  if (data?.city !== "") {
    formattedBaseLocation += `${data?.city}, `;
  }
  if (data?.subDistrict !== "") {
    formattedBaseLocation += `${data?.subDistrict}, `;
  }
  if (data?.district !== "") {
    formattedBaseLocation += `${data?.district}, `;
  }
  if (data?.state !== "") {
    formattedBaseLocation += `${data?.state}, `;
  }
  if (data?.country !== "") {
    formattedBaseLocation += `${data?.country} `;
  }
  return formattedBaseLocation;
};

const fromatBaseLocationSecondary = (data: any) => {
  let formattedBaseLocation: string = "";
  if (data?.city !== "") {
    formattedBaseLocation += `${data?.city}, `;
  }
  if (data?.sub_district !== null || "") {
    formattedBaseLocation += `${data?.sub_district}, `;
  }
  if (data?.district !== "") {
    formattedBaseLocation += `${data?.district}, `;
  }
  if (data?.state !== "") {
    formattedBaseLocation += `${data?.state}, `;
  }
  if (data?.country !== "") {
    formattedBaseLocation += `${data?.country} `;
  }
  return formattedBaseLocation;
};

const fromatBaseTaskLocation = (data: any) => {
  let formattedBaseLocation: string = "";
  if ("village" in data) {
    if (data?.village !== "") {
      formattedBaseLocation += `${data?.village}, `;
    }
  }
  if ("city" in data) {
    if (data?.city !== "") {
      formattedBaseLocation += `${data?.city}, `;
    }
  }
  if (data?.district !== "") {
    formattedBaseLocation += `${data?.district}, `;
  }
  if (data?.state !== "") {
    formattedBaseLocation += `${data?.state}, `;
  }
  if (data?.country !== "") {
    formattedBaseLocation += `${data?.country} `;
  }
  return formattedBaseLocation;
};

const fromatBaseTaskSubmissionLocation = (data: any) => {
  let formattedBaseLocation: string = "";
  if ("village" in data) {
    if (data?.village !== "") {
      formattedBaseLocation += `${data?.village}, `;
    }
  }
  if ("city" in data) {
    if (data?.city !== "") {
      formattedBaseLocation += `${data?.city}, `;
    }
  }
  if (data?.district !== "") {
    formattedBaseLocation += `${data?.district}, `;
  }
  if (data?.state !== "") {
    formattedBaseLocation += `${data?.state}, `;
  }
  if (data?.country !== "") {
    formattedBaseLocation += `${data?.country} `;
  }
  return formattedBaseLocation;
};

const getVerifiedRole = (roles: any) => {
  let roleString = "";
  roles?.forEach((role: any, index: number) => {
    roleString += `${role.roleTitle} (till ${dataHelper.formatDate(
      role.roleEndDate,
      "DD-MMM-YYYY"
    )}) ${index < roles.length - 1 ? ", " : ""} `;
  });
  return roleString;
};

const getVerifiedQualification = (qualifications: any) => {
  let qualificationString = "";
  qualifications?.forEach((qualification: any, index: number) => {
    qualificationString += `${qualification} ${index < qualifications.length - 1 ? ", " : ""} `;
  });
  return qualificationString;
};

const getAssignedBadges = (badges: any) => {
  let badgesString = "";
  badges?.forEach((badge: any, index: number) => {
    badgesString += `${badge.badgeName} (till ${dataHelper.formatDate(
      badge.endDate,
      "DD-MMM-YYYY"
    )}) ${index < badges.length - 1 ? ", " : ""} `;
  });
  return badgesString;
};

const getAssignedBadgesString = (badges: any) => {
  let badgesString = "";
  badges?.forEach((badge: any, index: number) => {
    badgesString += `${badge.badgeName}${index < badges.length - 1 ? ", " : ""} `;
  });
  return badgesString;
};

const getTopics = (topics: any) => {
  let topicsString = "";
  topics?.forEach((topic: any, index: number) => {
    topicsString += `${topic.name} ${index < topics.length - 1 ? " & " : ""} `;
  });
  return topicsString;
};

const handleDownload = (data: any, programName: string) => {
  const blob = new Blob([data], { type: "text/csv" });
  let link = document.createElement("a");
  link.href = window.URL.createObjectURL(blob);
  link.download = `${programName} ${dataHelper.formatDate(
    new Date().toISOString(),
    "YYYY-MM-DD HH:mm:ss"
  )}.csv`;
  link.click();
};

const capitalizeFirstLetter = (userString: string) => {
  let string = userString.toLowerCase();
  return string.charAt(0).toUpperCase() + string.slice(1);
};

const updateTelemetryEvent = async (eventType: string) => {
  const programDetails: any = await LocalStorage.getStorage("programDetails");
  const user: any = await LocalStorage.getStorage("user");
  const body: any = {
    timestamp: Moment().toLocaleString(),
    createdAt: Moment().toLocaleString(),
    deleted: "false",
    updatedAt: Moment().toLocaleString(),
    eventType: eventType,
    entityId: null,
    entityName: null
  };
  AddTelemetryService(body, programDetails, user, false);
};

const updateTopicParticipantTelemetryEvent = async (
  eventType: string,
  topicId: any,
  topicname: any
) => {
  const programDetails: any = await LocalStorage.getStorage("programDetails");
  const user: any = await LocalStorage.getStorage("user");
  const body: any = {
    timestamp: Moment().toLocaleString(),
    createdAt: Moment().toLocaleString(),
    deleted: "false",
    updatedAt: Moment().toLocaleString(),
    eventType: eventType,
    entityId: null,
    entityName: null,
    topicId: topicId,
    topicName: topicname
  };
  AddTelemetryService(body, programDetails, user, false);
};

const updateSessionParticipantTelemetryEvent = async (eventType: string, session: any) => {
  const programDetails: any = await LocalStorage.getStorage("programDetails");
  const user: any = await LocalStorage.getStorage("user");
  const body: any = {
    timestamp: Moment().toLocaleString(),
    createdAt: Moment().toLocaleString(),
    deleted: "false",
    updatedAt: Moment().toLocaleString(),
    eventType: eventType,
    entityId: null,
    entityName: null,
    topicId: session.topicId,
    topicName: session.topicName,
    sessionId: session.sessionId,
    sessionName: session.sessionName,
    sessionStartDate: session.sessionStartDate,
    sessionEndDate: session.sessionEndDate
  };
  AddTelemetryService(body, programDetails, user, false);
};

const handleValidatePhoneNumber = (mobileNumber: any, minLength: number, maxLength: number) => {
  const regex = /^(0*[1-9][0-9]*(\.[0-9]*)?|0*\.[0-9]*[1-9][0-9]*)$/;
  let customError = "";
  if (mobileNumber) {
    if (!regex.test(mobileNumber) && mobileNumber) {
      customError = "Invalid characters";
    } else if (minLength == maxLength && (mobileNumber?.length > 10 || mobileNumber?.length < 10))
      customError = "The phone number should be of 10 digits";
    else if (minLength !== maxLength && mobileNumber?.length > 20)
      customError = `The phone number should be between ${minLength} to ${maxLength} digits.`;
  }
  return customError;
};

const flattenObjectHandler = (obj: any) => {
  const flattened: any = {};
  Object.keys(obj).forEach((key) => {
    if (typeof obj[key] === "object" && obj[key] !== null) {
      Object.assign(flattened, flattenObjectHandler(obj[key]));
    } else {
      flattened[key] = obj[key];
    }
  });
  return flattened;
};

const getFileNameFromUrl = (url: string) => {
  let name = url.split("/");
  return name[name.length - 1];
};

const isUrlValid = (link: string) => {
  const reg_pattern = /(http(s)?:\\)?([\w-]+\.)+[\w-]+[.com|.in|.org]+(\[\?%&=]*)?/;
  if (reg_pattern.test(link)) {
    return false;
  } else {
    return true;
  }
};

const getLocalTimezone = () => {
  const get_name = moment.tz.guess();
  const timezone_abb = "(" + moment.tz(get_name).zoneAbbr() + ")";
  const timezone = new Date().toString().match(/([A-Z]+[\+-][0-9]+)/)[0];
  const local_timezone = [timezone, timezone_abb].join(" ");
  return local_timezone;
};

const getLocalTimezoneName = (timezone_offset: any, timezone_name: any) => {
  const timezone_abb = "(" + moment.tz(timezone_name).zoneAbbr() + ")";
  let offset = timezone_offset.split(":");
  const newOffset = offset.join("");
  const local_timezone = [newOffset, timezone_abb].join(" ");
  return local_timezone;
};

const fromatEntityLocation = (data: any) => {
  let formattedBaseLocation: string = "";

  if ("city" in data) {
    if (data?.city !== "") {
      formattedBaseLocation += `${data?.city}, `;
    }
  }

  if (data?.state !== "") {
    formattedBaseLocation += `${data?.state}, `;
  }
  if ("district" in data) {
    if (data?.district !== "") {
      formattedBaseLocation += `${data?.district}, `;
    }
  }

  if (data?.country !== "") {
    formattedBaseLocation += `${data?.country}, `;
  }

  if (data?.pin_code !== "") {
    formattedBaseLocation += `${data?.pin_code} `;
  }
  return formattedBaseLocation;
};

const scrollToTopHandler = () => {
  window.scrollTo(0, 0);
};

const getTimeZone = () => {
  let newData = new Date().toTimeString().split("(");
  let tempArray = newData[newData.length - 1].split(" ");
  let arr: any = [];
  tempArray.map((i) => {
    i.split("");
    arr.push(i[0]);
  });
  return `${newData[0].slice(9)}(${arr.join("")})`;
};

const getUserFirstLocationName = (data: any) => {
  let userLocation = "";
  if (data?.city !== "") {
    userLocation = data?.city;
    return userLocation;
  }
  if (data?.subDistrict !== "") {
    userLocation = data?.subDistrict;
    return userLocation;
  }
  if (data?.district !== "") {
    userLocation = data?.district;
    return userLocation;
  }
  if (data?.state !== "") {
    userLocation = data?.state;
    return userLocation;
  }
  if (data?.country !== "") {
    userLocation = data?.country;
    return userLocation;
  }
  return "None";
};

export const auxiliaryMethods = {
  fromatBaseLocation,
  getVerifiedRole,
  getVerifiedQualification,
  getAssignedBadges,
  getAssignedBadgesString,
  getTopics,
  handleDownload,
  capitalizeFirstLetter,
  updateTelemetryEvent,
  handleValidatePhoneNumber,
  flattenObjectHandler,
  getFileNameFromUrl,
  isUrlValid,
  getLocalTimezone,
  updateTopicParticipantTelemetryEvent,
  updateSessionParticipantTelemetryEvent,
  fromatBaseLocationSecondary,
  fromatBaseTaskLocation,
  fromatBaseTaskSubmissionLocation,
  fromatEntityLocation,
  scrollToTopHandler,
  getTimeZone,
  getUserFirstLocationName,
  getLocalTimezoneName
};
