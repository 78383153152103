import React, { ReactElement, useEffect, useState } from "react";
import { StyleSheet, View } from "react-native";
import { colorPallete } from "@socion-cordio/common/src/assets/styles/colors";
import {
  Text,
  TextSize,
  FontWeight,
  FontFamily
} from "@socion-cordio/common/src/components/atoms/text";
import { ApiClient } from "@socion-cordio/common/src/network/apiClient";
import { qualificationPackEndPoints } from "@socion-cordio/common/src/repositories/endPoints";
import { toast, ToastContainer } from "react-toastify";
import Loader from "@socion-cordio/common/src/components/atoms/loader";
import Moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { QualificationPackActions } from "@socion-cordio/common/src/modules/qualificationPacks/actions/actions";

interface Props {
  qualiPackDetails?: any;
  getTopicsData?: Function;
}

export default function QualiViewDetailsSidePanelPage(props: Props): ReactElement {
  const { qualiPackDetails, getTopicsData } = props;
  // const [loading, setLoading] = useState(true);
  const [failed, setFailed] = useState(false);
  const [bundleData, setBundleData] = useState(null);
  const [selectionList, setSelectionList] = useState([]);
  const dispatch = useDispatch();
  const {
    bundleData: bundles,
    loading: { getBundle: loading }
  } = useSelector((state: any) => state.bundleData);

  useEffect(() => {
    // getQualiPackDetails();
  }, [qualiPackDetails]);

  useEffect(() => {
    const bundleId = qualiPackDetails?.bundle?.id;
    dispatch(QualificationPackActions.getBundle(bundleId));
  }, []);

  useEffect(() => {
    getQualiPackDetails();
  }, [bundles]);

  const getQualiPackDetails = () => {
    // if(bundles!==null || bundles!==undefined){
    setBundleData(bundles?.response);
    getTopicsData(bundles?.response);
    const bundleList: any[] = bundles?.response?.bundleTopiMapping;
    let list: any[] = bundleList?.map(({ selectionId, topicName }) => ({
      selectionId,
      topicName
    }));
    formatSelectionList(list);
    // }
  };

  const formatSelectionList = (list: any[]) => {
    if (list !== null && list !== undefined) {
      const output = Object.values(
        list?.reduce((acc, cur) => {
          acc[cur.selectionId] = acc[cur.selectionId] || {
            selectionId: cur.selectionId,
            topics: []
          };
          acc[cur.selectionId].topics.push({ topicName: cur.topicName });
          return acc;
        }, {})
      );
      setSelectionList(output);
    }
  };

  const formatDate = (date: Date) => {
    const formattedDate = Moment(date).format("DD MMM YYYY");
    return formattedDate;
  };

  return (
    <View>
      {loading ? (
        <View style={styles.loaderContainer}>
          <View style={styles.subLoaderContainer}>
            <Loader />
          </View>
        </View>
      ) : failed ? (
        <View style={styles.noFailedDataContainer}>Failed to load data</View>
      ) : (
        <View style={styles.container}>
          <View style={styles.subContainer}>
            <View style={styles.alignContainer}>
              <Text
                fontWeight={FontWeight.Light}
                testId="text"
                textSize={TextSize.Small}
                textStyle={[styles.headerText, styles.headerTextTitleSupport]}
              >
                {bundleData?.bundle?.name}
              </Text>
            </View>

            <View style={(styles.badgeHeaderAlignmnet, styles.subHeaderAlignment)}>
              <View style={styles.dateCreatedContainer}>
                <Text
                  fontWeight={FontWeight.Light}
                  testId="addRoleText"
                  textSize={TextSize.Small}
                  textStyle={[
                    styles.headerText,
                    styles.headerAlignment,
                    styles.fontSizeText,
                    { marginRight: 10 }
                  ]}
                >
                  Created on
                </Text>
                <Text
                  fontWeight={FontWeight.Light}
                  testId="addRoleText"
                  textSize={TextSize.Small}
                  textStyle={[styles.headerText, styles.fontSizeText, styles.colorText]}
                >
                  {formatDate(bundleData?.bundle?.createdAt)}
                </Text>
              </View>
            </View>

            <View>
              <View style={styles.badgeHeaderAlignmnet}>
                <Text
                  fontWeight={FontWeight.Light}
                  testId="addRoleText"
                  textSize={TextSize.Small}
                  textStyle={[styles.headerText, styles.headerAlignment]}
                >
                  Description
                </Text>
              </View>
              <View style={styles.badgeHeaderAlignment}>
                <Text
                  fontWeight={FontWeight.Light}
                  testId="addRoleText"
                  textSize={TextSize.Small}
                  textStyle={[styles.headerText, styles.colorText]}
                >
                  {bundleData?.bundle?.description}
                </Text>
              </View>
            </View>

            {selectionList.map((prop: any, key: any) => (
              <View key={key} style={styles.selectionListContainer}>
                <View style={styles.badgeHeaderAlignmnet}>
                  <Text
                    fontWeight={FontWeight.Light}
                    testId="addRoleText"
                    textSize={TextSize.Small}
                    textStyle={[styles.headerText, styles.headerAlignment]}
                  >
                    {"Selection " + prop.selectionId}
                  </Text>
                </View>
                {prop.topics.map((props: any, key: any) => (
                  <View key={key} style={styles.badgeHeaderAlignment}>
                    <Text
                      fontWeight={FontWeight.Light}
                      testId="addRoleText"
                      textSize={TextSize.Small}
                      textStyle={[styles.headerText, styles.colorText]}
                    >
                      {props.topicName}
                    </Text>
                  </View>
                ))}
              </View>
            ))}
          </View>
        </View>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    padding: 20
  },
  subContainer: {},
  alignContainer: {
    marginBottom: 5
  },
  headerText: {
    fontSize: 12,
    fontFamily: FontFamily.Medium,
    lineHeight: 17
  },
  headerTextTitleSupport: {
    fontWeight: "700",
    fontSize: 14,
    lineHeight: 20,
    color: colorPallete.white
  },
  badgeHeaderAlignmnet: {
    marginBottom: 5,
    flex: 20
  },
  subHeaderAlignment: {
    marginBottom: 25
  },
  dateCreatedContainer: { flexDirection: "row" },
  badgeHeaderAlignment: {
    marginBottom: 10
  },
  headerAlignment: {
    color: colorPallete.textLight,
    fontFamily: FontFamily.Regular
  },
  fontSizeText: {
    fontSize: 12
  },
  colorText: {
    color: colorPallete.white,
    fontFamily: FontFamily.Regular
  },
  noFailedDataContainer: {
    padding: 25
  },
  loaderContainer: {
    padding: 20
  },
  subLoaderContainer: {},
  selectionListContainer: {
    marginBottom: 5
  }
});
