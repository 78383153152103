import React, { ReactElement, useEffect, useState } from "react";
import { StyleSheet, View } from "react-native";
import {
  Text,
  TextSize,
  FontWeight,
  FontFamily
} from "@socion-cordio/common/src/components/atoms/text";
import Icon, { IconNames } from "@socion-cordio/common/src/components/atoms/icon";
import Button, { ButtonType } from "@socion-cordio/common/src/components/atoms/button";
import UserInputSecondary from "@socion-cordio/common/src/components/molecules/userInputSecondary";
import UserInputTextArea from "@socion-cordio/common/src/components/molecules/userInputTextArea";
import { colorPallete } from "@socion-cordio/common/src/assets/styles/colors";
import { Switch } from "@socion-cordio/common/src/components/atoms/switches";
import { ApiClient } from "@socion-cordio/common/src/network/apiClient";
import { topicEndPoints } from "@socion-cordio/common/src/repositories/endPoints";
import TopicDetailsModal from "@socion-cordio/common/src/components/organisms/topic/topicDetailsModal";
import { toast, ToastContainer } from "react-toastify";
import { HTTP_STATUS_CODES } from "@socion-cordio/common/src/network/constants";
import AddTelemetryService from "@socion-cordio/common/src/services/telemetryService";
import { LocalStorage } from "@socion-cordio/common/src/services/storage/storageService";
import Moment from "moment";

interface Props {
  onSubmit?: (data: any) => void;
  onClose?: Function;
  setNewAddedTopic?: any;
}

export default function AddTopicModal(props: Props): ReactElement {
  const { setNewAddedTopic } = props;
  const [isInfoEnabled, setIsnfoEnabled] = useState<boolean>(false);
  const [topicName, setTopicName] = useState("");
  const [topicDescription, setTopicDescription] = useState("");
  const [viewTopicDetails, setViewTopicDetails] = useState(false);
  const [selectedTopic, setSelectedTopic] = useState(null);
  const [programData, setProgramData] = useState(undefined);
  const [topicLibraryEnabled, setTopicLibraryEnabled] = useState(false);
  const programDetails: any = JSON.parse(localStorage.getItem("programDetails"));
  const userDetails: any = JSON.parse(localStorage.getItem("user"));
  // const location: any = useLocation();
  // const dispatch = useDispatch();

  useEffect(() => {
    getProgramDetails();
  }, []);

  const getProgramDetails = async () => {
    const programDetails: any = await LocalStorage.getStorage("programDetails");
    setProgramData(programDetails);
    // console.log(programDetails.program.topicLibraryEnabled);
    setTopicLibraryEnabled(programDetails.program.topicLibraryEnabled);
  };

  const submitHandler = async () => {
    try {
      const addTopicBody = {
        name: topicName,
        description: topicDescription,
        programId: programDetails.program.id,
        entityId: programDetails.program.entity.registryEntityId,
        userName: userDetails.name,
        publishedForTopicLibrary: isInfoEnabled
      };
      const addTopicResponse = await ApiClient.post(topicEndPoints.addTopicDetails(), addTopicBody);
      // if(addTopicResponse.status===HTTP_STATUS_CODES.unauthorized){
      //   toast.error(addTopicResponse?.message);
      // }
      if (addTopicResponse !== undefined || addTopicResponse !== null) {
        setNewAddedTopic(addTopicResponse);
        updateTelemetry(addTopicResponse);
      }
    } catch (error) {
      toast.error("Something went wrong");
    }
  };

  const updateTelemetry = async (data: any) => {
    const body = {
      // created_at: programDetails.program.createdAt, // ask which one
      createdAt: data.createdAt, // topic ID
      deleted: "false",
      updatedAt: data.updatedAt,
      eventType: "Topic Created",
      topicName: data.name,
      topicId: data.id,
      timestamp: Moment().toLocaleString()
    };
    AddTelemetryService(body, programDetails, userDetails);
  };

  return (
    <View>
      <View style={styles.container}>
        <View style={styles.header}>
          <Text
            fontWeight={FontWeight.Bold}
            testId="addRoleText"
            textSize={TextSize.Small}
            textStyle={styles.headerText}
          >
            Add Topic
          </Text>
          <View>
            <Icon
              testID="close"
              name={IconNames.crossCircle}
              customStyle={styles.crossCircle}
              onPress={() => props.onClose()}
            />
          </View>
        </View>
        <View style={styles.alignContainer}>
          <View style={styles.userInputContainer}>
            <UserInputSecondary
              textValue="Topic name"
              handleChange={(topicName: string) => {
                setTopicName(topicName);
              }}
              handleBlur={() => {}}
              value={topicName}
              // inputStyle={[styles.inputStyling, styles.inputStylingAlignment]}
              placeholder="Enter name"
              name="enterTopicName"
              id="enterTopicName"
              noFormik
              userStyle={styles.inputStylingAlignment}
              userStyleText={styles.textHeaderField}
              textInputContainerStyles={{ marginLeft: 15 }}
              // userStyle={styles.alignTextFieldWidth}
              // userStyleText={styles.alignTextWidth}
            />
          </View>
        </View>

        <View style={styles.alignContainer}>
          <UserInputTextArea
            textValue="Description"
            label="Description"
            placeholder="Description"
            name="Description"
            id="description"
            handleChange={(description: string) => {
              setTopicDescription(description);
            }}
            handleBlur={() => {}}
            value={topicDescription}
            numberOfLines={5}
            multiline={true}
            noFormik
            userStyle={styles.inputStylingAlignment}
            userStyleText={styles.textHeaderField}
          />
        </View>

        {topicLibraryEnabled && (
          <View style={[styles.alignContainer, styles.alignContent]}>
            <View style={styles.alignInnerContent}>
              <Text
                fontWeight={FontWeight.Light}
                testId="addRoleText"
                textSize={TextSize.Small}
                textStyle={[styles.headerText, styles.headerAlignment]}
              >
                Add to topic library
              </Text>

              <Icon testID="edit" name={IconNames.about} customStyle={[styles.alignButton]} />
            </View>

            <View style={styles.radioButtonContainer}>
              <Switch
                isOn={isInfoEnabled}
                onColor="#B7504A"
                offColor="#F1DCDB"
                labelStyle={{ color: "black", fontWeight: "900" }}
                size="medium"
                onToggle={() => setIsnfoEnabled((isInfoEnabled: boolean) => !isInfoEnabled)}
              />
            </View>
          </View>
        )}

        <View style={[styles.alignButtonContainer]}>
          <Button
            type={ButtonType.Primary}
            buttonStyles={styles.submitbutton}
            textStyles={styles.submitButtonText}
            title="Add topic"
            onPress={() => submitHandler()}
            disabled={topicName === "" || topicDescription === ""}
          />
        </View>
      </View>
      <View>
        {viewTopicDetails && (
          <TopicDetailsModal
            topicId={selectedTopic}
            modalVisible={viewTopicDetails}
            setModalVisible={() => setViewTopicDetails(!viewTopicDetails)}
          />
        )}
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    width: 655
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row"
  },
  headerText: {
    fontSize: 12,
    fontFamily: FontFamily.Medium,
    lineHeight: 17
  },
  headerAlignment: {
    color: colorPallete.textLight,
    fontFamily: FontFamily.Regular
  },
  crossCircle: {
    fontSize: 15
  },
  alignContainer: {
    marginTop: 15
  },
  userInputContainer: {},
  textHeaderField: {
    flex: 15,
    fontSize: 12
  },
  inputStylingAlignment: {
    width: "100%",
    fontSize: 12
  },
  alignTextWidth: {
    flex: 15
  },
  alignContent: {
    flexDirection: "row",
    alignItems: "center",
    marginTop: 20
  },
  alignInnerContent: {
    flexDirection: "row",
    flex: 30
  },
  alignButtonContainer: {
    marginTop: 30,
    marginBottom: 30,
    justifyContent: "center",
    alignSelf: "center"
  },
  submitbutton: {
    width: "140px",
    height: "50px",
    borderRadius: 10
  },
  submitButtonText: {
    fontSize: 12
  },
  alignButton: {
    fontSize: 15,
    color: colorPallete.cordioTaupe,
    marginLeft: 15
  },
  radioButtonContainer: {
    flex: 70
  }
});
