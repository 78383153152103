import { colorPallete } from "@socion-cordio/common/src/assets/styles/colors";
import React, { ReactElement } from "react";
import { useField } from "formik";
import { FontWeight, Label, TextSize, Text } from "@socion-cordio/common/src/components/atoms/text";
import OTPTextInput from "react-native-otp-textinput";
import { TextInput, View, StyleSheet, StyleProp, TextStyle, ViewStyle } from "react-native";

interface IProps {
  testId?: string;
  handleTextChange?: Function;
  label?: string;
  placeholder?: string;
  name: string;
  styling?: Object;
  id: string;
  isSecure?: boolean;
  disabled?: boolean;
  value?: any;
  search?: boolean;
  focusStyle?: {};
  toggler?: boolean;
  manageFocus?: (arg: {}) => void;
  handleChange?: Function;
  handleBlur?: Function;
  handleKeyPress?: Function;
  labelStyle?: StyleProp<TextStyle>;
  errorStyle?: StyleProp<TextStyle>;
  inputStyle?: StyleProp<TextStyle>;
  fieldStyle?: StyleProp<ViewStyle>;
  customChange?: Function;
  noFormik?: boolean;
  formikPropsTouched?: boolean;
}
export const Otp = (props: IProps): ReactElement => {
  const { testId, handleTextChange, errorStyle, id, formikPropsTouched } = props;
  let field, meta, hasError, error;
  if (!props.noFormik) {
    [field, meta] = useField(props);
    hasError = meta.touched && meta.error;
    error = meta.error;
  }
  return (
    <View style={styles.container} testID={`chk${testId}`}>
      <OTPTextInput
        inputCount={6}
        handleTextChange={(e: any) => {
          if (props?.customChange) {
            props?.customChange(e);
          }
          return handleTextChange(e);
        }}
        // containerStyle={styles.otp}
        textInputStyle={styles.text}
        tintColor={colorPallete.cordioRed}
      ></OTPTextInput>
      {error && (formikPropsTouched === undefined ? true : formikPropsTouched) ? (
        <Text
          testId={testId}
          textSize={TextSize.Small}
          fontWeight={FontWeight.Regular}
          textStyle={[styles.error, errorStyle]}
        >
          {(formikPropsTouched === undefined ? true : formikPropsTouched) && error}
        </Text>
      ) : null}
    </View>
  );
};
const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    margin: 0
  },
  text: {
    borderColor: colorPallete.cordioRed,
    borderRadius: 10,
    borderWidth: 1,
    borderBottomWidth: 1,
    tintColor: "red",
    textDecorationStyle: "none",
    height: 40,
    width: 40
    //opacity: 0.4
  },
  error: {
    position: "relative",
    color: "red",
    alignSelf: "flex-start",
    fontSize: 10,
    fontWeight: "400",
    zIndex: -1
  }
});
