import { Login } from "@socion-cordio/common/src/models/login";
import { ApiClient } from "@socion-cordio/common/src/network/apiClient";
import { forgotPasswordEndPoints } from "@socion-cordio/common/src/repositories/endPoints";
import {
  ForgotPasswordUser,
  UpdateForgotPasswordUser
} from "@socion-cordio/common/src/models/forgotPassword";

class ForgotPasswordRepository {
  requestForgotPasswordOtp = async (body: Object): Promise<ForgotPasswordUser> => {
    let response = await ApiClient.post(forgotPasswordEndPoints.requestForgotOtp(), body);
    return response;
  };

  validateLoginUserOtp = async (body: Object): Promise<ForgotPasswordUser> => {
    let response = await ApiClient.post(forgotPasswordEndPoints.validateForgotPasswordOtp(), body);
    return response;
  };

  updateForgotPassword = async (body: Object): Promise<UpdateForgotPasswordUser> => {
    let response = await ApiClient.post(forgotPasswordEndPoints.forgotPasswordUpdate(), body);
    return response;
  };
}
const forgotPasswordRepository = new ForgotPasswordRepository();
export { forgotPasswordRepository as ForgotPasswordRepository };
