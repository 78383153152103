import React, { forwardRef, ReactElement, useEffect, useImperativeHandle, useState } from "react";
import { View, StyleSheet, ScrollView } from "react-native";
import {
  Text,
  TextSize,
  FontWeight,
  FontFamily
} from "@socion-cordio/common/src/components/atoms/text";
import Loader from "@socion-cordio/common/src/components/atoms/loader";
import { Checkbox, CheckboxType } from "@socion-cordio/common/src/components/atoms/checkBox";
import { dataHelper } from "@socion-cordio/common/src/utils/dataHelper";
import { useTranslation } from "react-i18next";
import SearchFilterSeondary from "@socion-cordio/common/src/components/molecules/searchFilterSecondary";
import { LocalStorage } from "@socion-cordio/common/src/services/storage/storageService";
import Button, { ButtonType } from "@socion-cordio/common/src/components/atoms/button";

interface Props {
  programDetails: any;
  badgeRoleList: any[];
  getSelectedRoleBadgeList: Function;
  dataFilteredHandler: Function;
  setModalVisible: any;
  setIsBadgeFilterApplied: Function;
  updatedBadgesList: Function;
}

const FilterBadgeRolePage = forwardRef((props: Props, ref): ReactElement => {
  const { t } = useTranslation();
  const {
    programDetails,
    badgeRoleList,
    getSelectedRoleBadgeList,
    dataFilteredHandler,
    setModalVisible,
    setIsBadgeFilterApplied,
    updatedBadgesList
  } = props;
  const [selectedList, setSelectedList] = useState([]);
  const [badgeRoleListsArray, setBadgeRoleListsArray] = useState([]);
  const [noBadgesSelect, setNoBadgesSelect] = useState(false);

  useEffect(() => {
    setBadgeRoleListsArray(badgeRoleList);
  }, [badgeRoleList]);

  const selectedCheckBoxHandler = (data: any) => {
    data.isSelected = !data.isSelected;
    checkSelectedList(data);
  };

  useEffect(() => {
    checkNoAssignedBagedHandler();
  }, []);

  const checkNoAssignedBagedHandler = async () => {
    const check = await LocalStorage.getStorage("noAssignedBadgesSelected");
    check ? setNoBadgesSelect(true) : setNoBadgesSelect(false);
  };

  const checkSelectedList = (data: any) => {
    setNoBadgesSelect(false);
    removeNoAssignedBadgesLocalStorage();
    let list: any = [...selectedList];
    let isPresent = selectedList.filter((x: any) => x.serialNo === data.serialNo);
    if (isPresent.length !== 0) {
      let removeList = selectedList.filter((x) => x.serialNo !== data.serialNo);
      setSelectedList(removeList);
      getSelectedRoleBadgeList(badgeRoleList);
      // updatedBadgesList(badgeRoleList);
      // filteredLists(badgeRoleList);
    } else {
      list.push(data);
      setSelectedList(list);
      // filteredLists(badgeRoleList);
      getSelectedRoleBadgeList(badgeRoleList);
      // updatedBadgesList(badgeRoleList);
    }
  };

  const filteredLists = async (list: any) => {
    // let newList = list.filter((x: any) => x.isSelected === true);
    // // setSelectedBadgeRoleList(list);
    // // setBadgeRoleListButton(newList);
    // let result = newList.map((a: any) => a.badgeId);
    // console.log("result;::::", result);
    // const body = {
    //   disableLocationFilter: "False",
    //   disableBadgeFilter: "False",
    //   allUsers: "False",
    //   badgeId: result,
    //   // locations: commonData.locations
    // };
    // const response = await ApiClient.post(
    //   qualificationPackEndPoints.getQualificationParticipantMapping(1271, 2),
    //   body
    // );
    // console.log("respon::",response);
  };

  const filterList = (value: any) => {
    let str = value.toLowerCase();
    let newList: any[] = [];
    badgeRoleList
      .filter((item) => item.name.toLowerCase().includes(str))
      .map((name) => {
        return newList.push(name);
      });
    setBadgeRoleListsArray(newList);
  };

  const noBadgesSelectHandler = () => {
    let badgeList = [...badgeRoleListsArray];
    let list: any = badgeList.forEach((item: any) => {
      item.isSelected = false;
    });
    LocalStorage.setStorage("noAssignedBadgesSelected", true);
    // setBadgeRoleListsArray(list);
    getSelectedRoleBadgeList([]);
  };

  const applyFilter = () => {
    noBadgesSelect
      ? dataFilteredHandler({ filtered: true, noAssignedBadges: true })
      : dataFilteredHandler({ filtered: true, noAssignedBadges: false });
    setModalVisible();
    setIsBadgeFilterApplied(true);
  };

  const clearFilter = () => {
    let badgeList = [...badgeRoleListsArray];
    let list: any = badgeList.forEach((item: any) => {
      item.isSelected = false;
    });
    // console.log("list::", list);
    removeNoAssignedBadgesLocalStorage();
    // setBadgeRoleListsArray([]);
    // setModalVisible();
  };

  const removeNoAssignedBadgesLocalStorage = () => {
    setNoBadgesSelect(false);
    LocalStorage.removeStorage("noAssignedBadgesSelected");
  };

  return (
    <View style={styles.mainContainer}>
      {badgeRoleList === null || badgeRoleList === undefined ? (
        <Loader customLoadingContainer={styles.customLoadingContainer} />
      ) : dataHelper.isEmptyArray(badgeRoleList) ? (
        <View style={styles.noDataContainer}>
          <Text
            fontWeight={FontWeight.Thin}
            testId="headerText"
            textSize={TextSize.Small}
            textStyle={styles.headerText}
          >
            {t("common:noData")}
          </Text>
        </View>
      ) : (
        <View>
          <View style={styles.searchContainer}>
            <SearchFilterSeondary filterList={filterList} />
          </View>
          <View>
            <View style={styles.locationListContainer}>
              <ScrollView>
                {badgeRoleListsArray?.map((prop, key) => {
                  return (
                    <View key={key}>
                      <View style={styles.checkBoxContainer}>
                        <Checkbox
                          testId={"checkBox"}
                          onPress={() => selectedCheckBoxHandler(prop)}
                          selected={prop.isSelected}
                          type={CheckboxType.Square}
                          text={prop.name}
                          textSize={TextSize.Small}
                          textFontWeight={FontWeight.Regular}
                          textStyle={styles.textStyleCheckBox}
                          iconSize={16}
                        />
                      </View>
                    </View>
                  );
                })}
                <View>
                  <Checkbox
                    testId={"checkBox"}
                    onPress={() => [noBadgesSelectHandler(), setNoBadgesSelect(!noBadgesSelect)]}
                    selected={noBadgesSelect}
                    type={CheckboxType.Square}
                    text={"No Assigned Badges"}
                    textSize={TextSize.Small}
                    textFontWeight={FontWeight.Regular}
                    textStyle={styles.textStyleCheckBox}
                    iconSize={16}
                  />
                </View>
              </ScrollView>
            </View>
            <View style={[styles.header]}>
              <View style={[styles.buttonContainer, styles.alignClearButton]}>
                <Button
                  type={ButtonType.Secondary}
                  buttonStyles={styles.button}
                  textStyles={styles.buttonFont}
                  title="Clear"
                  onPress={() => clearFilter()}
                />
              </View>
              {/* <View style={styles.buttonContainer}>
                <Button
                  type={ButtonType.Primary}
                  buttonStyles={styles.button}
                  textStyles={styles.buttonFont}
                  title="Apply"
                  onPress={() => [applyFilter()]}
                />
              </View>  */}
            </View>
          </View>
        </View>
      )}
    </View>
  );
});

export default FilterBadgeRolePage;

const styles = StyleSheet.create({
  mainContainer: {},
  searchContainer: {
    marginBottom: 15
  },

  // Loader
  customLoadingContainer: {
    height: 300
  },

  // Check Box LocationList
  locationListContainer: {
    height: 300
  },
  checkBoxContainer: {
    marginBottom: 8
  },
  textStyleCheckBox: {
    marginStart: 15
  },

  // No Data Container
  noDataContainer: {
    height: 200
  },
  headerText: {
    fontSize: 12,
    fontFamily: FontFamily.Medium,
    lineHeight: 17,
    fontWeight: "700"
    // marginBottom: 5
  },

  header: {
    display: "flex",
    justifyContent: "flex-end",
    flexDirection: "row"
  },
  buttonContainer: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center"
  },
  alignClearButton: {
    marginRight: 110
  },
  button: {
    width: 100,
    height: 40,
    borderRadius: 10
  },
  buttonFont: {
    fontSize: 12
  }
});
