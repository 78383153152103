import { deserialize, serialize } from "@socion-cordio/common/src/mappers/apiResMapper";
import { Program } from "@socion-cordio/common/src/models/program";
import { EntityInvite } from "@socion-cordio/common/src/models/entityInvite";
import { ApiClient } from "@socion-cordio/common/src/network/apiClient";
import {
  springshedEndPoints,
  taskEndPoints
} from "@socion-cordio/common/src/repositories/endPoints";

class ProgramRepository {
  getPrograms = async (): Promise<Program> => {
    let data = await ApiClient.get(taskEndPoints.getTaskPrograms());
    // const programsData: Program = deserialize(Program, data);
    return data;
  };

  getEntityInvite = async (): Promise<EntityInvite> => {
    let data = await ApiClient.get(springshedEndPoints.inviteEntity());
    // if (data.responseCode === 200) {
    //   data = deserialize(EntityInvite, data);
    //   return data;
    // }
    return data;
  };
}
const programRepository = new ProgramRepository();
export { programRepository as ProgramRepository };
