import { ActionHelper } from "@socion-cordio/common/src/utils/actionHelper";

const prefix = "programs";
const PROGRAMS = {
  GET_PROGRAMS: ActionHelper.actionTypesCreator(prefix, "GET_PROGRAMS"),
  GET_ENTITY_INVITE: ActionHelper.actionTypesCreator(prefix, "GET_ENTITY_INVITE")
};
const SAVE_PROGRAM_DETAILS = "SAVE_PROGRAM_DETAILS";

export { PROGRAMS, SAVE_PROGRAM_DETAILS };
