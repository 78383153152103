import React, { ReactElement, useState, useEffect } from "react";
import { StyleSheet, View } from "react-native";
import { colorPallete } from "@socion-cordio/common/src/assets/styles/colors";
import {
  Text,
  TextSize,
  FontWeight,
  FontFamily
} from "@socion-cordio/common/src/components/atoms/text";
import { ApiClient } from "@socion-cordio/common/src/network/apiClient";
import { Image } from "@socion-cordio/common/src/components/atoms/image";
import Icon, { IconNames } from "@socion-cordio/common/src/components/atoms/icon";
import { auxiliaryMethods } from "@socion-cordio/common/src/utils/auxiliaryMethods";
import { toast, ToastContainer } from "react-toastify";
import Loader from "@socion-cordio/common/src/components/atoms/loader";
import { profileEndPoints } from "@socion-cordio/common/src/repositories/endPoints";

interface Props {
  onClose?: Function;
  userDetails?: any;
  setModalLoading?: Function;
  piiFilter: boolean;
  selectedUserDetails: any;
}

export default function ViewUserDetails(props: Props): ReactElement {
  const { piiFilter, onClose, selectedUserDetails } = props;
  const [loading, setLoading] = useState(true);
  const [failed, setFailed] = useState(false);
  const [userDetails, setUserDetails] = useState(null);

  useEffect(() => {
    getUserDetails();
  }, []);

  const getUserDetails = async () => {
    try {
      const { userId } = selectedUserDetails;
      const params = {
        piiFilter: piiFilter
      };
      const response = await ApiClient.get(profileEndPoints.getSelectedUserDetails(userId), params);
      setUserDetails(response?.registryUserWithOsId);
      setLoading(false);
    } catch (error) {
      toast.error("Something went wrong.");
      setLoading(false);
      setFailed(true);
    }
  };

  return (
    <>
      {/* <ToastContainer /> */}
      {loading ? (
        <Loader customLoadingContainer={styles.customLoadingContainer} />
      ) : failed ? (
        <View style={styles.failedContainer}>Failed to load</View>
      ) : (
        <View>
          <View style={styles.header}>
            <View>
              <Icon
                testID="close"
                name={IconNames.crossCircle}
                customStyle={styles.crossIcon}
                onPress={() => onClose()}
              />
            </View>
          </View>
          <View style={styles.subContainer}>
            <View style={styles.profilePictureContainer}>
              <View style={styles.profilePicture}>
                <Image
                  imageStyle={styles.profilePicture}
                  testId="socionImg"
                  source={
                    userDetails?.photo ||
                    require("@socion-cordio/common/src/assets/images/user_circle.svg")
                  }
                />
              </View>
            </View>
            <View style={styles.memberInfoContainer}>
              <View style={styles.subContainerData}>
                <View style={styles.subContainerLabel}>
                  <Text
                    fontWeight={FontWeight.Bold}
                    testId="addRoleText"
                    textSize={TextSize.Small}
                    textStyle={styles.headerText}
                  >
                    {"Name "}
                  </Text>
                </View>
                <View style={styles.subContainerName}>
                  <Text
                    fontWeight={FontWeight.Bold}
                    testId="addRoleText"
                    textSize={TextSize.Small}
                    textStyle={styles.headerText}
                  >
                    {": " + userDetails?.name}
                  </Text>
                </View>
              </View>
              <View style={styles.subContainerData}>
                <View style={styles.subContainerLabel}>
                  <Text
                    fontWeight={FontWeight.Regular}
                    testId="addRoleText"
                    textSize={TextSize.Small}
                    textStyle={[styles.alignText]}
                  >
                    {"Phone No. "}
                  </Text>
                </View>
                <View style={styles.subContainerName}>
                  <Text
                    fontWeight={FontWeight.Regular}
                    testId="addRoleText"
                    textSize={TextSize.Small}
                    textStyle={[styles.alignText]}
                  >
                    {userDetails?.phoneNumber === "NA"
                      ? ": " + `${userDetails?.phoneNumber}`
                      : ": " + `${userDetails?.countryCode} - ${userDetails?.phoneNumber}`}
                  </Text>
                </View>
              </View>
              <View style={styles.subContainerData}>
                <View style={styles.subContainerLabel}>
                  <Text
                    fontWeight={FontWeight.Regular}
                    testId="addRoleText"
                    textSize={TextSize.Small}
                    textStyle={[styles.alignText]}
                  >
                    {"Email "}
                  </Text>
                </View>
                <View style={styles.subContainerName}>
                  <Text
                    fontWeight={FontWeight.Regular}
                    testId="addRoleText"
                    textSize={TextSize.Small}
                    textStyle={[styles.alignText]}
                  >
                    {userDetails?.emailId === "" ? ": NA" : ": " + userDetails?.emailId}
                  </Text>
                </View>
              </View>
            </View>
          </View>
        </View>
      )}
    </>
  );
}

const styles = StyleSheet.create({
  customLoadingContainer: {
    height: 133,
    width: 500
  },
  failedContainer: {
    height: 140,
    width: 500
  },
  header: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
    flexDirection: "row"
  },
  headerText: {
    fontSize: 12,
    fontFamily: FontFamily.Medium,
    lineHeight: 17
  },
  memberInfoContainer: {
    flex: 70
  },
  subContainer: {
    flexDirection: "row",
    marginBottom: 25,
    width: 500
  },
  profilePictureContainer: {
    flex: 30,
    alignItems: "center"
  },
  profilePicture: {
    borderRadius: 54,
    width: "80px",
    height: "80px"
  },
  alignText: {
    fontSize: 12,
    color: colorPallete.textLight,
    marginTop: 10,
    FontFamily: FontFamily.Medium,
    fontWeight: "400"
  },
  crossIcon: {
    fontSize: 15,
    marginBottom: 10
  },
  subContainerData: {
    display: "flex",
    flexDirection: "row"
  },
  subContainerLabel: {
    flex: 20
  },
  subContainerName: {
    flex: 80
  }
});
