import React, { useRef } from "react";
import { useField } from "formik";
import {
  TextInput,
  View,
  StyleSheet,
  StyleProp,
  TextStyle,
  ViewStyle,
  ColorValue
} from "react-native";
import { FontWeight, Label, TextSize, Text } from "@socion-cordio/common/src/components/atoms/text";
import PlatformUtils from "@socion-cordio/common/src/utils/platformUtils";
import { colorPallete } from "@socion-cordio/common/src/assets/styles/colors";

interface IProps {
  label: string;
  multiline?: boolean;
  numberOfLines?: number;
  placeholder: string;
  name: string;
  styling?: Object;
  id: string;
  selectionColor?: string;
  isSecure?: boolean;
  disabled?: boolean;
  value?: string;
  search?: boolean;
  focusStyle?: {};
  toggler?: boolean;
  manageFocus?: (arg: {}) => void;
  handleChange: Function;
  handleBlur: Function;
  labelStyle?: StyleProp<TextStyle>;
  errorStyle?: StyleProp<TextStyle>;
  inputStyle?: StyleProp<TextStyle>;
  fieldStyle?: StyleProp<ViewStyle>;
  noFormik?: boolean;
  editable?: boolean;
}
export const TextArea = ({
  label,
  placeholder = "",
  id = "",
  isSecure = false,
  disabled = false,
  handleBlur,
  handleChange,
  multiline = false,
  numberOfLines,
  value,
  ...props
}: IProps): React.ReactElement => {
  const {
    selectionColor = colorPallete.cordioRed,
    labelStyle,
    fieldStyle,
    manageFocus,
    focusStyle,
    styling,
    toggler = false,
    search = false,
    inputStyle,
    errorStyle,
    noFormik,
    editable
  } = props;
  // const [field, meta] = useField(props);
  // const hasError = meta.touched && meta.error;
  // const error = meta.error;

  let field, meta, hasError, error;
  if (!noFormik) {
    [field, meta] = useField(props);
    hasError = meta.touched && meta.error;
    error = meta.error;
  }

  const inputRef = useRef<TextInput>(null);
  const onClickFocus = () => {
    {
      toggler && inputRef.current?.focus();
    }
  };

  onClickFocus();

  const onFocus = () => {
    {
      search && (PlatformUtils.isWeb() ? manageFocus(styling) : manageFocus(styling));
    }
  };
  return (
    <View style={styles.container}>
      {!search && (
        <Label
          testId={id}
          textSize={TextSize.Small}
          fontWeight={FontWeight.Thin}
          textStyle={[styles.label, labelStyle]}
        >
          {label}
        </Label>
      )}
      <View style={styles.elementsContainer}>
        <TextInput
          ref={inputRef}
          placeholder={placeholder}
          secureTextEntry={isSecure}
          nativeID={"textAreaId"}
          style={
            search
              ? toggler && PlatformUtils.isWeb
                ? focusStyle
                : [fieldStyle, focusStyle]
              : [styles.inputBox, inputStyle]
          }
          editable={!disabled}
          value={value}
          multiline={multiline}
          numberOfLines={numberOfLines}
          selectionColor={selectionColor}
          // {...field}   causing warning in web and mobile
          // onChangeText={handleChange(value)}
          onChangeText={(e) => {
            // CUSTOM HANDLE CHANGE GOES hERE console.log(e);
            // if (props.customChange) {
            //   props.customChange(e);
            // }
            if (e.trim().length == 0) {
              e = e.trim();
            }
            return handleChange(e);
          }}
          // onChangeText={(e) => handleChange(e)}
          onBlur={handleBlur(props.name)}
          onFocus={() => onFocus()}
        />
        {error ? (
          <Text
            testId={id}
            textSize={TextSize.Small}
            fontWeight={FontWeight.Regular}
            textStyle={[styles.error, errorStyle]}
          >
            {error}
          </Text>
        ) : null}
      </View>
    </View>
  );
};
export default TextArea;

const styles = StyleSheet.create({
  container: {
    // flex: 1,
    flexDirection: "row",
    justifyContent: "flex-start"
  },
  elementsContainer: {
    flex: 1,
    flexDirection: "column"
  },
  label: {
    color: colorPallete.textBlack,
    opacity: 0.6,
    paddingBottom: 0,
    marginBottom: 0
  },
  textinput_focused_mobile: {
    width: "100%",
    height: 50
  },
  inputBox: {
    marginTop: 0,
    // paddingTop: 0,
    paddingStart: 10,
    // marginStart: 4,
    borderWidth: 1,
    borderColor: colorPallete.cordioTaupeLight3,
    // opacity: 0.5,
    borderRadius: 5,
    outlineWidth: 0
  },
  error: {
    position: "relative",
    color: "red",
    alignSelf: "flex-start",
    fontSize: 10,
    fontWeight: "400",
    zIndex: -1
  }
});
