import React, { ReactElement, useState, useEffect, useRef } from "react";
import { StyleSheet, View, Pressable, ScrollView } from "react-native";
import { colorPallete } from "@socion-cordio/common/src/assets/styles/colors";
import {
  Text,
  TextSize,
  FontWeight,
  FontFamily
} from "@socion-cordio/common/src/components/atoms/text";
import Icon, { IconNames } from "@socion-cordio/common/src/components/atoms/icon";
import Button, { ButtonType } from "@socion-cordio/common/src/components/atoms/button";
import TextField from "@socion-cordio/common/src/components/atoms/textField";

interface Props {
  title?: string;
  //   onClose?: Function;
  testId: string;
  label: string;
  handleChange: Function;
  value: string;
  placeholder?: string;
  name: string;
  id: string;
  handleSubmit: Function;
  disabled?: boolean;
  error?: boolean;
  errorMessage?: string;
  serverError?: string;
  cancelPress?: Function;
  isCancelVisible?: Boolean;
  locationArray?: any;
  renderLocationSugetion?: Function;
  handleKeyPress?: Function;
}

function elevationShadowStyle(elevation: number) {
  return {
    elevation,
    shadowColor: "black",
    shadowOffset: { width: 5.5, height: 0.55 * elevation },
    shadowOpacity: 0.2,
    shadowRadius: 0.5 * elevation
  };
}

export default function Location(props: Props): ReactElement {
  const {
    title,
    // onClose,
    testId,
    label,
    handleChange,
    value,
    placeholder,
    name,
    id,
    handleSubmit,
    disabled,
    error,
    errorMessage,
    serverError,
    cancelPress,
    isCancelVisible,
    locationArray,
    renderLocationSugetion,
    handleKeyPress
  } = props;
  console.log("getSelectedLocationgetSelectedLocation", locationArray);

  return (
    <View style={styles.searchContainer}>
      <TextField
        label=""
        handleChange={(value: any) => handleChange(value)}
        handleBlur={() => {}}
        value={value}
        inputStyle={styles.inputRole}
        placeholder={placeholder}
        name={name}
        id={id}
        noFormik
        handleKeyPress={(e: any) => handleKeyPress && handleKeyPress(e)}
      />

      {!locationArray || locationArray?.length === 0 ? null : (
        <View style={[styles.searchBoxShadow, styles.box]}>
          <ScrollView
            style={{
              width: "100%",
              height: 200
            }}
          >
            <View>{locationArray?.map(renderLocationSugetion)}</View>
            <View style={styles.alignGoogleText}>
              <Text
                fontWeight={FontWeight.Regular}
                testId="signup"
                textSize={TextSize.Small}
                style={styles.credits}
              >
                {"powered by Google"}
              </Text>
            </View>
          </ScrollView>
        </View>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  searchContainer: {
    height: 40,
    width: "100%",
    borderWidth: 1,
    borderColor: colorPallete.cordioTaupeLight3,
    borderRadius: 5,
    outlineWidth: 0
    // paddingLeft: 10
  },
  // emailInputContainer: {
  //   // marginTop: 20,
  //   flexDirection: "row",
  //   justifyContent: "center"
  // },

  inputRole: {
    height: 40,
    outline: "none",
    // borderRadius: 5,
    borderWidth: 0,
    // padding: 7,
    fontSize: 16,
    fontFamily: FontFamily.Thin,
    fontWeight: "400",
    border: "none",
    padding: "0 30px 0px 25px",
    width: "100%",
    textSize: TextSize.Small
    // paddingLeft: 10
  },
  error: {
    position: "relative",
    color: "red",
    alignSelf: "flex-start",
    fontSize: 10,
    fontWeight: "400",
    zIndex: -1
  },
  iconStyle: {},
  iconContainer: {
    justifyContent: "center",
    marginLeft: 10
  },
  credits: {
    color: colorPallete.gray,
    textAlign: "right",
    fontFamily: FontFamily.Thin
  },
  alignGoogleText: {
    // marginRight: 10,
    // marginBottom: 10,
    padding: 10,
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  searchBoxShadow: {
    ...elevationShadowStyle(5),
    backgroundColor: colorPallete.white,
    width: "100%",
    fontFamily: FontFamily.Thin
  },
  box: {
    borderWidth: 0.5,
    width: "100%",
    borderColor: colorPallete.gray
    // marginTop: 0
  },

  searchBarContainer: {
    display: "flex",
    flexDirection: "row",
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: colorPallete.cordioTaupeLight4,
    borderRadius: 5
  },
  iconStyleSearch: {
    marginLeft: 15,
    marginRight: 5,
    opacity: 0.5,
    position: "absolute",
    top: -10
  },
  footer: {
    flexDirection: "row",
    flex: 1
  },
  footerConsent: {
    flex: 50,
    alignSelf: "center"
  },
  fontSize11: {
    fontSize: 11
  }
});
