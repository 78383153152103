// import _ from "lodash";

import _, { isArray, reject } from "lodash";

// interface IParams {
//     programId: number | string;
//     artefactId?: string;
//   }

// export const canUpdate = (params: IParams) => {
//     return (
//       isPartOfArtefact() ||
//       programService.canAccessAll(params.programId)
//     );
//   }

//   const isPartOfArtefact = () => {
//     return isCreator() || isSubmitter();
//   }

//   const isCreator = () => {
//     return !_.isEmpty(this.artefact)
//       ? _.get(this.artefact, "user_id") === this.userService.userId
//       : true;
//   }

//   const isSubmitter = () => {
//     const existing = _.find(
//       _.get(this.artefact, "submitter_userid.submitterList"),
//       { userid: this.userService.userId }
//     );
//     return !_.isEmpty(existing);
//   }

//   const canAccessAll= (programId) => {
//     let admin = _.map(this.getProgramRoles({ programId }), (role) => {
//       if (_.includes(this.config.roleConfig.ADMIN, role)) {
//         return role;
//       }
//     });
//     admin = _.compact(admin);
//     return admin.length > 0;
//   }

export const getUser = () => {
  const user = JSON.parse(localStorage.getItem("user"));
  return user;
};

export const getSubmitter = (submitters: any) => {
  const isLoggedIn =
    _.find(_.get(submitters, "submitterList"), { userId: getUser().userId }) ||
    _.find(_.get(submitters, "submitterList"), { userid: getUser().userId });

  return isLoggedIn
    ? _.get(isLoggedIn, "name")
    : _.get(_.head(_.get(submitters, "submitterList")), "name");
};

export const getSubmitterDetails = (submitters: any) => {
  let ids = [];
  let names = [];
  let subList = _.get(submitters, "submitterList") || [];
  subList = isArray(subList) ? subList : [subList];
  ids = subList.map((user: any) => user?.userId || user?.userid);
  names = subList.map((user: any) => user?.name);
  return {
    ids: checkIfStringHasQuotes(ids.join(" , ")),
    names: checkIfStringHasQuotes(names.join(" , "))
  };
};

export const displayname = (): string => {
  return _.get(getUser(), "name");
};

export const getUserId = (): string => {
  return _.get(getUser(), "userId");
};

export const checkIfStringHasQuotes = (text: string) => {
  if (text.indexOf("'") >= 0 && text.indexOf('"') >= 0) {
    return `"""${text}"""`;
  } else {
    return `"${text}"`;
  }
};
