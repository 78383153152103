import React, { ReactElement, useEffect, useState } from "react";
import styled from "styled-components";
import {
  useTable,
  useSortBy,
  useGlobalFilter,
  usePagination,
  useFlexLayout,
  useRowSelect
} from "react-table";
import SearchFilter from "@socion-cordio/common/src/components/molecules/searchFilter";
import Pagination from "@socion-cordio/common/src/components/molecules/pagination";
import Icon, { IconNames } from "@socion-cordio/common/src/components/atoms/icon";
import {
  Text,
  TextSize,
  FontFamily,
  FontWeight
} from "@socion-cordio/common/src/components/atoms/text";
import { StyleSheet, TouchableOpacity } from "react-native";
import { dataHelper } from "@socion-cordio/common/src/utils/dataHelper";
import { View } from "react-native";
import Button, { ButtonType } from "@socion-cordio/common/src/components/atoms/button";
import { colorPallete } from "../../../../../common/src/assets/styles/colors";
import "./table.css";
import { useTranslation } from "react-i18next";
import DownloadTableAsCSV from "utils/downloadTableAsCSV";
import { Image } from "@socion-cordio/common/src/components/atoms/image";
import { allRoutesNames as routeNames } from "@socion-cordio/web/src/navigation/allRouteNames";
import { useHistory } from "react-router-dom";
import { canAccessAll } from "utils/artefactActionsHelper";
// import { Image } from "@socion-cordio/common/src/components/atoms/image";

// TABLE USED BY USING FLEX

type HoverProps = {
  hoverColor: boolean;
};

const Styles = styled.div`
  display: block;
  max-width: 100%;
  /* padding: 1rem; */
  /* This will make the table scrollable when it gets too small */
  .tableWrap {
    /* display: block; */
    /* max-width: 100%; */
    /* overflow-x: scroll; */
    /* overflow-y: hidden; */
    /* border-bottom: 1px solid #c2bcb9; */
    display: block;
    width: 100%;
    display: block;
    min-width: 100%;
    overflow: auto hidden;
  }

  .tableHeader {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .searchAndPaginations {
    /* display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center; */
    /* margin-bottom: 10; */
  }

  .headerLabel {
    font-weight: "700";
    text-align: left;
  }
  table {
    width: 100%;
    border-spacing: 0;
    border-top: 1px solid hsl(20, 7%, 90%);
    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }
    // tr:hover {
    //   background-color: #f8f8f8;
    //   cursor: pointer;
    // }
    tr:last-child {
      border-radius: 0 0 20px 20px;
    }
    th,
    td {
      margin: 0;
      /* padding: 0.9rem; */
      padding: 1rem 0.8rem 1rem 0.8rem;
      /* padding: 0 0 0 0; */
      padding-right: 0.2rem;
      /* padding-left: 0.2rem; */
      border-bottom: 1px solid hsl(20, 7%, 90%);
      border-right: 1px solid hsl(20, 7%, 90%);
      :last-child {
        border-right: 0;
      }
    }

    /* Only used when the status
     isUpdatedSearchContainerDesign is true */
    .updatedTableDataCol {
      padding: 8px 10px 8px 14px;
      // display: flex;
      // flex-direction: row;
      // align-items: center;
    }
  }
  .iconLabelContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
  }

  @media only screen and (max-width: 899px) {
    .tableWrap {
      display: block;
      max-width: 100%;
      overflow-x: scroll;
      overflow-y: hidden;
      border-bottom: 1px solid #c2bcb9;
    }
  }
`;

const TableRow = styled.tr<HoverProps>`
  tr:hover {
    background-color: ${(props) => (props.hoverColor ? "" : "#f8f8f8")};
    cursor: pointer;
  }
`;
const IndeterminateCheckbox = React.forwardRef((props: any, ref) => {
  const { indeterminate, ...rest } = props;
  const defaultRef = React.useRef();
  const resolvedRef: any = ref || defaultRef;

  React.useEffect(() => {
    resolvedRef.current.indeterminate = indeterminate;
  }, [resolvedRef, indeterminate]);

  return (
    <>
      <input type="checkbox" ref={resolvedRef} {...rest} />
    </>
  );
});

interface IProps {
  columns: any;
  data: any;
  isSuperAdmin?: boolean;
  paginationAndSearch?: boolean;
  handleRowClick?: Function;
  addIcon?: ReactElement;
  download?: any;
  refreshList?: any;
  handleCreateProgram?: Function;
  textValue?: string;
  createSessionHeaderText?: any;
  searchBarCustomStyle?: any;
  customSearchBarContainerStyle?: any;
  customSearchIconStyle?: any;
  dateRangePicker?: any;
  handleSelectedRows?: Function;
  showSearchField?: boolean;
  showDownload?: boolean;
  showAdd?: Boolean;
  showPaginationField?: boolean;
  showSaveBadgeButton?: boolean;
  saveSelectedBadgeDetailsHandler?: Function;
  isConflictPresentInList?: boolean;
  removeFirstHeaderList?: boolean;
  filterIcon?: ReactElement;
  filterButtons?: ReactElement;
  isUpdatedSearchContainerDesign?: boolean;
  refreshListHandler?: Function;
  noDataMessage?: string;
  totalCount?: any;
}

export default function Table(props: IProps) {
  const { t } = useTranslation();
  const {
    columns,
    data,
    isSuperAdmin,
    paginationAndSearch = true,
    handleRowClick,
    addIcon,
    download,
    refreshList,
    textValue,
    handleCreateProgram,
    createSessionHeaderText,
    searchBarCustomStyle,
    customSearchIconStyle,
    customSearchBarContainerStyle,
    dateRangePicker,
    handleSelectedRows,
    showSearchField = true,
    showDownload = false,
    showAdd = false,
    showPaginationField = true,
    showSaveBadgeButton = false,
    saveSelectedBadgeDetailsHandler,
    isConflictPresentInList,
    removeFirstHeaderList = false,
    filterIcon,
    filterButtons,
    // isUpdatedSearchContainerDesign = false,
    refreshListHandler,
    noDataMessage = "",
    totalCount
  } = props;

  const defaultColumn = React.useMemo(
    () => ({
      minWidth: 30,
      width: 150,
      maxWidth: 400
    }),
    []
  );

  const isAllowedToAddArtefactTypes = canAccessAll();

  const filterTypes = React.useMemo(
    () => ({
      text: (rows: any, id: any, filterValue: any) => {
        return rows.filter((row: any) => {
          const rowValue = row.values[id];
          return rowValue !== undefined
            ? [String(rowValue).toLowerCase().startsWith(String(filterValue).toLowerCase())]
            : true;
        });
      }
    }),
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    gotoPage,
    nextPage,
    previousPage,
    state: { pageSize, pageIndex, selectedRowIds },
    prepareRow,
    state,
    setGlobalFilter,
    rows,
    selectedFlatRows
  } = useTable(
    {
      columns,
      data,
      filterTypes,
      initialState: { pageSize: paginationAndSearch ? 12 : data.length, pageIndex: 0 },
      defaultColumn
    },
    useGlobalFilter,
    useSortBy,
    useFlexLayout,
    usePagination,
    useRowSelect
  );

  useEffect(() => {
    // console.log(selectedRowIds, selectedFlatRows.map(d => d.original))
    handleSelectedRows && handleSelectedRows(selectedFlatRows.map((d) => d.original));
  }, [selectedRowIds]);

  const [headerCheck, setHeaderCheck] = useState(null);
  useEffect(() => {
    checkHeader();
  }, [data, columns]);

  const checkHeader = () => {
    setHeaderCheck(headerGroups);
    removeFirstHeaderList === true ? headerGroups.splice(0, 1) : headerGroups;
  };

  const history: any = useHistory();

  // console.log("header  in table: ", columns);
  // console.log("tableData in table: ", data);

  return (
    <div>
      {dataHelper.isEmptyArray(data) ? (
        <>
          {isSuperAdmin && (
            <View style={styles.acceptInviteContainer}>
              <View>
                <View style={styles.noQualifications}>
                  <Text
                    fontWeight={FontWeight.Regular}
                    testId="signup"
                    textSize={TextSize.Small}
                    style={styles.noContent}
                  >
                    {`The entity does not have any programs.`}
                  </Text>
                </View>

                <View style={styles.buttonContainer}>
                  <Button
                    type={ButtonType.Primary}
                    buttonStyles={styles.button}
                    title="Create Program"
                    onPress={() => handleCreateProgram()}
                    textStyles={styles.buttonFont}
                  />
                </View>
              </View>
            </View>
          )}
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center"
            }}
          >
            {!isSuperAdmin && (
              <div
                style={{
                  padding: 25,
                  fontSize: 14,
                  fontFamily: FontFamily.Bold,
                  display: "flex"
                }}
              >
                {noDataMessage == "" ? "No Data Found" : noDataMessage}
              </div>
            )}

            {dateRangePicker && (
              <div style={{ display: "flex", marginRight: "1rem" }}>{dateRangePicker}</div>
            )}
          </div>
        </>
      ) : (
        <Styles>
          {paginationAndSearch && (
            <div>
              <div
                className="searchAndPaginations"
                style={
                  showSearchField === true
                    ? // !isUpdatedSearchContainerDesign
                      //   ? {
                      //       display: "flex",
                      //       justifyContent: "space-between",
                      //       alignItems: "center",
                      //       padding: 25,
                      //       marginBottom: 10
                      //     }
                      //   :
                      {
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        padding: 18
                      }
                    : { marginBottom: 20, float: "right", paddingRight: 20 }
                }
              >
                <div style={{ display: "flex", alignItems: "center", width: "100%" }}>
                  {showSearchField && (
                    <div style={{ flexDirection: "row" }}>
                      <SearchFilter
                        customStyle={[styles.customStyle, searchBarCustomStyle]}
                        globalFilter={state.globalFilter}
                        setGlobalFilter={setGlobalFilter}
                        customSearchIconStyle={customSearchIconStyle}
                        customSearchBarContainerStyle={customSearchBarContainerStyle}
                      />
                    </div>
                  )}

                  <div
                    style={{
                      flex: "1",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-around",
                      width: "30%"
                      // paddingRight: 15
                    }}
                  >
                    {totalCount && (
                      <Text
                        fontWeight={FontWeight.Bold}
                        testId="totalcount"
                        textSize={TextSize.Small}
                        style={[styles.noContent, { fontFamily: FontFamily.Bold }]}
                      >
                        {"Total: " + totalCount}
                      </Text>
                    )}
                    {showPaginationField && (
                      <div style={{ paddingRight: 15 }}>
                        <Pagination
                          customStylePaginator={styles.customStylePaginator}
                          totalCount={data.length}
                          currentPage={pageIndex + 1}
                          pageSize={pageSize}
                          previousPage={previousPage}
                          nextPage={nextPage}
                          gotoPage={gotoPage}
                        />
                      </div>
                    )}
                    {props.filterIcon && (
                      <div style={{ paddingRight: 15 }}>
                        <div>{props.filterIcon}</div>
                      </div>
                    )}
                    {showDownload && (
                      <div>
                        <DownloadTableAsCSV
                          header={columns}
                          tableData={data}
                          fileName={"download"}
                        />
                      </div>
                    )}
                  </div>
                </div>

                <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                  {props.refreshList && (
                    <TouchableOpacity onPress={() => refreshListHandler()}>
                      <div className="iconLabelContainer">
                        <div>{props.refreshList}</div>
                        <span
                          style={{
                            fontSize: 12,
                            fontFamily: FontFamily.Regular,
                            textDecoration: "underline",
                            color: colorPallete.textBlack
                          }}
                        >
                          {t("table:refreshList")}
                        </span>
                      </div>
                    </TouchableOpacity>
                  )}
                  {props.download && (
                    <div style={{ marginLeft: 40 }}>
                      <div>{props.download}</div>
                      {textValue}
                    </div>
                  )}

                  {/* {showPaginationField && (
                    <div>
                      <Pagination
                        customStylePaginator={styles.customStylePaginator}
                        totalCount={data.length}
                        currentPage={pageIndex + 1}
                        pageSize={pageSize}
                        previousPage={previousPage}
                        nextPage={nextPage}
                        gotoPage={gotoPage}
                      />
                    </div>
                  )} */}
                  {showSaveBadgeButton && (
                    <div>
                      <Button
                        type={ButtonType.Primary}
                        buttonStyles={styles.saveButton}
                        textStyles={styles.buttonFont}
                        title="Save"
                        onPress={() => saveSelectedBadgeDetailsHandler()}
                        // disabled={isConflictPresentInList}
                      />
                    </div>
                  )}
                  {dateRangePicker && <div style={{ marginLeft: "1rem" }}>{dateRangePicker}</div>}
                  <div style={{ marginLeft: 45 }}>{props.addIcon}</div>
                </div>
              </div>
              {props.createSessionHeaderText && (
                <div style={{ paddingLeft: 18, marginTop: -15, marginBottom: 18 }}>
                  {" "}
                  <span
                    style={{
                      fontSize: 12,
                      fontFamily: FontFamily.Regular,
                      color: colorPallete.textLight
                    }}
                  >
                    {t("session:selectTopicForCreateSession")}
                  </span>
                </div>
              )}
              {props.filterButtons && (
                <div className="filteredButtons">
                  <div style={{}}>
                    <div>{props.filterButtons}</div>
                  </div>
                </div>
              )}
            </div>
          )}

          <div className="tableWrap">
            <table {...getTableProps()}>
              <thead style={{ backgroundColor: "#F8F8F8", color: colorPallete.textBlack }}>
                {headerGroups.map((headerGroup: any) => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column: any) => (
                      <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                        <div className="tableHeader">
                          <span
                            className="headerLabel"
                            style={
                              // !isUpdatedSearchContainerDesign
                              //   ? {
                              //       fontSize: 10,
                              //       fontFamily: FontFamily.Medium,
                              //       fontWeight: FontWeight.Bold
                              //     }
                              //   :
                              {
                                fontSize: 12,
                                fontFamily: FontFamily.Medium,
                                fontWeight: FontWeight.Bold
                              }
                            }
                          >
                            {column.render("Header")}
                          </span>
                          {typeof column.render("Header") === "string" && (
                            <div>
                              {!column.disableSortBy ? (
                                <Icon
                                  name={IconNames.sortIcon}
                                  customStyle={{ marginLeft: 8, fontSize: 13 }}
                                />
                              ) : null}
                            </div>
                          )}
                        </div>
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()}>
                {page.map((row: any, i: any) => {
                  prepareRow(row);
                  return (
                    <TableRow
                      hoverColor={
                        row.original.deleted === true || row.original.deactivated == true
                          ? true
                          : false
                      }
                    >
                      <div
                        style={
                          row.original.deleted === true || row.original.deactivated == true
                            ? {
                                backgroundColor: "#E8E8E8",
                                borderBottom: "1px solid hsl(20,7%,90%)"
                              }
                            : { borderBottom: "1px solid hsl(20,7%,90%)" }
                        }
                      >
                        <tr
                          {...row.getRowProps()}
                          onClick={handleRowClick ? () => handleRowClick(row.original) : null}
                        >
                          {row.cells.map((cell: any) => {
                            // return !isUpdatedSearchContainerDesign ? (
                            //   <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                            // ) : (
                            //   <td className="updatedTableDataCol" {...cell.getCellProps()}>
                            //     {cell.render("Cell")}
                            //   </td>
                            // );
                            return (
                              <td
                                valign="middle"
                                className="updatedTableDataCol"
                                {...cell.getCellProps()}
                              >
                                {cell.render("Cell")}
                              </td>
                            );
                          })}
                        </tr>
                      </div>
                    </TableRow>
                  );
                })}
              </tbody>
            </table>
          </div>
        </Styles>
      )}
    </div>
  );
}

const styles = StyleSheet.create({
  customStyle: {
    marginLeft: 0,
    marginTop: 0
  },
  customStylePaginator: {
    marginTop: 0,
    marginLeft: 40,
    alignItems: "center"
  },
  paginationContainer: {
    // flexDirection: "row"
  },
  iconStyle: {
    fontSize: 15,
    marginLeft: 45
    // marginRight: 35
  },
  acceptInviteContainer: {
    backgroundColor: colorPallete.lightGrey,
    height: 243,
    borderBottomLeftRadius: 20,
    borderBottomRightRadius: 20,
    justifyContent: "center"
  },
  noQualifications: {
    alignItems: "center",
    justifyContent: "center"
  },
  noContent: {
    fontSize: 14,
    textAlign: "center",
    fontFamily: FontFamily.Regular
  },
  button: {
    width: "140px",
    height: "50px",
    borderRadius: 10,
    marginLeft: 20,
    marginRight: 20
  },
  buttonContainer: {
    justifyContent: "center",
    alignItems: "center",
    marginTop: 20
  },
  addPrograms: {
    alignSelf: "flex-end"
  },
  saveButton: {
    width: "140px",
    height: "50px",
    borderRadius: 10,
    marginHorizontal: 15
  },
  buttonFont: {
    fontSize: 12
  },
  verifyRoleContainer: {
    backgroundColor: "#F8F8F8"
  },
  searchBarContainerStyle: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: 25,
    marginBottom: 10
  }
});
