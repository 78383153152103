import { IFluxStandardAction } from "@socion-cordio/common/src/store/interfaces";
import { ARTEFACTS } from "@socion-cordio/common/src/modules/artefacts/actions/actionTypes";
import { Artefact } from "models/artefact";
import { compact } from "lodash";

const getArtefacts = (data: {
  pid: number;
  userId?: string;
}): IFluxStandardAction<{ pid: number; userId?: string }> => {
  return {
    type: ARTEFACTS.GET_ARTEFACTS.LOADING,
    payload: data
  };
};

const getArtefactssSuccess = (data: Artefact): IFluxStandardAction<any> => {
  if (data?.result) {
    data.result.forEach((task: any, index: number) => {
      task.serialNo = `${index + 1}.`;
    });
  } else {
    data.result = compact(data.result);
  }
  return {
    type: ARTEFACTS.GET_ARTEFACTS.SUCCESS,
    payload: data
  };
};

const getArtefactsError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: ARTEFACTS.GET_ARTEFACTS.ERROR,
    payload: error
  };
};

export const ArtefactActions = {
  getArtefacts,
  getArtefactssSuccess,
  getArtefactsError
};
