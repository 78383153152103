import React, { ReactElement } from "react";
import { StyleSheet, View } from "react-native";
import {
  Text,
  TextSize,
  FontWeight,
  FontFamily
} from "@socion-cordio/common/src/components/atoms/text";
import { Image } from "@socion-cordio/common/src/components/atoms/image";
import Icon, { IconNames } from "@socion-cordio/common/src/components/atoms/icon";

interface Props {
  onClose?: Function;
  sessionData: any;
  carouselHandler: any;
}

export default function ViewQrCodeDetails(props: Props): ReactElement {
  const { onClose, sessionData, carouselHandler } = props;

  return (
    <>
      <View style={styles.header}>
        <View>
          <Icon
            testID="close"
            name={IconNames.crossCircle}
            customStyle={styles.crossIcon}
            onPress={() => onClose()}
          />
        </View>
      </View>
      <View style={styles.subContainer}>
        <View style={styles.subContainerLabel}>
          <Text
            fontWeight={FontWeight.Bold}
            testId="addRoleText"
            textSize={TextSize.Small}
            textStyle={styles.headerText}
          >
            {`UID : ${carouselHandler.left === true ? sessionData.startUid : sessionData.endUid}`}
          </Text>
        </View>
        <View style={styles.subContainerName}>
          <Text
            fontWeight={FontWeight.Bold}
            testId="addRoleText"
            textSize={TextSize.Small}
            textStyle={styles.headerText}
          >
            <Image
              testId="sessionQR"
              source={
                carouselHandler.left === true ? sessionData.startQrImage : sessionData.endQrImage
              }
              imageStyle={styles.qrCodeStyle}
            />
          </Text>
        </View>
      </View>
    </>
  );
}

const styles = StyleSheet.create({
  header: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
    flexDirection: "row"
  },
  headerText: {
    fontSize: 12,
    fontFamily: FontFamily.Medium,
    lineHeight: 17
  },
  qrCodeStyle: {
    height: 300,
    width: 300
  },
  subContainer: {
    width: 500,
    alignItems: "center"
  },
  crossIcon: {
    fontSize: 15
  },
  subContainerLabel: {
    marginBottom: 10
  },
  subContainerName: {
    marginBottom: 10
  }
});
