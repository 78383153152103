import React, { useEffect, useState } from "react";
import { FlatList, SafeAreaView, StyleSheet, TouchableOpacity, View } from "react-native";
import { ProSidebar, Menu, MenuItem, SubMenu } from "react-pro-sidebar";
import Button, { ButtonType } from "@socion-cordio/common/src/components/atoms/button";
import Icon, { IconNames } from "@socion-cordio/common/src/components/atoms/icon";
import {
  Text,
  TextSize,
  FontWeight,
  FontFamily
} from "@socion-cordio/common/src/components/atoms/text";
import { colorPallete } from "@socion-cordio/common/src/assets/styles/colors";
import { Link } from "react-router-dom";
import LinearGradient from "react-native-web-linear-gradient";
import { LocalStorage } from "@socion-cordio/common/src/services/storage/storageService";
import { Image } from "@socion-cordio/common/src/components/atoms/image";

interface Props {
  data: Array<Object>;
  onLogout?: Function;
  getSidePanelWidth?: Function;
  menuCollapse?: boolean;
}

export default function List(props: Props) {
  const [selectedId, setSelectedId] = useState(null);
  const [menuCollapse, setMenuCollapse] = useState(true);
  const [widthCollapse, setWidthCollapse] = useState(220);
  const { data, getSidePanelWidth } = props;
  let menuData: any = [];
  data.map((item: any) => {
    menuData.push(item);
  });

  useEffect(() => {
    // widthCollapse === 220 ? getSidePanelWidth(220, false) : null;
    getLocalStoreWidth();
  }, []);

  const getLocalStoreWidth = async () => {
    const isSidePanelCollapsed: any = await LocalStorage.getStorage("isSidePanelCollapsed");
    isSidePanelCollapsed
      ? [getSidePanelWidth(60), setWidthCollapse(60), setMenuCollapse(false)]
      : getSidePanelWidth(220);
  };

  if (selectedId === 99) {
    props.onLogout(selectedId);
  }

  const Item = ({ onPress, item }: { onPress: any; item: any }) => (
    <TouchableOpacity onPress={onPress} style={[]}>
      {window.location.pathname.includes(item.path) ||
      window.location.pathname.includes(item?.pathName) ? (
        <LinearGradient
          colors={["#B7504A", "#6E302C"]}
          start={{ y: 0.0, x: 0.0 }}
          end={{ y: 0.0, x: 1.0 }}
        >
          {renderViewList(item)}
        </LinearGradient>
      ) : (
        <View>{renderViewList(item)}</View>
      )}
    </TouchableOpacity>
  );

  const renderItem = ({ item }: { item: any }) => (
    <Item item={item} onPress={() => setSelectedId(item.id)} />
  );

  const renderViewList = (item: any) => (
    <Link to={item.path} style={{ textDecorationLine: "none" }}>
      <View style={[styles.item]}>
        <View
          style={
            menuCollapse
              ? styles.selectedBarHighlighContainer
              : [styles.selectedBarHighlighContainer, styles.selectedBarHighlightAlign]
          }
        >
          {window.location.pathname.includes(item.path) ? (
            <View style={styles.selectedBarHighlight}></View>
          ) : (
            <View></View>
          )}
        </View>
        <View
          style={
            menuCollapse
              ? styles.subContainerIconLabel
              : [styles.subContainerIconLabel, styles.alignSubIconContainer]
          }
        >
          <View>
            {!item?.imageName && (
              <Icon
                name={item.iconName}
                customStyle={
                  menuCollapse ? styles.iconStyle : [styles.iconStyle, styles.alignIcons]
                }
              />
            )}
            {item?.imageName && (
              <Image
                testId="edit"
                source={require(`@socion-cordio/common/src/assets/images/${item?.imageName}`)}
                imageStyle={
                  item?.title === "Artefacts"
                    ? {
                        width: 20,
                        height: 20,
                        marginRight: 5,
                        tintColor: colorPallete.white
                      }
                    : {
                        width: 14,
                        height: 14,
                        marginRight: 5
                      }
                }
              />
            )}
          </View>
          {menuCollapse === true && (
            <View>
              <Text
                fontWeight={FontWeight.Bold}
                testId="title"
                textSize={TextSize.Small}
                style={
                  window.location.pathname.includes(item.path) ||
                  window.location.pathname.includes(item?.pathName)
                    ? [styles.title]
                    : [styles.lightFont]
                }
              >
                {item.title}
              </Text>
            </View>
          )}
        </View>
      </View>
    </Link>
  );

  const menuIconClick = () => {
    if (menuCollapse) {
      menuData = [];
      data.map((item: any) => {
        menuData.push({
          iconName: item?.iconName,
          id: item.id,
          path: item.path,
          imageName: item?.imageName
        });
      });
      setMenuCollapse(false);
      setWidthCollapse(60);
      getSidePanelWidth(60, false);
      LocalStorage.setStorage("isSidePanelCollapsed", true);
    } else {
      menuData = [];
      data.map((item: any, index: number) => {
        menuData.push(item);
      });
      setMenuCollapse(true);
      setWidthCollapse(220);
      getSidePanelWidth(220, true);
      LocalStorage.setStorage("isSidePanelCollapsed", false);
    }
  };

  useEffect(() => {
    programSelectedTabClearHandler();
    entitySelectedTabClearHandler();
    clearQpLocationFilterHandler();
  }, [window.location.pathname]);

  const programSelectedTabClearHandler = () => {
    !window.location.pathname.includes("/program")
      ? LocalStorage.removeStorage("programTabsOpen")
      : null;
  };

  const entitySelectedTabClearHandler = () => {
    !window.location.pathname.includes("/entity")
      ? LocalStorage.removeStorage("entityTabsOpen")
      : null;
  };

  const clearQpLocationFilterHandler = () => {
    LocalStorage.removeStorage("qpfilterList");
  };

  return (
    <>
      <View style={styles.mainContainer}>
        <ProSidebar collapsed={menuCollapse} collapsedWidth={widthCollapse} width={60}>
          <View style={styles.sidebar}>
            <SafeAreaView style={styles.container}>
              <FlatList
                data={menuData}
                renderItem={renderItem}
                keyExtractor={(item) => item.id}
                extraData={selectedId}
                contentContainerStyle={{}}
              />
            </SafeAreaView>
          </View>
        </ProSidebar>
        <View style={[styles.miniContainer2]}>
          <Icon
            customStyle={styles.sidebarIconStyle2}
            name={menuCollapse ? IconNames.doubleArrowLeft : IconNames.doubleArrowRight}
            onPress={() => menuIconClick()}
          />
        </View>
      </View>
    </>
  );
}

const styles = StyleSheet.create({
  mainContainer: {
    flexDirection: "row",
    display: "flex",
    backgroundColor: colorPallete.cordioRedDark1
  },
  miniContainer2: {
    // marginTop: 380,
    marginLeft: -37,
    marginTop: 450,
    backgroundColor: colorPallete.cordioRedDark1,
    width: 60,
    height: 60,
    borderRadius: 10,
    borderTopRightRadius: 30,
    transform: [{ rotate: "45deg" }]
  },
  sidebarIconStyle2: {
    fontSize: 25,
    alignSelf: "flex-end",
    margin: 10,
    color: colorPallete.white,
    transform: [{ rotate: "315deg" }]
  },
  sidebar: {
    left: 0,
    height: "100%",
    backgroundColor: colorPallete.cordioRedDark1,
    minHeight: "calc(100vh - 50px)"
  },
  container: {
    top: 70
  },
  item: {
    height: 45,
    flexDirection: "row",
    alignItems: "center",
    display: "flex"
  },
  title: {
    fontSize: 12,
    color: colorPallete.cordioBeige,
    fontFamily: FontFamily.Medium,
    fontWeight: "700",
    letterSpacing: 0.6
  },
  lightFont: {
    fontSize: 12,
    color: colorPallete.cordioBeige,
    fontFamily: FontFamily.Regular
  },
  iconStyle: {
    color: colorPallete.white,
    marginEnd: 10
  },
  alignIcons: {
    marginEnd: 0
  },
  selectedBarHighlighContainer: {
    flex: 13
    // width: 30
  },
  selectedBarHighlightAlign: {
    flex: 0
  },
  selectedBarHighlight: {
    width: 4,
    height: 45,
    backgroundColor: "#DBCBBC"
  },
  subContainerIconLabel: {
    flexDirection: "row",
    flex: 87,
    alignItems: "center"
  },
  alignSubIconContainer: {
    justifyContent: "center",
    marginRight: 4 // off set to adjust with the highlighted bar in the left
  }
});
