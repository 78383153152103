import React, { ReactElement, useState, useEffect, useRef } from "react";
import { StyleSheet, View, ScrollView, TouchableOpacity } from "react-native";
import Geolocation from "react-native-geolocation-service";
import * as RNLocalize from "react-native-localize";
import { colorPallete } from "@socion-cordio/common/src/assets/styles/colors";
import {
  Text,
  TextSize,
  FontWeight,
  FontFamily
} from "@socion-cordio/common/src/components/atoms/text";
import { profileEndPoints } from "@socion-cordio/common/src/repositories/endPoints";
import { ApiClient } from "@socion-cordio/common/src/network/apiClient";
import { useSelector, useDispatch } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
import { auxiliaryMethods } from "@socion-cordio/common/src/utils/auxiliaryMethods";
import Location from "./location";
import { isEmpty } from "lodash";
import { useHistory, useLocation } from "react-router-dom";
import { allRoutesNames } from "@socion-cordio/web/src/navigation/allRouteNames";
import { telemetryToTrackMayMyIndia } from "@socion-cordio/common/src/utils/mayMyIndiaTelemetry";

interface Props {
  // onClose: Function;
  modalTitle?: string;
  currentBaseLocation: string;
  setShowRightPanel?: Function;
  setIsLocationEnabled?: Function;
  isLocationEnabled?: boolean;
  locationConsentOnToggle?: boolean;
  showTitleAndCloseIcon?: boolean;
  getSelectedLocation: Function;
  locationChange?: Function;
  value?: Function;
  handleKeyPress?: Function;
  clearLocation: boolean;
}

export default function MapLocation(props: Props): ReactElement {
  const {
    currentBaseLocation,
    setShowRightPanel,
    setIsLocationEnabled,
    isLocationEnabled,
    locationConsentOnToggle,
    showTitleAndCloseIcon = true,
    getSelectedLocation,
    locationChange,
    handleKeyPress,
    clearLocation
  } = props;
  const [baseLocation, setBaseLocation] = useState(currentBaseLocation || "");
  const [lat, setLat] = useState(undefined);
  const [long, setLong] = useState(undefined);
  const [city, setCity] = useState("");
  const [district, setDistrict] = useState("");
  const [state, setState] = useState("");
  const [country, setCountry] = useState("");
  const [subDistrict, setSubDistrict] = useState("");
  const [detectedLanguageCode, setDetectedLanguageCode] = useState(null);
  const [baseLocationError, setBaseLocationError] = useState("");
  const [placeId, setPlaceId] = useState(undefined);
  const [establishmentName, setEstablishmentName] = useState(undefined);
  const [locationArray, setLocationArray] = useState([]);
  const [disableState, setDisableState] = useState(true);
  const [languageCode, setLanguageCode] = useState("en");
  const [formattedLocation, setFormattedLocation] = useState("");
  const dispatch = useDispatch();
  const data: any = JSON.parse(localStorage.getItem("user"));
  const [selectedLocationObj, setSelectedLocationObj] = useState({});
  const [scriptLoaded, setScriptLoaded] = useState(null);
  const history = useHistory();
  const locationHook = useLocation();

  useEffect(() => {
    console.log("getSelectedLocation", selectedLocationObj, "scriptLoaded", scriptLoaded);
    getSelectedLocation(selectedLocationObj);
  }, [selectedLocationObj]);

  const loadScript = (token: string) => {
    const script = document.createElement("script");
    script.id = "mapmyindiascript";
    script.src = `https://apis.mapmyindia.com/advancedmaps/api/${token}/map_sdk_plugins`;
    script.async = true;
    document.body.appendChild(script);
  };

  useEffect(() => {
    async function getAccessToken() {
      try {
        await loadMapData();
      } catch (error) {
        console.log("loadMapData Error", error);
        // toast.error("Something went wrong", error);
      }
    }
    getAccessToken();
  }, []);

  const loadMapData = async () => {
    const response = await ApiClient.get(profileEndPoints.getMapMyIndiaToekn());
    const { access_token } = response?.response;
    console.log("here");
    loadScript(access_token);
    let countryCode = data?.countryCode;
    if (countryCode === "+91") {
      const optional_config = {
        // width: "100%",
        height: 200,
        tokenizeAddress: true
      };
      const element = document.getElementById("addBaseLocation");
      try {
        const searched_place = new MapmyIndia.search(element, optional_config, suggestionsCallback);
        console.log("searched_placesearched_place", searched_place);
      } catch (err) {
        console.log("searched_placesearched_place", err, "locationHook", locationHook);
        // await preLoadScriptFiles();
        if (locationHook.pathname.includes("/artefact/edit/")) {
          await loadMapData();
        }
      }
    }
  };

  // useEffect(() => {
  //   onPressForLocation();
  // }, [currentBaseLocation]);

  useEffect(() => {
    console.log("clearLocation", clearLocation);
    if (clearLocation === true) {
      setBaseLocation("");
    }
  }, [clearLocation]);

  const getBaseLocationLatLongApi = async (params: any, callback: Function) => {
    console.log("checkLanguage", params.lang);
    const language = params.lang === "" || params.lang === null ? "en" : params.lang;
    try {
    } catch (error) {
      console.log("error");
      callback(false, error);
    }
    // };
  };

  const getLocationBasedOnLatLong = async (params: any, toggle: boolean) => {
    const { lat, long } = params;
    let countryCode = data?.countryCode;
    setLat(lat);
    setLong(long);

    getBaseLocationLatLongApi(params, (status: any, response: any) => {
      if (status) {
        const { location } = response.geometry;
        setLat(location.lat);
        setLong(location.lng);
        response.address_components.map((item: any) => {
          if (item.types[0] === "locality") {
            setCity(item.long_name);
          }
          if (item.types[0] === "administrative_area_level_2") {
            setDistrict(item.long_name);
          }
          if (item.types[0] === "administrative_area_level_1") {
            setState(item.long_name);
          }
          if (item.types[0] === "country") {
            setCountry(item.long_name);
          }
        });
        console.log("setFormattedLocationsetFormattedLocation", response);
        setFormattedLocation(response.formatted_address || "");
      }
    });
  };

  const onPressForLocation = (toggle = false) => {
    const local = RNLocalize.getLocales();
    const language = local[0];

    navigator.permissions.query({ name: "geolocation" }).then(function (result) {
      if (result.state == "granted") {
        Geolocation.getCurrentPosition(
          (position) => {
            const params = {
              lat: position.coords.latitude,
              long: position.coords.longitude,
              lang: language.languageCode
            };
            {
              getLocationBasedOnLatLong(params, toggle);
            }
            setLat(position.coords.latitude);
            setLong(position.coords.longitude);
            setLanguageCode(language.languageCode);
          },
          (error) => {
            console.log("============== error on press loc =========", error);
          },
          { enableHighAccuracy: true, timeout: 15000, maximumAge: 10000, showLocationDialog: false }
        );
      } else if (result.state == "prompt") {
        navigator.geolocation.getCurrentPosition(revealPosition, positionDenied);
        console.log("location access to be prompted");
      } else if (result.state == "denied") {
        console.log("location access denied");
      } else {
        console.log("==============error on press loc====in else part on press loc =====");
      }
    });
  };

  const revealPosition = () => {
    console.log("Revel position");
    onPressForLocation();
  };

  const positionDenied = () => {
    console.log("Position denied!");
  };

  const handleBaseLocation = (loc: string) => {
    setBaseLocation(loc);
    onSearchPress(loc);
  };

  let UNICODE = {
    ar: /[\u0600-\u06FF]/,
    iw: /[\u0590-\u05FF]/,
    bn: /[\u0980-\u09FF]/,
    de: /[\u0370-\u03FF]/,
    ka: /[\u10A0-\u10FF]/,
    th: /[\u0E00-\u0E7F]/,
    kn: /[\u0C80-\u0CFF]/,
    hi: /[\u0900-\u097F]/,
    en: /^[a-zA-Z]+$/,
    el: /[\u0900-\u097F]/,
    ta: /[\u0B80-\u0BFF]/,
    ml: /[\u0D00-\u0DFF]/,
    te: /[\u0C00-\u0C7F]/,
    lo: /[\u0E80-\u0EFF]/,
    hy: /[\u0530-\u058F]/,
    mr: /[\u0900-\u097F]/
    // add other languages here
  };

  function validateText(text: string) {
    let textExp = /\S/;
    return textExp.test(text);
  }

  const getElocData = (location: any) => {
    try {
      return new MapmyIndia.getEloc({ eloc: location?.eLoc });
    } catch (error) {
      toast.error("Something went wrong.");
    }
  };

  const getPlacePicker = (latitude: number, longitude: number) => {
    try {
      return new MapmyIndia.placePicker({
        location: { lat: latitude, lng: longitude }
      });
    } catch (error) {
      toast.error("Something went wrong.");
    }
  };

  // when current location selected
  const currentLocationSelected = async (location: any) => {
    console.log("Current location lat,long", location);
    const objectData = await getPlacePicker(location.latitude, location.longitude);
    console.log("current location placePicker", objectData);
    await telemetryToTrackMayMyIndia("PLACE_PICKER", {
      location: { lat: location.latitude, lng: location.longitude }
    });
    const formatted = objectData?.formatted_address || objectData?.formattedaddress;
    let baseLocation = formatted ? objectData?.formatted_address + " , " + objectData?.area : "";
    objectData.formatted_address = baseLocation;
    objectData.placeId = location?.eLoc;
    setBaseLocation(baseLocation);
    setResponseData(objectData);
  };

  async function suggestionsCallback(data: any) {
    try {
      if (data) {
        let locObj: any = {};
        setDisableState(true);
        console.log("Place Search - for suggestion of places as user type", data);
        const location = data[0];
        const key = document.getElementById("addBaseLocation");
        await telemetryToTrackMayMyIndia("SEARCH", { query: key?.value });
        let establishmentName = undefined;
        if (location.placeName.includes("Current Location")) {
          currentLocationSelected(location);
          return;
        }

        const elocSelectedObj = await getElocData(location);
        console.log(
          "Place Details Plugin - to get the Coarse Lat-Long of Address (eLoc) selected in Place Search",
          elocSelectedObj
        );
        await telemetryToTrackMayMyIndia("ELOC", { eloc: location?.eLoc });
        if (location?.type === "POI") {
          establishmentName = location?.placeName;
          setEstablishmentName(location?.placeName);
        }
        console.log("locationnnnn", location);
        // if (location.type === "VILLAGE") {
        location.addressTokens["area"] = "India";
        location.addressTokens["country"] = "India";
        location.addressTokens["latitude"] = elocSelectedObj?.data.latitude;
        location.addressTokens["longitude"] = elocSelectedObj?.data.longitude;
        location.addressTokens["placeId"] = elocSelectedObj?.data.eloc;
        let baseLocation = location?.placeName + " , " + location?.placeAddress;
        location.addressTokens["formatted_address"] = baseLocation;
        setBaseLocation(baseLocation);

        console.log("locationnnnn", location);
        setResponseData(location.addressTokens, establishmentName);

        setBaseLocation(baseLocation);
        // }
      }
    } catch (err) {
      // toast.error(data.error);
    }
  }

  const setResponseData = (objectData: any, establishmentName?: string) => {
    setCountry(objectData?.area);
    setState(objectData?.state);
    if (objectData?.village != "") {
      setCity(objectData?.village);
    } else {
      setCity(objectData?.city);
    }
    setDistrict(objectData?.district);
    setLat(objectData?.lat);
    setLong(objectData?.lng);
    setEstablishmentName(establishmentName);
    setSubDistrict(objectData?.subDistrict);
    console.log("objectDataobjectDataobjectData", objectData);
    // setFormattedLocation(objectData?.forma)

    const objData = {
      country: objectData?.area,
      state: objectData?.state,
      district: objectData?.district,
      subDistrict: objectData?.subDistrict,
      city: objectData?.city,
      village: objectData?.village,
      formattedaddress: objectData?.formatted_address,
      placeId: objectData?.placeId,
      latitude: objectData?.lat || objectData?.latitude,
      longitude: objectData?.lng || objectData?.longitude,
      location: objectData?.formatted_address
    };

    setSelectedLocationObj(objData);
  };

  const fromatBaseLocation = (data: any) => {
    let formattedBaseLocation: string = "";

    if (data?.village !== "") {
      formattedBaseLocation += `${data?.village}, `;
    } else {
      if (data?.city !== "") {
        formattedBaseLocation += `${data?.city}, `;
      }
    }
    if (data?.subDistrict !== "") {
      formattedBaseLocation += `${data?.subDistrict}, `;
    }
    if (data?.district !== "") {
      formattedBaseLocation += `${data?.district}, `;
    }
    if (data?.state !== "") {
      formattedBaseLocation += `${data?.state}, `;
    }
    if (data?.area !== "") {
      formattedBaseLocation += `${data?.area} `;
    }
    return formattedBaseLocation;
  };

  const getSearchedLocation = async (data: any, callback: any) => {
    try {
    } catch (error) {
      callback(false, error);
    }
    try {
      const response = await ApiClient.get(profileEndPoints.placeAutoComplete(), { key: data });
      if (response) {
        callback(response?.response?.status, response?.response?.predictions);
      }
    } catch (error) {
      callback(false, error);
    }
  };

  const onSearchPress = (loc: string) => {
    // const { searchApi, countryCode } = this.props;
    console.log("searched_placesearched_place onSearchPress", loc);
    Object.entries(UNICODE).forEach(([key, value]) => {
      if (value.test(loc) == true) {
        // this.setState({detectedLanguageCode: key});
        setDetectedLanguageCode(key);
      }
    });
    if (loc === "" || !validateText(loc)) {
      // locationArray = [];
      setLocationArray([]);
      // this.setState({validationError:true,validationErrorMessage:stringsConvertor('validationMessage.searchLocHint'),baseLocation:''});
      setBaseLocationError("Make sure you have searched the location");
    } else {
      let countryCode = data?.countryCode;
      if (countryCode !== "+91") {
        console.log("searched_placesearched_place onSearchPress countryCode", countryCode);
        // this.setState({ isLoading: true, placeId: '', noResult: false, validationError: false }, () => {
        getSearchedLocation(loc, (status: string, response: any) => {
          if (status === "OK") {
            console.log("response from the callback api", response);
            // locationArray = response;
            setLocationArray(response);
            // this.setState({ isLoading: false, itemSelected: false });
          } else if (status === "ZERO_RESULTS") {
            // locationArray = [];
            setLocationArray([]);
            // this.setState({ isLoading: false, noResult: true, noResultMessage: stringsConvertor('validationMessage.noResultFound') });
            setBaseLocationError("No result found for this search");
          } else {
            // locationArray = [];
            setLocationArray([]);
            // this.setState({ isLoading: false, noResult: true, noResultMessage: 'Some error' });
            setBaseLocationError("Some error");
          }
        }).catch((err: any) => {
          // this.setState({ isLoading: false });
          console.log("error ----- ", err);
        });
        // });
      }
    }
  };

  const renderLocationSugetion = (item: any) => {
    console.log("renderLocationSugetion", item);
    const { description, place_id, eLoc, addressTokens } = item;

    const POI = item.type === "POI" ? item.placeName : "";
    const address = item.description;
    return (
      <View
        style={{ width: "100%", display: "flex", flexWrap: "wrap" }}
        // style={{ borderBottomWidth: 1, paddingVertical: 2, backgroundColor: placeId === place_id ? colorPallete.gray : null }}
        // style = {{borderBottomWidth:verticalScale(.5),paddingVertical:verticalScale(4),backgroundColor:placeId === place_id ?COLORS.gray :null}}
      >
        <TouchableOpacity
          onPress={() => {
            onSelectItemPress(address, place_id, eLoc, POI, item, addressTokens);
          }}
        >
          <View style={styles.alignAddressText}>
            <Text
              style={{ fontSize: 16 }}
              fontWeight={FontWeight.Regular}
              testId="renderLocationSuggestion"
              textSize={TextSize.Small}
            >
              {address}
            </Text>
          </View>
        </TouchableOpacity>
      </View>
    );
  };

  const onSelectItemPress = (
    description: string,
    place_id: string,
    eLoc: string,
    POI: string,
    item: any,
    addressTokens: string
  ) => {
    setPlaceId(place_id);
    const data = {
      place_id,
      detectedLanguageCode
    };
    getLocationByPlaceId(data, (status: boolean, response: any) => {
      let selectedPlaceObj: any = {};
      selectedPlaceObj["city"] = "";
      selectedPlaceObj["district"] = "";
      selectedPlaceObj["subDistrict"] = "";
      selectedPlaceObj["state"] = "";
      selectedPlaceObj["country"] = "";
      selectedPlaceObj["establishment"] = "";
      setCity("");
      setDistrict("");
      setSubDistrict("");
      setState("");
      setCountry("");
      setLat("");
      setLong("");
      setEstablishmentName("");
      setFormattedLocation("");
      setPlaceId("");
      if (status === true) {
        console.log("============response by place id", response);
        response.address_components.map((item: any) => {
          if (item.types[0] === "locality") {
            setCity(item.long_name);
            selectedPlaceObj["city"] = item.long_name;
          }
          if (item.types[0] === "administrative_area_level_2") {
            setDistrict(item.long_name);
            selectedPlaceObj["district"] = item.long_name;
          }
          if (item.types[0] === "administrative_area_level_1") {
            setState(item.long_name);
            selectedPlaceObj["state"] = item.long_name;
          }
          if (item.types[0] === "country") {
            setCountry(item.long_name);
            selectedPlaceObj["country"] = item.long_name;
          }
        });

        setFormattedLocation(response.formatted_address || "");
        setPlaceId(response.place_id);
        selectedPlaceObj["placeId"] = response.place_id;
        selectedPlaceObj["formattedaddress"] = response.formatted_address || "";
        selectedPlaceObj["subDistrict"] = "";
        let type = response.types;
        let checkType = type.includes("establishment");
        if (checkType === true) {
          setEstablishmentName(response.name);
        } else {
          setEstablishmentName("");
        }
        const { location } = response.geometry;
        setLat(location.lat);
        setLong(location.lng);
        setLocationArray([]);
        selectedPlaceObj["latitude"] = location.lat;
        selectedPlaceObj["longitude"] = location.lng;
        console.log("selectedPlaceObj", selectedPlaceObj);
        setSelectedLocationObj(selectedPlaceObj);
        let baseLocation = response.formatted_address
          ? response.formatted_address + selectedPlaceObj?.country
            ? +" , " + selectedPlaceObj?.country
            : ""
          : "";
        // auxiliaryMethods.fromatBaseLocation(selectedPlaceObj);
        console.log("baseLocation", baseLocation);
        setBaseLocation(baseLocation);
        console.log("location arrya is emptied here ------- +++++++++++ =====");
      }
    }).catch((err) => {});
  };

  const getLocationByPlaceId = async (data: any, callback: any) => {
    try {
      console.log("getLocationByPlaceId", data);
      const response = await ApiClient.get(profileEndPoints.placeDetails(), {
        reference: data.place_id
      });
      if (response) {
        callback(true, response?.response?.result);
      }
    } catch (error) {
      callback(false, error);
    }
  };

  const onCancelPress = () => {
    setBaseLocation("");
    setLocationArray([]);
    setCity("");
    setDistrict("");
    setState("");
    setCountry("");
    setSubDistrict("");
    setFormattedLocation("");
    setPlaceId("");
    setLat(undefined);
    setLong(undefined);
  };

  return (
    <Location
      title="Add new base location"
      testId="addBaseLocationText"
      label="Base location"
      handleChange={(loc: any) => {
        locationChange(loc);
        handleBaseLocation(loc);
      }}
      value={baseLocation}
      placeholder="Enter base location to search"
      name="addBaseLocation"
      id="addBaseLocation"
      handleSubmit={(value: any) => {}}
      cancelPress={() => onCancelPress()}
      isCancelVisible={false}
      locationArray={locationArray}
      renderLocationSugetion={renderLocationSugetion}
      handleKeyPress={(e: any) => handleKeyPress && handleKeyPress(e)}
    />
  );
}

const styles = StyleSheet.create({
  alignAddressText: {
    marginBottom: 5,
    borderBottomWidth: 0.5,
    borderBottomColor: colorPallete.cordioRed,
    padding: 5,
    width: "100%"
  }
});
