import { JsonObject, JsonProperty } from "json2typescript";

@JsonObject("Response")
export class Response {
  @JsonProperty("createdAt", String)
  createdAt: string = "";
  @JsonProperty("updatedAt", String)
  updatedAt: string = "";
  @JsonProperty("createdBy", String)
  createdBy: string = "";
  @JsonProperty("updatedBy")
  updatedBy: string = null;
  @JsonProperty("deleted", Boolean)
  deleted: string = "";
  @JsonProperty("startDate", String)
  startDate: string = "";
  @JsonProperty("endDate", String)
  endDate: string = "";
  @JsonProperty("email", String, true)
  email: string = "";
  @JsonProperty("userLimit", Number)
  userLimit: number = null;
  @JsonProperty("acceptingVerifications", Boolean)
  acceptingVerifications: string = "";
  @JsonProperty("acceptingQualifications", Boolean)
  acceptingQualifications: string = "";
  @JsonProperty("acceptingRoles", Boolean)
  acceptingRoles: string = "";
  @JsonProperty("notificationStatus", String)
  notificationStatus: string = "";
  @JsonProperty("geoLevel", String, true)
  geoLevel: string = "";
  @JsonProperty("topicLibraryEnabled", Boolean)
  topicLibraryEnabled: string = "";
  @JsonProperty("subContentEnabled", Boolean)
  subContentEnabled: string = "";
  @JsonProperty("licensesIncludeTopicLibraryAccess", Boolean)
  licensesIncludeTopicLibraryAccess: string = "";
  @JsonProperty("sessionScanMode", String)
  sessionScanMode: string = "";
  @JsonProperty("multipleScanModeAccess", Boolean)
  multipleScanModeAccess: string = "";
  @JsonProperty("saSurveyEnabled", Boolean)
  saSurveyEnabled: string = "";
  @JsonProperty("paSurveyEnabled", Boolean)
  paSurveyEnabled: string = "";
  @JsonProperty("hyperlinked", Boolean)
  hyperlinked: string = "";
  @JsonProperty("id", Number)
  id: number = null;
  @JsonProperty("name", String)
  name: string = "";
  @JsonProperty("description", String)
  description: string = "";
}

@JsonObject("EligibleProgramsForQualification")
export class EligibleProgramsForQualification {
  @JsonProperty("response", [Response])
  response: Array<Response> = null;
  @JsonProperty("message", String)
  message: string = "";
  @JsonProperty("responseCode", Number)
  responseCode: number = null;
}
