import { RegisterNewUser, Signup } from "@socion-cordio/common/src/models/signup";
import { ApiClient } from "@socion-cordio/common/src/network/apiClient";
import { signupEndPoints } from "@socion-cordio/common/src/repositories/endPoints";

class SignupRepository {
  signupUser = async (body: {
    countryCode: number;
    phoneNumber: number;
    name: string;
  }): Promise<Signup> => {
    let signupData = await ApiClient.post(signupEndPoints.signupUser(), body);
    return signupData;
  };

  registerNewUser = async (body: Object): Promise<RegisterNewUser> => {
    let signupData = await ApiClient.post(signupEndPoints.signupNewUser(), body);
    return signupData;
  };

  validateNewUserOtp = async (body: Object): Promise<RegisterNewUser> => {
    let response = await ApiClient.post(signupEndPoints.validateNewOtp(), body);
    return response;
  };

  resendNewUserOtp = async (body: Object, params: Object): Promise<RegisterNewUser> => {
    let response = await ApiClient.post(signupEndPoints.resendNewOtp(), body, params);
    return response;
  };

  submitSignUpDetails = async (body: Object): Promise<RegisterNewUser> => {
    let response = await ApiClient.post(signupEndPoints.setNewPassword(), body);
    return response;
  };
}
const signupRepository = new SignupRepository();
export { signupRepository as SignupRepository };
