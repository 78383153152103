import Icon, { IconNames } from "@socion-cordio/common/src/components/atoms/icon";
import {
  Text,
  TextSize,
  FontWeight,
  FontFamily
} from "@socion-cordio/common/src/components/atoms/text";
import { colorPallete } from "@socion-cordio/common/src/assets/styles/colors";
import React, { ReactElement, useState } from "react";
import { View, StyleSheet } from "react-native";
import TextArea from "@socion-cordio/common/src/components/atoms/textArea";
import { ApiClient } from "@socion-cordio/common/src/network/apiClient";
import { programEndPoints } from "@socion-cordio/common/src/repositories/endPoints";
import { ScrollContainer } from "@socion-cordio/common/src/components/organisms/scrollWrapper";
import "@socion-cordio/common/src/assets/styles/styles.css";
import { toast } from "react-toastify";
interface IProps {
  description: string;
  userRoles: any;
  isSuperAdmin: any;
  programStatus?: Function;
  getProgramDetails: Function;
}

export default function DescriptionDetails(props: IProps): ReactElement {
  const { programStatus, getProgramDetails } = props;
  const programDetails: any = JSON.parse(localStorage.getItem("programDetails"));
  const [descriptionValue, setDescriptionValue] = useState(props.description);
  const [isEdit, setIsEdit] = useState(false);
  const editHandler = () => {
    setIsEdit(true);
  };

  const cancelHander = () => {
    setDescriptionValue(props.description);
    setIsEdit(false);
  };

  const saveHandler = async () => {
    try {
      const { program } = programDetails;
      const descriptionBody = {
        description: descriptionValue,
        entityId: program.entity.registryEntityId
      };
      const descriptionResponse = await ApiClient.put(
        programEndPoints.updateDescription(program.id),
        descriptionBody
      );
      if (descriptionResponse) {
        toast.success("Program description updated");
      }
      getProgramDetails();
      setIsEdit(false);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <View style={styles.container}>
      <View style={styles.headerContainer}>
        <Text
          fontWeight={FontWeight.Light}
          testId="headerText"
          textSize={TextSize.Small}
          textStyle={[styles.headerText, styles.headerAlignment]}
        >
          Description
        </Text>

        {!isEdit &&
          (props.userRoles?.includes("PROGRAM_ADMIN") ||
            props.userRoles?.includes("ENTITY_ADMIN") ||
            props.isSuperAdmin) &&
          !programStatus && (
            <Icon
              onPress={editHandler}
              testID="edit"
              name={IconNames.edit}
              customStyle={[styles.alignButton]}
            />
          )}
        {isEdit && (
          <View style={{ flexDirection: "row" }}>
            <Icon
              onPress={saveHandler}
              testID="edit"
              name={IconNames.checkBoxTicked}
              customStyle={[styles.alignButton, { marginRight: 20 }]}
            />
            <Icon
              onPress={cancelHander}
              testID="edit"
              name={IconNames.crossCircle}
              customStyle={[styles.alignButton]}
            />
          </View>
        )}
      </View>
      <View style={styles.subContainer}>
        {!isEdit && (
          <ScrollContainer height="100px" scrollable={descriptionValue.length > 150 ? true : false}>
            <Text
              fontWeight={FontWeight.Light}
              testId="headerText"
              textSize={TextSize.Small}
              textStyle={[styles.headerText, styles.informationText]}
            >
              {descriptionValue}
            </Text>
          </ScrollContainer>
        )}
        {isEdit && (
          <TextArea
            value={descriptionValue}
            inputStyle={[styles.inputStyling]}
            numberOfLines={4}
            multiline={true}
            label=""
            handleChange={(description: string) => {
              setDescriptionValue(description);
            }}
            handleBlur={() => {}}
            placeholder="Enter Description"
            name="description"
            id="description"
            noFormik
          />
        )}
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    borderColor: "rgba(103, 88, 80,0.1)",
    borderStyle: "solid",
    borderWidth: 1,
    borderRadius: 10,
    padding: 15
  },
  headerContainer: {
    flexDirection: "row",
    justifyContent: "space-between"
  },
  headerText: {
    color: colorPallete.textLight,
    fontSize: 12,
    fontFamily: FontFamily.Medium,
    lineHeight: 17
  },
  alignButton: {
    fontSize: 15,
    color: colorPallete.cordioTaupe
  },
  subContainer: {
    marginVertical: 20
  },
  informationText: {
    fontWeight: "500",
    color: colorPallete.textBlack
  },
  headerAlignment: {
    color: colorPallete.textLight,
    fontFamily: FontFamily.Regular
  },
  inputStyling: {
    outline: "none",
    borderRadius: 5,
    padding: 7,
    fontSize: 12,
    fontFamily: FontFamily.Medium,
    fontWeight: "400"
  },
  descriptionStyle: {
    height: 67
  }
});
