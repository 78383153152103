export const allRoutesNames = {
  HOME: "/",
  IAM: {
    root: "/new/iam",
    login: "/login",
    signup: "/signup"
  },
  CONTACT: "/new/contact",
  HEALTHCHECK: "/health",
  app: "/new/app",
  SIGNUPOTP: "/new/signup/otp",
  ABOUTUS: "/new/aboutUs",
  /* PROFILE ROUTES START*/
  PROFILE: "/profile",
  PERSONALINFO: "/personal-info",
  CHANGEPASSWORD: "/change-password",
  ABOUT: "/about",
  PRIVACYPOLICY: "/privacy-policy",
  TERMSOFUSE: "/terms-of-use",
  PROFILE_PRIVACYPOLICY: "/privacy-policy",
  PROFILE_TERMSOFUSE: "/terms-of-use",
  SETTINGS: "/settings",
  /* PROFILE ROUTES END */
  /* SPRINGSHED ROUTES START */
  ENTITY: "/entity",
  PROGRAM: "/program",
  TOPICS: "/topics",
  TOPICPARTIPANTDETAIL: "/topicparticipantdetail",
  SESSION: "/session",
  PARTICIPATIONDETAILS: "/participationDetails",
  PARTICIPATIONATTESTATION: "/participationAttestation",
  BADGES: "/badges",
  VERIFY: "/verify",
  DASHBOARD: "/dashboard",
  QUALIFICATIONPACKS: "/qualification-packs",
  /* SPRINGSHED ROUTES END */
  SIGNUP: "/signup",
  FORGOTPASSWORDOTP: "/new/forgot-password/otp",
  FORGOTPASSWORD: "/new/forgot-password",
  SIGNUPDETAILS: "/new/signup/details",
  RESETPASSWORD: "/new/forgot-password/reset-password",
  workspace: "/workspace",
  rev: {
    root: "/rev",
    dashboards: {
      root: `/dashboard/:comp`,
      urlParams: {
        selfCalibrationsList: `selfCalibrations`,
        reportsList: `reports`,
        actionPlansList: `actionPlans`,
        appealsList: `appeals`
      }
    },
    testAudit: `/testAudit`,
    detailedReport: `/detailedReport`,
    detailedActionPlan: `/detailedActionPlan`
  },
  CREATENEWSESSION: "/create-session",
  CREATESESSION: "/create",
  VIEWSESSION: "/view-session",
  PARTICIPATIONLIST: "/participation-list",
  ASSIGNBADGE: "/assign",
  UNASSIGNBADGE: "/unassign",
  VIEWQUALIFICATIONPACKS: "/view-pack-details",
  VIEWPARTICIPANTMAPPING: "/pariticipant-mapping",
  TOPICDETAILS: "/topic-details",
  //ARTEFACTS: "/artefacts",
  ARTEFACT_TYPES: "/artefactTypes",
  ADD_ARTEFACT_TYPES: "/addArtefactType",
  EDIT_ARTEFACT_TYPES: "/editArtefactType",
  ADD_ARTEFACT: "/add-artefact",
  VIEW_ARTEFACT: "/artefact/view",
  EDIT_ARTEFACT: "/artefact/edit",
  REVIEW_ARTEFACT: "/artefact/review",
  CREATE_TASK: "/create-task",
  UPDATE_TASK: "/update-task",
  PREVIEW_TASK: "/preview-task",
  TASK_SUBMISSIONS: "/submissions",
  TASK_VIEW_SUBMISSION: "/view-submission",
  PDA_PREVIEW_TASK: "/pda-preview-task",
  ARTEFACTS_FILTER: "/filter-artefacts",
  VIEW_ARTEFACT_PDF: "/artefact/pdf",
  VIEW_ARTEFACT_TYPE_PDF: "/artefactType/pdf",
  PUBLIC_VIEW_PDF: "/widget/viewer",
  LOGINOTP: "/new/login/otp"
};
