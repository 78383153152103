import React, { useEffect, useMemo, useState } from "react";
import { View, StyleSheet, TouchableOpacity } from "react-native";
import Table from "@socion-cordio/web/src/components/molecules/table/table";
import {
  Text,
  TextSize,
  FontWeight,
  FontFamily
} from "@socion-cordio/common/src/components/atoms/text";
import { dataHelper } from "@socion-cordio/common/src/utils/dataHelper";
import { colorPallete } from "@socion-cordio/common/src/assets/styles/colors";
import Loader from "@socion-cordio/common/src/components/atoms/loader";
import { useLocation, useHistory } from "react-router-dom";
import { TopicsActions } from "@socion-cordio/common/src/modules/topic/actions/actions";
import { useDispatch, useSelector } from "react-redux";
import TopicDetailsModal from "@socion-cordio/common/src/components/organisms/topic/topicDetailsModal";
import { Image } from "@socion-cordio/common/src/components/atoms/image";
import AddTopicModal from "@socion-cordio/common/src/components/organisms/topic/addTopicModal";
import SocionModal from "@socion-cordio/common/src/components/atoms/modal";
import { UserRolesProgramActions } from "@socion-cordio/common/src/modules/user-role/actions/actions";
import { LocalStorage } from "@socion-cordio/common/src/services/storage/storageService";
import SearchFilterSeondary from "@socion-cordio/common/src/components/molecules/searchFilterSecondary";
import { allRoutesNames as routeNames } from "@socion-cordio/web/src/navigation/allRouteNames";
import { springshedEndPoints } from "@socion-cordio/common/src/repositories/endPoints";
import { ApiClient } from "@socion-cordio/common/src/network/apiClient";

interface IProps {}

const Topics = (props: IProps) => {
  const location: any = useLocation();
  const [viewTopicDetails, setViewTopicDetails] = useState(false);
  const [selectedTopic, setSelectedTopic] = useState(null);
  const [addTopicModal, setAddTopicModal] = useState(false);
  const [newAddedTopic, setNewAddedTopic] = useState(null);
  const [topicDetails, setTopicDetails] = useState(null);
  const [updatedTopicsList, setUpdatedTopicsList] = useState([]);
  const [isEntityEnabled, setEntityStatus] = useState<boolean>(false);
  const programData: any = JSON.parse(localStorage.getItem("programDetails"));
  const dispatch = useDispatch();
  const history: any = useHistory();
  const {
    topicsData: topics,
    loading: { getTopics: loading }
  } = useSelector((state: any) => state.topics);

  const {
    userRolesData: userRoles,
    loading: { getUserRolesProgram: rolesLoader }
  } = useSelector((state: any) => state?.userRolesProgram);

  useEffect(() => {
    const payload = {
      programId: programData.program.id
    };
    dispatch(TopicsActions.getTopics(payload));
    isNewTopicCreated(newAddedTopic);
  }, [newAddedTopic]);

  useEffect(() => {
    topicsListHandler();
  }, [topics]);

  useEffect(() => {
    getEntityDetails();
  }, []);

  const topicsListHandler = () => {
    if (topics !== null) {
      const sortedList = sortTopicListHandler(topics.response);
      setUpdatedTopicsList(sortedList);
    }
  };

  const sortTopicListHandler = (list: any[]) => {
    // Returns list with latest sorted date wise
    const sorted = list.sort(function compare(a: any, b: any) {
      var dateA: any = new Date(a.creationDate);
      var dateB: any = new Date(b.creationDate);
      return dateA - dateB;
    });
    const newList = sorted.reverse();
    newList.forEach((topic: any, index: number) => {
      topic.serialNo = `${index + 1}.`;
    });
    return newList;
  };

  useEffect(() => {
    const rolesPayload = {
      programId: programData.program.id
    };
    dispatch(UserRolesProgramActions.getUserRolesProgram(rolesPayload));
  }, [location]);

  useEffect(() => {
    // storing userRoles
    LocalStorage.setStorage("userRoles", userRolesProgram);
  }, [useSelector((state: any) => state?.userRolesProgram)]);

  let userRolesProgram: any = useSelector((state: any) => state?.userRolesProgram);
  let userRolesData: any = useSelector((state: any) => state?.userRolesProgram);
  const memberRole = userRolesProgram?.userRolesData?.response;

  const isSuperAdmin = JSON.parse(localStorage.getItem("isSuperAdmin"));
  const cellData = (testId: string, style: any, data: string, onPress?: Function) => (
    <Text
      fontWeight={FontWeight.Bold}
      testId={testId}
      textSize={TextSize.Small}
      style={[
        {
          fontSize: 12,
          fontFamily: FontFamily.Regular,
          fontWeight: FontFamily.Light,
          textSize: TextSize.Small,
          color: colorPallete.textBlack
        },
        style
      ]}
      onPress={onPress ? () => onPress() : null}
    >
      {data}
    </Text>
  );

  const isNewTopicCreated = (data: any) => {
    if (data !== null) {
      setAddTopicModal(false);
      topicDetailsHandler(data);
      // setSelectedTopic(newAddedTopic.id);
      // setViewTopicDetails(true);
    }
  };

  const topicDetailsHandler = (topic: any) => {
    history.push(`${routeNames.app}${routeNames.TOPICS}${routeNames.TOPICDETAILS}`, {
      selectedTopicDetails: topic,
      topicId: topic.id,
      userRoles: memberRole,
      isSuperAdmin: isSuperAdmin
    });
  };

  const getEntityDetails = async () => {
    try {
      const entityId = programData?.entityData?.entity?.registryEntityId;
      const entityDetailsResponse = await ApiClient.get(
        springshedEndPoints.getEntityDetails(entityId)
      );
      LocalStorage.setStorage("isEntityDeleted", entityDetailsResponse?.Entity?.deleted);
      setEntityStatus(entityDetailsResponse?.Entity?.deleted);
    } catch (error) {
      console.log(error);
    }
  };

  const handleParticipantCount = (id: string, name: string) => {
    if (memberRole?.includes("ENTITY_ADMIN") || memberRole?.includes("PROGRAM_ADMIN")) {
      history.push(`${routeNames.app}${routeNames.TOPICPARTIPANTDETAIL}`, {
        topicId: id,
        topicName: name
      });
    } else return;
  };

  const columns = [
    {
      Header: "Sr.No",
      accessor: "serialNo",
      width: 42,
      maxWidth: 42,
      disableSortBy: true,
      Cell: ({ row: { original: topic } }: { row: { original: any } }) => {
        const style = {
          fontFamily: FontFamily.Regular,
          fontWeight: FontFamily.Light
        };
        return cellData("serialNo", style, topic.serialNo);
      }
    },
    {
      Header: "Topic name",
      accessor: "name",
      width: 300,
      maxWidth: 300,
      Cell: ({ row: { original: topic } }: { row: { original: any } }) => {
        const style = {
          fontFamily: FontFamily.Medium,
          fontWeight: FontFamily.Bold
        };
        const onPress = () => {
          topicDetailsHandler(topic);
          setSelectedTopic(topic.id);
          setViewTopicDetails(true);
          setTopicDetails(topic);
        };
        return cellData("name", style, topic.name, onPress);
      }
    },
    {
      Header: "Date of creation",
      accessor: "creationDate",
      width: 103,
      maxWidth: 103,
      Cell: ({ row: { original: topic } }: { row: { original: any } }) => {
        const style = { fontFamily: FontFamily.Regular, fontWeight: FontFamily.Light };
        return cellData("creationDate", style, dataHelper.formatDate(topic.creationDate));
      }
    },
    {
      Header: "Sessions",
      accessor: "sessionCount",
      width: 73,
      maxWidth: 73,
      Cell: ({ row: { original: topic } }: { row: { original: any } }) => {
        const style = { fontFamily: FontFamily.Regular, fontWeight: FontFamily.Light };
        return cellData("sessionCount", style, topic.sessionCount);
      }
    },
    {
      Header: "Contents",
      accessor: "contentFilesCount",
      width: 73,
      maxWidth: 73,
      Cell: ({ row: { original: topic } }: { row: { original: any } }) => {
        const style = { fontFamily: FontFamily.Regular, fontWeight: FontFamily.Light };
        return cellData("contentFilesCount", style, topic.contentFilesCount);
      }
    },
    {
      Header: "Status",
      accessor: "status",
      width: 68,
      maxWidth: 68,
      Cell: ({ row: { original: topic } }: { row: { original: any } }) => (
        <Text
          fontWeight={FontWeight.Light}
          testId="deleted"
          textSize={TextSize.Small}
          style={{
            fontSize: 12,
            fontFamily: FontFamily.Regular,
            color: colorPallete.textBlack
          }}
        >
          <View
            style={[
              styles.status,
              topic.status === "ACTIVE"
                ? styles.active
                : topic.status === "DEACTIVATED"
                ? styles.deactivated
                : topic.status === "PENDING"
                ? styles.pending
                : null
            ]}
          />
          {topic.status === "DEACTIVATED" ? "Inactive" : dataHelper.capitalize(topic.status)}
        </Text>
      )
    },
    {
      Header: "Participants",
      accessor: "participantCount",
      width: 85,
      maxWidth: 85,
      Cell: ({ row: { original: topic } }: { row: { original: any } }) => {
        const style = {
          fontFamily: FontFamily.Medium,
          fontWeight: FontFamily.Bold,

          textDecorationLine: "underline"
        };
        const onPress = () => {
          handleParticipantCount(topic?.id, topic?.name);
        };
        return cellData("participantCount", style, topic.participantCount, onPress);
      }
    }
  ];

  const searchbarAndAddMember = () => (
    <View>
      <View style={styles.searchBarContainer}>
        <View pointerEvents={"none"}>
          <SearchFilterSeondary customWidthStyle={styles.customWidthStyle} editable={false} />
        </View>
        <View>
          {!isEntityEnabled &&
            !programData.program.deleted &&
            ((!isSuperAdmin && memberRole?.includes("ENTITY_ADMIN")) ||
              memberRole?.includes("PROGRAM_ADMIN") ||
              memberRole?.includes("CONTENT_ADMIN")) && (
              <TouchableOpacity onPress={() => setAddTopicModal(true)}>
                <Image
                  testId="primaryAddImg"
                  source={require("@socion-cordio/common/src/assets/images/primary_add.svg")}
                  imageStyle={{
                    width: 30,
                    height: 30
                  }}
                />
              </TouchableOpacity>
            )}
        </View>
      </View>
      <Text
        fontWeight={FontWeight.Thin}
        testId="headerText"
        textSize={TextSize.Small}
        textStyle={styles.headerText}
      >
        {"No Topics Present"}
      </Text>
    </View>
  );

  return (
    <View style={styles.mainContainer}>
      <View style={styles.subContainer}>
        {loading || rolesLoader ? (
          <Loader />
        ) : dataHelper.isEmptyArray(topics?.response) ? (
          <View style={styles.noDataContainer}>
            {searchbarAndAddMember()}
            {addTopicModal && (
              <View>
                <SocionModal
                  modalVisible={addTopicModal}
                  setModalVisible={() => setAddTopicModal(!addTopicModal)}
                  component={
                    <AddTopicModal
                      onClose={() => setAddTopicModal(false)}
                      setNewAddedTopic={setNewAddedTopic}
                    />
                  }
                />
              </View>
            )}
          </View>
        ) : (
          <View>
            <Table
              columns={columns}
              data={updatedTopicsList}
              addIcon={
                ((!isEntityEnabled &&
                  !programData.program.deleted &&
                  !isSuperAdmin &&
                  memberRole?.includes("ENTITY_ADMIN")) ||
                  memberRole?.includes("PROGRAM_ADMIN") ||
                  memberRole?.includes("CONTENT_ADMIN")) && (
                  <TouchableOpacity onPress={() => [setAddTopicModal(true)]}>
                    <Image
                      testId="primaryAddImg"
                      source={require("@socion-cordio/common/src/assets/images/primary_add.svg")}
                      imageStyle={{
                        width: 28,
                        height: 28
                      }}
                    />
                  </TouchableOpacity>
                )
              }
              isUpdatedSearchContainerDesign={true}
              searchBarCustomStyle={styles.customSearchBarHeight}
              customSearchIconStyle={styles.customSearchIconStyle}
              customSearchBarContainerStyle={styles.customSearchBarContainerStyle}
            />
            {addTopicModal && (
              <View>
                <SocionModal
                  modalVisible={addTopicModal}
                  setModalVisible={() => setAddTopicModal(!addTopicModal)}
                  component={
                    <AddTopicModal
                      onClose={() => setAddTopicModal(false)}
                      setNewAddedTopic={setNewAddedTopic}
                    />
                  }
                />
              </View>
            )}
            {viewTopicDetails && (
              <TopicDetailsModal
                selectedTopicDetails={topicDetails}
                topicId={selectedTopic}
                modalVisible={viewTopicDetails}
                userRoles={memberRole}
                isSuperAdmin={isSuperAdmin}
                setModalVisible={() => setViewTopicDetails(!viewTopicDetails)}
              />
            )}
          </View>
        )}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  mainContainer: {
    // top: 55,
    padding: 20,
    paddingTop: 15,
    backgroundColor: colorPallete.cordioBeigeLight2,
    minHeight: "calc(100vh - 50px)"
  },
  subContainer: {
    backgroundColor: colorPallete.white,
    borderRadius: 10,
    shadowColor: colorPallete.cordioRedDark1,
    shadowOpacity: 0.1,
    shadowOffset: {
      height: 10,
      width: 5
    },
    shadowRadius: 10
  },
  noDataContainer: {
    height: "calc(100vh - 150px)",
    padding: 20
  },
  actionButton: {},
  status: {
    height: 8,
    width: 8,
    borderRadius: 50,
    marginRight: 8
  },
  active: {
    backgroundColor: colorPallete.cordioGreen
  },
  pending: {
    backgroundColor: colorPallete.cordioOrange
  },
  deactivated: {
    backgroundColor: colorPallete.yellowOne
  },
  alignHeader: {
    marginBottom: 30
  },
  header: {
    display: "flex",
    flexDirection: "row"
  },
  headerText: {
    fontSize: 14,
    fontFamily: FontFamily.Medium,
    lineHeight: 19.5,
    fontWeight: "700"
  },
  iconStyle: {
    fontSize: 18,
    marginRight: 35
  },
  // Search bar disabled
  searchBarContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 30
  },
  customWidthStyle: {
    width: 400
  },
  addButtonIcon: {},
  customSearchBarHeight: {
    height: 40,
    width: 400,
    fontSize: 14
  },
  customSearchIconStyle: {
    fontSize: 16
  },
  customSearchBarContainerStyle: {
    borderRadius: 5
  }
});

export default Topics;
