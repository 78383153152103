import { colorPallete } from "@socion-cordio/common/src/assets/styles/colors";
import {
  FontFamily,
  FontWeight,
  Text,
  TextSize
} from "@socion-cordio/common/src/components/atoms/text";
import { ArtefactRepository } from "@socion-cordio/common/src/repositories/artefact/artefact";
import { compact, find, isEmpty, orderBy } from "lodash";
import React, { useEffect, useState } from "react";
import { ScrollView, StyleSheet, TouchableOpacity, View } from "react-native";
import UserInput from "@socion-cordio/common/src/components/molecules/userInput";
import { getBadges, getJoinedLocation } from "../helpers/artefactHelper";
import { LocalStorage } from "@socion-cordio/common/src/services/storage/storageService";
import styled from "styled-components";
import TextField from "@socion-cordio/common/src/components/atoms/textField";
import { Image } from "@socion-cordio/common/src/components/atoms/image";

const AddSubmitterWrapper = styled.div`
  //   @media (min-width: 900px) {
  //     .leftNavNotCollapsed {
  //       width: 220px;
  //     }
  //     .leftNavCollapsed {
  //       width: 250px;
  //     }
  //   }
  //
`;

function elevationShadowStyle(elevation: number) {
  return {
    elevation,
    shadowColor: "black",
    shadowOffset: { width: 3.5, height: 0.55 * elevation },
    shadowOpacity: 0.2,
    shadowRadius: 0.5 * elevation
  };
}

const AddSubmitters = (props: any) => {
  const [editable, setEditable] = useState(true);
  const [users, setUsers] = useState([]);
  const [query, setQuery] = useState(null);
  const [selectedSubmitters, setSelectedSubmitters] = useState([]);
  const [submittersList, setSubmittersList] = useState([]);
  const [leftSidePanelCollapse, setLeftSidePanelCollapse] = useState(null);

  const leftNavCollapseHandler = async () => {
    let isSidePanelCollapsed: boolean = await LocalStorage.getStorage("isSidePanelCollapsed");
    setLeftSidePanelCollapse(isSidePanelCollapsed);
  };

  const handleDeleteNewSubmitter = (index: number) => {
    let tempArray = [...selectedSubmitters];
    tempArray.splice(index, 1);
    setSelectedSubmitters(tempArray);
    props.selectedSubmitters(tempArray);
  };

  useEffect(() => {
    leftNavCollapseHandler();
  });

  useEffect(() => {
    setSubmittersList(props?.submitters || []);
  }, [props]);

  useEffect(() => {
    if (query && query.length >= 3) {
      searchUsers();
    }
  }, [query]);

  const searchUsers = async () => {
    if (query.length >= 3) {
      const req = { request: { programId: props.programId, query: query } };
      const res = await ArtefactRepository.searchUsers(req);
      const users = res.data || [];
      const list = users.map((user: any) => {
        user.location = getJoinedLocation(user);
        let badge = getBadges(user);
        badge = compact(badge);
        user.badge = badge;
        return user;
      });
      setUsers(list);
    }
  };

  const renderUserSuggestion = (user: any) => {
    return (
      <View key={user?.userId}>
        <TouchableOpacity
          onPress={() => {
            onSelectUser(user);
            setUsers([]);
          }}
        >
          <View style={styles.alignUserText}>
            <Text
              testId="user-name"
              fontWeight={FontWeight.Regular}
              textSize={TextSize.Small}
              style={styles.text}
            >
              {user.name}
            </Text>
            <Text
              testId="user-ph"
              fontWeight={FontWeight.Regular}
              textSize={TextSize.Small}
              style={styles.text}
            >
              {user?.phoneNumber || user?.phonenumber}
            </Text>
            <Text
              testId="user-badge"
              fontWeight={FontWeight.Regular}
              textSize={TextSize.Small}
              style={styles.text}
            >
              {user?.badge}
            </Text>
            <Text
              testId="user-location"
              fontWeight={FontWeight.Regular}
              textSize={TextSize.Small}
              style={styles.text}
            >
              {user?.location}
            </Text>
          </View>
        </TouchableOpacity>
      </View>
    );
  };

  const onSelectUser = (user: any) => {
    const users: any[] = selectedSubmitters;

    console.log("");

    if (
      isEmpty(find(selectedSubmitters, { userId: user?.userId })) &&
      isEmpty(find(submittersList, { userid: user?.userId }))
    ) {
      users.push(user);
      props.selectedSubmitters(users);
      setSelectedSubmitters(users);
    }
  };

  const getNewSubmitters = () => {
    return (
      selectedSubmitters &&
      selectedSubmitters.length > 0 &&
      selectedSubmitters.map((sub, index) => {
        return (
          <div className="tagBtnWrapper" key={"submitter__" + index}>
            <div className="tagBtn">
              {sub?.name}
              <TouchableOpacity
                onPress={() => {
                  handleDeleteNewSubmitter(index);
                }}
              >
                <Image
                  testId="close"
                  source={require("@socion-cordio/common/src/assets/images/close.svg")}
                  imageStyle={{
                    width: 20,
                    height: 20,
                    marginLeft: 5
                  }}
                />
              </TouchableOpacity>
            </div>
          </div>
        );
      })
    );
  };

  return (
    <>
      <div className="formGroup">
        <div
          className="submittedDateTitle"
          style={{
            fontSize: 16,
            fontFamily: FontFamily.Thin,
            fontWeight: "400",
            // color: "#231F20"
            color: colorPallete.textLight
          }}
        >
          Name
        </div>

        <div className="submittedDateField">
          <View style={styles.addsubmittercontainer}>
            <TextField
              inputStyle={
                leftSidePanelCollapse
                  ? [styles.input, styles.leftNavCollapsed]
                  : [styles.input, styles.leftNavNotCollapsed]
              }
              name={query}
              placeholder={"Enter name"}
              label=""
              value={query}
              id="name"
              handleChange={(name: string) => {
                setQuery(name);
              }}
              handleBlur={() => {}}
              editable={editable}
              noFormik
            ></TextField>

            {!users || users?.length === 0 ? null : (
              <View style={[styles.searchBoxShadow, styles.box]}>
                <ScrollView
                  style={{
                    maxHeight: 100
                  }}
                  contentContainerStyle={{
                    flexGrow: 1,
                    backgroundColor: colorPallete.darkGreyFour
                  }}
                >
                  <View>{users?.map(renderUserSuggestion)}</View>
                </ScrollView>
              </View>
            )}
          </View>
        </div>
      </div>
      {getNewSubmitters()}
    </>
  );
};

const styles = StyleSheet.create({
  addsubmittercontainer: {
    // marginVertical: 1
    width: "100%"
  },
  customInput: {
    flexDirection: "row",
    display: "flex"
  },
  leftNavCollapsed: {
    width: "100%"
  },
  leftNavNotCollapsed: {
    width: "100%"
  },
  input: {
    width: "100%",
    height: 40,
    fontFamily: FontFamily.Thin,
    color: colorPallete.textBlack,
    fontSize: 16,
    paddingVertical: 20,
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: colorPallete.cordioTaupeLight4
  },
  view: {
    zIndex: -1,
    display: "flex",
    flexDirection: "row",
    // marginTop: "0.5rem",
    justifyContent: "space-between"
  },
  label: {
    marginBottom: 0,
    marginLeft: 0
  },
  textField: {
    flexDirection: "row"
  },
  icon: {
    flexDirection: "row",
    color: colorPallete.textLight,
    fontSize: 17
  },
  textIcon: {
    fontSize: 12,
    marginLeft: 0,
    fontFamily: FontFamily.Regular
  },
  searchBoxShadow: {
    ...elevationShadowStyle(5),
    backgroundColor: colorPallete.white
  },
  box: {
    borderWidth: 0.5,
    borderColor: colorPallete.gray,
    marginTop: 0
  },

  alignUserText: {
    margin: 5,
    paddingBottom: 10
  },
  text: {
    fontSize: 14,
    fontFamily: FontFamily.Medium,
    paddingTop: 5,
    paddingHorizontal: 5
  }
});

export default AddSubmitters;
