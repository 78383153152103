import React, { forwardRef, ReactElement, useEffect, useImperativeHandle, useState } from "react";
import { View, StyleSheet, ScrollView } from "react-native";
import {
  Text,
  TextSize,
  FontWeight,
  FontFamily
} from "@socion-cordio/common/src/components/atoms/text";
import Loader from "@socion-cordio/common/src/components/atoms/loader";
import { Checkbox, CheckboxType } from "@socion-cordio/common/src/components/atoms/checkBox";
import { dataHelper } from "@socion-cordio/common/src/utils/dataHelper";
import { useTranslation } from "react-i18next";
import SearchFilterSeondary from "@socion-cordio/common/src/components/molecules/searchFilterSecondary";
import { LocalStorage } from "@socion-cordio/common/src/services/storage/storageService";
import Button, { ButtonType } from "@socion-cordio/common/src/components/atoms/button";
import { ScrollContainer } from "@socion-cordio/common/src/components/organisms/scrollWrapper";

interface Props {
  programDetails: any;
  typesList: any[];
  getSelectedTypesList: Function;
  dataFilteredHandler: Function;
  setModalVisible: any;
  setIsTypeFilterApplied: Function;
  updatedTypesList: Function;
  applyFilterHandler?: Function;
  type?: string;
}

const FilterTypes = forwardRef((props: Props, ref): ReactElement => {
  const { t } = useTranslation();
  const { typesList, getSelectedTypesList, applyFilterHandler } = props;
  const [selectedList, setSelectedList] = useState([]);
  const [typesListsArray, setTypesListsArray] = useState([]);
  const [noTypesSelect, setNoTypesSelect] = useState(false);

  useEffect(() => {
    setTypesListsArray(typesList);
  }, [typesList]);

  const selectedCheckBoxHandler = (data: any) => {
    data.isSelected = !data.isSelected;
    checkSelectedList(data);
  };

  useEffect(() => {
    checkNoTypesHandler();
  }, []);

  const checkNoTypesHandler = async () => {
    const check =
      props.type === "type"
        ? await LocalStorage.getStorage("noTypesSelected")
        : await LocalStorage.getStorage("noStatusSelected");
    check ? setNoTypesSelect(true) : setNoTypesSelect(false);
  };

  const checkSelectedList = (data: any) => {
    setNoTypesSelect(false);
    removeNoTypesLocalStorage();
    let list: any = [...selectedList];
    let isPresent = selectedList.filter((x: any) => x.serialNo === data.serialNo);
    if (isPresent.length !== 0) {
      let removeList = selectedList.filter((x) => x.serialNo !== data.serialNo);
      setSelectedList(removeList);
      getSelectedTypesList(typesList);
    } else {
      list.push(data);
      setSelectedList(list);
      getSelectedTypesList(typesList);
    }
  };

  const filterList = (value: any) => {
    let str = value.toLowerCase();
    let newList: any[] = [];
    typesList
      .filter((item) => item.name.toLowerCase().includes(str))
      .map((name) => {
        return newList.push(name);
      });
    setTypesListsArray(newList);
  };

  const noTypesSelectHandler = () => {
    let typeList = [...typesListsArray];
    let list: any = typeList.forEach((item: any) => {
      item.isSelected = false;
    });
    props.type === "type"
      ? LocalStorage.setStorage("noTypesSelected", true)
      : LocalStorage.setStorage("noStatusSelected", true);
    getSelectedTypesList([]);
  };

  const clearFilter = () => {
    let typesList = [...typesListsArray];
    let list: any = typesList.forEach((item: any) => {
      item.isSelected = false;
    });

    setTypesListsArray(typesList);
    removeNoTypesLocalStorage();
  };

  const removeNoTypesLocalStorage = () => {
    setNoTypesSelect(false);
    props.type === "type"
      ? LocalStorage.removeStorage("noTypesSelected")
      : LocalStorage.removeStorage("noStatusSelected");
  };

  return (
    <View style={styles.mainContainer}>
      {typesList === null || typesList === undefined ? (
        <Loader customLoadingContainer={styles.customLoadingContainer} />
      ) : dataHelper.isEmptyArray(typesList) ? (
        <View style={styles.noDataContainer}>
          <Text
            fontWeight={FontWeight.Thin}
            testId="headerText"
            textSize={TextSize.Small}
            textStyle={styles.headerText}
          >
            {t("common:noData")}
          </Text>
        </View>
      ) : (
        <View>
          <View style={styles.searchContainer}>
            <SearchFilterSeondary filterList={filterList} />
          </View>

          <View style={styles.locationListContainer} key={props.type + "scrollcontainer"}>
            <ScrollContainer height="290px">
              {typesListsArray?.map((prop, key) => {
                return (
                  <View key={prop.name + key}>
                    <View style={styles.checkBoxContainer} key={prop.name + key + 1}>
                      <Checkbox
                        key={prop.name + key + 2}
                        testId={"checkBox"}
                        onPress={() => selectedCheckBoxHandler(prop)}
                        selected={prop.isSelected}
                        type={CheckboxType.Square}
                        text={prop.name}
                        textSize={TextSize.Small}
                        textFontWeight={FontWeight.Regular}
                        textStyle={styles.textStyleCheckBox}
                        iconSize={16}
                      />
                    </View>
                  </View>
                );
              })}
              <View key={props.type + "View"}>
                <Checkbox
                  key={props.type + "-none-checkbox"}
                  testId={"checkBox"}
                  onPress={() => [noTypesSelectHandler(), setNoTypesSelect(!noTypesSelect)]}
                  selected={noTypesSelect}
                  type={CheckboxType.Square}
                  text={"None"}
                  textSize={TextSize.Small}
                  textFontWeight={FontWeight.Regular}
                  textStyle={styles.textStyleCheckBox}
                  iconSize={16}
                />
              </View>
            </ScrollContainer>
          </View>

          <View style={[styles.mainButtonContainer]}>
            <View style={[styles.buttonContainer, styles.alignClearButton]}>
              <Button
                type={ButtonType.Secondary}
                buttonStyles={styles.button}
                textStyles={styles.buttonFont}
                title="Clear"
                onPress={() => clearFilter()}
              />
            </View>
            <View style={styles.buttonContainer}>
              <Button
                type={ButtonType.Primary}
                buttonStyles={styles.button}
                textStyles={styles.buttonFont}
                title="Apply"
                onPress={() => applyFilterHandler()}
              />
            </View>
          </View>
        </View>
      )}
    </View>
  );
});

export default FilterTypes;

const styles = StyleSheet.create({
  mainContainer: {},
  searchContainer: {
    marginBottom: 15
  },

  // Loader
  customLoadingContainer: {
    height: 300
  },

  // Check Box LocationList
  locationListContainer: {
    height: 300
  },
  checkBoxContainer: {
    marginBottom: 8
  },
  textStyleCheckBox: {
    marginStart: 15
  },

  // No Data Container
  noDataContainer: {
    height: 200
  },
  headerText: {
    fontSize: 12,
    fontFamily: FontFamily.Medium,
    lineHeight: 17,
    fontWeight: "700"
    // marginBottom: 5
  },

  header: {
    display: "flex",
    justifyContent: "flex-end",
    flexDirection: "row"
  },
  buttonContainer: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center"
  },
  alignClearButton: {
    marginRight: 20
  },
  button: {
    width: 100,
    height: 40,
    borderRadius: 10
  },
  buttonFont: {
    fontSize: 12
  },
  mainButtonContainer: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    justifyContent: "flex-end",
    alignItems: "center"
    // position: "absolute",
    // flexDirection: "row",
    // bottom: 0,
    // right: 0
  }
});
