import React, { ReactElement, useState, useEffect } from "react";
import { ScrollView, StyleSheet, View, Linking, TouchableOpacity } from "react-native";
import {
  Text,
  TextSize,
  FontWeight,
  FontFamily
} from "@socion-cordio/common/src/components/atoms/text";
import Icon, { IconNames } from "@socion-cordio/common/src/components/atoms/icon";
import Button, { ButtonType } from "@socion-cordio/common/src/components/atoms/button";
import Modal from "@socion-cordio/common/src/components/atoms/modal";
import UserInputSecondary from "@socion-cordio/common/src/components/molecules/userInputSecondary";
import UserInputTextArea from "@socion-cordio/common/src/components/molecules/userInputTextArea";
import { colorPallete } from "@socion-cordio/common/src/assets/styles/colors";
import { Switch } from "@socion-cordio/common/src/components/atoms/switches";
import { dataHelper } from "@socion-cordio/common/src/utils/dataHelper";
import { ApiClient } from "@socion-cordio/common/src/network/apiClient";
import { topicEndPoints } from "@socion-cordio/common/src/repositories/endPoints";
import Loader from "@socion-cordio/common/src/components/atoms/loader";
import FilePicker from "@socion-cordio/common/src/components/molecules/filePicker";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { TopicsActions } from "@socion-cordio/common/src/modules/topic/actions/actions";
import { toast, ToastContainer } from "react-toastify";
import { HTTP_STATUS_CODES } from "@socion-cordio/common/src/network/constants";
import LoaderCircle from "@socion-cordio/common/src/components/atoms/loaderCircle";
import formatBytes from "@socion-cordio/common/src/utils/sizeConvertor";
import AddTelemetryService from "@socion-cordio/common/src/services/telemetryService";
import Moment from "moment";
import { LocalStorage } from "@socion-cordio/common/src/services/storage/storageService";
import { GenerateQR } from "@socion-cordio/common/src/components/atoms/generateQR";
import { DownloadQR } from "@socion-cordio/common/src/components/atoms/downloadQR";
import { QR } from "@socion-cordio/common/src/components/atoms/qrCode";
import TopicDetailsContentList from "@socion-cordio/common/src/components/organisms/topic/topicsDetailsContentList";
import { CONFIG } from "@socion-cordio/common/src/config/envConstant";

interface Props {
  onSubmit?: (data: any) => void;
  topicId?: any;
  modalVisible: boolean;
  setModalVisible?: Function;
  userRoles?: any;
  isSuperAdmin?: any;
  selectedTopicDetails?: Object;
}

const commonTelemetryData = {
  timestamp: Moment().toLocaleString(),
  createdAt: Moment().toLocaleString(),
  deleted: "false",
  updatedAt: Moment().toLocaleString()
};

export default function TopicDetailsModal(props: Props): ReactElement {
  const { topicId, modalVisible, setModalVisible, userRoles, isSuperAdmin, selectedTopicDetails } =
    props;
  const [loading, setLoading] = useState<boolean>(true);
  const [failed, setFailed] = useState<boolean>(false);
  const [mainLoader, setMainLoader] = useState<boolean>(true);
  const [topicDetails, setTopicDetails] = useState(null);
  const [status, setStatus] = useState<boolean>(false);
  const [sessionLinked, setSessionLinked] = useState<boolean>(true);
  const [publishForTopicLibrary, setPublishForTopicLibrary] = useState<boolean>(false);
  const [topicName, setTopicName] = useState("");
  const [description, setDescription] = useState("");
  const [isEditable, setIsEditable] = useState<boolean>(false);
  const [topicDocument, setTopicDocument] = useState("");
  const programDetails: any = JSON.parse(localStorage.getItem("programDetails"));
  const userDetails: any = JSON.parse(localStorage.getItem("user"));
  const location: any = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    getTopicDetails();
    const requestId = 123;
    var url =
      CONFIG.REACT_APP_API_URL +
      "v1/requests/request_id?fields=items[].authorised-actions,items[].assignees";
    url = url.replace("request_id", requestId ? requestId : this.state.requestId);
    updateTelemetryTopicHandler(selectedTopicDetails, "Access Topic");
  }, []);

  const getTopicDetails = async () => {
    try {
      const payload = {
        topicIds: [topicId]
        // topicIds: [1194]
      };
      const { response } = await ApiClient.post(topicEndPoints.getTopicDetails(), payload);
      const [detailsResponse] = response;
      let status = detailsResponse.status === "ACTIVE" ? true : false;
      setStatus(status);
      setSessionLinked(detailsResponse.sessionLinked);
      setPublishForTopicLibrary(detailsResponse.publishForTopicLibrary);
      setTopicName(detailsResponse.name);
      setDescription(detailsResponse.description);
      setTopicDetails(detailsResponse);
      setLoading(false);
      setMainLoader(false);
      // setFailed(false);
    } catch (error) {
      setLoading(false);
      setMainLoader(false);
      setFailed(true);
    }
  };
  const editTopicDetialsHandler = () => {
    setIsEditable(true);
  };

  const submitTopicDetailsHandler = async () => {
    const topicDetailsBody = {
      name: topicName,
      description: description,
      programId: topicDetails.programId,
      id: topicDetails.id,
      sessionLinked: topicDetails.sessionLinked,
      createdAt: topicDetails.creationDate,
      entityId: programDetails.program.entity.id,
      userName: userDetails.name,
      publishedForTopicLibrary: publishForTopicLibrary
      // publishedForTopicLibrary, check again for confirmation
    };
    if (status === false) {
      const newBody = { ...topicDetailsBody, deleted: true };
      updateTelemetryDetactivateTopic(topicDetailsBody);
      submitTopicDetailsApiCallHandler(newBody);
    } else {
      const newBody = { ...topicDetailsBody, deleted: false };
      submitTopicDetailsApiCallHandler(newBody);
    }
  };

  const submitTopicDetailsApiCallHandler = async (detailsData: any) => {
    try {
      const addTopicResponse = await ApiClient.put(
        topicEndPoints.updateTopicDetails(topicId),
        detailsData
      );
      if (addTopicResponse !== undefined || addTopicResponse !== null) {
        setModalVisible();
        getTopics();
        updateTelemetryTopicHandler(addTopicResponse, "Topic edited");
        toast.success("Topic details updated successfully.");
      }
    } catch (error) {
      toast.error("Unauthorized: Not allowed to updated.");
    }
  };

  const updateTelemetryTopicHandler = async (data: any, eventType: string) => {
    const body = {
      ...commonTelemetryData,
      eventType: eventType,
      topicName: data.name,
      topicId: data.id,
      timestamp: Moment().toLocaleString()
    };
    AddTelemetryService(body, programDetails, userDetails);
  };

  const updateTelemetryDetactivateTopic = async (data: any) => {
    const body = {
      ...commonTelemetryData,
      eventType: "Topic Deactivated",
      topicName: data.name,
      topicId: data.id,
      baseLocationLatitude: userDetails.baseLocationLat,
      baseLocationLongitude: userDetails.baseLocationLong,
      timestamp: Moment().toLocaleString()
    };
    AddTelemetryService(body, programDetails, userDetails);
  };

  const getTopics = () => {
    const payload = {
      programId: location?.state?.programId
    };
    dispatch(TopicsActions.getTopics(payload));
  };

  const handleCustomChange = async (e: any) => {
    const validTypes = ["mp4", "mpeg", "flv", "png", "jpg", "jpeg", "pdf", "mov"];
    const validTypesImage = ["png", "jpg", "jpeg"];
    const validTypesDocs = ["pdf"];
    const validTypesVideo = ["mp4", "mpeg", "flv", "mov"];

    const splitFileName = e?.currentTarget?.files[0]?.name.split(".");
    const extension = splitFileName[splitFileName.length - 1];

    if (e.target.validity.valid && validTypes.includes(extension)) {
      setLoading(true);
      let formData: FormData = new FormData();
      formData.append("appType", "pda");
      formData.append("file", e?.currentTarget?.files[0]);
      formData.append("fileName", e?.currentTarget?.files[0]?.name);
      if (validTypesImage.includes(extension)) {
        formData.append("fileType", "content/image");
      }
      if (validTypesDocs.includes(extension)) {
        formData.append("fileType", "content/document");
      }
      if (validTypesVideo.includes(extension)) {
        formData.append("fileType", "content/video");
      }
      const uploadData = {
        contentType: extension,
        size: e?.currentTarget?.files[0].size,
        name: e?.currentTarget?.files[0].name,
        // url: response?.response
        topicId: topicDetails.id,
        programId: programDetails.program.id,
        programName: programDetails.program.name,
        userName: userDetails.name
      };
      if (validTypesVideo.includes(extension)) {
        videoUpload(uploadData, formData);
      } else {
        try {
          let response = await ApiClient.put(topicEndPoints.fileUpload(), formData);
          if (response.responseCode === HTTP_STATUS_CODES.ok) {
            const fileUploadData = {
              ...uploadData,
              url: response?.response
            };
            setTopicDocument(response?.response);
            apiCall(fileUploadData);
          } else {
            toast.error(response.message);
          }
        } catch (error) {
          toast.error("Something went wrong");
        }
      }
    } else {
      toast.error("Something went wrong");
    }
  };

  const videoUpload = async (uploadData: any, formData: any) => {
    let response = await ApiClient.post(topicEndPoints.fileUploadVideo(), formData);
    if (response.responseCode === HTTP_STATUS_CODES.ok) {
      const fileUploadData = {
        ...uploadData,
        vimeoUrl: response?.response
        // check once with URL.
      };
      // setTopicDocument(response?.response);
      apiCall(fileUploadData);
    } else {
      toast.error(response.message);
    }
  };

  const apiCall = async (uploadData: any) => {
    try {
      let response = await ApiClient.post(topicEndPoints.fileUploadResponse(), uploadData);
      if (response !== null || response !== undefined) {
        toast.success("Document uploaded successfully");
        setLoading(false);
        getTopicDetails();
        let eventType = "Content added";
        updateTelemetryContentAdd(response, eventType);
      }
    } catch (error) {
      toast.error("Something went wrong.");
    }
  };

  const updateTelemetryContentAdd = async (contentData: any, eventType: string) => {
    const programDetails: any = await LocalStorage.getStorage("programDetails");
    const userDetails: any = await LocalStorage.getStorage("user");
    const body: any = {
      ...commonTelemetryData,
      topicName: topicDetails.name,
      topicId: topicDetails.id,
      contentFileName: contentData.name || contentData.fileName,
      contentType: contentData.contentType || contentData.fileType,
      contentUrl: contentData.contentUrl,
      contentId: contentData.id || contentData.contentId,
      sizeOfContent: contentData.size || contentData.fileSize,
      eventType: eventType,
      entityId: null,
      entityName: null
    };
    AddTelemetryService(body, programDetails, userDetails);
  };

  const deleteTopicHandler = async () => {
    const programDetails: any = await LocalStorage.getStorage("programDetails");
    const topicId = topicDetails.id;
    // const body = {
    const entityId = programDetails.program.entity.id;
    const programId = topicDetails.programId;
    // };
    const response = await ApiClient.delete(
      topicEndPoints.deleteTopic(topicId, entityId, programId),
      {}
    );
  };

  return (
    <View>
      <Modal
        modalVisible={modalVisible}
        setModalVisible={() => setModalVisible()}
        component={
          <View style={styles.container}>
            {mainLoader ? (
              <Loader />
            ) : failed ? (
              <View>Failed to load data</View>
            ) : (
              <>
                <ToastContainer />
                <View style={styles.header}>
                  <Text
                    fontWeight={FontWeight.Bold}
                    testId="addRoleText"
                    textSize={TextSize.Small}
                    textStyle={styles.headerText}
                  >
                    Topic details
                  </Text>
                  <View>
                    <Icon
                      testID="close"
                      name={IconNames.crossCircle}
                      customStyle={styles.crossCircle}
                      onPress={() => setModalVisible()}
                    />
                  </View>
                </View>

                <View>
                  {console.log(topicDetails, "topicDetails")}
                  <View style={styles.topicDetailsContainer}>
                    {!isEditable && (
                      <View style={styles.qrCodeContainer}>
                        <View style={styles.qrCodeAlign}>
                          <View style={styles.qrCode}>
                            <View style={styles.qrCode} nativeID="generateQR">
                              <View style={styles.displayQR} nativeID="displayQR">
                                Entity: {programDetails.program.entity.name}
                                <br />
                                Program: {programDetails.program.name}
                                <br />
                                Topic: {topicDetails?.name}
                              </View>
                              <View nativeID="divider"> </View>
                              <GenerateQR
                                id="qrCode"
                                size={150}
                                value={"id:" + topicDetails?.id + ", QRtype: TOPIC"}
                              />
                            </View>
                            {/* <View style={styles.qrCodeImage}>
                              <QR value={'"id":"detailsResponse.id", "QRtype":"TOPIC"'} />
                            </View> */}
                          </View>
                        </View>
                        <View style={styles.footerQrCode}>
                          <Icon
                            onPress={() => {}}
                            testID="download"
                            name={IconNames.downloadList}
                            customStyle={styles.titleIcon}
                          />
                          <View style={styles.qrDownloadText}>
                            <Text
                              onPress={() => DownloadQR({ id: "generateQR" })}
                              fontWeight={FontWeight.Light}
                              testId="addRoleText"
                              textSize={TextSize.Small}
                              textStyle={[styles.headerText, styles.headerTextQrSupport]}
                            >
                              Download QR
                            </Text>
                          </View>
                        </View>
                      </View>
                    )}
                    <View style={styles.topicDetials}>
                      {" "}
                      {!isEditable && (
                        <View>
                          <View style={styles.topicHeaderAlignmnet}>
                            <Text
                              fontWeight={FontWeight.Light}
                              testId="addRoleText"
                              textSize={TextSize.Small}
                              textStyle={[styles.headerText, styles.headerAlignment]}
                            >
                              Topic name
                            </Text>
                          </View>
                          <View style={styles.topicNameHeaderAlignment}>
                            <Text
                              fontWeight={FontWeight.Regular}
                              testId="addRoleText"
                              textSize={TextSize.Small}
                              textStyle={[styles.headerText, { fontFamily: FontFamily.Bold }]}
                            >
                              {topicName}
                            </Text>
                          </View>
                          <View style={styles.topicHeaderAlignmnet}>
                            <Text
                              fontWeight={FontWeight.Light}
                              testId="addRoleText"
                              textSize={TextSize.Small}
                              textStyle={[styles.headerText, styles.headerAlignment]}
                            >
                              Description
                            </Text>
                          </View>
                          <View style={styles.topicNameHeaderAlignment}>
                            <Text
                              fontWeight={FontWeight.Light}
                              testId="addRoleText"
                              textSize={TextSize.Small}
                              textStyle={[styles.headerText]}
                            >
                              {description}
                            </Text>
                          </View>
                        </View>
                      )}
                      {isEditable && (
                        <View>
                          <View style={styles.userInputContainer}>
                            <UserInputSecondary
                              textValue="Topic name"
                              handleChange={(name: string) => {
                                setTopicName(name);
                              }}
                              handleBlur={() => {}}
                              value={topicName}
                              placeholder="Enter name"
                              name="enterTopicName"
                              id="enterTopicName"
                              noFormik
                              userStyle={styles.inputStylingAlignment}
                              userStyleText={styles.textHeaderField}
                              textInputContainerStyles={{ marginLeft: 15 }}
                            />
                          </View>
                          <View style={styles.userInputContainer}>
                            <UserInputTextArea
                              textValue="Description"
                              label="Description"
                              placeholder="Description"
                              name="Description"
                              id="description"
                              handleChange={(description: string) => {
                                setDescription(description);
                              }}
                              handleBlur={() => {}}
                              value={description}
                              numberOfLines={5}
                              multiline={true}
                              noFormik
                              userStyle={styles.inputStylingAlignment}
                              userStyleText={styles.textHeaderField}
                            />
                          </View>
                        </View>
                      )}
                      <View style={(styles.topicHeaderAlignmnet, styles.subHeaderAlignment)}>
                        <View
                          style={
                            !isEditable
                              ? styles.dateCreatedContainer
                              : { flexDirection: "row", flex: 50, marginTop: 5 }
                          }
                        >
                          <Text
                            fontWeight={FontWeight.Light}
                            testId="addRoleText"
                            textSize={TextSize.Small}
                            textStyle={[
                              styles.headerText,
                              styles.headerAlignment,
                              styles.fontSizeText,
                              { marginRight: 10 }
                            ]}
                          >
                            Created on
                          </Text>
                          <Text
                            fontWeight={FontWeight.Light}
                            testId="addRoleText"
                            textSize={TextSize.Small}
                            textStyle={[styles.headerText, styles.fontSizeText]}
                          >
                            {dataHelper.formatDate(topicDetails?.creationDate, "DD MMM YYYY")}

                            {/* 01 Jan 2021 */}
                          </Text>
                        </View>
                        {((!props?.isSuperAdmin && props?.userRoles?.includes("PROGRAM_ADMIN")) ||
                          props?.userRoles?.includes("ENTITY_ADMIN") ||
                          props?.userRoles?.includes("CONTENT_ADMIN")) && (
                          <View style={[styles.toggleButtonContainer]}>
                            {sessionLinked && (
                              <View style={styles.radioButtonContainer}>
                                <Text
                                  fontWeight={FontWeight.Light}
                                  testId="addRoleText"
                                  textSize={TextSize.Small}
                                  textStyle={[
                                    styles.headerText,
                                    styles.toggleButtonText,
                                    { width: 50, margin: 0 }
                                  ]}
                                >
                                  Status
                                </Text>
                                <Switch
                                  isOn={status}
                                  onColor="#B7504A"
                                  offColor="#F1DCDB"
                                  labelStyle={{ color: "black", fontWeight: "900" }}
                                  size="medium"
                                  onToggle={() => setStatus((status) => !status)}
                                />
                                <Text
                                  fontWeight={FontWeight.Light}
                                  testId="addRoleText"
                                  textSize={TextSize.Small}
                                  textStyle={[
                                    styles.headerText,
                                    styles.toggleButtonText,
                                    { width: 60 }
                                  ]}
                                >
                                  {status ? "Active" : "Deactive"}
                                </Text>
                              </View>
                            )}
                            {!sessionLinked && (
                              <View style={styles.deleteTopicContainerMain}>
                                <TouchableOpacity onPress={() => deleteTopicHandler()}>
                                  <View style={styles.deleteTopicContainer}>
                                    <Icon
                                      testID="edit"
                                      name={IconNames.deleteFile}
                                      customStyle={[styles.alignButton]}
                                    />
                                    <Text
                                      fontWeight={FontWeight.Light}
                                      testId="addRoleText"
                                      textSize={TextSize.Small}
                                      textStyle={[
                                        styles.headerText,
                                        styles.headerAlignment,
                                        styles.fontSizeText,
                                        styles.alignHeader
                                      ]}
                                    >
                                      {"Delete Topic"}
                                    </Text>
                                  </View>
                                </TouchableOpacity>
                              </View>
                            )}
                          </View>
                        )}
                      </View>
                      {
                        // topicDetails?.topic_library_enabled &&
                        ((!props?.isSuperAdmin && props?.userRoles?.includes("PROGRAM_ADMIN")) ||
                          props?.userRoles?.includes("ENTITY_ADMIN") ||
                          props?.userRoles?.includes("CONTENT_ADMIN")) &&
                          status &&
                          !dataHelper.isEmptyArray(topicDetails?.contentFiles) && (
                            <View style={[styles.alignContainer, styles.alignContent]}>
                              <View style={styles.alignInnerContent}>
                                <Text
                                  fontWeight={FontWeight.Light}
                                  testId="addRoleText"
                                  textSize={TextSize.Small}
                                  textStyle={[styles.headerText, styles.headerAlignment]}
                                >
                                  Add to topic library
                                </Text>
                                <Icon
                                  testID="edit"
                                  name={IconNames.about}
                                  customStyle={[styles.alignButton]}
                                />
                              </View>
                              <View style={[styles.radioButtonContainer, styles.alignSwitch]}>
                                <View style={styles.radioButtonContainer}>
                                  <Text
                                    fontWeight={FontWeight.Light}
                                    testId="addRoleText"
                                    textSize={TextSize.Small}
                                    textStyle={[
                                      styles.headerText,
                                      styles.toggleButtonText,
                                      { width: 50, margin: 0 }
                                    ]}
                                  >
                                    {""}
                                  </Text>
                                  <Switch
                                    isOn={publishForTopicLibrary}
                                    onColor="#B7504A"
                                    offColor="#F1DCDB"
                                    labelStyle={{ color: "black", fontWeight: "900" }}
                                    size="medium"
                                    onToggle={() =>
                                      setPublishForTopicLibrary(
                                        (publishForTopicLibrary) => !publishForTopicLibrary
                                      )
                                    }
                                  />
                                  <Text
                                    fontWeight={FontWeight.Light}
                                    testId="addRoleText"
                                    textSize={TextSize.Small}
                                    textStyle={[
                                      styles.headerText,
                                      styles.toggleButtonText,
                                      { width: 60 }
                                    ]}
                                  >
                                    {""}
                                  </Text>
                                </View>
                              </View>
                            </View>
                          )
                      }
                    </View>
                  </View>
                </View>
                {((!props?.isSuperAdmin && props?.userRoles?.includes("PROGRAM_ADMIN")) ||
                  props?.userRoles?.includes("ENTITY_ADMIN") ||
                  props?.userRoles?.includes("CONTENT_ADMIN")) &&
                  status &&
                  topicDetails?.contentUpload && (
                    <>
                      <View style={styles.fileUploaderContainer}>
                        <View style={loading ? { width: "92%" } : { width: "100%" }}>
                          <FilePicker
                            customDocsContainer={styles.customDocsContainer}
                            onChange={async (e) => {
                              await handleCustomChange(e);
                            }}
                          />
                        </View>
                        {loading && (
                          <View style={styles.customLoader}>
                            <LoaderCircle />
                          </View>
                        )}
                      </View>
                    </>
                  )}

                {!dataHelper.isEmptyArray(topicDetails?.contentFiles) && (
                  <>
                    <TopicDetailsContentList
                      getTopicDetails={getTopicDetails}
                      updateTelemetryContentAdd={updateTelemetryContentAdd}
                      topicDetails={topicDetails}
                      status={status}
                      userRoles={props.userRoles}
                    />
                  </>
                )}
                {((!props?.isSuperAdmin && props?.userRoles?.includes("PROGRAM_ADMIN")) ||
                  props?.userRoles?.includes("ENTITY_ADMIN") ||
                  props?.userRoles?.includes("CONTENT_ADMIN")) && (
                  <View style={[styles.alignButtonContainer]}>
                    {!isEditable && (
                      <Button
                        type={ButtonType.Secondary}
                        buttonStyles={styles.submitbutton}
                        textStyles={{ fontSize: 12 }}
                        title="Edit"
                        onPress={editTopicDetialsHandler}
                        disabled={!status}
                      />
                    )}
                    {isEditable && (
                      <Button
                        type={ButtonType.Primary}
                        buttonStyles={styles.submitbutton}
                        textStyles={{ fontSize: 12 }}
                        title="Save changes"
                        onPress={submitTopicDetailsHandler}
                        // disabled={true}
                      />
                    )}
                  </View>
                )}
              </>
            )}
          </View>
        }
      ></Modal>
    </View>
  );
}

const styles = StyleSheet.create({
  displayQR: {
    display: "none",
    color: colorPallete.textLight
  },
  container: {
    width: 655
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row",
    marginBottom: 5
  },
  headerText: {
    fontSize: 12,
    fontFamily: FontFamily.Medium,
    lineHeight: 17,
    marginBottom: 5
  },
  alignHeader: {
    marginBottom: 0,
    marginLeft: 10
  },
  headerAlignment: {
    color: colorPallete.textLight,
    fontFamily: FontFamily.Regular
  },
  fontSizeText: {
    fontSize: 10
  },
  crossCircle: {
    fontSize: 15
  },

  topicDetailsContainer: {
    flexDirection: "row",
    // marginTop: 15,
    marginBottom: 5
  },

  //QR Code scss
  qrCodeContainer: {
    flex: 25,
    // marginLeft: 10,
    marginRight: 10,
    alignItems: "center"
  },
  qrCodeAlign: {},
  qrCode: {
    flex: 100,
    // width: 130,
    maxWidth: 130,
    minHeight: 130,
    backgroundColor: colorPallete.cordioBeige
  },
  qrCodeImage: {
    top: 0,
    width: 160,
    height: 160,
    transform: [{ scale: 1.3 }]
  },
  footerQrCode: {
    width: 130,
    // marginTop: 10,
    // marginLeft: 10,
    // marginRight: 10,
    flexDirection: "row",
    justifyContent: "space-between"
  },
  titleIcon: {
    fontSize: 15
  },
  qrDownloadText: {},
  headerTextQrSupport: {
    fontWeight: "400",
    textDecorationLine: "underline",
    marginLeft: 10
  },

  topicDetials: {
    flex: 75,
    marginLeft: 10,
    marginRight: 10
  },
  userInputContainer: {
    marginTop: 2
  },
  inputStylingAlignment: {
    width: "100%",
    fontSize: 12
    // font
  },
  textHeaderField: {
    flex: 15,
    fontSize: 12
  },
  topicHeaderAlignmnet: {
    marginBottom: 0
  },
  topicNameHeaderAlignment: {
    marginBottom: 5
  },
  subHeaderAlignment: {
    flexDirection: "row",
    marginTop: 10
  },
  dateCreatedContainer: {
    flex: 50
  },
  toggleButtonContainer: {
    width: "20%",
    justifyContent: "center",
    flex: 50
  },
  toggleButtonText: {
    // alignSelf: "center",
    fontSize: 10,
    marginLeft: 10,
    marginRight: 10
  },

  alignContainer: {
    // alignItems: "center",
    marginTop: 15
  },
  alignContent: {
    flexDirection: "row",
    alignItems: "center",
    marginTop: 10
  },
  alignInnerContent: {
    flexDirection: "row",
    flex: 50
  },
  alignButtonContainer: {
    marginTop: 10,
    // marginBottom: 10,
    justifyContent: "center",
    alignSelf: "center"
  },
  submitbutton: {
    width: "140px",
    height: "50px",
    borderRadius: 10
  },
  alignButton: {
    fontSize: 15,
    color: colorPallete.cordioTaupe,
    marginLeft: 15
    // marginLeft: 30
  },
  radioButtonContainer: {
    flex: 50,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-around"
    // alignItems: "center"
  },
  deleteTopicContainerMain: {
    flexDirection: "row",
    justifyContent: "center"
  },
  deleteTopicContainer: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center"
  },
  alignSwitch: {},

  //upload button
  uploadDocsContainer: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row",
    alignItems: "center",
    backgroundColor: colorPallete.lightGrey,
    borderRadius: 5,
    borderColor: colorPallete.lightGrey,
    height: "64px",
    padding: 15,
    marginTop: 0
  },
  uploadDocIcon: {
    opacity: 0.5
  },

  //File Uploader
  fileUploaderContainer: {
    flexDirection: "row",
    marginTop: 5,
    marginBottom: 5
  },
  customDocsContainer: {
    marginTop: 0
  },
  customLoader: {
    justifyContent: "center",
    alignItems: "center",
    marginTop: 11
  },
  subcontenBtn: {
    fontSize: 18,
    color: colorPallete.cordioTaupe,
    marginRight: 5,
    marginLeft: 5
  }
});
