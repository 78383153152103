import React, { ReactElement, useState, useEffect } from "react";
import { StyleSheet, TouchableOpacity, View } from "react-native";
import { toast, ToastContainer } from "react-toastify";
import { colorPallete } from "@socion-cordio/common/src/assets/styles/colors";
import {
  Text,
  TextSize,
  FontWeight,
  FontFamily
} from "@socion-cordio/common/src/components/atoms/text";
import AesUtil from "@socion-cordio/common/src/utils/encryptionHelper";
import { ApiClient } from "@socion-cordio/common/src/network/apiClient";
import { HTTP_STATUS_CODES } from "@socion-cordio/common/src/network/constants";
import {
  springshedEndPoints,
  programEndPoints
} from "@socion-cordio/common/src/repositories/endPoints";
import Icon, { IconNames } from "@socion-cordio/common/src/components/atoms/icon";
import Button, { ButtonType } from "@socion-cordio/common/src/components/atoms/button";
import { Checkbox, CheckboxType } from "@socion-cordio/common/src/components/atoms/checkBox";
import { auxiliaryMethods } from "@socion-cordio/common/src/utils/auxiliaryMethods";
import MobileInput from "@socion-cordio/common/src/components/molecules/mobileInput";
import { Image } from "@socion-cordio/common/src/components/atoms/image";
import { LocalStorage } from "@socion-cordio/common/src/services/storage/storageService";
import { QR } from "@socion-cordio/common/src/components/atoms/qrCode";
import { QrCodeScannerModal } from "@socion-cordio/common/src/components/molecules/qrCodeScannerModal";
import Moment from "moment";
import AddTelemetryService from "@socion-cordio/common/src/services/telemetryService";

interface Props {
  getProgramMembers?: Function;
  onClose?: Function;
  getUserRoles?: Function;
}

export default function AddProgramMemberModal(props: Props): ReactElement {
  let aesUtil: AesUtil = new AesUtil();
  const [mobileNumber, setMobileNumber] = useState("");
  const [countryCode, setCountryCode] = useState("");
  const [selectedUser, setSelectedUser] = useState(undefined);
  const [programData, setProgramData] = useState(undefined);
  const [userData, setuserData] = useState(undefined);
  const [programCheckbox, setProgramCheckbox] = useState(false);
  const [contentCheckbox, setContentCheckbox] = useState(false);
  const [trainerCheckbox, setTrainerCheckbox] = useState(false);
  const [verifierCheckbox, setVerifierCheckbox] = useState(false);
  const [qrCodeUserDetails, setQrCodeUserDetails] = useState(false);
  const [modalLoading, setModalLoading] = useState(false);
  const [isProgramCheckboxPrefilled, setIsProgramCheckboxPrefilled] = useState(false);
  const [isContentCheckboxPrefilled, setIsContentCheckboxPrefilled] = useState(false);
  const [isTrainerCheckboxPrefilled, setTrainerCheckboxPrefilled] = useState(false);
  const [isVerifierCheckboxrefilled, setVerifierCheckboxPrefilled] = useState(false);
  const [selectedUserRoles, setSelectedUserRoles] = useState([]);
  const [roles, setRoles] = useState([]);
  const [validate, setValidate] = useState([
    {
      label: "+91",
      phoneNumberLength: 10,
      phoneNumberSizeMax: 10,
      phoneNumberSizeMin: 10,
      value: 91
    }
  ]);
  const [minLength, setMinLength] = useState(10);
  const [maxLength, setMaxLength] = useState(10);

  useEffect(() => {
    if (validate[0]?.phoneNumberSizeMax != validate[0]?.phoneNumberSizeMin) {
      setMinLength(validate[0]?.phoneNumberSizeMin);
      setMaxLength(validate[0]?.phoneNumberSizeMax);
    } else {
      setMinLength(10);
      setMaxLength(10);
    }
  }, [validate]);

  useEffect(() => {
    getProgramDetails();
  }, []);

  const getProgramDetails = async () => {
    const programDetails: any = await LocalStorage.getStorage("programDetails");
    const userDetails: any = await LocalStorage.getStorage("user");
    setProgramData(programDetails);
    setuserData(userDetails);
  };

  const handleMobileChange = (mobileNumber: string) => {
    setMobileNumber(mobileNumber);
  };

  const handleCountryCodeChange = (countryCode: string) => {
    setCountryCode(countryCode);
  };

  const getSelecteduserRoles = async (userId: any) => {
    const getSelectedMemberRoleResponse = await ApiClient.get(
      programEndPoints.getSelectedMemberRole(userId, programData.program.id)
    );
    setSelectedUserRoles(getSelectedMemberRoleResponse?.response);
    if (getSelectedMemberRoleResponse.response.includes("PROGRAM_ADMIN")) {
      setProgramCheckbox(true);
      setIsProgramCheckboxPrefilled(true);
    }
    if (getSelectedMemberRoleResponse.response.includes("CONTENT_ADMIN")) {
      setContentCheckbox(true);
      setIsContentCheckboxPrefilled(true);
    }
    if (getSelectedMemberRoleResponse.response.includes("TRAINER")) {
      setTrainerCheckbox(true);
      setTrainerCheckboxPrefilled(true);
    }
    if (getSelectedMemberRoleResponse.response.includes("VERIFIER")) {
      setVerifierCheckbox(true);
      setVerifierCheckboxPrefilled(true);
    }
  };

  const getUserDetails = async () => {
    const payload = {
      phoneNumbers: [
        {
          phoneNumber: mobileNumber,
          countryCode: `+${countryCode.toString()}`
        }
      ]
    };
    const getUserDetailsResponse = await ApiClient.post(
      springshedEndPoints.getProfileFromPhone(),
      payload
    );
    if (getUserDetailsResponse.responseCode === HTTP_STATUS_CODES.ok) {
      if (getUserDetailsResponse.response.length > 0) {
        const keys = Object.keys(getUserDetailsResponse.response[0]);
        setSelectedUser(getUserDetailsResponse.response[0][keys[0]]);
        getSelecteduserRoles(getUserDetailsResponse.response[0][keys[0]].userId);
      } else {
        toast.error(getUserDetailsResponse?.message);
      }
    } else {
      if (getUserDetailsResponse.responseCode === HTTP_STATUS_CODES.notFound) {
        toast.error(getUserDetailsResponse?.message);
      } else {
        toast.error("Something went Wrong!");
      }
    }
  };

  const handleAddMember = async () => {
    let rolesList = [];
    if (programCheckbox && !isProgramCheckboxPrefilled) {
      rolesList[0] = "PROGRAM_ADMIN";
    }
    if (contentCheckbox && !isContentCheckboxPrefilled) {
      rolesList[1] = "CONTENT_ADMIN";
    }
    if (trainerCheckbox && !isTrainerCheckboxPrefilled) {
      rolesList[2] = "TRAINER";
    }
    if (verifierCheckbox && !isVerifierCheckboxrefilled) {
      rolesList[3] = "VERIFIER";
    }
    let list = rolesList.filter((item) => item);
    if (
      isProgramCheckboxPrefilled &&
      isContentCheckboxPrefilled &&
      isTrainerCheckboxPrefilled &&
      isVerifierCheckboxrefilled
    ) {
      toast.error("Member already Added!!");
    } else {
      list.length !== 0
        ? submitAddMemberHandler(list)
        : toast.error("Please Enter Atleast One Role.");
    }
  };

  const updateTelemetryAddProgramMember = async (data: any) => {
    const user: any = await LocalStorage.getStorage("user");
    const programDetails: any = await LocalStorage.getStorage("programDetails");

    const body = {
      eventType: "Add Program Member",
      timestamp: Moment().toLocaleString(),
      createdAt: Moment().toLocaleString(),
      deleted: "false",
      updatedAt: Moment().toLocaleString(),
      sessionStartDate: new Date(programDetails.program.startDate)
        .toISOString()
        .replace("T", " ")
        .replace("Z", ""),
      sessionEndDate: new Date(programDetails.program.endDate)
        .toISOString()
        .replace("T", " ")
        .replace("Z", ""),
      memberId: user.userId,
      userId: selectedUser.userId,
      programRole: data,
      baseLocationCity: selectedUser?.city,
      baseLocationDistrict: selectedUser?.district,
      baseLocationSubDistrict: selectedUser?.subDistrict,
      baseLocationState: selectedUser?.state,
      baseLocationCountry: selectedUser?.country,
      baseLocationLatitude: selectedUser?.latitude,
      baseLocationLongitude: selectedUser?.longitude,
      establishmentName: selectedUser?.establishmentName
    };
    AddTelemetryService(body, programDetails, user);
  };

  const submitAddMemberHandler = async (list: any[]) => {
    const payload = {
      roles: list,
      userId: selectedUser.userId,
      programId: programData.program.id
    };
    try {
      const MemberStatusResponse = await ApiClient.post(
        programEndPoints.addProgramMember(),
        payload
      );
      if (MemberStatusResponse.responseCode === 200) {
        list.forEach((row: any) => {
          updateTelemetryAddProgramMember(row);
        });
      }

      if (MemberStatusResponse.responseCode === 400) {
        toast.success("Member already present in this program role");
      } else {
        toast.success(MemberStatusResponse.message);
      }
      props.onClose();
      props.getProgramMembers();
      props.getUserRoles();
      setTimeout(() => {
        window.location.reload();
      }, 1000);
      // toast.success(MemberStatusResponse.message);
    } catch (error) {
      toast.error("Something went Wrong!");
    }
  };

  const getScannedUserHandler = (userData: object) => {
    setSelectedUser(userData);
    getSelecteduserRoles(userData.userId);
    setModalLoading(false);
    setQrCodeUserDetails(false);
  };

  return (
    <>
      <ToastContainer />
      {!selectedUser && (
        <View>
          <View style={styles.container}>
            <View style={styles.header}>
              <Text
                fontWeight={FontWeight.Bold}
                testId="addRoleText"
                textSize={TextSize.Small}
                textStyle={styles.headerText}
              >
                Add Member
              </Text>
              <View>
                <Icon
                  testID="close"
                  name={IconNames.crossCircle}
                  customStyle={styles.crossCircle}
                  onPress={() => props.onClose()}
                />
              </View>
            </View>
            <View style={[styles.alignContainer]}>
              <Text
                fontWeight={FontWeight.Bold}
                testId="addRoleText"
                textSize={TextSize.Small}
                textStyle={styles.headerText}
              >
                {programData?.program?.name}
              </Text>
            </View>
            <View style={styles.alignTextField}>
              <View style={{ flexDirection: "row" }}>
                <Text
                  fontWeight={FontWeight.Regular}
                  testId="qualificationTitleText"
                  textSize={TextSize.Small}
                  textStyle={styles.subheaderText}
                >
                  Mobile number
                </Text>
                <MobileInput
                  dropdownPlaceholder="+91"
                  label=""
                  handleChange={(mobile: string) => handleMobileChange(mobile)}
                  onChangeValue={(countryCode: string) => handleCountryCodeChange(countryCode)}
                  handleBlur={() => {}}
                  name="mobileNumber"
                  placeholder="Enter mobile number"
                  dropdownValue={countryCode}
                  value={mobileNumber}
                  id="mobileNumber"
                  containerStyle={styles.containerStyle}
                  noFormik
                  setValidate={setValidate}
                  dropDownContainerStyle={styles.dropDownContainerStyle}
                />
                <View style={styles.errorContainer}>
                  <Text
                    fontWeight={FontWeight.Regular}
                    testId="internal"
                    textSize={TextSize.Small}
                    textStyle={[styles.subheaderText, styles.errorText]}
                  >
                    {auxiliaryMethods.handleValidatePhoneNumber(mobileNumber, minLength, maxLength)}
                  </Text>
                </View>
                {mobileNumber && mobileNumber.length > 2 && (
                  <View style={styles.iconContainer}>
                    <TouchableOpacity>
                      <Icon
                        name={IconNames.close}
                        customStyle={styles.iconStyle}
                        onPress={() => setMobileNumber("")}
                      />
                    </TouchableOpacity>
                    <TouchableOpacity>
                      <Icon
                        name={IconNames.tick}
                        customStyle={styles.iconStyle}
                        onPress={() => getUserDetails()}
                      />
                    </TouchableOpacity>
                  </View>
                )}
              </View>
            </View>
            <View style={[styles.textFieldContainer]}>
              <Text
                fontWeight={FontWeight.Regular}
                testId="addRoleText"
                textSize={TextSize.Small}
                textStyle={styles.headerText}
              >
                OR
              </Text>
            </View>
            <View style={[styles.alignTextField, styles.alignScanButton]}>
              <Button
                type={ButtonType.Primary}
                buttonStyles={styles.submitbutton}
                title="Scan QR code"
                onPress={() => {
                  setQrCodeUserDetails(true), setModalLoading(true);
                }}
              />
            </View>
            {qrCodeUserDetails && (
              <View>
                <QrCodeScannerModal
                  onCloseScannerModal={() => setQrCodeUserDetails(!qrCodeUserDetails)}
                  modalLoading={modalLoading}
                  getScannedUserHandler={getScannedUserHandler}
                />
              </View>
            )}
          </View>
        </View>
      )}

      {selectedUser && (
        <>
          <View style={styles.header}>
            <Icon
              testID="close"
              name={IconNames.crossCircle}
              customStyle={styles.crossIcon}
              onPress={() => props.onClose()}
            />
          </View>
          <View style={styles.subContainer}>
            <View style={styles.profilePictureContainer}>
              <View style={styles.profilePicture}>
                <Image
                  imageStyle={styles.profilePicture}
                  testId="socionImg"
                  source={
                    selectedUser?.photo === ""
                      ? require("@socion-cordio/common/src/assets/images/user_circle.svg")
                      : selectedUser?.photo
                  }
                />
              </View>
            </View>
            <View style={styles.memberInfoContainer}>
              <Text
                fontWeight={FontWeight.Bold}
                testId="addRoleText"
                textSize={TextSize.Small}
                textStyle={styles.headerText}
              >
                {selectedUser.name}
              </Text>
              <View style={styles.userDetailsContainer}>
                <Text
                  fontWeight={FontWeight.Regular}
                  testId="addRoleText"
                  textSize={TextSize.Small}
                  textStyle={[styles.alignText]}
                >
                  {"Contact Number : "}
                </Text>
                <Text
                  fontWeight={FontWeight.Regular}
                  testId="addRoleText"
                  textSize={TextSize.Small}
                  textStyle={[styles.alignText]}
                >
                  {selectedUser.piiInfo
                    ? `${selectedUser.countryCode} ${selectedUser.phoneNumber}`
                    : `NA`}
                </Text>
              </View>
              <View style={styles.userDetailsContainer}>
                <Text
                  fontWeight={FontWeight.Regular}
                  testId="addRoleText"
                  textSize={TextSize.Small}
                  textStyle={[styles.alignText]}
                >
                  {"Contact Email : "}
                </Text>
                <Text
                  fontWeight={FontWeight.Regular}
                  testId="addRoleText"
                  textSize={TextSize.Small}
                  textStyle={[styles.alignText]}
                >
                  {selectedUser.piiInfo
                    ? selectedUser.emailId !== ""
                      ? selectedUser.emailId
                      : ""
                    : `NA`}
                </Text>
              </View>
              <Text
                fontWeight={FontWeight.Regular}
                testId="addRoleText"
                textSize={TextSize.Small}
                textStyle={[styles.alignText]}
              >
                Base location :
              </Text>
              <Text
                fontWeight={FontWeight.Regular}
                testId="addRoleText"
                textSize={TextSize.Small}
                textStyle={[styles.alignText, styles.textColor]}
              >
                {auxiliaryMethods.fromatBaseLocation(selectedUser) === ""
                  ? "None"
                  : auxiliaryMethods.fromatBaseLocation(selectedUser)}
              </Text>
            </View>
            <View style={styles.qrContainer}>
              <QR value={aesUtil.encrypt(selectedUser.userId)} />
            </View>
          </View>

          <View style={styles.checkboxContainer}>
            <Checkbox
              testId=""
              selected={isProgramCheckboxPrefilled ? true : programCheckbox}
              type={CheckboxType.Square}
              text="Program administrator"
              textSize={TextSize.Large}
              textFontWeight={FontWeight.Regular}
              textStyle={styles.checkboxProgram}
              onPress={() => [
                isProgramCheckboxPrefilled ? null : setProgramCheckbox(!programCheckbox)
              ]}
            />
            <Checkbox
              testId=""
              selected={contentCheckbox}
              type={CheckboxType.Square}
              text="Content administrator"
              textSize={TextSize.Large}
              textFontWeight={FontWeight.Regular}
              textStyle={styles.checkboxProgram}
              onPress={() => [
                isContentCheckboxPrefilled ? null : setContentCheckbox(!contentCheckbox)
              ]}
            />
            <Checkbox
              testId=""
              selected={trainerCheckbox}
              type={CheckboxType.Square}
              text="Trainer"
              textSize={TextSize.Large}
              textFontWeight={FontWeight.Regular}
              textStyle={styles.checkboxTrainer}
              onPress={() => [
                isTrainerCheckboxPrefilled ? null : setTrainerCheckbox(!trainerCheckbox)
              ]}
            />
            <Checkbox
              testId=""
              selected={verifierCheckbox}
              type={CheckboxType.Square}
              text="Verifier"
              textSize={TextSize.Large}
              textFontWeight={FontWeight.Regular}
              textStyle={styles.checkboxTrainer}
              onPress={() => [
                isVerifierCheckboxrefilled ? null : setVerifierCheckbox(!verifierCheckbox)
              ]}
            />
          </View>
          <View style={styles.buttonContainer}>
            <Button
              type={ButtonType.Primary}
              buttonStyles={styles.button}
              textStyles={styles.buttonFont}
              title="Add member"
              onPress={handleAddMember}
            />
          </View>
        </>
      )}
    </>
  );
}

const styles = StyleSheet.create({
  container: {
    width: 655
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row"
  },
  headerText: {
    fontSize: 14,
    fontFamily: FontFamily.Medium,
    lineHeight: 17
  },
  crossCircle: {
    fontSize: 15
  },
  alignContainer: {
    alignItems: "center",
    marginTop: 32
  },
  userInputContainer: {
    width: "450px"
  },
  textHeaderField: {
    flex: 30
  },
  inputStylingAlignment: {
    width: "100%"
  },
  alignTextFieldWidth: {
    width: "25%"
  },
  alignTextWidth: {
    flex: 15
  },
  submitbutton: {
    width: "140px",
    height: "50px",
    borderRadius: 10
  },
  memberInfoContainer: {
    flex: 80
  },
  iconStyle: {
    color: colorPallete.cordioTaupe,
    marginLeft: 5,
    marginRight: 5,
    fontSize: 15
  },
  iconContainer: {
    flexDirection: "row",
    marginTop: 13,
    justifyContent: "center"
  },
  subheaderText: {
    fontWeight: "400",
    fontSize: 14,
    fontFamily: FontFamily.Regular,
    lineHeight: 17,
    color: colorPallete.textLight,
    marginTop: 13,
    marginRight: 35
  },
  alignTextField: {
    alignItems: "center",
    marginVertical: 25
  },
  textFieldContainer: {
    alignItems: "center",
    marginTop: 10,
    zIndex: -1
  },
  subContainer: {
    flexDirection: "row",
    marginBottom: 25,
    width: "680px"
  },
  profilePictureContainer: {
    flex: 20,
    alignItems: "center"
  },
  profilePicture: {
    borderRadius: 54,
    width: " 80px",
    height: "80px"
  },
  alignText: {
    fontSize: 14,
    color: colorPallete.textLight,
    marginTop: 10,
    FontFamily: FontFamily.Medium,
    fontWeight: "400"
  },
  textColor: {
    color: colorPallete.textBlack,
    fontFamily: FontFamily.Regular,
    fontWeight: "700"
  },
  button: {
    width: "140px",
    height: "50px",
    borderRadius: 10
  },
  buttonFont: {
    fontSize: 12
  },
  buttonContainer: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "40px"
  },
  checkboxContainer: {
    marginTop: 15,
    flexDirection: "row"
  },
  checkboxProgram: {
    paddingLeft: 10,
    fontSize: 14,
    width: "130px",
    lineHeight: "17px",
    marginTop: "15px"
  },
  checkboxTrainer: {
    paddingLeft: 10,
    fontSize: 14,
    width: "80px"
  },
  crossIcon: {
    fontSize: 15,
    marginLeft: 700,
    marginBottom: 49
  },
  qrContainer: {
    marginRight: 20
  },
  loader: {
    height: 400,
    paddingTop: 350
  },
  alignContent: {
    paddingLeft: "500px"
  },
  containerStyle: {
    borderColor: colorPallete.cordioTaupe,
    borderWidth: 1,
    borderTopLeftRadius: 5,
    borderBottomLeftRadius: 5,
    height: 40,
    marginBottom: 0
  },
  errorContainer: {
    position: "absolute",
    top: 41,
    marginLeft: 145,
    zIndex: -1
  },
  errorText: {
    marginTop: 2,
    justifyContent: "center",
    color: colorPallete.cordioRed,
    fontSize: 12,
    marginRight: 0
  },
  alignScanButton: {
    zIndex: -1,
    marginTop: 25,
    marginBottom: 25
  },
  userDetailsContainer: {
    flexDirection: "row"
  },
  dropDownContainerStyle: {
    marginTop: 10,
    width: 303,
    zIndex: 10
  }
});
