import React from "react";
import { StyleProp, StyleSheet, TouchableOpacity, View, ViewStyle } from "react-native";
import {
  Text,
  TextSize,
  FontWeight,
  FontFamily
} from "@socion-cordio/common/src/components/atoms/text";
import Icon, { IconNames } from "@socion-cordio/common/src/components/atoms/icon";
import { colorPallete } from "@socion-cordio/common/src/assets/styles/colors";

interface Props {
  textValue: any;
  data: any;
  functionOfButton: Function;
  functionOfButtonProp: any;
}

const CancelButton = (props: Props) => {
  const { textValue, data, functionOfButton, functionOfButtonProp } = props;

  return (
    <TouchableOpacity>
      <View style={styles.filterButtonsContainer}>
        <View>
          <Text
            testId="testId"
            fontWeight={FontWeight.Regular}
            textSize={TextSize.Small}
            style={[styles.textAlignmentFilterButtons]}
          >
            {textValue}
          </Text>
        </View>
        <View>
          <TouchableOpacity
            style={styles.alignCancelButton}
            onPress={() => functionOfButton(data, functionOfButtonProp)}
          >
            <Icon name={IconNames.crossCircle} customStyle={styles.iconStyleFilter} />
          </TouchableOpacity>
        </View>
      </View>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  filterButtonsContainer: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    padding: 5,
    borderColor: colorPallete.cordioTaupe,
    borderStyle: "solid",
    borderWidth: 1,
    borderRadius: 5,
    minWidth: 80,
    maxWidth: 300,
    minHeight: 40,
    marginRight: 10,
    marginBottom: 5
  },
  textAlignmentFilterButtons: {
    fontSize: 12,
    fontFamily: FontFamily.Medium,
    minWidth: 80,
    maxWidth: 200
  },
  alignCancelButton: {
    marginLeft: 5
  },
  iconStyleFilter: {
    color: colorPallete.textBlack,
    fontSize: 14
  }
});

export default CancelButton;
