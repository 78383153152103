import { Login } from "@socion-cordio/common/src/models/login";
import { LOGIN } from "@socion-cordio/common/src/modules/login/actions/actionTypes";

export interface ILoginLoading {
  postCredentials: boolean;
}

export interface ILoginError {
  postCredentials: string;
}
export interface ILoginState {
  loginData: Login;
  error: ILoginError;
  loading: ILoginLoading;
}

export const loginInitialState: ILoginState = {
  loginData: null,

  error: {
    postCredentials: ""
  },
  loading: { postCredentials: false }
};

const loginReducer = (
  state = loginInitialState,
  action: { payload: any; type: string }
): ILoginState => {
  switch (action.type) {
    case LOGIN.POST_CREDENTIALS.LOADING:
      return {
        ...state,
        loading: { ...state.loading, postCredentials: true },
        error: { ...state.error, postCredentials: null }
      };
    case LOGIN.POST_CREDENTIALS.SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, postCredentials: false },
        error: { ...state.error, postCredentials: null },
        loginData: action.payload
      };
    case LOGIN.POST_CREDENTIALS.ERROR:
      return {
        ...state,
        loading: { ...state.loading, postCredentials: false },
        error: { ...state.error, postCredentials: action.payload }
      };
    default:
      return state;
  }
};
export default loginReducer;
