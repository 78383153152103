import { deserialize, serialize } from "@socion-cordio/common/src/mappers/apiResMapper";
import { Profile } from "@socion-cordio/common/src/models/profile";
import { UserProfile } from "@socion-cordio/common/src/models/userProfile";
import { UserRole } from "@socion-cordio/common/src/models/userRole";
import { UserQualification } from "@socion-cordio/common/src/models/userQualification";
import { ApiClient } from "@socion-cordio/common/src/network/apiClient";
import { profileEndPoints } from "@socion-cordio/common/src/repositories/endPoints";
import { EligibleProgramsForRole } from "@socion-cordio/common/src/models/eligibleProgramsForRole";
import { EligibleProgramsForQualification } from "@socion-cordio/common/src/models/eligibleProgramsForQualification";

class ProfileRepository {
  fetchProfile = async (param: { emailId: string }): Promise<Profile> => {
    let data = await ApiClient.get(profileEndPoints.userProfile(), param);
    const profileData: Profile = deserialize(Profile, data);
    return profileData;
  };
  getUserProfile = async (): Promise<UserProfile> => {
    let data = await ApiClient.get(profileEndPoints.getUserProfile());
    // const response: UserProfile = deserialize(UserProfile, data);
    return data;
  };
  updateProfile = async (data: Profile): Promise<Profile> => {
    const serializedBody = serialize(Profile, data);
    let updatedProfile = await ApiClient.put(profileEndPoints.userProfile(), serializedBody);
    const profileData: Profile = deserialize(Profile, updatedProfile);
    return profileData;
  };
  uploadFile = async (data: FormData) => {
    let res = await ApiClient.post(profileEndPoints.uploadFile(), data);
    return res;
  };
  getUserRole = async (): Promise<UserRole> => {
    let data = await ApiClient.get(profileEndPoints.getUserRole());
    if (data.responseCode === 200) {
      data = deserialize(UserRole, data);
    }
    return data;
  };
  getUserQualification = async (): Promise<UserQualification> => {
    try {
      let data = await ApiClient.get(profileEndPoints.getUserQualification());
      if (data.responseCode === 200) {
        data = deserialize(UserQualification, data);
      }
      return data;
    } catch (err) {
      console.log("errr", err);
    }
  };
  getUserEligiblePrgramsForRole = async (param: {
    acceptingType: string;
  }): Promise<EligibleProgramsForRole> => {
    let data = await ApiClient.get(profileEndPoints.getUserEligibleProgramsForRole(), param);
    if (data.responseCode === 200) {
      data = deserialize(EligibleProgramsForRole, data);
    }
    return data;
  };
  getUserEligiblePrgramsForQualification = async (param: {
    acceptingType: string;
  }): Promise<EligibleProgramsForQualification> => {
    let data = await ApiClient.get(
      profileEndPoints.getUserEligibleProgramsForQualification(),
      param
    );
    if (data.responseCode === 200) {
      data = deserialize(EligibleProgramsForQualification, data);
    }
    return data;
  };
}
const profileRepository = new ProfileRepository();
export { profileRepository as ProfileRepository };
