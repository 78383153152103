import React, { ReactElement, useEffect, useState, useMemo } from "react";
import { View, StyleSheet, TouchableOpacity } from "react-native";
import {
  Text,
  TextSize,
  FontWeight,
  FontFamily
} from "@socion-cordio/common/src/components/atoms/text";
import Table from "@socion-cordio/web/src/components/molecules/table/table";
import Loader from "@socion-cordio/common/src/components/atoms/loader";
import { colorPallete } from "@socion-cordio/common/src/assets/styles/colors";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { ProgressBar } from "@socion-cordio/common/src/components/atoms/progressBar";
import { allRoutesNames as routeNames } from "@socion-cordio/web/src/navigation/allRouteNames";

interface Props {
  metrics?: any;
  qualificationPackDetails?: any;
}

export default function QualificationViewDetailsTable(props: Props): ReactElement {
  const { metrics, qualificationPackDetails } = props;
  const [qualificationPack, setQualificationPack] = useState(null);
  const [loading, setLoading] = useState(true);
  const [failed, setFailed] = useState(false);
  const history: any = useHistory();
  useEffect(() => {
    convertMetrics();
  }, [metrics]);

  const metricsSum = () => {
    // to get the total sum in the metrics
    return Object.values(metrics)?.reduce((a: any, b: any) => a + b);
  };

  const convertingArrayToObjectArray = () => {
    // retuns array of array with the metrics data
    const metricsToArray = Object.entries(metrics);
    const arrayToObjectArray: any = [];
    metricsToArray.forEach((element) => {
      let tempObj = {};
      tempObj = Object.assign({}, element);
      arrayToObjectArray.push(tempObj);
    });
    return arrayToObjectArray;
  };

  const assigningValues = (arrayToObjectArray: any, sumValues: number) => {
    // looping to add the statement, no. of participant and
    // total percentage value to the remaining list.
    arrayToObjectArray?.forEach((obj: any, index: number) => {
      obj.statement = `${index} out of ${arrayToObjectArray.length} Selections Completed`;
      obj.noOfParticipants = `${arrayToObjectArray[index]["1"]}`;
      obj.percentage = `${sumValues}`;
    });
    return arrayToObjectArray;
  };

  const assignValueToRemovedMetric = (lastMetric: any, sumValues: number) => {
    // Assigning values to removed metric
    // and the no. of participant as key given by the response.
    lastMetric = {
      statement: "All Selections Completed",
      noOfParticipants: lastMetric[0]?.[1],
      percentage: `${sumValues}`
    };
    return lastMetric;
  };

  const convertMetrics = () => {
    try {
      // to get the total sum in the metrics
      let sumValues: any = 0;
      if (metrics.length !== 0) {
        sumValues = metricsSum();
      }
      const arrayToObjectArray: any = convertingArrayToObjectArray();

      // removing last element as to display on 'All selection completed"
      let lastMetric = arrayToObjectArray.splice(-1);
      const assignValuesList = assigningValues(arrayToObjectArray, sumValues);
      lastMetric = assignValueToRemovedMetric(lastMetric, sumValues);

      // looping list to get add the serial no.
      let list = assignValuesList.concat(lastMetric);

      const updatedList = listSelectionNoHandler(list);

      setQualificationPack(updatedList);
      setLoading(false);
    } catch (error) {
      toast.error("Something went wrong.");
      setFailed(true);
    }
  };

  const listSelectionNoHandler = (list: any[]) => {
    list.reverse();
    list?.forEach((obj: any, index: number) => {
      obj.serialNo = `${index + 1}.`;
      obj.selectionNo = `${index}`;
    });
    const newList = [...list];
    newList.reverse();
    newList?.forEach((obj: any, index: number) => {
      obj.selectionNoUpdated = `${index}`;
    });
    const updateNewList = [...newList];
    return updateNewList.reverse();
  };

  const navigivateViewDetailsHandler = (qualificationPack: any) => {
    history.push(
      `${routeNames.app}${routeNames.QUALIFICATIONPACKS}${routeNames.VIEWPARTICIPANTMAPPING}`,
      {
        packDetails: qualificationPack,
        qualificationPackDetails: qualificationPackDetails
      }
    );
  };

  const cellData = (testId: string, style: any, data: string, onPress?: Function) => (
    <Text
      fontWeight={FontWeight.Light}
      testId={testId}
      textSize={TextSize.Small}
      style={[
        {
          fontSize: 12,
          fontFamily: FontFamily.Regular,
          fontWeight: FontFamily.Light,
          textSize: TextSize.Small
        },
        style
      ]}
      onPress={onPress ? () => onPress() : null}
    >
      {data}
    </Text>
  );

  const columns = useMemo(
    () => [
      {
        Header: "Sr.No",
        accessor: "serialNo",
        width: 30,
        maxWidth: 30,
        disableSortBy: true,
        Cell: ({ row: { original: qualiPack } }: { row: { original: any } }) => {
          const style = {};
          return cellData("serialNo", style, qualiPack?.serialNo);
        }
      },
      {
        Header: " ",
        accessor: "name",
        width: 140,
        maxWidth: 140,
        disableSortBy: true,
        Cell: ({ row: { original: qualiPack } }: { row: { original: any } }) => {
          const style = {};
          return cellData("name", style, qualiPack?.statement);
        }
      },
      {
        Header: "Participants",
        accessor: "noOfParticipants",
        width: 65,
        maxWidth: 65,
        Cell: ({ row: { original: qualiPack } }: { row: { original: any } }) => {
          const style = {
            fontFamily: FontFamily.Medium,
            fontWeight: FontFamily.Bold,
            textDecorationLine: "underline"
          };
          const onPress = () => {
            navigivateViewDetailsHandler(qualiPack);
          };
          return cellData("noOfParticipants", style, qualiPack?.noOfParticipants, onPress);
        }
      },
      {
        Header: "Completion percentage",
        accessor: "percentage",
        width: 145,
        maxWidth: 145,
        Cell: ({ row: { original: qualiPack } }: { row: { original: any } }) => (
          <View>
            {qualiPack.percentage === null && <View></View>}
            {qualiPack.percentage && (
              <View style={styles.progressBarContainer}>
                <ProgressBar
                  progressBarStyles={styles.progressBarStyles}
                  percentage={(+qualiPack.noOfParticipants / +qualiPack.percentage) * 100}
                />
                <Text
                  onPress={() => {}}
                  fontWeight={FontWeight.Regular}
                  testId="text"
                  textSize={TextSize.Small}
                  textStyle={[styles.headerText, styles.headerTextSupport]}
                >
                  {((+qualiPack.noOfParticipants / +qualiPack.percentage) * 100).toFixed(2) + " %"}
                </Text>
              </View>
            )}
          </View>
        )
      }
    ],
    []
  );

  return (
    <View>
      {loading ? (
        <Loader />
      ) : failed ? (
        <View style={styles.noFailedDataContainer}>Failed to load data</View>
      ) : (
        <View>
          <Table columns={columns} data={qualificationPack} />
        </View>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  roleName: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center"
  },
  image: {
    width: 25,
    height: 25,
    borderRadius: 25 / 2,
    marginEnd: 10
  },
  mainHeaderText: {
    fontSize: 12,
    fontFamily: FontFamily.Bold,
    lineHeight: 17,
    fontWeight: "700",
    paddingLeft: 25,
    marginTop: 10,
    marginBottom: 10
  },
  addRoleButton: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    padding: 10
  },
  statusContainer: {
    flexDirection: "row",
    alignItems: "center"
  },
  statusColor: {
    height: 8,
    width: 8,
    borderRadius: 50,
    marginRight: 8
  },
  activeColor: {
    backgroundColor: colorPallete.cordioGreen
  },
  inactiveColor: {
    backgroundColor: colorPallete.cordioOrange
  },
  textAlign: {
    fontFamily: FontFamily.Regular,
    color: colorPallete.textBlack,
    fontSize: 10
  },
  noFailedDataContainer: {
    padding: 25
  },
  progressBarStyles: {
    width: "73%"
  },
  progressBarContainer: {
    flexDirection: "row",
    alignItems: "center"
  },
  headerText: {
    fontSize: 12,
    fontFamily: FontFamily.Medium
  },
  headerTextSupport: {
    marginLeft: 10
  }
});
