import React, { ReactElement, useState, useEffect } from "react";
import { StyleSheet, View, Pressable } from "react-native";
import { colorPallete } from "@socion-cordio/common/src/assets/styles/colors";
import {
  Text,
  TextSize,
  FontWeight,
  FontFamily
} from "@socion-cordio/common/src/components/atoms/text";
import Icon, { IconNames } from "@socion-cordio/common/src/components/atoms/icon";
import Button, { ButtonType } from "@socion-cordio/common/src/components/atoms/button";
import { useSelector } from "react-redux";
import MobileInput from "@socion-cordio/common/src/components/molecules/mobileInput";
import OtpValidate from "@socion-cordio/common/src/components/molecules/otpValidate";
import { profileEndPoints } from "@socion-cordio/common/src/repositories/endPoints";
import { ApiClient } from "@socion-cordio/common/src/network/apiClient";
import { HTTP_STATUS_CODES } from "@socion-cordio/common/src/network/constants";
import { toast } from "react-toastify";
interface Props {
  onClose: Function;
}

export default function AddMobile(props: Props): ReactElement {
  const profileState = useSelector((state: any) => state.profile);
  const {
    userProfileData: { response }
  } = profileState;
  const [otpHandle, setOtpHandle] = useState(false);
  const [mobileNumber, setMobileNumber] = useState(null);
  const [countryCode, setCountryCode] = useState(response.countryCode);
  const [phoneNumberDetails, setPhoneNumberDetails] = useState(null);
  const [error, setError] = useState(false);
  const [incorrectMobileMsg, setIncorrectMobileMsg] = useState(null);
  const [customError, setCustomError] = useState("");
  const [validate, setValidate] = useState([
    {
      label: "+91",
      phoneNumberLength: 10,
      phoneNumberSizeMax: 10,
      phoneNumberSizeMin: 10,
      value: 91
    }
  ]);
  const [minLength, setMinLength] = useState(10);
  const [maxLength, setMaxLength] = useState(10);

  const sendOtpNewPhone = async () => {
    const sendOtpNewPhoneResponse = await ApiClient.get(
      profileEndPoints.sendOtpNewPhone(mobileNumber, `+${countryCode}`)
    );
    if (sendOtpNewPhoneResponse.responseCode === HTTP_STATUS_CODES.ok) {
      setOtpHandle(true);
      toast.success("Mobile number updated successfully");
      setPhoneNumberDetails({ countryCode: countryCode, mobileNumber: mobileNumber });
    } else {
      setIncorrectMobileMsg(sendOtpNewPhoneResponse?.message);
    }
  };

  useEffect(() => {
    if (validate[0]?.phoneNumberSizeMax != validate[0]?.phoneNumberSizeMin) {
      setMinLength(validate[0]?.phoneNumberSizeMin);
      setMaxLength(validate[0]?.phoneNumberSizeMax);
    } else {
      setMinLength(10);
      setMaxLength(10);
    }
  }, [validate]);

  const handleMobileNumber = (mobileNumber: string) => {
    // const mobilePattern = /^\d{10}$/;
    const regex = /^(0*[1-9][0-9]*(\.[0-9]*)?|0*\.[0-9]*[1-9][0-9]*)$/;
    let errorMessage;
    if (!regex.test(mobileNumber)) {
      setError(true);
      errorMessage = "Invalid characters";
    } else if (minLength == maxLength && mobileNumber.length != 10) {
      setError(true);
      errorMessage = "The phone number should be of 10 digits";
    } else if (minLength !== maxLength && mobileNumber.length > 20) {
      setError(true);
      errorMessage = `The phone number should be between ${minLength} to ${maxLength} digits.`;
    } else {
      setError(false);
      errorMessage = "";
    }
    setCustomError(errorMessage);
    setMobileNumber(mobileNumber);
    setIncorrectMobileMsg(null);
  };

  return (
    <View>
      {!otpHandle && (
        <View>
          <View style={styles.header}>
            <Text
              fontWeight={FontWeight.Bold}
              testId="addRoleText"
              textSize={TextSize.Small}
              textStyle={styles.headerText}
            >
              Add new mobile number
            </Text>
            <View>
              <Pressable onPress={() => props.onClose()}>
                <Icon testID="close" name={IconNames.crossCircle} />
              </Pressable>
            </View>
          </View>
          <View style={styles.subContainerTwo}>
            <View style={styles.addNewNumberContainer}>
              <View>
                <Text
                  fontWeight={FontWeight.Regular}
                  testId="addRoleTitleText"
                  textSize={TextSize.Small}
                  textStyle={[styles.subheaderText, styles.mobileNumberHeaderText]}
                >
                  Mobile number
                </Text>
              </View>
              <MobileInput
                handleChange={(mobileNumber: string) => handleMobileNumber(mobileNumber)}
                onChangeValue={(countryCode: number) => setCountryCode(countryCode)}
                handleBlur={() => {}}
                inputStyle={styles.mobileInput}
                name="mobileNumber"
                placeholder="Enter number"
                id="mobileNumber"
                dropdownPlaceholder="+91"
                noFormik
                setValidate={setValidate}
                dropDownContainerStyle={styles.dropDownContainerStyle}
              />
            </View>
            {error && (
              <Text
                testId="emailError"
                textSize={TextSize.Small}
                fontWeight={FontWeight.Regular}
                textStyle={styles.error}
              >
                {customError}
              </Text>
            )}
            {!error && incorrectMobileMsg && (
              <Text
                testId="incorrectEmail"
                textSize={TextSize.Small}
                fontWeight={FontWeight.Regular}
                textStyle={styles.error}
              >
                {incorrectMobileMsg}
              </Text>
            )}
            <View style={styles.submitButtonContainer}>
              <Button
                type={ButtonType.Primary}
                buttonStyles={styles.submitbutton}
                title="Save"
                onPress={sendOtpNewPhone}
                disabled={error || !mobileNumber}
              />
            </View>
          </View>
        </View>
      )}
      {otpHandle && (
        <OtpValidate
          onClose={props.onClose}
          modalTitle="Add new mobile number"
          headerText="Please enter the code sent to the new mobile number"
          newPhoneOtp={true}
          sendOtpNewPhone={sendOtpNewPhone}
          phoneNumberDetails={phoneNumberDetails}
        />
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  header: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row"
  },
  headerText: {
    fontWeight: "700",
    fontSize: 14,
    fontFamily: FontFamily.Medium,
    lineHeight: 17
  },
  subContainerTwo: {
    marginTop: 15
  },
  subheaderText: {
    fontWeight: "400",
    fontSize: 14,
    fontFamily: FontFamily.Regular,
    lineHeight: 17,
    color: colorPallete.textBlack,
    marginTop: 13,
    flex: 20
  },
  submitButtonContainer: {
    alignItems: "center",
    marginTop: 30,
    marginBottom: 10,
    zIndex: -1
  },
  submitbutton: {
    width: "175px",
    height: "50px",
    borderRadius: 10
  },
  addNewNumberContainer: {
    display: "flex",
    flexDirection: "row",
    flex: 100,
    justifyContent: "center"
  },
  mobileNumberHeaderText: {
    marginTop: 12,
    marginRight: 10
  },
  mobileInput: {
    width: "250px"
  },
  error: {
    position: "relative",
    color: "red",
    alignSelf: "flex-start",
    fontSize: 10,
    fontWeight: "400",
    zIndex: -1
  },
  dropDownContainerStyle: {
    marginTop: 10,
    width: 303,
    zIndex: 10
  }
});
