import React from "react";
import { TouchableOpacity, View } from "react-native";
import { Image } from "@socion-cordio/common/src/components/atoms/image";
import jsonToCsv from "@socion-cordio/common/src/utils/jsonToCsvDownload";
import { downloadCsvArtefactList } from "./exportToCSVHelper";

interface IProps {
  header: any;
  tableData: any;
  fileName: string;
}

export default function DownloadTableAsCSV(props: IProps) {
  const { header, tableData, fileName } = props;

  // console.log("header : ", header);
  // console.log("tableData : ", tableData);
  // console.log("filename : ", fileName);

  return (
    <View>
      <TouchableOpacity onPress={() => downloadCsvArtefactList(tableData)}>
        <Image
          testId="downloadAsCSV"
          source={require("@socion-cordio/common/src/assets/images/download.png")}
          imageStyle={{
            width: 20,
            height: 20
          }}
        />
      </TouchableOpacity>
    </View>
  );
}
