import { colorPallete } from "@socion-cordio/common/src/assets/styles/colors";
import Icon, { IconNames } from "@socion-cordio/common/src/components/atoms/icon";
import {
  FontFamily,
  FontWeight,
  Text,
  TextSize
} from "@socion-cordio/common/src/components/atoms/text";
import React, { useEffect, useState } from "react";
import { StyleSheet, View } from "react-native";

const Comments = (props: any) => {
  return (
    <View style={styles.commentContainer}>
      <View style={styles.headerQrContainer}>
        <View>
          <Text
            fontWeight={FontWeight.Bold}
            testId="addText"
            textSize={TextSize.Large}
            textStyle={styles.headerText}
          >
            {props?.headerTitle}
          </Text>
        </View>
        <View>
          <Icon
            testID="close"
            name={IconNames.crossCircle}
            customStyle={[styles.crossCircle, styles.alignContent]}
            onPress={() => props.onClose()}
          />
        </View>
      </View>
      <Text
        fontWeight={FontWeight.Regular}
        testId="text"
        textSize={TextSize.Large}
        style={{
          fontSize: 14,
          fontFamily: FontFamily.Medium,
          color: colorPallete.textBlack
        }}
      >
        {props?.remarks}
      </Text>
    </View>
  );
};
const styles = StyleSheet.create({
  commentContainer: {
    padding: 0,
    margin: 0,
    width: 550
  },
  headerText: {
    fontWeight: "700",
    fontSize: 14,
    fontFamily: FontFamily.Bold,
    lineHeight: 17,
    color: colorPallete.cordioRed
  },
  headerQrContainer: {
    justifyContent: "space-between",
    flexDirection: "row",
    width: "100%",
    color: colorPallete.cordioRed,
    paddingBottom: "30px"
  },
  crossCircle: {
    fontSize: 15,
    color: colorPallete.cordioRed
  },
  alignContent: {}
});
export default Comments;
