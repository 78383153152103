import { ActionHelper } from "@socion-cordio/common/src/utils/actionHelper";

const prefix = "TASKS";

const TASKS = {
  GET_TASKS: ActionHelper.actionTypesCreator(prefix, "GET_TASKS"),
  GET_TASK_DATA: ActionHelper.actionTypesCreator(prefix, "GET_TASK_DATA"),
  GET_SUBMISSIONS: ActionHelper.actionTypesCreator(prefix, "GET_SUBMISSIONS"),
  GET_SUBMITTER: ActionHelper.actionTypesCreator(prefix, "GET_SUBMITTER")
};

export { TASKS };
