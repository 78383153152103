import { colorPallete } from "@socion-cordio/common/src/assets/styles/colors";
import html2canvas from "html2canvas";
import { toast } from "react-toastify";

interface IProps {
  id?: any;
  qrTitle?: string;
}

export const DownloadQR = (props: IProps) => {
  const { qrTitle } = props;
  const canvas: any = document.getElementById("generateQR");
  html2canvas(canvas, {
    width: 220,
    backgroundColor: colorPallete.white,
    onclone: function (clone: any) {
      clone.getElementById("displayQR").style.display = "flex";
      clone.getElementById("generateQR").style.backgroundColor = colorPallete.white;
      clone.getElementById("displayQR").style.margin = "15px";
      clone.getElementById("displayQR").style.fontWeight = "bold";
      clone.getElementById("divider").style.border = "1px solid";
      clone.getElementById("divider").style.marginLeft = "15px";
      clone.getElementById("divider").style.width = "190px";
      clone.getElementById("displayQR").style.marginBottom = "20px";
      clone.getElementById("qrCode").style.width = "200px";
      clone.getElementById("qrCode").style.height = "200px";
      clone.getElementById("qrCodeContainer").style.width = "220px";
      clone.getElementById("qrCodeContainer").style.justifyContent = "center";
      clone.getElementById("displayQR").style.width = "200px";
    }
  })
    .then((dataUrl) => {
      document.getElementById("displayQR").style.display = "none";
      let downloadLink = document.createElement("a");
      downloadLink.href = dataUrl.toDataURL("image/png");
      downloadLink.download = qrTitle + `_QRCode.png`;
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    })
    .catch(function (error) {
      toast.error("Unable to download the QR");
    });
};
