import React from "react";
import { Route, Switch } from "react-router-dom";

interface Props {
  routes: any;
  basePath: string;
  isAddNotFound?: boolean;
  privateConfig?: any;
  props?: any;
  history?: any;
  translate?: any;
}

export interface IRoute {
  path: string;
  component: any;
  isExact: boolean;
  children: Array<IRoute>;
  permission: Array<string>;
}

export default function MapRoutes({
  routes,
  basePath,
  isAddNotFound,
  privateConfig,
  props,
  history
}: Props): React.ReactElement {
  return (
    // <div className="mapRtr">
    <React.Fragment>
      <Switch>
        {routes &&
          routes.map((route: IRoute, index: number) => {
            const {
              path,
              component: Component,
              isExact,
              children = [],
              permission,
              ...rest
            } = route;
            return (
              <Route
                key={`${basePath}-${path}-${index}`}
                path={basePath !== "" ? `${basePath}${path}` : path}
                exact={isExact}
                {...rest}
              >
                <Component {...props} history={history} children={children} />
              </Route>
            );
          })}
      </Switch>
    </React.Fragment>
    // </div>
  );
}
