import { Topic } from "@socion-cordio/common/src/models/topic"; //Doubt!!
import { TOPICS } from "@socion-cordio/common/src/modules/topic/actions/actionTypes";
import { IFluxStandardAction } from "@socion-cordio/common/src/store/interfaces";

const getTopics = (data: { programId: number }): IFluxStandardAction<{ programId: number }> => {
  return {
    type: TOPICS.GET_TOPICS.LOADING,
    payload: data
  };
};

const getTopicsSuccess = (data: Topic): IFluxStandardAction<Topic> => {
  data.response.forEach((topic: any, index: number) => {
    topic.serialNo = `${index + 1}.`;
  });
  return {
    type: TOPICS.GET_TOPICS.SUCCESS,
    payload: data
  };
};

const getTopicsError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: TOPICS.GET_TOPICS.ERROR,
    payload: error
  };
};

export const TopicsActions = {
  getTopics,
  getTopicsSuccess,
  getTopicsError
};
