import { colorPallete } from "@socion-cordio/common/src/assets/styles/colors";
import Button, { ButtonType } from "@socion-cordio/common/src/components/atoms/button";
import { LocalStorage } from "@socion-cordio/common/src/services/storage/storageService";
import React, { ReactElement, useEffect, useState } from "react";
import { StyleSheet, View } from "react-native";

interface IProps {
  disabled: boolean;
  buttons: string[];
  onClick: Function;
}

const ArtefactButtons = (props: IProps): ReactElement => {
  const { disabled, buttons } = props;
  const [leftSidePanelCollapse, setLeftSidePanelCollapse] = useState(null);

  const leftNavCollapseHandler = async () => {
    let isSidePanelCollapsed: boolean = await LocalStorage.getStorage("isSidePanelCollapsed");
    setLeftSidePanelCollapse(isSidePanelCollapsed);
  };

  useEffect(() => {
    leftNavCollapseHandler();
  });

  // const getBtnStyle = () => {
  //   return { width: 120 }
  // };

  const handleSubmit = (name: string) => {
    props.onClick(name);
  };

  return (
    <View style={styles.buttonContainer}>
      <Button
        type={ButtonType.Secondary}
        buttonStyles={[styles.button, { marginRight: 20 }]}
        title="Cancel"
        onPress={() => handleSubmit("Cancel")}
      />
      {buttons.includes("Save") && (
        <Button
          type={ButtonType.Primary}
          buttonStyles={
            !disabled ? [styles.button, styles.submitbutton] : [styles.button, styles.disabled]
          }
          title="Save"
          onPress={() => handleSubmit("Save")}
          disabled={disabled}
        />
      )}

      {buttons.includes("Reject") && (
        <Button
          type={ButtonType.Primary}
          buttonStyles={
            !disabled
              ? [styles.button, styles.submitbutton, { marginRight: 20 }]
              : [styles.button, { marginRight: 20 }]
          }
          title="Reject"
          onPress={() => handleSubmit("Rejected")}
          disabled={disabled}
        />
      )}

      {buttons.includes("Approve") && (
        <Button
          type={ButtonType.Primary}
          buttonStyles={!disabled ? [styles.button, styles.approveBtn] : [styles.button]}
          title="Approve"
          onPress={() => handleSubmit("Approved")}
          disabled={disabled}
        />
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  buttonContainer: {
    flexDirection: "row",
    justifyContent: "flex-end",
    width: "100%"
  },

  disabled: {
    opacity: 0.5
  },

  button: {
    borderRadius: 5,
    width: 120

    // padding: 10
  },
  submitbutton: {
    backgroundColor: colorPallete.cordioRed
  },
  approveBtn: {
    backgroundColor: colorPallete.lightGreen
  }
});

export default ArtefactButtons;
