import Icon, { IconNames } from "@socion-cordio/common/src/components/atoms/icon";
import {
  Text,
  TextSize,
  FontWeight,
  FontFamily
} from "@socion-cordio/common/src/components/atoms/text";
import { colorPallete } from "@socion-cordio/common/src/assets/styles/colors";
import React, { ReactElement, useEffect, useState } from "react";
import { View, StyleSheet, StyleProp, ViewStyle, ScrollView, TouchableOpacity } from "react-native";
import CollapseItem from "@socion-cordio/common/src/components/molecules/collapseItem";
import UserInputSecondary from "@socion-cordio/common/src/components/molecules/userInputSecondary";
import UserInput from "@socion-cordio/common/src/components/molecules/userInput";
import { ApiClient } from "@socion-cordio/common/src/network/apiClient";
import {
  programEndPoints,
  sessionPackEndPoints
} from "@socion-cordio/common/src/repositories/endPoints";
import { toast, ToastContainer } from "react-toastify";
import { LocalStorage } from "@socion-cordio/common/src/services/storage/storageService";
import { Image } from "@socion-cordio/common/src/components/atoms/image";
import AddViewRemoveMemberModal from "@socion-cordio/common/src/components/organisms/session/addViewRemoveMember";
import RemoveMemberModal from "@socion-cordio/common/src/components/organisms/common-modals/removeMember";
import { HTTP_STATUS_CODES } from "@socion-cordio/common/src/network/constants";
import Loader from "@socion-cordio/common/src/components/atoms/loader";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
interface IProps {
  icon?: IconNames;
  sessionDetails?: any;
  sessionId?: number;
  sessionMembers?: any;
  isSessionCompleted?: boolean;
  getCompleteSessionDetails?: any;
  setIsLoader?: Function;
}

export default function AddMembersPage(props: IProps): ReactElement {
  const { t } = useTranslation();
  const {
    sessionDetails,
    sessionId,
    sessionMembers,
    isSessionCompleted,
    getCompleteSessionDetails,
    setIsLoader
  } = props;
  const [userTrainerList, setUserTrainerList] = useState(null);
  const [trainers, setTrainers] = useState([]);
  const [name, setName] = useState(null);
  const [displayUserListTrainers, setdisplayUserListTrainers] = useState(false);
  const [sessionMembersData, setSessionMembersData] = useState(sessionDetails?.members || null);
  const [selectedUserList, setSelectedUserList] = useState([]);
  const [selectedUserData, setSelectedUserData] = useState(null);
  const [editSelectedUser, setEditSelectedUser] = useState(false);
  const [isEditButtonClicked, setIsEditButtonClicked] = useState(false);
  const [isRemoveMember, setIsRemoveMember] = useState(false);
  const [loadingRoles, setLoadingRoles] = useState(true);
  const [isSessionCreator, setIsSessionCreator] = useState(false);
  const [failed, setFailed] = useState(false);
  const [loadingList, setLoadingList] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const location: any = useLocation();

  useEffect(() => {
    getUserList();
    checkSessionCreator();
  }, []);

  const checkSessionCreator = async () => {
    const user: any = await LocalStorage.getStorage("user");
    const userId = user?.userId;
    const sessionCreatorId = sessionDetails?.sessionCreatorProfile.userId;
    if (userId === sessionCreatorId) {
      setIsSessionCreator(true);
    } else {
      setIsSessionCreator(false);
    }
  };

  const getUserList = async () => {
    try {
      const programDetails: any = await LocalStorage.getStorage("programDetails");
      const programId = programDetails?.program?.id;
      const response = await ApiClient.get(programEndPoints.getUserList(programId), {});
      const trainers = response?.users.filter((member: any) => member.roles.includes("TRAINER"));
      setTrainers(trainers);
      setLoadingRoles(false);
    } catch (error) {
      setLoadingRoles(false);
      toast.error("Something went wrong.");
    }
  };

  const searchUserHandler = (value: any) => {
    setName(value);
    value?.length !== 0 ? setdisplayUserListTrainers(true) : setdisplayUserListTrainers(false);
    const user: any = trainers.filter(
      (u) => u.name.toLowerCase().includes(value.toLowerCase()) || u.phoneNumber.includes(value)
    );
    setUserTrainerList(user);
  };

  const addUserHandler = (data: any) => {
    setName(""); // clear search field
    setIsEditButtonClicked(false);
    setdisplayUserListTrainers(false);
    setSelectedUserData(data); // selected user data object
    const isAlreadyExistsCheck = sessionMembersData.find((x: any) => x.userId === data.userId);
    if (isAlreadyExistsCheck) {
      toast.error("Member is already added to this session!");
    } else {
      setEditSelectedUser(true); //modal open and passing the selected data
    }
  };

  const setUserMemberRole = (roles: any) => {
    const newSelectedUserBody = {
      ...selectedUserData,
      roles
    };
    if (isEditButtonClicked === false) {
      addMemberToList(roles, newSelectedUserBody);
    }
    if (isEditButtonClicked === true) {
      const user = [...sessionMembersData];
      let index = user.findIndex((obj) => obj.userId === newSelectedUserBody.userId);
      user[index] = newSelectedUserBody;
      setSessionMembersData(user);
    }
    setIsEditButtonClicked(false);
    setEditSelectedUser(false); //modal close
  };

  const addMemberToList = async (roles: any, newMember: any) => {
    try {
      setIsLoader(true);
      setSelectedUserList([]);
      const newBody = {
        roles,
        sessionId: sessionDetails.sessionId,
        userId: newMember.userId
      };
      const response = await ApiClient.post(sessionPackEndPoints.addMemberToSession(), newBody);
      if (response?.responseCode === HTTP_STATUS_CODES.ok) {
        // update the list here, GET call
        getCompleteSessionDetails();
        // const list = selectedUserList.concat(newMember);
        // setSelectedUserList(list);
        toast.success(response.message);
        setIsLoader(false);
      } else {
        setLoadingList(false);
        toast.error(response.message);
        setIsLoader(false);
      }
    } catch (error) {
      toast.error("Something went wrong.");
      setLoadingList(false);
      setFailed(true);
      setIsLoader(false);
    }
  };

  const getSessionDetails = async () => {
    try {
      const response = await ApiClient.get(
        sessionPackEndPoints.getCompleteSessionDetails(sessionId)
      );
      if (response.responseCode === HTTP_STATUS_CODES.ok) {
        setSelectedUserList(response?.response?.members);
        setSessionMembersData([]);
        setLoadingList(false);
        setFailed(false);
      } else {
        toast.error(response?.message);
        setLoadingList(false);
        setFailed(true);
      }
    } catch (error) {
      toast.error("Something went wrong.");
      setLoadingList(false);
      setFailed(true);
    }
  };

  const formatRoles = (data: any) => {
    let bool: boolean = false;
    let formatString = "";
    let otherText = "";
    Object.fromEntries(
      Object.entries(data).filter(([key, value]) => {
        if (key === "trainer" && value === true) {
          formatString += "Trainer ";
        }
        if (key === "admin" && value === true) {
          formatString += "Admin ";
        }
        if (key === "other" && value === true) {
          bool = true;
        }
        if (bool === true) {
          if (key === "otherRoleNames" && value !== null) {
            otherText += `${value} `;
          }
        }
      })
    );
    let newString = formatString.split(" ").join(", ").replace(/,\s*$/, "");
    let finalString = newString !== "" ? `${newString},${otherText}` : otherText;
    return otherText == "" ? newString : finalString;
  };

  const editSelectedUserHandler = (data: any) => {
    setSelectedUserData(data);
    setEditSelectedUser(true); //modal open
    setIsEditButtonClicked(true);
  };

  const handleDeleteMember = async () => {
    const response = await ApiClient.delete(
      sessionPackEndPoints.deleteSessionMember(selectedUserData.userId, selectedUserData.sessionId)
    );
    if (response.responseCode === HTTP_STATUS_CODES.ok) {
      getCompleteSessionDetails();
    } else {
      toast.error(response.message);
    }
    setIsRemoveMember(false);
  };

  const deleteSelectedUserHandler = (data: any) => {
    setIsRemoveMember(true);
    setSelectedUserData(data);
  };

  const deleteMemberResponse = (val: any) => {
    try {
      if (val === true) {
        const user = [...selectedUserList];
        const removeUser = user.filter((x) => x.userId !== selectedUserData.userId);
        setSelectedUserList(removeUser);
        setIsRemoveMember(false);
      }
    } catch (error) {
      setIsRemoveMember(false);
      toast.error("Something went wrong.");
    }
  };

  useEffect(() => {
    let location = window?.location?.href;
    if (
      location?.split("/").includes("upcoming") &&
      location?.split("/").includes("view-session")
    ) {
      setIsEdit(true);
    }
  }, [sessionMembersData]);

  return (
    <View style={styles.container}>
      <CollapseItem
        headerStyles={[styles.collapseHeaderContainer]}
        isExpanded={window.location.pathname.includes("completed") ? false : true}
        headerComponent={t("session:addMembers")}
        children={
          <View style={styles.collapseContainer}>
            {loadingList ? (
              <Loader customLoadingContainer={{ height: 250 }} />
            ) : failed ? (
              <View>Failed to load data</View>
            ) : (
              <View style={styles.wrapperContainer}>
                {!isSessionCompleted &&
                  (location?.state?.completed === true ? null : (
                    <View>
                      {isSessionCreator && (
                        <View style={styles.inputContainer}>
                          {loadingRoles ? (
                            <View style={{ height: 40 }}>
                              {" "}
                              <Text
                                fontWeight={FontWeight.Light}
                                testId="text"
                                textSize={TextSize.Small}
                                textStyle={[styles.headerText, styles.headerTextTitleSupport]}
                              >
                                {t("session:loadingMemberRoles")}
                              </Text>
                            </View>
                          ) : (
                            <UserInput
                              handleChange={(value: any) => searchUserHandler(value)}
                              handleBlur={() => {}}
                              value={name}
                              placeholder={t("common:searchByNameOrMobile")}
                              name="countryInput"
                              id="countryInput"
                              userStyle={styles.inputStylingAlignment}
                              input={styles.inputStyle}
                              noFormik
                              editable={true}
                              showEditIcon={false}
                            />
                          )}
                          <View
                            style={{ position: "absolute", top: 70, width: "100%", height: 200 }}
                          >
                            <ScrollView>
                              {displayUserListTrainers &&
                                userTrainerList?.map((prop: any, key: any) => {
                                  return (
                                    <View
                                      key={key}
                                      style={{ display: "flex", flexDirection: "row" }}
                                    >
                                      {/* <View style={{ flex: 50 }}></View> */}
                                      <TouchableOpacity
                                        onPress={() => addUserHandler(prop)}
                                        style={[
                                          styles.listDropdownContainer,
                                          { backgroundColor: "white" }
                                        ]}
                                      >
                                        <View key={key}>
                                          <Text
                                            fontWeight={FontWeight.Light}
                                            testId="text"
                                            textSize={TextSize.Small}
                                            textStyle={[
                                              styles.headerText,
                                              styles.headerTextTitleSupport,
                                              { marginBottom: 2 }
                                            ]}
                                          >
                                            {prop.name}
                                          </Text>
                                          <Text
                                            fontWeight={FontWeight.Light}
                                            testId="text"
                                            textSize={TextSize.Small}
                                            textStyle={[
                                              styles.headerText,
                                              styles.headerColor,
                                              { fontSize: 10 }
                                            ]}
                                          >
                                            {prop.countryCode}
                                            {"-"}
                                            {prop.phoneNumber}
                                          </Text>
                                        </View>
                                      </TouchableOpacity>
                                    </View>
                                  );
                                })}
                            </ScrollView>
                          </View>
                        </View>
                      )}
                    </View>
                  ))}

                <View style={styles.memberContainer}>
                  <View style={styles.profilePictureContainer}>
                    <View>
                      <Image
                        testId="socion"
                        imageStyle={styles.profileImage}
                        source={sessionDetails?.sessionCreatorProfile?.photo}
                      />
                    </View>
                  </View>
                  <View style={styles.nameContainer}>
                    <Text
                      fontWeight={FontWeight.Light}
                      testId="text"
                      textSize={TextSize.Small}
                      textStyle={[styles.headerText, styles.headerTextTitleSupport]}
                    >
                      {sessionDetails?.sessionCreatorProfile?.name}
                    </Text>
                  </View>
                  <View style={styles.memberDesignationContainer}>
                    <Text
                      fontWeight={FontWeight.Light}
                      testId="text"
                      textSize={TextSize.Small}
                      textStyle={[styles.headerText, styles.headerColor, { fontSize: 10 }]}
                    >
                      {t("session:sessionCreator")}
                    </Text>
                  </View>
                </View>

                {sessionMembersData?.map((prop: any, key: React.Key) => {
                  return (
                    // <View key={key} style={styles.memberContainer}>
                    //   <View style={styles.profilePictureContainer}>
                    //     <View>
                    //       <Image
                    //         testId="socion"
                    //         imageStyle={styles.profileImage}
                    //         source={prop?.photo}
                    //       />
                    //     </View>
                    //   </View>
                    //   <View style={styles.nameContainer}>
                    //     <Text
                    //       fontWeight={FontWeight.Light}
                    //       testId="text"
                    //       textSize={TextSize.Small}
                    //       textStyle={[styles.headerText, styles.headerTextTitleSupport]}
                    //     >
                    //       {prop?.name}
                    //     </Text>
                    //   </View>
                    //   <View style={styles.memberDesignationContainer}>
                    //     <Text
                    //       fontWeight={FontWeight.Light}
                    //       testId="text"
                    //       textSize={TextSize.Small}
                    //       textStyle={[styles.headerText, styles.headerColor, { fontSize: 10 }]}
                    //     >
                    //       {formatRoles(prop?.roles)}
                    //     </Text>
                    //   </View>
                    // </View>
                    <View key={key} style={styles.userListMainContainer}>
                      {isEdit && isSessionCreator ? (
                        <View style={styles.miniIconContainer}>
                          <Icon
                            onPress={() => {
                              deleteSelectedUserHandler(prop);
                            }}
                            testID="delete"
                            name={IconNames.deleteFile}
                            customStyle={[styles.alignContainerButton]}
                          />
                          <Icon
                            onPress={() => {
                              editSelectedUserHandler(prop);
                            }}
                            testID="edit"
                            name={IconNames.edit}
                            customStyle={[styles.alignContainerButton]}
                          />
                        </View>
                      ) : null}
                      <View
                        style={{
                          flexDirection: "row",
                          flex: 1,
                          alignItems: "center",
                          marginBottom: 15
                        }}
                      >
                        <View style={styles.profilePictureContainer}>
                          <View>
                            <Image
                              testId="socion"
                              imageStyle={styles.profileImage}
                              source={prop?.photo}
                            />
                          </View>
                        </View>
                        <View style={styles.nameContainer}>
                          <Text
                            fontWeight={FontWeight.Light}
                            testId="text"
                            textSize={TextSize.Small}
                            textStyle={[styles.headerText, styles.headerTextTitleSupport]}
                          >
                            {prop.name}
                          </Text>
                        </View>
                        <View style={styles.memberDesignationContainer}>
                          <Text
                            fontWeight={FontWeight.Light}
                            testId="text"
                            textSize={TextSize.Small}
                            textStyle={[styles.headerText, styles.headerColor, { fontSize: 10 }]}
                          >
                            {formatRoles(prop?.roles)}
                          </Text>
                        </View>
                      </View>
                    </View>
                  );
                })}

                <View style={{}}>
                  <ScrollView>
                    {selectedUserList?.map((prop: any, key: React.Key) => {
                      return (
                        <View key={key} style={styles.userListMainContainer}>
                          <View
                            style={{
                              flexDirection: "row",
                              flex: 1,
                              alignItems: "center",
                              marginBottom: 15
                            }}
                          >
                            <View style={styles.profilePictureContainer}>
                              <View>
                                <Image
                                  testId="socion"
                                  imageStyle={styles.profileImage}
                                  source={prop?.photo}
                                />
                              </View>
                            </View>
                            <View style={styles.nameContainer}>
                              <Text
                                fontWeight={FontWeight.Light}
                                testId="text"
                                textSize={TextSize.Small}
                                textStyle={[styles.headerText, styles.headerTextTitleSupport]}
                              >
                                {prop.name}
                              </Text>
                            </View>
                            <View style={styles.memberDesignationContainer}>
                              <Text
                                fontWeight={FontWeight.Light}
                                testId="text"
                                textSize={TextSize.Small}
                                textStyle={[
                                  styles.headerText,
                                  styles.headerColor,
                                  { fontSize: 10 }
                                ]}
                              >
                                {formatRoles(prop?.roles)}
                              </Text>
                            </View>
                          </View>
                        </View>
                      );
                    })}
                  </ScrollView>
                </View>
              </View>
            )}

            <View>
              {editSelectedUser && (
                <AddViewRemoveMemberModal
                  modalVisible={editSelectedUser}
                  selectedUserData={selectedUserData}
                  setUserMemberRole={setUserMemberRole}
                  isEditButtonClicked={isEditButtonClicked}
                  setModalVisible={() => setEditSelectedUser(!editSelectedUser)}
                  isSave={true}
                  getCompleteSessionDetails={getCompleteSessionDetails}
                />
              )}
            </View>
            <View>
              {isRemoveMember && (
                <RemoveMemberModal
                  modalVisible={isRemoveMember}
                  selectedUserData={handleDeleteMember}
                  textValue={t("session:removeMemberFromSession")}
                  setModalVisible={() => setIsRemoveMember(!isRemoveMember)}
                />
              )}
            </View>
          </View>
        }
      />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    shadowColor: colorPallete.cordioRedDark1,
    shadowOpacity: 0.1,
    shadowOffset: {
      height: 10,
      width: 5
    },
    shadowRadius: 10
  },
  collapseHeaderContainer: {},
  collapseContainer: {
    padding: 16,
    minHeight: 300
  },
  inputStylingAlignment: {
    width: "100%",
    fontSize: 12
  },
  textHeaderField: {
    flex: 56,
    fontSize: 12
  },
  memberContainer: {
    zIndex: -1, //so that the searchdropdown appears on top
    flexDirection: "row",
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: colorPallete.cordioTaupe,
    borderRadius: 10,
    marginTop: 15,
    height: 77,
    alignItems: "center",
    paddingRight: 5,
    paddingLeft: 5
  },
  profilePictureContainer: {
    flex: 15,
    alignItems: "center"
  },
  nameContainer: {
    flex: 55
  },
  memberDesignationContainer: {
    flex: 30,
    marginRight: 10
  },
  headerText: {
    fontSize: 12,
    fontFamily: FontFamily.Medium,
    lineHeight: 17
  },
  headerTextTitleSupport: {
    fontWeight: "700"
  },
  headerColor: {
    fontWeight: "400",
    fontSize: 12,
    fontFamily: FontFamily.Regular,
    lineHeight: 17,
    color: colorPallete.textLight
  },

  //member search name and dropdown
  wrapperContainer: {
    paddingLeft: 15,
    paddingRight: 15
  },
  inputContainer: {
    marginBottom: 15
  },
  titleInputContainerStyles: {
    marginTop: 0
  },
  listDropdownContainer: {
    borderColor: "lightgrey",
    borderWidth: 1,
    borderStyle: "solid",
    padding: 5,
    paddingLeft: 10,
    flex: 80
  },
  userListMainContainer: {
    // zIndex: -1, //so that the searchdropdown appears on top
    flexDirection: "column",
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: colorPallete.cordioTaupe,
    borderRadius: 10,
    marginTop: 15,
    minHeight: 77,
    // alignItems: "center",
    paddingRight: 5,
    paddingLeft: 5
  },
  miniIconContainer: {
    flexDirection: "row-reverse",
    marginTop: 5
  },
  alignContainerButton: {
    fontSize: 12,
    color: colorPallete.cordioTaupe,
    marginRight: 5,
    marginLeft: 25
  },
  profileImage: {
    width: 33,
    height: 33,
    borderRadius: 50
  },
  inputStyle: {
    width: "100%"
  }
});
