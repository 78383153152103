import React, { ReactElement, useState, useEffect } from "react";
import { ScrollView, StyleSheet, View, Linking, TouchableOpacity } from "react-native";
import {
  Text,
  TextSize,
  FontWeight,
  FontFamily
} from "@socion-cordio/common/src/components/atoms/text";
import { Image } from "@socion-cordio/common/src/components/atoms/image";
import Icon, { IconNames } from "@socion-cordio/common/src/components/atoms/icon";
import TextField from "@socion-cordio/common/src/components/atoms/textField";
import CollapseItem from "@socion-cordio/common/src/components/molecules/collapseItem";
import formatBytes from "@socion-cordio/common/src/utils/sizeConvertor";
import { colorPallete } from "@socion-cordio/common/src/assets/styles/colors";
import { dataHelper } from "@socion-cordio/common/src/utils/dataHelper";
import { useTranslation } from "react-i18next";
import { ApiClient } from "@socion-cordio/common/src/network/apiClient";
import { topicEndPoints } from "@socion-cordio/common/src/repositories/endPoints";
import { toast, ToastContainer } from "react-toastify";
import AddTelemetryService from "@socion-cordio/common/src/services/telemetryService";
import Moment from "moment";
import { LocalStorage } from "@socion-cordio/common/src/services/storage/storageService";
import RemoveLinkModal from "@socion-cordio/common/src/components/organisms/common-modals/removeLinkModal";
import Loader from "@socion-cordio/common/src/components/atoms/loader";
import { mediaHelper } from "@socion-cordio/common/src/utils/mediaHelperMethods";
import { auxiliaryMethods } from "@socion-cordio/common/src/utils/auxiliaryMethods";
import { ScrollContainer } from "@socion-cordio/common/src/components/organisms/scrollWrapper";

interface Props {
  topicDetails: any;
  getTopicDetails: Function;
  status: any;
  updateTelemetryContentAdd: Function;
  userRoles?: any;
  subContentEnabled: Boolean;
  publishForTopicLibraryHandler: Function;
  programStatus?: any;
}

export default function TopicDetailsContentList(props: Props): ReactElement {
  const {
    topicDetails,
    getTopicDetails,
    status,
    updateTelemetryContentAdd,
    subContentEnabled,
    publishForTopicLibraryHandler,
    programStatus
  } = props;
  const { t } = useTranslation();
  const [isEditableId, setIsEditableId] = useState(null);
  const [addNewLinkData, setNewLinkData] = useState(null);
  const [isNewLinkAdded, setIsNewLinkAdded] = useState(false);
  const [title, setTitle] = useState(null);
  const [titleUpdate, setTitleUpdate] = useState(null);
  const [enteredUrlUpdate, setEnteredUrlUpdate] = useState(null);
  const [enteredUrl, setEnteredUrl] = useState(null);
  const [isUrlCorrect, setIsUrlCorrect] = useState(true);
  const [isValidUrl, setIsValidUrl] = useState(true);
  const [isSubContentDelete, setIsSubContentDelete] = useState(false);
  const [isContentDelete, setIsContentDelete] = useState(false);
  const [contentFiles, setContentFiles] = useState(null);
  const [subContentFile, setSubContentFile] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingMain, setIsLoadingMain] = useState(false);
  const [failed, setFailed] = useState(false);
  const [messageTextValue, setMessageTextValue] = useState(null);
  const [isExpanded, setIsExpanded] = useState(false);
  const [isTitleEntered, setTitleEntered] = useState(true);
  const [isTitleEnteredUpdate, setTitleEnteredUpdate] = useState(true);

  const editHandler = (data: any) => {
    setTitleUpdate(data.title);
    setEnteredUrlUpdate(data.url);
    setIsEditableId(data.id);
    setIsUrlCorrect(true);
    setTitleEnteredUpdate(true);
  };

  const cancelEditHander = () => {
    setIsEditableId(" ");
    setTitleUpdate("");
    setEnteredUrlUpdate("");
  };

  const deleteSubTopicContentHandler = async (subContent: any, contentFile: any, value?: any) => {
    setIsSubContentDelete(true);
    setContentFiles(contentFile);
    setSubContentFile(subContent);
    setMessageTextValue(t("topics:deleteSubTopicsLinkConfirm"));
  };

  const deleteSubTopics = async (isDeleted: boolean) => {
    try {
      if (isDeleted === true) {
        setIsSubContentDelete(false);
        setIsLoading(true);
        const response = await ApiClient.delete(
          topicEndPoints.deleteSubTopicLink(subContentFile.id),
          {}
        );
        toast.success("Content Deleted Succesfully.");
        let eventType = "Sub-content deleted";
        updateTelemetry(subContentFile, contentFiles, eventType);
        setIsLoading(false);
        setFailed(false);
        getTopicDetails(topicDetails.id);
        setMessageTextValue(null);
      }
    } catch (error) {
      toast.error("Something went wrong.");
      setFailed(true);
      setFailed(false);
    }
  };

  const saveDetailsApiCallHandler = async (subContent: any, contentFile: any) => {
    try {
      const content = {
        topicId: topicDetails.id,
        title: titleUpdate,
        url: enteredUrlUpdate,
        type: "url",
        contentId: contentFile.contentId,
        programId: topicDetails.programId
      };
      const response = await ApiClient.put(
        topicEndPoints.updateSubTopicLink(subContent.id),
        content
      );
      toast.success("Content Updated Succesfully.");
      getTopicDetails(topicDetails.id);
      cancelEditHander();
    } catch (error) {
      toast.error("Something went wrong.");
      cancelEditHander();
    }
  };

  const saveDetailsHandler = async (subContent: any, contentFile: any) => {
    if (titleUpdate !== null && titleUpdate !== "") {
      // Title for exisiting is entered, hence able to submit
      setTitleEnteredUpdate(true);
      const isValid = auxiliaryMethods.isUrlValid(enteredUrlUpdate);
      !isValid
        ? [setIsUrlCorrect(true), saveDetailsApiCallHandler(subContent, contentFile)] // valid Url, able to submit
        : [setIsUrlCorrect(false)]; // no valid url, display error
    } else {
      // display error, title for exisiting  not entered
      setTitleEnteredUpdate(false);
    }
  };

  const addNewLinkRowHandler = (data: any) => {
    setNewLinkData(data);
    setIsNewLinkAdded(true);
    setIsExpanded(true); // To expand the collapsible
  };

  const saveNewLinkApiCallHandler = async (contentFile: any) => {
    try {
      setIsLoading(true);
      const content = {
        topicId: topicDetails.id,
        title: title,
        url: enteredUrl,
        type: "url",
        contentId: contentFile.contentId,
        programId: topicDetails.programId
      };
      const response = await ApiClient.post(topicEndPoints.addNewSubTopicLink(), content);
      let eventType = "Sub-content added";
      updateTelemetry(content, contentFile, eventType);
      toast.success("Sub-Content Link Added Succesfully.");
      setIsLoading(false);
      setFailed(false);
      cancelAddNewLinkHander();
      getTopicDetails(topicDetails.id);
    } catch (error) {
      toast.error("Something went wrong.");
      cancelAddNewLinkHander();
      setFailed(true);
    }
  };

  const saveNewLinkHandler = async (contentFile: any) => {
    if (title !== null && title !== "") {
      // Title is entered, hence able to submit
      setTitleEntered(true);
      const isValid = auxiliaryMethods.isUrlValid(enteredUrl);
      !isValid
        ? [setIsValidUrl(true), saveNewLinkApiCallHandler(contentFile)] // valid Url, able to submit
        : [setIsValidUrl(false)]; // no valid url, display error
    } else {
      // display error, title not entere
      setTitleEntered(false);
    }
  };

  const updateTelemetry = (subContent: any, contentFile: any, eventType: string) => {
    const contentBody = {
      contentFileName: subContent.title,
      contentType: "URL",
      eventType: eventType,
      contentUrl: subContent.url
    };
    updateTelemetryContentApiCall(contentBody);
  };
  const updateTelemetryContentApiCall = async (data: any) => {
    const programDetails: any = await LocalStorage.getStorage("programDetails");
    const userDetails: any = await LocalStorage.getStorage("user");
    const body = {
      timestamp: Moment().toLocaleString(),
      createdAt: Moment().toLocaleString(),
      deleted: "false",
      updatedAt: Moment().toLocaleString(),
      topicName: topicDetails.name,
      topicId: topicDetails.id,
      entityId: null,
      entityName: null,
      ...data
    };
    AddTelemetryService(body, programDetails, userDetails);
  };

  const cancelAddNewLinkHander = () => {
    // setNewLinkData(null);
    setIsNewLinkAdded(false);
    setTitle(null);
    setEnteredUrl(null);
    setTitleEntered(true);
    setIsValidUrl(true);
  };

  const deleteContentHandler = (contentFile: any) => {
    setContentFiles(contentFile);
    // setIsSubContentDelete(true);
    setIsContentDelete(true);
    setMessageTextValue(t("topics:deleteContentFileConfirm"));
  };

  const deleteContentFileHandler = async (isDeleted: boolean) => {
    try {
      if (isDeleted === true) {
        setIsLoadingMain(true);
        // setIsSubContentDelete(false);
        setIsContentDelete(false);
        const response = await ApiClient.delete(
          topicEndPoints.deleteContentFile(contentFiles.contentId),
          {}
        );
        toast.success("File deleted successfully");
        isContentDeletedLast(topicDetails);
        getTopicDetails(topicDetails.id);
        setIsLoadingMain(false);
        setFailed(false);
        setMessageTextValue(null);
        let eventType = "Content Deleted";
        updateTelemetryContentAdd(contentFiles, eventType);
      }
    } catch (error) {
      toast.error("Something went wrong.");
      setIsLoadingMain(false);
      setFailed(false);
    }
  };

  // current length at the time of deleting is 1,
  // when deleted send the publish for lib topic as false
  const isContentDeletedLast = (topicDetails: any) => {
    topicDetails.contentFiles.length === 1 ? publishForTopicLibraryHandler(true) : null;
  };

  const validateSubContentUrl = (url: string) => {
    const urlCheck = url.includes("https://")
      ? Linking.openURL(url)
      : Linking.openURL(`https://${url}`);
  };

  return (
    <View>
      <View style={styles.topicsListContainer}>
        {/* <ScrollView> */}
        <ScrollContainer
          height="300px"
          scrollable={topicDetails?.contentFiles?.length > 3 ? true : false}
        >
          <>
            {isLoadingMain ? (
              <Loader customLoadingContainer={{ height: "100px" }} />
            ) : failed ? (
              <View>Failed to load data</View>
            ) : (
              <View>
                {topicDetails.contentFiles.map((contentFile: any) => (
                  <View key={contentFile?.contentId} style={styles.miniContainer}>
                    <CollapseItem
                      headerStyles={[styles.collapseHeaderContainer]}
                      accordionStyles={styles.accordionStyles}
                      bodyStyles={styles.bodyStyles}
                      showAccordianIcon={contentFile.subContentList.length > 0 ? true : false}
                      isExpanded={
                        isExpanded && addNewLinkData?.contentId === contentFile?.contentId
                      }
                      headerComponent={
                        <View style={styles.flexDriectionRow}>
                          <Icon
                            testID="edit"
                            name={
                              mediaHelper.getIcons(contentFile.fileType) === "image"
                                ? IconNames.imageFile
                                : mediaHelper.getIcons(contentFile.fileType) === "video"
                                ? IconNames.videoIcon
                                : IconNames.documentFile
                            }
                            customStyle={[styles.alignButtonSecondary]}
                          />
                          <View style={styles.alignContentNameHeader}>
                            <TouchableOpacity
                              onPress={() => {
                                contentFile.vimeoUrl === null
                                  ? Linking.openURL(contentFile.contentUrl)
                                  : Linking.openURL(contentFile.vimeoUrl);
                              }}
                            >
                              <Text
                                fontWeight={FontWeight.Bold}
                                testId="headerText"
                                textSize={TextSize.Small}
                                textStyle={[styles.headerText, {}]}
                              >
                                {contentFile.fileName}
                              </Text>
                            </TouchableOpacity>
                            <Text
                              fontWeight={FontWeight.Light}
                              testId="addRoleText"
                              textSize={TextSize.Small}
                              textStyle={[
                                styles.headerText,
                                styles.headerAlignment,
                                styles.fontSizeText
                              ]}
                            >
                              {formatBytes(contentFile.fileSize)}
                            </Text>
                          </View>
                        </View>
                      }
                      children={
                        <View>
                          {isLoading ? (
                            <Loader customLoadingContainer={{ height: "100px" }} />
                          ) : failed ? (
                            <View>Failed to load data</View>
                          ) : (
                            <View>
                              {isNewLinkAdded &&
                                addNewLinkData.contentId === contentFile.contentId && (
                                  <View style={styles.addNewLinkContainer}>
                                    <View style={styles.inputTextContainers}>
                                      <View style={styles.urlContainer}>
                                        <View style={styles.inputTextContainer}>
                                          <TextField
                                            label=""
                                            handleChange={(title: string) => {
                                              setTitle(title);
                                              // title !== null && title !== ""
                                              //   ? setTitleEntered(true)
                                              //   : setTitleEntered(false);
                                            }}
                                            handleBlur={() => {}}
                                            value={title}
                                            inputStyle={[
                                              styles.inputStylings,
                                              styles.inputStylingAlignment
                                            ]}
                                            placeholder="Enter Title"
                                            name="programName"
                                            id="programName"
                                            noFormik
                                          />
                                        </View>
                                        {!isTitleEntered && (
                                          <Text
                                            fontWeight={FontWeight.Regular}
                                            testId="internal"
                                            textSize={TextSize.Small}
                                            textStyle={[styles.headerText, styles.errorText]}
                                          >
                                            {"Please Enter Sub-Content Title."}
                                          </Text>
                                        )}
                                      </View>
                                      <View style={styles.urlContainer}>
                                        <View style={styles.inputTextContainer}>
                                          <TextField
                                            label=""
                                            handleChange={(url: string) => {
                                              [setEnteredUrl(url)];
                                            }}
                                            handleBlur={() => {}}
                                            value={enteredUrl}
                                            inputStyle={[
                                              styles.inputStylings,
                                              styles.inputStylingAlignment
                                            ]}
                                            placeholder="Enter URL"
                                            name="programName"
                                            id="programName"
                                            noFormik
                                            // editable={editable}
                                          />
                                        </View>
                                        {!isValidUrl && (
                                          <View>
                                            <Text
                                              fontWeight={FontWeight.Regular}
                                              testId="internal"
                                              textSize={TextSize.Small}
                                              textStyle={[
                                                styles.headerText,
                                                // styles.alignText,
                                                styles.errorText
                                              ]}
                                            >
                                              {t("session:validUrl")}
                                            </Text>
                                          </View>
                                        )}
                                      </View>
                                    </View>
                                    <View style={styles.editDeleteContainer}>
                                      <TouchableOpacity>
                                        <Icon
                                          onPress={() => cancelAddNewLinkHander()}
                                          testID="edit"
                                          name={IconNames.close}
                                          customStyle={[styles.alignEditDeleteButton]}
                                        />
                                      </TouchableOpacity>
                                      <TouchableOpacity>
                                        <Icon
                                          onPress={() => {
                                            saveNewLinkHandler(contentFile);
                                          }}
                                          testID="save"
                                          name={IconNames.tick}
                                          customStyle={[styles.alignEditDeleteButton]}
                                        />
                                      </TouchableOpacity>
                                    </View>
                                  </View>
                                )}

                              <View>
                                {!dataHelper.isEmptyArray(contentFile?.subContentList) &&
                                  contentFile.subContentList.map((subContent: any) => (
                                    <View key={subContent.id} style={styles.linksContainer}>
                                      {isEditableId !== subContent.id && (
                                        <View style={styles.subLinksContainer}>
                                          <Text
                                            fontWeight={FontWeight.Light}
                                            testId="link"
                                            textSize={TextSize.Small}
                                            textStyle={[
                                              styles.headerText,
                                              styles.fontSizeText,
                                              styles.textAlign,
                                              {}
                                            ]}
                                            onPress={() => {
                                              validateSubContentUrl(subContent.url);
                                            }}
                                          >
                                            {subContent.title}
                                          </Text>
                                        </View>
                                      )}

                                      {isEditableId === subContent.id && (
                                        <View style={styles.inputTextContainers}>
                                          <View style={styles.inputTextContainer}>
                                            <TextField
                                              label=""
                                              handleChange={(title: string) => {
                                                setTitleUpdate(title);
                                              }}
                                              handleBlur={() => {}}
                                              value={titleUpdate}
                                              inputStyle={[
                                                styles.inputStylings,
                                                styles.inputStylingAlignment
                                              ]}
                                              placeholder="Enter Title"
                                              name="programName"
                                              id="programName"
                                              noFormik
                                            />
                                            {!isTitleEnteredUpdate && (
                                              <Text
                                                fontWeight={FontWeight.Regular}
                                                testId="internal"
                                                textSize={TextSize.Small}
                                                textStyle={[styles.headerText, styles.errorText]}
                                              >
                                                {"Please Enter Sub-Content Title."}
                                              </Text>
                                            )}
                                          </View>
                                          <View>
                                            <View style={styles.inputTextContainer}>
                                              <TextField
                                                label=""
                                                handleChange={(url: string) => {
                                                  [setEnteredUrlUpdate(url)];
                                                }}
                                                handleBlur={() => {}}
                                                value={enteredUrlUpdate}
                                                inputStyle={[
                                                  styles.inputStylings,
                                                  styles.inputStylingAlignment
                                                ]}
                                                placeholder="Enter URL"
                                                name="programName"
                                                id="programName"
                                                noFormik
                                              />
                                            </View>
                                            {!isUrlCorrect && (
                                              <View>
                                                <Text
                                                  fontWeight={FontWeight.Regular}
                                                  testId="internal"
                                                  textSize={TextSize.Small}
                                                  textStyle={[styles.headerText, styles.errorText]}
                                                >
                                                  {t("session:validUrl")}
                                                </Text>
                                              </View>
                                            )}
                                          </View>
                                        </View>
                                      )}
                                      {(props?.userRoles?.includes("PROGRAM_ADMIN") ||
                                        props?.userRoles?.includes("ENTITY_ADMIN") ||
                                        props?.userRoles?.includes("CONTENT_ADMIN")) &&
                                        isEditableId !== subContent.id &&
                                        !programStatus &&
                                        status &&
                                        topicDetails.contentUpload && (
                                          <View style={styles.editDeleteContainer}>
                                            <TouchableOpacity
                                              onPress={() =>
                                                deleteSubTopicContentHandler(
                                                  subContent,
                                                  contentFile
                                                )
                                              }
                                            >
                                              <Image
                                                testId="addSubcontent"
                                                source={require("@socion-cordio/common/src/assets/images/remove.svg")}
                                                imageStyle={[
                                                  styles.alignContainerButton,
                                                  {
                                                    width: 18,
                                                    height: 18
                                                  }
                                                ]}
                                              />
                                            </TouchableOpacity>
                                            <TouchableOpacity>
                                              <Icon
                                                onPress={() => editHandler(subContent)}
                                                testID="edit"
                                                name={IconNames.edit}
                                                customStyle={[styles.alignEditDeleteButton]}
                                              />
                                            </TouchableOpacity>
                                          </View>
                                        )}
                                      {isEditableId === subContent.id && (
                                        <View style={styles.editDeleteContainer}>
                                          <TouchableOpacity>
                                            <Icon
                                              onPress={() => cancelEditHander()}
                                              testID="edit"
                                              name={IconNames.close}
                                              customStyle={[styles.alignEditDeleteButton]}
                                            />
                                          </TouchableOpacity>
                                          <TouchableOpacity>
                                            <Icon
                                              onPress={() => {
                                                saveDetailsHandler(subContent, contentFile);
                                              }}
                                              testID="save"
                                              name={IconNames.tick}
                                              customStyle={[styles.alignEditDeleteButton]}
                                            />
                                          </TouchableOpacity>
                                        </View>
                                      )}
                                    </View>
                                  ))}
                              </View>
                            </View>
                          )}
                        </View>
                      }
                    />

                    <View style={styles.miniIconContainer}>
                      {(props?.userRoles?.includes("PROGRAM_ADMIN") ||
                        props?.userRoles?.includes("ENTITY_ADMIN") ||
                        props?.userRoles?.includes("CONTENT_ADMIN")) &&
                        topicDetails.contentUpload &&
                        status && (
                          <TouchableOpacity onPress={() => deleteContentHandler(contentFile)}>
                            <Image
                              testId="addSubcontent"
                              source={require("@socion-cordio/common/src/assets/images/remove.svg")}
                              imageStyle={[
                                styles.alignContainerButton,
                                {
                                  width: 18,
                                  height: 18
                                }
                              ]}
                            />
                          </TouchableOpacity>
                        )}
                      {
                        // topicDetails?.sub_content_enabled &&
                        subContentEnabled &&
                          (props?.userRoles?.includes("PROGRAM_ADMIN") ||
                            props?.userRoles?.includes("ENTITY_ADMIN") ||
                            props?.userRoles?.includes("CONTENT_ADMIN")) &&
                          topicDetails.contentUpload &&
                          !programStatus &&
                          status && (
                            <TouchableOpacity
                              onPress={() => [
                                addNewLinkRowHandler(contentFile),
                                setTitleEntered(true),
                                setIsValidUrl(true),
                                setEnteredUrl(""),
                                setTitle("")
                              ]}
                            >
                              <Image
                                testId="addSubcontent"
                                source={require("@socion-cordio/common/src/assets/images/secondary_add.svg")}
                                imageStyle={[
                                  styles.alignContainerButton,
                                  {
                                    width: 18,
                                    height: 18
                                  }
                                ]}
                              />
                            </TouchableOpacity>
                          )
                      }

                      <TouchableOpacity
                        style={styles.alignContainer}
                        onPress={() => {
                          mediaHelper.mediaTopicContentDownloadHandler(
                            contentFile,
                            contentFile.fileType,
                            auxiliaryMethods.getFileNameFromUrl(contentFile.contentUrl)
                          );
                        }}
                      >
                        <Icon
                          testID="downloadContent"
                          name={IconNames.downloadList}
                          customStyle={styles.styleIcon}
                        />
                      </TouchableOpacity>
                    </View>
                  </View>
                ))}
              </View>
            )}
          </>
        </ScrollContainer>
        {/* </ScrollView> */}
      </View>

      {isSubContentDelete && (
        <RemoveLinkModal
          modalVisible={isSubContentDelete}
          selectedData={deleteSubTopics}
          textValue={messageTextValue}
          setModalVisible={() => setIsSubContentDelete(!isSubContentDelete)}
        />
      )}
      {isContentDelete && (
        <RemoveLinkModal
          modalVisible={isContentDelete}
          selectedData={deleteContentFileHandler}
          textValue={messageTextValue}
          setModalVisible={() => setIsContentDelete(!isContentDelete)}
        />
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  topicsListContainer: {
    marginTop: 5,
    height: 250
  },

  // collapsible css
  collapseHeaderContainer: {
    padding: 0,
    alignItems: "flex-start",
    shadowColor: "#ffffff0",
    shadowOffset: { width: 0, height: 0 },
    shadowOpacity: 0,
    shadowRadius: 0,
    backgroundColor: "#ffffff0",
    borderRadius: 0,
    // width: 550, // Collapsible width change,
    justifyContent: "flex-start"
  },
  accordionStyles: {
    shadowColor: "#ffffff0",
    backgroundColor: "#ffffff0",
    shadowOffset: {
      width: 0,
      height: 0
    },
    shadowOpacity: 0,
    shadowRadius: 0,
    borderRadius: 0
  },
  bodyStyles: {
    marginLeft: 25,
    marginRight: 10,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    width: 550
  },

  miniContainer: {
    paddingHorizontal: 20,
    paddingVertical: 15,
    marginTop: 10,
    marginBottom: 10,
    width: "99%",
    borderColor: colorPallete.lightGreen,
    borderStyle: "solid",
    borderWidth: 1,
    borderRadius: 10,
    flexDirection: "row",
    justifyContent: "space-between"
  },
  miniIconContainer: {
    flexDirection: "row"
  },

  // Add new links container
  addNewLinkContainer: {
    flexDirection: "row"
  },

  // Links container
  linksContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginVertical: 5
  },
  subLinksContainer: {},

  // input containers
  inputTextContainers: {
    flexDirection: "row",
    flex: 1
  },
  inputTextContainer: {
    marginRight: 5,
    flex: 35
  },

  // URL container
  urlContainer: {},
  errorText: {
    color: colorPallete.cordioRed,
    marginTop: 5
  },

  // text input
  inputStylings: {
    height: 25,
    borderRadius: 5,
    padding: 7,
    fontSize: 12,
    fontWeight: FontWeight.Light,
    fontFamily: FontFamily.Medium,
    width: "100%"
  },
  inputStylingAlignment: {},

  // Icons
  editDeleteContainer: {
    flexDirection: "row",
    alignSelf: "flex-start",
    marginTop: "inherit",
    alignItems: "center"
  },

  alignEditDeleteButton: {
    fontSize: 15,
    marginLeft: 30,
    color: colorPallete.cordioTaupe
  },

  alignContainerButton: {
    fontSize: 17,
    color: colorPallete.cordioTaupe,
    marginRight: 5,
    marginLeft: 25
  },

  // Icon styles
  alignContainer: {
    marginRight: 5,
    marginLeft: 25
  },
  styleIcon: {
    fontSize: 17,
    color: colorPallete.cordioTaupe
  },
  flexDriectionRow: {
    flexDirection: "row"
  },
  alignContentNameHeader: {
    maxWidth: 450
  },
  headerText: {
    fontSize: 12,
    fontFamily: FontFamily.Medium,
    lineHeight: 17,
    marginBottom: 5
  },
  headerAlignment: {
    color: colorPallete.textLight,
    fontFamily: FontFamily.Regular
  },
  fontSizeText: {
    fontSize: 12
  },
  textAlign: {
    textDecorationLine: "underline"
    // marginVertical: 5
  },
  alignButtonSecondary: {
    marginLeft: 0,
    marginRight: 10,
    fontSize: 15,
    color: colorPallete.textBlack
  }
});
