import { JsonConverter, JsonCustomConvert, JsonObject, JsonProperty } from "json2typescript";

// @JsonConverter
// class DateConverter implements JsonCustomConvert<Date> {
//   serialize(date: Date): any {
//     return date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate();
//   }
//   deserialize(date: any): Date {
//     return new Date(date);
//   }
// }

@JsonObject("RolePrograms")
export class RolePrograms {
  @JsonProperty("entityName", String)
  program: string = "";
  @JsonProperty("requestedDate", String)
  createdOn: string = "";
  @JsonProperty("status", String)
  status: string = "";
  @JsonProperty("verifierName", String)
  verifierName: string = "";
  @JsonProperty("verifierId", Number)
  verifierId: number = null;
  @JsonProperty("responseDate", String)
  responseDate: string = "";
  @JsonProperty("reason", String)
  reason: string = "";
  @JsonProperty("roleStartDate", String)
  roleStartDate: string = "";
  @JsonProperty("roleEndDate", String)
  roleEndDate: string = "";
  @JsonProperty("approverProfileData", Object)
  approverProfileData: object = null;
}

@JsonObject("Response")
export class Response {
  @JsonProperty("roleId", String)
  roleId: string = "";
  @JsonProperty("rolePrograms", [RolePrograms])
  rolePrograms: Array<RolePrograms> = null;
  @JsonProperty("documents", [String])
  documents: Array<string> = [];
  @JsonProperty("roleTitle", String)
  roleTitle: string = "";
  @JsonProperty("userId", String)
  userId: string = "";
  @JsonProperty("osid", String)
  osid: string = "";
}

@JsonObject("UserRole")
export class UserRole {
  @JsonProperty("response", [Response])
  response: Array<Response> = null;
  @JsonProperty("message", String)
  message: string = "";
  @JsonProperty("responseCode", Number)
  responseCode: number = null;
}
