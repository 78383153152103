import { Signup } from "@socion-cordio/common/src/models/signup"; //Doubt!!
import { SIGNUP } from "@socion-cordio/common/src/modules/signup/actions/actionTypes";
import { IFluxStandardAction } from "@socion-cordio/common/src/store/interfaces";
const signupUser = (data: {
  countryCode: number;
  phoneNumber: number;
  name: string;
}): IFluxStandardAction<{ countryCode: number; phoneNumber: number; name: string }> => {
  return {
    type: SIGNUP.SIGNUP_USER.LOADING,
    payload: data
  };
};

const signupUserSucess = (data: Signup): IFluxStandardAction<Signup> => {
  return {
    type: SIGNUP.SIGNUP_USER.SUCCESS,
    payload: data
  };
};

const signupUserError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: SIGNUP.SIGNUP_USER.ERROR,
    payload: error
  };
};

export const SignupActions = {
  signupUser,
  signupUserSucess,
  signupUserError
};
