import { colorPallete } from "@socion-cordio/common/src/assets/styles/colors";
import {
  FontFamily,
  FontWeight,
  Text,
  TextSize
} from "@socion-cordio/common/src/components/atoms/text";
import { ArtefactRepository } from "@socion-cordio/common/src/repositories/artefact/artefact";
import { dataHelper } from "@socion-cordio/common/src/utils/dataHelper";
import { orderBy } from "lodash";
import React, { useEffect, useState } from "react";
import { StyleSheet, View } from "react-native";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import { getJoinedLocation } from "../helpers/artefactHelper";
import { viewArtefactEvent } from "../helpers/telemetryEvents";
import ArtefactDocs from "./artefactDocs";
import Submitters from "./submitters";
import Workflow from "./workflow";

const ViewArtefactWrapper = styled.div`
  border-radius: 5px;
  margin: 20px;
  padding: 20px;
  border: 1px solid #a19f9f;
  min-height: calc(100vh - 35vh);
  position: relative;
  .close {
    position: absolute;
    right: 20px;
    top: 20px;
  }

  .viewArtefactDetailsWrapper {
    display: flex;
    flex-direction: row;
    width: 100%;
    background: "#ffffff";

    .viewArtefactRightBorder {
      border-right: 1px solid rgba(0, 0, 0, 0.12);
      width: 60%;
    }
    .formGroup {
      padding: 8px 0;
      display: flex;
      flex-direction: row;
      width: 100%;
      .title {
        width: 30%;
      }
      .submittedDateTitle {
        width: 40%;
      }
      .submittedDateField {
        width: 60%;
      }
      .field {
        width: 70%;
      }
    }
    .addSubmittersWrapper {
      width: 40%;
      padding: 10px 15px;
      margin-bottom: 5px;
      .submitterImg {
        padding-right: 20px;
        img {
          margin: 0 0;
        }
      }
    }

    .submittersWrapper {
      border: 1px solid #675850;
      border-radius: 10px;
      padding: 10px 15px;
      display: flex;
      align-items: center;
      margin-bottom: 5px;
      .submitterImg {
        padding-right: 20px;
        img {
          margin: 0 0;
        }
      }
    }
  }
  @media (min-width: 767px) and (max-width: 900px) {
  }
  @media (max-width: 767px) {
    .viewArtefactDetailsWrapper {
      grid-template-columns: 1fr;
      grid-gap: 30px;
      .viewArtefactRightBorder {
        border-right: 0px;
      }
    }
  }
`;

const ViewArtefact = (props: any) => {
  const location = useLocation();
  const [artefactMetaData, setArtefactMetaData] = useState(null);
  const [artefactWorkFlow, setArtefactWorkFlow] = useState([]);
  const [artefactDocs, setArtefactDocs] = useState([]);

  useEffect(() => {
    const artefactId = location.pathname.split("/").pop();
    fetchArtefactDetails(artefactId);
    fetchArtefactDocs(artefactId), fetchArtefactWorkFlow(artefactId);
  }, [location]);

  const fetchArtefactDetails = (artefactId: string) => {
    ArtefactRepository.getArtefactDetails(artefactId)
      .then((response) => {
        return response;
      })
      .then((data) => {
        setArtefactMetaData(data.data);
      });
  };

  const fetchArtefactDocs = (artefactId: string) => {
    ArtefactRepository.getArtefactDocs(artefactId)
      .then((response) => {
        return response;
      })
      .then((data) => {
        console.log("fetchArtefactDocs : ", data);
        setArtefactDocs(data.data);
      });
  };

  const fetchArtefactWorkFlow = (artefactId: string) => {
    ArtefactRepository.getArtefactWorkFlow(artefactId)
      .then((response) => {
        return response;
      })
      .then((data) => {
        const workflowData = orderBy(data.data, ["actedon"], ["desc"]);

        setArtefactWorkFlow(workflowData);
      });
  };

  useEffect(() => {
    if (artefactMetaData !== null && artefactDocs !== null) {
      viewArtefactEvent(artefactMetaData, artefactDocs.length);
    }
  }, [artefactMetaData, artefactDocs]);

  return (
    <div>
      {artefactMetaData && (
        <View style={styles.mainContainer}>
          <View style={styles.subContainer}>
            <ViewArtefactWrapper>
              <div className="close">{/* <img src={close} alt="Close" /> */}</div>
              <div className="viewArtefactDetailsWrapper">
                <div className="viewArtefactRightBorder">
                  <div
                    style={{
                      fontSize: 14,
                      fontFamily: FontFamily.Bold,
                      color: colorPallete.textBlack,
                      paddingBottom: "20px"
                    }}
                  >
                    View Artefact
                  </div>
                  <div>
                    <div className="formGroup">
                      <div
                        className="title"
                        style={{
                          fontSize: 16,
                          fontFamily: FontFamily.Thin,
                          fontWeight: "400",
                          // color: "#231F20"
                          color: colorPallete.textLight
                        }}
                      >
                        Location
                      </div>
                      <div
                        className="field"
                        style={{
                          fontSize: 14,
                          fontFamily: FontFamily.Thin,
                          fontWeight: "400",
                          color: "#231F20",
                          display: "flex",
                          flexDirection: "column"
                        }}
                      >
                        {artefactMetaData.location}

                        <Text
                          fontWeight={FontWeight.Thin}
                          testId="joinedLocation"
                          textSize={TextSize.Small}
                          textStyle={{
                            fontSize: 10,
                            fontFamily: FontFamily.Regular
                            // paddingStart: 10
                          }}
                        >
                          {`( ${getJoinedLocation(artefactMetaData)} )`}
                        </Text>
                      </div>
                    </div>
                    <div className="formGroup">
                      <div
                        className="title"
                        style={{
                          fontSize: 16,
                          fontFamily: FontFamily.Thin,
                          fontWeight: "400",
                          // color: "#231F20"
                          color: colorPallete.textLight
                        }}
                      >
                        Artefact type
                      </div>
                      <div
                        className="field"
                        style={{
                          fontSize: 14,
                          fontFamily: FontFamily.Thin,
                          fontWeight: "400",
                          color: "#231F20"
                        }}
                      >
                        {artefactMetaData.artefact_type_title}
                      </div>
                    </div>
                    <div className="formGroup">
                      <div
                        className="title"
                        style={{
                          fontSize: 16,
                          fontFamily: FontFamily.Thin,
                          fontWeight: "400",
                          // color: "#231F20"
                          color: colorPallete.textLight
                        }}
                      >
                        Artefact name
                      </div>
                      <div
                        className="field"
                        style={{
                          fontSize: 14,
                          fontFamily: FontFamily.Thin,
                          fontWeight: "700",
                          color: "#231F20"
                        }}
                      >
                        {artefactMetaData.artefact_name}
                      </div>
                    </div>
                    <div className="formGroup">
                      <div
                        className="title"
                        style={{
                          fontSize: 16,
                          fontFamily: FontFamily.Thin,
                          fontWeight: "400",
                          // color: "#231F20"
                          color: colorPallete.textLight
                        }}
                      >
                        Uploaded by
                      </div>
                      <div
                        className="field"
                        style={{
                          fontSize: 14,
                          fontFamily: FontFamily.Thin,
                          fontWeight: "400",
                          color: "#231F20"
                        }}
                      >
                        {artefactMetaData.created_by}
                      </div>
                    </div>
                  </div>
                  <div
                    style={{
                      paddingBottom: "20px",
                      paddingRight: "20px"
                    }}
                  >
                    <ArtefactDocs
                      id={artefactMetaData?.artefact_meta_id}
                      type="artefact"
                      docs={artefactDocs}
                      isView={true}
                    ></ArtefactDocs>
                  </div>
                </div>
                <div className="addSubmittersWrapper">
                  <div
                    style={{
                      fontSize: 14,
                      fontFamily: FontFamily.Bold,
                      fontWeight: "700",
                      color: "#231F20"
                    }}
                  >
                    Submission Details
                  </div>
                  <div>
                    <div className="formGroup">
                      <div
                        className="submittedDateTitle"
                        style={{
                          fontSize: 16,
                          fontFamily: FontFamily.Thin,
                          fontWeight: "400",
                          // color: "#231F20"
                          color: colorPallete.textLight
                        }}
                      >
                        Submitted Date
                      </div>
                      <div
                        className="submittedDateField"
                        style={{
                          fontSize: 14,
                          fontFamily: FontFamily.Thin,
                          fontWeight: "400",
                          color: "#231F20"
                        }}
                      >
                        {artefactMetaData.submitted_date
                          ? dataHelper.formatDateUtc(artefactMetaData.submitted_date)
                          : dataHelper.formatDateUtc(artefactMetaData.created_at)}
                      </div>
                    </div>
                    <div
                      style={{
                        fontSize: 14,
                        fontFamily: FontFamily.Bold,
                        fontWeight: "700",
                        color: "#231F20",
                        paddingTop: "18px",
                        paddingBottom: "30px"
                      }}
                    >
                      Submitters
                    </div>
                    <div>
                      <Submitters
                        submitters={artefactMetaData.submitters}
                        isView={true}
                      ></Submitters>
                    </div>
                    {artefactMetaData.approved_by && artefactMetaData.approved_date && (
                      <div>
                        <div
                          style={{
                            fontSize: 14,
                            fontFamily: FontFamily.Bold,
                            fontWeight: "700",
                            color: "#231F20",
                            paddingTop: "30px",
                            paddingBottom: "8px"
                          }}
                        >
                          Approver Details
                        </div>

                        <div className="formGroup">
                          <div
                            className="submittedDateTitle"
                            style={{
                              fontSize: 16,
                              fontFamily: FontFamily.Thin,
                              fontWeight: "400",
                              // color: "#231F20"
                              color: colorPallete.textLight
                            }}
                          >
                            Approver name
                          </div>
                          <div
                            className="submittedDateField"
                            style={{
                              fontSize: 14,
                              fontFamily: FontFamily.Thin,
                              fontWeight: "400",
                              color: "#231F20"
                            }}
                          >
                            {artefactMetaData.approved_by}
                          </div>
                        </div>
                        <div className="formGroup">
                          <div
                            className="submittedDateTitle"
                            style={{
                              fontSize: 16,
                              fontFamily: FontFamily.Thin,
                              fontWeight: "400",
                              // color: "#231F20"
                              color: colorPallete.textLight
                            }}
                          >
                            Approver date
                          </div>
                          <div
                            className="submittedDateField"
                            style={{
                              fontSize: 14,
                              fontFamily: FontFamily.Thin,
                              fontWeight: "400",
                              color: "#231F20"
                            }}
                          >
                            {dataHelper.formatDateUtc(artefactMetaData.approved_date)}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </ViewArtefactWrapper>

            <Workflow artefactWorkFlow={artefactWorkFlow}></Workflow>
          </View>
        </View>
      )}
      {/* {openComments && showComments()} */}
    </div>
  );
};

const styles = StyleSheet.create({
  mainContainer: {
    // top: 55,
    paddingBottom: 20,
    paddingHorizontal: 20,
    // paddingTop: 15,
    backgroundColor: colorPallete.cordioBeigeLight2,
    minHeight: "calc(100vh - 50px)"
  },
  subContainer: {
    backgroundColor: colorPallete.white,
    borderRadius: 10,
    shadowColor: colorPallete.cordioRedDark1,
    shadowOpacity: 0.1,
    shadowOffset: {
      height: 10,
      width: 5
    },
    shadowRadius: 10
  }
  // profilePicture: {
  //   borderRadius: 54,
  //   width: "40px",
  //   height: "40px"
  // }
});
export default ViewArtefact;
