import React from "react";
import { StyleSheet, View } from "react-native";
import { Image } from "@socion-cordio/common/src/components/atoms/image";

interface Props {}

const LoaderCircle = (props: Props) => (
  <View style={styles.loadingContainer}>
    <Image
      imageStyle={{ height: 50, width: 50 }}
      testId="loadingImg"
      source={require("@socion-cordio/common/src/assets/images/loading_circle.svg")}
    />
  </View>
);

const styles = StyleSheet.create({
  loadingContainer: {
    justifyContent: "center",
    alignItems: "center"
  }
});

export default LoaderCircle;
