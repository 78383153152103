import { colorPallete } from "@socion-cordio/common/src/assets/styles/colors";
import {
  FontFamily,
  FontWeight,
  Text,
  TextSize
} from "@socion-cordio/common/src/components/atoms/text";
import TextArea from "@socion-cordio/common/src/components/atoms/textArea";
import { ArtefactRepository } from "@socion-cordio/common/src/repositories/artefact/artefact";
import { LocalStorage } from "@socion-cordio/common/src/services/storage/storageService";
import { dataHelper } from "@socion-cordio/common/src/utils/dataHelper";
import { get, includes, orderBy, replace, uniqBy } from "lodash";
import React, { useEffect, useState } from "react";
import { StyleSheet, View } from "react-native";
import { useHistory, useLocation } from "react-router-dom";
import styled from "styled-components";
import ArtefactButtons from "./artefactButtons";
import ArtefactDocs from "./artefactDocs";
import Submitters from "./submitters";
import Workflow from "./workflow";
import SocionModal from "@socion-cordio/common/src/components/atoms/modal";
import ConfirmModal from "@socion-cordio/common/src/components/organisms/confirmModal";
import { getJoinedLocation, STATUS } from "../helpers/artefactHelper";
import { reviewArtefactEvent } from "../helpers/telemetryEvents";
import { artefact_messages } from "../data/message";
import { toast } from "react-toastify";
import { allRoutesNames } from "navigation/allRouteNames";
import { useDispatch } from "react-redux";
import { NotificationsActions } from "@socion-cordio/common/src/modules/notifications/actions/action";

const ReviewArtefactWrapper = styled.div`
  border-radius: 5px;
  margin: 20px;
  padding: 20px;
  border: 1px solid rgba(63, 162, 2, 0.6);
  min-height: calc(100vh - 50vh);
  position: relative;
  .close {
    position: absolute;
    right: 20px;
    top: 20px;
  }
  .ReviewArtefactDetailsWrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    background: "#ffffff";

    .ReviewArtefactRightBorder {
      border-right: 1px solid rgba(0, 0, 0, 0.12);
      width: 60%;
    }
    .addSubmittersWrapper {
      width: 40%;
      padding: 10px 15px;
      margin-bottom: 5px;
      .submitterImg {
        padding-right: 20px;
        img {
          margin: 0 0;
        }
      }
    }
    .formGroup {
      padding: 8px 0;
      display: flex;
      flex-direction: row;
      width: 100%;
      .title {
        width: 25%;
      }
      .submittedDateTitle {
        width: 40%;
      }
      .submittedDateField {
        width: 60%;
      }
      .field {
        width: 80%;
        padding: 0 25px;
      }
    }
    .submittersWrapper {
      border: 1px solid #675850;
      border-radius: 10px;
      padding: 10px 15px;
      display: flex;
      align-items: center;
      margin-bottom: 5px;
      .submitterImg {
        padding-right: 20px;
        img {
          margin: 0 0;
        }
      }
    }
  }
  @media (min-width: 767px) and (max-width: 900px) {
  }
  @media (max-width: 767px) {
    .ReviewArtefactDetailsWrapper {
      grid-template-columns: 1fr;
      grid-gap: 30px;
      .ReviewArtefactRightBorder {
        border-right: 0px;
      }
    }
  }
`;

const ReviewArtefact = (props: any) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const user = JSON.parse(localStorage.getItem("user"));
  const programData: any = JSON.parse(localStorage.getItem("programDetails"));
  const history = useHistory();

  const [artefactMetaData, setArtefactMetaData] = useState(null);
  const [artefactWorkFlow, setArtefactWorkFlow] = useState([]);
  const [artefactDocs, setArtefactDocs] = useState([]);
  const [leftSidePanelCollapse, setLeftSidePanelCollapse] = useState(null);
  const [remarks, setRemarks] = useState(null);
  const [buttonType, setButtonType] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [type, setType] = useState(null);
  const [confirmMsg, setConfirmMsg] = useState(null);
  const [disableBtn, setDisableBtn] = useState(false);

  const leftNavCollapseHandler = async () => {
    let isSidePanelCollapsed: boolean = await LocalStorage.getStorage("isSidePanelCollapsed");
    setLeftSidePanelCollapse(isSidePanelCollapsed);
  };

  useEffect(() => {
    leftNavCollapseHandler();
  });

  useEffect(() => {
    const artefactId = location.pathname.split("/").pop();
    fetchArtefactDetails(artefactId);
    fetchArtefactDocs(artefactId);
    fetchArtefactWorkFlow(artefactId);
  }, [location]);

  const fetchArtefactDetails = (artefactId: string) => {
    ArtefactRepository.getArtefactDetails(artefactId)
      .then((response) => {
        return response;
      })
      .then(async (data) => {
        const artefact = data?.data;
        setArtefactMetaData(artefact);
        if (
          !includes(
            [
              STATUS.approved.toLowerCase(),
              STATUS.rejected.toLowerCase(),
              STATUS.inprogress.toLowerCase()
            ],
            get(artefact, "status").toLowerCase()
          )
        ) {
          await updateArtefact(
            artefact?.artefact_meta_id,
            STATUS.inprogress,
            artefact?.status.toUpperCase(),
            artefact
          );
        }
      });
  };

  const fetchArtefactDocs = (artefactId: string) => {
    ArtefactRepository.getArtefactDocs(artefactId)
      .then((response) => {
        return response;
      })
      .then((data) => {
        console.log("fetchArtefactDocs : ", data);
        setArtefactDocs(data.data);
      });
  };

  const fetchArtefactWorkFlow = (artefactId: string) => {
    ArtefactRepository.getArtefactWorkFlow(artefactId)
      .then((response) => {
        return response;
      })
      .then((data) => {
        const workflowData = orderBy(data.data, ["actedon"], ["desc"]);

        setArtefactWorkFlow(workflowData);
      });
  };

  const showConfirmModal = () => {
    return (
      <SocionModal
        modalVisible={showModal}
        setModalVisible={() => {
          setShowModal(!showModal);
        }}
        component={
          <ConfirmModal
            onClose={() => {
              setShowModal(false);
              setDisableBtn(false);
            }}
            onConfirm={async () => {
              setDisableBtn(true);
              setShowModal(false);
              await updateArtefact(
                artefactMetaData?.artefact_meta_id,
                type.toUpperCase(),
                artefactMetaData?.status?.toUpperCase(),
                artefactMetaData
              );
            }}
            msg={confirmMsg}
          ></ConfirmModal>
        }
      ></SocionModal>
    );
  };

  const handleSubmit = async (type: string) => {
    console.log("toLowercasetoLowercase", type);
    setType(type?.toLowerCase());
    setButtonType(type);
    if (type.toLowerCase() === "cancel") {
      history.push(`${allRoutesNames.app}${allRoutesNames.workspace}`);
      return;
    }

    if ((type.toLowerCase() === "rejected" && remarks) || type?.toLowerCase() === "approved") {
      setConfirmMsg(`Are you sure you want to ${type} this artefact`);
      setShowModal(true);
    }
  };

  const updateArtefact = async (id: string, status: string, prev_status: string, artefact: any) => {
    const request = {
      approved_by: user?.name,
      approved_date: new Date(),
      remarks: remarks,
      status: status,
      updated_by: user?.userId
    };
    await ArtefactRepository.updateArtefact(id, request)
      .then(async (data: any) => {
        setArtefactMetaData({ ...artefact, ...request });
        const res = await addWorkflow(id, prev_status, status);
        if (status.toUpperCase() !== STATUS.inprogress) {
          const resNot = await addNotification({ ...artefact, ...request });
          const sesNot = await addSessionNotification({ ...artefact, ...request });
          dispatch(NotificationsActions.getNotifications());
          let msg = replace(
            artefact_messages.success.m015,
            "{name}",
            `"${get(artefact, "artefact_name")}"`
          );
          msg = replace(msg, "{status}", `${status}`);
          toast.success(msg);
          history.push(`${allRoutesNames.app}${allRoutesNames.workspace}`);
        }

        const artefactReq = { ...artefact, ...request };
        reviewArtefactEvent(artefactReq, artefactDocs.length);
      })
      .catch((err) => {
        setDisableBtn(false);
        toast.error(
          replace(artefact_messages.error.m020, "{name}", `"${get(artefact, "artefact_name")}"`)
        );
        console.log("Error ", err);
      });
  };

  const addWorkflow = async (id: string, prev_status: string, current_status: string) => {
    const workflowReq = {
      previous_status: prev_status,
      current_status: current_status,
      actedby: user?.name,
      actedon: new Date(),
      remarks: remarks,
      actedby_id: user?.userId
    };

    const res = await ArtefactRepository.addWorkflow(id, workflowReq);
    console.log("Ressss ", res);
  };

  const addNotification = async (artefact: any) => {
    const submitters = artefact?.submitter_userid?.submitterList || artefact?.submitters;
    submitters.push({ userid: artefact?.user_id });
    console.log("notification submisttetetettetss", submitters);

    let users = submitters.map((submitter: any) => {
      return {
        title: `${artefact?.artefact_name}: ${artefact?.status.toLowerCase()}`,
        description: `Artefact: ${artefact?.artefact_name} with type: ${
          artefact?.artefact_type_title
        } got ${artefact?.status.toLowerCase()} by ${user?.name}`,
        notificationType: "WORKFLOW",
        dateTime: new Date(),
        // messageEmbeddedTime: null,
        userId: submitter?.userid || submitter?.userId
      };
    });
    users = uniqBy(users, "userId");
    console.log(" notification submisttetetettetss users", users);
    const res = await ArtefactRepository.addNotification(users);
    console.log("Ressss ", res);
  };

  const addSessionNotification = async (artefact: any) => {
    const submitters = artefact?.submitter_userid?.submitterList || artefact?.submitters;
    console.log("submisttetetettetss", submitters);
    let users = submitters.map((submitter: any) => {
      return {
        title: `${artefact?.artefact_name}: ${artefact?.status.toLowerCase()}`,
        description: `Artefact: ${artefact?.artefact_name} with type: ${
          artefact?.artefact_type_title
        } got ${artefact?.status.toLowerCase()} by ${user?.name}`,
        notificationType: "WORKFLOW",
        userId: submitter?.userid || submitter?.userId
      };
    });
    users = uniqBy(users, "userId");

    console.log("submisttetetettetss users", users);
    const res = await ArtefactRepository.sendSessionNotification(users);
    console.log("Ressss ", res);
  };

  return (
    <div>
      {artefactMetaData && (
        <View style={styles.mainContainer}>
          <View style={styles.subContainer}>
            <ReviewArtefactWrapper>
              <div className="close">{/* <img src={close} alt="Close" /> */}</div>
              <div className="ReviewArtefactDetailsWrapper">
                <View style={styles.reviewArtefactWrapperContainer}>
                  <div className="ReviewArtefactRightBorder">
                    <div
                      style={{
                        fontSize: 14,
                        fontFamily: FontFamily.Bold,
                        color: colorPallete.textBlack,
                        paddingBottom: "20px"
                      }}
                    >
                      Review Artefact
                    </div>
                    <div>
                      <div className="formGroup">
                        <div
                          className="title"
                          style={{
                            fontSize: 16,
                            fontFamily: FontFamily.Thin,
                            fontWeight: "400",
                            // color: "#231F20"
                            color: colorPallete.textLight
                          }}
                        >
                          Location
                        </div>
                        <div
                          className="field"
                          style={{
                            fontSize: 14,
                            fontFamily: FontFamily.Thin,
                            fontWeight: "400",
                            color: "#231F20",
                            display: "flex",
                            flexDirection: "column"
                          }}
                        >
                          {artefactMetaData.location}

                          <Text
                            fontWeight={FontWeight.Thin}
                            testId="joinedLocation"
                            textSize={TextSize.Small}
                            textStyle={{
                              fontSize: 10,
                              fontFamily: FontFamily.Regular
                              // paddingStart: 10
                            }}
                          >
                            {`( ${getJoinedLocation(artefactMetaData)} )`}
                          </Text>
                        </div>
                      </div>
                      <div className="formGroup">
                        <div
                          className="title"
                          style={{
                            fontSize: 16,
                            fontFamily: FontFamily.Thin,
                            fontWeight: "400",
                            // color: "#231F20"
                            color: colorPallete.textLight
                          }}
                        >
                          Artefact type
                        </div>
                        <div
                          className="field"
                          style={{
                            fontSize: 14,
                            fontFamily: FontFamily.Thin,
                            fontWeight: "400",
                            color: "#231F20"
                          }}
                        >
                          {artefactMetaData.artefact_type_title}
                        </div>
                      </div>
                      <div className="formGroup">
                        <div
                          className="title"
                          style={{
                            fontSize: 16,
                            fontFamily: FontFamily.Thin,
                            fontWeight: "400",
                            // color: "#231F20"
                            color: colorPallete.textLight
                          }}
                        >
                          Artefact name
                        </div>
                        <div
                          className="field"
                          style={{
                            fontSize: 14,
                            fontFamily: FontFamily.Thin,
                            fontWeight: "700",
                            color: "#231F20"
                          }}
                        >
                          {artefactMetaData.artefact_name}
                        </div>
                      </div>
                      <div className="formGroup">
                        <div
                          className="title"
                          style={{
                            fontSize: 16,
                            fontFamily: FontFamily.Thin,
                            fontWeight: "400",
                            // color: "#231F20"
                            color: colorPallete.textLight
                          }}
                        >
                          Uploaded by
                        </div>
                        <div
                          className="field"
                          style={{
                            fontSize: 14,
                            fontFamily: FontFamily.Thin,
                            fontWeight: "400",
                            color: "#231F20"
                          }}
                        >
                          {artefactMetaData.created_by}
                        </div>
                      </div>
                    </div>
                    <div
                      style={{
                        paddingBottom: "20px",
                        paddingRight: "20px"
                      }}
                    >
                      <ArtefactDocs
                        id={artefactMetaData?.artefact_meta_id}
                        type="reviewartefact"
                        docs={artefactDocs}
                        isView={true}
                      ></ArtefactDocs>
                    </div>
                  </div>
                  <div className="addSubmittersWrapper">
                    <div
                      style={{
                        fontSize: 14,
                        fontFamily: FontFamily.Bold,
                        fontWeight: "700",
                        color: "#231F20"
                      }}
                    >
                      Submission Details
                    </div>
                    <div>
                      <div className="formGroup">
                        <div
                          className="submittedDateTitle"
                          style={{
                            fontSize: 16,
                            fontFamily: FontFamily.Thin,
                            fontWeight: "400",
                            // color: "#231F20"
                            color: colorPallete.textLight
                          }}
                        >
                          Submitted Date
                        </div>
                        <div
                          className="submittedDateField"
                          style={{
                            fontSize: 14,
                            fontFamily: FontFamily.Thin,
                            fontWeight: "400",
                            color: "#231F20"
                          }}
                        >
                          {artefactMetaData.submitted_date
                            ? dataHelper.formatDateUtc(artefactMetaData.submitted_date)
                            : dataHelper.formatDateUtc(artefactMetaData.created_at)}
                        </div>
                      </div>
                      <div
                        style={{
                          fontSize: 14,
                          fontFamily: FontFamily.Bold,
                          fontWeight: "700",
                          color: "#231F20",
                          paddingTop: "18px",
                          paddingBottom: "30px"
                        }}
                      >
                        Submitters
                      </div>
                      <div>
                        <Submitters
                          submitters={artefactMetaData.submitters}
                          isView={true}
                        ></Submitters>
                      </div>
                      {artefactMetaData.approved_by && artefactMetaData.approved_date && (
                        <div>
                          <div
                            style={{
                              fontSize: 14,
                              fontFamily: FontFamily.Bold,
                              fontWeight: "700",
                              color: "#231F20",
                              paddingTop: "30px",
                              paddingBottom: "8px"
                            }}
                          >
                            Approver Details
                          </div>

                          <div className="formGroup">
                            <div
                              className="submittedDateTitle"
                              style={{
                                fontSize: 16,
                                fontFamily: FontFamily.Thin,
                                fontWeight: "400",
                                // color: "#231F20"
                                color: colorPallete.textLight
                              }}
                            >
                              Approver name
                            </div>
                            <div
                              className="submittedDateField"
                              style={{
                                fontSize: 14,
                                fontFamily: FontFamily.Thin,
                                fontWeight: "400",
                                color: "#231F20"
                              }}
                            >
                              {artefactMetaData.approved_by}
                            </div>
                          </div>
                          <div className="formGroup">
                            <div
                              className="submittedDateTitle"
                              style={{
                                fontSize: 16,
                                fontFamily: FontFamily.Thin,
                                fontWeight: "400",
                                // color: "#231F20"
                                color: colorPallete.textLight
                              }}
                            >
                              Approver date
                            </div>
                            <div
                              className="submittedDateField"
                              style={{
                                fontSize: 14,
                                fontFamily: FontFamily.Thin,
                                fontWeight: "400",
                                color: "#231F20"
                              }}
                            >
                              {dataHelper.formatDateUtc(artefactMetaData.approved_date)}
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </View>

                <View
                  style={[
                    styles.reviewArtefactWrapperContainer,
                    { display: "flex", justifyContent: "flex-end", alignItems: "flex-end" }
                  ]}
                >
                  <View
                    style={[
                      styles.textFieldContianer,
                      styles.textFieldContianerAlignment
                      // textFieldContianerAlign
                    ]}
                  >
                    <div
                      style={{
                        fontSize: 14,
                        fontFamily: FontFamily.Thin,
                        fontWeight: "700",
                        color: "#231F20",
                        paddingTop: 10,
                        paddingBottom: 10
                      }}
                    >
                      Remarks
                    </div>

                    <TextArea
                      value={remarks}
                      inputStyle={
                        leftSidePanelCollapse
                          ? [styles.inputStyling, { width: "89%" }]
                          : [styles.inputStyling, styles.inputStylingAlignment]
                      }
                      numberOfLines={100}
                      multiline={true}
                      label=""
                      handleChange={(value: any) => {
                        setRemarks(value);
                      }}
                      handleBlur={() => {}}
                      placeholder={"Add Remarks"}
                      name={"Remarks"}
                      id={"remarks"}
                      noFormik
                    />
                    {!remarks && buttonType && buttonType.toLowerCase() === "rejected" && (
                      <Text
                        testId={`remarks-error`}
                        fontWeight={FontWeight.Regular}
                        textSize={TextSize.Small}
                        style={styles.errorMsg}
                      >
                        {artefact_messages.info.m05}
                      </Text>
                    )}
                  </View>
                  <View></View>
                  <View style={styles.buttons}>
                    <ArtefactButtons
                      disabled={disableBtn}
                      buttons={["Reject", "Approve"]}
                      onClick={(type: any) => handleSubmit(type)}
                    ></ArtefactButtons>
                  </View>
                </View>
              </div>
            </ReviewArtefactWrapper>

            <Workflow artefactWorkFlow={artefactWorkFlow}></Workflow>
          </View>
        </View>
      )}
      {showModal && showConfirmModal()}
    </div>
  );
};

const styles = StyleSheet.create({
  mainContainer: {
    // top: 55,
    paddingBottom: 20,
    paddingHorizontal: 20,
    // paddingTop: 15,
    backgroundColor: colorPallete.cordioBeigeLight2,
    minHeight: "calc(100vh - 50px)"
  },
  subContainer: {
    backgroundColor: colorPallete.white,
    borderRadius: 10,
    shadowColor: colorPallete.cordioRedDark1,
    shadowOpacity: 0.1,
    shadowOffset: {
      height: 10,
      width: 5
    },
    shadowRadius: 10
  },
  buttonContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "flex-end",
    marginVertical: 10
  },
  buttons: {
    width: "40%",
    justifyContent: "flex-end",
    alignItems: "center"
  },
  textFieldContianer: {
    flex: 30,
    borderColor: colorPallete.cordioTaupe
  },
  textFieldContianerAlignment: {
    flex: 60
  },
  inputStyling: {
    height: 64,
    outline: "none",
    borderRadius: 10,
    padding: 7,
    fontSize: 14,
    fontFamily: FontFamily.Medium,
    fontWeight: "400",
    backgroundColor: colorPallete.lightGrey
  },
  inputStylingAlignment: {
    width: "88%"
  },
  errorMsg: {
    fontSize: 12,
    color: "#FF0D10",
    padding: 5
  },
  reviewArtefactWrapperContainer: {
    display: "flex",
    flexDirection: "row",
    width: "100%"
  }
});
export default ReviewArtefact;
