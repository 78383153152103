import React, { ReactElement, useEffect, useState } from "react";
import { StyleSheet, TouchableOpacity, View } from "react-native";
import { colorPallete } from "@socion-cordio/common/src/assets/styles/colors";
import {
  Text,
  TextSize,
  FontWeight,
  FontFamily
} from "@socion-cordio/common/src/components/atoms/text";
import Icon, { IconNames } from "@socion-cordio/common/src/components/atoms/icon";
import { useLocation, useHistory } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { ApiClient } from "@socion-cordio/common/src/network/apiClient";
import { qualificationPackEndPoints } from "@socion-cordio/common/src/repositories/endPoints";
import Loader from "@socion-cordio/common/src/components/atoms/loader";
import { useTranslation } from "react-i18next";
import QualiViewDetailsSidePanelPage from "@socion-cordio/common/src/components/organisms/qualification-packs/qualiViewDetailsSidePanelPage";
import QualificationViewDetailsTable from "@socion-cordio/common/src/components/organisms/qualification-packs/qualificationViewDetailsTable";
import { allRoutesNames as routeNames } from "@socion-cordio/web/src/navigation/allRouteNames";

interface Props {
  onSubmit?: (data: { otp: number }) => void;
}

export default function QualificationViewDetails(props: Props): ReactElement {
  const { t } = useTranslation();
  const [qualiPackData, setQualiPackData] = useState(null);
  const [metrics, setMetrics] = useState([]);
  const [participantCount, setParticipantCount] = useState(null);
  const [bundleTopicData, setBundleTopicData] = useState(null);

  const [loading, setLoading] = useState(true);
  const [failed, setFailed] = useState(false);
  const location: any = useLocation();
  const history: any = useHistory();

  useEffect(() => {
    setQualiPackData(location?.state?.packDetails);
    getQualificationDetails();
  }, []);

  const getQualificationDetails = async () => {
    try {
      const bundleId = location?.state?.packDetails?.bundle?.id;
      const body = {
        disableLocationFilter: "False",
        disableBadgeFilter: "False",
        allUsers: "False"
      };
      const response = await ApiClient.post(
        qualificationPackEndPoints.getQualificationPackDetails(bundleId),
        // call final data here as well.
        // setCommonData({
        //   badgeIds: data.badgeIds,
        //   locations: data.locations
        // });
        body
      );
      setMetrics(response?.response);
      countParticipant(response?.response);
      setLoading(false);
    } catch (error) {
      toast.error("Something went wrong.");
      setFailed(true);
    }
  };

  const countParticipant = (metrics: any) => {
    const count = Object.values(metrics)?.reduce((a: any, b: any) => a + b);
    setParticipantCount(count);
  };

  const getTopicsData = (data: any) => {
    setBundleTopicData(data);
  };

  const totalParticipantHandler = () => {
    history.push(
      `${routeNames.app}${routeNames.QUALIFICATIONPACKS}${routeNames.VIEWPARTICIPANTMAPPING}`,
      {
        qualificationPackDetails: qualiPackData,
        isTotalParticipationEnabled: true,
        packDetails: {
          selectionNoUpdated: 0
        }
      }
    );
  };

  return (
    <View>
      {loading ? (
        <View style={styles.loaderContainer}>
          <View style={styles.subLoaderContainer}>
            <Loader />
          </View>
        </View>
      ) : failed ? (
        <View style={styles.noFailedDataContainer}>Failed to load data</View>
      ) : (
        <View style={styles.container}>
          <View style={styles.rightContainer}>
            <View style={styles.subContainer}>
              <View style={styles.headerContainer}>
                <View style={styles.iconContainerHeader}>
                  <Icon
                    testID="down"
                    name={IconNames.down}
                    customStyle={styles.titleIcon}
                    onPress={() => {
                      history.goBack();
                    }}
                  />
                </View>
                <View>
                  <Text
                    fontWeight={FontWeight.Light}
                    testId="text"
                    textSize={TextSize.Small}
                    textStyle={[styles.headerText, styles.headerTextTitleSupport]}
                  >
                    {qualiPackData?.bundle?.name}
                  </Text>
                </View>
                <View>
                  <Text
                    onPress={() => {}}
                    fontWeight={FontWeight.Regular}
                    testId="text"
                    textSize={TextSize.Small}
                    textStyle={[styles.headerText, styles.headerTextSupport, styles.alignIcon]}
                  >
                    {">"}
                  </Text>
                </View>
                <View>
                  <Text
                    onPress={() => {}}
                    fontWeight={FontWeight.Regular}
                    testId="text"
                    textSize={TextSize.Small}
                    textStyle={[styles.headerText, styles.headerTextSupport]}
                  >
                    {/* {sessionDetail?.name} */}
                    {"Pack completion"}
                  </Text>
                </View>
              </View>
              <View>
                <QualificationViewDetailsTable
                  qualificationPackDetails={qualiPackData}
                  metrics={metrics}
                />
              </View>
            </View>
            <View style={styles.participantCountContainer}>
              <TouchableOpacity
                style={styles.alignContent}
                onPress={() => totalParticipantHandler()}
              >
                <Text
                  fontWeight={FontWeight.Regular}
                  testId="text"
                  textSize={TextSize.Small}
                  textStyle={[styles.headerText, styles.headerTextSupport, styles.totalCountText]}
                >
                  {"Total"}
                </Text>

                <Text
                  fontWeight={FontWeight.Regular}
                  testId="text"
                  textSize={TextSize.Small}
                  textStyle={[
                    styles.headerText,
                    styles.headerTextSupportCount,
                    styles.totalCountText
                  ]}
                >
                  {" : " + `${participantCount}` + " Participants"}
                </Text>
              </TouchableOpacity>
            </View>
          </View>
          <View style={styles.leftContainer}>
            <View style={{ marginTop: 60 }}>
              <QualiViewDetailsSidePanelPage
                getTopicsData={getTopicsData}
                qualiPackDetails={qualiPackData}
              />
            </View>
          </View>
        </View>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flexDirection: "row"
  },
  rightContainer: {
    display: "flex",
    padding: 20,
    paddingTop: 15,
    backgroundColor: "#F8F5F0",
    width: "calc(100% - 240px)"
  },
  loaderContainer: {
    padding: 20
  },
  subLoaderContainer: {
    backgroundColor: colorPallete.white,
    borderRadius: 20,
    padding: 20
  },
  leftContainer: {
    // height: "calc(100vh - 50px)",
    marginTop: -60,
    flex: 1,
    backgroundColor: colorPallete.cordioRedDark2,
    minHeight: "100vh"
  },

  titleIcon: {
    color: colorPallete.textBlack,
    fontSize: 9,
    marginRight: 10,
    transform: [{ rotate: "90deg" }]
  },
  headerText: {
    fontSize: 12,
    fontFamily: FontFamily.Medium,
    lineHeight: 17
  },
  subContainer: {
    backgroundColor: colorPallete.white,
    borderRadius: 10,
    marginBottom: 30,
    shadowColor: colorPallete.cordioRedDark1,
    shadowOpacity: 0.1,
    shadowOffset: {
      height: 10,
      width: 5
    },
    shadowRadius: 10
  },

  //Header Container
  headerTextTitleSupport: {
    fontWeight: "700"
  },
  headerContainer: {
    flexDirection: "row",
    alignItems: "center",
    padding: 18,
    paddingBottom: 0
  },
  headerTextSupport: {
    fontFamily: FontFamily.Regular,
    marginLeft: 10
  },
  iconContainerHeader: {
    marginRight: 10
  },
  alignIcon: {
    marginLeft: 10,
    marginRight: 0,
    textDecorationLine: "none"
  },
  participantCountContainer: {
    flexDirection: "row",
    justifyContent: "flex-end"
  },
  alignContent: {
    flexDirection: "row"
  },
  totalCountText: {
    fontSize: 14,
    textDecorationLine: "none"
  },
  headerTextSupportCount: {
    marginLeft: 10,
    fontFamily: FontFamily.Bold,
    lineHeight: 17,
    fontWeight: "700"
  },
  noFailedDataContainer: {
    padding: 25
  }
});
