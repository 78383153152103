import React, { ReactElement, useEffect, useState } from "react";
import { StyleSheet, View } from "react-native";
import { colorPallete } from "@socion-cordio/common/src/assets/styles/colors";

export const ProgressBar = (props: any) => {
  return (
    <View style={[styles.progressBar, props.progressBarStyles]}>
      <Filler percentage={props.percentage} />
    </View>
  );
};

const Filler = (props: any) => {
  return <View style={[styles.filler, { width: `${props.percentage}%` }]} />;
};

const styles = StyleSheet.create({
  progressBar: {
    height: 20,
    backgroundColor: colorPallete.lightGrey,
    borderRadius: 5
  },
  filler: {
    backgroundColor: colorPallete.cordioGreen,
    height: "100%",
    borderRadius: 5
  }
});
