import { call, put, takeLatest } from "redux-saga/effects";
import { PROGRAMS } from "@socion-cordio/common/src/modules/program/actions/actionTypes";
import { ProgramActions } from "@socion-cordio/common/src/modules/program/actions/actions";
import { ProgramRepository } from "@socion-cordio/common/src/repositories/program/program";
import { Program } from "@socion-cordio/common/src/models/program";
import { EntityInvite } from "@socion-cordio/common/src/models/entityInvite";

function* getProgramWorkerSaga() {
  try {
    const response: Program = yield call(ProgramRepository.getPrograms);
    yield put(ProgramActions.getProgramsSucess(response));
  } catch (error) {
    yield put(ProgramActions.getProgramsError(error));
  }
}

function* getEntityInviteWorkerSaga() {
  try {
    const response: EntityInvite = yield call(ProgramRepository.getEntityInvite);
    yield put(ProgramActions.getEntityInviteSuccess(response));
  } catch (error) {
    yield put(ProgramActions.getEntityInviteError(error));
  }
}

export default function* ProgramWatcherSaga() {
  yield takeLatest(PROGRAMS.GET_PROGRAMS.LOADING, getProgramWorkerSaga);
  yield takeLatest(PROGRAMS.GET_ENTITY_INVITE.LOADING, getEntityInviteWorkerSaga);
}
