import * as React from "react";
import {
  Image as RNImage,
  ImageResizeMode,
  ImageSourcePropType,
  ImageStyle,
  StyleProp,
  ImageURISource,
  StyleSheet,
  View
} from "react-native";

interface IImageProps {
  source: ImageSourcePropType;
  testId: string;
  imageStyle?: StyleProp<ImageStyle>;
  resizeMode?: ImageResizeMode;
  loadingImageSource?: ImageURISource;
}

export const Image = (props: IImageProps): React.ReactElement => {
  const { source, imageStyle, testId, loadingImageSource, resizeMode = "cover" } = props;
  return (
    <View style={styles.container}>
      <RNImage
        resizeMode={resizeMode}
        testID={testId}
        source={source}
        style={[styles.image, imageStyle]}
        loadingIndicatorSource={loadingImageSource}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    //container styles
  },
  image: {
    width: 100,
    height: 100
  }
});
