import { colorPallete } from "@socion-cordio/common/src/assets/styles/colors";
import React, { ReactElement } from "react";
import { View, StyleSheet, StyleProp, ViewStyle } from "react-native";
import ToggleSwitch from "toggle-switch-react-native";

interface IProps {
  testId?: string;
  isOn?: boolean;
  onColor?: string;
  offColor?: string;
  label?: string;
  size?: string;
  labelStyle?: object;
  onToggle?: () => void;
  customStyleContainer?: StyleProp<ViewStyle>;
  thumbOnStyle?: StyleProp<ViewStyle>;
  thumbOffStyle?: StyleProp<ViewStyle>;
  trackOnStyle?: StyleProp<ViewStyle>;
  trackOffStyle?: StyleProp<ViewStyle>;
}

export const Switch = (props: IProps): ReactElement => {
  const {
    testId,
    isOn = false,
    onColor = colorPallete.cordioRedDark1,
    offColor = colorPallete.cordioRedLight1,
    label,
    size = "medium",
    onToggle,
    customStyleContainer,
    thumbOnStyle,
    thumbOffStyle,
    trackOnStyle,
    trackOffStyle
  } = props;

  return (
    <View style={[styles.container, customStyleContainer]} testID={`chk${testId}`}>
      <ToggleSwitch
        isOn={isOn}
        onColor={onColor}
        offColor={offColor}
        size={size}
        onToggle={onToggle}
        thumbOnStyle={[styles.thumbOnStyle, thumbOnStyle]}
        thumbOffStyle={[styles.thumbOffStyle, thumbOffStyle]}
        trackOnStyle={[styles.trackStyle, trackOnStyle]}
        trackOffStyle={[styles.trackStyle, trackOffStyle]}
      />
    </View>
  );
};
const styles = StyleSheet.create({
  container: {},

  // Default Switch Style
  thumbOnStyle: {
    height: 22,
    width: 23,
    left: 2,
    borderRadius: 50
  },
  thumbOffStyle: {
    height: 22,
    width: 23,
    left: 3,
    borderRadius: 50
  },
  trackStyle: {
    height: 24,
    width: 46,
    padding: 0,
    borderRadius: 50
  }
});
