import React, { ReactElement, useEffect, useState } from "react";
import { StyleSheet, View } from "react-native";
import { colorPallete } from "@socion-cordio/common/src/assets/styles/colors";
import ButtonTab, {
  ButtonTypeSecondary
} from "@socion-cordio/common/src/components/atoms/buttonTab";
import { FontFamily } from "@socion-cordio/common/src/components/atoms/text";
import RoleVerification from "@socion-cordio/common/src/components/organisms/verify/roleVerification";
import QualificationVerification from "@socion-cordio/common/src/components/organisms/verify/qualificationVerification";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

interface Props {}

export default function VerifyPage(props: Props): ReactElement {
  const [isRoleVerification, setIsRoleVerification] = useState<boolean>(true);
  const [isQualificationVerification, setIsQualificationVerification] = useState<boolean>(false);
  const location: any = useLocation();

  const programDetails: any = JSON.parse(localStorage.getItem("programDetails"));

  let roles = useSelector((state: any) => state?.userRolesProgram);
  let userRoles = roles.userRolesData.response;

  useEffect(() => {
    (async () => {
      await processActiveTab();
    })();
  }, [location?.state?.activeTab]);

  const processActiveTab = () => {
    if (location?.state?.activeTab === "QUALIFICATION") {
      setIsRoleVerification(false);
      setIsQualificationVerification(true);
    } else if (location?.state?.activeTab === "ROLES") {
      setIsRoleVerification(true);
      setIsQualificationVerification(false);
    }
  };

  return (
    <View style={styles.container}>
      <View style={styles.subContainer}>
        <View style={styles.toggleContainer}>
          {/* <ButtonGroup/> */}
          <ButtonTab
            type={isRoleVerification ? ButtonTypeSecondary.Secondary : ButtonTypeSecondary.Primary}
            buttonStyles={[
              styles.toggleButton,
              { borderBottomLeftRadius: 5, borderTopLeftRadius: 5 }
            ]}
            textStyles={styles.textStyle}
            title="Role verification"
            onPress={() => {
              setIsRoleVerification(true);
              setIsQualificationVerification(false);
            }}
          />
          <ButtonTab
            type={
              isQualificationVerification
                ? ButtonTypeSecondary.Secondary
                : ButtonTypeSecondary.Primary
            }
            buttonStyles={[
              styles.toggleButton,
              { borderBottomRightRadius: 5, borderTopRightRadius: 5 }
            ]}
            textStyles={styles.textStyle}
            title="Qualification verification"
            onPress={() => {
              setIsRoleVerification(false);
              setIsQualificationVerification(true);
            }}
          />
        </View>
        {isRoleVerification ? (
          <RoleVerification programDetails={programDetails} userRoles={userRoles} />
        ) : isQualificationVerification ? (
          <QualificationVerification programDetails={programDetails} userRoles={userRoles} />
        ) : null}
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    display: "flex",
    padding: 20,
    paddingTop: 15,
    backgroundColor: "#F8F5F0",
    minHeight: "calc(100vh - 50px)"
  },
  header: {
    display: "flex",
    flexDirection: "row"
  },
  iconContainer: {
    marginTop: 10
  },
  titleIcon: {
    fontSize: 15
  },
  subContainer: {
    backgroundColor: colorPallete.white,
    borderRadius: 10,
    shadowColor: colorPallete.cordioRedDark1,
    shadowOpacity: 0.1,
    shadowOffset: {
      height: 10,
      width: 5
    },
    shadowRadius: 10
  },
  containerStyle: {
    width: "20%",
    marginLeft: 15
  },
  dropDownContainerStyle: {
    borderWidth: 0,
    borderTopRightRadius: 5,
    borderBottomRightRadius: 5
  },
  toggleContainer: {
    flexDirection: "row",
    borderRadius: 20,
    padding: 18,
    paddingBottom: 0
  },
  toggleButton: {
    width: 180
  },
  textStyle: {
    fontSize: 12
  },
  alignHeader: {
    marginBottom: 30
  },
  headerText: {
    fontSize: 14,
    fontFamily: FontFamily.Medium,
    lineHeight: 19.5,
    fontWeight: "700"
  },
  iconStyle: {
    fontSize: 18,
    marginRight: 35
  }
});
