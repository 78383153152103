import { deserialize, serialize } from "@socion-cordio/common/src/mappers/apiResMapper";
import { Login } from "@socion-cordio/common/src/models/login";
import { ApiClient } from "@socion-cordio/common/src/network/apiClient";
import { loginEndPoints } from "@socion-cordio/common/src/repositories/endPoints";
import { RegisterNewUser } from "@socion-cordio/common/src/models/signup";

class LoginRepository {
  postCredentials = async (body: {
    password: string;
    userName: number;
    countryCode: number;
  }): Promise<Login> => {
    const loginData = await ApiClient.post(loginEndPoints.postCredentials(), body);
    // const loginData: Login = deserialize(Login, data);
    return loginData;
  };

  loginUser = async (body: Object): Promise<RegisterNewUser> => {
    let response = await ApiClient.post(loginEndPoints.loginUser(), body);
    return response;
  };

  validateLoginUserOtp = async (body: Object): Promise<Login> => {
    let response = await ApiClient.post(loginEndPoints.validateLoginUserOtp(), body);
    return response;
  };
}
const loginRepository = new LoginRepository();
export { loginRepository as LoginRepository };
