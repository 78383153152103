import React, { useEffect, useState } from "react";
import { StyleSheet, TouchableOpacity, View } from "react-native";
import { colorPallete } from "@socion-cordio/common/src/assets/styles/colors";
import {
  FontFamily,
  FontWeight,
  Text,
  TextSize
} from "@socion-cordio/common/src/components/atoms/text";
import { Image } from "@socion-cordio/common/src/components/atoms/image";
import FilePicker from "@socion-cordio/common/src/components/molecules/filePicker";
import { toast } from "react-toastify";
import { ArtefactRepository } from "@socion-cordio/common/src/repositories/artefact/artefact";
import { getImage } from "../helpers/artefactHelper";
import { CONFIG } from "@socion-cordio/common/src/config/envConstant";

const AddArtefactDocs = (props: any) => {
  const [uploadedDocs, setUploadedDocs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isUploading, setIsUploading] = useState(null);
  const [uploadingIndex, setUploadingIndex] = useState(null);

  useEffect(() => {
    setCookie();
  }, []);

  const setCookie = async () => {
    const res = await ArtefactRepository.setCookie().catch((err) => {
      console.log("Error", err);
    });
  };

  useEffect(() => {}, [uploadedDocs]);

  useEffect(() => {
    if (isUploading !== null) {
      props.isUploading(isUploading);
    }
  }, [isUploading]);

  const handleUploadedDocuments = (index: number) => {
    let tempArray = [...uploadedDocs];
    tempArray.splice(index, 1);
    setUploadedDocs(tempArray);
    props.uploadedDocs(tempArray);
  };

  const handleCustomChange = (e: any) => {
    toggleUpload(true);
    const uploadedFile = e?.currentTarget?.files[0] || e?.target?.files[0];
    const validTypes = props.acceptedFormat;
    const splitFileName = uploadedFile?.name.split(".");
    let extension = splitFileName[splitFileName.length - 1];
    console.log("acceptedFormatacceptedFormat", props, extension);

    if (uploadedDocs.includes(uploadedFile?.name)) {
      toast.error("Document already added");
      toggleUpload(false);
      return;
    }
    if (!props?.acceptedFormat.includes(`.${extension}`)) {
      toggleUpload(false);
      toast.error(
        `Invalid File Type! Only Files with format of ${props?.acceptedFormat} will be uploaded!!`
      );
      return;
    }

    if (e?.target?.validity?.valid && validTypes.includes(`.${extension}`)) {
      let uploadDocs = [...uploadedDocs];
      uploadDocs.push(uploadedFile);
      setUploadingIndex(uploadDocs.length - 1);
      setUploadedDocs(uploadDocs);
      toggleUpload(false);
      props.uploadedDocs(uploadDocs);
    }
  };

  const toggleUpload = (visible: boolean) => {
    setLoading(visible);
    setIsUploading(visible);
  };

  const getDocData = (doc: any, index: number, type: string, docs: Array<any>) => {
    return (
      <>
        {(index !== uploadingIndex || (index === uploadingIndex && !loading)) && (
          <View style={styles.qualificationContainer} key={index}>
            <TouchableOpacity style={{ width: "90%" }} key={index + "click"}>
              <View style={styles.imageText} key={index + "view"}>
                {(doc?.fileType || doc?.type) && (
                  <Image
                    testId={index + "edit"}
                    source={getImage(doc?.fileType || doc?.type)}
                    imageStyle={{
                      width: 14,
                      height: 14,
                      marginRight: "10px"
                    }}
                  />
                )}
                {docs.length > 0 && (
                  <a
                    href={
                      CONFIG.REACT_APP_ARTEFACT_API_BASE_URL + "/artifact-store/" + doc?.fileName
                    }
                    target="_blank"
                  >
                    <Text
                      fontWeight={FontWeight.Regular}
                      testId="qualificationTitleText"
                      textSize={TextSize.Small}
                      textStyle={styles.qualificationTitle}
                    >
                      {doc?.fName || doc?.name}
                    </Text>
                  </a>
                )}
              </View>
            </TouchableOpacity>

            <TouchableOpacity
              style={{ width: "10%" }}
              key={index + "click 2"}
              onPress={() => {
                handleUploadedDocuments(index);
              }}
            >
              <Image
                testId={index + "remove"}
                source={require(`@socion-cordio/common/src/assets/images/remove.svg`)}
                imageStyle={{
                  width: 14,
                  height: 14
                }}
              />
            </TouchableOpacity>
          </View>
        )}
      </>
    );
  };

  return (
    <>
      <FilePicker
        acceptedFormat={props?.acceptedFormat}
        isDisabled={loading}
        contentText={"Select supporting documents to upload"}
        onChange={async (e) => {
          await handleCustomChange(e);
        }}
        textStyles={styles.uploadHeaderText}
      />
      {uploadedDocs &&
        uploadedDocs.length > 0 &&
        uploadedDocs.map((doc: any, index: number) => {
          return getDocData(doc, index, "upload", uploadedDocs);
        })}
    </>
  );
};

const styles = StyleSheet.create({
  qualificationContainer: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row",
    alignItems: "center",
    borderRadius: 5,
    height: "64px",
    width: "100%",
    marginRight: "5px",
    padding: 15,
    marginTop: 20,
    borderColor: "#3FA202",
    borderStyle: "solid",
    borderWidth: 1
  },
  qualificationTitle: {
    // fontWeight: "700",
    fontSize: 12,
    fontFamily: FontFamily.Light,
    color: colorPallete.blue,
    lineHeight: 17,
    textDecorationLine: "underline",
    width: "100%",
    display: "flex",
    flexWrap: "wrap"
  },
  uploadHeaderText: {
    fontWeight: "400",
    fontSize: 14,
    fontFamily: FontFamily.Regular,
    lineHeight: 17,
    color: colorPallete.textLight
  },
  uploadDocIcon: {
    opacity: 0.5
  },
  imageText: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    width: "100%",
    flexWrap: "wrap"
  }
  // loader: {
  //   marginTop: 10,
  //   width: "10%",
  //   height: 20,
  //   transform: [{ scale: 0.8 }]
  // }
});
export default AddArtefactDocs;
