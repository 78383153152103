import React, { ReactElement, useEffect, useState } from "react";
import { StyleSheet, View, TouchableOpacity } from "react-native";
import { colorPallete } from "@socion-cordio/common/src/assets/styles/colors";
import {
  Text,
  TextSize,
  FontWeight,
  FontFamily
} from "@socion-cordio/common/src/components/atoms/text";
import Icon, { IconNames } from "@socion-cordio/common/src/components/atoms/icon";
import { GenerateQR } from "@socion-cordio/common/src/components/atoms/generateQR";
import { DownloadQR } from "@socion-cordio/common/src/components/atoms/downloadQR";
import DescriptionDetails from "@socion-cordio/common/src/components/organisms/program/descriptionDetails";
import DocumentsDetails from "@socion-cordio/common/src/components/organisms/program/documentsDetails";
import SettingsDetails from "@socion-cordio/common/src/components/organisms/program/settingsDetails";
import RolesPrivilegesbyAppDetails from "@socion-cordio/common/src/components/organisms/program/rolesPrivilegesbyAppDetails";
import { Switch } from "@socion-cordio/common/src/components/atoms/switches";
import { programEndPoints } from "@socion-cordio/common/src/repositories/endPoints";
import { ApiClient } from "@socion-cordio/common/src/network/apiClient";
import Loader from "@socion-cordio/common/src/components/atoms/loader";
import { dataHelper } from "@socion-cordio/common/src/utils/dataHelper";
import TextField from "@socion-cordio/common/src/components/atoms/textField";
import DatePicker from "@socion-cordio/common/src/components/molecules/datePicker";
import Moment from "moment";
import { toast, ToastContainer } from "react-toastify";
import { HTTP_STATUS_CODES } from "@socion-cordio/common/src/network/constants";
import AddTelemetryService from "@socion-cordio/common/src/services/telemetryService";
import { LocalStorage } from "@socion-cordio/common/src/services/storage/storageService";
import { QR } from "@socion-cordio/common/src/components/atoms/qrCode";
import { useHistory } from "react-router-dom";
import RemoveLinkModal from "@socion-cordio/common/src/components/organisms/common-modals/removeLinkModal";
import { allRoutesNames } from "@socion-cordio/web/src/navigation/allRouteNames";
interface Props {
  onSubmit?: (data: { otp: number }) => void;
  programDetails: any;
  userRoles: any;
  isSuperAdmin: any;
  getProgramStatus?: Function;
  programStatus?: Function;
  setProgramDetails?: Function;
}

export default function ProgramDetailsPage(props: Props): ReactElement {
  const {
    programDetails,
    userRoles,
    isSuperAdmin,
    getProgramStatus,
    programStatus,
    setProgramDetails
  } = props;
  const [loading, setLoading] = useState(true);
  const [failed, setFailed] = useState(false);
  const [isProgramEnabled, setIsProgramEnabled] = useState(false);
  const [programName, setProgramName] = useState(null);
  const [userLimit, setUserLimit] = useState(null);
  const [programStartDate, setProgramStartDate] = useState(null);
  const [programEndDate, setProgramEndDate] = useState(null);
  const [programDetailsValue, setProgramDetailsValue] = useState(null);
  const [userLicenses, setUserLicenses] = useState(0);
  const [isEditable, setIsEditable] = useState(false);
  const [isNumber, setIsNumber] = useState(false);
  const [memberRole, setMemberRoles] = useState([]);
  const [customError, setCustomError] = useState({ programName: "", userLimit: "" });
  const [showRemoveModal, setShowRemoveModal] = useState(false);
  const isEntityEnabled = JSON.parse(localStorage.getItem("isEntityDeleted"));
  const history: any = useHistory();

  useEffect(() => {
    getProgramDetails();
    getUserLicenses();
  }, []);
  // empty because only rendering once.

  useEffect(() => {
    let programNameErrorMessage =
      programName == null || programName == "" ? "Enter program name" : "";
    let userLimitErrorMessage = userLimit == null || userLimit == "" ? "Enter user limit" : "";
    setCustomError({
      ...customError,
      programName: programNameErrorMessage,
      userLimit: userLimitErrorMessage
    });
  }, [programName, userLimit]);

  useEffect(() => {
    const memberRole = userRoles?.userRolesData?.response;
    setMemberRoles(memberRole);
  }, []);

  const getProgramDetails = async () => {
    try {
      const { program } = programDetails;
      const detailsResponse = await ApiClient.get(programEndPoints.getProgramDetails(program.id));
      setProgramDetailsValue(detailsResponse);
      setDetails(detailsResponse);
      setProgramName(detailsResponse?.name);
      setUserLimit(detailsResponse?.userLimit);
      getProgramStatus(detailsResponse?.deleted);
      let programDataObj = programDetails;
      programDataObj.program = detailsResponse;
      LocalStorage.setStorage("programDetails", programDataObj);
      setLoading(false);
      setFailed(false);
    } catch (error) {
      setLoading(false);
      setFailed(true);
    }
  };

  const getUserLicenses = async () => {
    try {
      const { program } = programDetails;
      const userLicensesResponse = await ApiClient.get(
        programEndPoints.getUserLicensesById(program.id)
      );
      setUserLicenses(userLicensesResponse.users);
      setUserLicenses(userLicensesResponse.users);
    } catch (error) {}
  };

  const setDetails = (data: any) => {
    const formatEndDate = formatDateHandler(data?.endDate);
    const formatStartDate = formatDateHandler(data?.startDate);
    setProgramStartDate(formatStartDate);
    setProgramEndDate(formatEndDate);
    isDeletedProgram(data);
  };

  const isDeletedProgram = (data: any) => {
    data.deleted === true ? setIsProgramEnabled(false) : setIsProgramEnabled(true);
  };

  const activateDeactivatProgramHandler = async (data: boolean) => {
    setShowRemoveModal(true);
  };

  const handleActivateDeactivate = async () => {
    try {
      const { program } = programDetails;
      const response = await ApiClient.put(
        programEndPoints.activateDetactivateProgram(program.id, isProgramEnabled),
        {}
      );
      getProgramDetails();
      toast.success(response.message);
      cancelEditHander();
      updateTelemetryProgramStatus(isProgramEnabled);
      history.push(allRoutesNames.app + allRoutesNames.workspace);
      setShowRemoveModal(false);
    } catch (error) {
      cancelEditHander();
      toast.error("Unauthorized: User is not a Super Admin.");
      setShowRemoveModal(false);
    }
  };

  const updateTelemetryProgramStatus = async (data: boolean) => {
    const deleted = !data;
    let eventType = "";
    let date = null;
    // true "Successfully reactivated program"
    // false "Successfully deactivated program"
    const user: any = await LocalStorage.getStorage("user");
    const programDetails: any = await LocalStorage.getStorage("programDetails");
    deleted === true
      ? [(eventType = "Reactivate Program"), (date = programDetails.program.startDate)]
      : [(eventType = "Deactivate Program"), (date = null)];

    const body = {
      timestamp: Moment().toLocaleString(),
      createdAt: Moment().toLocaleString(),
      deleted: "false",
      updatedAt: Moment().toLocaleString(),
      eventType: eventType,

      programId: programDetails.program.id,
      programName: programDetails.program.name,
      sessionStartDate: new Date(programDetails.program.startDate)
        .toISOString()
        .replace("T", " ")
        .replace("Z", ""),
      sessionEndDate: new Date(programDetails.program.endDate)
        .toISOString()
        .replace("T", " ")
        .replace("Z", ""),

      entityId: programDetails.program.entity.registryEntityId,
      entityName: programDetails.program.entity.name
    };
    AddTelemetryService(body, programDetails, user);
  };

  const editHandler = () => {
    setIsEditable(true);
  };

  const cancelEditHander = () => {
    const formatEndDate = formatDateHandler(programDetailsValue?.endDate);
    const formatStartDate = formatDateHandler(programDetailsValue?.startDate);
    setProgramName(programDetailsValue?.name);
    setUserLimit(programDetailsValue?.userLimit);
    setProgramStartDate(formatStartDate);
    setProgramEndDate(formatEndDate);
    setIsEditable(false);
  };

  const formatDateHandler = (date: string, format?: string) => {
    let dateFormat = format ? format : "YYYY-MM-DD";
    return Moment(date).format(dateFormat);
  };

  const numberCheck = (number: any) => {
    var re = /^[0-9\b]+$/;
    return re.test(number);
  };

  const saveDetialsHandler = async () => {
    try {
      const { program } = programDetails;
      const newPorgramDetails = {
        name: programName,
        description: programDetailsValue.description,
        startDate: programStartDate || programDetailsValue.startDate,
        endDate: programEndDate || programDetailsValue.endDate,
        email: programDetailsValue.email,
        entityId: programDetailsValue.entity.id,
        entityName: programDetailsValue.entity.name,
        sessionScanMode: programDetailsValue.sessionScanMode,
        acceptingVerifications: programDetailsValue.acceptingVerifications,
        isHyperLinked: programDetailsValue.hyperlinked,
        createdBy: programDetailsValue.createdBy,
        userLimit: +userLimit || programDetailsValue.userLimit
      };
      const programDetailsResponse = await ApiClient.put(
        programEndPoints.updateProgramDetails(program.id),
        newPorgramDetails
      );
      toast.success("Program details updated successfully");
      getProgramDetails();
      setIsEditable(false);
      if (
        programName !== programDetails.program.name ||
        dataHelper.formatDate(programStartDate) !==
          dataHelper.formatDate(programDetails.program.startDate) ||
        dataHelper.formatDate(programEndDate) !==
          dataHelper.formatDate(programDetails.program.endDate)
      ) {
        updateTelemetry(programDetailsResponse);
      }
    } catch (error) {
      toast.error("Unauthorized: User is not a Super Admin");
      setIsEditable(false);
    }
  };

  const updateTelemetry = async (data: any) => {
    const user: any = await LocalStorage.getStorage("user");
    const programDetails: any = await LocalStorage.getStorage("programDetails");
    const body = {
      createdAt: Moment().toLocaleString(),
      deleted: "false",
      updatedAt: Moment().toLocaleString(),
      eventType: "Update Program",
      sessionStartDate: new Date(data.startDate).toISOString().replace("T", " ").replace("Z", ""),
      sessionEndDate: new Date(data.endDate).toISOString().replace("T", " ").replace("Z", ""),
      programName: data?.name
    };
    AddTelemetryService(body, programDetails, user);
  };

  useEffect(() => {});

  const onSubmitForm = (data: { otp: number }) => {
    props.onSubmit(data);
  };

  const onDelete = () => {
    // console.log("Delete");
  };
  const onEdit = () => {};
  const onDownload = () => {};

  return (
    <View>
      <ToastContainer />
      {loading ? (
        <Loader />
      ) : failed ? (
        <View>Failed to load data</View>
      ) : (
        <View style={styles.container}>
          <View style={styles.subContainer}>
            <View style={styles.subContainerOne}>
              <View style={styles.detailsContainerOne}>
                <View style={styles.qrContainer}>
                  <View>
                    <View style={styles.qrCode}>
                      <View>
                        <View style={styles.qrCode} nativeID="generateQR">
                          <View style={styles.displayQR} nativeID="displayQR">
                            Entity: {programDetailsValue.entity.name}
                            <br />
                            Program: {programName}
                            <br />
                          </View>
                          <View nativeID="divider"> </View>
                          <View nativeID="qrCodeContainer">
                            <GenerateQR
                              id="qrCode"
                              size={125}
                              value={"id:" + programDetailsValue?.id + ", QRtype: PROGRAM"}
                            />
                          </View>
                        </View>
                      </View>
                    </View>
                  </View>
                  <TouchableOpacity
                    style={styles.footerQrCode}
                    onPress={() =>
                      DownloadQR({ id: "generateQR", qrTitle: programDetailsValue?.name })
                    }
                  >
                    <Icon
                      testID="download"
                      name={IconNames.downloadList}
                      customStyle={styles.titleIcon}
                    />
                    <View style={styles.qrDownloadText}>
                      <Text
                        fontWeight={FontWeight.Light}
                        testId="addRoleText"
                        textSize={TextSize.Small}
                        textStyle={[
                          styles.headerText,
                          styles.headerAlignment,
                          styles.headerTextQrSupport
                        ]}
                      >
                        Download QR
                      </Text>
                    </View>
                  </TouchableOpacity>
                </View>
                <View style={styles.basicDetailsOne}>
                  <View style={styles.basicDetailsOneHeader}>
                    {!isEditable && (
                      <Text
                        fontWeight={FontWeight.Light}
                        testId="addRoleText"
                        textSize={TextSize.Small}
                        textStyle={[styles.headerText, styles.headerTextTitleSupport]}
                      >
                        {programName}
                      </Text>
                    )}
                    {isEditable && (
                      <View style={{ marginRight: "-38%" }}>
                        <TextField
                          label=""
                          handleChange={(programName: string) => {
                            setProgramName(programName);
                          }}
                          handleBlur={() => {}}
                          value={programName}
                          inputStyle={[styles.inputStylings, styles.inputStylingAlignment]}
                          placeholder="Enter Program Name"
                          name="programName"
                          id="programName"
                          noFormik
                          // editable={editable}
                        />
                      </View>
                    )}
                    {customError.programName !== "" ? (
                      <View>
                        <Text
                          fontWeight={FontWeight.Regular}
                          testId="internal"
                          textSize={TextSize.Small}
                          textStyle={[
                            styles.subheaderText,
                            styles.alignHeaderText,
                            styles.customError
                          ]}
                        >
                          {customError.programName}
                        </Text>
                      </View>
                    ) : null}
                    {!isEditable && !programDetailsValue?.deleted && (
                      <View style={styles.editDeleteContainer}>
                        {props.isSuperAdmin && (
                          <Icon
                            onPress={editHandler}
                            testID="edit"
                            name={IconNames.edit}
                            customStyle={[styles.alignEditDeleteButton]}
                          />
                        )}
                      </View>
                    )}
                    {isEditable && (
                      <View style={styles.editDeleteContainer}>
                        {isNumber &&
                          customError.programName == "" &&
                          !(programStartDate > programEndDate) && (
                            <Icon
                              onPress={saveDetialsHandler}
                              testID="save"
                              name={IconNames.checkBoxTicked}
                              customStyle={[styles.alignEditDeleteButton]}
                            />
                          )}
                        {(!isNumber ||
                          customError.programName !== "" ||
                          programStartDate > programEndDate) && (
                          <Icon
                            onPress={() => {}}
                            testID="save"
                            name={IconNames.checkBoxTicked}
                            customStyle={[
                              styles.alignEditDeleteButton,
                              { opacity: 0.1, pointerEvents: "none" }
                            ]}
                          />
                        )}
                        <Icon
                          onPress={cancelEditHander}
                          testID="cancel"
                          name={IconNames.crossCircle}
                          customStyle={[styles.alignEditDeleteButton]}
                        />
                      </View>
                    )}
                  </View>

                  <View style={{ flexDirection: "row", alignItems: "center" }}>
                    <View style={{ width: "28%" }}>
                      <View style={styles.titleInputContainer}>
                        <View style={styles.alignSubTextLabel}>
                          <Text
                            fontWeight={FontWeight.Regular}
                            testId="internal"
                            textSize={TextSize.Small}
                            textStyle={[styles.subheaderText, styles.alignHeaderText]}
                          >
                            User licenses
                          </Text>
                        </View>
                        <View style={styles.alignSubText}>
                          {!isEditable && (
                            <Text
                              fontWeight={FontWeight.Light}
                              testId="internal"
                              textSize={TextSize.Small}
                              textStyle={[
                                styles.subheaderText,
                                styles.alignHeaderText,
                                styles.alignText
                              ]}
                            >
                              {": " + " " + `${userLicenses}/${userLimit}`}
                            </Text>
                          )}
                          {isEditable && (
                            <View>
                              <TextField
                                label=""
                                handleChange={(userLimit: number) => {
                                  setUserLimit(userLimit);
                                }}
                                handleBlur={() => {
                                  if (!numberCheck(userLimit)) {
                                    setIsNumber(false);
                                  } else {
                                    setIsNumber(true);
                                  }
                                }}
                                value={userLimit}
                                inputStyle={[styles.inputStylings, styles.inputStylingAlignment]}
                                placeholder="Enter UserLimit"
                                name="userLimit"
                                id="userLimit"
                                noFormik
                                isNumeric={true}
                              />
                            </View>
                          )}
                        </View>
                      </View>
                    </View>
                    <View>
                      {!isNumber && isEditable && (
                        <View>
                          <Text
                            fontWeight={FontWeight.Regular}
                            testId="internal"
                            textSize={TextSize.Small}
                            textStyle={[
                              styles.subheaderText,
                              styles.alignHeaderText,
                              // styles.alignText,
                              styles.errorText
                            ]}
                          >
                            Please enter a valid number.
                          </Text>
                        </View>
                      )}
                    </View>
                  </View>
                  <View style={styles.inputContainerFields}>
                    <View style={styles.labelContainer}>
                      <View style={styles.titleInputContainer}>
                        <View style={styles.alignSubTextLabel}>
                          <Text
                            fontWeight={FontWeight.Regular}
                            testId="internal"
                            textSize={TextSize.Small}
                            textStyle={[styles.subheaderText, styles.alignHeaderText]}
                          >
                            Start Date
                          </Text>
                        </View>
                        <View style={styles.alignSubText}>
                          {!isEditable && (
                            <Text
                              fontWeight={FontWeight.Light}
                              testId="internal"
                              textSize={TextSize.Small}
                              textStyle={[
                                styles.subheaderText,
                                styles.alignHeaderText,
                                styles.alignText
                              ]}
                            >
                              {": " + " " + dataHelper.formatDate(programStartDate)}
                            </Text>
                          )}
                          {isEditable && (
                            <View>
                              <DatePicker
                                min="2019-01-01"
                                max="2022-12-31"
                                handleBlur={() => {}}
                                onChange={(programStartDate: any) => {
                                  setProgramStartDate(programStartDate.target.value);
                                  setProgramEndDate("");
                                }}
                                value={programStartDate}
                                name="roleStartDate"
                                id="roleStartDate"
                                noFormik
                              />
                            </View>
                          )}
                        </View>
                      </View>
                    </View>
                    <View style={styles.labelContainer}>
                      <View style={styles.titleInputContainer}>
                        <View style={styles.alignSubTextLabel}>
                          <Text
                            fontWeight={FontWeight.Regular}
                            testId="internal"
                            textSize={TextSize.Small}
                            textStyle={[styles.subheaderText, styles.alignHeaderText]}
                          >
                            End Date
                          </Text>
                        </View>
                        <View style={styles.alignSubText}>
                          {!isEditable && (
                            <Text
                              fontWeight={FontWeight.Light}
                              testId="internal"
                              textSize={TextSize.Small}
                              textStyle={[
                                styles.subheaderText,
                                styles.alignHeaderText,
                                styles.alignText
                              ]}
                            >
                              {": " + " " + dataHelper.formatDate(programEndDate)}
                            </Text>
                          )}
                          {isEditable && (
                            <View>
                              <DatePicker
                                min="2019-01-01"
                                max="2022-12-31"
                                handleBlur={() => {}}
                                onChange={(programEndDate: any) => {
                                  setProgramEndDate(programEndDate.target.value);
                                }}
                                value={programEndDate}
                                name="roleStartDate"
                                id="roleStartDate"
                                noFormik
                              />
                            </View>
                          )}
                        </View>
                      </View>
                    </View>
                    <View style={[styles.alignView, styles.toggleButtonContainer]}>
                      {props.isSuperAdmin && !isEntityEnabled && (
                        <View style={styles.radioButtonContainer}>
                          <Switch
                            isOn={isProgramEnabled}
                            onColor="#B7504A"
                            offColor="#F1DCDB"
                            labelStyle={{ color: "black", fontWeight: "900" }}
                            size="medium"
                            onToggle={() => [
                              // setIsProgramEnabled((isProgramEnabled) => !isProgramEnabled),
                              // activateDeactivatProgramHandler(!isProgramEnabled)
                              activateDeactivatProgramHandler(isProgramEnabled)
                            ]}
                          />
                          <Text
                            fontWeight={FontWeight.Light}
                            testId="addRoleText"
                            textSize={TextSize.Small}
                            textStyle={[styles.headerText, styles.toggleButtonText]}
                          >
                            {isProgramEnabled ? "Active" : "Inactive"}
                          </Text>
                        </View>
                      )}
                    </View>
                  </View>
                </View>
              </View>
            </View>
            <View style={styles.subContainerTwo}>
              <View style={styles.subContainerTwoLeft}>
                <View style={styles.alignSubContainer}>
                  <DescriptionDetails
                    description={programDetailsValue?.description}
                    userRoles={memberRole}
                    isSuperAdmin={props.isSuperAdmin}
                    programStatus={programStatus}
                    getProgramDetails={getProgramDetails}
                  />
                </View>
                <View style={styles.alignSubContainer}>
                  <DocumentsDetails
                    documentsDetails={programDetailsValue}
                    userRoles={memberRole}
                    isSuperAdmin={props.isSuperAdmin}
                    entityId={programDetailsValue?.entity?.registryEntityId}
                    programId={programDetailsValue?.id}
                    programStatus={programStatus}
                    setLoading={setLoading}
                  />
                </View>
              </View>
              <View style={styles.subContainerTwoRight}>
                <View style={styles.alignSubContainer}>
                  <SettingsDetails
                    settingsDetails={programDetailsValue}
                    userRoles={memberRole}
                    isSuperAdmin={props.isSuperAdmin}
                    programStatus={programStatus}
                    getProgramDetails={getProgramDetails}
                  />
                </View>
                <View style={styles.alignSubContainer}>
                  <RolesPrivilegesbyAppDetails
                    programData={programDetails}
                    userRoles={memberRole}
                    isSuperAdmin={props.isSuperAdmin}
                    programStatus={programStatus}
                  />
                </View>
              </View>
            </View>
            {showRemoveModal && (
              <RemoveLinkModal
                modalVisible={showRemoveModal}
                selectedData={handleActivateDeactivate}
                textValue={
                  isProgramEnabled
                    ? "Are you sure you want to deactivate this Program?"
                    : "Are you sure you want to reactivate this Program?"
                }
                deleteButtonText={isProgramEnabled ? "Deactivate" : "Reactivate"}
                setModalVisible={() => setShowRemoveModal(!showRemoveModal)}
              />
            )}
          </View>
        </View>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    display: "flex",
    padding: 18,
    paddingTop: 0
  },
  header: {
    display: "flex",
    flexDirection: "row"
  },
  iconContainer: {
    marginTop: 10
  },
  titleIcon: {
    fontSize: 15
  },
  headerText: {
    fontSize: 12,
    fontFamily: FontFamily.Medium,
    lineHeight: 17
  },
  headerTextQrSupport: {
    fontWeight: "400",
    textDecorationLine: "underline",
    marginLeft: 10,
    fontFamily: FontFamily.Medium,
    color: colorPallete.textBlack
  },
  subContainer: {
    backgroundColor: colorPallete.white
  },
  containerStyle: {
    width: "20%",
    marginLeft: 15
  },
  alignTextFieldWidth: {
    width: "100%",
    fontSize: 12
  },
  alignTextWidth: {
    flex: 40,
    fontSize: 12
  },
  dropDownContainerStyle: {
    borderWidth: 0,
    borderTopRightRadius: 5,
    borderBottomRightRadius: 5
  },
  toggleContainer: {
    flexDirection: "row"
  },
  toggleButton: {
    width: 180
  },
  subContainerOne: {},
  detailsContainerOne: {
    marginTop: 20,
    marginBottom: 20,
    flexDirection: "row",
    flex: 1
  },
  qrCode: {
    flex: 100,
    maxWidth: 130,
    minHeight: 130
  },
  qrCodeImage: {
    width: 120,
    height: 120
  },
  footerQrCode: {
    // marginTop: 10,
    flexDirection: "row",
    justifyContent: "center"
  },
  qrDownloadText: {},
  qrContainer: {
    flex: 13
  },
  basicDetailsOne: {
    marginLeft: 10,
    flex: 87
  },
  basicDetailsOneHeader: {
    flexDirection: "row",
    justifyContent: "space-between",
    minHeight: 40,
    alignItems: "center"
  },
  editDeleteContainer: {
    flexDirection: "row"
  },
  alignEditDeleteButton: {
    fontSize: 15,
    marginLeft: 30,
    color: colorPallete.cordioTaupe
  },
  headerTextTitleSupport: {
    fontWeight: "700"
  },
  inputContainerFields: {
    flexDirection: "row",
    flexWrap: "wrap"
  },
  labelContainer: {
    width: "28%",
    marginRight: 15
  },
  toggleButtonContainer: {
    width: "20%",
    justifyContent: "center"
  },
  radioButtonContainer: {
    flexDirection: "row"
  },
  toggleButtonText: {
    alignSelf: "center",
    marginLeft: 20,
    fontSize: 10,
    color: colorPallete.textLight
  },
  alignView: {},
  subContainerTwo: {
    flexDirection: "row",
    flex: 100
  },
  subContainerTwoLeft: {
    flex: 50,
    marginRight: 30
  },
  subContainerTwoRight: {
    flex: 50,
    marginLeft: 30
  },
  alignSubContainer: {
    marginBottom: 20
  },
  inputStylings: {
    height: 35,
    borderRadius: 5,
    padding: 7,
    fontSize: 12,
    fontWeight: FontWeight.Light,
    fontFamily: FontFamily.Medium
  },
  inputStylingAlignment: {},
  titleInputContainer: {
    display: "flex",
    flexDirection: "row",
    flex: 100,
    minHeight: 40,
    alignItems: "center"
  },
  subheaderText: {
    fontWeight: "400",
    fontSize: 12,
    fontFamily: FontFamily.Regular,
    lineHeight: 17,
    color: colorPallete.textLight
  },
  alignHeaderText: {},
  errorText: {
    marginLeft: 20,
    justifyContent: "center",
    color: colorPallete.cordioRed
  },
  customError: {
    color: colorPallete.cordioRed
  },
  textFieldContianer: {
    marginLeft: 15,
    borderColor: colorPallete.cordioTaupe
  },
  alignText: {
    fontFamily: FontFamily.Medium,
    color: colorPallete.textBlack
  },
  alignSubTextLabel: {
    flex: 40
  },
  alignSubText: {
    flex: 60
  },
  qrCodeContainer: {
    flex: 20,
    marginRight: 10,
    alignItems: "center"
  },
  qrCodeAlign: {},
  displayQR: {
    display: "none",
    color: colorPallete.textLight
  },
  headerAlignment: {
    color: colorPallete.textLight,
    fontFamily: FontFamily.Regular
  },
  fontSize9: {
    fontSize: 9
  }
});
