import { Artefact } from "@socion-cordio/common/src/models/artefact";
import _, { compact } from "lodash";
import { useSelector } from "react-redux";
import { getUser } from "./artefactUserHelper";
//import { canAccessAll } from "./programHelper"

interface IParams {
  programId: number | string;
  artefactId?: string;
}

let artefact: Artefact;

export const canUpdate = (artefactDetails: Artefact) => {
  artefact = artefactDetails;
  return isPartOfArtefact(artefact) || canAccessAll();
};

const isPartOfArtefact = (artefact: Artefact) => {
  return isCreator(artefact) || isSubmitter(artefact);
};

const isCreator = (artefact: Artefact) => {
  return !_.isEmpty(artefact) ? _.get(artefact, "user_id") === getUser().userId : true;
};

const isSubmitter = (artefact: Artefact) => {
  let existing = _.find(_.get(artefact, "submitter_userid.submitterList"), (user) => {
    const id = user?.userId || user?.userid || user?.user_id;
    if (id === getUser().userId) {
      return user;
    }
  });
  return !_.isEmpty(existing);
};

export const canDelete = (artefactDetails: Artefact) => {
  artefact = artefactDetails;

  return isCreator(artefact)
    ? !(
        _.get(artefact, "status").toLowerCase() === "review_inprogress" ||
        !_.isEmpty(_.get(artefact, "remarks")) ||
        !_.isEmpty(_.get(artefact, "approved_by"))
      )
    : canAccessAll() && !isPartOfArtefact(artefact);
};

export const canReview = (artefactDetails: Artefact) => {
  artefact = artefactDetails;
  const canUserReview = (isVerifier() || canAccessAll()) && !isPartOfArtefact(artefact);
  return canUserReview;
};

export const canOnlyView = () => {
  let admin = _.map(getProgramRoles(), (role) => {
    if (_.includes("SUPER_ADMIN", role)) {
      return role;
    }
  });
  admin = _.compact(admin);
  return admin.length > 0;
};

export const isVerifier = () => {
  let admin = _.map(getProgramRoles(), (role) => {
    if (_.includes("VERIFIER", role)) {
      return role;
    }
  });
  admin = _.compact(admin);
  return admin.length > 0;
};

export const canAccessAll = () => {
  let admin = _.map(getProgramRoles(), (role) => {
    if (_.includes("PROGRAM_ADMIN", role)) {
      return role;
    }
  });
  admin = _.compact(admin);
  return admin.length > 0;
};

const getProgramRoles = () => {
  const programRoles: any = JSON.parse(localStorage.getItem("currentProgramRole"));
  return programRoles;
};
