import React, { ReactElement, useEffect, useState, useMemo } from "react";
import { View, StyleSheet, TouchableOpacity } from "react-native";
import { toast } from "react-toastify";
import {
  Text,
  TextSize,
  FontWeight,
  FontFamily
} from "@socion-cordio/common/src/components/atoms/text";
import { Image } from "@socion-cordio/common/src/components/atoms/image";
import { colorPallete } from "@socion-cordio/common/src/assets/styles/colors";
import {
  programEndPoints,
  springshedEndPoints
} from "@socion-cordio/common/src/repositories/endPoints";
import { ApiClient } from "@socion-cordio/common/src/network/apiClient";
import Loader from "@socion-cordio/common/src/components/atoms/loader";
import Table from "@socion-cordio/web/src/components/molecules/table/table";
import SocionModal from "@socion-cordio/common/src/components/atoms/modal";
import AddProgramMemberModal from "@socion-cordio/common/src/components/organisms/addProgramMember";
import ActivateRemoveMember from "@socion-cordio/common/src/components/organisms/programActivateDeactivate";
import ViewUserDetails from "@socion-cordio/common/src/components/organisms/common-modals/viewUserDetailsModal";
import SearchFilterSeondary from "@socion-cordio/common/src/components/molecules/searchFilterSecondary";
interface Props {
  programData: any;
  userRoles: any;
  isSuperAdmin: any;
  getUserRoles?: Function;
  programStatus?: Function;
}

export default function ProgramRoles(props: Props): ReactElement {
  const { userRoles, programStatus } = props;
  const [programAdmins, setProgramAdmins] = useState([]);
  const [contentAdmins, setContentAdmins] = useState([]);
  const [trainers, setTrainers] = useState([]);
  const [verifiers, setVerifiers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [failed, setFailed] = useState(false);
  const [programAdminSection, setProgramAdminSection] = useState(true);
  const [contentAdminSection, setContentAdminSection] = useState(false);
  const [trainerSection, setTrainerSection] = useState(false);
  const [verifierSection, setVerifierSection] = useState(false);
  const [addMemberModal, setAddMemberModal] = useState(false);
  const [viewButtonDetails, setViewButtonDetails] = useState(false);
  const [selectedUser, setSelectedUser] = useState(undefined);
  const [memberStatus, setMemberStatus] = useState("");
  const [userId, setUserId] = useState(null);
  const [modalLoading, setModalLoading] = useState(false);
  const [viewButtonUserDetails, setViewButtonUserDetails] = useState(false);
  const [selectedUserDetails, setSelectedUserDetails] = useState(null);
  const [memberRole, setMemberRoles] = useState([]);
  const [updatedColumns, setUpdatedColumns] = useState([]);

  const {
    programData: {
      program,
      entityData: { entity }
    }
  } = props;

  useEffect(() => {
    getProgramMembers();
  }, []);

  useEffect(() => {
    if (userId) handleMemberModal();
  }, [userId]);

  useEffect(() => {
    const memberRole = userRoles?.userRolesData?.response;
    setMemberRoles(memberRole);

    validateColumns(memberRole);
  }, []);

  const validateColumns = (memberRole: any[]) => {
    if (
      (memberRole?.includes("PROGRAM_ADMIN") || memberRole?.includes("ENTITY_ADMIN")) &&
      !programStatus
    ) {
      return setUpdatedColumns(columns);
    } else {
      let newColumns = columns.pop();
      return setUpdatedColumns(columns);
    }
  };

  const handleMemberModal = async () => {
    setModalLoading(true);
    const getUserDetailsResponse = await ApiClient.get(
      springshedEndPoints.getProfileByUserId(userId)
    );
    if (getUserDetailsResponse) {
      setSelectedUser(getUserDetailsResponse);
      setModalLoading(false);
    } else {
      toast.error(getUserDetailsResponse.message);
    }
  };

  const sortData = (data: any) => {
    let deActiveTrue = data.filter((i: any) => i.deactivated == true);
    let deActiveFalse = data.filter((i: any) => i.deactivated == false);
    let result = deActiveFalse.concat(deActiveTrue);
    return result;
  };

  const getProgramMembers = async () => {
    try {
      const membersResponse = await ApiClient.get(
        programEndPoints.getProgramMembers(program.id, entity.registryEntityId)
      );
      let programAdmins = membersResponse.filter((member: any) => member.role === "PROGRAM_ADMIN");
      programAdmins = await sortData(programAdmins);
      let contentAdmins = membersResponse.filter((member: any) => member.role === "CONTENT_ADMIN");
      contentAdmins = await sortData(contentAdmins);
      let trainers = membersResponse.filter((member: any) => member.role === "TRAINER");
      trainers = await sortData(trainers);
      let verifiers = membersResponse.filter((member: any) => member.role === "VERIFIER");
      verifiers = await sortData(verifiers);
      programAdmins.forEach((member: any, index: number) => {
        member.serialNo = `${index + 1}.`;
      });
      contentAdmins.forEach((member: any, index: number) => {
        member.serialNo = `${index + 1}.`;
      });
      trainers.forEach((member: any, index: number) => {
        member.serialNo = `${index + 1}.`;
      });
      verifiers.forEach((member: any, index: number) => {
        member.serialNo = `${index + 1}.`;
      });
      setProgramAdmins(programAdmins);
      setContentAdmins(contentAdmins);
      setTrainers(trainers);
      setVerifiers(verifiers);
      setLoading(false);
      setFailed(false);
    } catch (error) {
      setLoading(false);
      setFailed(true);
    }
  };

  const displayUserDetails = (data: any) => {
    setViewButtonUserDetails(true);
    setSelectedUserDetails(data);
  };

  const cellData = (testId: string, style: any, data: string, onPress?: Function) => (
    <Text
      fontWeight={FontWeight.Bold}
      testId={testId}
      textSize={TextSize.Small}
      style={[
        {
          fontSize: 12,
          fontFamily: FontFamily.Regular,
          textSize: TextSize.Small,
          fontWeight: FontFamily.Light
        },
        style
      ]}
      onPress={onPress ? () => onPress() : null}
    >
      {data}
    </Text>
  );

  const formatLocation = (data: any) => {
    let formattedLocation: string = "";
    // city, subdist, district, state, country
    if (data?.city) {
      formattedLocation += `${data?.city}, `;
    }
    if (data?.subDistrict) {
      formattedLocation += `${data?.subDistrict}, `;
    }
    if (data?.district) {
      formattedLocation += `${data?.district}, `;
    }
    if (data?.state) {
      formattedLocation += `${data?.state}, `;
    }
    if (data?.country) {
      formattedLocation += `${data?.country} `;
    }
    return formattedLocation;
  };

  const handleMemberStatus = (member: any, status: string) => {
    setUserId(member?.userId);
    setMemberStatus(status);
    setViewButtonDetails(true);
  };

  const columns = useMemo(
    () => [
      {
        Header: "Sr.No",
        accessor: "serialNo",
        width: 30,
        maxWidth: 30,
        disableSortBy: true,
        Cell: ({ row: { original: member } }: { row: { original: any } }) => {
          const style = {
            color: member?.deactivated ? colorPallete.gray : colorPallete.textBlack
          };
          return cellData("serialNo", style, member.serialNo);
        }
      },
      {
        Header: "Name",
        accessor: "name",
        width: 200,
        maxWidth: 200,
        Cell: ({ row: { original: member } }: { row: { original: any } }) => {
          return (
            <View style={styles.roleName}>
              <TouchableOpacity style={styles.roleName} onPress={() => displayUserDetails(member)}>
                <Image
                  testId="roleName"
                  imageStyle={styles.image}
                  source={
                    member?.photo ||
                    require("@socion-cordio/common/src/assets/images/user_circle.svg")
                  }
                />
                <Text
                  fontWeight={FontWeight.Bold}
                  testId="profileName"
                  textSize={TextSize.Large}
                  style={{
                    fontSize: 12,
                    fontFamily: FontFamily.Medium,
                    color: member?.deactivated ? colorPallete.gray : colorPallete.textBlack
                  }}
                >
                  {member?.name}
                </Text>
              </TouchableOpacity>
            </View>
          );
        }
      },
      {
        Header: "Location",
        accessor: "location",
        width: 200,
        maxWidth: 200,
        Cell: ({ row: { original: member } }: { row: { original: any } }) => {
          const style = {
            color: member?.deactivated ? colorPallete.gray : colorPallete.textBlack
          };
          const location: string = formatLocation(member) ? formatLocation(member) : "None";
          return cellData("location", style, location);
        }
      },
      {
        // Header: "Add/Remove",
        Header: " ",
        width: 70,
        maxWidth: 70,
        disableSortBy: true,
        Cell: ({ row: { original: member } }: { row: { original: any } }) => {
          return (
            <View style={styles.alignIcon}>
              {member.deactivated ? (
                <TouchableOpacity onPress={() => handleMemberStatus(member, "activate")}>
                  <Image
                    testId="secondaryAddImg"
                    source={require("@socion-cordio/common/src/assets/images/secondary_add.svg")}
                    imageStyle={{
                      width: 20,
                      height: 20
                    }}
                  />
                </TouchableOpacity>
              ) : (
                <View>
                  <TouchableOpacity onPress={() => handleMemberStatus(member, "deactivate")}>
                    <Image
                      testId="remove"
                      source={require("@socion-cordio/common/src/assets/images/remove.svg")}
                      imageStyle={{
                        width: 20,
                        height: 20
                      }}
                    />
                  </TouchableOpacity>
                </View>
              )}
            </View>
          );
        }
      }
    ],
    []
  );

  const AddMember = () => (
    <TouchableOpacity onPress={() => [setAddMemberModal(true)]}>
      <Image
        testId="primaryAddImg"
        source={require("@socion-cordio/common/src/assets/images/primary_add.svg")}
        imageStyle={{
          width: 30,
          height: 30
        }}
      />
    </TouchableOpacity>
  );

  const searchbarAndAddMember = () => (
    <View>
      <View style={styles.searchBarContainer}>
        <View pointerEvents={"none"}>
          <SearchFilterSeondary customWidthStyle={styles.customWidthStyle} editable={false} />
        </View>
        <View>
          {(memberRole?.includes("PROGRAM_ADMIN") || memberRole?.includes("ENTITY_ADMIN")) &&
            !programStatus && (
              <TouchableOpacity
                style={styles.addButtonIcon}
                onPress={() => setAddMemberModal(true)}
              >
                <Image
                  testId="secondaryAddImg"
                  source={require("@socion-cordio/common/src/assets/images/primary_add.svg")}
                  imageStyle={{
                    width: 30,
                    height: 30
                  }}
                />
              </TouchableOpacity>
            )}
        </View>
      </View>
      <Text
        fontWeight={FontWeight.Thin}
        testId="headerText"
        textSize={TextSize.Small}
        textStyle={styles.headerText}
      >
        {"No Role Present"}
      </Text>
    </View>
  );

  return (
    <View>
      {loading ? (
        <Loader />
      ) : failed ? (
        <View>Failed to load</View>
      ) : (
        <View>
          <View style={styles.labelHeader}>
            <View style={styles.alignHeader}>
              <Text
                fontWeight={FontWeight.Medium}
                testId="entity"
                textSize={TextSize.Small}
                textStyle={[styles.roles, programAdminSection ? [styles.selectedRole, {}] : null]}
                onPress={() => {
                  setProgramAdminSection(true);
                  setContentAdminSection(false);
                  setTrainerSection(false);
                  setVerifierSection(false);
                }}
              >
                Program administrator
              </Text>
            </View>
            <View style={styles.alignHeader}>
              <Text
                fontWeight={FontWeight.Medium}
                testId="entity"
                textSize={TextSize.Small}
                textStyle={[styles.roles, contentAdminSection ? styles.selectedRole : null]}
                onPress={() => {
                  setContentAdminSection(true);
                  setProgramAdminSection(false);
                  setTrainerSection(false);
                  setVerifierSection(false);
                }}
              >
                Content administrator
              </Text>
            </View>
            <View style={styles.alignHeader}>
              <Text
                fontWeight={FontWeight.Medium}
                testId="entity"
                textSize={TextSize.Small}
                textStyle={[styles.roles, trainerSection ? styles.selectedRole : null]}
                onPress={() => {
                  setTrainerSection(true);
                  setContentAdminSection(false);
                  setProgramAdminSection(false);
                  setVerifierSection(false);
                }}
              >
                Trainer
              </Text>
            </View>
            <View style={styles.alignHeader}>
              <Text
                fontWeight={FontWeight.Medium}
                testId="entity"
                textSize={TextSize.Small}
                textStyle={[styles.roles, verifierSection ? styles.selectedRole : null]}
                onPress={() => {
                  setVerifierSection(true);
                  setContentAdminSection(false);
                  setProgramAdminSection(false);
                  setTrainerSection(false);
                }}
              >
                Verifier
              </Text>
            </View>
          </View>
          {programAdminSection ? (
            <View>
              {programAdmins.length === 0 ? (
                <View>{searchbarAndAddMember()}</View>
              ) : (
                <Table
                  data={programAdmins}
                  columns={updatedColumns}
                  addIcon={
                    (memberRole?.includes("PROGRAM_ADMIN") ||
                      memberRole?.includes("ENTITY_ADMIN")) &&
                    !programStatus &&
                    AddMember()
                  }
                />
              )}
            </View>
          ) : contentAdminSection ? (
            <View>
              {contentAdmins.length === 0 ? (
                <View>{searchbarAndAddMember()}</View>
              ) : (
                <Table
                  data={contentAdmins}
                  columns={updatedColumns}
                  addIcon={
                    (memberRole?.includes("PROGRAM_ADMIN") ||
                      memberRole?.includes("ENTITY_ADMIN")) &&
                    !programStatus &&
                    AddMember()
                  }
                />
              )}
            </View>
          ) : trainerSection ? (
            <View>
              {trainers.length === 0 ? (
                <View>{searchbarAndAddMember()}</View>
              ) : (
                <Table
                  data={trainers}
                  columns={updatedColumns}
                  addIcon={
                    (memberRole?.includes("PROGRAM_ADMIN") ||
                      memberRole?.includes("ENTITY_ADMIN")) &&
                    !programStatus &&
                    AddMember()
                  }
                />
              )}
            </View>
          ) : verifierSection ? (
            <View>
              {verifiers.length === 0 ? (
                <View>{searchbarAndAddMember()}</View>
              ) : (
                <Table
                  data={verifiers}
                  columns={updatedColumns}
                  addIcon={
                    (memberRole?.includes("PROGRAM_ADMIN") ||
                      memberRole?.includes("ENTITY_ADMIN")) &&
                    !programStatus &&
                    AddMember()
                  }
                />
              )}
            </View>
          ) : null}
        </View>
      )}
      {addMemberModal && (
        <View>
          <SocionModal
            modalVisible={addMemberModal}
            setModalVisible={() => setAddMemberModal(!addMemberModal)}
            component={
              <AddProgramMemberModal
                onClose={() => setAddMemberModal(false)}
                getProgramMembers={() => getProgramMembers()}
                getUserRoles={() => props.getUserRoles()}
              />
            }
          />
        </View>
      )}
      {!modalLoading && viewButtonDetails && (
        <View>
          <SocionModal
            modalVisible={viewButtonDetails}
            setModalVisible={() => setViewButtonDetails(!viewButtonDetails)}
            component={
              <ActivateRemoveMember
                onClose={() => {
                  setViewButtonDetails(false);
                }}
                getProgramMembers={() => getProgramMembers()}
                getUserRoles={() => props.getUserRoles()}
                role={
                  programAdminSection
                    ? "PROGRAM_ADMIN"
                    : contentAdminSection
                    ? "CONTENT_ADMIN"
                    : trainerSection
                    ? "TRAINER"
                    : verifierSection
                    ? "VERIFIER"
                    : null
                }
                userDetails={selectedUser}
                userId={userId}
                memberStatus={memberStatus}
                setModalLoading={setModalLoading}
              />
            }
          />
        </View>
      )}
      {viewButtonUserDetails && (
        <View>
          <SocionModal
            modalVisible={viewButtonUserDetails}
            setModalVisible={() => setViewButtonUserDetails(!viewButtonUserDetails)}
            component={
              <ViewUserDetails
                onClose={() => {
                  setViewButtonUserDetails(false);
                }}
                selectedUserDetails={selectedUserDetails}
                piiFilter={true}
              />
            }
          />
        </View>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  labelHeader: {
    display: "flex",
    flexDirection: "row",
    padding: 18,
    paddingBottom: 0,
    marginBottom: -2
  },
  alignHeader: {
    width: "23%"
  },
  roleName: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center"
  },
  image: {
    width: 25,
    height: 25,
    borderRadius: 25 / 2,
    marginEnd: 10
  },
  mainHeaderText: {
    fontSize: 12,
    fontFamily: FontFamily.Bold,
    lineHeight: 17,
    fontWeight: "700",
    paddingLeft: 25,
    marginTop: 10,
    marginBottom: 10
  },
  roles: {
    fontSize: 12,
    marginRight: 15,
    fontWeight: "400",
    color: "#7B7979",
    flex: 1,
    fontFamily: FontFamily.Regular
  },
  selectedRole: {
    fontSize: 12,
    textSize: TextSize.Small,
    color: colorPallete.textBlack,
    fontFamily: FontFamily.Bold
  },
  // Search bar disabled
  searchBarContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    padding: 25,
    marginBottom: 10
  },
  customWidthStyle: {
    width: 400
  },
  addButtonIcon: {},
  headerText: {
    fontSize: 14,
    fontFamily: FontFamily.Medium,
    lineHeight: 19.5,
    fontWeight: "700",
    paddingHorizontal: 25,
    marginBottom: 30
  },
  alignIcon: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    marginRight: 10
  }
});
