import { Program } from "@socion-cordio/common/src/models/program";
import { EntityInvite } from "@socion-cordio/common/src/models/entityInvite";
import {
  PROGRAMS,
  SAVE_PROGRAM_DETAILS
} from "@socion-cordio/common/src/modules/program/actions/actionTypes";
import { IFluxStandardAction } from "@socion-cordio/common/src/store/interfaces";

const getPrograms = (): IFluxStandardAction<{}> => {
  return {
    type: PROGRAMS.GET_PROGRAMS.LOADING
  };
};

const getProgramsSucess = (data: any): IFluxStandardAction<Program> => {
  data.response.forEach((entityData: any) => {
    entityData.programs.forEach((program: any, index: number) => {
      program.serialNo = `${index + 1}.`;
    });
  });
  return {
    type: PROGRAMS.GET_PROGRAMS.SUCCESS,
    payload: data.response
  };
};

const getProgramsError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: PROGRAMS.GET_PROGRAMS.ERROR,
    payload: error
  };
};

const saveProgramDetails = (data: any): IFluxStandardAction<{}> => {
  return {
    type: SAVE_PROGRAM_DETAILS,
    payload: data
  };
};

const getEntityInvite = (): IFluxStandardAction<string> => {
  return {
    type: PROGRAMS.GET_ENTITY_INVITE.LOADING
  };
};
const getEntityInviteSuccess = (data: EntityInvite): IFluxStandardAction<EntityInvite> => {
  return {
    type: PROGRAMS.GET_ENTITY_INVITE.SUCCESS,
    payload: data
  };
};
const getEntityInviteError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: PROGRAMS.GET_ENTITY_INVITE.ERROR,
    payload: error
  };
};

export const ProgramActions = {
  getPrograms,
  getProgramsSucess,
  getProgramsError,
  saveProgramDetails,
  getEntityInvite,
  getEntityInviteSuccess,
  getEntityInviteError
};
