import React, { ReactElement, useEffect, useState } from "react";
import { StyleSheet, View } from "react-native";
import ButtonTab, {
  ButtonTypeSecondary
} from "@socion-cordio/common/src/components/atoms/buttonTab";
import {
  Text,
  TextSize,
  FontWeight,
  FontFamily
} from "@socion-cordio/common/src/components/atoms/text";
import { useLocation, useHistory } from "react-router-dom";
import { colorPallete } from "@socion-cordio/common/src/assets/styles/colors";
import { useTranslation } from "react-i18next";
import Icon, { IconNames } from "@socion-cordio/common/src/components/atoms/icon";
import CompletedSessionParticipationList from "./completedSessionParticipantList";
import CompletedSessionTrainerList from "./completedSessionTrainerList";

interface Props {
  sessionDetails?: any;
}

export default function CompletedSessionParticipationListPage(props: Props): ReactElement {
  const { t } = useTranslation();
  const { sessionDetails } = props;
  const [isParticipantList, setIsParticipantList] = useState<boolean>(true);
  const [isTrainerList, setIsTrainerList] = useState<boolean>(false);
  const [sessionData, setSessionData] = useState(null);
  const history: any = useHistory();
  const location: any = useLocation();

  useEffect(() => {
    getDetails();
  }, []);

  const getDetails = () => {
    setSessionData(location?.state?.sessionDetails);
    if (location?.state?.isParticipantListDisplay === false) {
      setIsParticipantList(false);
      setIsTrainerList(true);
    }
  };

  return (
    <View style={styles.container}>
      <View style={styles.subContainerOne}>
        <View style={styles.headerContainer}>
          <View style={styles.iconContainerHeader}>
            <Icon
              testID="down"
              name={IconNames.down}
              customStyle={styles.titleIcon}
              onPress={() => {
                history.goBack();
              }}
            />
          </View>
          <View>
            <Text
              fontWeight={FontWeight.Light}
              testId="text"
              textSize={TextSize.Small}
              textStyle={[styles.headerText, styles.headerTextTitleSupport]}
            >
              {sessionData?.sessionName}
            </Text>
          </View>
          <View>
            <Text
              onPress={() => {}}
              fontWeight={FontWeight.Regular}
              testId="text"
              textSize={TextSize.Small}
              textStyle={[styles.headerText, { marginLeft: 10, marginRight: 0 }]}
            >
              {">"}
            </Text>
          </View>
          <View>
            <Text
              onPress={() => {}}
              fontWeight={FontWeight.Regular}
              testId="text"
              textSize={TextSize.Small}
              textStyle={[styles.headerText, styles.headerTextSupport]}
            >
              {t("session:viewSessionDetails")}
            </Text>
          </View>
        </View>

        <View style={styles.subContainer}>
          <View>
            <View style={styles.toggleContainer}>
              {/* <ButtonGroup/> */}
              <ButtonTab
                type={
                  isParticipantList ? ButtonTypeSecondary.Secondary : ButtonTypeSecondary.Primary
                }
                buttonStyles={[
                  styles.toggleButton,
                  { borderBottomLeftRadius: 5, borderTopLeftRadius: 5 }
                ]}
                textStyles={styles.textStyle}
                title={t("session:participant")}
                onPress={() => {
                  setIsParticipantList(true);
                  setIsTrainerList(false);
                }}
              />
              <ButtonTab
                type={isTrainerList ? ButtonTypeSecondary.Secondary : ButtonTypeSecondary.Primary}
                buttonStyles={[
                  styles.toggleButton,
                  { borderBottomRightRadius: 5, borderTopRightRadius: 5 }
                ]}
                textStyles={styles.textStyle}
                title={t("session:trainer")}
                onPress={() => {
                  setIsParticipantList(false);
                  setIsTrainerList(true);
                }}
              />
            </View>
            {isParticipantList ? (
              <CompletedSessionParticipationList sessionDetails={sessionData} />
            ) : isTrainerList ? (
              <CompletedSessionTrainerList sessionDetails={sessionData} />
            ) : null}
          </View>
        </View>
      </View>{" "}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    display: "flex",
    padding: 20
    // backgroundColor: "#F8F5F0",
    // minHeight: "calc(100vh - 50px)"
  },
  // subContainerOne:{
  // },
  subContainerOne: {
    backgroundColor: colorPallete.white,
    borderRadius: 20,
    // padding: 20,
    shadowColor: colorPallete.cordioRedDark1,
    shadowOpacity: 0.1,
    shadowOffset: {
      height: 10,
      width: 5
    },
    shadowRadius: 10
  },
  subContainer: {},
  headerContainer: {
    flexDirection: "row",
    marginBottom: 20,
    alignItems: "center",
    padding: 20,
    paddingLeft: 25
  },
  iconContainerHeader: {
    marginRight: 20
  },
  titleIcon: {
    color: colorPallete.textBlack,
    fontSize: 9,
    transform: [{ rotate: "90deg" }]
  },
  headerTextTitleSupport: {
    fontWeight: "700"
  },
  headerTextSupport: {
    // Due to font size, increasing the size.
    fontFamily: FontFamily.Regular,
    marginLeft: 10
  },

  subtContainerOne: {
    paddingLeft: 25,
    paddingRight: 25
  },
  containerStyle: {
    width: "20%",
    marginLeft: 15
  },
  toggleContainer: {
    flexDirection: "row",
    paddingLeft: 25,
    paddingRight: 25
  },
  toggleButton: {
    width: 180
  },
  textStyle: {
    fontSize: 12
  },
  headerText: {
    fontSize: 12,
    fontFamily: FontFamily.Regular,
    lineHeight: 17
  },
  headerSupport: {
    marginBottom: 10
  },
  headerAlign: {
    fontSize: 14,
    fontFamily: FontFamily.Medium,
    color: colorPallete.textLight
  }
});
