import { artefact_messages } from "./../data/message";
import { ArtefactRepository } from "@socion-cordio/common/src/repositories/artefact/artefact";
import { LocalStorage } from "@socion-cordio/common/src/services/storage/storageService";
import { auxiliaryMethods } from "@socion-cordio/common/src/utils/auxiliaryMethods";
import { compact, get, isEmpty, map, replace, uniq, uniqBy } from "lodash";
import { deleteArtefactEvent } from "./telemetryEvents";
import { toast } from "react-toastify";
import { NotificationsActions } from "@socion-cordio/common/src/modules/notifications/actions/action";

export enum STATUS {
  submitted = "SUBMITTED",
  approved = "APPROVED",
  rejected = "REJECTED",
  inprogress = "REVIEW_INPROGRESS"
}

export const downloadTypes = {
  xls: [
    "vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    "vnd.ms-excel",
    "application/vnd.ms-excel",
    "application/vnd.spreadsheetml.sheet",
    "application/vnd.spreadsheetml.sheet",
    "xls"
  ],
  doc: [
    "msword",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    "vnd.openxmlformats-officedocument.wordprocessingml.document",
    "application/vnd.wordprocessingml.document",
    "vnd.wordprocessingml.document",
    "doc",
    "application/msword"
  ],
  pdf: ["application/pdf", "pdf"],
  txt: ["plain", "txt", "text/plain"],
  ppt: [
    "application/vnd.presentationml.presentation",
    "vnd.presentationml.presentation",
    "application/vnd.openxmlformats-officedocument.presentationml.presentation",
    "vnd.openxmlformats-officedocument.presentationml.presentation",
    "application/vnd.ms-powerpoint",
    "vnd.ms-powerpoint",
    "ppt"
  ],
  images: ["jpeg", "jpg", "png", "image/jpeg", "image/jpg", "image/png"]
};

// application/vnd.ms-excel

export const getJoinedLocation = (location: any) => {
  let locjoined = "";

  // console.log("getJoinedLocation location : ", location);

  if (location?.village) {
    locjoined = location?.village + " , ";
  }

  if (location?.city) {
    locjoined = location?.city + " , ";
  }

  if (location?.sub_district || location?.subDistrict || location?.subdistrict) {
    locjoined = (location?.sub_district || location?.subDistrict || location?.subdistrict) + " , ";
  }

  if (location?.district) {
    locjoined = locjoined + location?.district + " , ";
  }

  if (location?.state) {
    locjoined = locjoined + location?.state + " , ";
  }

  if (location?.country) {
    locjoined = locjoined + location?.country;
  }
  // console.log("getJoinedLocation", locjoined);
  return locjoined;
};

export const getLocation = (location: any) => {
  // console.log("getLocationgetLocation", location);
  const formatted = location?.formatted_address || location?.formattedaddress;

  const loc = {
    country: location?.country,
    state: location?.state,
    district: location?.district || "",
    subDistrict: location?.subDistrict || location?.sub_district || "",
    city: location?.city || "",
    village: location?.village || "",
    formattedaddress: formatted || "",
    placeid: location?.placeId || location?.placeid || "",
    latitude: location?.latitude || "",
    longitude: location?.longitude || "",
    location: formatted ? formatted : ""
  };
  return loc;
};

export const formatBaseLocation = (data: any) => {
  let formattedBaseLocation: string = "";
  if (data?.village !== "") {
    formattedBaseLocation += `${data?.village}, `;
  }
  if (data?.city !== "") {
    formattedBaseLocation += `${data?.city}, `;
  }
  if (data?.subDistrict !== "") {
    formattedBaseLocation += `${data?.subDistrict || data?.sub_district}, `;
  }
  if (data?.district !== "") {
    formattedBaseLocation += `${data?.district}, `;
  }
  if (data?.state !== "") {
    formattedBaseLocation += `${data?.state}, `;
  }
  if (data?.country !== "") {
    formattedBaseLocation += `${data?.country} `;
  }
  return formattedBaseLocation;
};

export const getBadges = (user: { badgeDetails: any }) => {
  return map(user.badgeDetails, (badge: { badgeName: any }) => badge.badgeName);
};

export const hasType = (type: any) => {
  const types = ["jpeg", "jpg", "png", "image/jpeg", "image/jpg", "image/png"];
  const imageType = types.includes(type);
  return imageType;
};

export const getImage = (mimeType: any) => {
  let type = "";

  if (mimeType) {
    if (downloadTypes.pdf.includes(mimeType)) {
      type = "pdf.png";
    } else if (downloadTypes.doc.includes(mimeType)) {
      type = "doc.png";
    } else if (downloadTypes.txt.includes(mimeType)) {
      type = "txt.png";
    } else if (downloadTypes.ppt.includes(mimeType)) {
      type = "ppt.png";
    } else if (downloadTypes.xls.includes(mimeType)) {
      type = "xls.png";
    } else if (downloadTypes.images.includes(mimeType)) {
      type = "photo.svg";
    }
    const path = require(`@socion-cordio/common/src/assets/images/${type}`);
    return path;
  }
  return;
};

const getFilterLocation = (data: any) => {
  const location: any = {};
  const subDistrict = data?.sub_district || data?.subDistrict || data?.subdistrict || "";
  location.country = data?.country;
  location.state = data?.state;
  location.district = data?.district;
  location.subDistrict = subDistrict;
  location.city = data?.city;
  location.village = data?.village;
  return location;
};

export const getArtefactFilters = (filters: any) => {
  const location = filters?.location;
  const loc = location?.map((data: any, index: number) => {
    const locat: any = getFilterLocation(data);
    locat.serialNo = `${index + 1}`;
    locat.formattedBaseLocation = auxiliaryMethods.fromatBaseLocation(locat);
    locat.isSelected = false;
    return locat;
  });
  const types = filters?.type;
  types.forEach((data: any, index: number) => {
    data.serialNo = `${index + 1}`;
    data.isSelected = false;
    data.name = data?.artefact_type_title;
  });

  const status = filters?.status;
  status.forEach((data: any, index: number) => {
    data.serialNo = `${index + 1}`;
    data.isSelected = false;
    data.name = data?.name;
  });

  return { location: loc, types, status };
};

export const isListBtnSelectionNotEmpty = (selectedFilterListBtn: any) => {
  if (
    selectedFilterListBtn.location.length !== 0 ||
    selectedFilterListBtn.type.length !== 0 ||
    selectedFilterListBtn.submittedDate.length !== 0 ||
    selectedFilterListBtn.uploadedDate.length !== 0 ||
    selectedFilterListBtn.status.length !== 0
  ) {
    return true;
  }
  return false;
};

export const isAnyFilterApplied = (filterApplied: any) => {
  if (
    filterApplied.location ||
    filterApplied.type ||
    filterApplied.submittedDate ||
    filterApplied.uploadedDate
  ) {
    return true;
  }
  return false;
};

export const isNotSelected = (notSelected: any) => {
  if (
    notSelected.location ||
    notSelected.type ||
    notSelected.submittedDate ||
    notSelected.uploadedDate ||
    notSelected.status
  ) {
    return true;
  }
  return false;
};

const filterListToTrue = (list: any) => {
  return list.filter((x: any) => x.isSelected === true);
};

export const removeFilteredLocationList = async (
  data: any,
  filtersListData: any,
  checkedList: any,
  selectedFilterListBtn: any
) => {
  // find element in the all locations list, locationListSecondry: true list
  const list = filtersListData.locationSecondary.filter((item: any) =>
    item.formattedBaseLocation.includes(data)
  );
  // mark the removed element it as false
  let checkList = [...checkedList];
  list.forEach((element: any) => {
    element.isSelected = false;
    const index = checkList.indexOf(element.serialNo);
    let data = checkList.splice(index, 1);
  });
  const typeList = [...filtersListData.type];
  let newTypeList = typeList.map((x: any) => x.id);
  // remove the location from display
  const updatedLocList = selectedFilterListBtn.location.filter((item: any) => item !== data);
  LocalStorage.setStorage("globalNameList", updatedLocList);

  // const global: any[] = await LocalStorage.getStorage("globalNameList");
  const location: { country: []; state: []; district: []; sub_district: []; city: [] } =
    await LocalStorage.getStorage("selectedLocation");
  let finalLocation = {};
  if (isEmpty(updatedLocList)) {
    LocalStorage.setStorage("selectedLocation", {
      country: [],
      state: [],
      district: [],
      sub_district: [],
      city: []
    });
  } else {
    const coun = [...location.country];
    coun.forEach((cou, index) => {
      if (!updatedLocList.includes(cou)) {
        coun.splice(index, 1);
      }
    });
    const stat = [...location.state];
    stat.forEach((sta, index) => {
      if (!updatedLocList.includes(sta)) {
        stat.splice(index, 1);
      }
    });
    const dist = [...location.district];
    dist.forEach((dis, index) => {
      if (!updatedLocList.includes(dis)) {
        dist.splice(index, 1);
      }
    });
    const subdist = [...location.sub_district];
    subdist.forEach((subdis, index) => {
      if (!updatedLocList.includes(subdis)) {
        subdist.splice(index, 1);
      }
    });
    const cit = [...location.city];
    cit.forEach((ci, index) => {
      if (!updatedLocList.includes(ci)) {
        cit.splice(index, 1);
      }
    });

    finalLocation = {
      country: coun || [],
      state: stat || [],
      district: dist || [],
      sub_district: subdist || [],
      city: cit || []
    };

    // setUpdatedFinalLocation(finalLocation);
    LocalStorage.setStorage("selectedLocation", finalLocation);
  }

  const res = {
    checkedList: [...checkList],
    typeList: newTypeList,
    selectedFilterListBtn: { ...selectedFilterListBtn, location: updatedLocList },
    finalLocation: finalLocation
  };

  return res;
};

export const delArtefact = async (selectedArtefact: any, isLocal = false) => {
  var artefactsToDelete: string[] = (await LocalStorage.getStorage("artefactsToDelete")) || [];
  const res = await ArtefactRepository.deleteArtefact(selectedArtefact.artefact_meta_id)
    .then(async (data) => {
      artefactsToDelete = artefactsToDelete.filter((id) => id != selectedArtefact.artefact_meta_id);
      artefactsToDelete = uniq(artefactsToDelete);
      await LocalStorage.setStorage("artefactsToDelete", artefactsToDelete);
    })
    .catch((err) => {
      if (!isLocal) {
        toast.error(
          replace(
            artefact_messages.error.m03,
            "{name}",
            `"${get(selectedArtefact, "artefact_name")}"`
          )
        );
      }
      console.log("Error: ", err);
    });
  console.log("id delete api", Date.now());

  const docRes = await ArtefactRepository.getArtefactDocs(selectedArtefact.artefact_meta_id).catch(
    (err) => {
      console.log(" Doc Error: ", err);
    }
  );
  deleteArtefactEvent(selectedArtefact, docRes?.data?.length);
  console.log("id delete", Date.now());

  return selectedArtefact.artefact_meta_id;
};

export const isEmptySelectedFilter = (selectedFilter: any) => {
  if (
    selectedFilter.location.length === 0 &&
    selectedFilter.type.length === 0 &&
    !(selectedFilter.submittedDate.fromdate && selectedFilter.submittedDate.todate) &&
    !(selectedFilter.uploadedDate.uploadedFromDate && selectedFilter.uploadedDate.uploadedToDate) &&
    selectedFilter.status.length === 0
  ) {
    return true;
  }
  return false;
};

export const addNotification = async (updateReq: any, artefact: any, user: any, dispatch: any) => {
  const request = {
    ...artefact,
    ...updateReq
  };

  const submitters = updateReq?.submitter_userid?.submitterList || artefact?.submitters || [];
  submitters.push({ userid: artefact.user_id });
  let users = submitters.map((submitter: any) => {
    console.log("submitters", submitter);

    if (submitter?.userid || submitter?.userId) {
      return {
        title: `${request.artefact_name}: ${request.status.toLowerCase()}`,
        description: `Artefact: ${request.artefact_name} with type: ${
          request.artefact_type_title
        } got ${request.status.toLowerCase()} by ${user?.name}`,
        notificationType: "WORKFLOW",
        dateTime: new Date(),
        userId: submitter?.userid || submitter?.userId
      };
    }
  });

  users = uniqBy(users, "userId");
  users = compact(users);

  const res = await ArtefactRepository.addNotification(users);
  dispatch(NotificationsActions.getNotifications());
};

export const addWorkflow = async (updateReq: any, artefact: any, user: any) => {
  const request = {
    ...artefact,
    ...updateReq
  };

  const workflowReq = {
    previous_status: artefact.status,
    current_status: request.status,
    actedby: user?.name,
    actedon: new Date(),
    actedby_id: user?.userId,
    remarks: request?.remarks
  };

  const res = await ArtefactRepository.addWorkflow(artefact?.artefact_meta_id, workflowReq);
};

export const deleteArtefactsToBeDeletedinLocal = async () => {
  var artefactsToDelete: string[] = (await LocalStorage.getStorage("artefactsToDelete")) || [];
  artefactsToDelete.map(async (id) => {
    const artefactDetails = await ArtefactRepository.getArtefactDetails(id).catch((error) => {
      console.log("Error", error);
    });
    if (!isEmpty(artefactDetails)) {
      await delArtefact(artefactDetails?.data, true);
    }
  });
};
