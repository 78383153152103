import React, { ReactElement, useEffect, useState } from "react";
import { StyleSheet, View } from "react-native";
import ButtonTab, {
  ButtonTypeSecondary
} from "@socion-cordio/common/src/components/atoms/buttonTab";
import {
  Text,
  TextSize,
  FontWeight,
  FontFamily
} from "@socion-cordio/common/src/components/atoms/text";
import ViewSessionParticipantPage from "@socion-cordio/common/src/components/organisms/session/viewSessionParticipantPage";
import ViewSessionTrainerPage from "@socion-cordio/common/src/components/organisms/session/viewSessionTrainerPage";
import { useLocation, useHistory } from "react-router-dom";
import { colorPallete } from "@socion-cordio/common/src/assets/styles/colors";
import CompletedSessionParticipationPage from "@socion-cordio/common/src/components/organisms/session//completedSessionParticipationPage";
import { useTranslation } from "react-i18next";

interface Props {
  sessionDetails?: any;
}

const TRAINER = "Trainer";

export default function SessionParticipantPage(props: Props): ReactElement {
  const { t } = useTranslation();
  const { sessionDetails } = props;
  const [isParticipantList, setIsParticipantList] = useState<boolean>(true);
  const [isTrainerList, setIsTrainerList] = useState<boolean>(false);
  const [isLiveSession, setIsLiveSession] = useState<boolean>(false);
  const [isUpcomingSession, setIsUpcomingSession] = useState<boolean>(false);
  const [isCompletedSession, setIsCompletedSession] = useState<boolean>(false);

  const location: any = useLocation();

  useEffect(() => {
    checkRouteUrl();
  }, [location.pathname]);

  const checkRouteUrl = () => {
    if (location.pathname.includes("live")) {
      setIsLiveSession(true);
      setIsUpcomingSession(false);
      setIsCompletedSession(false);
    }
    if (location.pathname.includes("upcoming")) {
      setIsLiveSession(false);
      setIsUpcomingSession(true);
      setIsCompletedSession(false);
    }
    if (location.pathname.includes("completed")) {
      setIsLiveSession(false);
      setIsUpcomingSession(false);
      setIsCompletedSession(true);
    }
  };

  useEffect(() => {
    redirectionTrainerHandler();
  }, [location]);

  const redirectionTrainerHandler = () => {
    location.state.sessionParticipantType === TRAINER
      ? [setIsParticipantList(false), setIsTrainerList(true)]
      : [setIsParticipantList(true), setIsTrainerList(false)];
  };

  return (
    <View style={styles.container}>
      <View style={styles.subContainer}>
        <View style={styles.subtContainerOne}>
          <View>
            <Text
              onPress={() => {}}
              fontWeight={FontWeight.Regular}
              testId="text"
              textSize={TextSize.Small}
              textStyle={[styles.headerText, styles.headerSupport]}
            >
              {t("session:sessionParticipation")}
            </Text>
          </View>
        </View>

        {isLiveSession && (
          <View>
            <View style={styles.toggleContainer}>
              {/* <ButtonGroup/> */}
              <ButtonTab
                type={
                  isParticipantList ? ButtonTypeSecondary.Secondary : ButtonTypeSecondary.Primary
                }
                buttonStyles={[
                  styles.toggleButton,
                  { borderBottomLeftRadius: 5, borderTopLeftRadius: 5 }
                ]}
                textStyles={styles.textStyle}
                title={t("session:participant")}
                onPress={() => {
                  setIsParticipantList(true);
                  setIsTrainerList(false);
                }}
              />
              <ButtonTab
                type={isTrainerList ? ButtonTypeSecondary.Secondary : ButtonTypeSecondary.Primary}
                buttonStyles={[
                  styles.toggleButton,
                  { borderBottomRightRadius: 5, borderTopRightRadius: 5 }
                ]}
                textStyles={styles.textStyle}
                title={t("session:trainer")}
                onPress={() => {
                  setIsParticipantList(false);
                  setIsTrainerList(true);
                }}
              />
            </View>
            {isParticipantList ? (
              <ViewSessionParticipantPage sessionDetails={sessionDetails} />
            ) : isTrainerList ? (
              <ViewSessionTrainerPage sessionDetails={sessionDetails} />
            ) : null}
          </View>
        )}

        {isUpcomingSession && (
          <View>
            {" "}
            <View style={{ minHeight: 110, justifyContent: "center", alignItems: "center" }}>
              <Text
                onPress={() => {}}
                fontWeight={FontWeight.Regular}
                testId="text"
                textSize={TextSize.Small}
                textStyle={[styles.headerText, styles.headerSupport, styles.headerAlign]}
              >
                This session has not yet started
              </Text>
            </View>
          </View>
        )}

        {isCompletedSession && (
          <View>
            {" "}
            <CompletedSessionParticipationPage sessionDetails={sessionDetails} />
          </View>
        )}
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    display: "flex",
    width: "calc(100vw - 515px)"
  },
  subContainer: {},
  subtContainerOne: {
    paddingLeft: 18,
    paddingRight: 18
  },
  containerStyle: {
    width: "20%",
    marginLeft: 15
  },
  toggleContainer: {
    flexDirection: "row",
    paddingLeft: 18,
    paddingRight: 18
  },
  toggleButton: {
    width: 180
  },
  textStyle: {
    fontSize: 12
  },
  headerText: {
    fontSize: 12,
    fontFamily: FontFamily.Regular,
    lineHeight: 17
  },
  headerSupport: {
    marginBottom: 18
  },
  headerAlign: {
    fontSize: 14,
    fontFamily: FontFamily.Medium,
    color: colorPallete.textLight
  }
});
