import React, { ReactElement, useEffect, useState } from "react";
import { StyleSheet, View } from "react-native";
import { colorPallete } from "@socion-cordio/common/src/assets/styles/colors";
import ButtonTab, {
  ButtonTypeSecondary
} from "@socion-cordio/common/src/components/atoms/buttonTab";
import LiveSessionPage from "@socion-cordio/common/src/components/organisms/session/liveSessionPage";
import UpcomingSessionPage from "@socion-cordio/common/src/components/organisms/session/upcomingSessionPage";
import CompletedSessionPage from "@socion-cordio/common/src/components/organisms/session/completedSessionPage";
import { LocalStorage } from "@socion-cordio/common/src/services/storage/storageService";
import { useTranslation } from "react-i18next";
import { useLocation, useHistory } from "react-router-dom";

interface Props {}

export default function SessionPage(props: Props): ReactElement {
  const { t } = useTranslation();
  const location: any = useLocation();
  const [isLiveSession, setIsLiveSession] = useState<boolean>(true);
  const [isUpcomingSession, setIsUpcomingSession] = useState<boolean>(false);
  const [isCompletedSession, setIsCompletedSession] = useState<boolean>(false);
  const [programDetails, setProgramDetails] = useState(null);

  useEffect(() => {
    getProgramDetails();
  }, []);

  useEffect(() => {
    (async () => {
      await getProgramDetails();
      await processActiveTab();
    })();
  }, [location?.state?.activeTab]);

  const processActiveTab = () => {
    if (location?.state?.activeTab === "UPCOMING") {
      upcomingSessionButtonSelection();
    } else if (location?.state?.activeTab === "COMPLETED") {
      completedSessionButtonSelection();
    }
  };

  const getProgramDetails = async () => {
    const programDetails = await LocalStorage.getStorage("programDetails");
    setProgramDetails(programDetails);
  };

  useEffect(() => {
    validateTabSwitchHandler();
  }, [location]);

  const validateTabSwitchHandler = () => {
    if (location.state !== undefined) {
      if (location.state.locationData.pathname.includes("live")) {
        liveSessionButtonSelection();
      }
      if (location.state.locationData.pathname.includes("upcoming")) {
        upcomingSessionButtonSelection();
      }
      if (location.state.locationData.pathname.includes("completed")) {
        completedSessionButtonSelection();
      }
    }
  };

  const liveSessionButtonSelection = async () => {
    await getProgramDetails();
    setIsLiveSession(true);
    setIsUpcomingSession(false);
    setIsCompletedSession(false);
  };

  const upcomingSessionButtonSelection = async () => {
    await getProgramDetails();
    setIsLiveSession(false);
    setIsUpcomingSession(true);
    setIsCompletedSession(false);
  };

  const completedSessionButtonSelection = async () => {
    await getProgramDetails();
    setIsLiveSession(false);
    setIsUpcomingSession(false);
    setIsCompletedSession(true);
  };

  return (
    <View style={styles.container}>
      <View style={styles.subContainer}>
        <View style={styles.toggleContainer}>
          {/* <ButtonGroup/> */}
          <ButtonTab
            type={isLiveSession ? ButtonTypeSecondary.Secondary : ButtonTypeSecondary.Primary}
            buttonStyles={[styles.toggleButton, styles.borderLeftRadius]}
            textStyles={styles.textStyle}
            title={t("session:live")}
            onPress={() => {
              liveSessionButtonSelection();
            }}
          />
          <ButtonTab
            type={isUpcomingSession ? ButtonTypeSecondary.Secondary : ButtonTypeSecondary.Primary}
            buttonStyles={[styles.toggleButton]}
            textStyles={styles.textStyle}
            title={t("session:upcoming")}
            onPress={() => {
              upcomingSessionButtonSelection();
            }}
          />
          <ButtonTab
            type={isCompletedSession ? ButtonTypeSecondary.Secondary : ButtonTypeSecondary.Primary}
            buttonStyles={[styles.toggleButton, styles.borderRightRadius]}
            textStyles={styles.textStyle}
            title={t("session:completed")}
            onPress={() => {
              completedSessionButtonSelection();
            }}
          />
        </View>
        {programDetails && isLiveSession ? (
          <LiveSessionPage programDetails={programDetails} />
        ) : isUpcomingSession ? (
          <UpcomingSessionPage programDetails={programDetails} />
        ) : isCompletedSession ? (
          <CompletedSessionPage programDetails={programDetails} />
        ) : null}
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    display: "flex",
    padding: 20,
    paddingTop: 15,
    backgroundColor: "#F8F5F0",
    minHeight: "calc(100vh - 50px)"
  },
  header: {
    display: "flex",
    flexDirection: "row"
  },
  iconContainer: {
    marginTop: 10
  },
  titleIcon: {
    fontSize: 15
  },
  subContainer: {
    backgroundColor: colorPallete.white,
    borderRadius: 10,
    shadowColor: colorPallete.cordioRedDark1,
    shadowOpacity: 0.1,
    shadowOffset: {
      height: 10,
      width: 5
    },
    shadowRadius: 10,
    minHeight: "calc(100vh - 100px)"
  },
  containerStyle: {
    width: "20%",
    marginLeft: 15
  },
  dropDownContainerStyle: {
    borderWidth: 0,
    borderTopRightRadius: 5,
    borderBottomRightRadius: 5
  },
  toggleContainer: {
    flexDirection: "row",
    borderRadius: 20,
    padding: 18,
    paddingBottom: 0
  },
  toggleButton: {
    width: 180
  },
  textStyle: {
    fontSize: 12
  },
  borderLeftRadius: {
    borderBottomLeftRadius: 5,
    borderTopLeftRadius: 5
  },
  borderRightRadius: {
    borderBottomRightRadius: 5,
    borderTopRightRadius: 5
  }
});
