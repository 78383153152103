import { Login } from "@socion-cordio/common/src/models/login"; //Doubt!!
import { LOGIN } from "@socion-cordio/common/src/modules/login/actions/actionTypes";
import { IFluxStandardAction } from "@socion-cordio/common/src/store/interfaces";
const postCredentials = (data: {
  password: string;
  userName: number;
  countryCode: number;
}): IFluxStandardAction<{ password: string; userName: number; countryCode: number }> => {
  return {
    type: LOGIN.POST_CREDENTIALS.SUCCESS,
    payload: data
  };
};

//data on success?

const postCredentialsSucess = (data: Login): IFluxStandardAction<Login> => {
  return {
    type: LOGIN.POST_CREDENTIALS.LOADING,
    payload: data
  };
};

const postCredentialsError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: LOGIN.POST_CREDENTIALS.ERROR,
    payload: error
  };
};

export const LoginActions = {
  postCredentials,
  postCredentialsSucess,
  postCredentialsError
};
