import React, { ReactElement, useState, useEffect } from "react";
import { Form, Formik, FormikProps } from "formik";
import { StyleSheet, TouchableOpacity, View } from "react-native";
import { colorPallete } from "@socion-cordio/common/src/assets/styles/colors";
import {
  Text,
  TextSize,
  FontWeight,
  FontFamily
} from "@socion-cordio/common/src/components/atoms/text";
import Icon, { IconNames } from "@socion-cordio/common/src/components/atoms/icon";
import UserInputSecondary from "@socion-cordio/common/src/components/molecules/userInputSecondary";
import { Switch } from "@socion-cordio/common/src/components/atoms/switches";
import { springshedEndPoints } from "@socion-cordio/common/src/repositories/endPoints";
import { ApiClient } from "@socion-cordio/common/src/network/apiClient";
import { dataHelper } from "@socion-cordio/common/src/utils/dataHelper";
import Loader from "@socion-cordio/common/src/components/atoms/loader";
import { toast } from "react-toastify";
import RemoveLinkModal from "@socion-cordio/common/src/components/organisms/common-modals/removeLinkModal";
import { GenerateQR } from "@socion-cordio/common/src/components/atoms/generateQR";
import { DownloadQR } from "@socion-cordio/common/src/components/atoms/downloadQR";
import EntityDocuments from "@socion-cordio/common/src/components/organisms/entity/entityDocuments";
import { LocalStorage } from "@socion-cordio/common/src/services/storage/storageService";
import { useHistory } from "react-router-dom";
import { allRoutesNames } from "@socion-cordio/web/src/navigation/allRouteNames";

interface Props {
  onSubmit?: (data: { otp: number }) => void;
  programDetails: any;
  userRoles: any;
  isSuperAdmin: any;
  getUserRoles?: Function;
  getEntityStatus?: Function;
  entityStatus?: any;
}

export default function EntityDetails(props: Props): ReactElement {
  const [isInfoEnabled, setIsnfoEnabled] = useState<boolean>(false);
  const [detailsResponse, setDetailsResponse] = useState(null);
  const [loading, setLoading] = useState(true);
  const [failed, setFailed] = useState(false);
  const [editable, setEditable] = useState(false);
  const [memberRole, setMemberRoles] = useState([]);
  const [showRemoveModal, setShowRemoveModal] = useState(false);
  const [state, setState] = useState({
    businessRegistrationNumber: null,
    taxRegistrationNumber: null,
    addressLine1: null,
    addressLine2: null,
    city: null,
    state: null,
    country: null,
    pincode: null
  });
  const history: any = useHistory();
  const [isLoading, setIsLoading] = useState(false);

  const { programDetails, userRoles, isSuperAdmin, entityStatus } = props;

  useEffect(() => {
    getEntityDetails();
  }, []);

  useEffect(() => {
    setMemberRoles(userRoles?.userRolesData?.response);
  }, []);

  const getEntityDetails = async () => {
    try {
      const entityId = programDetails?.entityData?.entity?.registryEntityId;
      if (!entityId) {
        setLoading(false);
        setFailed(true);
        return;
      }
      const entityDetailsResponse = await ApiClient.get(
        springshedEndPoints.getEntityDetails(entityId)
      );
      setDetailsResponse(entityDetailsResponse);
      setValues(entityDetailsResponse);
      props.getEntityStatus(entityDetailsResponse?.Entity?.deleted);
      setIsnfoEnabled(!entityDetailsResponse?.Entity?.deleted);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setFailed(true);
    }
  };

  const handleChange = (value: string, name: string) => {
    setState({
      ...state,
      [name]: value
    });
  };

  const onSubmit = async () => {
    try {
      const newEntityDetails = {
        entity_id: detailsResponse?.Entity?.entity_id,
        name: detailsResponse?.Entity?.name,
        business_registration_number: state.businessRegistrationNumber,
        tax_registration_number: state.taxRegistrationNumber,
        address_line1: state.addressLine1,
        address_line2: state.addressLine2,
        city: state.city,
        state: state.state,
        country: state.country,
        pin_code: state.pincode,
        deleted: detailsResponse?.Entity?.deleted,
        is_registered: detailsResponse?.Entity?.registered,
        registered_date: detailsResponse?.Entity?.registered_date,
        created_at: detailsResponse?.Entity?.updatedAt,
        updated_at: detailsResponse?.Entity?.registereded_at,
        created_by: detailsResponse?.Entity?.createdBy,
        updated_by: detailsResponse?.Entity?.updatedBy,
        osid: detailsResponse?.Entity?.osid,
        entityidStr: detailsResponse?.Entity?.entity_id
      };
      const entityDetailsResponse = await ApiClient.put(
        springshedEndPoints.updateEntityDetails(),
        newEntityDetails
      );
      setDetailsResponse(entityDetailsResponse);
      getEntityDetails();
      toast.success("Entity details updated successfully.");
      setEditable(false);
    } catch (error) {
      toast.error("Something went wrong.");
      setEditable(false);
    }
  };

  const activateDeactivatEntityHandler = async (data: boolean) => {
    setShowRemoveModal(true);
  };

  const handleActivateDeactivate = async () => {
    try {
      const updatedEntityDetails = {
        entity_id: detailsResponse?.Entity?.entity_id,
        name: detailsResponse?.Entity?.name,
        business_registration_number: detailsResponse?.Entity?.business_registration_number,
        tax_registration_number: detailsResponse?.Entity?.tax_registration_number,
        address_line1: detailsResponse?.Entity?.address_line1,
        address_line2: detailsResponse?.Entity?.address_line2,
        city: detailsResponse?.Entity?.city,
        state: detailsResponse?.Entity?.state,
        country: detailsResponse?.Entity?.country,
        pin_code: detailsResponse?.Entity?.pin_code,
        deleted: isInfoEnabled,
        is_registered: detailsResponse?.Entity?.registered,
        registered_date: detailsResponse?.Entity?.registered_date,
        created_at: detailsResponse?.Entity?.updatedAt,
        updated_at: detailsResponse?.Entity?.registereded_at,
        created_by: detailsResponse?.Entity?.createdBy,
        updated_by: detailsResponse?.Entity?.updatedBy,
        osid: detailsResponse?.Entity?.osid,
        entityidStr: detailsResponse?.Entity?.entity_id
      };
      const entityDetailsResponse = await ApiClient.put(
        springshedEndPoints.updateEntityDetails(),
        updatedEntityDetails
      );
      LocalStorage.setStorage("isEntityDeleted", isInfoEnabled);
      history.push(allRoutesNames.app + allRoutesNames.workspace);
      getEntityDetails();
      toast.success(
        isInfoEnabled ? "Entity deactivated successfully" : "Entity reactivated successfully"
      );
      setIsnfoEnabled((isInfoEnabled: boolean) => !isInfoEnabled);
    } catch (error) {
      toast.error("Something went wrong.");
    }
    setShowRemoveModal(false);
  };

  const setValues = (detailsResponse: any) => {
    const {
      business_registration_number,
      tax_registration_number,
      address_line1,
      address_line2,
      city,
      state,
      country,
      pin_code
    } = detailsResponse?.Entity;
    setState({
      businessRegistrationNumber: business_registration_number,
      taxRegistrationNumber: tax_registration_number,
      addressLine1: address_line1,
      addressLine2: address_line2,
      city: city,
      state: state,
      country: country,
      pincode: pin_code
    });
    setEditable(false);
  };

  return (
    <View style={styles.container}>
      <View style={styles.subContainer}>
        {loading || isLoading ? (
          <Loader />
        ) : failed ? (
          <View>Failed to load data</View>
        ) : (
          <View style={styles.subContainerOne}>
            <View style={styles.detailsContainerOne}>
              <View style={styles.qrContainer}>
                {/* <View>
                  <View style={styles.qrCode}>
                    <View>
                      <QR value={'"id":"programDetails.entityData.entity.registryEntityId"'} />
                    </View>
                  </View>
                </View> */}
                <View>
                  <View style={styles.qrCode}>
                    <View nativeID="generateQR">
                      <View style={styles.displayQR} nativeID="displayQR">
                        Entity: {programDetails.program.entity.name}
                      </View>
                      <View nativeID="divider"> </View>
                      <View nativeID="qrCodeContainer">
                        <GenerateQR
                          id="qrCode"
                          size={125}
                          value={'"id":"programDetails.entityData.entity.registryEntityId"'}
                        />
                      </View>
                    </View>
                  </View>
                </View>

                <TouchableOpacity
                  onPress={() =>
                    DownloadQR({ id: "generateQR", qrTitle: programDetails.program.entity.name })
                  }
                >
                  <View style={styles.footerQrCode}>
                    <Icon
                      testID="close"
                      name={IconNames.downloadList}
                      customStyle={styles.titleIcon}
                    />
                    <View style={styles.qrDownloadText}>
                      <Text
                        fontWeight={FontWeight.Light}
                        testId="addRoleText"
                        textSize={TextSize.Small}
                        textStyle={[styles.headerText, styles.headerTextQrSupport]}
                      >
                        Download QR
                      </Text>
                    </View>
                  </View>
                </TouchableOpacity>
              </View>
              <View style={styles.basicDetailsOne}>
                <View style={styles.basicDetailsOneHeader}>
                  <Text
                    fontWeight={FontWeight.Light}
                    testId="addRoleText"
                    textSize={TextSize.Small}
                    textStyle={[styles.headerText, styles.headerTextTitleSupport]}
                  >
                    {programDetails?.entityData?.entity.name}
                  </Text>
                  {!props.isSuperAdmin &&
                    memberRole?.includes("ENTITY_ADMIN") &&
                    !detailsResponse?.Entity?.deleted && (
                      <View style={styles.editDeleteContainer}>
                        {editable ? (
                          <Icon
                            testID="tick"
                            name={IconNames.tick}
                            customStyle={[styles.alignEditDeleteButton]}
                            onPress={onSubmit}
                          />
                        ) : (
                          <Icon
                            testID="edit"
                            name={IconNames.edit}
                            customStyle={[styles.alignEditDeleteButton]}
                            onPress={() => setEditable(true)}
                          />
                        )}
                      </View>
                    )}
                </View>
                <View style={styles.inputContainerFields}>
                  <View style={styles.alignUserInput}>
                    <UserInputSecondary
                      textValue="Date of registration"
                      handleBlur={() => {}}
                      value={dataHelper.formatDate(detailsResponse?.Entity?.registered_date)}
                      placeholder="Enter Date of registration"
                      name="dateOfRegistrationInput"
                      id="dateOfRegistrationInput"
                      userStyle={[styles.alignFontSize, { width: "60%" }]}
                      userStyleText={[styles.alignTextWidth, { flex: 58 }]}
                      noFormik
                      editable={false}
                    />
                  </View>
                  <View style={styles.alignUserInput}></View>
                  <View style={{ flex: 20 }}></View>
                </View>
                <View style={styles.inputContainerFields}>
                  <View style={styles.alignUserInput}>
                    <UserInputSecondary
                      textValue="Administrator name"
                      handleBlur={() => {}}
                      value={
                        programDetails?.entityData?.entityAdmins
                          ? `${programDetails.entityData.entityAdmins[0]?.name}`
                          : null
                      }
                      placeholder="Enter Administrator name"
                      name="administratorNameInput"
                      id="administratorNameInput"
                      userStyle={styles.alignFontSize}
                      userStyleText={[styles.alignTextWidth, { flex: 58 }]}
                      noFormik
                      editable={false}
                    />
                  </View>
                  <View style={styles.alignUserInput}>
                    <UserInputSecondary
                      textValue="Contact number"
                      handleBlur={() => {}}
                      value={
                        programDetails?.entityData?.entityAdmins
                          ? `${programDetails.entityData.entityAdmins[0]?.countryCode}-${programDetails.entityData.entityAdmins[0]?.phoneNumber}`
                          : null
                      }
                      placeholder="Enter Contact number"
                      name="contactNumberInput"
                      id="contactNumberInput"
                      userStyle={[styles.alignFontSize]}
                      // userStyleText={{ flex: 50 }}
                      userStyleText={[styles.alignTextWidth, { flex: 50 }]}
                      noFormik
                      editable={false}
                    />
                  </View>
                  {!props.isSuperAdmin && memberRole?.includes("ENTITY_ADMIN") && (
                    <View style={[styles.alignView, { flex: 20 }]}>
                      <Switch
                        isOn={isInfoEnabled}
                        onColor="#B7504A"
                        offColor="#F1DCDB"
                        labelStyle={{ color: "black", fontWeight: "900" }}
                        size="medium"
                        onToggle={() => [activateDeactivatEntityHandler(isInfoEnabled)]}
                      />
                      <Text
                        fontWeight={FontWeight.Regular}
                        testId="addRoleText"
                        textSize={TextSize.Small}
                        textStyle={[styles.toggleButtonText]}
                      >
                        {isInfoEnabled ? "Active" : "Inactive"}
                      </Text>
                    </View>
                  )}
                  {props.isSuperAdmin && !memberRole?.includes("ENTITY_ADMIN") && (
                    <View style={[styles.alignView, { flex: 20 }]}></View>
                  )}
                </View>
              </View>
            </View>

            <View style={styles.detailsContainer}>
              <View style={styles.detailsContainerTwo}>
                <View style={styles.labelValueContainer}>
                  <View style={styles.header}>
                    <Text
                      fontWeight={FontWeight.Regular}
                      testId="internal"
                      textSize={TextSize.Small}
                      textStyle={[styles.alignTextStyles, styles.fontSize12]}
                    >
                      {"Details"}
                    </Text>
                  </View>
                  <View>
                    <UserInputSecondary
                      textValue="Business registration number"
                      handleChange={(businessRegistrationNumber: any) =>
                        handleChange(businessRegistrationNumber, "businessRegistrationNumber")
                      }
                      handleBlur={() => {}}
                      value={state.businessRegistrationNumber}
                      // inputStyle={[styles.inputStyling, styles.inputStylingAlignment]}
                      placeholder="Enter Business registration number"
                      name="businessRegistrationNumber"
                      id="businessRegistrationNumber"
                      noFormik
                      editable={editable}
                      userStyle={[
                        styles.alignTextStyles,
                        styles.fontSize12,
                        styles.inputStylingAlignment
                      ]}
                      userStyleText={[styles.alignTextStyles, styles.fontSize12]}
                      titleInputContainerStyles={styles.titleInputContainerStyles}
                    />
                  </View>
                  <View>
                    <UserInputSecondary
                      textValue=" Tax registration number"
                      handleChange={(taxRegistrationNumber: string) =>
                        handleChange(taxRegistrationNumber, "taxRegistrationNumber")
                      }
                      handleBlur={() => {}}
                      value={state.taxRegistrationNumber}
                      // inputStyle={[styles.inputStyling, styles.inputStylingAlignment]}
                      placeholder="Tax registration number"
                      name="taxRegistrationInput"
                      id="taxRegistrationInput"
                      noFormik
                      editable={editable}
                      userStyle={[
                        styles.alignTextStyles,
                        styles.fontSize12,
                        styles.inputStylingAlignment
                      ]}
                      userStyleText={[styles.alignTextStyles, styles.fontSize12]}
                      titleInputContainerStyles={styles.titleInputContainerStyles}
                    />
                  </View>
                  <View>
                    <UserInputSecondary
                      textValue=" Address line 1"
                      // handleChange={formikProps.handleChange("addressLine1Input")}
                      handleChange={(addressLine1: string) =>
                        handleChange(addressLine1, "addressLine1")
                      }
                      handleBlur={() => {}}
                      value={state.addressLine1}
                      // inputStyle={[styles.inputStyling, styles.inputStylingAlignment]}
                      placeholder="Enter Address Line 1"
                      name="addressLine1Input"
                      id="addressLine1Input"
                      noFormik
                      editable={editable}
                      userStyle={[
                        styles.alignTextStyles,
                        styles.fontSize12,
                        styles.inputStylingAlignment
                      ]}
                      userStyleText={[styles.alignTextStyles, styles.fontSize12]}
                      titleInputContainerStyles={styles.titleInputContainerStyles}
                    />
                  </View>
                  <View>
                    <UserInputSecondary
                      textValue=" Address line 2"
                      handleChange={(addressLine2: string) =>
                        handleChange(addressLine2, "addressLine2")
                      }
                      handleBlur={() => {}}
                      value={state.addressLine2}
                      // inputStyle={[styles.inputStyling, styles.inputStylingAlignment]}
                      placeholder="Enter Address Line 1"
                      name="addressLine2Input"
                      id="addressLine2Input"
                      noFormik
                      editable={editable}
                      userStyle={[
                        styles.alignTextStyles,
                        styles.fontSize12,
                        styles.inputStylingAlignment
                      ]}
                      userStyleText={[styles.alignTextStyles, styles.fontSize12]}
                      titleInputContainerStyles={styles.titleInputContainerStyles}
                    />
                  </View>
                  <View>
                    <UserInputSecondary
                      textValue="City"
                      handleChange={(city: string) => handleChange(city, "city")}
                      handleBlur={() => {}}
                      value={state.city}
                      // inputStyle={[styles.inputStyling, styles.inputStylingAlignment]}
                      placeholder="Enter City"
                      name="cityInput"
                      id="cityInput"
                      noFormik
                      editable={editable}
                      userStyle={[
                        styles.alignTextStyles,
                        styles.fontSize12,
                        styles.inputStylingAlignment
                      ]}
                      userStyleText={[styles.alignTextStyles, styles.fontSize12]}
                      titleInputContainerStyles={styles.titleInputContainerStyles}
                    />
                  </View>
                  <View>
                    <UserInputSecondary
                      textValue="State"
                      handleChange={(state: string) => handleChange(state, "state")}
                      handleBlur={() => {}}
                      value={state.state}
                      // inputStyle={[styles.inputStyling, styles.inputStylingAlignment]}
                      placeholder="Enter State"
                      name="stateInput"
                      id="stateInput"
                      noFormik
                      editable={editable}
                      userStyle={[
                        styles.alignTextStyles,
                        styles.fontSize12,
                        styles.inputStylingAlignment
                      ]}
                      userStyleText={[styles.alignTextStyles, styles.fontSize12]}
                      titleInputContainerStyles={styles.titleInputContainerStyles}
                    />
                  </View>
                  <View>
                    <UserInputSecondary
                      textValue="Country"
                      handleChange={(country: string) => handleChange(country, "country")}
                      handleBlur={() => {}}
                      value={state.country}
                      // inputStyle={[styles.inputStyling, styles.inputStylingAlignment]}
                      placeholder="Enter Country"
                      name="countryInput"
                      id="countryInput"
                      noFormik
                      editable={editable}
                      userStyle={[
                        styles.alignTextStyles,
                        styles.fontSize12,
                        styles.inputStylingAlignment
                      ]}
                      userStyleText={[styles.alignTextStyles, styles.fontSize12]}
                      titleInputContainerStyles={styles.titleInputContainerStyles}
                    />
                  </View>
                  <View>
                    <UserInputSecondary
                      textValue="Pincode"
                      handleChange={(pincode: string) => handleChange(pincode, "pincode")}
                      handleBlur={() => {}}
                      value={state.pincode}
                      // inputStyle={[styles.inputStyling, styles.inputStylingAlignment]}
                      placeholder="Enter Pincode"
                      name="pincodeInput"
                      id="pincodeInput"
                      noFormik
                      editable={editable}
                      userStyleText={[styles.alignTextStyles, styles.fontSize12]}
                      userStyle={[
                        styles.alignTextStyles,
                        styles.fontSize12,
                        styles.inputStylingAlignment
                      ]}
                      titleInputContainerStyles={styles.titleInputContainerStyles}
                    />
                  </View>
                </View>
              </View>
              <View
                style={
                  memberRole?.includes("ENTITY_ADMIN")
                    ? styles.detailsContainerTwo
                    : styles.detailsSubContainerTwo
                }
              >
                {memberRole?.includes("ENTITY_ADMIN") ? (
                  <EntityDocuments
                    detailsResponse={detailsResponse}
                    entityStatus={entityStatus}
                    isSuperAdmin={isSuperAdmin}
                    userRoles={memberRole}
                    programDetails={programDetails}
                    getEntityDetails={getEntityDetails}
                    setIsLoading={setIsLoading}
                  />
                ) : null}
              </View>
            </View>
          </View>
        )}
        {showRemoveModal && (
          <RemoveLinkModal
            modalVisible={showRemoveModal}
            selectedData={handleActivateDeactivate}
            textValue={
              isInfoEnabled
                ? "Are you sure you want to deactivate this Entity?"
                : "Are you sure you want to reactivate this Entity?"
            }
            deleteButtonText={isInfoEnabled ? "Deactivate" : "Reactivate"}
            setModalVisible={() => setShowRemoveModal(!showRemoveModal)}
          />
        )}
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    display: "flex",
    padding: 18
    // paddingLeft: 25
    // backgroundColor: "#F8F5F0"
  },
  header: {
    display: "flex",
    flexDirection: "row",
    marginBottom: 20,
    justifyContent: "space-between"
  },
  iconContainer: {
    marginTop: 10
  },
  titleIcon: {
    fontSize: 15
  },
  headerText: {
    fontSize: 14,
    fontFamily: FontFamily.Medium,
    lineHeight: 17
  },

  headerTextQrSupport: {
    fontWeight: "400",
    textDecorationLine: "underline",
    marginLeft: 10,
    fontSize: 12
  },
  subContainer: {
    backgroundColor: colorPallete.white
  },
  detailsContainer: {
    flexDirection: "row",
    flex: 1
  },
  detailsSubContainerTwo: {
    flex: 50
  },
  containerStyle: {
    width: "20%",
    marginLeft: 15
  },
  alignFontSize: {
    width: "85%",
    fontSize: 12
  },
  alignTextStyles: {
    flex: 70,
    marginTop: 0,
    color: colorPallete.textLight
  },
  fontSize12: {
    fontSize: 12
  },
  alignTextWidth: {
    flex: 15,
    fontSize: 12
  },
  dropDownContainerStyle: {
    borderWidth: 0,
    borderTopRightRadius: 5,
    borderBottomRightRadius: 5
  },
  toggleContainer: {
    flexDirection: "row"
  },
  toggleButton: {
    width: 180
  },
  subContainerOne: {},
  detailsContainerOne: {
    marginTop: 10,
    marginBottom: 40,
    flexDirection: "row",
    flex: 1
  },
  qrCode: {
    flex: 100,
    maxWidth: 130,
    minHeight: 130,
    alignItems: "center",
    alignSelf: "center"
  },
  qrCodeAlign: {},
  displayQR: {
    display: "none",
    color: colorPallete.textLight
  },
  footerQrCode: {
    flexDirection: "row",
    justifyContent: "center"
  },
  qrDownloadText: {},
  qrContainer: {
    flex: 13,
    marginTop: -10
  },
  basicDetailsOne: {
    marginLeft: 10,
    flex: 87
  },
  basicDetailsOneHeader: {
    flexDirection: "row",
    justifyContent: "space-between"
  },
  editDeleteContainer: {
    flexDirection: "row"
  },
  alignEditDeleteButton: {
    fontSize: 15,
    marginLeft: 30
  },
  headerTextTitleSupport: {
    fontWeight: "700",
    fontSize: 12
  },
  //CSS for Text and Input Field
  titleInputContainer: {
    display: "flex",
    flexDirection: "row",
    marginTop: 10
  },
  textFieldContianer: {
    marginLeft: 15,
    borderColor: colorPallete.cordioTaupe
  },
  subheaderText: {},
  inputStyling: {},

  inputStylingAlignment: {
    width: "75%"
  },
  titleInputContainerStyles: {
    marginTop: 0,
    alignItems: "center",
    marginBottom: 10
  },

  inputContainerFields: {
    flexDirection: "row",
    flexWrap: "wrap",
    alignItems: "center"
  },
  alignUserInput: {
    flex: 40,
    marginRight: 20
  },
  alignView: {
    flexDirection: "row",
    marginTop: 10
  },
  detailsContainerTwo: {
    flex: 50,
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: colorPallete.lightGreyThree,
    borderRadius: 10,
    marginHorizontal: 5,
    marginLeft: 0
  },
  labelValueContainer: {
    paddingVertical: 15,
    paddingHorizontal: 20
  },

  alignHeaderText: {
    flex: 20
  },
  textFieldContianerAlignment: {
    flex: 80
  },
  toggleButtonText: {
    alignSelf: "center",
    marginLeft: 20,
    fontSize: 12,
    color: colorPallete.textLight
  }
});
