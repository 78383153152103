import { Artefact } from "models/artefact";
import { ARTEFACTS } from "../actions/actionTypes";

export interface ITaskLoading {
  getArtefacts: boolean;
  // getTaskData: boolean;
  // getSubmissions: boolean;
  // getSubmitter: boolean;
}

export interface ITaskError {
  getArtefacts: string;
  // getTaskData: string;
  // getSubmissions: string;
  // getSubmitter: string;
}

export interface IArtefactState {
  artefactList: Artefact;
  // taskData: string;

  // submissionsList: any[];
  // submitter: any[];
  loading: ITaskLoading;
  error: ITaskError;
}

export const artefactInitialState: IArtefactState = {
  loading: {
    getArtefacts: false
    //   getTaskData: false,
    //   getSubmissions: false,
    //   getSubmitter: false
  },
  error: {
    getArtefacts: ""
    //   getTaskData: "",
    //   getSubmissions: "",
    //   getSubmitter: ""
  },
  artefactList: null
  //artefactData: null,
  // submissionsList: null,
  // submitter: []
};

const ArtefactReducer = (
  state = artefactInitialState,
  action: { type: string; payload: any }
): IArtefactState => {
  switch (action.type) {
    case ARTEFACTS.GET_ARTEFACTS.LOADING:
      return {
        ...state,
        loading: { ...state.loading, getArtefacts: true },
        error: { ...state.error, getArtefacts: null }
      };
    case ARTEFACTS.GET_ARTEFACTS.SUCCESS:
      return {
        ...state,
        loading: {
          ...state.loading,
          getArtefacts: false
        },
        error: {
          ...state.error,
          getArtefacts: null
        },
        artefactList: action.payload
      };
    case ARTEFACTS.GET_ARTEFACTS.ERROR:
      return {
        ...state,
        loading: {
          ...state.loading,
          getArtefacts: false
        },
        error: {
          ...state.error,
          getArtefacts: action.payload
        }
      };
    // case TASKS.GET_TASK_DATA.LOADING:
    //   return {
    //     ...state,
    //     loading: { ...state.loading, getTaskData: true },
    //     error: { ...state.error, getTaskData: null }
    //   };
    // case TASKS.GET_TASK_DATA.SUCCESS:
    //   return {
    //     ...state,
    //     loading: {
    //       ...state.loading,
    //       getTaskData: false
    //     },
    //     error: {
    //       ...state.error,
    //       getTaskData: null
    //     },
    //     taskData: action.payload
    //   };
    // case TASKS.GET_TASK_DATA.ERROR:
    //   return {
    //     ...state,
    //     loading: {
    //       ...state.loading,
    //       getTaskData: false
    //     },
    //     error: {
    //       ...state.error,
    //       getTaskData: action.payload
    //     }
    //   };

    // case TASKS.GET_SUBMISSIONS.LOADING:
    //   return {
    //     ...state,
    //     loading: { ...state.loading, getSubmissions: true },
    //     error: { ...state.error, getSubmissions: null }
    //   };
    // case TASKS.GET_SUBMISSIONS.SUCCESS:
    //   return {
    //     ...state,
    //     loading: {
    //       ...state.loading,
    //       getSubmissions: false
    //     },
    //     error: {
    //       ...state.error,
    //       getSubmissions: null
    //     },
    //     submissionsList: action.payload
    //   };
    // case TASKS.GET_SUBMISSIONS.ERROR:
    //   return {
    //     ...state,
    //     loading: {
    //       ...state.loading,
    //       getSubmissions: false
    //     },
    //     error: {
    //       ...state.error,
    //       getSubmissions: action.payload
    //     }
    //   };
    // case TASKS.GET_SUBMITTER.LOADING:
    //   return {
    //     ...state,
    //     loading: { ...state.loading, getSubmitter: true },
    //     error: { ...state.error, getSubmitter: null }
    //   };
    // case TASKS.GET_SUBMITTER.SUCCESS:
    //   return {
    //     ...state,
    //     loading: {
    //       ...state.loading,
    //       getSubmitter: false
    //     },
    //     error: {
    //       ...state.error,
    //       getSubmitter: null
    //     },
    //     submitter: action.payload
    //   };
    // case TASKS.GET_SUBMITTER.ERROR:
    //   return {
    //     ...state,
    //     loading: {
    //       ...state.loading,
    //       getSubmitter: false
    //     },
    //     error: {
    //       ...state.error,
    //       getSubmitter: action.payload
    //     }
    //   };

    default: {
      return state;
    }
  }
};

export default ArtefactReducer;
