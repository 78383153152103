import React, { ReactElement, useState, useEffect, useRef } from "react";
import { ScrollView, StyleSheet, View, TouchableOpacity } from "react-native";
import { toast, ToastContainer } from "react-toastify";
import Select from "react-select";
import Moment from "moment";
import {
  Text,
  TextSize,
  FontWeight,
  FontFamily
} from "@socion-cordio/common/src/components/atoms/text";
import Icon, { IconNames } from "@socion-cordio/common/src/components/atoms/icon";
import { ApiClient } from "@socion-cordio/common/src/network/apiClient";
import { HTTP_STATUS_CODES } from "@socion-cordio/common/src/network/constants";
import { qualificationPackEndPoints } from "@socion-cordio/common/src/repositories/endPoints";
import Button, { ButtonType } from "@socion-cordio/common/src/components/atoms/button";
import { Image } from "@socion-cordio/common/src/components/atoms/image";
import { GenerateQR } from "@socion-cordio/common/src/components/atoms/generateQR";
import { DownloadQR } from "@socion-cordio/common/src/components/atoms/downloadQR";
import UserInputSecondary from "@socion-cordio/common/src/components/molecules/userInputSecondary";
import UserInputTextArea from "@socion-cordio/common/src/components/molecules/userInputTextArea";
import { colorPallete } from "@socion-cordio/common/src/assets/styles/colors";
import AddTelemetryService from "@socion-cordio/common/src/services/telemetryService";
import { LocalStorage } from "@socion-cordio/common/src/services/storage/storageService";
import Loader from "@socion-cordio/common/src/components/atoms/loader";
import { ScrollContainer } from "@socion-cordio/common/src/components/organisms/scrollWrapper";
import RemoveLinkModal from "@socion-cordio/common/src/components/organisms/common-modals/removeLinkModal";
interface Props {
  onClose: Function;
  qualificationPackDetails: any;
  getBadgesTable: Function;
  setBundleId: Function;
  qualificationPackList: any;
  userRoles?: any;
}

export default function QualificationPackDetailsModal(props: Props): ReactElement {
  const { qualificationPackDetails, qualificationPackList, userRoles } = props;
  const [openDropdown, setOpenDropdown] = useState(true);
  const [topicValue, setTopicValue] = useState([]);
  const [selectedItems, setSelectedItems] = useState(false);
  const [selectionList, setSelectionList] = useState([]);
  const [topicList, setTopicList] = useState([]);
  const [selections, setSelections] = useState([]);
  const [temp, setTemp] = useState(false);
  const [chipRemove, setChipRemove] = useState(false);
  const [removeSelection, setRemoveSelection] = useState(false);
  const [qualificationPackName, setQualificationPackName] = useState("");
  const [packDescription, setPackDescription] = useState("");
  const [isEditable, setIsEditable] = useState<boolean>(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = React.useState([]);
  const [emptyListCheck, setEmptyListCheck] = useState(false);
  const [duplicateTopicCheck, setDuplicateTopicCheck] = useState(false);
  const [qpNameCheck, setQpNameCheck] = useState(false);
  const [qpNameCheckValue, setQpNameCheckValue] = useState(null);
  const [loading, setLoading] = useState(true);
  const [failed, setFailed] = useState(false);
  const [isTopicDeleted, setIsTopicDeleted] = useState(false);
  const [showRemoveModal, setShowRemoveModal] = useState(false);
  const programDetails: any = JSON.parse(localStorage.getItem("programDetails"));

  const qrCodeRef: any = useRef();

  useEffect(() => {
    setQualificationPackName(props.qualificationPackDetails?.bundle?.name);
    setPackDescription(props.qualificationPackDetails?.bundle?.description);
  }, [isEditable, props]);

  const editTopicDetialsHandler = () => {
    setIsEditable(true);
  };

  const handleDropdown = (item: any, id: number, e: any) => {
    setTemp(true);
    setOpenDropdown(false);
    let temp = [...selectionList];
    if (e.option) {
      setSearchTerm(e.option.label);
      if (item) {
        let flag = 0;
        temp.map((listId: any) => {
          listId.selectionId === id
            ? listId.topics.map((listItem: any) => {
                if (listItem.topicName === e.option.label) {
                  flag = 1;
                }
              })
            : listId;
        });
        temp.map((listId: any) => {
          listId.selectionId === id && flag === 0
            ? listId.topics.push({
                topicName: e.option.label,
                topicId: e.option.value
              })
            : listId;
        });
        setSelectionList(temp);
      }
    }
    setOpenDropdown(true);
  };

  const formatSelectionList = (list: any) => {
    if (list?.length != 0 && list != undefined) {
      const output = Object.values(
        list?.reduce((acc: any, cur: any) => {
          acc[cur.selectionId] = acc[cur.selectionId] || {
            selectionId: cur.selectionId,
            topics: []
          };
          acc[cur.selectionId].topics.push({
            topicName: cur.topicName,
            topicId: cur.topicId,
            isTopicActive: cur.isTopicActive
          });
          return acc;
        }, {})
      );
      setSelectionList(output);
    } else {
      setSelectionList([]);
    }
  };

  const isTopicPresentHandler = (topicList: any) => {
    const bundleTopicList = props?.qualificationPackDetails?.bundleTopiMapping;
    const temp: any = [];
    const list = bundleTopicList?.forEach((element: any) => {
      element.isTopicActive = false;
      topicList?.forEach((ele: any) => {
        if (element.topicId === ele.id) {
          element.isTopicActive = true;
        }
      });
    });
    formatSelectionList(bundleTopicList);
  };

  const handleOptions = async () => {
    const payload = {
      programId: programDetails.program.id
    };
    // all topic lists
    let topicListResponse = await ApiClient.post(
      qualificationPackEndPoints.getBundleTopicList(),
      payload
    );
    if (topicListResponse.responseCode === HTTP_STATUS_CODES.ok) {
      const listData = topicListResponse.response.map((list: any, index: number) => ({
        value: list.id,
        label: list.name
      }));
      setTopicList(listData);
      setLoading(false);
      isTopicPresentHandler(topicListResponse.response);
    } else {
      toast.error(topicListResponse.message);
      setLoading(false);
      setFailed(true);
    }
  };

  useEffect(() => {
    handleOptions();
    // formatSelectionList();
  }, [props]);

  const isDeletedTopicHandler = (detailsList: any) => {
    const list = detailsList.map((item: any) => item.topics);
    const flattenedList = [].concat.apply([], list);
    const filterList = flattenedList.map((item: any) => item.isTopicActive);
    const isFalsePresent = filterList.includes(false);
    return isFalsePresent;
  };

  const handleUpdateSelections = async () => {
    const emptyCheckList = isEmptyArrayExists(selectionList);
    const isDuplicatePresent = checkDuplicateTopic(selectionList);
    const isDeleteTopicPresent = isDeletedTopicHandler(selectionList);

    if (!isDeleteTopicPresent) {
      // Is the selectionList has topic any deleted topic
      if (emptyCheckList.length === 0) {
        // when selection list has empty topics array
        if (isDuplicatePresent) {
          // duplicate topicIDs
          setDuplicateTopicCheck(true);
          setEmptyListCheck(false);
          setIsTopicDeleted(false);
        } else {
          // No duplicate topicIDs and
          // list has no empty selectionList content
          setDuplicateTopicCheck(false);
          setEmptyListCheck(false);
          setIsTopicDeleted(false);
          submitSelectionListHandler();
        }
      } else {
        // list has empty selectionList
        setDuplicateTopicCheck(false);
        setEmptyListCheck(true);
        setIsTopicDeleted(false);
      }
    } else {
      // list which has topic which are deleted
      setDuplicateTopicCheck(false);
      setEmptyListCheck(false);
      setIsTopicDeleted(true);
    }
  };

  const isEmptyArrayExists = (list: any) => {
    return list.filter((item: any) => item.topics.length === 0);
  };

  const checkDuplicateTopic = (selectionList: any) => {
    //returns array of arrays of topicIds
    const filteredArray = selectionList.map((item: any) =>
      item.topics.map((ele: any) => ele.topicId)
    );
    // flattening the topicIds array
    var newArray = Array.prototype.concat.apply([], filteredArray);
    // finding if any duplicate present, returns true if yes
    const duplicateArr = newArray.some((val: any, i: any) => newArray.indexOf(val) !== i);
    return duplicateArr;
  };

  const submitSelectionListHandler = async () => {
    selectionList.map((list: any) => {
      list.topics.map((value: any) =>
        selections.push({
          bundle_topic_mapping_id: 0,
          programId: programDetails.program.id,
          selectionId: list.selectionId,
          topicId: value.topicId,
          topicName: value.topicName
        })
      );
    });
    const payload = selections;
    let updateResponse = await ApiClient.put(
      qualificationPackEndPoints.getQualificationPackBundleDetails(
        props?.qualificationPackDetails?.bundle?.id
      ),
      payload
    );

    if (updateResponse.responseCode === HTTP_STATUS_CODES.ok) {
      updateTelemetry(props.qualificationPackDetails?.bundle, "QP Selection Edited");
      toast.success("Selections updated successfully");
    } else {
      toast.error(updateResponse.message);
    }
    props.setBundleId(null);
    props.onClose();
    props.getBadgesTable();
  };

  const handleUpdate = async () => {
    const qpNameList = qualificationPackList.map((item: any) => item.bundle.name);
    const nameExist = qpNameList.includes(qualificationPackName);
    const nameExistDetails = qualificationPackList.filter(
      (item: any) => item.bundle.name === qualificationPackName
    );
    const currentName = qualificationPackName === qualificationPackDetails?.bundle?.name;
    nameExist
      ? currentName
        ? [setQpNameCheck(false), submitHandler()] // No change reqd.
        : [
            setQpNameCheck(true),
            setQpNameCheckValue(nameExistDetails[0]?.bundle?.name)
            // name exists
          ]
      : submitHandler(); // name change allowed
  };

  const submitHandler = async () => {
    const payload = {
      programId: programDetails.program.id,
      description: packDescription,
      name: qualificationPackName
    };
    let updateResponse = await ApiClient.put(
      qualificationPackEndPoints.updateQualificationPack(
        props?.qualificationPackDetails?.bundle?.id
      ),
      payload
    );
    if (updateResponse.responseCode === HTTP_STATUS_CODES.ok) {
      updateTelemetry(updateResponse?.response, "QP Edited");
      toast.success("Qualification Pack details updated successfully");
      props.onClose();
      props.setBundleId(null);
      props.getBadgesTable();
    } else {
      toast.error(updateResponse.message);
    }
  };

  const handleDeleteBundle = async () => {
    let updateResponse = await ApiClient.delete(
      qualificationPackEndPoints.updateQualificationPack(
        props?.qualificationPackDetails?.bundle?.id
      ),
      {}
    );
    props.onClose();
    props.getBadgesTable();
    const bundleData = props.qualificationPackDetails?.bundle;
    updateTelemetry(bundleData, "QP Deleted");
    toast.success("Qualification Pack deleted successfully");
    if (updateResponse) {
      toast.success(updateResponse.message);
    } else {
      toast.error(updateResponse.message);
    }
    setShowRemoveModal(false);
  };

  const handleAddSelection = () => {
    setTemp(true);
    selectionList.push({ selectionId: selectionList.length + 1, topics: [] });
    setSelectedItems(true);
  };

  const removeChip = (topic: any, selectionId: any) => {
    let tempList = [...selectionList];
    tempList.map((list: any, index: number) => {
      list.selectionId === selectionId
        ? list.topics.map((item: any, indexValue: number) =>
            item === topic ? tempList[index].topics.splice(indexValue, 1) : item
          )
        : list;
    });
    setSelectionList(tempList);
    setChipRemove(true);
    setTemp(true);
  };

  const handleRemoveSelection = (selectionId: number) => {
    selectionList.map((list: any, index: number) => {
      list.selectionId === selectionId ? selectionList.splice(index, 1) : list;
    });

    selectionList.map((list: any, index: number) => {
      index >= selectionId - 1 ? (selectionList[index].selectionId = index + 1) : list;
    });
    setRemoveSelection(true);
    setTemp(true);
  };

  useEffect(() => {
    setRemoveSelection(false);
  }, [removeSelection]);

  useEffect(() => {
    setSelectedItems(false);
  }, [selectedItems]);

  useEffect(() => {
    setChipRemove(false);
  }, [chipRemove]);

  const updateTelemetry = async (data: any, eventType: string) => {
    const programDetails: any = await LocalStorage.getStorage("programDetails");
    const user: any = await LocalStorage.getStorage("user");
    const body: any = {
      timestamp: Moment().toLocaleString(),
      createdAt: Moment().toLocaleString(),
      deleted: "false",
      updatedAt: Moment().toLocaleString(),
      eventType: eventType,
      bundleId: data.id,
      bundleName: data.name,
      entityId: null,
      entityName: null,
      noOfSelections: selectionList.length
    };
    AddTelemetryService(body, programDetails, user, true);
  };

  const toastHandler = (message: string) => {
    toast.error(message);
  };

  return (
    <View>
      <ToastContainer />
      {loading ? (
        <Loader customLoadingContainer={styles.loaderContainer} />
      ) : failed ? (
        <View>Failed to load data</View>
      ) : (
        <View style={styles.container}>
          <View style={styles.header}>
            <Text
              fontWeight={FontWeight.Bold}
              testId="headerText"
              textSize={TextSize.Small}
              textStyle={styles.headerText}
            >
              Qualification pack details
            </Text>
            <View>
              <Icon
                testID="close"
                name={IconNames.crossCircle}
                customStyle={styles.crossCircle}
                onPress={() => {
                  props.onClose(), setSelectionList([]);
                }}
              />
            </View>
          </View>
          <View>
            <View style={styles.topicDetailsContainer}>
              {!isEditable && (
                <View style={styles.qrCodeContainer}>
                  <View style={styles.qrCodeAlign}>
                    <View style={styles.qrCode} nativeID="generateQR">
                      <View style={styles.displayQR} nativeID="displayQR">
                        Qualification Pack: {props.qualificationPackDetails?.bundle?.name}
                      </View>
                      <View nativeID="divider"> </View>
                      <GenerateQR
                        id="qrCode"
                        size={150}
                        value={
                          "id:" + props.qualificationPackDetails?.bundle?.id + ", QRtype: BUNDLE"
                        }
                      />
                    </View>
                  </View>
                  <View style={styles.footerQrCode}>
                    <Icon
                      testID="download"
                      name={IconNames.download}
                      customStyle={styles.titleIcon}
                    />
                    <View style={styles.qrDownloadText}>
                      <Text
                        onPress={() => DownloadQR({ id: "generateQR" })}
                        fontWeight={FontWeight.Light}
                        testId="addRoleText"
                        textSize={TextSize.Small}
                        textStyle={[styles.headerText, styles.headerTextQrSupport]}
                      >
                        Download QR
                      </Text>
                    </View>
                  </View>
                </View>
              )}
              <View style={styles.topicDetials}>
                {" "}
                {!isEditable && props.qualificationPackDetails && (
                  <View>
                    <View style={styles.topicHeaderAlignmnet}>
                      <Text
                        fontWeight={FontWeight.Light}
                        testId="addRoleText"
                        textSize={TextSize.Small}
                        textStyle={[styles.headerText, styles.headerAlignment]}
                      >
                        Qualification Pack Name
                      </Text>
                    </View>
                    <View style={styles.topicNameHeaderAlignment}>
                      <Text
                        fontWeight={FontWeight.Bold}
                        testId="addRoleText"
                        textSize={TextSize.Small}
                        textStyle={styles.headerText}
                      >
                        {props.qualificationPackDetails?.bundle?.name}
                      </Text>
                    </View>
                    <View style={styles.topicHeaderAlignmnet}>
                      <Text
                        fontWeight={FontWeight.Light}
                        testId="addRoleText"
                        textSize={TextSize.Small}
                        textStyle={[styles.headerText, styles.headerAlignment]}
                      >
                        Description
                      </Text>
                    </View>
                    <View style={[styles.topicNameHeaderAlignment, styles.alignDescription]}>
                      <ScrollContainer
                        height="60px"
                        scrollable={
                          props.qualificationPackDetails?.bundle?.description?.length > 150
                            ? true
                            : false
                        }
                      >
                        {/* <ScrollView style={styles.descriptionScrollHeight}> */}
                        <Text
                          fontWeight={FontWeight.Light}
                          testId="addRoleText"
                          textSize={TextSize.Small}
                          textStyle={[styles.headerText]}
                        >
                          {props.qualificationPackDetails?.bundle?.description}
                        </Text>
                        {/* </ScrollView> */}
                      </ScrollContainer>
                    </View>
                  </View>
                )}
                {isEditable && (
                  <View>
                    <View style={styles.alignContainer}>
                      <View style={styles.userInputContainer}>
                        <UserInputSecondary
                          textValue="Qualification pack name"
                          handleChange={(qualificationPackName: string) => {
                            setQualificationPackName(qualificationPackName);
                          }}
                          handleBlur={() => {}}
                          value={qualificationPackName}
                          placeholder="Enter name"
                          name="enterQualificationName"
                          id="enterQualificationName"
                          noFormik
                          userStyle={styles.inputStylingAlignment}
                          userStyleText={[styles.textHeaderField, { marginTop: 5 }]}
                          textInputContainerStyles={{ marginLeft: 6 }}
                        />
                        {qpNameCheck && (
                          <View style={[styles.errorContainer, styles.alignErrorQpName]}>
                            <Text
                              fontWeight={FontWeight.Regular}
                              testId="internal"
                              textSize={TextSize.Small}
                              textStyle={[styles.subheaderText, styles.errorText]}
                            >
                              {`Qualification Pack ${qpNameCheckValue} already exists!`}
                            </Text>
                          </View>
                        )}
                      </View>
                    </View>

                    <View style={styles.alignContainer}>
                      <UserInputTextArea
                        textValue="Description"
                        label="Description"
                        placeholder="Write a description"
                        name="Description"
                        id="description"
                        handleChange={(description: string) => {
                          setPackDescription(description);
                        }}
                        handleBlur={() => {}}
                        value={packDescription}
                        numberOfLines={5}
                        multiline={true}
                        noFormik
                        userStyle={styles.inputStylingAlignment}
                        userStyleText={styles.textHeaderField}
                      />
                    </View>
                  </View>
                )}
                <View style={(styles.topicHeaderAlignmnet, styles.subHeaderAlignment)}>
                  {isEditable && <View style={{ flex: 15 }}></View>}
                  <View
                    style={
                      !isEditable
                        ? styles.dateCreatedContainer
                        : { flexDirection: "row", flex: 85, marginLeft: 15 }
                    }
                  >
                    <Text
                      fontWeight={FontWeight.Light}
                      testId="addRoleText"
                      textSize={TextSize.Small}
                      textStyle={[
                        styles.headerText,
                        styles.headerAlignment,
                        styles.fontSizeText,
                        { marginRight: 10 }
                      ]}
                    >
                      Created on
                    </Text>
                    <Text
                      fontWeight={FontWeight.Light}
                      testId="addRoleText"
                      textSize={TextSize.Small}
                      textStyle={[styles.headerText, styles.fontSizeText]}
                    >
                      {Moment(props.qualificationPackDetails?.bundle?.createdAt).format(
                        "DD MMM YY"
                      )}
                    </Text>
                  </View>
                </View>
              </View>
            </View>
          </View>

          <View style={[styles.header, { marginVertical: 5 }]}>
            <Text
              fontWeight={FontWeight.Light}
              testId="addRoleText"
              textSize={TextSize.Small}
              textStyle={[styles.headerText, styles.headerAlignment]}
            >
              Selections
            </Text>
            <View>
              {userRoles?.includes("PROGRAM_ADMIN") && (
                <TouchableOpacity onPress={() => !isEditable && handleAddSelection()}>
                  <Image
                    testId="primaryAddImg"
                    source={require("@socion-cordio/common/src/assets/images/primary_add.svg")}
                    imageStyle={{
                      width: 30,
                      height: 30
                    }}
                  />
                </TouchableOpacity>
              )}
            </View>
          </View>

          {/* When no selections */}
          {selectionList.length === 0 && (
            <View>
              <View style={[styles.selectionsContainer, styles.selectionContainerAlign]}>
                <Text
                  fontWeight={FontWeight.Bold}
                  testId="addSelectionText"
                  textSize={TextSize.Small}
                  textStyle={[styles.headerText, styles.headerAlignment]}
                >
                  No selections created yet
                </Text>
              </View>
            </View>
          )}

          {(selectionList || selectedItems || removeSelection) && selectionList.length != 0 && (
            <View style={{ height: 200 }}>
              {emptyListCheck && toastHandler("Selection cannot be empty")}
              {duplicateTopicCheck && toastHandler("A topic can be tagged only once")}
              {isTopicDeleted &&
                toastHandler("One or more topics tagged in selections are deleted")}
              {/* {isTopicDeleted && (
                <View style={styles.errorContainer}>
                  <Text
                    fontWeight={FontWeight.Regular}
                    testId="internal"
                    textSize={TextSize.Small}
                    textStyle={[styles.subheaderText, styles.errorText]}
                  >
                    {`One or more topics tagged in selections are deleted`}
                  </Text>
                </View>
              )} */}
              <ScrollContainer height="300px" scrollable={selectionList?.length > 1 ? true : false}>
                {selectionList.map((prop: any, key: number) => {
                  return (
                    <View key={key} style={styles.selectionsContainer}>
                      <View style={{ flexDirection: "row", alignItems: "center" }}>
                        <View>
                          <View style={styles.header}>
                            <View style={{ width: 200 }}>
                              <Text
                                fontWeight={FontWeight.Bold}
                                testId="addRoleText"
                                textSize={TextSize.Small}
                                textStyle={[styles.headerText, { marginTop: 10 }]}
                              >
                                Selection {prop.selectionId}
                              </Text>
                            </View>
                            <View style={{ flexDirection: "row", width: 550, marginLeft: 60 }}>
                              {userRoles?.includes("PROGRAM_ADMIN") && (
                                <View style={styles.dropdownContainer}>
                                  <Select
                                    isClearable={false}
                                    value={topicValue || ""}
                                    removeSelected={true}
                                    hideSelectedOptions={true}
                                    onChange={(item, e) =>
                                      handleDropdown(item, prop.selectionId, e)
                                    }
                                    placeholder="Add Topic"
                                    options={topicList}
                                    openMenuOnClick={false}
                                    isMulti={true}
                                    controlShouldRenderValue={false}
                                    menuPortalTarget={document.body}
                                    menuPosition={"fixed"}
                                    styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                                    isDisabled={isEditable ? true : false}
                                  />
                                </View>
                              )}
                              {userRoles?.includes("PROGRAM_ADMIN") && (
                                <TouchableOpacity
                                  onPress={() =>
                                    !isEditable && handleRemoveSelection(prop.selectionId)
                                  }
                                  style={styles.alignRemoveSelectionButton}
                                >
                                  <Image
                                    testId="remove"
                                    source={require("@socion-cordio/common/src/assets/images/remove.svg")}
                                    imageStyle={{
                                      width: 20,
                                      height: 20
                                    }}
                                  />
                                </TouchableOpacity>
                              )}
                            </View>
                          </View>
                          {(openDropdown || chipRemove) && (
                            <View>
                              <View style={styles.chipContrainer}>
                                {" "}
                                {prop.topics.map((topic: any, index: number) => (
                                  <View
                                    style={
                                      topic.isTopicActive === false
                                        ? [styles.chipLabel, styles.errorStyle]
                                        : styles.chipLabel
                                    }
                                  >
                                    {topic.topicName}
                                    {userRoles?.includes("PROGRAM_ADMIN") && (
                                      <Icon
                                        testID="close"
                                        name={IconNames.crossCircle}
                                        customStyle={[styles.crossCircle, { marginLeft: 10 }]}
                                        onPress={() => {
                                          !isEditable && removeChip(topic, prop.selectionId);
                                        }}
                                      />
                                    )}
                                  </View>
                                ))}
                              </View>
                            </View>
                          )}
                        </View>
                      </View>
                    </View>
                  );
                })}
              </ScrollContainer>
            </View>
          )}

          <View style={[styles.alignButtonContainer]}>
            {!isEditable && !temp && userRoles?.includes("PROGRAM_ADMIN") && (
              <View style={{ flexDirection: "row" }}>
                <View>
                  <Button
                    type={ButtonType.Secondary}
                    buttonStyles={styles.submitbutton}
                    title="Delete"
                    onPress={() => {
                      setShowRemoveModal(true);
                    }}
                  />
                </View>
                <View>
                  <Button
                    type={ButtonType.Primary}
                    buttonStyles={styles.submitbutton}
                    title="Edit"
                    onPress={editTopicDetialsHandler}
                  />
                </View>
              </View>
            )}
            {(isEditable || temp) && (
              <Button
                type={ButtonType.Primary}
                buttonStyles={styles.submitbutton}
                title="Save changes"
                onPress={() => {
                  isEditable ? handleUpdate() : handleUpdateSelections();
                }}
                disabled={qualificationPackName === ""}
              />
            )}
            {showRemoveModal && (
              <RemoveLinkModal
                modalVisible={showRemoveModal}
                selectedData={handleDeleteBundle}
                textValue={"Are you sure you want to delete this Qualification Pack?"}
                deleteButtonText={"Delete"}
                setModalVisible={() => setShowRemoveModal(!showRemoveModal)}
              />
            )}
          </View>
        </View>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  dropdownContainer: {
    position: "relative",
    zIndex: 2,
    width: "60%",
    marginRight: 20
  },
  loaderContainer: {
    width: 655,
    height: 450
  },
  selectionContainer: {
    flexDirection: "row",
    width: "600px",
    marginLeft: "10px"
  },
  alignRemoveSelectionButton: {
    alignSelf: "center"
  },
  chipContrainer: {
    display: "flex",
    flexDirection: "row",
    marginTop: "20px",
    flexWrap: "wrap",
    maxWidth: 650
  },
  chipLabel: {
    minHeight: 40,
    padding: "10px",
    borderRadius: 2,
    width: "fit-content",
    borderWidth: 1,
    borderColor: colorPallete.cordioTaupeLight4,
    borderStyle: "solid",
    flexDirection: "row",
    marginRight: 20,
    marginBottom: "10px"
  },
  errorStyle: {
    backgroundColor: colorPallete.cordioRed
  },
  container: {
    width: 680
  },
  displayQR: {
    display: "none",
    color: colorPallete.textLight
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row",
    marginBottom: 5
  },
  headerText: {
    fontSize: 14,
    fontFamily: FontFamily.Medium,
    lineHeight: 17,
    marginBottom: 5
  },
  headerAlignment: {
    color: colorPallete.textLight,
    fontFamily: FontFamily.Regular
  },
  fontSizeText: {
    fontSize: 12
  },
  crossCircle: {
    fontSize: 15
  },

  topicDetailsContainer: {
    flexDirection: "row",
    // marginTop: 15,
    marginBottom: 10,
    marginTop: 10
  },

  //QR Code scss
  qrCodeContainer: {
    flex: 25,
    marginLeft: 10,
    marginRight: 10
  },
  qrCodeAlign: {},
  qrCode: {
    flex: 100,
    // width: 130,
    maxWidth: 130,
    minHeight: 130,
    backgroundColor: colorPallete.cordioBeige
  },
  footerQrCode: {
    marginTop: 15,
    flexDirection: "row",
    justifyContent: "space-between"
  },
  titleIcon: {
    fontSize: 15
  },
  qrDownloadText: {},
  headerTextQrSupport: {
    fontWeight: "400",
    textDecorationLine: "underline",
    marginRight: 30
  },

  topicDetials: {
    flex: 75,
    // marginLeft: 10,
    marginRight: 10
  },
  userInputContainer: {
    marginTop: 2
  },
  inputStylingAlignment: {
    width: "100%"
  },
  textHeaderField: {
    flex: 10,
    marginRight: 20
  },
  topicHeaderAlignmnet: {
    marginBottom: 0
  },
  topicNameHeaderAlignment: {
    marginBottom: 15
  },
  alignDescription: {
    marginBottom: 0
  },
  descriptionScrollHeight: {
    height: 60
  },
  subHeaderAlignment: {
    flexDirection: "row",
    marginTop: 10
  },
  dateCreatedContainer: {
    flex: 50
  },

  alignContainer: {
    // alignItems: "center",
    // marginTop: 15,
    marginBottom: 15
  },
  alignContent: {
    flexDirection: "row",
    alignItems: "center",
    marginTop: 20
  },
  alignInnerContent: {
    flexDirection: "row",
    flex: 50
  },

  //Selection Container
  selectionsContainer: {
    flexWrap: "wrap",
    padding: 15,
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: colorPallete.cordioTaupe,
    borderRadius: 10,
    maxWidth: 660,
    marginBottom: 20
  },
  selectionContainerAlign: {
    height: 131,
    justifyContent: "center",
    alignItems: "center"
  },

  //input text
  inputStyling: {
    height: 40,
    // outline: "none",
    borderRadius: 5,
    padding: 7,
    fontSize: 14,
    fontFamily: FontFamily.Regular,
    fontWeight: "400"
  },

  //topic List
  topicListContainer: {
    flexDirection: "row",
    flexWrap: "wrap",
    marginTop: 5
  },
  topicList: {
    margin: 5
  },

  alignButtonContainer: {
    marginTop: 10,
    // marginBottom: 10,
    justifyContent: "center",
    alignSelf: "center"
  },
  submitbutton: {
    width: "140px",
    height: "50px",
    borderRadius: 10,
    marginRight: 10,
    marginLeft: 10
  },
  alignButton: {
    fontSize: 15,
    color: colorPallete.cordioTaupe,
    marginLeft: 15
    // marginLeft: 30
  },

  //topics list container
  topicsListContainer: {
    marginTop: 5,
    height: 130
  },
  miniContainer: {
    height: "80px",
    padding: 20,
    marginTop: 10,
    marginBottom: 10,
    width: "100%",
    borderColor: colorPallete.lightGreen,
    borderStyle: "solid",
    borderWidth: 1,
    borderRadius: 10,
    flexDirection: "row",
    justifyContent: "space-between"
  },
  miniIconContainer: {
    flexDirection: "row"
  },
  alignContainerButton: {
    fontSize: 20,
    color: colorPallete.cordioTaupe,
    marginRight: 5,
    marginLeft: 25
  },

  // Error container
  errorContainer: {
    // marginLeft: 90
    // position: "absolute",
    // top: 52,
    // left: 105
    marginBottom: 7
  },
  subheaderText: {
    fontWeight: "400",
    fontSize: 12,
    fontFamily: FontFamily.Regular,
    lineHeight: 17,
    color: colorPallete.textLight
  },
  errorText: {
    justifyContent: "center",
    color: colorPallete.cordioRed,
    fontSize: 12,
    marginRight: 0
  },
  alignErrorQpName: {
    position: "absolute",
    marginLeft: 115,
    marginTop: 50
  }
});
