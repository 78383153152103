import React, { useState, useEffect } from "react";
import { IconNames } from "@socion-cordio/common/src/components/atoms/icon";
import List from "@socion-cordio/common/src/components/molecules/List";
import { allRoutesNames as routeNames } from "@socion-cordio/web/src/navigation/allRouteNames";

export default function ArtefactNavbar(props: any) {
  const DATA = [];
  DATA.push({
    id: 1,
    title: "Artefacts",
    iconName: IconNames.artefact,
    // imageName: "artefact.svg",
    pathName: `${routeNames.app}`,
    path: `${routeNames.app}${routeNames.workspace}`
  });

  return <List getSidePanelWidth={props.getSidePanelWidth} data={DATA} />;
}
