import Icon, { IconNames } from "@socion-cordio/common/src/components/atoms/icon";
import {
  Text,
  TextSize,
  FontWeight,
  FontFamily
} from "@socion-cordio/common/src/components/atoms/text";
import { colorPallete } from "@socion-cordio/common/src/assets/styles/colors";
import React, { ReactElement, useEffect, useState } from "react";
import { View, StyleSheet, TouchableOpacity, ScrollView } from "react-native";
import { ApiClient } from "@socion-cordio/common/src/network/apiClient";
import { Image } from "@socion-cordio/common/src/components/atoms/image";
import { mediaHelper } from "@socion-cordio/common/src/utils/mediaHelperMethods";
import { toast } from "react-toastify";
import { HTTP_STATUS_CODES } from "@socion-cordio/common/src/network/constants";
import { springshedEndPoints } from "@socion-cordio/common/src/repositories/endPoints";
import { LocalStorage } from "@socion-cordio/common/src/services/storage/storageService";
import LoaderCircle from "@socion-cordio/common/src/components/atoms/loaderCircle";
import Loader from "@socion-cordio/common/src/components/atoms/loader";
import RemoveLinkModal from "@socion-cordio/common/src/components/organisms/common-modals/removeLinkModal";
import { v4 as uuidv4 } from "uuid";
import Moment from "moment";
import { auxiliaryMethods } from "@socion-cordio/common/src/utils/auxiliaryMethods";
interface IProps {
  detailsResponse?: any;
  entityStatus?: any;
  userRoles: any;
  isSuperAdmin: any;
  programDetails: any;
  getEntityDetails: Function;
  setIsLoading?: Function;
}

export default function EntityDocuments(props: IProps): ReactElement {
  const DELETE_DOCUMENT_MESSAGE = `Are you sure you want to Delete this Document?`;
  const deleteButtonText = `Delete Document`;
  const {
    detailsResponse,
    userRoles,
    isSuperAdmin,
    entityStatus,
    programDetails,
    getEntityDetails,
    setIsLoading
  } = props;
  const [entityDocsList, setEntityDocsList] = useState([]);
  const [userData, setUserData] = useState(undefined);
  const [uploading, setUploading] = useState(false);
  const [isDocDeleted, setIsDocDeleted] = useState(false);
  const [loading, setLoading] = useState(true);
  const [failed, setFailed] = useState(false);
  const [documentData, setDocumentData] = useState(null);
  const [allDocs, setAllDocs] = useState([]);
  const hiddenFileInput = React.useRef(null);
  const isEntityEnabled = JSON.parse(localStorage.getItem("isEntityDeleted"));

  const handleClick = (event: any) => {
    hiddenFileInput.current.click();
  };

  useEffect(() => {
    getUserDetails();
  }, []);

  const getUserDetails = async () => {
    const userDetails: any = await LocalStorage.getStorage("user");
    setUserData(userDetails);
  };

  useEffect(() => {
    setEntityDocsList(detailsResponse.EntityDocs);
    let temp = [...allDocs];
    if (detailsResponse?.EntityDocs?.length != 0) {
      detailsResponse.EntityDocs.map((i: any) => {
        temp.push(i.name);
      });
      setAllDocs(temp);
    }
  }, [detailsResponse.EntityDocs]);

  const handleCustomChange = async (e: any) => {
    if (allDocs.includes(e?.currentTarget?.files[0]?.name)) {
      toast.error("Document already added");
      return;
    }
    const validTypes = ["mp4", "mpeg", "mov", "flv", "png", "jpg", "jpeg", "pdf"];
    const validTypesImage = ["png", "jpg", "jpeg"];
    const validTypesDocs = ["pdf"];
    const validTypesVideo = ["mp4", "mpeg", "flv", "mov"];
    const splitFileName = e?.currentTarget?.files[0]?.name.split(".");
    const extension = splitFileName[splitFileName.length - 1];
    if (e.target.validity.valid && validTypes.includes(extension)) {
      setIsLoading(true);
      let formData: FormData = new FormData();
      formData.append("appType", "AWS");
      formData.append("file", e?.currentTarget?.files[0]);
      formData.append("fileName", e?.currentTarget?.files[0]?.name);
      if (validTypesImage.includes(extension)) {
        formData.append("fileType", "entity-docs/image");
      }
      if (validTypesDocs.includes(extension)) {
        formData.append("fileType", "entity-docs/document");
      }
      if (validTypesVideo.includes(extension)) {
        formData.append("fileType", "entity-docs/Entity Videos");
      }
      const uploadData = {
        content_type: extension,
        entity_document_id: uuidv4(),
        name: e?.currentTarget?.files[0].name,
        vimeo_url: "",
        entityidStr: `${programDetails.program.entity.registryEntityId}`,
        entity_id: programDetails.program.entity.registryEntityId,
        created_at: Moment().toLocaleString(),
        deleted: false,
        updated_at: Moment().toLocaleString(),
        updated_by: userData.userId,
        created_by: userData.userId
      };
      let response = await ApiClient.put(springshedEndPoints.fileUpload(), formData);
      if (response.responseCode === HTTP_STATUS_CODES.ok) {
        const fileUploadData = {
          ...uploadData,
          content_url: response?.response?.url,
          size: response?.response?.size
        };
        postDocument(fileUploadData);
        setIsLoading(false);
      } else {
        toast.error(response.message);
        setIsLoading(false);
      }
    } else {
      toast.error(
        "Invalid File Type! Only Files with format of mp4, mpeg, mov, flv, png, jpg, jpeg, pdf will be uploaded!!"
      );
    }
  };

  const postDocument = async (data: any) => {
    try {
      let response = await ApiClient.post(springshedEndPoints.updateEntityDocuments(), data);
      if (response.responseCode === HTTP_STATUS_CODES.ok) {
        getEntityDetails();
        toast.success("Document uploaded successfully");
        setUploading(false);
      } else {
        toast.error(response.message);
        setUploading(false);
      }
    } catch (error) {
      setUploading(false);
      toast.error("Something went wrong.");
    }
  };

  const deleteDocHandler = async (value: boolean) => {
    try {
      if (value === true) {
        console.log("value", value, documentData);
        const docId = documentData.entity_document_id;
        let response = await ApiClient.put(
          springshedEndPoints.deleteEntityDocuments(docId, true),
          {}
        );
        setIsDocDeleted(false);
        getEntityDetails();
        toast.success("Document deleted successfully");
      }
    } catch (error) {
      setIsDocDeleted(false);
      toast.error("Something went wrong.");
    }
  };

  const handleOnClick = (event: any) => {
    const { target = {} } = event || {};
    target.value = "";
  };

  return (
    <>
      {/* {loading ? (
        <Loader />
      ) : failed ? (
        <View>Failed to load data</View>
      ) : ( */}
      <View style={[styles.documentsContainer, styles.labelValueContainer]}>
        <View style={styles.header}>
          <Text
            fontWeight={FontWeight.Regular}
            testId="internal"
            textSize={TextSize.Small}
            textStyle={[styles.alignTextStyles, styles.fontSize12]}
          >
            {"Documents"}
          </Text>
          {(isSuperAdmin || userRoles?.includes("ENTITY_ADMIN")) && !entityStatus && (
            <>
              {!uploading && (
                <TouchableOpacity onPress={handleClick}>
                  <Image
                    testId="primaryAddImg"
                    source={require("@socion-cordio/common/src/assets/images/primary_add.svg")}
                    imageStyle={{
                      width: 28,
                      height: 28
                    }}
                  />
                  <input
                    style={{
                      display: "none",
                      zIndex: -1
                    }}
                    ref={hiddenFileInput}
                    type="file"
                    onChange={async (e) => {
                      await handleCustomChange(e);
                    }}
                    onClick={handleOnClick}
                  />
                </TouchableOpacity>
              )}
            </>
          )}
          {uploading && (
            <View style={styles.customLoader}>
              <LoaderCircle />
            </View>
          )}
        </View>
        <View>
          <View style={styles.listContainer}>
            {/* <ScrollView style={styles.entityListContainer}> */}
            {entityDocsList?.length !== 0 ? (
              <View>
                {entityDocsList?.map((prop: any, key: number) => {
                  return (
                    <View key={key} style={styles.subContainer}>
                      <View style={styles.miniContainer}>
                        <a
                          href={prop.vimeo_url === "" ? prop.content_url : prop.vimeo_url}
                          target="_blank"
                        >
                          <Text
                            fontWeight={FontWeight.Bold}
                            testId="headerText"
                            textSize={TextSize.Small}
                            textStyle={[styles.headerText, styles.miniContainerHeaderText]}
                          >
                            {prop.name}
                          </Text>
                        </a>
                        <View style={styles.miniIconContainer}>
                          {!isEntityEnabled && (
                            <TouchableOpacity
                              style={styles.alignButtons}
                              onPress={() => [setDocumentData(prop), setIsDocDeleted(true)]}
                            >
                              <Icon
                                testID="delete"
                                name={IconNames.deleteFile}
                                customStyle={[styles.alignContainerButton]}
                              />
                            </TouchableOpacity>
                          )}
                          <TouchableOpacity
                            style={styles.alignButtons}
                            onPress={() => {
                              mediaHelper.mediaDownloadEntityHandler(
                                prop.content_type,
                                auxiliaryMethods.getFileNameFromUrl(prop.content_url)
                              );
                            }}
                          >
                            <Icon
                              testID="download"
                              name={IconNames.downloadList}
                              customStyle={[styles.alignContainerButton]}
                            />
                          </TouchableOpacity>
                        </View>
                      </View>
                    </View>
                  );
                })}
              </View>
            ) : (
              <View>
                <Text
                  fontWeight={FontWeight.Bold}
                  testId="headerText"
                  textSize={TextSize.Small}
                  textStyle={[styles.headerText, styles.miniContainerHeaderText]}
                >
                  No Documents Available
                </Text>
              </View>
            )}
            {/* </ScrollView> */}
          </View>
        </View>
      </View>
      {/* )} */}

      {isDocDeleted && (
        <RemoveLinkModal
          modalVisible={isDocDeleted}
          selectedData={deleteDocHandler}
          textValue={DELETE_DOCUMENT_MESSAGE}
          deleteButtonText={deleteButtonText}
          setModalVisible={() => setIsDocDeleted(!isDocDeleted)}
        />
      )}
    </>
  );
}

const styles = StyleSheet.create({
  documentsContainer: {},
  labelValueContainer: {
    paddingVertical: 15,
    paddingHorizontal: 20
  },
  header: {
    display: "flex",
    flexDirection: "row",
    marginBottom: 20,
    justifyContent: "space-between"
  },
  alignTextStyles: {
    flex: 70,
    marginTop: 0,
    color: colorPallete.textLight
  },

  headerText: {
    color: colorPallete.textLight,
    fontSize: 12,
    fontFamily: FontFamily.Regular,
    lineHeight: 17
  },
  fontSize12: {
    fontSize: 12
  },
  listContainer: {
    marginBottom: 10
  },
  entityListContainer: {
    height: 310
  },
  subContainer: {},
  miniContainer: {
    padding: 20,
    marginBottom: 10,
    width: "100%",
    borderColor: colorPallete.lightGreen,
    borderStyle: "solid",
    borderWidth: 1,
    borderRadius: 10,
    flexDirection: "row",
    justifyContent: "space-between"
  },
  miniContainerHeaderText: {
    color: colorPallete.textBlack,
    fontSize: 12,
    fontFamily: FontFamily.Medium,
    lineHeight: 20
  },
  miniIconContainer: {
    flexDirection: "row"
  },
  alignContainerButton: {
    fontSize: 16,
    color: colorPallete.cordioTaupe
  },
  alignButtons: {
    marginRight: 5,
    marginLeft: 25
  },
  customLoader: {
    position: "absolute",
    right: -10,
    top: -10,
    justifyContent: "center",
    alignItems: "center"
  }
});
