import { UserRolesProgram } from "@socion-cordio/common/src/models/userRolesProgram"; //Doubt!!
// import { TOPICS } from "@socion-cordio/common/src/modules/topic/actions/actionTypes";
import { IFluxStandardAction } from "@socion-cordio/common/src/store/interfaces";
import { USER } from "@socion-cordio/common/src/modules/user-role/actions/actionTypes";

const getUserRolesProgram = (data: {
  programId: number;
}): IFluxStandardAction<{ programId: number }> => {
  return {
    type: USER.GET_USER_ROLES_PROGRAM.LOADING,
    payload: data
  };
};

const getUserRolesProgramSuccess = (
  data: UserRolesProgram
): IFluxStandardAction<UserRolesProgram> => {
  return {
    type: USER.GET_USER_ROLES_PROGRAM.SUCCESS,
    payload: data
  };
};

const getUserRolesProgramError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: USER.GET_USER_ROLES_PROGRAM.ERROR,
    payload: error
  };
};

export const UserRolesProgramActions = {
  getUserRolesProgram,
  getUserRolesProgramSuccess,
  getUserRolesProgramError
};
