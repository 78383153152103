import { call, put, takeLatest } from "redux-saga/effects";
import { IFluxStandardAction } from "store/interfaces";
import { ArtefactRepository } from "@socion-cordio/common/src/repositories/artefact/artefact";
import { NotificationsActions } from "@socion-cordio/common/src/modules/notifications/actions/action";
//import { Notifications } from "@socio-n-cordio/common/src/models/artefactNotifications";
import { Notifications } from "@socion-cordio/common/src/models/artefactNotifications";
import { NOTIFICATIONS } from "@socion-cordio/common/src/modules/notifications/actions/actionTypes";

function* getNotificationsWorkerSaga() {
  try {
    // const { payload } = value;
    console.log("in notification Saga");
    const response: Notifications = yield call(ArtefactRepository.getNotifications);
    console.log("saga resp : ", response);
    yield put(NotificationsActions.getNotificationsSuccess(response));
  } catch (error) {
    yield put(NotificationsActions.getNotificationsError(error));
  }
}

export default function* NotificationsWatcherSaga() {
  yield takeLatest(NOTIFICATIONS.GET_NOTIFICATIONS.LOADING, getNotificationsWorkerSaga);
}
