import React, { ReactElement, useEffect, useState, useMemo } from "react";
import { View, StyleSheet, TouchableOpacity } from "react-native";
import {
  Text,
  TextSize,
  FontWeight,
  FontFamily
} from "@socion-cordio/common/src/components/atoms/text";
import Table from "@socion-cordio/web/src/components/molecules/table/table";
import { colorPallete } from "@socion-cordio/common/src/assets/styles/colors";
import Icon, { IconNames } from "@socion-cordio/common/src/components/atoms/icon";
import jsonToCsv from "@socion-cordio/common/src/utils/jsonToCsvDownload";
import { useTranslation } from "react-i18next";
import { auxiliaryMethods } from "@socion-cordio/common/src/utils/auxiliaryMethods";
import { ApiClient } from "@socion-cordio/common/src/network/apiClient";
import { topicEndPoints } from "@socion-cordio/common/src/repositories/endPoints";
import SocionModal from "@socion-cordio/common/src/components/atoms/modal";
import ViewUserDetails from "@socion-cordio/common/src/components/organisms/common-modals/viewUserDetailsModal";
import { LocalStorage } from "@socion-cordio/common/src/services/storage/storageService";

interface Props {
  topicDetails?: any;
  topicName?: string;
  topicId?: any;
}

export default function TopicTrainer(props: Props): ReactElement {
  const { topicDetails, topicName, topicId } = props;
  const { t } = useTranslation();
  const [viewButtonDetails, setViewButtonDetails] = useState(false);
  const [selectedUserDetails, setSelectedUserDetails] = useState(null);

  const cellData = (testId: string, style: any, data: string, onPress?: Function) => (
    <Text
      fontWeight={FontWeight.Bold}
      testId={testId}
      textSize={TextSize.Small}
      style={[
        {
          fontSize: 12,
          fontFamily: FontFamily.Regular,
          fontWeight: FontFamily.Light,
          textSize: TextSize.Small,
          color: colorPallete.textBlack
        },
        style
      ]}
      onPress={onPress ? () => onPress() : null}
    >
      {data}
    </Text>
  );

  const displayUserDetails = (data: any) => {
    setViewButtonDetails(true);
    setSelectedUserDetails(data);
  };

  const columns = useMemo(
    () => [
      {
        Header: "Sr.No",
        accessor: "serialNo",
        width: 30,
        maxWidth: 30,
        disableSortBy: true,
        Cell: ({ row: { original: topic } }: { row: { original: any } }) => {
          const style = {};
          return cellData("serialNo", style, topic?.serialNo);
        }
      },
      {
        Header: "Trainer Name",
        accessor: "name",
        width: 58,
        maxWidth: 58,
        Cell: ({ row: { original: topic } }: { row: { original: any } }) => {
          const style = {};
          return (
            <Text
              fontWeight={FontWeight.Bold}
              testId="participantName"
              textSize={TextSize.Large}
              style={{
                fontSize: 12,
                fontFamily: FontFamily.Medium
              }}
            >
              <TouchableOpacity onPress={() => displayUserDetails(topic)}>
                {topic?.name}
              </TouchableOpacity>
            </Text>
          );
        }
      },
      {
        Header: "Location",
        accessor: "location",
        width: 64,
        maxWidth: 64,
        Cell: ({ row: { original: topic } }: { row: { original: any } }) => {
          const style = {};
          const location: string =
            auxiliaryMethods.fromatBaseLocation(topic) !== ""
              ? auxiliaryMethods.fromatBaseLocation(topic)
              : "None";
          return cellData("location", style, location);
        }
      },
      {
        Header: "Establishment",
        accessor: "establishment",
        width: 65,
        maxWidth: 65,
        Cell: ({ row: { original: topic } }: { row: { original: any } }) => {
          const style = {};
          const establishment = topic?.establishmentName ? topic?.establishmentName : "None";
          return cellData("establishment", style, establishment);
        }
      },
      {
        Header: "Verified Role",
        accessor: "Verified Role",
        width: 68,
        maxWidth: 68,
        Cell: ({ row: { original: topic } }: { row: { original: any } }) => {
          const style = {};
          const roleDetails =
            topic?.roleDetails.length !== 0
              ? auxiliaryMethods.getVerifiedRole(topic?.roleDetails)
              : "None";
          return cellData("role", style, roleDetails);
        }
      },
      {
        Header: "Verified Qualification",
        accessor: "Verified Qualification",
        width: 68,
        maxWidth: 68,
        Cell: ({ row: { original: topic } }: { row: { original: any } }) => {
          const style = {};
          const quali =
            topic?.qualificationTitle.length !== 0
              ? auxiliaryMethods.getVerifiedQualification(topic?.qualificationTitle)
              : "None";
          return cellData("qualification", style, quali);
        }
      },
      {
        Header: "Assigned Badges",
        accessor: "badgeDetails",
        width: 83,
        maxWidth: 83,
        Cell: ({ row: { original: topic } }: { row: { original: any } }) => {
          const style = {};
          const badgeDetails =
            topic?.badgeDetails.length !== 0
              ? auxiliaryMethods.getAssignedBadges(topic?.badgeDetails)
              : "None";
          return cellData("badgeDetails", style, badgeDetails);
        }
      },
      {
        Header: "Attestation Received",
        accessor: "Attestation Received",
        width: 65,
        maxWidth: 65,
        Cell: ({ row: { original: topic } }: { row: { original: any } }) => {
          const style = {};
          return cellData("attestations", style, topic?.attestations);
        }
      }
    ],
    []
  );

  const downloadCsvList = async (data: any) => {
    let payload = {
      topicIds: [topicId],
      userType: "TRAINER",
      timezone: auxiliaryMethods.getLocalTimezone()
    };
    const response = await ApiClient.post(topicEndPoints.getParticipantListCSV(), payload);
    if (response) {
      const program: any = await LocalStorage.getStorage("programDetails");
      const { name } = program.program;
      auxiliaryMethods.handleDownload(response, `${name}-Trainers`);
    }
  };

  return (
    <View>
      <Table
        columns={columns}
        data={topicDetails}
        paginationAndSearch={true}
        showPaginationField={true}
        download={
          <TouchableOpacity onPress={() => downloadCsvList(topicDetails)}>
            <View style={{ flexDirection: "row" }}>
              <Icon name={IconNames.downloadList} customStyle={styles.iconStyle} />
              <View>
                <Text
                  testId="testId"
                  fontWeight={FontWeight.Bold}
                  textSize={TextSize.Small}
                  style={[
                    styles.textAlignment,
                    {
                      fontFamily: FontFamily.Regular,
                      fontSize: 12
                    }
                  ]}
                >
                  {t("table:download")}
                </Text>
              </View>
            </View>
          </TouchableOpacity>
        }
      />
      {viewButtonDetails && (
        <View>
          <SocionModal
            modalVisible={viewButtonDetails}
            setModalVisible={() => setViewButtonDetails(!viewButtonDetails)}
            component={
              <ViewUserDetails
                onClose={() => {
                  setViewButtonDetails(false);
                }}
                selectedUserDetails={selectedUserDetails}
                piiFilter={true}
              />
            }
          />
        </View>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  roleName: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center"
  },
  image: {
    width: 25,
    height: 25,
    borderRadius: 25 / 2,
    marginEnd: 10
  },
  status: {
    height: 8,
    width: 8,
    borderRadius: 50,
    marginRight: 8
  },
  active: {
    backgroundColor: colorPallete.cordioGreen
  },
  pending: {
    backgroundColor: colorPallete.cordioOrange
  },
  deactivated: {
    backgroundColor: colorPallete.cordioRedDark3
  },
  textAlign: {
    fontFamily: FontFamily.Regular,
    color: colorPallete.textBlack,
    fontSize: 10
  },
  statusContainer: {
    flexDirection: "row",
    alignItems: "center"
  },
  statusColor: {
    height: 8,
    width: 8,
    borderRadius: 50,
    marginRight: 8
  },
  approvedColor: {
    backgroundColor: colorPallete.cordioGreen
  },
  pendingColor: {
    backgroundColor: colorPallete.cordioOrange
  },
  declineColor: {
    backgroundColor: colorPallete.cordioRedDark3
  },
  iconStyle: {
    color: colorPallete.textBlack,
    fontSize: 17,
    marginRight: 10
  },
  textAlignment: {
    textDecorationLine: "underline"
  },
  searchBarContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    padding: 25
  },
  customWidthStyle: {
    width: 400
  },
  headerText: {
    fontSize: 14,
    fontFamily: FontFamily.Medium,
    lineHeight: 19.5,
    fontWeight: "700"
  },
  headerAlignment: {
    paddingHorizontal: 25
  }
});
