import { ArtefactTypes } from "models/artefactTypes";
import { ARTEFACT_TYPES } from "../actions/actionTypes";

export interface ITaskLoading {
  getArtefactTypes: boolean;
}

export interface ITaskError {
  getArtefactTypes: string;
}

export interface IArtefactTypesState {
  artefactTypesList: ArtefactTypes;
  loading: ITaskLoading;
  error: ITaskError;
}

export const artefactTypesInitialState: IArtefactTypesState = {
  loading: {
    getArtefactTypes: false
  },
  error: {
    getArtefactTypes: ""
  },
  artefactTypesList: null
};

const ArtefactTypesReducer = (
  state = artefactTypesInitialState,
  action: { type: string; payload: any }
): IArtefactTypesState => {
  switch (action.type) {
    case ARTEFACT_TYPES.GET_ARTEFACT_TYPES.LOADING:
      return {
        ...state,
        loading: { ...state.loading, getArtefactTypes: true },
        error: { ...state.error, getArtefactTypes: null }
      };
    case ARTEFACT_TYPES.GET_ARTEFACT_TYPES.SUCCESS:
      return {
        ...state,
        loading: {
          ...state.loading,
          getArtefactTypes: false
        },
        error: {
          ...state.error,
          getArtefactTypes: null
        },
        artefactTypesList: action.payload
      };
    case ARTEFACT_TYPES.GET_ARTEFACT_TYPES.ERROR:
      return {
        ...state,
        loading: {
          ...state.loading,
          getArtefactTypes: false
        },
        error: {
          ...state.error,
          getArtefactTypes: action.payload
        }
      };

    default: {
      return state;
    }
  }
};

export default ArtefactTypesReducer;
