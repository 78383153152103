import React, { ReactElement, useState, useEffect } from "react";
import { StyleSheet, View } from "react-native";
import { colorPallete } from "@socion-cordio/common/src/assets/styles/colors";
import {
  Text,
  TextSize,
  FontWeight,
  FontFamily
} from "@socion-cordio/common/src/components/atoms/text";
import { topNavbarEndPoints } from "@socion-cordio/common/src/repositories/endPoints";
import { ApiClient } from "@socion-cordio/common/src/network/apiClient";
import { HTTP_STATUS_CODES } from "@socion-cordio/common/src/network/constants";
import Icon, { IconNames } from "@socion-cordio/common/src/components/atoms/icon";
import Button, { ButtonType } from "@socion-cordio/common/src/components/atoms/button";
import { toast, ToastContainer } from "react-toastify";

interface Props {
  onClose: Function;
  onConfirm: Function;
  msg: string;
}

export default function ConfirmModal(props: Props): ReactElement {
  const confirm = async () => {
    props.onConfirm();
  };

  return (
    <View style={styles.confirmModalContainer}>
      <ToastContainer />
      <View style={styles.container}>
        <View style={styles.header}>
          <Icon
            testID="close"
            name={IconNames.crossCircle}
            customStyle={styles.crossIcon}
            onPress={() => props.onClose("close")}
          />
        </View>
        <Text
          fontWeight={FontWeight.Regular}
          testId="confirmText"
          textSize={TextSize.Regular}
          textStyle={[styles.alignText, styles.textColor]}
        >
          {props?.msg}
        </Text>
      </View>
      <View style={[styles.header, styles.contentConatiner]}>
        <View style={styles.buttonContainer}>
          <Button
            type={ButtonType.Primary}
            buttonStyles={styles.button}
            textStyles={styles.buttonFont}
            title="Cancel"
            onPress={() => props.onClose("Cancel")}
          />
        </View>
        <View style={styles.buttonContainer}>
          <Button
            type={ButtonType.Primary}
            buttonStyles={styles.button}
            textStyles={styles.buttonFont}
            title="Confirm"
            onPress={() => confirm()}
          />
        </View>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  confirmModalContainer: {
    minHeight: 120
  },
  container: {
    width: 600
  },
  header: {
    display: "flex",
    // justifyContent: "space-between",
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center"
  },
  headerText: {
    fontSize: 14,
    fontFamily: FontFamily.Medium,
    lineHeight: 17
  },
  contentConatiner: {
    marginHorizontal: 20,
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-end"
  },
  alignText: {
    fontSize: 14,
    color: colorPallete.textLight,
    FontFamily: FontFamily.Medium,
    fontWeight: "400",
    marginBottom: 20,
    marginLeft: 25
  },
  textColor: {
    color: colorPallete.textBlack,
    fontFamily: FontFamily.Regular,
    fontWeight: "700"
  },
  button: {
    width: "130px",
    height: "45px",
    borderRadius: 10
  },
  buttonFont: {
    fontSize: 14
  },
  buttonContainer: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    marginTop: 15,
    marginLeft: 15
  },
  crossIcon: {
    fontSize: 15
    // marginLeft: 570
  }
});
