import { IFluxStandardAction } from "@socion-cordio/common/src/store/interfaces";
//import { ARTEFACTS } from "@socion-cordio/common/src/modules/artefacts/actions/actionTypes";
//import { Artefact } from "models/artefact";
import { ArtefactTypes } from "@socion-cordio/common/src/models/artefactTypes";
import { compact } from "lodash";
import { ARTEFACT_TYPES } from "@socion-cordio/common/src/modules/artefactTypes/actions/actionTypes";

const getArtefactTypes = (data: { pid: number }): IFluxStandardAction<{ pid: number }> => {
  return {
    type: ARTEFACT_TYPES.GET_ARTEFACT_TYPES.LOADING,
    payload: data
  };
};

const getArtefactTypesSuccess = (response: ArtefactTypes): IFluxStandardAction<any> => {
  if (response?.data) {
    response.data.type.forEach((task: any, index: number) => {
      task.serialNo = `${index + 1}.`;
    });
  } else {
    response.data.type = compact(response.data.type);
  }
  return {
    type: ARTEFACT_TYPES.GET_ARTEFACT_TYPES.SUCCESS,
    payload: response.data.type
  };
};

const getArtefactTypesError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: ARTEFACT_TYPES.GET_ARTEFACT_TYPES.ERROR,
    payload: error
  };
};

export const ArtefactTypesActions = {
  getArtefactTypes,
  getArtefactTypesSuccess,
  getArtefactTypesError
};
