import { deserialize, serialize } from "@socion-cordio/common/src/mappers/apiResMapper";
import { Topic } from "@socion-cordio/common/src/models/topic";
import { ApiClient } from "@socion-cordio/common/src/network/apiClient";
import { topicEndPoints } from "@socion-cordio/common/src/repositories/endPoints";

class TopicRepository {
  getTopics = async (body: { programId: number }): Promise<Topic> => {
    let data = await ApiClient.post(topicEndPoints.getTopics(), body);
    // const topicsData: Topic = deserialize(Topic, data);
    return data;
  };
}
const topicRepository = new TopicRepository();
export { topicRepository as TopicRepository };
