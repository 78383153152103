import { colorPallete } from "@socion-cordio/common/src/assets/styles/colors";
import {
  FontFamily,
  FontWeight,
  Text,
  TextSize
} from "@socion-cordio/common/src/components/atoms/text";
import Dropdown from "@socion-cordio/common/src/components/molecules/dropdown";
import { ArtefactRepository } from "@socion-cordio/common/src/repositories/artefact/artefact";
import { dataHelper } from "@socion-cordio/common/src/utils/dataHelper";
import { compact, get, isEmpty, omit, orderBy, replace, uniq } from "lodash";
import React, { useEffect, useState } from "react";
import { StyleSheet, TouchableOpacity, View } from "react-native";
import { useHistory, useLocation } from "react-router-dom";
import styled from "styled-components";
import MapLocation from "@socion-cordio/common/src/components/organisms/mapLocation";
import {
  addNotification,
  addWorkflow,
  delArtefact,
  deleteArtefactsToBeDeletedinLocal,
  getJoinedLocation,
  getLocation,
  STATUS
} from "../helpers/artefactHelper";
import AddSubmitters from "./addSubmitter";
import SocionModal from "@socion-cordio/common/src/components/atoms/modal";
import Loader from "@socion-cordio/common/src/components/atoms/loader";
import TextField from "@socion-cordio/common/src/components/atoms/textField";
import AddArtefactDocs from "./addArtefactDocs";
import DatePicker from "@socion-cordio/common/src/components/molecules/datePicker";
import ArtefactButtons from "./artefactButtons";
import { LocalStorage } from "@socion-cordio/common/src/services/storage/storageService";
import { useDispatch } from "react-redux";
import ConfirmModal from "@socion-cordio/common/src/components/organisms/confirmModal";
import { canAccessAll, canOnlyView, isVerifier } from "utils/artefactActionsHelper";
import { addArtefactEvent } from "../helpers/telemetryEvents";
import { toast } from "react-toastify";
import { artefact_messages } from "../data/message";
import { allRoutesNames } from "navigation/allRouteNames";
import { cancelUpload, startUploadDoc } from "../helpers/docHelper";
import DocUploadProgress from "./docUploadProgress";

const AddArtefactWrapper = styled.div`
  border-radius: 5px;
  margin: 20px;
  padding: 20px;
  border: 1px solid rgba(63, 162, 2, 0.6);
  min-height: calc(100vh - 35vh);
  position: relative;
  .close {
    position: absolute;
    right: 20px;
    top: 20px;
  }
  .AddArtefactDetailsWrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    background: "#ffffff";

    .AddArtefactRightBorder {
      border-right: 1px solid rgba(0, 0, 0, 0.12);
      width: 60%;
    }

    .addSubmittersWrapper {
      width: 40%;
      padding: 10px 15px;
      margin-bottom: 5px;
      .submitterImg {
        padding-right: 20px;
        img {
          margin: 0 0;
        }
      }
    }
    .formGroup {
      padding: 8px 0;
      display: flex;
      flex-direction: row;
      width: 100%;
      .title {
        width: 25%;
      }
      .submittedDateTitle {
        width: 40%;
      }
      .submittedDateField {
        width: 60%;
      }
      .field {
        width: 75%;
        padding: 0 25px;
      }
    }
  }

  .tagBtnWrapper {
    display: flex;
    align-items: center;
    padding-top: 14px;
    .tagBtn {
      border: 1px solid #b7504a;
      border-radius: 40px;
      font-family: Montserrat-Regular;
      //font-weight: 400;
      font-size: 14px;
      padding: 6px 12px;
      height: 30px;
      margin-right: 12px;
      display: flex;
      align-items: center;
      img {
        margin: 0 0;
        margin-left: 8px;
      }
    }
  }
  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black background */
    z-index: 9999; /* Ensure it's on top of everything */
  }
  @media (min-width: 767px) and (max-width: 900px) {
  }
  @media (max-width: 767px) {
    .AddArtefactDetailsWrapper {
      grid-template-columns: 1fr;
      grid-gap: 30px;
      .AddArtefactRightBorder {
        border-right: 0px;
      }
    }
    .tagBtnWrapper {
      display: block;
      .tagBtn {
        margin-right: 0px;
        margin-bottom: 12px;
        width: fit-content;
      }
    }
  }
`;
interface IFormProps {
  name: string;
  submittedDate: any;
}

const AddArtefact = () => {
  const programData: any = JSON.parse(localStorage.getItem("programDetails"));
  let currentProgramRole = localStorage.getItem("currentProgramRole");
  currentProgramRole = currentProgramRole ? JSON.parse(currentProgramRole) : [];
  let currentProgramBadgeRole = localStorage.getItem("currentProgramBadgeRole");
  currentProgramBadgeRole = currentProgramBadgeRole ? JSON.parse(currentProgramBadgeRole) : {};
  const history = useHistory();
  const user = JSON.parse(localStorage.getItem("user"));
  const [artefactMetaData, setArtefactMetaData] = useState(null);
  const [open, setOpen] = useState(null);
  const [val, setVal] = useState(null);
  const [items, setItems] = useState(undefined);
  const [types, setTypes] = useState([]);
  const [updatedLocation, setUpdatedLocation] = useState(null);
  const [artefactName, setArtefactName] = useState("");
  const [exSubmitters, setExSubmitters] = useState([]);
  const [newSubmitters, setNewSubmitters] = useState([]);
  const [leftSidePanelCollapse, setLeftSidePanelCollapse] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const [isFormUpdated, setIsFormUpdated] = useState(false);
  const [artefectName, setArtefectName] = useState(null);
  const [submittedDate, setSubmittedDate] = useState(null);
  const [uploadedDocs, setUploadedDocs] = useState([]);
  const [addedFields, setAddedFields] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [showTypeModal, setShowTypeModal] = useState(false);
  const [selectedType, setSelectedType] = useState(null);
  const [state, setState] = useState("start");
  const [isClearLocation, setIsClearLocation] = useState(null);
  const [disableBtn, setDisableBtn] = useState(null);
  const [showProgress, setShowProgress] = useState(null);
  const [isUploadCancelled, setIsUploadCancelled] = useState(null);

  const dispatch = useDispatch();
  const [showOverlay, setShowOverlay] = useState(false);

  const [fieldTouched, setFieldTouched] = useState({
    location: false,
    name: false,
    date: false
  });

  const leftNavCollapseHandler = async () => {
    let isSidePanelCollapsed: boolean = await LocalStorage.getStorage("isSidePanelCollapsed");
    setLeftSidePanelCollapse(isSidePanelCollapsed);
  };

  useEffect(() => {
    leftNavCollapseHandler();
  });

  useEffect(() => {
    fetchArtefactTypes();
    setFieldTouched({
      location: false,
      name: false,
      date: false
    });
    setSubmittedDate(dataHelper.formatDate(`${new Date()}`, "YYYY-MM-DD"));

    deleteArtefactsToBeDeletedinLocal();

    setIsLoading(true);
  }, []);

  useEffect(() => {
    if (!isEmpty(addedFields)) {
      const type = addedFields?.artefact_type_id || val;
      const docs = addedFields?.docs || uploadedDocs;
      const submitters = newSubmitters;
      const date =
        addedFields?.submitted_date === "Invalid date" ? false : addedFields?.submitted_date;

      if (
        isEmpty(updatedLocation) ||
        !type ||
        !artefactName ||
        !date ||
        isEmpty(docs) ||
        isEmpty(newSubmitters)
      ) {
        setIsFormUpdated(false);
      } else {
        setIsFormUpdated(true);
      }
    }
  }, [addedFields, newSubmitters]);

  useEffect(() => {
    if (!isEmpty(selectedType) && !isEmpty(updatedLocation)) {
      search();
    }
  }, [selectedType, updatedLocation]);

  const search = async () => {
    console.log("searchhhh", updatedLocation);

    const request: any = {
      pid: programData?.program?.id,
      location: updatedLocation?.location,
      artefact_type_id: selectedType?.id
    };

    if (!canAccessAll() && !canOnlyView() && !isVerifier()) {
      request.userId = user?.userid || user?.userId;
    }

    const res: any = await ArtefactRepository.getArtefacts(request);

    if (res?.data && res?.data.length > 0) {
      setShowTypeModal(true);
      setIsClearLocation(false);
    }
  };

  const fetchArtefactTypes = async () => {
    const pid = programData?.program?.id;
    const filterData = await ArtefactRepository.getArtefactTypes({ pid });
    const typeList = filterData?.data?.type || [];
    const list: any = typeList.map((data: any, id: any) => {
      if (data?.active === "1") {
        return { label: data.artefact_type_title, value: data.id };
      }
    });
    setTypes(typeList);
    setItems(compact(list));
    setIsLoading(false);
    setAddedFields({
      ...addedFields,
      submitted_date: getDate(`${new Date()}`)
    });
  };

  const getDate = (date: any) => {
    const datee =
      dataHelper.formatDate(date, "YYYY-MM-DD") ||
      dataHelper.formatDate(`${new Date()}`, "YYYY-MM-DD");
    return datee;
  };

  const handleSubmit = async (type: any) => {
    setDisableBtn(true);
    if (type.toLowerCase() === "cancel") {
      history.push(`${allRoutesNames.app}${allRoutesNames.workspace}`);
      return;
    }

    let request = {
      ...addedFields,
      created_by: user?.name,
      status: STATUS.submitted,
      user_id: user?.userId,
      userid: user?.userId,
      username: user?.userName,
      publish_date: getDate(`${new Date()}`),
      role: currentProgramRole.toString(),
      programName: programData?.program?.name,
      pid: programData?.program?.id,
      badgerole: currentProgramBadgeRole.toString(),
      submitters: newSubmitters
    };

    if (request?.subDistrict || request?.subdistrict || request?.sub_district) {
      request.sub_district = request?.subDistrict || request?.subdistrict || request?.sub_district;
    }

    request = omit(request, "docs");

    const docs = addedFields?.docs || [];

    let existingSub = exSubmitters;
    if (!isEmpty(request.submitters) || !isEmpty(newSubmitters)) {
      existingSub = existingSub.concat(request.submitters || newSubmitters);
      request.submitter_userid = { submitterList: existingSub };
    }
    // else {
    //   request.submitter_userid = { submitterList: [] };
    // }

    const createRes = await ArtefactRepository.addArtefact(request).catch((err) => {
      setDisableBtn(false);
      toast.error(replace(artefact_messages.error.m01, "{name}", `"${artefactName}"`));
      console.log("Error ", err);
    });
    if (createRes) {
      const artefact = createRes?.data[0];
      addArtefactEvent(artefact, 0);
      setArtefactMetaData(createRes?.data[0]);
      const docres = await uploadDocs(docs, artefact);
      if (!docres) {
        const notRes = await addNotification(request, artefact, user, dispatch);
        const workRes = await addWorkflow(artefact, artefact, user);
        toast.success(replace(artefact_messages.success.m01, "{name}", `"${artefactName}"`));
        history.push(`${allRoutesNames.app}${allRoutesNames.workspace}`);
      }
    }
  };

  const getMessage = () => {
    let msg = replace(artefact_messages.info.m02, "{type}", selectedType?.artefact_type_title);
    msg = replace(msg, "{location}", updatedLocation?.location);
    return msg;
  };

  const showTypeConfirmModal = () => {
    return (
      <SocionModal
        modalVisible={showTypeModal}
        setModalVisible={() => setShowTypeModal(!showTypeModal)}
        component={
          <ConfirmModal
            onClose={(value: string) => {
              if (value.toLowerCase() === "cancel") {
                setVal("");
                setUpdatedLocation({});
                setIsClearLocation(true);
                setSelectedType("");
                setAddedFields({
                  ...addedFields,
                  ...getLocation({}),
                  artefact_type_id: "",
                  artefact_type_title: ""
                });
              }
              setShowTypeModal(false);
            }}
            onConfirm={async () => setShowTypeModal(false)}
            msg={getMessage()}
          ></ConfirmModal>
        }
      ></SocionModal>
    );
  };

  const uploadDocs = async (docs: any, request: any) => {
    try {
      if (docs?.length > 0) {
        setShowProgress(true);
        setShowOverlay(true);
        const uploadRes = await startUploadDoc(
          uploadedDocs,
          {
            id: request?.artefact_meta_id,
            programId: programData?.program?.id,
            type: "artefact",
            docsCount: 0
          },
          request,
          artefactMetaData,
          user
        );
      }
      return "";
    } catch (err) {
      console.log("setIsUploadCancelled(true);", err);
      setShowProgress(false);
      setShowOverlay(false);
      setIsUploadCancelled(true);
      var artefactsToDelete: string[] = (await LocalStorage.getStorage("artefactsToDelete")) || [];
      artefactsToDelete.push(request?.artefact_meta_id);
      artefactsToDelete = uniq(artefactsToDelete);
      await LocalStorage.setStorage("artefactsToDelete", artefactsToDelete);
      await delArtefact({ ...request, ...artefactMetaData });
      toast.error("Failed to upload docs.Please try again...");
      return err;
    }
  };

  const showError = (field: any, error: any) => {
    return (
      <Text
        testId={`${field}-error`}
        fontWeight={FontWeight.Regular}
        textSize={TextSize.Small}
        style={styles.errorMsg}
      >
        {error}
      </Text>
    );
  };

  const updateUploadedDocs = (docs: any) => {
    setUploadedDocs(docs);
    setAddedFields({
      ...addedFields,
      docs
    });
    if (isUploadCancelled && docs?.length > 0) {
      setDisableBtn(false);
    }
  };

  const updatedSubmitters = (submitters: any[]) => {
    setNewSubmitters(submitters);
    setAddedFields({
      ...addedFields,
      submitters
    });
  };

  const getDisabled = () => {
    let bool = true;
    if (isUploading) {
      bool = true;
    } else if (isFormUpdated) {
      bool = false;
    }
    return bool;
  };

  const FullScreenOverlay = (props: { showOverlay: any; onButtonClick: any }) => {
    return props.showOverlay ? (
      <div className="overlay">
        {/* You can place the button here if needed */}
        <DocUploadProgress docsToUpload={uploadedDocs} handleCancel={props.onButtonClick} />
      </div>
    ) : null;
  };

  const handleButtonClick = () => {
    setIsUploadCancelled(true);
    cancelUpload();
    // setTimeout(() => {
    //   history.push(`${allRoutesNames.app}${allRoutesNames.workspace}`);
    // }, 4000);
  };

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <View style={styles.mainContainer}>
          <View style={styles.subContainer}>
            <AddArtefactWrapper>
              <div className="AddArtefactDetailsWrapper">
                <View style={styles.addArtefactWrapperContainer}>
                  <div className="AddArtefactRightBorder">
                    <div
                      style={{
                        fontSize: 14,
                        fontFamily: FontFamily.Bold,
                        color: colorPallete.textBlack,
                        paddingBottom: "20px",
                        fontWeight: 700
                      }}
                    >
                      Add Artefact
                    </div>
                    <div>
                      <div className="formGroup">
                        <div
                          className="title"
                          style={{
                            fontSize: 16,
                            fontFamily: FontFamily.Thin,
                            fontWeight: "400",
                            color: colorPallete.textLight
                          }}
                        >
                          Location
                        </div>

                        <div
                          className="field"
                          style={{ fontFamily: FontFamily.Thin, fontSize: 16 }}
                        >
                          <View style={styles.modalContainer}>
                            <MapLocation
                              showTitleAndCloseIcon={false}
                              currentBaseLocation={
                                updatedLocation?.country ? updatedLocation?.location : ""
                              }
                              clearLocation={isClearLocation}
                              locationChange={(value: any) => {
                                setFieldTouched({
                                  ...fieldTouched,
                                  location: true
                                });
                                setUpdatedLocation({});
                              }}
                              getSelectedLocation={(location: any) => {
                                console.log("getSelectedLocation", location);

                                if (!isEmpty(location)) {
                                  setUpdatedLocation(getLocation(location));
                                  setAddedFields({
                                    ...addedFields,
                                    ...getLocation(location)
                                  });
                                }
                              }}
                            />
                            {fieldTouched?.location &&
                              !updatedLocation?.country &&
                              showError("formattedaddress", "Please enter location")}

                            {updatedLocation?.country && (
                              <Text
                                fontWeight={FontWeight.Thin}
                                testId="joinedLocation"
                                textSize={TextSize.Small}
                                textStyle={{
                                  fontSize: 10,
                                  fontFamily: FontFamily.Regular,
                                  paddingStart: 10
                                }}
                              >
                                {`( ${getJoinedLocation(updatedLocation)} )`}
                              </Text>
                            )}
                          </View>
                        </div>
                      </div>

                      <div className="formGroup">
                        <div
                          className="title"
                          style={{
                            fontSize: 16,
                            fontFamily: FontFamily.Thin,
                            fontWeight: "400",
                            color: colorPallete.textLight
                          }}
                        >
                          Artefact type
                        </div>
                        <div className="field" style={{ fontFamily: FontFamily.Thin }}>
                          <View style={styles.dropDownContainer}>
                            <Dropdown
                              open={open}
                              dropdownValue={val}
                              items={items}
                              setOpen={setOpen}
                              setValue={setVal}
                              setItems={setItems}
                              onChangeValue={() => {
                                if (types) {
                                  const typed = types.find((typ) => {
                                    if (typ.id === val) {
                                      return typ;
                                    }
                                  });
                                  const d = typed;
                                  if (val && val !== artefactMetaData?.artefact_type_id) {
                                    setAddedFields({
                                      ...addedFields,
                                      artefact_type_id: d?.id,
                                      artefact_type_title: d?.artefact_type_title
                                    });
                                    setSelectedType(d);
                                  }
                                }
                              }}
                              containerWidthStyle={styles.containerStyle}
                              containerRadius={styles.dropDownContainerStyle}
                              pickerStyle={styles.alignTextHeader}
                              labelStyles={styles.labelStyles}
                              placeholderStyle={styles.placeholderStyle}
                              customItemContainerStyle={styles.customItemContainerStyle}
                              listItems={styles.listItems}
                              listItemLabelStyle={styles.listItemLabelStyle}
                              arrowIconStyle={styles.arrowIconStyle}
                              closeAfterSelecting={true}
                            />
                          </View>
                        </div>
                      </div>
                      <div className="formGroup">
                        <div
                          className="title"
                          style={{
                            fontSize: 16,
                            fontFamily: FontFamily.Thin,
                            fontWeight: "400",
                            color: colorPallete.textLight
                          }}
                        >
                          Artefact name
                        </div>
                        <div className="field" style={{ fontFamily: FontFamily.Thin }}>
                          {/* <View> */}
                          <TextField
                            editable={true}
                            label=""
                            handleChange={(value: any) => {
                              setFieldTouched({
                                ...fieldTouched,
                                name: true
                              });
                              setArtefactName(value);
                              setAddedFields({
                                ...addedFields,
                                artefact_name: value ? value.trim() : value
                              });
                            }}
                            handleBlur={() => {}}
                            value={artefactName}
                            inputStyle={styles.inputRole}
                            placeholder="Enter name"
                            name="addRoleTitle"
                            id="addRoleTitle"
                            noFormik
                          />
                          {fieldTouched?.name &&
                            !artefactName &&
                            showError("name", "Please add artefact name")}
                          {/* </View> */}
                        </div>
                      </div>
                    </div>
                    <div
                      style={{
                        paddingBottom: "20px",
                        paddingRight: "20px"
                      }}
                    >
                      <div
                        style={{
                          fontSize: 14,
                          fontFamily: FontFamily.Bold,
                          fontWeight: "700",
                          color: "#231F20",
                          paddingTop: "20px"
                        }}
                      >
                        Documents
                      </div>
                      {showProgress && (
                        <FullScreenOverlay
                          showOverlay={showOverlay}
                          onButtonClick={handleButtonClick}
                        />
                      )}
                      {!showProgress && (
                        <AddArtefactDocs
                          acceptedFormat={[".pdf", ".jpeg", ".jpg", ".png"]}
                          uploadedDocs={(docs: any) => updateUploadedDocs(docs)}
                          programId={programData?.program?.id}
                          type={"artefact"}
                          isUploading={(uploading: boolean) => {
                            setIsUploading(uploading);
                          }}
                          isEdit={false}
                          artefact={""}
                          docsCount={0}
                        ></AddArtefactDocs>
                      )}
                    </div>
                  </div>
                  <div className="addSubmittersWrapper">
                    <div
                      style={{
                        fontSize: 14,
                        fontFamily: FontFamily.Bold,
                        fontWeight: "700",
                        color: "#231F20",
                        paddingBottom: 25
                      }}
                    >
                      Submission Details
                    </div>
                    <div>
                      <div className="formGroup">
                        <div
                          className="submittedDateTitle"
                          style={{
                            fontSize: 16,
                            fontFamily: FontFamily.Thin,
                            fontWeight: "400",
                            // color: "#231F20"
                            color: colorPallete.textLight
                          }}
                        >
                          Submitted date
                        </div>
                        <div className="submittedDateField" style={{ fontFamily: FontFamily.Thin }}>
                          {/* <View> */}
                          <DatePicker
                            min="2019-01-01"
                            max="2024-12-31"
                            onChange={(e: any) => {
                              setFieldTouched({
                                ...fieldTouched,
                                date: true
                              });
                              setSubmittedDate(getDate(e?.target?.value));
                              setAddedFields({
                                ...addedFields,
                                submitted_date: getDate(e?.target?.value)
                              });
                            }}
                            value={submittedDate}
                            name="submittedDate"
                            id="submittedDate"
                            noFormik
                          />
                          {fieldTouched?.date &&
                            !submittedDate &&
                            showError("submittedDate", "Please add submitted date")}
                          {/* </View> */}
                        </div>
                      </div>

                      <AddSubmitters
                        programId={programData?.program?.id}
                        selectedSubmitters={(users: any[]) => updatedSubmitters(users)}
                        submitters={exSubmitters}
                      ></AddSubmitters>
                    </div>
                  </div>
                </View>
                <View
                  style={[
                    styles.addArtefactWrapperContainer,
                    {
                      display: "flex",
                      justifyContent: "flex-end",
                      alignItems: "center",
                      width: "100%"
                    },
                    showOverlay ? { zIndex: -1 } : null
                  ]}
                >
                  <View style={{ width: "75%" }}></View>
                  <View style={[styles.buttons]}>
                    <ArtefactButtons
                      disabled={disableBtn || getDisabled()}
                      buttons={["Save"]}
                      onClick={(type: any) => handleSubmit(type)}
                    ></ArtefactButtons>
                  </View>
                </View>
              </div>
            </AddArtefactWrapper>
          </View>
        </View>
      )}
      {showTypeModal && showTypeConfirmModal()}
    </>
  );
};

const styles = StyleSheet.create({
  mainContainer: {
    // top: 55,
    paddingBottom: 20,
    paddingHorizontal: 20,
    // paddingTop: 15,
    backgroundColor: colorPallete.cordioBeigeLight2,
    minHeight: "calc(100vh - 50px)"
    // display: "flex",
  },
  subContainer: {
    backgroundColor: colorPallete.white,
    borderRadius: 10,
    shadowColor: colorPallete.cordioRedDark1,
    shadowOpacity: 0.1,
    shadowOffset: {
      height: 10,
      width: 5
    },
    shadowRadius: 10
  },

  addArtefactWrapperContainer: {
    display: "flex",
    flexDirection: "row",
    width: "100%"
  },
  containerStyle: {
    marginBottom: 0,
    display: "flex",
    width: "100%",
    alignItems: "center",
    height: 20,
    borderWidth: 1,
    paddingVertical: 20,
    borderRadius: 5,
    borderColor: colorPallete.cordioTaupeLight4
  },
  dropDownContainer: {
    zIndex: 10,
    width: "100%"
  },
  dropDownContainerStyle: {
    borderWidth: 0,
    borderTopRightRadius: 5,
    borderBottomRightRadius: 5,
    justifyContent: "center",
    marginBottom: 0,
    width: "100%"
  },
  alignTextHeader: {
    paddingTop: 5,
    marginLeft: 0,
    marginRight: 0,
    paddingVertical: 5
  },
  labelStyles: {
    // paddingLeft: 0,
    fontFamily: FontFamily.Thin,
    fontWeight: FontWeight.Bold,
    fontSize: 16,
    color: "#231F20",
    paddingHorizontal: 10,
    paddingLeft: 20
  },
  placeholderStyle: {
    paddingHorizontal: 10,
    fontFamily: FontFamily.Thin,
    fontSize: 16
  },
  customItemContainerStyle: {
    padding: 5,
    width: "100%"
  },
  listItems: {
    padding: 5
  },
  listItemLabelStyle: {
    fontFamily: FontFamily.Thin,
    fontWeight: FontWeight.Bold
  },
  arrowIconStyle: {
    width: 25,
    height: 19,
    marginHorizontal: 5
  },

  modalContainer: {
    width: "100%",
    zIndex: 90
  },

  inputRole: {
    height: 40,
    outline: "none",
    width: "100%",
    borderRadius: 5,
    padding: 7,
    paddingLeft: 10,
    fontSize: 16,
    fontFamily: FontFamily.Thin,
    fontWeight: "500",
    borderColor: colorPallete.cordioTaupeLight4,
    color: colorPallete.textBlack
  },
  errorMsg: {
    fontSize: 12,
    color: "#FF0D10",
    padding: 5
  },
  buttons: {
    width: "25%",
    justifyContent: "flex-end",
    alignItems: "center"
  }
});
export default AddArtefact;
