import React, { ReactElement, useState, useEffect } from "react";
import { StyleSheet, View, Pressable } from "react-native";
import { colorPallete } from "@socion-cordio/common/src/assets/styles/colors";
import {
  Text,
  TextSize,
  FontWeight,
  FontFamily
} from "@socion-cordio/common/src/components/atoms/text";
import Icon, { IconNames } from "@socion-cordio/common/src/components/atoms/icon";
import Button, { ButtonType } from "@socion-cordio/common/src/components/atoms/button";
import TextField from "@socion-cordio/common/src/components/atoms/textField";
import { profileEndPoints } from "@socion-cordio/common/src/repositories/endPoints";
import { ApiClient } from "@socion-cordio/common/src/network/apiClient";
import { HTTP_STATUS_CODES } from "@socion-cordio/common/src/network/constants";
import EmailInput from "@socion-cordio/common/src/components/molecules/emailInput";
import EmailInputPrimary from "@socion-cordio/common/src/components/molecules/emailInputPrimary";
import { toast } from "react-toastify";
interface Props {
  onClose: Function;
  modalTitle?: string;
}

export default function AddEmail(props: Props): ReactElement {
  const [emailSent, setEmailSent] = useState(false);
  const [email, setEmail] = useState("");
  const [error, setError] = useState(false);
  const [incorrectEmailMsg, setIncorrectEmailMsg] = useState(null);

  const updateEmail = async () => {
    const updateEmailResponse = await ApiClient.get(profileEndPoints.updateEmail(email));
    if (updateEmailResponse.responseCode === HTTP_STATUS_CODES.ok) {
      setEmailSent(true);
      // toast.success("Email ID updated successfully");
    } else {
      setIncorrectEmailMsg(updateEmailResponse?.message);
    }
  };

  const handleEmail = (email: string) => {
    // const emailPattern = /^\w+@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$/;
    const emailPattern =
      /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
    if (email.match(emailPattern)) {
      setError(false);
    } else {
      setError(true);
    }
    setEmail(email);
    setIncorrectEmailMsg(null);
  };
  return (
    <View>
      {emailSent ? (
        <View>
          <View style={styles.modalHeader}>
            <Text
              fontWeight={FontWeight.Bold}
              testId="addRoleText"
              textSize={TextSize.Small}
              textStyle={styles.headerText}
            >
              {props.modalTitle}
            </Text>
            <View>
              <Pressable onPress={() => props.onClose()}>
                <Icon testID="close" name={IconNames.crossCircle} />
              </Pressable>
            </View>
          </View>
          <View>
            <Text
              fontWeight={FontWeight.Regular}
              testId="addRoleText"
              textSize={TextSize.Small}
              style={{ fontSize: 14 }}
            >
              Please click on the link sent to this updated email to complete the verification
              process.
            </Text>
          </View>
        </View>
      ) : (
        <View>
          <EmailInputPrimary
            title="Add new email address"
            onClose={() => props.onClose()}
            testId="addEmailAddressText"
            label="Email"
            handleChange={(email: string) => handleEmail(email)}
            value={email}
            placeholder="Enter email"
            name="addEmail"
            id="addEmail"
            handleSubmit={() => updateEmail()}
            disabled={error || !email}
            error={error}
            errorMessage="Please enter valid email"
            serverError={incorrectEmailMsg}
          />
        </View>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  modalHeader: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row"
  },
  headerText: {
    fontWeight: "700",
    fontSize: 14,
    fontFamily: FontFamily.Medium,
    lineHeight: 17
  },
  subheaderText: {
    fontWeight: "400",
    fontSize: 14,
    fontFamily: FontFamily.Regular,
    lineHeight: 17,
    color: colorPallete.textBlack,
    marginTop: 13,
    flex: 20
  },
  submitButtonContainer: {
    alignItems: "center",
    marginTop: 30,
    marginBottom: 10
  },
  alignSubmitButton: {
    marginTop: 50
  },
  submitbutton: {
    width: "175px",
    height: "50px",
    borderRadius: 10,
    marginLeft: 10,
    marginRight: 10
  },
  emailInputContainer: {
    marginTop: 20,
    flexDirection: "row",
    justifyContent: "center"
  },
  subHeaderContainer: {
    marginTop: 10,
    marginRight: 15
  },
  inputRole: {
    height: 40,
    outline: "none",
    borderRadius: 5,
    padding: 7,
    fontSize: 14,
    fontFamily: FontFamily.Regular,
    fontWeight: "400",
    width: "330px"
  },
  error: {
    position: "relative",
    color: "red",
    alignSelf: "flex-start",
    fontSize: 10,
    fontWeight: "400",
    zIndex: -1
  }
});
