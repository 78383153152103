import { IFluxStandardAction } from "@socion-cordio/common/src/store/interfaces";
import { ViewBundle } from "@socion-cordio/common/src/models/qualificationPacks";
import { BUNDLE } from "@socion-cordio/common/src/modules/qualificationPacks/actions/actionTypes";

const getBundle = (data: { bundleId: number }): IFluxStandardAction<{ bundleId: number }> => {
  return {
    type: BUNDLE.GET_BUNDLE.LOADING,
    payload: data
  };
};

const getBundleSuccess = (data: ViewBundle): IFluxStandardAction<ViewBundle> => {
  return {
    type: BUNDLE.GET_BUNDLE.SUCCESS,
    payload: data
  };
};

const getBundleError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: BUNDLE.GET_BUNDLE.ERROR,
    payload: error
  };
};

export const QualificationPackActions = {
  getBundle,
  getBundleSuccess,
  getBundleError
};
