import React, { ReactElement, useState } from "react";
import { StyleSheet, View } from "react-native";
import {
  Text,
  TextSize,
  FontWeight,
  FontFamily
} from "@socion-cordio/common/src/components/atoms/text";
import Icon, { IconNames } from "@socion-cordio/common/src/components/atoms/icon";
import Button, { ButtonType } from "@socion-cordio/common/src/components/atoms/button";
import Modal from "@socion-cordio/common/src/components/atoms/modal";
import { useTranslation } from "react-i18next";
interface Props {
  onSubmit?: (data: any) => void;
  modalVisible: boolean;
  setModalVisible?: Function;
  sendData?: any;
}
export default function CancelSessionModal(props: Props): ReactElement {
  const { t } = useTranslation();
  const { modalVisible, setModalVisible, sendData } = props;

  const confirmCancelHandler = () => {
    sendData(true);
  };

  return (
    <View>
      <Modal
        modalVisible={modalVisible}
        setModalVisible={() => setModalVisible()}
        component={
          <View style={styles.container}>
            <View style={styles.header}>
              <View>
                <Icon
                  testID="close"
                  name={IconNames.crossCircle}
                  customStyle={styles.crossCircle}
                  onPress={() => setModalVisible()}
                />
              </View>
            </View>
            <View style={{ justifyContent: "center", alignItems: "center", minHeight: 230 }}>
              <View style={styles.headerContainer}>
                <Text
                  fontWeight={FontWeight.Regular}
                  testId="addRoleText"
                  textSize={TextSize.Small}
                  textStyle={styles.headerText}
                >
                  {t("common:sureYouWantToCanel")}
                </Text>
              </View>

              <View
                style={[
                  styles.alignContainer,
                  { marginBottom: 10, flexDirection: "row", justifyContent: "center" }
                ]}
              >
                <Button
                  type={ButtonType.Primary}
                  buttonStyles={styles.submitbutton}
                  title={t("common:confirm")}
                  onPress={() => confirmCancelHandler()}
                  textStyles={styles.buttonText}
                />
              </View>
            </View>
          </View>
        }
      ></Modal>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    width: 655
    // minHeight: 270
  },
  header: {
    display: "flex",
    justifyContent: "flex-end",
    flexDirection: "row"
  },
  crossCircle: {
    fontSize: 15
  },

  alignContainer: {
    alignItems: "center",
    marginTop: 10,
    marginBottom: 10
  },
  submitbutton: {
    width: "167px",
    height: "50px",
    borderRadius: 10,
    marginLeft: 10,
    marginRight: 10
  },
  buttonText: {
    fontSize: 12
  },
  headerContainer: {
    marginBottom: 30,
    justifyContent: "center"
  },
  headerText: {
    fontSize: 12,
    fontFamily: FontFamily.Bold,
    lineHeight: 17,
    fontWeight: "700"
  }
});
