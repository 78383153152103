import { IFluxStandardAction } from "@socion-cordio/common/src/store/interfaces";
//import { ARTEFACTS } from "@socion-cordio/common/src/modules/artefacts/actions/actionTypes";
//import { Artefact } from "models/artefact";
//import { ArtefactTypes } from "@socion-cordio/common/src/models/artefactTypes";
import { Notifications } from "@socion-cordio/common/src/models/artefactNotifications";
import { compact } from "lodash";
import { NOTIFICATIONS } from "@socion-cordio/common/src/modules/notifications/actions/actionTypes";

const getNotifications = (): IFluxStandardAction<{}> => {
  return {
    type: NOTIFICATIONS.GET_NOTIFICATIONS.LOADING
  };
};

const getNotificationsSuccess = (response: Notifications): IFluxStandardAction<any> => {
  console.log("success response data : ", response.data);
  // if (response?.data) {
  //   response.data.forEach((notif: any, index: number) => {
  //     notif.serialNo = `${index + 1}.`;
  //   });
  // } else {
  //   response.data = compact(response.data);
  // }
  // console.log('success response data : ', response.data)
  console.log("success");
  return {
    type: NOTIFICATIONS.GET_NOTIFICATIONS.SUCCESS,
    payload: response.data
  };
};

const getNotificationsError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: NOTIFICATIONS.GET_NOTIFICATIONS.ERROR,
    payload: error
  };
};

export const NotificationsActions = {
  getNotifications,
  getNotificationsSuccess,
  getNotificationsError
};
