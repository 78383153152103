import { Roles } from "@socion-cordio/common/src/constants/roles";
import { allRoutesNames as routeNames } from "navigation/allRouteNames";
import AddArtefact from "../components/addArtefact";
import AddArtefactType from "../components/addArtefactType";
import ArtefactTypes from "../components/artefactTypes";
import EditArtefact from "../components/editArtefact";
import EditArtefactType from "../components/editArtefactType";
import FileViewer from "../components/fileViewer";
import ReviewArtefact from "../components/reviewArtefact";
import ViewArtefact from "../components/viewArtefact";

const ArtefactRoutes = () => [
  {
    path: `${routeNames.VIEW_ARTEFACT}`,
    component: ViewArtefact,
    isExact: false
    // permission: [Roles.Admin, Roles.AreaManager, Roles.StoreAccess],
  },
  {
    path: `${routeNames.ADD_ARTEFACT_TYPES}`,
    component: AddArtefactType,
    isExact: false
    // permission: [Roles.Admin, Roles.AreaManager, Roles.StoreAccess],
  },
  {
    path: `${routeNames.EDIT_ARTEFACT_TYPES}`,
    component: EditArtefactType,
    isExact: false
    // permission: [Roles.Admin, Roles.AreaManager, Roles.StoreAccess],
  },
  {
    path: `${routeNames.ARTEFACT_TYPES}`,
    component: ArtefactTypes,
    isExact: false
    // permission: [Roles.Admin, Roles.AreaManager, Roles.StoreAccess],
  },
  {
    path: `${routeNames.ADD_ARTEFACT}`,
    component: AddArtefact,
    isExact: false
    // permission: [Roles.Admin, Roles.AreaManager, Roles.StoreAccess],
  },
  {
    path: `${routeNames.EDIT_ARTEFACT}`,
    component: EditArtefact,
    isExact: false
    // permission: [Roles.Admin, Roles.AreaManager, Roles.StoreAccess],
  },

  {
    path: `${routeNames.REVIEW_ARTEFACT}`,
    component: ReviewArtefact,
    isExact: false
    // permission: [Roles.Admin, Roles.AreaManager, Roles.StoreAccess],
  },
  {
    path: `${routeNames.VIEW_ARTEFACT_PDF}`,
    component: FileViewer,
    isExact: false
  },
  {
    path: `${routeNames.VIEW_ARTEFACT_TYPE_PDF}`,
    component: FileViewer,
    isExact: false
  }
];

const ArtefactRouter = () => {
  return ArtefactRoutes();
};

export default ArtefactRouter;
