import React, { ReactElement, useEffect, useState } from "react";
import { StyleSheet, useWindowDimensions, View } from "react-native";
import { colorPallete } from "@socion-cordio/common/src/assets/styles/colors";
import Qualifications from "@socion-cordio/common/src/components/organisms/qualifications";
import Roles from "@socion-cordio/common/src/components/organisms/roles";
import { useDispatch } from "react-redux";
import { ProfileActions } from "@socion-cordio/common/src/modules/profile/actions/actions";
import PersonalInfo from "@socion-cordio/common/src/components/organisms/personalInfo";
import UserInfo from "@socion-cordio/common/src/components/organisms/userInfo";
import LocationInfo from "@socion-cordio/common/src/components/organisms/locationInfo";
import { FontFamily } from "@socion-cordio/common/src/components/atoms/text";
import { useSelector } from "react-redux";
import Loader from "@socion-cordio/common/src/components/atoms/loader";

export default function ProfilePage(): ReactElement {
  const { width, height } = useWindowDimensions();
  const [isLocationEnabled, setIsLocationEnabled] = useState<boolean>(false);
  const [showRightPanel, setShowRightPanel] = useState<boolean>(false);
  const [loader, setLoader] = useState<boolean>(false);

  const {
    userProfileData: profileState,
    loading: { userProfileGet: loading }
  } = useSelector((state: any) => state.profile);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(ProfileActions.getUserProfile());
  }, []);

  return (
    <>
      {loading || loader ? (
        <View style={styles.loaderContainer}>
          <View style={styles.subLoaderContainer}>
            <Loader />
          </View>
        </View>
      ) : (
        <View style={styles.mainContainer}>
          <View style={width > 1024 ? styles.container : [styles.container, styles.containerRow]}>
            <View style={styles.leftSection}>
              <UserInfo setLoader={setLoader} />
              <PersonalInfo />
              <LocationInfo
                isLocationEnabled={isLocationEnabled}
                setIsLocationEnabled={setIsLocationEnabled}
                setShowRightPanel={setShowRightPanel}
              />
            </View>
            {/* <View style={styles.borderContainer}></View>
            <View style={styles.rightSection}>
              {showRightPanel && (
                <>
                  <View style={styles.alignContainer}>
                    <Qualifications />
                  </View>
                  <View style={styles.alignContainer}>
                    <Roles />
                  </View>
                </>
              )}
            </View> */}
          </View>
        </View>
      )}
    </>
  );
}

const styles = StyleSheet.create({
  loaderContainer: {
    padding: 20,
    width: "100%",
    paddingTop: 5
  },
  subLoaderContainer: {
    backgroundColor: colorPallete.white,
    borderRadius: 20,
    padding: 20
  },
  mainContainer: {
    backgroundColor: "#F8F5F0",
    padding: 20,
    paddingTop: 5,
    minHeight: "calc(100vh - 50px)",
    width: "60%"
  },
  container: {
    flexDirection: "row",
    justifyContent: "space-between",
    backgroundColor: colorPallete.white,
    borderRadius: 20,
    height: "100%",
    //shadow
    shadowColor: colorPallete.cordioRedDark1,
    shadowOpacity: 0.1,
    shadowOffset: {
      height: 10,
      width: 5
    },
    shadowRadius: 10
  },
  containerRow: {
    flexDirection: "column"
  },
  leftSection: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
    // marginRight: "0.1rem",
    padding: 20
  },
  borderContainer: {
    borderRightColor: colorPallete.cordioTaupe,
    borderStyle: "solid",
    borderRightWidth: 1,
    opacity: 0.1
  },

  rightSection: {
    flex: 1,
    padding: 20,
    paddingHorizontal: 35
  },
  alignContainer: {
    marginBottom: 30
  },
  alignHeader: {
    marginBottom: 30
  },
  header: {
    display: "flex",
    flexDirection: "row"
  },
  headerText: {
    fontSize: 14,
    fontFamily: FontFamily.Medium,
    lineHeight: 19.5,
    fontWeight: "700"
  },
  iconStyle: {
    fontSize: 18,
    marginRight: 35
  }
});
