import React, { ReactElement, useEffect, useState, useMemo } from "react";
import { View, StyleSheet, TouchableOpacity } from "react-native";
import {
  Text,
  TextSize,
  FontWeight,
  FontFamily
} from "@socion-cordio/common/src/components/atoms/text";
import Table from "@socion-cordio/web/src/components/molecules/table/table";
import { colorPallete } from "@socion-cordio/common/src/assets/styles/colors";
import { sessionPackEndPoints } from "@socion-cordio/common/src/repositories/endPoints";
import { ApiClient } from "@socion-cordio/common/src/network/apiClient";
import Loader from "@socion-cordio/common/src/components/atoms/loader";
import Icon, { IconNames } from "@socion-cordio/common/src/components/atoms/icon";
import { allRoutesNames as routeNames } from "@socion-cordio/web/src/navigation/allRouteNames";
import { HTTP_STATUS_CODES } from "@socion-cordio/common/src/network/constants";
import { useTranslation } from "react-i18next";
import { auxiliaryMethods } from "@socion-cordio/common/src/utils/auxiliaryMethods";
import TableSecondary from "@socion-cordio/web/src/components/molecules/table/tableSecondary";
import SocionModal from "@socion-cordio/common/src/components/atoms/modal";
import ViewUserDetails from "@socion-cordio/common/src/components/organisms/common-modals/viewUserDetailsModal";
import { participationDetailsEndPoints } from "@socion-cordio/common/src/repositories/endPoints";

interface Props {
  sessionDetails?: any;
}

export default function ViewSessionTrainerPage(props: Props): ReactElement {
  const { t } = useTranslation();
  const { sessionDetails } = props;
  const [loadingState, setLoadingState] = useState(true);
  const [failed, setFailed] = useState(false);
  const [sessionTrainerList, setSessionTrainerList] = useState(null);
  const [sessionTrainerData, setSessionTrainerData] = useState(null);
  const [viewButtonDetails, setViewButtonDetails] = useState(false);
  const [selectedUserDetails, setSelectedUserDetails] = useState(null);

  useEffect(() => {
    if (sessionDetails !== null) {
      getSessionTrainerDetails();
    }
  }, [sessionDetails]);

  const getSessionTrainerDetails = async () => {
    try {
      const sessionId = sessionDetails?.sessionId;
      const newBody = {
        sessionIds: [sessionId],
        userType: "TRAINER",
        sessionStatus: "UPCOMING"
      };
      const response = await ApiClient.post(
        sessionPackEndPoints.getSessionParticipationList(),
        newBody
      );
      if (response?.responseCode === HTTP_STATUS_CODES.ok) {
        setSessionTrainerData(response?.response);
        const list = response?.response[0]?.sessionData[sessionId];
        list.forEach((session: any, index: number) => {
          session.serialNo = `${index + 1}.`;
        });
        setSessionTrainerList(list);
        setLoadingState(false);
      }
    } catch (error) {
      setLoadingState(false);
      setFailed(true);
    }
  };

  const participantDetailsCSV = async (data: any) => {
    const participantListCSVResponse = await ApiClient.post(
      participationDetailsEndPoints.getParticipantListCSV(),
      {
        sessionIds: [sessionDetails.sessionId],
        userType: "TRAINER",
        sessionStatus: data.sessionStatus,
        timezone: auxiliaryMethods.getLocalTimezone()
      }
    );
    return participantListCSVResponse;
  };

  const downloadCsvList = async (data: any) => {
    let result = await participantDetailsCSV({ sessionStatus: "UPCOMING" });
    auxiliaryMethods.handleDownload(
      result,
      `${sessionDetails.sessionName}-${sessionDetails.sessionId}-Trainers`
    );
    // auxiliaryMethods.updateTelemetryEvent("Download Participant List");
  };

  const displayUserDetails = (data: any) => {
    setViewButtonDetails(true);
    setSelectedUserDetails(data);
  };

  const cellData = (testId: string, style: any, data: string, onPress?: Function) => (
    <Text
      fontWeight={FontWeight.Bold}
      testId={testId}
      textSize={TextSize.Small}
      style={[
        style,
        {
          fontSize: 12,
          fontFamily: FontFamily.Regular,
          fontWeight: FontFamily.Light,
          textSize: TextSize.Small,
          color: colorPallete.textBlack
        }
      ]}
      onPress={onPress ? () => onPress() : null}
    >
      {data}
    </Text>
  );

  const columns = useMemo(
    () => [
      {
        Header: "Trainer Name",
        accessor: "name",
        width: 150,
        maxWidth: 150,
        Cell: ({ row: { original: session } }: { row: { original: any } }) => {
          return (
            <Text
              fontWeight={FontWeight.Bold}
              testId="participantName"
              textSize={TextSize.Large}
              style={{
                fontSize: 12,
                fontFamily: FontFamily.Medium,
                color: colorPallete.textBlack
              }}
            >
              <TouchableOpacity onPress={() => displayUserDetails(session)}>
                {session?.name}
              </TouchableOpacity>
            </Text>
          );
        }
      },
      {
        Header: "Base Location",
        accessor: "location",
        width: 140,
        maxWidth: 140,
        Cell: ({ row: { original: session } }: { row: { original: any } }) => {
          const style = {};
          const location: string =
            auxiliaryMethods.fromatBaseLocation(session) !== ""
              ? auxiliaryMethods.fromatBaseLocation(session)
              : "None";
          return cellData("location", style, location);
        }
      },
      {
        Header: "Establishment Name",
        accessor: "establishment",
        width: 175,
        maxWidth: 175,
        Cell: ({ row: { original: session } }: { row: { original: any } }) => {
          const style = {};
          const establishment = session?.establishmentName ? session?.establishmentName : "None";
          return cellData("establishment", style, establishment);
        }
      },
      {
        Header: "Verified Role",
        accessor: "Verified Role",
        width: 130,
        maxWidth: 130,
        Cell: ({ row: { original: session } }: { row: { original: any } }) => {
          const style = {};
          const roleDetails =
            session?.roleDetails.length !== 0
              ? auxiliaryMethods.getVerifiedRole(session?.roleDetails)
              : "None";
          return cellData("phone", style, roleDetails);
        }
      },
      {
        Header: "Verified Qualification",
        accessor: "Verified Qualification",
        width: 180,
        maxWidth: 180,
        Cell: ({ row: { original: session } }: { row: { original: any } }) => {
          const style = {};
          const quali =
            session?.qualificationTitle.length !== 0
              ? auxiliaryMethods.getVerifiedQualification(session?.qualificationTitle)
              : "None";
          return cellData("email", style, quali);
        }
      },
      {
        Header: "Assigned Badges",
        accessor: "badgeDetails",
        width: 150,
        maxWidth: 150,
        Cell: ({ row: { original: session } }: { row: { original: any } }) => {
          const style = {};
          const badgeDetails =
            session?.badgeDetails.length !== 0
              ? auxiliaryMethods.getAssignedBadges(session?.badgeDetails)
              : "None";
          return cellData("badgeDetails", style, badgeDetails);
        }
      },
      {
        Header: "Attestation Received",
        accessor: "Attestation Received",
        width: 175,
        maxWidth: 175,
        Cell: ({ row: { original: session } }: { row: { original: any } }) => {
          const style = {};
          return cellData("email", style, session?.attestations);
        }
      }
    ],
    []
  );

  return (
    <View>
      {loadingState ? (
        <Loader />
      ) : failed ? (
        <View>Failed to load data</View>
      ) : (
        <View>
          <TableSecondary
            columns={columns}
            data={sessionTrainerList}
            searchBarCustomStyle={{ width: 200 }}
            download={
              <TouchableOpacity onPress={() => downloadCsvList(sessionTrainerList)}>
                <View style={{ flexDirection: "row" }}>
                  <Icon
                    name={IconNames.downloadList}
                    customStyle={styles.iconStyle}
                    onPress={() => downloadCsvList(sessionTrainerList)}
                  />
                  <View>
                    <Text
                      testId="testId"
                      fontWeight={FontWeight.Bold}
                      textSize={TextSize.Small}
                      style={[
                        styles.textAlignment,
                        {
                          fontFamily: FontFamily.Regular,
                          fontSize: 12
                        }
                      ]}
                    >
                      {t("table:download")}
                    </Text>
                  </View>
                </View>
              </TouchableOpacity>
            }
          />
        </View>
      )}

      {viewButtonDetails && (
        <View>
          <SocionModal
            modalVisible={viewButtonDetails}
            setModalVisible={() => setViewButtonDetails(!viewButtonDetails)}
            component={
              <ViewUserDetails
                onClose={() => {
                  setViewButtonDetails(false);
                }}
                selectedUserDetails={selectedUserDetails}
                piiFilter={false}
              />
            }
          />
        </View>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  roleName: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center"
  },
  image: {
    width: 25,
    height: 25,
    borderRadius: 25 / 2,
    marginEnd: 10
  },
  status: {
    height: 8,
    width: 8,
    borderRadius: 50,
    marginRight: 8
  },
  active: {
    backgroundColor: colorPallete.cordioGreen
  },
  pending: {
    backgroundColor: colorPallete.cordioOrange
  },
  deactivated: {
    backgroundColor: colorPallete.cordioRedDark3
  },
  textAlign: {
    fontFamily: FontFamily.Regular,
    color: colorPallete.textBlack,
    fontSize: 10
  },
  statusContainer: {
    flexDirection: "row",
    alignItems: "center"
  },
  statusColor: {
    height: 8,
    width: 8,
    borderRadius: 50,
    marginRight: 8
  },
  approvedColor: {
    backgroundColor: colorPallete.cordioGreen
  },
  pendingColor: {
    backgroundColor: colorPallete.cordioOrange
  },
  declineColor: {
    backgroundColor: colorPallete.cordioRedDark3
  },
  iconStyle: {
    color: colorPallete.textBlack,
    fontSize: 17,
    marginRight: 10
  },
  textAlignment: {
    textDecorationLine: "underline"
  }
});
