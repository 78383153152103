import React, { ReactElement, useEffect, useState, useMemo } from "react";
import { View, StyleSheet, TouchableOpacity } from "react-native";
import {
  Text,
  TextSize,
  FontWeight,
  FontFamily
} from "@socion-cordio/common/src/components/atoms/text";
import Table from "@socion-cordio/web/src/components/molecules/table/table";
import { dataHelper } from "@socion-cordio/common/src/utils/dataHelper";
import { colorPallete } from "@socion-cordio/common/src/assets/styles/colors";
import { sessionPackEndPoints } from "@socion-cordio/common/src/repositories/endPoints";
import { ApiClient } from "@socion-cordio/common/src/network/apiClient";
import Loader from "@socion-cordio/common/src/components/atoms/loader";
import { Image } from "@socion-cordio/common/src/components/atoms/image";
import { allRoutesNames as routeNames } from "@socion-cordio/web/src/navigation/allRouteNames";
import { useHistory } from "react-router-dom";
import { LocalStorage } from "@socion-cordio/common/src/services/storage/storageService";
interface Props {
  programDetails: any;
}

export default function CompletedSessionPage(props: Props): ReactElement {
  const [loading, setLoading] = useState(true);
  const [failed, setFailed] = useState(false);
  const [completedSessionResponse, setCompletedSessionResponse] = useState(null);
  const [user, setUser] = useState(null);
  const [userRolesData, setUserRolesData] = useState(null);
  const history: any = useHistory();

  useEffect(() => {
    getSessionDetails();
  }, []);

  useEffect(() => {
    (async () => {
      let userData = await LocalStorage.getStorage("user");
      let tempUserRolesData: any = await LocalStorage.getStorage("userRoles");
      setUser(userData);
      setUserRolesData(tempUserRolesData?.userRolesData?.response);
    })();
  }, []);

  const getSessionDetails = async () => {
    try {
      const { program } = props?.programDetails;
      const newBody = {
        programId: program.id,
        sessionStatus: "COMPLETED"
      };
      const response = await ApiClient.post(sessionPackEndPoints.getSessionDetails(), newBody);
      response?.response.forEach((session: any, index: number) => {
        session.serialNo = `${index + 1}.`;
      });
      setCompletedSessionResponse(response?.response);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setFailed(true);
    }
  };

  const viewSessionDetailsHandler = (data: any) => {
    history.push(`${routeNames.app}${routeNames.SESSION}${routeNames.VIEWSESSION}/completed`, {
      sessionData: data
    });
  };

  const cellData = (testId: string, style: any, data: string, onPress?: Function) => (
    <Text
      fontWeight={FontWeight.Bold}
      testId={testId}
      textSize={TextSize.Small}
      style={[
        {
          fontSize: 12,
          fontFamily: FontFamily.Regular,
          fontWeight: FontFamily.Light,
          textSize: TextSize.Small,
          color: colorPallete.textBlack
        },
        style
      ]}
      onPress={onPress ? () => onPress() : null}
    >
      {data}
    </Text>
  );

  const handleScanIn = (data: any) => {
    history.push(
      `${routeNames.app}${routeNames.SESSION}${routeNames.VIEWSESSION}/completed/live/scanIn`,
      {
        sessionData: data,
        completed: true,
        redirectionFrom: "Completed Session",
        sessionScanMode: data.session.sessionScanMode,
        scanType: "Scan In"
      }
    );
  };

  const handleScanOut = (data: any) => {
    history.push(
      `${routeNames.app}${routeNames.SESSION}${routeNames.VIEWSESSION}/completed/live/scanOut`,
      {
        sessionData: data,
        completed: true,
        redirectionFrom: "Completed Session",
        sessionScanMode: data.session.sessionScanMode,
        scanType: "Scan Out"
      }
    );
  };

  const columns = [
    {
      Header: "Sr.No",
      accessor: "serialNo",
      width: 30,
      maxWidth: 30,
      disableSortBy: true,
      Cell: ({ row: { original: session } }: { row: { original: any } }) => {
        const style = {};
        return cellData("serialNo", style, session?.serialNo);
      }
    },

    {
      Header: "Session Name",
      accessor: "name",
      width: 200,
      maxWidth: 200,
      Cell: ({ row: { original: session } }: { row: { original: any } }) => {
        const style = {
          fontFamily: FontFamily.Medium,
          fontWeight: FontFamily.Bold,
          textDecorationLine:
            session.memberRole ||
            user?.userId == session?.sessionCreator?.userId ||
            userRolesData.includes("ENTITY_ADMIN") ||
            userRolesData.includes("PROGRAM_ADMIN")
              ? "underline"
              : "none"
        };
        const onPress = () => {
          session.memberRole ||
          user?.userId == session?.sessionCreator?.userId ||
          userRolesData.includes("ENTITY_ADMIN") ||
          userRolesData.includes("PROGRAM_ADMIN")
            ? viewSessionDetailsHandler(session)
            : null;
        };
        return cellData("name", style, session?.session?.sessionName, onPress);
      }
    },
    {
      Header: "Start",
      accessor: "start",
      width: 50,
      maxWidth: 50,
      Cell: ({ row: { original: session } }: { row: { original: any } }) => {
        const style = {};
        return cellData("start", style, dataHelper.formatDate(session?.session?.sessionStartDate));
      }
    },
    {
      Header: "End",
      accessor: "end",
      width: 50,
      maxWidth: 50,
      Cell: ({ row: { original: session } }: { row: { original: any } }) => {
        const style = {};
        return cellData("end", style, dataHelper.formatDate(session?.session?.sessionEndDate));
      }
    },
    {
      Header: "Scan In",
      accessor: "scanIns",
      width: 56,
      maxWidth: 56,
      Cell: ({ row: { original: session } }: { row: { original: any } }) => {
        const style = {
          fontFamily: FontFamily.Medium,
          fontWeight: FontFamily.Bold,
          textDecorationLine:
            session.memberRole ||
            user?.userId == session?.sessionCreator?.userId ||
            userRolesData.includes("ENTITY_ADMIN") ||
            userRolesData.includes("PROGRAM_ADMIN")
              ? "underline"
              : "none"
        };
        const onPress = () => {
          session.memberRole ||
          user?.userId == session?.sessionCreator?.userId ||
          userRolesData.includes("ENTITY_ADMIN") ||
          userRolesData.includes("PROGRAM_ADMIN")
            ? handleScanIn(session)
            : null;
        };
        const scanIns = session?.scanIns ? session?.scanIns : "None";
        return cellData("sessionCreator", style, scanIns, onPress);
      }
    },
    {
      Header: "Scan Out",
      accessor: "scanOuts",
      width: 56,
      maxWidth: 56,
      Cell: ({ row: { original: session } }: { row: { original: any } }) => {
        const style = {
          fontFamily: FontFamily.Medium,
          fontWeight: FontFamily.Bold,
          textDecorationLine:
            session.memberRole ||
            user?.userId == session?.sessionCreator?.userId ||
            userRolesData.includes("ENTITY_ADMIN") ||
            userRolesData.includes("PROGRAM_ADMIN")
              ? "underline"
              : "none"
        };
        const onPress = () => {
          session.memberRole ||
          user?.userId == session?.sessionCreator?.userId ||
          userRolesData.includes("ENTITY_ADMIN") ||
          userRolesData.includes("PROGRAM_ADMIN")
            ? handleScanOut(session)
            : null;
        };
        const scanOuts = session?.scanOuts ? session?.scanOuts : "None";
        return cellData("sessionCreator", style, scanOuts, onPress);
      }
    },
    {
      Header: "Venue",
      accessor: "venue",
      width: 56,
      maxWidth: 56,
      Cell: ({ row: { original: session } }: { row: { original: any } }) => {
        const style = {};
        return cellData("venue", style, session?.session?.address);
      }
    }
  ];

  return (
    <View>
      {loading ? (
        <Loader />
      ) : failed ? (
        <View>Failed to load data</View>
      ) : (
        <View>
          <Table columns={columns} data={completedSessionResponse} />
        </View>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  sessionName: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center"
  },
  image: {
    width: 25,
    height: 25,
    borderRadius: 25 / 2,
    marginEnd: 10
  },
  status: {
    height: 8,
    width: 8,
    borderRadius: 50,
    marginRight: 8
  },
  active: {
    backgroundColor: colorPallete.cordioGreen
  },
  pending: {
    backgroundColor: colorPallete.cordioOrange
  },
  deactivated: {
    backgroundColor: colorPallete.cordioRedDark3
  },
  textAlign: {
    fontFamily: FontFamily.Regular,
    color: colorPallete.textBlack,
    fontSize: 10
  },
  statusContainer: {
    flexDirection: "row",
    alignItems: "center"
  },
  statusColor: {
    height: 8,
    width: 8,
    borderRadius: 50,
    marginRight: 8
  },
  approvedColor: {
    backgroundColor: colorPallete.cordioGreen
  },
  pendingColor: {
    backgroundColor: colorPallete.cordioOrange
  },
  declineColor: {
    backgroundColor: colorPallete.cordioRedDark3
  }
});
