import { Topic } from "@socion-cordio/common/src/models/topic";
import { TOPICS } from "@socion-cordio/common/src/modules/topic/actions/actionTypes";

export interface ITopicLoading {
  getTopics: boolean;
}
export interface ITopicError {
  getTopics: string;
}
export interface ITopicState {
  topicsData: Topic;
  error: ITopicError;
  loading: ITopicLoading;
}

export const topicsInitialState: ITopicState = {
  topicsData: null,
  error: {
    getTopics: ""
  },
  loading: { getTopics: false }
};

const topicsReducer = (
  state = topicsInitialState,
  action: { payload: any; type: string }
): ITopicState => {
  switch (action.type) {
    case TOPICS.GET_TOPICS.LOADING:
      return {
        ...state,
        loading: { ...state.loading, getTopics: true },
        error: { ...state.error, getTopics: null }
      };
    case TOPICS.GET_TOPICS.SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, getTopics: false },
        error: { ...state.error, getTopics: null },
        topicsData: action.payload
      };
    case TOPICS.GET_TOPICS.ERROR:
      return {
        ...state,
        loading: { ...state.loading, getTopics: false },
        error: { ...state.error, getTopics: action.payload }
      };
    default:
      return state;
  }
};
export default topicsReducer;
