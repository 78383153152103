import { toast, ToastContainer } from "react-toastify";
import Icon, { IconNames } from "@socion-cordio/common/src/components/atoms/icon";
import {
  Text,
  TextSize,
  FontWeight,
  FontFamily
} from "@socion-cordio/common/src/components/atoms/text";
import { colorPallete } from "@socion-cordio/common/src/assets/styles/colors";
import { springshedEndPoints } from "@socion-cordio/common/src/repositories/endPoints";
import { HTTP_STATUS_CODES } from "@socion-cordio/common/src/network/constants";
import React, { ReactElement, useEffect, useState } from "react";
import { View, StyleSheet, StyleProp, ImageStyle, ViewStyle, TouchableOpacity } from "react-native";
import { programEndPoints } from "@socion-cordio/common/src/repositories/endPoints";
import { ApiClient } from "@socion-cordio/common/src/network/apiClient";
import { Image } from "@socion-cordio/common/src/components/atoms/image";
import RemoveLinkModal from "@socion-cordio/common/src/components/organisms/common-modals/removeLinkModal";

interface IProps {
  noFormik?: boolean;
  documentsDetails?: any;
  userRoles: any;
  isSuperAdmin: any;
  entityId?: string;
  programId?: string;
  programStatus?: Function;
  setLoading?: Function;
}

export default function DocumentsDetails(props: IProps): ReactElement {
  const { programStatus, setLoading } = props;
  const [programDocuments, setProgramDocumnets] = useState(null);
  const [isDocDeleted, setIsDocDeleted] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const hiddenFileInput = React.useRef(null);
  const userDetails: any = JSON.parse(localStorage.getItem("user"));
  const DELETE_DOCUMENT_MESSAGE = `Are you sure you want to Delete this Document?`;
  const deleteButtonText = `Delete Document`;

  useEffect(() => {
    getDocumentsById();
  }, []);

  const getDocumentsById = async () => {
    try {
      const { id } = props.documentsDetails;
      const documentResponse = await ApiClient.get(programEndPoints.getProgramDocumentsById(id));
      setProgramDocumnets(documentResponse);
    } catch (error) {
      console.log("error");
    }
  };

  const handleClick = (event: any) => {
    hiddenFileInput.current.click();
  };

  const postDocument = async (data: any) => {
    try {
      let response = await ApiClient.post(
        springshedEndPoints.postProgramDocument(props.programId),
        data
      );
      if (response) {
        getDocumentsById();
        toast.success("Document uploaded successfully");
        setLoading(false);
      }
    } catch (error) {
      toast.error("Something went wrong.");
      setLoading(false);
    }
  };

  const handleCustomChange = async (e: any) => {
    let docsArray: any = [];
    programDocuments.map((i: any) => {
      docsArray.push(i.name);
    });
    if (docsArray.includes(e.target.files[0].name)) {
      toast.error("Document already added");
      return;
    }
    const validTypes = ["mp4", "mpeg", "mov", "flv", "png", "jpg", "jpeg", "pdf"];
    const validTypesImage = ["png", "jpg", "jpeg"];
    const validTypesDocs = ["pdf"];
    const validTypesVideo = ["mp4", "mpeg", "flv", "mov"];
    const splitFileName = e?.currentTarget?.files[0]?.name.split(".");
    const extension = splitFileName[splitFileName.length - 1];
    if (e.target.validity.valid && validTypes.includes(extension)) {
      setLoading(true);
      let formData: FormData = new FormData();
      formData.append("appType", "AWS");
      formData.append("file", e?.currentTarget?.files[0]);
      formData.append("fileName", e?.currentTarget?.files[0]?.name);
      if (validTypesImage.includes(extension)) {
        formData.append("fileType", "Program-Docs/Images");
      }
      if (validTypesDocs.includes(extension)) {
        formData.append("fileType", "Program-Docs/Documents");
      }
      if (validTypesVideo.includes(extension)) {
        formData.append("fileType", "Program-Docs/Program Video");
      }
      const uploadData = {
        name: e?.currentTarget?.files[0].name,
        vimeoUrl: "",
        createdBy: userDetails.name,
        contentType: extension,
        entityId: props.entityId
      };
      let response = await ApiClient.put(springshedEndPoints.fileUpload(), formData);
      if (response.responseCode === HTTP_STATUS_CODES.ok) {
        const fileUploadData = {
          ...uploadData,
          contentUrl: response?.response?.url,
          size: response?.response?.size
        };
        postDocument(fileUploadData);
      } else {
        toast.error(response.message);
        setLoading(false);
      }
    } else {
      toast.error(
        "Invalid File Type! Only Files with format of mp4, mpeg, mov, flv, png, jpg, jpeg, pdf will be uploaded!!"
      );
    }
  };

  const handleDeleteDoc = async () => {
    try {
      let response = await ApiClient.delete(
        springshedEndPoints.deleteProgramDocument(props.programId, deleteId)
      );
      if (response) {
        getDocumentsById();
        toast.success("Document deleted successfully");
        setIsDocDeleted(false);
      }
    } catch (error) {
      toast.error("Something went wrong.");
      setIsDocDeleted(false);
    }
  };

  const handleDownload = async (type: string, name: string) => {
    const validTypesImage = ["png", "jpg", "jpeg"];
    const validTypesDocs = ["pdf"];
    const validTypesVideo = ["mp4", "mpeg", "flv", "mov"];
    let content = null;
    if (type.toLowerCase() == "image" || validTypesImage.includes(type.toLowerCase())) {
      content = "Program-Docs/Images";
    }
    if (type.toLowerCase() == "document" || validTypesDocs.includes(type.toLowerCase())) {
      content = "Program-Docs/Documents";
    }
    if (type.toLowerCase() == "video" || validTypesVideo.includes(type.toLowerCase())) {
      content = "Program-Docs/Program Video";
    }
    const response = await ApiClient.get(programEndPoints.getPresignedUrl(content, name));
    if (response) {
      var link = document.createElement("a");
      link.href = response;
      link.download = "Download.jpg";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      content = null;
    }
  };

  const getFileName = (url: string) => {
    let name = url.split("/");
    return name[name.length - 1];
  };

  const handleOnClick = (event: any) => {
    const { target = {} } = event || {};
    target.value = "";
  };

  return (
    <View style={styles.container}>
      <View style={styles.headerContainer}>
        <Text
          fontWeight={FontWeight.Light}
          testId="headerText"
          textSize={TextSize.Small}
          textStyle={styles.headerText}
        >
          Documents
        </Text>

        {/* <Icon
          onPress={() => console.log("Edit")}
          testID="edit"
          name={IconNames.edit}
          customStyle={[styles.alignButton]}
        /> */}
        <View>
          {(props?.isSuperAdmin ||
            props?.userRoles?.includes("PROGRAM_ADMIN") ||
            props?.userRoles?.includes("ENTITY_ADMIN")) &&
            !programStatus && (
              <>
                <TouchableOpacity onPress={handleClick}>
                  <Image
                    testId="primaryAddImg"
                    source={require("@socion-cordio/common/src/assets/images/primary_add.svg")}
                    imageStyle={{
                      width: 28,
                      height: 28
                    }}
                  />
                </TouchableOpacity>
                <input
                  style={{
                    display: "none",
                    zIndex: -1
                  }}
                  ref={hiddenFileInput}
                  type="file"
                  onChange={async (e) => {
                    await handleCustomChange(e);
                  }}
                  onClick={handleOnClick}
                />
              </>
            )}
        </View>
      </View>
      <View style={styles.listContainer}>
        {programDocuments?.length !== 0 ? (
          <View>
            {" "}
            {programDocuments?.map((prop: any, key: number) => {
              return (
                <View key={key} style={styles.subContainer}>
                  <View style={styles.miniContainer}>
                    <a
                      href={prop.vimeoUrl == "" ? prop.content_url : prop.vimeoUrl}
                      target="_blank"
                    >
                      <Text
                        fontWeight={FontWeight.Bold}
                        testId="headerText"
                        textSize={TextSize.Small}
                        textStyle={[styles.headerText, styles.miniContainerHeaderText]}
                      >
                        {prop.name}
                      </Text>
                    </a>
                    <View style={styles.miniIconContainer}>
                      {(props?.isSuperAdmin ||
                        props?.userRoles?.includes("PROGRAM_ADMIN") ||
                        props?.userRoles?.includes("ENTITY_ADMIN")) &&
                        !programStatus && (
                          <TouchableOpacity>
                            <Icon
                              testID="delete"
                              name={IconNames.deleteFile}
                              customStyle={[styles.alignContainerButton]}
                              onPress={() => [setIsDocDeleted(true), setDeleteId(prop.id)]}
                            />
                          </TouchableOpacity>
                        )}
                      <TouchableOpacity
                        onPress={() =>
                          handleDownload(prop.content_type, getFileName(prop?.content_url))
                        }
                      >
                        <Icon
                          testID="download"
                          name={IconNames.download}
                          customStyle={[styles.alignContainerButton]}
                        />
                      </TouchableOpacity>
                    </View>
                  </View>
                </View>
              );
            })}
          </View>
        ) : (
          <View>
            <Text
              fontWeight={FontWeight.Bold}
              testId="headerText"
              textSize={TextSize.Small}
              textStyle={[styles.headerText, styles.miniContainerHeaderText]}
            >
              No Documents Available
            </Text>
          </View>
        )}
        {isDocDeleted && (
          <RemoveLinkModal
            modalVisible={isDocDeleted}
            selectedData={handleDeleteDoc}
            textValue={DELETE_DOCUMENT_MESSAGE}
            deleteButtonText={deleteButtonText}
            setModalVisible={() => setIsDocDeleted(!isDocDeleted)}
          />
        )}
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    borderColor: "rgba(103, 88, 80,0.1)",
    borderStyle: "solid",
    borderWidth: 1,
    borderRadius: 10,
    padding: 15
  },
  headerContainer: {
    flexDirection: "row",
    justifyContent: "space-between"
  },
  headerText: {
    color: colorPallete.textLight,
    fontSize: 12,
    fontFamily: FontFamily.Regular,
    lineHeight: 17
  },
  alignButton: {
    fontSize: 20,
    color: colorPallete.cordioTaupe
  },
  listContainer: {
    marginTop: 20
  },
  subContainer: {
    // marginTop: 20,
    // marginBottom: 30
  },
  miniContainer: {
    padding: 20,
    // marginTop: 10,
    marginBottom: 10,
    width: "100%",
    borderColor: colorPallete.lightGreen,
    borderStyle: "solid",
    borderWidth: 1,
    borderRadius: 10,
    flexDirection: "row",
    justifyContent: "space-between"
  },
  miniContainerHeaderText: {
    color: colorPallete.textBlack,
    fontSize: 14,
    fontFamily: FontFamily.Medium,
    lineHeight: 20
  },
  miniIconContainer: {
    flexDirection: "row"
  },
  alignContainerButton: {
    fontSize: 18,
    color: colorPallete.cordioTaupe,
    marginRight: 5,
    marginLeft: 25
  }
});
