import React, { ReactElement, useEffect, useState, useMemo } from "react";
import { View, StyleSheet, TouchableOpacity } from "react-native";
import {
  Text,
  TextSize,
  FontWeight,
  FontFamily
} from "@socion-cordio/common/src/components/atoms/text";
import Table from "@socion-cordio/web/src/components/molecules/table/table";
import { colorPallete } from "@socion-cordio/common/src/assets/styles/colors";
import { sessionPackEndPoints } from "@socion-cordio/common/src/repositories/endPoints";
import { ApiClient } from "@socion-cordio/common/src/network/apiClient";
import Loader from "@socion-cordio/common/src/components/atoms/loader";
import Icon, { IconNames } from "@socion-cordio/common/src/components/atoms/icon";
import { HTTP_STATUS_CODES } from "@socion-cordio/common/src/network/constants";
import { dataHelper } from "@socion-cordio/common/src/utils/dataHelper";
import { useLocation, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import TableSecondary from "@socion-cordio/web/src/components/molecules/table/tableSecondary";
import { auxiliaryMethods } from "@socion-cordio/common/src/utils/auxiliaryMethods";
import SocionModal from "@socion-cordio/common/src/components/atoms/modal";
import ViewUserDetails from "@socion-cordio/common/src/components/organisms/common-modals/viewUserDetailsModal";
import { participationDetailsEndPoints } from "@socion-cordio/common/src/repositories/endPoints";

interface Props {
  sessionDetails?: any;
}
const SINGLE_SCAN = "SINGLE_SCAN";
const MULTI_SCAN = "MULTI_SCAN";
const SCAN_IN = "Scan In";
const SCAN_OUT = "Scan Out";
const LIVE_SESSION = "Live Session";

export default function ViewSessionParticipantPage(props: Props): ReactElement {
  const { t } = useTranslation();
  const { sessionDetails } = props;
  const [loadingState, setLoadingState] = useState(true);
  const [failed, setFailed] = useState(false);
  const [sessionParticipantList, setSessionParticipantList] = useState(null);
  const [sessionParticipantData, setSessionParticipantData] = useState(null);
  const [updatedColumns, setUpdatedColumns] = useState([]);
  const [displayRefreshButton, setDisplayRefreshButton] = useState(true);
  const [viewButtonDetails, setViewButtonDetails] = useState(false);
  const [selectedUserDetails, setSelectedUserDetails] = useState(null);

  const location: any = useLocation();
  useEffect(() => {
    if (sessionDetails !== null) {
      getSessionParticipantDetails(false);
      validateRefreshButtonHandler();
    }
  }, [sessionDetails, location?.pathname]);

  const getSessionParticipantDetails = async (isRefreshClicked?: boolean) => {
    try {
      const sessionId = sessionDetails?.sessionId;
      const newBody = {
        sessionIds: [sessionId],
        userType: "TRAINEE",
        sessionStatus: "UPCOMING"
      };
      const response = await ApiClient.post(
        sessionPackEndPoints.getSessionParticipationList(),
        newBody
      );
      if (response?.responseCode === HTTP_STATUS_CODES.ok) {
        setSessionParticipantData(response?.response);
        const list = response?.response[0]?.sessionData[sessionId];
        list.forEach((session: any, index: number) => {
          session.serialNo = `${index + 1}.`;
        });
        checkScanOutPresent(list);
        setLoadingState(false);
        isRefreshClicked
          ? setUpdatedColumns(columns)
          : checkListModes(response.response[0], sessionId);
      }
    } catch (error) {
      setLoadingState(false);
      setFailed(true);
    }
  };

  // To only display button from live session view.
  // Disabling button when redirection from live session.
  const validateRefreshButtonHandler = () => {
    const checkPath = location?.state?.redirectionFrom;
    checkPath === "Completed Session"
      ? setDisplayRefreshButton(false)
      : setDisplayRefreshButton(true);
  };

  // Checks session data on the basis of scan mode,
  // If single scan mode, removing scan out time and
  // replacing scan in time header with Scan Time
  const checkListModes = (response: any, sessionId: number) => {
    const scanMode = response.sessionScanModes[sessionId];
    if (scanMode === "SINGLE_SCAN") {
      const newCols = columns.splice(7, 1);
      const scanInCol = columns.filter((item: any) => item.Header === "Scan In Time");
      scanInCol[0].Header = "Scan Time";
      setUpdatedColumns(columns);
    } else {
      setUpdatedColumns(columns);
    }
  };

  const checkScanOutPresent = (data: any) => {
    if (location?.pathname.includes("live")) {
      // const list = data?.filter((x: any) => x.scanOutTime !== null);
      setSessionParticipantList(data);
    }
    if (location?.pathname.includes("sessions")) {
      const list = location?.state?.completedSessionData;
      const listA = list?.filter((x: any) => x.scanOutTime !== null);
      setSessionParticipantList(listA);
    }
    if (location?.pathname.includes("live/scanOut")) {
      const filteredData = data?.filter((x: any) => x.scanOutTime !== null);
      setSessionParticipantList(filteredData);
    }
  };

  const participantDetailsCSV = async (data: any) => {
    const participantListCSVResponse = await ApiClient.post(
      participationDetailsEndPoints.getParticipantListCSV(),
      {
        sessionIds: [sessionDetails.sessionId],
        userType: "TRAINEE",
        sessionStatus: data.sessionStatus,
        timezone: auxiliaryMethods.getLocalTimezone()
      }
    );
    return participantListCSVResponse;
  };

  const downloadCsvList = async (data: any) => {
    location.state.redirectionFrom === LIVE_SESSION
      ? downloadCSVLiveSession()
      : downloadCSVCompletedSession();
  };

  const downloadCSVLiveSession = async () => {
    let result;
    location.state.sessionScanMode === SINGLE_SCAN
      ? (result = await participantDetailsCSV({ sessionStatus: "UPCOMING" }))
      : location.state.scanType === SCAN_IN
      ? (result = await participantDetailsCSV({ sessionStatus: "UPCOMING" }))
      : (result = await participantDetailsCSV({ sessionStatus: "COMPLETED" }));
    auxiliaryMethods.handleDownload(
      result,
      `${sessionDetails.sessionName}-${sessionDetails.sessionId}-Participants`
    );
    const session: any = {
      topicId: sessionDetails.topicInfo.topic.id,
      topicName: sessionDetails.topicInfo.topic.name,
      sessionId: sessionDetails.sessionId,
      sessionName: sessionDetails.sessionName,
      sessionStartDate: sessionDetails.sessionStartDate,
      sessionEndDate: sessionDetails.sessionEndDate
    };
    auxiliaryMethods.updateTelemetryEvent("Download Participant List"), session;
  };

  const downloadCSVCompletedSession = async () => {
    console.log("sessionDetails::::", sessionDetails);

    let result;
    location.state.sessionScanMode === SINGLE_SCAN
      ? (result = await participantDetailsCSV({ sessionStatus: "COMPLETED" }))
      : location.state.scanType === SCAN_IN
      ? (result = await participantDetailsCSV({ sessionStatus: "UPCOMING" }))
      : (result = await participantDetailsCSV({ sessionStatus: "COMPLETED" }));
    auxiliaryMethods.handleDownload(
      result,
      `${sessionDetails.sessionName}-${sessionDetails.sessionId}-Participants`
    );
    const session: any = {
      topicId: sessionDetails.topicInfo.topic.id,
      topicName: sessionDetails.topicInfo.topic.name,
      sessionId: sessionDetails.sessionId,
      sessionName: sessionDetails.sessionName,
      sessionStartDate: sessionDetails.sessionStartDate,
      sessionEndDate: sessionDetails.sessionEndDate
    };
    auxiliaryMethods.updateSessionParticipantTelemetryEvent("Download Participant List", session);
  };

  const refreshListHandler = (isRefreshClick?: boolean) => {
    getSessionParticipantDetails(isRefreshClick);
    setLoadingState(true);
  };

  const displayUserDetails = (data: any) => {
    setViewButtonDetails(true);
    setSelectedUserDetails(data);
  };

  const cellData = (testId: string, style: any, data: string, onPress?: Function) => (
    <Text
      fontWeight={FontWeight.Bold}
      testId={testId}
      textSize={TextSize.Small}
      style={[
        style,
        {
          fontSize: 12,
          fontFamily: FontFamily.Regular,
          fontWeight: FontFamily.Light,
          textSize: TextSize.Small,
          color: colorPallete.textBlack
        }
      ]}
      onPress={onPress ? () => onPress() : null}
    >
      {data}
    </Text>
  );

  const columns = useMemo(
    () => [
      {
        Header: "Participant Name",
        accessor: "name",
        width: 155,
        maxWidth: 155,
        Cell: ({ row: { original: session } }: { row: { original: any } }) => {
          return (
            <Text
              fontWeight={FontWeight.Bold}
              testId="participantName"
              textSize={TextSize.Large}
              style={{
                fontSize: 12,
                fontFamily: FontFamily.Medium,
                color: colorPallete.textBlack
              }}
            >
              <TouchableOpacity onPress={() => displayUserDetails(session)}>
                {session?.name}
              </TouchableOpacity>
            </Text>
          );
        }
      },
      {
        Header: "Base Location",
        accessor: "location",
        width: 140,
        maxWidth: 140,
        Cell: ({ row: { original: session } }: { row: { original: any } }) => {
          const style = {};
          const location: string =
            auxiliaryMethods.fromatBaseLocation(session) !== ""
              ? auxiliaryMethods.fromatBaseLocation(session)
              : "None";
          return cellData("location", style, location);
        }
      },
      {
        Header: "Establishment Name",
        accessor: "establishment",
        width: 180,
        maxWidth: 180,
        Cell: ({ row: { original: session } }: { row: { original: any } }) => {
          const style = {};
          const establishment = session?.establishmentName ? session?.establishmentName : "None";
          return cellData("establishment", style, establishment);
        }
      },
      {
        Header: "Verified Role",
        accessor: "Verified Role",
        width: 130,
        maxWidth: 130,
        Cell: ({ row: { original: session } }: { row: { original: any } }) => {
          const style = {};
          const roleDetails =
            session?.roleDetails.length !== 0
              ? auxiliaryMethods.getVerifiedRole(session?.roleDetails)
              : "None";
          return cellData("phone", style, roleDetails);
        }
      },
      {
        Header: "Verified Qualification",
        accessor: "Verified Qualification",
        width: 180,
        maxWidth: 180,
        Cell: ({ row: { original: session } }: { row: { original: any } }) => {
          const style = {};
          const quali =
            session?.qualificationTitle.length !== 0
              ? auxiliaryMethods.getVerifiedQualification(session?.qualificationTitle)
              : "None";
          return cellData("email", style, quali);
        }
      },
      {
        Header: "Assigned Badges",
        accessor: "badgeDetails",
        width: 160,
        maxWidth: 160,
        Cell: ({ row: { original: session } }: { row: { original: any } }) => {
          const style = {};
          const badgeDetails =
            session?.badgeDetails.length !== 0
              ? auxiliaryMethods.getAssignedBadges(session?.badgeDetails)
              : "None";
          return cellData("badgeDetails", style, badgeDetails);
        }
      },
      {
        Header: "Scan In Time",
        accessor: "scanInTime",
        width: 140,
        maxWidth: 140,
        Cell: ({ row: { original: session } }: { row: { original: any } }) => {
          const style = {};
          const scanIn = dataHelper.formatDateTimeUtc(session?.scanInTime);
          if (scanIn === "Invalid date") {
            const scanIn = "None";
            return cellData("scanInTime", style, scanIn);
          } else {
            return cellData("scanInTime", style, scanIn);
          }
        }
      },
      {
        Header: "Scan Out Time",
        accessor: "scanOutTime",
        width: 140,
        maxWidth: 140,
        Cell: ({ row: { original: session } }: { row: { original: any } }) => {
          const style = {};
          const scanOut = dataHelper.formatDateTimeUtc(session?.scanOutTime);
          if (scanOut === "Invalid date") {
            const scanOut = "None";
            return cellData("scanOutTime", style, scanOut);
          } else {
            return cellData("scanOutTime", style, scanOut);
          }
        }
      },
      {
        Header: "Assisted Scan",
        accessor: "Assisted Scan",
        width: 130,
        maxWidth: 130,
        Cell: ({ row: { original: session } }: { row: { original: any } }) => {
          const style = {};
          const assistedScan = session?.assistedScan?.assistedScan ? "Yes" : "No";
          return cellData("email", style, assistedScan);
        }
      },
      {
        Header: "Attestation Received",
        accessor: "Attestation Received",
        width: 175,
        maxWidth: 175,
        Cell: ({ row: { original: session } }: { row: { original: any } }) => {
          const style = {};
          return cellData("email", style, session?.attestations);
        }
      }
    ],
    []
  );

  return (
    <View>
      {loadingState ? (
        <Loader />
      ) : failed ? (
        <View>Failed to load data</View>
      ) : (
        <View>
          {sessionParticipantList == null || sessionParticipantList.length === 0 ? (
            <View style={styles.refreshContainer}>
              <View style={[styles.flexDirectionRow, styles.refreshSubContainer]}>
                {location?.state?.completed ? null : (
                  <TouchableOpacity
                    style={styles.flexDirectionRow}
                    onPress={() => refreshListHandler(false)}
                  >
                    <Icon
                      name={IconNames.refresh}
                      customStyle={styles.iconStyle}
                      onPress={() => refreshListHandler(false)}
                    />
                    <View>
                      <Text
                        testId="testId"
                        fontWeight={FontWeight.Bold}
                        textSize={TextSize.Small}
                        style={[
                          styles.textAlignment,
                          {
                            fontFamily: FontFamily.Regular,
                            fontSize: 12
                          }
                        ]}
                      >
                        {t("table:refreshList")}
                      </Text>
                    </View>
                  </TouchableOpacity>
                )}
              </View>
              <Text
                testId="testId"
                fontWeight={FontWeight.Bold}
                textSize={TextSize.Small}
                style={[styles.alignNoDataFound]}
              >
                {"No Data Found"}
              </Text>
            </View>
          ) : (
            <TableSecondary
              columns={updatedColumns}
              data={sessionParticipantList}
              searchBarCustomStyle={{ width: 200 }}
              refreshList={
                location?.state?.completed == true ? null : (
                  <TouchableOpacity onPress={() => {}}>
                    <Icon
                      name={IconNames.refresh}
                      customStyle={styles.iconStyle}
                      onPress={() => refreshListHandler(true)}
                    />
                  </TouchableOpacity>
                )
              }
              refreshListHandler={() => refreshListHandler(true)}
              displayRefreshButton={displayRefreshButton}
              download={
                <TouchableOpacity onPress={() => downloadCsvList(sessionParticipantList)}>
                  <View style={{ flexDirection: "row" }}>
                    <Icon
                      name={IconNames.downloadList}
                      customStyle={styles.iconStyle}
                      onPress={() => downloadCsvList(sessionParticipantList)}
                    />
                    <View>
                      <Text
                        testId="testId"
                        fontWeight={FontWeight.Bold}
                        textSize={TextSize.Small}
                        style={[
                          styles.textAlignment,
                          {
                            fontFamily: FontFamily.Regular,
                            fontSize: 12
                          }
                        ]}
                      >
                        {t("table:download")}
                      </Text>
                    </View>
                  </View>
                </TouchableOpacity>
              }
            />
          )}
        </View>
      )}

      {viewButtonDetails && (
        <View>
          <SocionModal
            modalVisible={viewButtonDetails}
            setModalVisible={() => setViewButtonDetails(!viewButtonDetails)}
            component={
              <ViewUserDetails
                onClose={() => {
                  setViewButtonDetails(false);
                }}
                selectedUserDetails={selectedUserDetails}
                piiFilter={true}
              />
            }
          />
        </View>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  roleName: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center"
  },
  image: {
    width: 25,
    height: 25,
    borderRadius: 25 / 2,
    marginEnd: 10
  },
  status: {
    height: 8,
    width: 8,
    borderRadius: 50,
    marginRight: 8
  },
  active: {
    backgroundColor: colorPallete.cordioGreen
  },
  pending: {
    backgroundColor: colorPallete.cordioOrange
  },
  deactivated: {
    backgroundColor: colorPallete.cordioRedDark3
  },
  textAlign: {
    fontFamily: FontFamily.Regular,
    color: colorPallete.textBlack,
    fontSize: 10
  },
  statusContainer: {
    flexDirection: "row",
    alignItems: "center"
  },
  statusColor: {
    height: 8,
    width: 8,
    borderRadius: 50,
    marginRight: 8
  },
  approvedColor: {
    backgroundColor: colorPallete.cordioGreen
  },
  pendingColor: {
    backgroundColor: colorPallete.cordioOrange
  },
  declineColor: {
    backgroundColor: colorPallete.cordioRedDark3
  },
  iconStyle: {
    color: colorPallete.textBlack,
    fontSize: 17,
    marginRight: 10
  },
  textAlignment: {
    textDecorationLine: "underline"
  },
  refreshContainer: {
    height: 100
  },
  refreshSubContainer: {
    justifyContent: "flex-end",
    marginHorizontal: 40
  },
  flexDirectionRow: {
    flexDirection: "row"
  },
  alignNoDataFound: {
    fontFamily: FontFamily.Bold,
    fontSize: 14,
    padding: 18
  }
});
