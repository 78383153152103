import React, { ReactElement, useEffect, useState } from "react";
import { StyleSheet, TouchableOpacity, View } from "react-native";
import { colorPallete } from "@socion-cordio/common/src/assets/styles/colors";
import {
  Text,
  TextSize,
  FontWeight,
  FontFamily
} from "@socion-cordio/common/src/components/atoms/text";
import { useSelector, useDispatch } from "react-redux";
import Icon, { IconNames } from "@socion-cordio/common/src/components/atoms/icon";
import { Divider, Orientations } from "@socion-cordio/common/src/components/atoms/divider";
import { Image } from "@socion-cordio/common/src/components/atoms/image";
import { ApiClient } from "@socion-cordio/common/src/network/apiClient";
import { profileEndPoints } from "@socion-cordio/common/src/repositories/endPoints";
import { HTTP_STATUS_CODES } from "@socion-cordio/common/src/network/constants";
import { toast, ToastContainer } from "react-toastify";
import { ProfileActions } from "@socion-cordio/common/src/modules/profile/actions/actions";
import AddProfileModal from "@socion-cordio/common/src/components/organisms/addProfileModal";
import Modal from "@socion-cordio/common/src/components/atoms/modal";
import Loader from "@socion-cordio/common/src/components/atoms/loader";
import { QR } from "@socion-cordio/common/src/components/atoms/qrCode";
import AesUtil from "@socion-cordio/common/src/utils/encryptionHelper";
import { enableScroll, disableScroll } from "@socion-cordio/common/src/utils/scrollToTop";

interface IProps {
  setLoader?: Function;
}

export default function UserInfo(props: IProps): ReactElement {
  const { setLoader } = props;
  let aesUtil: AesUtil = new AesUtil();
  const [userProfileData, setUserProfileData] = useState(null);
  const [addProfileModalVisible, setAddProfileModalVisible] = useState(false);
  const dispatch = useDispatch();
  const data: any = useSelector((state) => state);

  const processUserProfileData = () => {
    if (data?.profile?.userProfileData?.responseCode === 200) {
      setUserProfileData(data?.profile?.userProfileData.response);
    }
  };

  const handleProfileDownload = () => {
    if (!userProfileData.profileCardGenerated) {
      generateProfileCard();
      setLoader(true);
    } else {
      window.open(`${userProfileData.profileCardUrl}`, "_blank");
    }
  };
  const generateProfileCard = async () => {
    const generateProfileCardResponse = await ApiClient.get(profileEndPoints.generateProfileCard());
    if (generateProfileCardResponse.responseCode === HTTP_STATUS_CODES.ok) {
      setLoader(false);
      window.open(`${generateProfileCardResponse.response}`, "_blank");
      dispatch(ProfileActions.getUserProfile());
    } else {
      setLoader(false);
      toast.error(generateProfileCardResponse.message);
    }
  };

  useEffect(() => {
    processUserProfileData();
  }, [data]);

  useEffect(() => {
    if (addProfileModalVisible) disableScroll();
    else enableScroll();
  }, [addProfileModalVisible]);

  return (
    <>
      <View style={styles.userInfo}>
        <View style={styles.one}>
          <Image
            imageStyle={styles.profileImage}
            testId="socionImg"
            // source={require("@socion-cordio/common/src/assets/images/user_circle.svg")}
            source={
              userProfileData?.photo ||
              require("@socion-cordio/common/src/assets/images/user_circle.svg")
            }
          />
          <View style={styles.uploadProfileIconContainer}>
            <View style={styles.uploadProfileIconSubContainer}>
              <Icon
                onPress={() => setAddProfileModalVisible(true)}
                name={IconNames.uploadProfile}
                customStyle={[styles.iconStyle, styles.uploadIcon]}
              />
            </View>
          </View>
        </View>

        {addProfileModalVisible && (
          <View>
            <Modal
              modalVisible={addProfileModalVisible}
              setModalVisible={() => setAddProfileModalVisible(!addProfileModalVisible)}
              component={
                <View style={styles.modalContainer}>
                  <AddProfileModal
                    onClose={() => setAddProfileModalVisible(false)}
                    profilePicture={userProfileData?.photo}
                  />
                </View>
              }
            />
          </View>
        )}

        <View style={styles.two}>
          <Text fontWeight={FontWeight.Bold} testId="signup" textSize={TextSize.Small}>
            <Text
              fontWeight={FontWeight.Regular}
              testId="addRoleText"
              textSize={TextSize.Small}
              textStyle={styles.headerText}
            >
              {userProfileData?.name}
            </Text>
            <View style={styles.profileOptions}>
              <TouchableOpacity
                onPress={() => handleProfileDownload()}
                style={styles.iconsLabelContainer}
              >
                <View>
                  <Icon
                    name={IconNames.download}
                    customStyle={[styles.iconStyle, styles.alignIcon]}
                  />
                </View>
                <Text
                  fontWeight={FontWeight.Regular}
                  testId="signup"
                  textSize={TextSize.Small}
                  textStyle={[styles.textStyle]}
                >
                  Download
                </Text>
              </TouchableOpacity>
            </View>
          </Text>
        </View>

        <View style={styles.three}>
          <QR value={aesUtil.encrypt(userProfileData?.userId)} />
        </View>
      </View>
    </>
  );
}

const styles = StyleSheet.create({
  userInfo: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignContent: "center",
    alignItems: "flex-start"
  },
  one: {
    // flex: 0.8,
    width: 100,
    position: "relative"
  },

  //css for two container
  two: {
    flex: 2
  },
  headerText: {
    fontSize: 12,
    fontFamily: FontFamily.Bold,
    lineHeight: 17,
    fontWeight: "700"
  },

  three: {
    flex: 1
  },
  profileImage: {
    width: 65,
    height: 65,
    borderRadius: 100 / 2
  },
  profileOptions: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: 20,
    width: "80%"
  },
  iconsLabelContainer: {
    flexDirection: "row",
    alignItems: "center"
  },
  shareIcon: {
    color: colorPallete.cordioTaupe,
    paddingEnd: 15
  },
  downloadIcon: {
    color: colorPallete.cordioTaupe,
    paddingStart: 5,
    paddingEnd: 35
  },
  iconStyle: {
    fontSize: 17,
    color: colorPallete.cordioTaupe
  },
  alignIcon: {
    marginRight: 10
  },
  uploadProfileIconContainer: {
    position: "absolute",
    borderRadius: 50,
    width: 30,
    height: 30,
    backgroundColor: colorPallete.white,
    justifyContent: "center",
    alignItems: "center",
    bottom: -10,
    right: 30
  },
  uploadProfileIconSubContainer: {
    borderRadius: 50,
    width: 27,
    height: 27,
    backgroundColor: colorPallete.cordioTaupe,
    justifyContent: "center",
    alignItems: "center"
  },
  uploadIcon: {
    color: colorPallete.white
  },
  textStyle: {
    fontSize: 12,
    fontFamily: FontFamily.Medium,
    fontWeight: "500",
    lineHeight: 17,
    color: colorPallete.textLight
  },
  modalContainer: {
    width: 680
  },
  loaderContainer: {
    padding: 20,
    width: "100%",
    paddingTop: 5
  },
  subLoaderContainer: {
    backgroundColor: colorPallete.white,
    borderRadius: 20,
    padding: 20
  }
});
