import React, { ReactElement, useEffect, useState } from "react";
import { StyleSheet, View } from "react-native";
import { colorPallete } from "@socion-cordio/common/src/assets/styles/colors";
import ButtonTab, {
  ButtonTypeSecondary
} from "@socion-cordio/common/src/components/atoms/buttonTab";
import ProgramRoles from "@socion-cordio/common/src/components/organisms/program/programRoles";
import ProgramDetailsPage from "@socion-cordio/common/src/components/organisms/program/programDetailsPage";
import { FontFamily } from "@socion-cordio/common/src/components/atoms/text";
import { LocalStorage } from "@socion-cordio/common/src/services/storage/storageService";
import { useSelector, useDispatch } from "react-redux";
import { UserRolesProgramActions } from "@socion-cordio/common/src/modules/user-role/actions/actions";

interface Props {
  onSubmit?: (data: { otp: number }) => void;
}

const DETAILS_TAB = "detailsTabOpen";
const ROLES_TAB = "rolesTabOpen";

export default function programPage(props: Props): ReactElement {
  const [isDetails, setIsDetails] = useState(true);
  const [isRoles, setIsRoles] = useState(false);
  const [programDetails, setProgramDetails] = useState(null);
  const [programStatus, setProgramStatus] = useState(null);
  const dispatch = useDispatch();
  const programData: any = JSON.parse(localStorage.getItem("programDetails"));
  const isSuperAdmin = JSON.parse(localStorage.getItem("isSuperAdmin"));
  const userRolesData: any = useSelector((state: any) => state?.userRolesProgram);
  LocalStorage.setStorage("userRoles", userRolesData);

  useEffect(() => {
    getProgramDetails();
  }, []);

  const getUserRoles = () => {
    const rolesPayload = {
      programId: programData.program.id
    };
    dispatch(UserRolesProgramActions.getUserRolesProgram(rolesPayload));
  };

  const getProgramDetails = async () => {
    const programDetails = await LocalStorage.getStorage("programDetails");
    setProgramDetails(programDetails);
  };

  const getProgramStatus = (data: boolean) => {
    setProgramStatus(data);
  };

  useEffect(() => {
    checkTabSelectionHandler();
  }, []);

  const checkTabSelectionHandler = async () => {
    const tabSelected = await LocalStorage.getStorage("programTabsOpen");
    tabSelected !== null
      ? tabSelected === DETAILS_TAB
        ? programDetailsTabSelectHandler()
        : programRolesTabSelectHandler()
      : null;
  };

  const programDetailsTabSelectHandler = () => {
    setIsDetails(true);
    setIsRoles(false);
    LocalStorage.setStorage("programTabsOpen", DETAILS_TAB);
  };

  const programRolesTabSelectHandler = () => {
    setIsRoles(true);
    setIsDetails(false);
    LocalStorage.setStorage("programTabsOpen", ROLES_TAB);
  };

  return (
    <View>
      <View style={styles.container}>
        <View style={styles.subContainer}>
          <View style={styles.toggleContainer}>
            {/* <ButtonGroup/> */}
            <ButtonTab
              type={isDetails ? ButtonTypeSecondary.Secondary : ButtonTypeSecondary.Primary}
              buttonStyles={[
                styles.toggleButton,
                { borderBottomLeftRadius: 5, borderTopLeftRadius: 5 }
              ]}
              title="Details"
              textStyles={styles.textButtonStyle}
              onPress={() => {
                programDetailsTabSelectHandler();
              }}
            />
            <ButtonTab
              type={isRoles ? ButtonTypeSecondary.Secondary : ButtonTypeSecondary.Primary}
              buttonStyles={[
                styles.toggleButton,
                { borderBottomRightRadius: 5, borderTopRightRadius: 5 }
              ]}
              title="Roles"
              textStyles={styles.textButtonStyle}
              onPress={() => {
                programRolesTabSelectHandler();
              }}
            />
          </View>
          {programDetails && isDetails ? (
            <ProgramDetailsPage
              programDetails={programDetails}
              userRoles={userRolesData}
              isSuperAdmin={isSuperAdmin}
              getProgramStatus={getProgramStatus}
              programStatus={programStatus}
            />
          ) : isRoles ? (
            <ProgramRoles
              programData={programDetails}
              userRoles={userRolesData}
              isSuperAdmin={isSuperAdmin}
              getUserRoles={() => getUserRoles()}
              programStatus={programStatus}
            />
          ) : null}
        </View>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    display: "flex",
    padding: 20,
    paddingTop: 15,
    backgroundColor: "#F8F5F0",
    minHeight: "calc(100vh - 50px)"
  },
  header: {
    display: "flex",
    flexDirection: "row"
  },
  iconContainer: {
    marginTop: 10
  },
  titleIcon: {
    fontSize: 15
  },
  subContainer: {
    backgroundColor: colorPallete.white,
    borderRadius: 10,
    shadowColor: colorPallete.cordioRedDark1,
    shadowOpacity: 0.1,
    shadowOffset: {
      height: 10,
      width: 5
    },
    shadowRadius: 10
  },
  containerStyle: {
    width: "20%",
    marginLeft: 15
  },
  dropDownContainerStyle: {
    borderWidth: 0,
    borderTopRightRadius: 5,
    borderBottomRightRadius: 5
  },
  toggleContainer: {
    flexDirection: "row",
    padding: 18,
    paddingBottom: 0
  },
  toggleButton: {
    width: 180
  },
  textButtonStyle: {
    fontSize: 12
  },
  alignHeader: {
    marginBottom: 30
  },
  headerText: {
    fontSize: 14,
    fontFamily: FontFamily.Medium,
    lineHeight: 19.5,
    fontWeight: "700"
  },
  iconStyle: {
    fontSize: 18,
    marginRight: 35
  }
});
