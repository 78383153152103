import React, { ReactElement, useEffect, useState } from "react";
import { Form, Formik, FormikProps } from "formik";
import { StyleSheet, View } from "react-native";
import { colorPallete } from "@socion-cordio/common/src/assets/styles/colors";
import {
  Text,
  TextSize,
  FontWeight,
  FontFamily
} from "@socion-cordio/common/src/components/atoms/text";
import UserInput from "@socion-cordio/common/src/components/molecules/userInput";
import Icon, { IconNames } from "@socion-cordio/common/src/components/atoms/icon";
import * as Yup from "yup";
import Button, { ButtonType } from "@socion-cordio/common/src/components/atoms/button";
import AesUtil from "@socion-cordio/common/src/utils/encryptionHelper";
import { profileEndPoints } from "@socion-cordio/common/src/repositories/endPoints";
import { ApiClient } from "@socion-cordio/common/src/network/apiClient";
import { HTTP_STATUS_CODES } from "@socion-cordio/common/src/network/constants";
import { ToastContainer, toast } from "react-toastify";
import AddTelemetryService from "@socion-cordio/common/src/services/telemetryService";
import { LocalStorage } from "@socion-cordio/common/src/services/storage/storageService";
import Moment from "moment";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import Loader from "@socion-cordio/common/src/components/atoms/loader";
import {
  allRoutesNames,
  allRoutesNames as routeNames
} from "@socion-cordio/web/src/navigation/allRouteNames";
interface Props {
  fetchResetPassword?: Function;
  // profileData: Profile;
  // loading: IProfileLoading;
  updateResetPassword?: Function;
}
export default function ChangePassword(props: Props): ReactElement {
  const dispatch = useDispatch();
  const history: any = useHistory();
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState({ current: false, new: false });
  const formValidaionSchema = Yup.object({
    currentPassword: Yup.string().required("Please enter current password").nullable(),
    newPassword: Yup.string().required("Please enter new password").nullable(),
    reEnterNewPassword: Yup.string()
      .required("Please confirm your new password")
      .nullable()
      .oneOf([Yup.ref("newPassword"), null], "Passwords entered don't match")
  });
  const ResetPassword = async (values: {
    currentPassword: string;
    newPassword: string;
    reEnterNewPassword: string;
  }) => {
    let payload: any = {};
    let aesUtil: AesUtil = new AesUtil();
    payload.currentPassword = aesUtil.encrypt(values.currentPassword);
    payload.newPassword = aesUtil.encrypt(values.newPassword);
    let changePasswordResponse = await ApiClient.post(profileEndPoints.changePassword(), payload);
    if (changePasswordResponse.responseCode === HTTP_STATUS_CODES.ok) {
      setLoading(true);
      toast.success(
        "Your Password has been changed successfully. You will be prompted to login with your new password"
      );
      updateTelemetry();
      handleLogout();
    } else {
      if (changePasswordResponse.message === "Current password value is incorrect") {
        toast.error("Current password is incorrect...!!");
      } else if (
        changePasswordResponse.message ===
        "Current password and updated password should be different"
      ) {
        toast.error("New password should be different from the existing password.");
      } else {
        toast.error("Something went Wrong!");
      }

      setLoading(false);
    }
  };
  const updateTelemetry = async () => {
    const user: any = await LocalStorage.getStorage("user");
    const body = {
      createdAt: Moment().toLocaleString(),
      deleted: "false",
      updatedAt: Moment().toLocaleString(),
      eventType: "Profile edit-Password change"
    };
    const userDetails = user;
    AddTelemetryService(body, undefined, userDetails);
  };

  const handleLogout = () => {
    setTimeout(() => {
      setLoading(false);
      dispatch({ type: "USER_LOGOUT" });
      history.push(allRoutesNames.IAM.root + allRoutesNames.IAM.login);
      LocalStorage.removeStoredKeys();
    }, 1500);
  };

  const handleShowPassword = (key: string) => {
    if (key == "current") setShowPassword({ ...showPassword, current: !showPassword.current });
    else setShowPassword({ ...showPassword, new: !showPassword.new });
  };

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <View style={styles.mainContainer}>
          <View style={styles.container}>
            <View style={styles.formContainer}>
              <Formik
                initialValues={{
                  currentPassword: null,
                  newPassword: null,
                  reEnterNewPassword: null
                }}
                onSubmit={(values, { setSubmitting }) => {
                  ResetPassword(values);
                  setSubmitting(false);
                }}
                validationSchema={formValidaionSchema}
                enableReinitialize={true}
              >
                {(
                  formikProps: FormikProps<{
                    currentPassword: string;
                    newPassword: string;
                    reEnterNewPassword: string;
                  }>
                ) => (
                  <Form>
                    <View>
                      <Text
                        fontWeight={FontWeight.Bold}
                        testId="changePasswordText"
                        textSize={TextSize.Large}
                        textStyle={styles.subHeading}
                      >
                        Change Password
                      </Text>
                      <View style={styles.alignContainer}>
                        <UserInput
                          label=""
                          handleChange={formikProps.handleChange("currentPassword")}
                          handleBlur={() => formikProps.setFieldTouched}
                          userStyle={styles.mobile}
                          labelStyle={styles.labelStyle}
                          textField={styles.textField}
                          textIcon={styles.textIcon}
                          input={styles.input}
                          textValue="Current password"
                          name="currentPassword"
                          placeholder="Current Password"
                          value={formikProps.values.currentPassword}
                          id="currentPassword"
                          isSecure={showPassword.current ? false : true}
                          handleKeyPress={(e: any) => {
                            if (e.key === "Enter") {
                              formikProps.handleSubmit();
                            }
                          }}
                        />
                        <Icon
                          testID="close"
                          name={IconNames.passwordEye}
                          customStyle={styles.eyeIcon}
                          onPress={() => handleShowPassword("current")}
                        />
                      </View>
                      <View style={styles.alignContainer}>
                        <UserInput
                          label=""
                          handleChange={formikProps.handleChange("newPassword")}
                          handleBlur={() => formikProps.setFieldTouched}
                          userStyle={styles.mobile}
                          labelStyle={styles.labelStyle}
                          textField={styles.textField}
                          textIcon={styles.textIcon}
                          input={styles.input}
                          textValue="New password"
                          name="newPassword"
                          placeholder="New Password"
                          value={formikProps.values.newPassword}
                          id="newPassword"
                          isSecure={showPassword.new ? false : true}
                          handleKeyPress={(e: any) => {
                            if (e.key === "Enter") {
                              formikProps.handleSubmit();
                            }
                          }}
                        />
                        <Icon
                          testID="close"
                          name={IconNames.passwordEye}
                          customStyle={styles.eyeIcon}
                          onPress={() => handleShowPassword("new")}
                        />
                      </View>
                      <View style={styles.alignContainer}>
                        <UserInput
                          label=""
                          handleChange={formikProps.handleChange("reEnterNewPassword")}
                          handleBlur={() => formikProps.setFieldTouched}
                          userStyle={styles.mobile}
                          labelStyle={styles.labelStyle}
                          textField={styles.textField}
                          textIcon={styles.textIcon}
                          input={styles.input}
                          textValue="Re-enter new password"
                          name="reEnterNewPassword"
                          placeholder="Re-enter new password"
                          value={formikProps.values.reEnterNewPassword}
                          id="reEnterNewPassword"
                          isSecure
                          handleKeyPress={(e: any) => {
                            if (e.key === "Enter") {
                              formikProps.handleSubmit();
                            }
                          }}
                        />
                      </View>
                      <View style={styles.subitButtonContainer}>
                        <View style={styles.submitButtonContainerOne}>
                          <Button
                            type={ButtonType.Primary}
                            buttonStyles={styles.button}
                            textStyles={styles.textStyle}
                            title="Change Password"
                            onPress={() => {
                              formikProps.handleSubmit();
                            }}
                          />
                        </View>
                        <View style={styles.submitButtonContainerTwo}></View>
                      </View>
                    </View>
                  </Form>
                )}
              </Formik>
            </View>
          </View>
        </View>
      )}{" "}
    </>
  );
}
const styles = StyleSheet.create({
  mainContainer: {
    backgroundColor: "#F8F5F0",
    padding: 20,
    // height:'100vh'
    minHeight: "calc(100vh - 50px)"
  },
  container: {
    // top: 70,
    minHeight: "78vh",
    flexDirection: "row",
    justifyContent: "space-between",
    backgroundColor: colorPallete.white,
    borderRadius: 20,
    height: "100%",
    //shadow
    shadowColor: colorPallete.cordioRedDark1,
    shadowOpacity: 0.1,
    shadowOffset: {
      height: 10,
      width: 5
    },
    shadowRadius: 10
  },
  formContainer: {
    padding: 20,
    width: "100%"
  },
  subitButtonContainer: {
    flexDirection: "row"
  },
  submitButtonContainerOne: {
    flex: 17
  },
  submitButtonContainerTwo: {
    flex: 83
  },
  button: {
    marginTop: 20,
    height: 50,
    fontSize: 12,
    zIndex: -1
  },
  textStyle: {
    fontSize: 12
  },
  mobile: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    // width: "50%",
    marginTop: 10
  },
  subHeading: {
    fontSize: 12,
    fontWeight: "700"
  },
  textFieldStyle: {
    flexGrow: 1,
    flexShrink: 1,
    flexBasis: "auto",
    marginTop: "10px"
  },
  alignContainer: {
    height: 65
  },
  eyeIcon: {
    marginTop: "-45px",
    marginLeft: "475px"
  },
  labelStyle: {
    flex: 17,
    height: 40
  },
  textField: {
    // flex: 83
    width: 400,
    position: "absolute",
    left: 165
  },
  textIcon: {
    fontSize: 12,
    fontFamily: FontFamily.Regular
  },
  input: {
    marginRight: "0.5rem",
    fontWeight: FontWeight.Light,
    fontFamily: FontFamily.Medium,
    fontSize: 12
  },
  //Align Header
  alignHeader: {
    marginBottom: 30
  },
  header: {
    display: "flex",
    flexDirection: "row"
  },
  headerText: {
    fontSize: 14,
    fontFamily: FontFamily.Medium,
    lineHeight: 19.5,
    fontWeight: "700"
  },
  iconStyle: {
    fontSize: 15,
    marginRight: 35
  }
});
