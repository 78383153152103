import React from "react";

const IndeterminateCheckbox = React.forwardRef((props: any, ref) => {
  const { indeterminate, ...rest } = props;
  const defaultRef = React.useRef();
  const resolvedRef: any = ref || defaultRef;

  React.useEffect(() => {
    resolvedRef.current.indeterminate = indeterminate;
  }, [resolvedRef, indeterminate]);

  return (
    <>
      <input type="checkbox" ref={resolvedRef} {...rest} />
    </>
  );
});

export default IndeterminateCheckbox;
