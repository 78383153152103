import React, { ReactElement, useEffect, useRef, useState } from "react";
import { StyleSheet, TouchableOpacity, View } from "react-native";
import { colorPallete } from "@socion-cordio/common/src/assets/styles/colors";
import {
  Text,
  TextSize,
  FontWeight,
  FontFamily
} from "@socion-cordio/common/src/components/atoms/text";
import Icon, { IconNames } from "@socion-cordio/common/src/components/atoms/icon";
import Modal from "@socion-cordio/common/src/components/atoms/modal";
import { LocalStorage } from "@socion-cordio/common/src/services/storage/storageService";
import FilterLocationPage from "@socion-cordio/common/src/components/organisms/qualification-packs/filterLocationPage";
import FilterBadgeRolePage from "@socion-cordio/common/src/components/organisms/qualification-packs/filterBadgeRolePage";
import Button, { ButtonType } from "@socion-cordio/common/src/components/atoms/button";

interface Props {
  modalVisible: boolean;
  setModalVisible?: Function;
  getSelectedLocationList: Function;
  getSelectedRoleBadgeList: Function;
  dataFilteredHandler: Function;
  locationList: any[];
  badgeRoleList: any[];
  programDetails: any;
  setExpandedList: Function;
  expandedList: any;
  setCheckedList: Function;
  checkedList: any;
  clearAllFilter: Function;
  setIsLocationFilterApplied: Function;
  setIsBadgeFilterApplied: Function;
  closeModalHandler: Function;
  setGlobalList?: Function;
  globalList: any;
  setAllExpandedNodes?: any;
  allExpandedNodes?: any;
}
export default function FilterQualificationPackModal(props: Props): ReactElement {
  const {
    modalVisible,
    setModalVisible,
    locationList,
    getSelectedLocationList,
    getSelectedRoleBadgeList,
    dataFilteredHandler,
    programDetails,
    badgeRoleList,
    setExpandedList,
    expandedList,
    setCheckedList,
    checkedList,
    clearAllFilter,
    setIsLocationFilterApplied,
    setIsBadgeFilterApplied,
    closeModalHandler,
    setGlobalList,
    globalList,
    setAllExpandedNodes,
    allExpandedNodes
  } = props;
  const [isLocationSelect, setIsLocationSelect] = useState(true);
  const [isBadgeRoleSelect, setIsBadgeRoleSelect] = useState(false);
  const [updatedLocationList, setUpdatedLocationList] = useState([]);
  const [updatedBadgeList, setUpdatedBadgeList] = useState([]);

  const updatedFilteredLocationList = (locationList: any, bool: any) => {
    setUpdatedLocationList(locationList);
  };

  const updatedBadgesList = (badgeList: any) => {
    setUpdatedBadgeList(badgeList);
  };

  const applyFilterHandler = async () => {
    LocalStorage.setStorage("globalNameList", globalList);
    // setIsLocationFilterApplied(true);
    // setIsBadgeFilterApplied(true);

    // getSelectedLocationList(updatedLocationList);
    // getSelectedRoleBadgeList(updatedBadgeList);

    const isNoneLocationSet = await LocalStorage.getStorage("noneLocationListSet");
    const isNoBadgeAssignSet = await LocalStorage.getStorage("noAssignedBadgesSelected");

    dataFilteredHandler({
      isFiltered: true,
      isNoneLocationSet: isNoneLocationSet,
      isNoBadgeAssignSet: isNoBadgeAssignSet
    });
    setModalVisible();

    setIsLocationFilterApplied(true);
    setIsBadgeFilterApplied(true);
  };

  return (
    <View>
      {/* <Modal
        modalVisible={modalVisible}
        setModalVisible={() => setModalVisible()}
        component={ */}
      <View style={styles.container}>
        <View style={styles.header}>
          <Text
            fontWeight={FontWeight.Bold}
            testId="addRoleText"
            textSize={TextSize.Small}
            textStyle={styles.headerText}
          >
            Filter
          </Text>
          <View>
            <Icon
              testID="close"
              name={IconNames.crossCircle}
              customStyle={styles.crossCircle}
              // onPress={() => [setModalVisible(), dataFilteredHandler(true)]}
              onPress={() => [setModalVisible(), closeModalHandler()]}
            />
          </View>
        </View>
        <View style={styles.subContainer}>
          <View style={styles.leftContainer}>
            <View>
              <TouchableOpacity
                onPress={() => {
                  setIsLocationSelect(true);
                  setIsBadgeRoleSelect(false);
                }}
              >
                <View style={styles.alignLeftContainer}>
                  <View>
                    <Icon
                      testID="baseLocation"
                      name={IconNames.userName}
                      customStyle={
                        isLocationSelect ? styles.leftIconContainerDark : styles.leftIconContainer
                      }
                    />
                  </View>
                  <Text
                    fontWeight={FontWeight.Light}
                    // fontWeight={FontWeight.Bold}
                    testId="addRoleText"
                    textSize={TextSize.Small}
                    textStyle={
                      isLocationSelect
                        ? [styles.headerText, styles.headerTextPrimary]
                        : [styles.headerTextSecondary]
                    }
                  >
                    Location
                  </Text>
                </View>
              </TouchableOpacity>
              <TouchableOpacity
                onPress={() => {
                  setIsLocationSelect(false);
                  setIsBadgeRoleSelect(true);
                }}
              >
                <View style={styles.alignLeftContainer}>
                  <View>
                    <Icon
                      testID="establishment"
                      name={IconNames.mobile}
                      customStyle={
                        isBadgeRoleSelect ? styles.leftIconContainerDark : styles.leftIconContainer
                      }
                    />
                  </View>
                  <Text
                    fontWeight={FontWeight.Light}
                    testId="addRoleText"
                    textSize={TextSize.Small}
                    textStyle={
                      isBadgeRoleSelect
                        ? [styles.headerText, styles.headerTextPrimary]
                        : [styles.headerTextSecondary]
                    }
                  >
                    Badge role
                  </Text>
                </View>
              </TouchableOpacity>
            </View>
          </View>
          <View style={styles.dividerContainer}></View>
          <View style={styles.rightContainer}>
            {programDetails && isLocationSelect ? (
              <FilterLocationPage
                getSelectedLocationList={getSelectedLocationList}
                locationList={locationList}
                programDetails={programDetails}
                setExpandedList={setExpandedList}
                expandedList={expandedList}
                setCheckedList={setCheckedList}
                checkedList={checkedList}
                dataFilteredHandler={dataFilteredHandler}
                setModalVisible={setModalVisible}
                setIsLocationFilterApplied={setIsLocationFilterApplied}
                updatedFilteredLocationList={updatedFilteredLocationList}
                setGlobalList={setGlobalList}
                globalList={globalList}
                setAllExpandedNodes={setAllExpandedNodes}
                allExpandedNodes={allExpandedNodes}
              />
            ) : isBadgeRoleSelect ? (
              <FilterBadgeRolePage
                getSelectedRoleBadgeList={getSelectedRoleBadgeList}
                badgeRoleList={badgeRoleList}
                programDetails={programDetails}
                dataFilteredHandler={dataFilteredHandler}
                setModalVisible={setModalVisible}
                setIsBadgeFilterApplied={setIsBadgeFilterApplied}
                updatedBadgesList={updatedBadgesList}
              />
            ) : null}
          </View>
        </View>
        <View style={[styles.mainButtonContainer]}>
          {/* <View style={styles.buttonContainer}>
                <Button
                  type={ButtonType.Secondary}
                  buttonStyles={styles.button}
                  textStyles={styles.buttonFont}
                  title="Clear"
                  onPress={() => [clearAllFilter(false), setModalVisible()]}
                />
              </View> */}
          <View style={styles.buttonContainer}>
            <Button
              type={ButtonType.Primary}
              buttonStyles={styles.button}
              textStyles={styles.buttonFont}
              title="Apply"
              onPress={() => applyFilterHandler()}
            />
          </View>
        </View>
      </View>
      {/* }
      ></Modal> */}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    width: 655,
    height: 450
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row"
  },
  headerText: {
    fontSize: 12,
    fontFamily: FontFamily.Medium,
    lineHeight: 17
  },
  headerTextSecondary: {
    fontSize: 12,
    marginLeft: 15,
    color: colorPallete.textLight,
    fontFamily: FontFamily.Regular
  },
  headerTextPrimary: {
    fontSize: 12,
    fontWeight: "600",
    marginLeft: 15
  },
  alignText: {
    color: colorPallete.textLight,
    marginTop: 10
  },
  textColor: {
    color: colorPallete.textBlack
  },
  crossCircle: {
    fontSize: 15
  },
  subContainer: {
    marginTop: 15,
    marginBottom: 15,
    flexDirection: "row"
  },

  //left container
  leftContainer: {
    flex: 20,
    padding: 10,
    paddingLeft: 0
  },
  alignLeftContainer: {
    flexDirection: "row",
    alignItems: "center",
    marginBottom: 25
  },
  // alignLabelContainer: {
  //   flexDirection: "column",
  //   justifyContent: "space-between",
  //   height: "95%"
  // },
  leftIconContainer: {
    fontSize: 16,
    color: colorPallete.textLight
  },
  leftIconContainerDark: {
    fontSize: 16,
    color: colorPallete.textBlack
  },

  //divider
  dividerContainer: {
    flex: 1,
    minHeight: 450,
    borderRightColor: colorPallete.cordioTaupe,
    borderRightWidth: 1,
    borderStyle: "solid",
    opacity: 0.1
  },

  //right container
  rightContainer: {
    flex: 79,
    padding: 10,
    paddingLeft: 25
  },
  //text field
  inputStyling: {
    height: 40,
    // outline: "none",
    borderRadius: 5,
    padding: 7,
    fontSize: 12,
    fontFamily: FontFamily.Regular,
    fontWeight: "400"
  },
  inputStylingAlignment: {},
  //checkBox
  checkBoxContainer: {
    marginTop: 27
  },
  textStyleCheckBox: {
    marginStart: 20
  },
  mainButtonContainer: {
    position: "absolute",
    flexDirection: "row",
    bottom: 0,
    right: 0
  },
  buttonContainer: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center"
  },
  button: {
    width: 100,
    height: 40,
    borderRadius: 10
  },
  buttonFont: {
    fontSize: 12
  }
});
