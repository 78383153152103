import React, { useEffect, useState } from "react";
import { StyleSheet, View } from "react-native";
import {
  Text,
  TextSize,
  FontWeight,
  FontFamily
} from "@socion-cordio/common/src/components/atoms/text";
import Icon, { IconNames } from "@socion-cordio/common/src/components/atoms/icon";
import { allRoutesNames as routeNames } from "@socion-cordio/web/src/navigation/allRouteNames";
import { LocalStorage } from "@socion-cordio/common/src/services/storage/storageService";
import Dropdown from "@socion-cordio/common/src/components/molecules/dropdown";
import { useHistory, useLocation } from "react-router-dom";
import { UserRolesProgramActions } from "@socion-cordio/common/src/modules/user-role/actions/actions";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { artefactEndPoints } from "@socion-cordio/common/src/repositories/endPoints";
import { ApiClient } from "@socion-cordio/common/src/network/apiClient";
import { compact, find, isEmpty } from "lodash";
import { ArtefactActions } from "@socion-cordio/common/src/modules/artefacts/actions/action";
import { ArtefactTypesActions } from "@socion-cordio/common/src/modules/artefactTypes/actions/action";
import {
  canAccessAll,
  canOnlyView,
  canReview,
  isVerifier
} from "@socion-cordio/web/src/utils/artefactActionsHelper";
import { NotificationsActions } from "@socion-cordio/common/src/modules/notifications/actions/action";
import { ProgramActions } from "@socion-cordio/common/src/modules/program/actions/actions";
import { artefact_messages } from "@socion-cordio/web/src/modules/artefacts/data/message";

interface Props {
  callbackFromParentHandler?: Function;
  isDropdownOpen?: boolean;
  setIsDropdownOpen?: any;
}

export default function ArtefactProgramDropdown(props: Props) {
  const { callbackFromParentHandler, setIsDropdownOpen, isDropdownOpen } = props;
  let [programDetails, setProgramDetails] = useState(null);
  const [containerWidth, setContainerWidth] = useState(null);
  const [val, setVal] = useState();
  const [items, setItems] = useState([]);
  const [userProgramList, setUserProgramList] = useState(null);
  const [loading, setLoading] = useState(true);
  const [registryEntityId, setRegistryEntityId] = useState(null);
  const history: any = useHistory();
  const programData: any = JSON.parse(localStorage.getItem("programDetails"));
  const dispatch = useDispatch();
  const location = useLocation();
  const [programId, setProgramId] = useState(null);
  const user = JSON.parse(localStorage.getItem("user"));

  useEffect(() => {
    getProgramDetails();
    getNotificationDetails();
  }, []);

  useEffect(() => {
    setIsDropdownOpen(false);
  }, [location.pathname]);

  const { programsData: entities } = useSelector((state: any) => state.programs);

  const {
    userRolesData: userRoles,
    loading: { getUserRolesProgram: rolesLoader }
  } = useSelector((state: any) => state?.userRolesProgram);

  useEffect(() => {
    if (programData?.program?.id) {
      const rolesPayload = {
        programId: programData?.program?.id
      };
      dispatch(UserRolesProgramActions.getUserRolesProgram(rolesPayload));
    }
  }, [location]);

  useEffect(() => {
    // storing userRoles
    LocalStorage.setStorage("userRoles", userRolesProgram);
  }, [useSelector((state: any) => state?.userRolesProgram)]);

  let userRolesProgram: any = useSelector((state: any) => state?.userRolesProgram);

  const getNotificationDetails = async () => {
    dispatch(NotificationsActions.getNotifications());
  };

  const getProgramDetails = async () => {
    const programDetails: any = await LocalStorage.getStorage("programDetails");
    getAllUserPrograms(programDetails);
    setProgramDetails(programDetails);
  };

  const getAllUserPrograms = async (programDetails: any) => {
    try {
      const user: any = await LocalStorage.getStorage("user");
      const response = await ApiClient.get(artefactEndPoints.getAllUserTaskPrograms(user.userId));
      const programs = response?.response?.programs;
      console.log("getAllUserPrograms", programs);
      const userRoles = response?.response?.roles;
      const badgeRoles = response?.response?.badgeList;
      LocalStorage.setStorage("allProgramRolesOfUser", userRoles);
      LocalStorage.setStorage("allProgramBadgesOfUser", badgeRoles);

      setUserProgramList(programs);
      setLoading(false);
      setProgramHandler(programs, programDetails);
    } catch (error) {
      setLoading(false);
      toast.error("Something went wrong.");
    }
  };

  const getBadgeRoles = (badges: any[], pid: any) => {
    let list = badges.map((badge) => {
      if (badge?.programId === pid) {
        return badge?.badgeName;
      }
    });
    list = compact(list);
    return list;
  };

  const setProgramHandler = (programDetails: any, currentProgramDetails: any) => {
    const list: any = programDetails?.map((data: any, id: any) => {
      return { label: data.name, value: parseInt(data.id) };
    });
    const program: any = currentProgramDetails?.program
      ? currentProgramDetails?.program
      : currentProgramDetails || "";
    const search = location.search;
    const programId = new URLSearchParams(search).get("programId");
    setProgramId(programId);

    if (programId) {
      const currentProgram = find(programDetails, { id: parseInt(programId) });
      if (currentProgram) {
        setVal(currentProgram?.id);
        setContainerWidth(currentProgram?.name.length);
      } else {
        toast.error(artefact_messages.error.PDA_PROGRAM_ERROR);
        setVal(programDetails[0]?.id);
        setContainerWidth(programDetails[0]?.name?.length);
      }
    } else if (!program?.id) {
      setVal(programDetails[0]?.id);
      setContainerWidth(programDetails[0]?.name.length);
    } else {
      setVal(program?.id);
      setContainerWidth(program?.name.length);
    }
    setItems(list);
  };

  useEffect(() => {
    dispatch(ProgramActions.getPrograms());
  }, [val]);

  const updateLocalStorage = (entityId?: any, selectedProgram?: any) => {
    let index = entities.findIndex((items: any) => items?.entity?.registryEntityId == entityId);
    let data = entities[index];
    if (data) {
      const programIndex = data
        ? data.programs.findIndex((item: any) => item?.entity?.registryEntityId === entityId)
        : -1;
      const program = data ? data?.programs[programIndex] : "";
      if (!programDetails) {
        programDetails = { entityData: data };
      } else {
        programDetails.entityData = data;
      }
      if (program) {
        programDetails.program = program;
      } else {
        programDetails.program = selectedProgram;
      }
    } else {
      programDetails.program = selectedProgram;
    }
    LocalStorage.setStorage("programDetails", programDetails);
  };

  useEffect(() => {
    if (!!entities && !!registryEntityId) {
      let index = entities.findIndex(
        (items: any) => items?.entity?.registryEntityId == registryEntityId
      );
      let data = entities[index];

      if (!programDetails) {
        programDetails = { entityData: data };
      } else {
        programDetails.entityData = data;
      }
      if (userProgramList !== null) {
        const selectedProgram = userProgramList.filter((item: any) => item.id === val);
        programDetails.program = selectedProgram[0];
      }
      LocalStorage.setStorage("programDetails", programDetails);
      // history.push(`${routeNames.app}${routeNames.workspace}`);
    }
  }, [entities]);

  const onChangeValue = async () => {
    if (userProgramList !== null) {
      const selectedProgram = userProgramList.filter((item: any) => item.id === val);
      callbackFromParentHandler(selectedProgram[0]?.entity?.name);
      setRegistryEntityId(selectedProgram[0]?.entity?.registryEntityId);
      if (!programDetails) {
        programDetails = { program: selectedProgram[0] };
      } else {
        programDetails.program = selectedProgram[0];
      }

      // updateLocalStorage(selectedProgram[0]?.entity?.registryEntityId, selectedProgram[0]);
      setContainerWidth(selectedProgram[0]?.name?.length);
      LocalStorage.setStorage("programDetails", programDetails);
      const userRoles = JSON.parse(localStorage.getItem("allProgramRolesOfUser"));

      if (!!userRoles) {
        const currentProgramRole = find(userRoles, {
          programId: parseInt(programDetails?.program?.id)
        });

        LocalStorage.setStorage("currentProgramRole", currentProgramRole?.roles || []);
      }
      const userBadgeRoles = JSON.parse(localStorage.getItem("allProgramBadgesOfUser"));

      if (!!userBadgeRoles) {
        let currentProgramBadgeRole = getBadgeRoles(userBadgeRoles, {
          programId: parseInt(programDetails?.program?.id)
        });

        currentProgramBadgeRole = currentProgramBadgeRole ? currentProgramBadgeRole : [];

        LocalStorage.setStorage("currentProgramBadgeRole", currentProgramBadgeRole);
      }

      if (programId) {
        setProgramId(selectedProgram[0]?.id);
        history.push(
          `${routeNames.app + routeNames.workspace}?programId=${selectedProgram[0]?.id}`
        );
        if (selectedProgram[0]?.id) {
          dispathRolesApiHandler(selectedProgram[0].id);
          dispatchArtefactsApiHandler(selectedProgram[0].id);
          dispatchArtefactTypesApiHandler(selectedProgram[0].id);
        }
      } else {
        if (selectedProgram[0]?.id) {
          dispathRolesApiHandler(selectedProgram[0].id);
          dispatchArtefactsApiHandler(selectedProgram[0].id);
          dispatchArtefactTypesApiHandler(selectedProgram[0].id);
        }
        // history.push(routeNames.app + routeNames.workspace);
      }
    }
  };
  const dispatchArtefactsApiHandler = (id: number) => {
    const payload: any = {
      pid: id
    };
    if (!canAccessAll() && !canOnlyView() && !isVerifier()) {
      payload.userId = user?.userid || user?.userId;
    }
    // if (id) {
    dispatch(ArtefactActions.getArtefacts(payload));
    // }
  };
  const dispatchArtefactTypesApiHandler = (id: number) => {
    const payload = {
      pid: id
    };

    // if (id) {
    dispatch(ArtefactTypesActions.getArtefactTypes(payload));
    // }
  };

  const dispathRolesApiHandler = (id: number) => {
    const rolesPayload = {
      programId: id
    };
    // if (id) {
    dispatch(UserRolesProgramActions.getUserRolesProgram(rolesPayload));
    // }
  };

  return (
    <>
      <View
        style={
          window.location.pathname.includes(routeNames.PROFILE)
            ? [styles.mainContainer, styles.mainContainerAlignment]
            : styles.mainContainer
        }
      >
        <View>
          <Icon
            testID="close"
            name={IconNames.backToWorkspace}
            customStyle={styles.iconStyle}
            onPress={() => history.goBack()}
          />
        </View>
        {window.location.pathname.includes(routeNames.PROFILE) ? (
          <Text
            fontWeight={FontWeight.Bold}
            testId="headerText"
            textSize={TextSize.Small}
            textStyle={[styles.headerText, styles.headerFontSize]}
          >
            {"Profile"}
          </Text>
        ) : (
          <>
            {loading ? (
              <View style={styles.loadingContainer}>
                <Text
                  fontWeight={FontWeight.Bold}
                  testId="headerText"
                  textSize={TextSize.Small}
                  textStyle={[styles.headerText, styles.headerFontSize]}
                >
                  {"Loading Program Names . . ."}
                </Text>
              </View>
            ) : (
              <Dropdown
                open={isDropdownOpen}
                dropdownValue={val}
                items={items}
                setOpen={setIsDropdownOpen}
                setValue={setVal}
                setItems={setItems}
                onChangeValue={onChangeValue}
                containerWidthStyle={
                  containerWidth >= 32
                    ? [
                        styles.containerStyle,
                        { width: `${containerWidth + 10}ch`, maxWidth: "100ch" }
                      ]
                    : styles.containerStyle
                }
                containerRadius={styles.dropDownContainerStyle}
                pickerStyle={styles.alignTextHeader}
                labelStyles={styles.labelStyles}
                placeholderStyle={styles.placeholderStyle}
                customItemContainerStyle={styles.customItemContainerStyle}
                listItems={styles.listItems}
                listItemLabelStyle={styles.listItemLabelStyle}
                arrowIconStyle={styles.arrowIconStyle}
                closeAfterSelecting={true}
              />
            )}
          </>
        )}
      </View>
    </>
  );
}

const styles = StyleSheet.create({
  loadingContainer: {},
  mainContainer: {
    zIndex: 1,
    flexDirection: "row",
    padding: 5,
    alignItems: "center"
    // paddingBottom: 5
  },
  mainContainerAlignment: {
    paddingBottom: 5
  },
  iconStyle: {
    fontSize: 18,
    marginRight: 30
  },
  headerText: {
    fontSize: 12,
    fontFamily: FontFamily.Medium,
    lineHeight: 17
  },
  headerFontSize: {
    fontSize: 14
  },
  containerStyle: {
    marginBottom: 0,
    display: "flex",
    width: "37ch",
    alignItems: "center",
    height: 20
  },
  dropDownContainerStyle: {
    borderWidth: 0,
    borderTopRightRadius: 5,
    borderBottomRightRadius: 5,
    justifyContent: "center",
    marginBottom: 0
  },
  alignTextHeader: {
    paddingTop: 5,
    marginLeft: 0,
    marginRight: 0,
    padding: 5
  },
  labelStyles: {
    paddingLeft: 0,
    fontFamily: FontFamily.Bold,
    fontWeight: FontWeight.Bold
  },
  placeholderStyle: {},
  customItemContainerStyle: {
    padding: 5
  },
  listItems: {
    padding: 5
  },
  listItemLabelStyle: {
    fontFamily: FontFamily.Bold,
    fontWeight: FontWeight.Bold
  },
  arrowIconStyle: {
    width: 25,
    height: 19,
    marginLeft: 5
  }
});
