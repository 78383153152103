import React, { ReactElement } from "react";
import { StyleSheet } from "react-native";
import { Route, BrowserRouter as Router, Switch } from "react-router-dom";
import ScrollToTop from "@socion-cordio/common/src/utils/scrollToTop";
import { allRoutesNames as routeNames } from "navigation/allRouteNames";
import AuthenticatedRoute from "navigation/authenticatedRoute";
import "navigation/mainRouter.scss";
import PublicRouter, { publicRoutes } from "navigation/publicRouter";
import { ToastContainer } from "react-toastify";
import ArtefactsPrivateRouter from "./artefactsPrivateRouter";
interface Props {
  onLanguageChange?: Function;
}
const MainRouter = ({ onLanguageChange }: Props): ReactElement => {
  return (
    <React.Fragment>
      <ToastContainer />
      <div className="router_outlet">
        <Router>
          <ScrollToTop />
          <Switch>
            <AuthenticatedRoute path={routeNames.app} component={ArtefactsPrivateRouter} />
            <PublicRouter />
          </Switch>
        </Router>
      </div>
    </React.Fragment>
  );
};
export default MainRouter;
const styles = StyleSheet.create({
  container: {
    flexDirection: "column",
    width: 400,
    justifyContent: "space-between"
  },
  button: {
    height: 40,
    width: 100
  }
});
