import React, { ReactElement, useState, useEffect, useRef } from "react";
import { StyleSheet, View, TouchableOpacity, ScrollView } from "react-native";
import { toast, ToastContainer } from "react-toastify";
import Moment from "moment";
import { colorPallete } from "@socion-cordio/common/src/assets/styles/colors";
import {
  Text,
  TextSize,
  FontWeight,
  FontFamily
} from "@socion-cordio/common/src/components/atoms/text";
import {
  artefactEndPoints,
  topNavbarEndPoints
} from "@socion-cordio/common/src/repositories/endPoints";
import Loader from "@socion-cordio/common/src/components/atoms/loader";
import { ApiClient } from "@socion-cordio/common/src/network/apiClient";
import { HTTP_STATUS_CODES } from "@socion-cordio/common/src/network/constants";
import Icon, { IconNames } from "@socion-cordio/common/src/components/atoms/icon";
import SocionModal from "@socion-cordio/common/src/components/atoms/modal";
import ConfirmationPopupModal from "@socion-cordio/common/src/components/organisms/confirmationPopupModal";
import { ArtefactRepository } from "@socion-cordio/common/src/repositories/artefact/artefact";
import { ArtefactApiClient } from "@socion-cordio/common/src/network/artefactApiClient";
import { NotificationsActions } from "@socion-cordio/common/src/modules/notifications/actions/action";
import { useDispatch, useSelector } from "react-redux";
import { getUserId } from "@socion-cordio/web/src/utils/artefactUserHelper";
import ClearArtefactNotificationPopupModal from "../clearArtefactNotificationPopupModal";
import { orderBy } from "lodash";

interface Props {
  onClose?: Function;
}

export default function ArtefactNotificationModal(props: Props): ReactElement {
  var groups: any = [];
  var pageValue: any = 0;
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [notificationData, setNotificationData] = useState(null);
  const [notificationList, setNotificationList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [loaders, setLoader] = useState(false);
  const collapseRef = useRef(false);
  const scrollRef: any = useRef();
  const dispatch = useDispatch();
  const {
    notificationsList: notifications,
    loading: { getNotifications: loading },
    error: { getNotifications: notificationsError }
  } = useSelector((state: any) => state.notifications);

  useEffect(() => {
    if (notificationsError) {
      setIsLoading(false);
      setNotificationData([]);
    }
  }, [notificationsError]);

  useEffect(() => {
    setIsLoading(loading);
  }, [loading]);

  useEffect(() => {
    notificationsListHandler();
  }, [notifications]);

  const notificationsListHandler = () => {
    console.log("from data : ", notifications);
    setNotificationData(notifications);
    setLoader(false);
  };

  const deleteNotification = async (notificationId: string) => {
    console.log("notif id : ", notificationId);
    let deleteResponse = await ArtefactApiClient.delete(
      artefactEndPoints.deleteNotification(notificationId)
    );
    if (deleteResponse.code === HTTP_STATUS_CODES.ok) {
      toast.success("Notification deleted successfully");
      getNotificationDetails();
    } else {
      toast.error(deleteResponse.message);
    }
  };

  const readNotification = async (notificationId: string) => {
    console.log("read notification ----- ", notificationList);
    let readResponse = await ArtefactApiClient.put(
      artefactEndPoints.readNotification(notificationId),
      {}
    );
    if (readResponse.code === HTTP_STATUS_CODES.ok) {
      //toast.success("Notification read successfully");
    } else {
      toast.error("Error is reading notification");
    }
  };

  const getNotificationDetails = () => {
    setLoader(true);
    console.log("dispatch from notif component");
    dispatch(NotificationsActions.getNotifications());
  };

  function extract() {
    console.log("data------", notificationData);
    let list: any = [];
    notificationData.forEach(function (val: any) {
      var date = val.date_time != null && val.date_time.split(" ")[0];
      var dateValue = 0;
      groups.map((item: any, index: number) => {
        if (item.date === date) {
          item.array.push(val);
          dateValue = 1;
        }
      });
      if (dateValue === 0) {
        groups.push({ date: date, array: [val] });
      }
    });
    console.log("groups :", groups);
    list.push(groups);
    console.log("liststststtsttst", list[0]);

    const notifications = orderBy(list[0], "date", "desc");
    console.log("daatattat", notifications);
    setNotificationList([notifications]);
    // setNotificationList(list);
  }

  useEffect(() => {
    if (notificationData) extract();
  }, [notificationData]);

  return (
    <>
      <ToastContainer />
      {loaders ? (
        <Loader customLoadingContainer={styles.modalLoader} />
      ) : (
        <>
          <View style={styles.header}>
            <View style={styles.notificationrHeaderText}>
              <Text
                fontWeight={FontWeight.Bold}
                testId="headerText"
                textSize={TextSize.Small}
                textStyle={styles.miniContainerHeaderText}
              >
                {"Notifications"}
              </Text>
            </View>
            <View style={styles.header}>
              <Icon
                testID="close"
                name={IconNames.crossCircle}
                customStyle={styles.crossIcon}
                onPress={() => props.onClose()}
              />
            </View>
          </View>
          <ScrollView
            // style={styles.scrollable}
            showsVerticalScrollIndicator={false}
            ref={scrollRef}
            onScrollBeginDrag={() => (collapseRef.current = false)}
            // onScroll={(e) => handleScroll(e)}
            scrollEventThrottle={1}
          >
            {notificationList.map((prop: any, indexValue: number) => {
              return prop.map((notification: any, key: number) => {
                return (
                  <View style={loading && { paddingBottom: 20 }} key={indexValue + key + "noti"}>
                    <View style={styles.header} key={key + indexValue + "header" + 1}>
                      {((notificationList &&
                        notificationList.length >= 2 &&
                        notificationList[notificationList.length - 2][
                          notificationList[notificationList.length - 2].length - 1
                        ].date !== notification.date) ||
                        indexValue === 0) && (
                        <View>
                          <Text
                            fontWeight={FontWeight.Light}
                            testId="text"
                            textSize={TextSize.Small}
                            textStyle={[styles.containerHeaderText, styles.dateTime]}
                          >
                            {new Date(notification.date) === new Date()
                              ? "Today"
                              : new Date(notification.date) === new Date(Date.now() - 864e5)
                              ? "Yesterday"
                              : Moment(notification.date).format("DD MMM YY")}
                          </Text>
                        </View>
                      )}
                      <View style={styles.header}>
                        {notificationList[0][0].date === notification.date && (
                          <View style={styles.header}>
                            <Text
                              fontWeight={FontWeight.Light}
                              testId="ClearAllText"
                              textSize={TextSize.Small}
                              textStyle={[styles.clearText, styles.containerHeaderText]}
                              onPress={() => setConfirmationModal(true)}
                            >
                              {"Clear all"}
                            </Text>
                          </View>
                        )}
                      </View>
                    </View>
                    {notification.array.map((value: any, index: number) => {
                      return (
                        <>
                          <TouchableOpacity
                            key={index + "__value"}
                            onPress={() => {
                              if (value.is_read === false) {
                                readNotification(value.id);
                                let tempNotificationList = notificationList.slice();
                                console.log("temp : ", tempNotificationList);
                                tempNotificationList[indexValue][key].array[index].is_read = true;
                                console.log("temp lis after update : ", tempNotificationList);
                                // const notifications = orderBy(tempNotificationList[0], "date", "desc");
                                // console.log("daatattat", notifications);
                                // setNotificationList([notifications]);
                                setNotificationList(tempNotificationList);
                              }
                            }}
                          >
                            <View key={key + "subcon" + key + 1} style={styles.subContainer}>
                              <View
                                style={
                                  value.is_read
                                    ? [styles.miniContainer, styles.miniContainerReadBorder]
                                    : [styles.miniContainer, styles.miniContainerBorder]
                                }
                              >
                                <Icon
                                  testID="trainee"
                                  name={IconNames.trainee}
                                  customStyle={[styles.alignContainerButton]}
                                />
                                <View style={styles.descriptionContainer}>
                                  <Text
                                    fontWeight={FontWeight.Regular}
                                    testId="headerText"
                                    textSize={TextSize.Small}
                                    textStyle={[styles.headerText]}
                                  >
                                    {value.description}
                                  </Text>
                                  <View style={styles.timeContainer}>
                                    <Text
                                      fontWeight={FontWeight.Light}
                                      testId="timeText"
                                      textSize={TextSize.Small}
                                      textStyle={styles.headerText}
                                    >
                                      {new Date(value.date_time)
                                        .toLocaleTimeString("en-US", {
                                          hour: "numeric",
                                          minute: "numeric"
                                        })
                                        .toLowerCase()}
                                    </Text>
                                  </View>
                                </View>
                                <View style={styles.miniIconContainer}>
                                  <TouchableOpacity onPress={() => deleteNotification(value.id)}>
                                    <Icon
                                      testID="delete"
                                      name={IconNames.deleteFile}
                                      customStyle={[styles.alignContainerButton]}
                                    />
                                  </TouchableOpacity>
                                </View>
                              </View>
                            </View>
                          </TouchableOpacity>
                        </>
                      );
                    })}
                  </View>
                );
              });
            })}

            {/* {loading && <Loader customLoadingContainer={styles.loader} />} */}
            {confirmationModal && (
              <View>
                <SocionModal
                  modalVisible={confirmationModal}
                  setModalVisible={() => setConfirmationModal(!confirmationModal)}
                  component={
                    <ClearArtefactNotificationPopupModal
                      onClose={(value: any) => {
                        setNotificationData([]);
                        setConfirmationModal(false);
                      }}
                    />
                  }
                />
              </View>
            )}
          </ScrollView>
        </>
      )}
    </>
  );
}

const styles = StyleSheet.create({
  scrollable: {
    width: 5
  },
  dateTime: {
    marginLeft: 205,
    marginBottom: 10
  },
  button: {
    width: "140px",
    height: "50px",
    borderRadius: 10
  },
  buttonFont: {
    fontSize: 12
  },
  timeContainer: {
    marginTop: 6
  },
  clearText: {
    marginLeft: 320,
    textDecorationLine: "underline",
    marginRight: 18,
    marginBottom: 20
  },
  descriptionContainer: {
    width: 325,
    color: colorPallete.textBlack,
    fontSize: 12,
    fontFamily: FontFamily.Medium,
    lineHeight: 20
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row"
  },
  subContainer: {
    flexDirection: "row",
    marginBottom: 25,
    width: "680px"
  },
  crossIcon: {
    fontSize: 15,
    marginBottom: 22,
    marginTop: 22,
    marginLeft: 350,
    marginRight: 20
  },
  headerText: {
    color: colorPallete.textLight,
    fontSize: 12,
    fontFamily: FontFamily.Regular,
    lineHeight: 17
  },
  miniContainer: {
    padding: 20,
    marginLeft: 205,
    width: "439px",
    borderStyle: "solid",
    borderWidth: 1,
    borderRadius: 10,
    flexDirection: "row",
    justifyContent: "space-between"
  },
  miniContainerReadBorder: {
    borderColor: colorPallete.cordioTaupe
  },
  miniContainerBorder: {
    borderColor: colorPallete.cordioOrange
  },
  miniContainerHeaderText: {
    color: colorPallete.textBlack,
    fontSize: 14,
    fontFamily: FontFamily.Medium,
    lineHeight: 20
  },
  miniIconContainer: {
    flexDirection: "row"
  },
  alignContainerButton: {
    fontSize: 18,
    color: colorPallete.cordioTaupe,
    marginRight: 5
  },
  notificationrHeaderText: {
    marginTop: 22,
    marginLeft: 240
  },
  containerHeaderText: {
    color: colorPallete.textBlack,
    fontSize: 12,
    fontFamily: FontFamily.Regular,
    lineHeight: 20
  },
  loader: {
    height: 50,
    width: 50,
    marginLeft: 200
  },
  dateContainer: {
    marginRight: 435
  },
  modalLoader: {
    height: 100,
    width: 100,
    marginTop: 280,
    marginRight: 200
  }
});
