import React, { ReactElement, useEffect, useState, useMemo } from "react";
import { View, StyleSheet, TouchableOpacity } from "react-native";
import {
  Text,
  TextSize,
  FontWeight,
  FontFamily
} from "@socion-cordio/common/src/components/atoms/text";
import Table from "@socion-cordio/web/src/components/molecules/table/table";
import { colorPallete } from "@socion-cordio/common/src/assets/styles/colors";
import { sessionPackEndPoints } from "@socion-cordio/common/src/repositories/endPoints";
import { ApiClient } from "@socion-cordio/common/src/network/apiClient";
import Loader from "@socion-cordio/common/src/components/atoms/loader";
import Icon, { IconNames } from "@socion-cordio/common/src/components/atoms/icon";
import jsonToCsv from "@socion-cordio/common/src/utils/jsonToCsvDownload";
import { useLocation, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

interface Props {
  sessionDetails?: any;
}

export default function CompletedSessionTrainerList(props: Props): ReactElement {
  const { t } = useTranslation();
  const { sessionDetails } = props;
  const [loadingState, setLoadingState] = useState(true);
  const [failed, setFailed] = useState(false);
  const [sessionParticipantList, setSessionParticipantList] = useState(null);
  const [sessionParticipantData, setSessionParticipantData] = useState(null);
  const location: any = useLocation();

  useEffect(() => {
    if (sessionDetails !== null) {
      getSessionParticipantDetails();
    }
  }, [sessionDetails]);

  const getSessionParticipantDetails = async () => {
    try {
      const topicId = sessionDetails?.topicInfo?.topic?.id;
      const newBody = {
        topicIds: [topicId],
        userType: "TRAINER"
      };
      const response = await ApiClient.post(
        sessionPackEndPoints.getSessionParticipationList(),
        newBody
      );
      setSessionParticipantData(response?.response);
      const list = response?.response[0]?.topicData[topicId];
      list.forEach((session: any, index: number) => {
        session.serialNo = `${index + 1}.`;
      });
      setSessionParticipantList(list);
      setLoadingState(false);
    } catch (error) {
      setLoadingState(false);
      setFailed(true);
    }
  };

  const downloadCsvList = (data: any) => {
    const header = {
      srNo: "Sr.No",
      name: "Name",
      phoneNumber: "Phone Number",
      email: "Email",
      location: "Location",
      establishment: "Establishment"
    };
    const tableData = data.map((element: any) => ({
      srNo: element?.serialNo,
      name: element?.name,
      phoneNumber: element?.phoneNumber,
      email: element?.emailId ? element?.emailId : "None",
      location: element?.city,
      establishment: element?.establishmentName ? element?.establishmentName : "None"
    }));
    jsonToCsv(header, tableData, sessionDetails?.sessionName + "_Participant_Trainer_List");
  };

  const cellData = (testId: string, style: any, data: string, onPress?: Function) => (
    <Text
      fontWeight={FontWeight.Bold}
      testId={testId}
      textSize={TextSize.Small}
      style={style}
      onPress={onPress ? () => onPress() : null}
    >
      {data}
    </Text>
  );

  const formatLocation = (data: any) => {
    let formattedLocation: string = "";
    // city, subdist, district, state, country
    if (data?.city) {
      formattedLocation += `${data?.city}, `;
    }
    if (data?.subDistrict) {
      formattedLocation += `${data?.subDistrict}, `;
    }
    if (data?.district) {
      formattedLocation += `${data?.district}, `;
    }
    if (data?.state) {
      formattedLocation += `${data?.state}, `;
    }
    if (data?.country) {
      formattedLocation += `${data?.country} `;
    }
    if (formattedLocation === "") {
      return (formattedLocation = "None");
    }
    return formattedLocation;
  };

  const columns = useMemo(
    () => [
      {
        Header: "Sr.No",
        accessor: "serialNo",
        width: 15,
        maxWidth: 15,
        disableSortBy: true,
        Cell: ({ row: { original: session } }: { row: { original: any } }) => {
          const style = {
            fontSize: 10,
            fontFamily: FontFamily.Medium,
            fontWeight: FontFamily.Bold,
            textSize: TextSize.Small,
            color: colorPallete.textBlack
          };
          return cellData("serialNo", style, session?.serialNo);
        }
      },

      {
        Header: "Name",
        accessor: "name",
        width: 35,
        maxWidth: 35,
        Cell: ({ row: { original: session } }: { row: { original: any } }) => {
          const style = {
            fontSize: 10,
            fontFamily: FontFamily.Medium,
            fontWeight: FontFamily.Bold,
            textSize: TextSize.Small,
            color: colorPallete.textBlack
          };
          // const onPress = () => {
          //   viewSessionDetailsHandler(session);
          // };
          return cellData("name", style, session?.name);
        }
      },
      {
        Header: "Phone",
        accessor: "phone",
        width: 35,
        maxWidth: 35,
        Cell: ({ row: { original: session } }: { row: { original: any } }) => {
          const style = {
            fontSize: 10,
            fontFamily: FontFamily.Regular,
            fontWeight: FontFamily.Light,
            textSize: TextSize.Small,
            color: colorPallete.textBlack
          };
          return cellData("phone", style, session?.phoneNumber);
        }
      },
      {
        Header: "Email",
        accessor: "email",
        width: 50,
        maxWidth: 50,
        Cell: ({ row: { original: session } }: { row: { original: any } }) => {
          const style = {
            fontSize: 10,
            fontFamily: FontFamily.Regular,
            fontWeight: FontFamily.Light,
            textSize: TextSize.Small,
            color: colorPallete.textBlack
          };
          const email = session?.emailId ? session?.emailId : "None";
          return cellData("email", style, email);
        }
      },
      {
        Header: "Location",
        accessor: "location",
        width: 40,
        maxWidth: 40,
        Cell: ({ row: { original: session } }: { row: { original: any } }) => {
          const style = {
            fontSize: 10,
            fontFamily: FontFamily.Regular,
            fontWeight: FontFamily.Light,
            textSize: TextSize.Small,
            color: colorPallete.textBlack
          };
          const location: string = formatLocation(session);
          return cellData("location", style, location);
        }
      },
      {
        Header: "Establishment",
        accessor: "establishment",
        width: 35,
        maxWidth: 35,
        disableSortBy: true,
        Cell: ({ row: { original: session } }: { row: { original: any } }) => {
          const style = {
            fontSize: 10,
            fontFamily: FontFamily.Regular,
            fontWeight: FontFamily.Light,
            textSize: TextSize.Small,
            color: colorPallete.textBlack
          };
          const establishment = session?.establishmentName ? session?.establishmentName : "None";
          return cellData("establishment", style, establishment);
        }
      }
    ],
    []
  );

  return (
    <View>
      {loadingState ? (
        <Loader />
      ) : failed ? (
        <View>Failed to load data</View>
      ) : (
        <View>
          <Table
            columns={columns}
            data={sessionParticipantList}
            download={
              <TouchableOpacity onPress={() => downloadCsvList(sessionParticipantList)}>
                <View style={{ flexDirection: "row" }}>
                  <Icon
                    name={IconNames.downloadList}
                    customStyle={styles.iconStyle}
                    onPress={() => downloadCsvList(sessionParticipantList)}
                  />
                  <View>
                    <Text
                      testId="testId"
                      fontWeight={FontWeight.Bold}
                      textSize={TextSize.Small}
                      style={[
                        styles.textAlignment,
                        {
                          fontFamily: FontFamily.Regular,
                          fontSize: 12
                        }
                      ]}
                    >
                      {t("table:download")}
                    </Text>
                  </View>
                </View>
              </TouchableOpacity>
            }
          />
        </View>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  roleName: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center"
  },
  image: {
    width: 25,
    height: 25,
    borderRadius: 25 / 2,
    marginEnd: 10
  },
  status: {
    height: 8,
    width: 8,
    borderRadius: 50,
    marginRight: 8
  },
  active: {
    backgroundColor: colorPallete.cordioGreen
  },
  pending: {
    backgroundColor: colorPallete.cordioOrange
  },
  deactivated: {
    backgroundColor: colorPallete.cordioRedDark3
  },
  textAlign: {
    fontFamily: FontFamily.Regular,
    color: colorPallete.textBlack,
    fontSize: 10
  },
  statusContainer: {
    flexDirection: "row",
    alignItems: "center"
  },
  statusColor: {
    height: 8,
    width: 8,
    borderRadius: 50,
    marginRight: 8
  },
  approvedColor: {
    backgroundColor: colorPallete.cordioGreen
  },
  pendingColor: {
    backgroundColor: colorPallete.cordioOrange
  },
  declineColor: {
    backgroundColor: colorPallete.cordioRedDark3
  },
  iconStyle: {
    color: colorPallete.textBlack,
    fontSize: 17,
    marginRight: 10
  },
  textAlignment: {
    textDecorationLine: "underline"
  }
});
