import React, { ReactElement, useState, useEffect } from "react";
import { StyleSheet, View, Pressable } from "react-native";
import { colorPallete } from "@socion-cordio/common/src/assets/styles/colors";
import {
  Text,
  TextSize,
  FontWeight,
  FontFamily
} from "@socion-cordio/common/src/components/atoms/text";
import Icon, { IconNames } from "@socion-cordio/common/src/components/atoms/icon";
import Button, { ButtonType } from "@socion-cordio/common/src/components/atoms/button";
import { Otp } from "@socion-cordio/common/src/components/atoms/otp";
import { useDispatch, useSelector } from "react-redux";
import { profileEndPoints } from "@socion-cordio/common/src/repositories/endPoints";
import { ApiClient } from "@socion-cordio/common/src/network/apiClient";
import AesUtil from "@socion-cordio/common/src/utils/encryptionHelper";
import { HTTP_STATUS_CODES } from "@socion-cordio/common/src/network/constants";
import AddEmail from "@socion-cordio/common/src/components/organisms/addEmail";
import AddMobile from "@socion-cordio/common/src/components/organisms/addMobile";
import { LocalStorage } from "@socion-cordio/common/src/services/storage/storageService";
import { useHistory } from "react-router-dom";
import Moment from "moment";
import AddTelemetryService from "@socion-cordio/common/src/services/telemetryService";
import { toast } from "react-toastify";
interface Props {
  onClose: Function;
  showEmail?: boolean;
  modalTitle: string;
  headerText: string;
  newPhoneOtp?: boolean;
  sendOtpNewPhone?: Function;
  subHeaderText?: string;
  phoneNumberDetails?: any;
}

export default function OtpValidate(props: Props): ReactElement {
  const { subHeaderText = "Haven’t received a message?", phoneNumberDetails, showEmail } = props;
  const [otpHandle, setOtpHandle] = useState(false);
  const [enterEmailHandler, setEnterEmailHandler] = useState(false);
  const [enterMobileHandler, setEnterMobileHandler] = useState(false);
  const [mobileNumberVerified, setMobileNumberVerified] = useState(false);
  const [error, setError] = useState(false);
  const [incorrectOtpMsg, setIncorrectOtpMsg] = useState(null);
  const [otp, setOtp] = useState(null);
  const profileState = useSelector((state: any) => state.profile);
  const {
    userProfileData: { response }
  } = profileState;
  const history: any = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    if (props.showEmail) {
      sendEmailOtp();
    } else if (props.showEmail === false) {
      sendMobileOtp();
    }
  }, []);

  const sendMobileOtp = async () => {
    const sendOtpResponse = await ApiClient.get(
      profileEndPoints.sendOtp(response.phoneNumber, "UpdatePhone-OTP", response.countryCode)
    );
    sendOtpResponse;
  };

  const sendEmailOtp = async () => {
    const sendOtpResponse = await ApiClient.get(
      profileEndPoints.sendOtp(response.phoneNumber, "UpdateEmail-OTP", response.countryCode)
    );
    if (sendOtpResponse.responseCode === 200) {
      // toast.success("OTP sent successfully...");
    } else if (sendOtpResponse.responseCode === 509) {
      toast.error("You have exceeded your OTP limit. Try after one day");
    } else {
      toast.error("Something went wrong...!!!");
    }
    console.log(sendOtpResponse);
  };

  const resendOtp = () => {
    if (props.showEmail) {
      sendEmailOtp();
    } else if (props.showEmail === false) {
      sendMobileOtp();
    } else if (props.sendOtpNewPhone) {
      props.sendOtpNewPhone();
    }
  };

  const validateMobileOtp = async () => {
    const typeOfOTP = props.newPhoneOtp ? "NewPhone-OTP" : "UpdatePhone-OTP";
    const aesUtil: AesUtil = new AesUtil();
    const userCurrentPhoneNumber =
      typeOfOTP === "NewPhone-OTP"
        ? response.phoneNumber
        : showEmail === false
        ? response.phoneNumber
        : phoneNumberDetails.mobileNumber;
    const payload = {
      phoneNumber: userCurrentPhoneNumber,
      otp: aesUtil.encrypt(otp.toString()),
      typeOfOTP: typeOfOTP,
      countryCode: showEmail === false ? response.countryCode : `+${phoneNumberDetails.countryCode}`
    };
    const validateOtpResponse = await ApiClient.post(profileEndPoints.validateOtp(), payload);
    if (validateOtpResponse.responseCode === HTTP_STATUS_CODES.ok) {
      if (props.newPhoneOtp) {
        updateTelemetryPhoneChange();
        setMobileNumberVerified(true);
        setOtpHandle(true);
        setEnterMobileHandler(false);
        setEnterEmailHandler(false);
        toast.success(
          "Your Phone Number has been changed successfully. You will be prompted to login with your new Phone Number."
        );
      } else {
        setOtpHandle(true);
        setEnterMobileHandler(true);
        setEnterEmailHandler(false);
      }
    } else {
      setIncorrectOtpMsg(validateOtpResponse?.message);
    }
  };

  const updateTelemetryPhoneChange = () => {
    const body = {
      createdAt: Moment(),
      deleted: "false",
      updatedAt: Moment(),
      eventType: "Profile edit-Phone Number change"
    };
    const userDetails = response;
    AddTelemetryService(body, undefined, userDetails);
  };

  const handleOtp = (otp: string) => {
    let isValidOtp = /^\d+$/.test(otp);
    if (isValidOtp) {
      setError(false);
    } else {
      setError(true);
    }
    setOtp(otp);
    setIncorrectOtpMsg(null);
  };

  const validateEmailOtp = async () => {
    const aesUtil: AesUtil = new AesUtil();
    const payload = {
      phoneNumber: response.phoneNumber,
      otp: aesUtil.encrypt(otp.toString()),
      typeOfOTP: "UpdateEmail-OTP",
      countryCode: response.countryCode
    };
    const validateOtpResponse = await ApiClient.post(profileEndPoints.validateOtp(), payload);
    if (validateOtpResponse.responseCode === HTTP_STATUS_CODES.ok) {
      setOtpHandle(true);
      setEnterEmailHandler(true);
      setEnterMobileHandler(false);
      updateTelemetryEmail();
    } else {
      setIncorrectOtpMsg(validateOtpResponse?.message);
    }
  };

  const updateTelemetryEmail = async () => {
    const user: any = await LocalStorage.getStorage("user");
    const body = {
      createdAt: Moment(),
      deleted: "false",
      updatedAt: Moment(),
      eventType: "Profile edit-Email ID change",
      establishmentName: user.establishmentName
    };
    const userDetails = user;
    AddTelemetryService(body, undefined, userDetails);
  };

  const handleLogout = () => {
    setTimeout(() => {
      dispatch({ type: "USER_LOGOUT" });
      history.push(allRoutesNames.IAM.root + allRoutesNames.IAM.login);
      LocalStorage.removeStoredKeys();
    }, 1000);
  };

  return (
    <View>
      {!otpHandle && (
        <View>
          <View style={styles.modalHeader}>
            <Text
              fontWeight={FontWeight.Bold}
              testId="addRoleText"
              textSize={TextSize.Small}
              textStyle={styles.headerText}
            >
              {props.modalTitle}
            </Text>
            <View>
              <Pressable onPress={() => props.onClose()}>
                <Icon testID="close" name={IconNames.crossCircle} />
              </Pressable>
            </View>
          </View>
          <View style={styles.headerContainer}>
            <Text
              fontWeight={FontWeight.Regular}
              testId="addRoleTitleText"
              textSize={TextSize.Small}
              textStyle={styles.subheaderText}
            >
              {props.headerText}
            </Text>
          </View>
          <View style={styles.otpContainer}>
            <Otp
              id="otp"
              name="otp"
              value={otp}
              handleTextChange={(otp: string) => handleOtp(otp)}
              noFormik
            />
          </View>
          <View style={styles.requiredMessageContainer}>
            {error && (
              <Text
                testId="otpError"
                textSize={TextSize.Small}
                fontWeight={FontWeight.Regular}
                textStyle={styles.error}
              >
                Invalid characters
              </Text>
            )}
            {!error && incorrectOtpMsg && (
              <Text
                testId="otpError"
                textSize={TextSize.Small}
                fontWeight={FontWeight.Regular}
                textStyle={styles.error}
              >
                {incorrectOtpMsg}
              </Text>
            )}
          </View>
          <View style={styles.resendContainer}>
            <Text
              fontWeight={FontWeight.Regular}
              testId="addRoleTitleText"
              textSize={TextSize.Small}
              textStyle={[styles.subheaderText, styles.subheaderResendText]}
            >
              {subHeaderText}
            </Text>
            <Text
              fontWeight={FontWeight.Regular}
              testId="addRoleTitleText"
              textSize={TextSize.Small}
              textStyle={[styles.subheaderText, styles.resendText]}
              onPress={resendOtp}
            >
              Resend OTP
            </Text>
          </View>
          <View style={styles.submitButtonContainer}>
            <Button
              type={ButtonType.Primary}
              buttonStyles={styles.submitbutton}
              title="Verify and Proceed"
              onPress={props.showEmail ? validateEmailOtp : validateMobileOtp}
              disabled={error || otp?.length !== 6}
            />
          </View>
        </View>
      )}
      {otpHandle && enterEmailHandler && (
        <AddEmail onClose={props.onClose} modalTitle={props.modalTitle} />
      )}
      {otpHandle && enterMobileHandler && <AddMobile onClose={props.onClose} />}
      {otpHandle && mobileNumberVerified && (
        <View>
          <View style={styles.modalHeader}>
            <Text
              fontWeight={FontWeight.Bold}
              testId="addRoleText"
              textSize={TextSize.Small}
              textStyle={styles.headerText}
            >
              {props.modalTitle}
            </Text>
            <View>
              <Pressable
                onPress={() => {
                  handleLogout();
                }}
              >
                <Icon testID="close" name={IconNames.crossCircle} />
              </Pressable>
            </View>
          </View>
          <View>Mobile number verified successfully</View>
        </View>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  modalHeader: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row",
    marginBottom: 15
  },
  headerText: {
    fontWeight: "700",
    fontSize: 14,
    fontFamily: FontFamily.Medium,
    lineHeight: 17
  },
  subheaderText: {
    fontWeight: "400",
    fontSize: 14,
    fontFamily: FontFamily.Regular,
    lineHeight: 17,
    color: colorPallete.textBlack,
    marginTop: 13,
    flex: 20
  },
  headerContainer: {
    alignItems: "center"
  },
  requiredMessageContainer: {
    alignSelf: "center"
  },
  otpContainer: {
    marginTop: 5
  },
  submitButtonContainer: {
    alignItems: "center",
    marginTop: 30,
    marginBottom: 10
  },
  resendContainer: {
    alignItems: "center"
  },
  subheaderResendText: {
    marginTop: 20
  },
  resendText: {
    color: "#B7504A",
    textDecorationLine: "underline",
    marginTop: 3
  },
  submitbutton: {
    width: "175px",
    height: "50px",
    borderRadius: 10,
    marginLeft: 10,
    marginRight: 10
  },
  error: {
    position: "relative",
    color: "red",
    alignSelf: "flex-start",
    fontSize: 10,
    fontWeight: "400",
    zIndex: -1
  }
});
