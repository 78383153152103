import React, { useEffect, useState } from "react";
import { View, StyleSheet, TouchableOpacity } from "react-native";
import Table from "@socion-cordio/web/src/components/molecules/table/table";
import {
  Text,
  TextSize,
  FontWeight,
  FontFamily
} from "@socion-cordio/common/src/components/atoms/text";
import { dataHelper } from "@socion-cordio/common/src/utils/dataHelper";
import { colorPallete } from "@socion-cordio/common/src/assets/styles/colors";
import Loader from "@socion-cordio/common/src/components/atoms/loader";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import SearchFilterSeondary from "@socion-cordio/common/src/components/molecules/searchFilterSecondary";
import { isEmpty, orderBy } from "lodash";
import { allRoutesNames as routeNames } from "navigation/allRouteNames";
import { ArtefactRepository } from "@socion-cordio/common/src/repositories/artefact/artefact";
import { ArtefactTypesActions } from "@socion-cordio/common/src/modules/artefactTypes/actions/action";
import { Image } from "@socion-cordio/common/src/components/atoms/image";

const ArtefactTypes = () => {
  const programData: any = JSON.parse(localStorage.getItem("programDetails"));
  const [updatedArtefactTypesList, setUpdatedArtefactTypesList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const history = useHistory();
  const dispatch = useDispatch();

  const {
    artefactTypesList: artefactTypes,
    loading: { getArtefactTypes: loading },
    error: { getArtefactTypes: artefactTypesError }
  } = useSelector((state: any) => state.artefactTypes);

  console.log("artefactTypes : ", artefactTypes);

  useEffect(() => {
    dispatchArtefactTypesApiHandler(programData?.program.id);
  }, []);

  const dispatchArtefactTypesApiHandler = (id: number) => {
    const payload = {
      pid: id
    };
    dispatch(ArtefactTypesActions.getArtefactTypes(payload));
  };

  useEffect(() => {
    if (artefactTypesError) {
      setIsLoading(false);
      setUpdatedArtefactTypesList([]);
    }
  }, [artefactTypesError]);

  useEffect(() => {
    setIsLoading(loading);
  }, [loading]);

  useEffect(() => {
    artefactTypesListHandler();
  }, [artefactTypes]);

  const artefactTypesListHandler = () => {
    if (artefactTypes !== null) {
      //Note
      const sortedList = sortArtefactTypesListHandler(artefactTypes);
      setUpdatedArtefactTypesList(sortedList);
      setIsLoading(false);
    }

    if (isEmpty(programData)) {
      setIsLoading(false);
    }
  };

  const sortArtefactTypesListHandler = (list: any[]) => {
    list = orderBy(list, ["created_at"], ["desc"]);
    let artefactTypes: any[] = list;
    artefactTypes.forEach((artefactType: any, index: number) => {
      artefactType.key = artefactType?.id;
      artefactType.serialNo = `${index + 1}.`;
      artefactType.displayStatus = artefactType.active === "0" ? "Inactive" : "Active";
      artefactType.displayCreatedDate = dataHelper.formatDateUtc(artefactType.created_at);
      artefactType.displayUpdateBy = artefactType.updated_by ? artefactType.updated_by : "NA";
      artefactType.displayUpdatedDate = artefactType.updated_at
        ? dataHelper.formatDateUtc(artefactType.updated_at)
        : "NA";
    });
    return artefactTypes;
  };

  let userRolesProgram: any = useSelector((state: any) => state?.userRolesProgram);
  const memberRole = userRolesProgram?.userRolesData?.response;

  const isSuperAdmin = JSON.parse(localStorage.getItem("isSuperAdmin"));
  const cellData = (testId: string, style: any, data: string, onPress?: Function) => (
    <Text
      fontWeight={FontWeight.Bold}
      testId={testId}
      textSize={TextSize.Small}
      style={[
        {
          fontSize: 12,
          fontFamily: FontFamily.Regular,
          fontWeight: FontFamily.Light,
          textSize: TextSize.Small,
          color: colorPallete.textBlack
        },
        style
      ]}
      onPress={onPress ? () => onPress() : null}
    >
      {data}
    </Text>
  );

  // const topicDetailsHandler = (topic: any) => {
  //   history.push(`${routeNames.app}${routeNames.TOPICS}${routeNames.TOPICDETAILS}`, {
  //     selectedTopicDetails: topic,
  //     topicId: topic.id,
  //     userRoles: memberRole,
  //     isSuperAdmin: isSuperAdmin
  //   });
  // };

  // const getAddEditArtefactTypes = () => {
  //   console.log('getAddEditArtefactTypes')
  //   history.push(`${routeNames.app}${routeNames.ADD_EDIT_ARTEFACT_TYPES}`);
  // };

  const columns = [
    {
      Header: "Sr.No",
      accessor: "serialNo",
      width: 42,
      maxWidth: 42,
      disableSortBy: true,
      Cell: ({ row: { original: artefactTypes } }: { row: { original: any } }) => {
        const style = {
          fontFamily: FontFamily.Regular,
          fontWeight: FontFamily.Light
        };
        return cellData("serialNo", style, artefactTypes.serialNo);
      }
    },
    {
      Header: "Artefact Type",
      accessor: "artefact_type_title",
      width: 300,
      maxWidth: 300,
      Cell: ({ row: { original: artefactTypes } }: { row: { original: any } }) => {
        const style = {
          fontFamily: FontFamily.Medium,
          fontWeight: FontFamily.Bold,
          textDecorationLine: "underline"
        };
        //const onPress = () => {
        // topicDetailsHandler(topic);
        // setSelectedTopic(topic.id);
        // setViewTopicDetails(true);
        // setTopicDetails(topic);
        // };
        // return cellData("artefact_type_title", style, artefactTypes.artefact_type_title);
        return (
          <TouchableOpacity
            onPress={() => {
              // history.push(
              //   `${routeNames.app}${routeNames.VIEW_ARTEFACT}/${artefact.artefact_meta_id}`
              // );
              history.push({
                pathname: `${routeNames.app}${routeNames.EDIT_ARTEFACT_TYPES}/${artefactTypes.id}`,
                // search: `?query=${artefact.artefact_meta_id}`,
                state: { id: artefactTypes.id }
              });
            }}
          >
            {cellData("artefact_type_title", style, artefactTypes.artefact_type_title)}
          </TouchableOpacity>
        );
      }
    },
    {
      Header: "Created By",
      accessor: "created_by",
      width: 103,
      maxWidth: 103,
      Cell: ({ row: { original: artefactTypes } }: { row: { original: any } }) => {
        const style = { fontFamily: FontFamily.Regular, fontWeight: FontFamily.Light };
        return cellData("created_by", style, artefactTypes.created_by);
      }
    },
    {
      Header: "Created Date",
      accessor: "displayCreatedDate",
      width: 110,
      maxWidth: 110,
      Cell: ({ row: { original: artefactTypes } }: { row: { original: any } }) => {
        const style = { fontFamily: FontFamily.Regular, fontWeight: FontFamily.Light };
        return cellData("displayCreatedDate", style, artefactTypes.displayCreatedDate);
      }
    },

    {
      Header: "Updated By",
      accessor: "displayUpdateBy",
      width: 103,
      maxWidth: 103,
      Cell: ({ row: { original: artefactTypes } }: { row: { original: any } }) => {
        const style = { fontFamily: FontFamily.Regular, fontWeight: FontFamily.Light };
        return cellData("displayUpdateBy", style, artefactTypes.displayUpdateBy);
      }
    },
    {
      Header: "Updated Date",
      accessor: "displayUpdatedDate",
      width: 110,
      maxWidth: 110,
      Cell: ({ row: { original: artefactTypes } }: { row: { original: any } }) => {
        const style = { fontFamily: FontFamily.Regular, fontWeight: FontFamily.Light };
        return cellData("displayUpdatedDate", style, artefactTypes.displayUpdatedDate);
      }
    },

    {
      Header: "No. of Artefacts",
      accessor: "artefactcount",
      width: 110,
      maxWidth: 110,
      Cell: ({ row: { original: artefactTypes } }: { row: { original: any } }) => {
        const style = { fontFamily: FontFamily.Regular, fontWeight: FontFamily.Light };
        return cellData("artefactcount", style, artefactTypes?.artefactcount);
      }
    },

    {
      Header: "Status",
      accessor: "displayStatus",
      width: 75,
      maxWidth: 75,
      Cell: ({ row: { original: artefactTypes } }: { row: { original: any } }) => (
        <Text
          fontWeight={FontWeight.Light}
          testId="status"
          textSize={TextSize.Small}
          style={{
            fontSize: 12,
            fontFamily: FontFamily.Regular,
            color: colorPallete.textBlack
          }}
        >
          <View
            style={[
              styles.status,
              artefactTypes.active === "1"
                ? styles.active
                : artefactTypes.active === "0"
                ? styles.inactive
                : null
            ]}
          />
          {artefactTypes.displayStatus}
        </Text>
      )
    }
  ];

  const getAddArtefactTypes = () => {
    console.log("getAddArtefactTypes");
    history.push(`${routeNames.app}${routeNames.ADD_ARTEFACT_TYPES}`);
  };
  const searchbarAndAddArtefactType = () => {
    return (
      <View style={styles.searchBarContainer}>
        <View style={styles.addArtefactIcon}>
          <TouchableOpacity onPress={() => [getAddArtefactTypes()]}>
            <Image
              testId="primaryAddImg"
              source={require("@socion-cordio/common/src/assets/images/primary_add.svg")}
              imageStyle={{
                width: 28,
                height: 28
              }}
            />
          </TouchableOpacity>
        </View>
      </View>
    );
  };

  return (
    <View style={styles.mainContainer}>
      <View style={styles.subContainer}>
        {loading ? (
          <Loader />
        ) : (
          <>
            <View style={styles.noDataContainer}>{searchbarAndAddArtefactType()}</View>
            {dataHelper.isEmptyArray(artefactTypes) ? (
              <View style={styles.noData}>
                <Text
                  fontWeight={FontWeight.Thin}
                  testId="headerText"
                  textSize={TextSize.Small}
                  textStyle={styles.headerText}
                >
                  {"No Artefact Types Present"}
                </Text>
              </View>
            ) : (
              <Table columns={columns} data={updatedArtefactTypesList} showSearchField={true} />
            )}
          </>
        )}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  mainContainer: {
    paddingBottom: 20,
    paddingHorizontal: 20,
    // paddingTop: 15,
    backgroundColor: colorPallete.cordioBeigeLight2,
    minHeight: "calc(100vh - 50px)"
  },
  subContainer: {
    backgroundColor: colorPallete.white,
    borderRadius: 10,
    shadowColor: colorPallete.cordioRedDark1,
    shadowOpacity: 0.1,
    shadowOffset: {
      height: 10,
      width: 5
    },
    shadowRadius: 10
  },
  noData: {
    height: "calc(100vh - 150px)",
    paddingHorizontal: 20,
    marginTop: 30,
    marginBottom: -30
  },
  noDataContainer: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "flex-end",
    position: "relative",
    zIndex: 999
  },
  status: {
    height: 8,
    width: 8,
    borderRadius: 50,
    marginRight: 8
  },
  active: {
    backgroundColor: colorPallete.cordioGreen
  },
  inactive: {
    backgroundColor: colorPallete.yellowOne
  },
  headerText: {
    fontSize: 14,
    fontFamily: FontFamily.Medium,
    lineHeight: 19.5,
    fontWeight: "700"
  },
  // Search bar disabled
  searchBarContainer: {
    position: "absolute",
    top: 25,
    right: 25
    // display: "flex",
    // flexDirection: "row",
    // justifyContent: "flex-end",
    // alignItems: "flex-end",
    // marginBottom: -25
  },
  customWidthStyle: {
    width: 400
  },
  customSearchBarHeight: {
    height: 40,
    width: 535,
    fontSize: 14
  },
  customSearchIconStyle: {
    fontSize: 16
  },
  customSearchBarContainerStyle: {
    borderRadius: 5
  },
  addArtefactIcon: {}
});

export default ArtefactTypes;
