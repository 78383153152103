import React, { ReactElement, useEffect, useState } from "react";
import { StyleSheet, View } from "react-native";
import { colorPallete } from "@socion-cordio/common/src/assets/styles/colors";
import {
  Text,
  TextSize,
  FontWeight,
  Label,
  FontFamily
} from "@socion-cordio/common/src/components/atoms/text";
import { useHistory } from "react-router-dom";

interface Props {
  component?: React.ReactNode;
  footerText?: string;
  footerTextType?: string;
  onPressFooterTextType?: Function;
}

export default function WrapperForm(props: Props): ReactElement {
  const { component, footerText, footerTextType, onPressFooterTextType } = props;
  const history: any = useHistory();

  return (
    <View>
      <View style={styles.mainContainer}>
        <View style={styles.formContainer}>
          <View style={{ width: "100%" }}>{component}</View>
          <View style={styles.footer}>
            <Text
              fontWeight={FontWeight.Regular}
              testId="footerText"
              textSize={TextSize.Small}
              textStyle={styles.footerText}
            >
              {footerText}
              <View>
                <Text
                  fontWeight={FontWeight.Regular}
                  testId="footerTextType"
                  textSize={TextSize.Small}
                  textStyle={styles.loginText}
                  onPress={() => onPressFooterTextType()}
                >
                  {footerTextType}
                </Text>
              </View>
            </Text>
          </View>
        </View>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  mainContainer: {
    display: "flex",
    alignItems: "center",
    marginTop: 15
  },
  formContainer: {
    width: 480,
    backgroundColor: colorPallete.cordioBeige,
    borderRadius: 20,
    alignItems: "center",
    borderColor: colorPallete.cordioRed,
    borderStyle: "solid",
    borderWidth: 1
  },
  footer: {
    marginVertical: 7
  },
  footerText: {
    fontWeight: "400",
    fontSize: 12,
    position: "relative",
    zIndex: -1
  },
  loginText: {
    fontWeight: "400",
    fontSize: 12,
    position: "relative",
    zIndex: -1,
    // textDecorationLine: "underline",
    color: colorPallete.cordioBlue2
  }
});
