import React, { useState, useEffect } from "react";
import { Pressable, StyleSheet, View } from "react-native";
import Icon, { IconNames } from "@socion-cordio/common/src/components/atoms/icon";
import { colorPallete } from "@socion-cordio/common/src/assets/styles/colors";
import {
  FontFamily,
  FontWeight,
  Text,
  TextSize
} from "@socion-cordio/common/src/components/atoms/text";
import { ApiClient } from "@socion-cordio/common/src/network/apiClient";
import { HTTP_STATUS_CODES } from "@socion-cordio/common/src/network/constants";
import { springshedEndPoints } from "@socion-cordio/common/src/repositories/endPoints";
import { toast, ToastContainer } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import TextField from "@socion-cordio/common/src/components/atoms/textField";
import Button, { ButtonType } from "@socion-cordio/common/src/components/atoms/button";
import { Form, Formik, FormikProps } from "formik";
import * as Yup from "yup";
import { ProgramActions } from "@socion-cordio/common/src/modules/program/actions/actions";
import FilePicker from "@socion-cordio/common/src/components/molecules/filePicker";
import DatePicker from "@socion-cordio/common/src/components/molecules/datePicker";
import TextArea from "@socion-cordio/common/src/components/atoms/textArea";
import AddTelemetryService from "@socion-cordio/common/src/services/telemetryService";
import { LocalStorage } from "@socion-cordio/common/src/services/storage/storageService";
import Loader from "@socion-cordio/common/src/components/atoms/loader";
import Moment from "moment";
import { ScrollContainer } from "@socion-cordio/common/src/components/organisms/scrollWrapper";

interface Props {
  onClose: Function;
  entity?: any;
  isProgramListRequired?: boolean;
  setProgramDetails?: Function;
  programDetails?: any;
}

export default function CreateProgramsModal(props: Props) {
  const {
    onClose,
    entity,
    isProgramListRequired = false,
    setProgramDetails,
    programDetails
  } = props;
  const [fileName, setFileName] = useState("");
  const [fileDisplay, setFileDisplay] = useState(false);
  const [allFiles, setAllFiles] = useState([]);
  const [allDocs, setAllDocs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [formFields, setFormFields] = useState({
    title: "",
    description: "",
    startDate: "",
    endDate: "",
    userLimit: ""
  });
  const [customError, setCustomError] = useState({
    title: "",
    description: "",
    startDate: "",
    endDate: "",
    userLimit: ""
  });
  const [showValidation, setShowValidation] = useState(false);
  const user = JSON.parse(localStorage.getItem("user"));

  const dispatch = useDispatch();
  const formValidaionSchema = Yup.object({
    name: Yup.string().required("Required"),
    description: Yup.string().required("Required"),
    startDate: Yup.string().required("Required"),
    endDate: Yup.string().required("Required"),
    userLimit: Yup.string().required("Required")
  });
  useEffect(() => {
    if (showValidation) {
      let titleErrorMessage = formFields.title == "" ? "Required" : "";
      let descriptionErrorMessage = formFields.description == "" ? "Required" : "";
      let startDateErrorMessage = formFields.startDate == "" ? "Required" : "";
      let endDateErrorMessage =
        formFields.endDate == ""
          ? "Required"
          : formFields.startDate > formFields.endDate
          ? "Invalid end date"
          : "";
      let userLimitErrorMessage = formFields.userLimit == "" ? "Required" : "";
      setCustomError({
        ...customError,
        title: titleErrorMessage,
        description: descriptionErrorMessage,
        startDate: startDateErrorMessage,
        endDate: endDateErrorMessage,
        userLimit: userLimitErrorMessage
      });
    }
  }, [formFields, showValidation]);

  const handleCreateProgram = async (values: any) => {
    setShowValidation(true);
    if (
      formFields.title != "" &&
      formFields.description != "" &&
      formFields.startDate != "" &&
      formFields.endDate != "" &&
      formFields.userLimit != "" &&
      !(formFields.startDate > formFields.endDate)
    ) {
      let payload = {
        name: formFields.title,
        description: formFields.description,
        startDate: formFields.startDate,
        endDate: formFields.endDate,
        userLimit: formFields.userLimit,
        entityId: entity?.id || undefined,
        entityName: entity?.name || undefined,
        createdBy: user.userId
      };
      const sendInviteResponse = await ApiClient.post(springshedEndPoints.createProgram(), payload);
      if (sendInviteResponse.responseCode === 201) {
        if (sendInviteResponse?.response?.id && allFiles?.length > 0) {
          for (let i = 0; i < allFiles.length; i++) {
            const splitFileName = allDocs[i].name.split(".");
            const extension = splitFileName[splitFileName.length - 1];
            let payload = {
              size: allFiles[i].size,
              name: allDocs[i].name,
              vimeoUrl: "",
              createdBy: user.name,
              contentType: extension,
              entityId: sendInviteResponse?.response?.entity?.registryEntityId,
              contentUrl: allFiles[i].url
            };
            try {
              let response = await ApiClient.post(
                springshedEndPoints.postProgramDocument(sendInviteResponse?.response?.id),
                payload
              );
            } catch (error) {
              toast.error("Something went wrong.");
            }
          }
        }
        toast.success(sendInviteResponse.message);
        onClose();
        dispatch(ProgramActions.getPrograms());
        updateTelemetry(sendInviteResponse?.response);
        isProgramListRequired && updateList(sendInviteResponse?.response);
      } else {
        toast.error(sendInviteResponse.message);
      }
    }
  };

  const updateList = async (data: any) => {
    let dataObj = programDetails;
    let temp = dataObj.entityData.programs;
    let obj = { ...data, serialNo: `${temp.length}.` };
    temp.push(obj);
    dataObj.entityData.programs = temp;
    setProgramDetails(dataObj);
    LocalStorage.setStorage("programDetails", dataObj);
    window.location.reload();
  };

  const handleCustomChange = async (e: any, setFieldValue: any) => {
    let tempArray: any = [];
    allDocs.map((i) => {
      tempArray.push(i.name);
    });
    if (tempArray.includes(e.target.files[0].name)) {
      toast.error("Document already added");
      return;
    }
    const validTypes = ["mp4", "mpeg", "mov", "flv", "png", "jpg", "jpeg", "pdf"];
    const validTypesImage = ["png", "jpg", "jpeg"];
    const validTypesDocs = ["pdf"];
    const validTypesVideo = ["mp4", "mpeg", "flv", "mov"];
    const splitFileName = e?.currentTarget?.files[0]?.name.split(".");
    const extension = splitFileName[splitFileName.length - 1];
    let filesArray = [...allDocs];
    let filesUrlArray = [...allFiles];
    if (e.target.validity.valid && validTypes.includes(extension)) {
      setLoading(true);
      setFileName(e?.currentTarget?.files[0]?.name);
      filesArray.push(e?.currentTarget?.files[0]);
      setAllDocs(filesArray);
      let formData: FormData = new FormData();
      formData.append("appType", "pda");
      formData.append("file", e?.currentTarget?.files[0]);
      formData.append("fileName", e?.currentTarget?.files[0]?.name);
      // formData.append("fileType", "Program-Docs/Documents");
      if (validTypesImage.includes(extension)) {
        formData.append("fileType", "Program-Docs/Images");
      }
      if (validTypesDocs.includes(extension)) {
        formData.append("fileType", "Program-Docs/Documents");
      }
      if (validTypesVideo.includes(extension)) {
        formData.append("fileType", "Program-Docs/Program Videos");
      }
      let response = await ApiClient.put(springshedEndPoints.fileUpload(), formData);
      if (response.responseCode === HTTP_STATUS_CODES.ok) {
        setFileDisplay(true);
        setFieldValue("document", response?.response);
        filesUrlArray.push(response?.response);
        setAllFiles(filesUrlArray);
        setLoading(false);
      } else {
        toast.error(response.message);
        setLoading(false);
      }
    } else {
      toast.error(
        "Invalid File Type! Only Files with format of mp4, mpeg, mov, flv, png, jpg, jpeg, pdf will be uploaded!!"
      );
    }
  };

  const updateTelemetry = async (data: any) => {
    const user: any = await LocalStorage.getStorage("user");
    const body = {
      timestamp: Moment().toLocaleString(),
      createdAt: Moment().toLocaleString(),
      deleted: "false",
      updatedAt: Moment().toLocaleString(),
      eventType: "Create Program",
      sessionStartDate: new Date(data.startDate).toISOString().replace("T", " ").replace("Z", ""),
      sessionEndDate: new Date(data.endDate).toISOString().replace("T", " ").replace("Z", ""),
      programName: data.name,
      programId: data.id,
      entityId: data.entity.registryEntityId,
      entityName: data.entity.name
    };
    AddTelemetryService(body, undefined, user);
  };

  const handleDeleteDocument = (index: number) => {
    let tempArray = [...allDocs];
    tempArray.splice(index, 1);
    allFiles.splice(index, 1);
    setAllDocs(tempArray);
  };

  return (
    <>
      {loading ? (
        <Loader customLoadingContainer={styles.loaderContiner} />
      ) : (
        <View style={styles.container}>
          <View style={styles.modalHeader}>
            <Text
              fontWeight={FontWeight.Bold}
              testId="addEmailText"
              textSize={TextSize.Small}
              textStyle={styles.headerText}
            >
              {"Add Program"}
            </Text>
            <View>
              <Pressable onPress={() => onClose()}>
                <Icon testID="close" name={IconNames.crossCircle} />
              </Pressable>
            </View>
          </View>

          <Formik
            initialValues={{
              name: undefined,
              description: undefined,
              startDate: undefined,
              endDate: undefined,
              userLimit: undefined,
              document: undefined,
              entityId: entity?.id || undefined,
              entityName: entity?.name || undefined,
              createdBy: user.userId
            }}
            onSubmit={(values, { setSubmitting }) => {
              handleCreateProgram(values);
            }}
            // validationSchema={formValidaionSchema}
            enableReinitialize={true}
          >
            {(
              formikProps: FormikProps<{
                name: string;
                description: string;
                startDate: string;
                endDate: string;
                userLimit: string;
                document: string;
                entityId: number;
                entityName: string;
                createdBy: string;
              }>
            ) => (
              <Form>
                <View>
                  <View style={styles.titleContainer}>
                    <View style={styles.textFieldContianer}>
                      <View style={styles.labelTextContainer}>
                        <Text
                          fontWeight={FontWeight.Regular}
                          testId="programNameTitleText"
                          textSize={TextSize.Small}
                          textStyle={styles.subheaderText}
                        >
                          Program Name
                        </Text>
                      </View>
                      <View style={styles.widthContainer}>
                        <View style={styles.contentContainer}>
                          <TextField
                            label=""
                            handleChange={(value: string) =>
                              setFormFields({ ...formFields, title: value })
                            }
                            handleBlur={() => {}}
                            value={formFields.title}
                            inputStyle={[styles.inputMobile, styles.entityContainer]}
                            placeholder="Enter program name"
                            name="name"
                            id="name"
                          />
                        </View>
                        {customError.title != "" && (
                          <Text
                            testId={"1"}
                            textSize={TextSize.Small}
                            fontWeight={FontWeight.Regular}
                            textStyle={[styles.error]}
                          >
                            {customError.title}
                          </Text>
                        )}
                      </View>
                    </View>
                  </View>

                  <View style={[styles.titleContainer, { minHeight: 100 }]}>
                    <View style={styles.textFieldContianer}>
                      <View style={styles.labelTextContainer}>
                        <Text
                          fontWeight={FontWeight.Regular}
                          testId="qualificationTitleText"
                          textSize={TextSize.Small}
                          textStyle={styles.subheaderText}
                        >
                          Description
                        </Text>
                      </View>
                      <View style={styles.widthContainer}>
                        <View style={styles.contentContainer}>
                          <TextArea
                            label=""
                            handleChange={(value: string) =>
                              setFormFields({ ...formFields, description: value })
                            }
                            handleBlur={() => {}}
                            value={formFields.description}
                            inputStyle={[styles.textAreaALign, styles.entityContainer]}
                            placeholder="Enter description"
                            name="description"
                            id="description"
                            numberOfLines={4}
                            multiline={true}
                          />
                        </View>
                        {customError.description != "" && (
                          <Text
                            testId={"1"}
                            textSize={TextSize.Small}
                            fontWeight={FontWeight.Regular}
                            textStyle={[styles.error]}
                          >
                            {customError.description}
                          </Text>
                        )}
                      </View>
                    </View>
                  </View>

                  <View style={styles.containerSecondary}>
                    <View style={{}}>
                      <View style={styles.textFieldContianer}>
                        <View style={styles.labelSecondaryContainer}>
                          <View style={styles.labelTextSecondaryContainer}>
                            <Text
                              fontWeight={FontWeight.Regular}
                              testId="startDateTitleText"
                              textSize={TextSize.Small}
                              textStyle={[styles.subheaderText, { marginTop: 11 }]}
                            >
                              Start Date
                            </Text>
                          </View>
                          <View style={styles.contentSecondaryContainer}>
                            <DatePicker
                              min="2019-01-01"
                              max="2022-12-31"
                              handleBlur={() => {}}
                              onChange={(e) => {
                                setFormFields({
                                  ...formFields,
                                  startDate: e.target.value,
                                  endDate: ""
                                });
                              }}
                              value={formFields.startDate}
                              name="startDate"
                              id="startDate"
                            />
                          </View>
                        </View>
                      </View>
                    </View>

                    <View style={{}}>
                      <View style={[styles.textFieldContianer]}>
                        <View style={styles.labelSecondaryContainer}>
                          <View style={[styles.labelTextSecondaryContainer]}>
                            <Text
                              fontWeight={FontWeight.Regular}
                              testId="endDateTitleText"
                              textSize={TextSize.Small}
                              textStyle={[styles.subheaderText, { marginTop: 11 }]}
                            >
                              End Date
                            </Text>
                          </View>
                          <View style={[styles.contentSecondaryContainer]}>
                            <DatePicker
                              min={formFields.startDate}
                              max="2022-12-31"
                              handleBlur={() => {}}
                              onChange={(e) => {
                                setFormFields({ ...formFields, endDate: e.target.value });
                              }}
                              value={formFields.endDate}
                              name="endDate"
                              id="endDate"
                            />
                          </View>
                        </View>
                      </View>
                    </View>
                    <View style={{}}>
                      <View style={[styles.textFieldContianer]}>
                        <View style={{ flexDirection: "row" }}>
                          <View style={[styles.labelTextSecondaryContainer, {}]}>
                            <Text
                              fontWeight={FontWeight.Regular}
                              testId="endDateTitleText"
                              textSize={TextSize.Small}
                              textStyle={[styles.subheaderText]}
                            >
                              User limit
                            </Text>
                          </View>
                          <View style={[styles.contentSecondaryContainer, {}]}>
                            <TextField
                              label=""
                              handleChange={(value: string) => {
                                setFormFields({ ...formFields, userLimit: value });
                              }}
                              handleBlur={() => {}}
                              value={formFields.userLimit}
                              inputStyle={[
                                styles.inputMobile,
                                styles.entityContainer,
                                styles.alignContainer
                              ]}
                              placeholder="Enter user limit"
                              name="userLimit"
                              id="userLimit"
                              isNumeric={true}
                            />
                          </View>
                        </View>
                      </View>
                    </View>
                  </View>
                  <View style={styles.errorMainContainer}>
                    <View style={styles.errorContainer}>
                      <View>
                        {customError.startDate != "" && (
                          <Text
                            testId={"1"}
                            textSize={TextSize.Small}
                            fontWeight={FontWeight.Regular}
                            textStyle={[styles.error]}
                          >
                            {customError.startDate}
                          </Text>
                        )}
                      </View>
                      <View style={styles.ml10}>
                        {customError.endDate != "" && (
                          <Text
                            testId={"1"}
                            textSize={TextSize.Small}
                            fontWeight={FontWeight.Regular}
                            textStyle={[styles.error]}
                          >
                            {customError.endDate}
                          </Text>
                        )}
                      </View>
                      <View style={styles.ml25}>
                        {customError.userLimit != "" && (
                          <Text
                            testId={"1"}
                            textSize={TextSize.Small}
                            fontWeight={FontWeight.Regular}
                            textStyle={[styles.error]}
                          >
                            {customError.userLimit}
                          </Text>
                        )}
                      </View>
                    </View>
                  </View>
                  <FilePicker
                    onChange={async (e) => {
                      formikProps.handleChange(e);
                      await handleCustomChange(e, formikProps.setFieldValue);
                    }}
                  />
                  <View style={styles.docContainer}>
                    <ScrollContainer height="100px" scrollable={allDocs?.length > 1 ? true : false}>
                      {fileDisplay &&
                        allDocs.map((data, index) => (
                          <View style={styles.docsContainer}>
                            <View>
                              <Text
                                fontWeight={FontWeight.Regular}
                                testId="docsTitleText"
                                textSize={TextSize.Small}
                                textStyle={styles.docsTitle}
                              >
                                {data?.name}
                              </Text>
                              <Text
                                fontWeight={FontWeight.Regular}
                                testId="docsTitleText"
                                textSize={TextSize.Small}
                                textStyle={[
                                  styles.uploadHeaderText,
                                  { marginTop: 10, fontSize: 12 }
                                ]}
                              >
                                Document
                              </Text>
                            </View>
                            <Icon
                              testID="close"
                              name={IconNames.deleteFile}
                              customStyle={styles.uploadDocIcon}
                              onPress={() => handleDeleteDocument(index)}
                            />
                          </View>
                        ))}
                    </ScrollContainer>
                  </View>

                  <View style={styles.buttonContainer}>
                    <Button
                      type={ButtonType.Primary}
                      buttonStyles={styles.button}
                      textStyles={styles.buttonFont}
                      title="Submit"
                      onPress={() => {
                        formikProps.handleSubmit();
                      }}
                    />
                  </View>
                </View>
              </Form>
            )}
          </Formik>
        </View>
      )}
    </>
  );
}

const styles = StyleSheet.create({
  container: {
    display: "flex",
    width: "655px"
  },
  modalHeader: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row",
    marginBottom: 25
  },
  headerText: {
    fontWeight: "700",
    fontSize: 12,
    fontFamily: FontFamily.Medium,
    lineHeight: 17
  },
  uploadPictureContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center"
    // marginTop: "1rem"
  },
  uploadPicture: {
    width: "450px",
    height: "300px",
    backgroundColor: "#F8F8F8",
    borderRadius: 5
  },
  uploadPictureText: {
    margin: "auto",
    color: colorPallete.textBlack,
    fontSize: 14,
    opacity: 0.5
  },
  customUpload: {
    alignSelf: "center",
    opacity: 0.8
  },
  titleContainer: {
    display: "flex",
    flexDirection: "row",
    flex: 50,
    minHeight: 70
  },
  containerSecondary: {
    flexDirection: "row",
    justifyContent: "space-between",
    // alignItems: "center",
    minHeight: 45
  },
  subheaderText: {
    fontWeight: "400",
    fontSize: 12,
    fontFamily: FontFamily.Regular,
    lineHeight: 17,
    color: colorPallete.textLight,
    marginTop: 13
  },
  textFieldContianer: {
    flexDirection: "row",
    width: "100%",
    display: "flex"
  },
  labelTextContainer: {
    flex: 18
  },
  contentContainer: {
    flex: 85
  },
  labelSecondaryContainer: {
    flexDirection: "row"
  },
  labelTextSecondaryContainer: {
    // flex: 28
    marginRight: 8
  },
  contentSecondaryContainer: {
    // flex: 58
  },

  inputMobile: {
    height: 40,
    outline: "none",
    borderRadius: 5,
    padding: 7,
    fontSize: 12,
    fontFamily: FontFamily.Regular,
    fontWeight: "400",
    width: "100%"
  },
  entityContainer: {
    fontFamily: FontFamily.Medium,
    fontWeight: "400",
    padding: 0
  },
  alignContainer: {
    width: 148
  },
  alignInputFieldMedium: {
    // width: "70%"
  },
  alignHeader: {
    // marginTop: 13
  },
  uploadHeaderText: {
    fontWeight: "400",
    fontSize: 14,
    fontFamily: FontFamily.Regular,
    lineHeight: 17,
    color: colorPallete.textLight
  },
  button: {
    width: "140px",
    height: "50px",
    borderRadius: 10
  },
  buttonFont: {
    fontSize: 12
  },
  alignAddressText: {
    paddingLeft: 5,
    marginTop: 5,
    borderRadius: 5,
    borderColor: colorPallete.gray,
    borderStyle: "solid",
    borderWidth: 1
  },
  buttonContainer: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "1.5rem"
  },
  iconStyle: {
    color: colorPallete.cordioTaupe,
    marginLeft: 5,
    marginRight: 5,
    fontSize: 15
  },
  iconContainer: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center"
  },
  memberInfoContainer: {
    flex: 80
  },
  alignText: {
    fontSize: 12,
    color: colorPallete.textLight,
    marginTop: 10,
    FontFamily: FontFamily.Medium,
    // fontWeight:'900'
    fontWeight: "400"
  },

  docsContainer: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row",
    alignItems: "center",
    borderRadius: 5,
    height: "80px",
    marginRight: "5px",
    padding: 15,
    marginTop: 20,
    borderColor: "#3FA202",
    borderStyle: "solid",
    borderWidth: 1
  },
  docsTitle: {
    fontWeight: "700",
    fontSize: 14,
    fontFamily: FontFamily.Medium,
    lineHeight: 17
  },
  textAreaALign: {
    borderRadius: 5,
    padding: 7,
    paddingTop: 10,
    fontSize: 12,
    fontFamily: FontFamily.Regular,
    fontWeight: "400",
    width: "100%"
  },
  mainDocContainer: {
    height: "110px",
    overflowY: "scroll",
    marginTop: "10px"
  },
  uploadDocIcon: {
    opacity: 0.5
  },
  loaderContiner: {
    width: 655,
    height: 438
  },
  error: {
    position: "relative",
    color: "red",
    alignSelf: "flex-start",
    fontSize: 10,
    fontWeight: "400",
    zIndex: -1
  },
  widthContainer: {
    width: "82%"
  },
  errorContainer: {
    display: "grid",
    gridTemplateColumns: "repeat(3, 1fr)",
    gridTemplateRows: "repeat(1, 20px)"
  },
  errorMainContainer: {
    marginLeft: "11%"
  },
  ml10: {
    marginLeft: "10%"
  },
  ml25: {
    marginLeft: "25%"
  },
  docContainer: {
    marginTop: 10
  }
});
