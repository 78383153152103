import React, { ReactElement } from "react";
import { StyleProp, StyleSheet, TouchableOpacity, ViewStyle, Text, TextStyle } from "react-native";
import { colorPallete } from "@socion-cordio/common/src/assets/styles/colors";
import Icon, { IconNames } from "@socion-cordio/common/src/components/atoms/icon";
import { FontWeight } from "./text";

export enum ButtonType {
  Primary = "primary",
  Secondary = "secondary"
}
interface IProps {
  type: ButtonType;
  onPress?: () => void;
  disabled?: boolean;
  activeOpacity?: number;
  title?: string;
  iconName?: IconNames;
  buttonStyles?: StyleProp<ViewStyle>;
  textStyles?: StyleProp<TextStyle>;
  iconStyles?: StyleProp<TextStyle>;
  // create a new Prop to enable a prop to enable or disbale.
  //  enable - call telemetry
  // false - simple onPress.
}

export default function Button(props: IProps): ReactElement {
  const {
    onPress,
    type,
    title,
    iconName,
    buttonStyles,
    disabled = false,
    textStyles,
    iconStyles,
    activeOpacity = 0.5
  } = props;

  const containerStyle = getContainerStyle(disabled, type, buttonStyles);
  const textStyle = getTextStyle(disabled, type, textStyles);
  const iconStyle = getIconStyle(disabled, type, iconStyles);

  const telemetry = () => {
    // call temeletry function here.
    // after this call onPress
  };

  return (
    <TouchableOpacity
      onPress={onPress}
      // // instead of onPress call telementry here.
      style={containerStyle}
      activeOpacity={activeOpacity}
      disabled={disabled}
    >
      {iconName && <Icon name={iconName} customStyle={iconStyle} />}
      <Text style={textStyle}>{title}</Text>
    </TouchableOpacity>
  );
}

const getContainerStyle = (disabled: boolean, type: ButtonType, buttonStyles = {}): ViewStyle => {
  let themedStyle: StyleProp<ViewStyle> = styles.primary;
  if (type === "primary" && disabled) {
    themedStyle = styles.primaryDisabled;
  }
  if (type === "secondary" && !disabled) {
    themedStyle = styles.secondary;
  }
  if (type === "secondary" && disabled) {
    themedStyle = styles.secondaryDisabled;
  }
  return StyleSheet.flatten([styles.container, themedStyle, buttonStyles]);
};

const getTextStyle = (disabled: boolean, type: ButtonType, textStyles = {}): TextStyle => {
  let themedStyle: StyleProp<TextStyle> = styles.primaryText;
  if (type === "primary" && disabled) {
    themedStyle = styles.primaryDisabledText;
  }
  if (type === "secondary" && !disabled) {
    themedStyle = styles.secondaryText;
  }
  if (type === "secondary" && disabled) {
    themedStyle = styles.secondaryDisabledText;
  }
  return StyleSheet.flatten([styles.buttonText, themedStyle, textStyles]);
};

const getIconStyle = (disabled: boolean, type: ButtonType, iconStyles = {}): TextStyle => {
  let themedStyle: StyleProp<TextStyle> = styles.primaryText;
  if (disabled) {
    themedStyle = styles.primaryDisabledText;
  }
  if (type === "secondary" && !disabled) {
    themedStyle = styles.secondaryText;
  }
  return StyleSheet.flatten([styles.iconText, themedStyle, iconStyles]);
};

const styles = StyleSheet.create({
  container: {
    borderRadius: 10,
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
    padding: 8
  },
  buttonText: {
    fontWeight: FontWeight.Medium,
    marginTop: 2
  },
  iconText: {
    marginHorizontal: 4
  },
  primary: {
    backgroundColor: colorPallete.cordioRed
  },
  primaryText: {
    fontFamily: "Montserrat-Medium",
    color: colorPallete.white
  },
  primaryDisabled: {
    backgroundColor: colorPallete.cordioTaupeLight3,
    borderWidth: 2,
    borderColor: colorPallete.cordioTaupeLight3
  },
  primaryDisabledText: {
    fontFamily: "Montserrat-Medium",
    color: colorPallete.white
  },
  secondary: {
    borderWidth: 1,
    borderColor: colorPallete.cordioRed,
    backgroundColor: colorPallete.white
  },
  secondaryText: {
    fontFamily: "Montserrat-Medium",
    color: colorPallete.cordioRed
  },
  secondaryDisabled: {
    backgroundColor: colorPallete.white,
    borderWidth: 1,
    borderColor: colorPallete.cordioTaupeLight3
  },
  secondaryDisabledText: {
    fontFamily: "Montserrat-Medium",
    color: colorPallete.textLight
  }
});
