import { Task } from "@socion-cordio/common/src/models/task";
import { TASKS } from "@socion-cordio/common/src/modules/tasks/actions/actionTypes";

export interface ITaskLoading {
  getTasks: boolean;
  getTaskData: boolean;
  getSubmissions: boolean;
  getSubmitter: boolean;
}

export interface ITaskError {
  getTasks: string;
  getTaskData: string;
  getSubmissions: string;
  getSubmitter: string;
}

export interface ITaskState {
  taskList: Task;
  taskData: string;

  submissionsList: any[];
  submitter: any[];
  loading: ITaskLoading;
  error: ITaskError;
}

export const taskInitialState: ITaskState = {
  loading: {
    getTasks: false,
    getTaskData: false,
    getSubmissions: false,
    getSubmitter: false
  },
  error: {
    getTasks: "",
    getTaskData: "",
    getSubmissions: "",
    getSubmitter: ""
  },
  taskList: null,
  taskData: null,
  submissionsList: null,
  submitter: []
};

const TaskReducer = (
  state = taskInitialState,
  action: { type: string; payload: any }
): ITaskState => {
  switch (action.type) {
    case TASKS.GET_TASKS.LOADING:
      return {
        ...state,
        loading: { ...state.loading, getTasks: true },
        error: { ...state.error, getTasks: null }
      };
    case TASKS.GET_TASKS.SUCCESS:
      return {
        ...state,
        loading: {
          ...state.loading,
          getTasks: false
        },
        error: {
          ...state.error,
          getTasks: null
        },
        taskList: action.payload
      };
    case TASKS.GET_TASKS.ERROR:
      return {
        ...state,
        loading: {
          ...state.loading,
          getTasks: false
        },
        error: {
          ...state.error,
          getTasks: action.payload
        }
      };
    case TASKS.GET_TASK_DATA.LOADING:
      return {
        ...state,
        loading: { ...state.loading, getTaskData: true },
        error: { ...state.error, getTaskData: null }
      };
    case TASKS.GET_TASK_DATA.SUCCESS:
      return {
        ...state,
        loading: {
          ...state.loading,
          getTaskData: false
        },
        error: {
          ...state.error,
          getTaskData: null
        },
        taskData: action.payload
      };
    case TASKS.GET_TASK_DATA.ERROR:
      return {
        ...state,
        loading: {
          ...state.loading,
          getTaskData: false
        },
        error: {
          ...state.error,
          getTaskData: action.payload
        }
      };

    case TASKS.GET_SUBMISSIONS.LOADING:
      return {
        ...state,
        loading: { ...state.loading, getSubmissions: true },
        error: { ...state.error, getSubmissions: null }
      };
    case TASKS.GET_SUBMISSIONS.SUCCESS:
      return {
        ...state,
        loading: {
          ...state.loading,
          getSubmissions: false
        },
        error: {
          ...state.error,
          getSubmissions: null
        },
        submissionsList: action.payload
      };
    case TASKS.GET_SUBMISSIONS.ERROR:
      return {
        ...state,
        loading: {
          ...state.loading,
          getSubmissions: false
        },
        error: {
          ...state.error,
          getSubmissions: action.payload
        }
      };
    case TASKS.GET_SUBMITTER.LOADING:
      return {
        ...state,
        loading: { ...state.loading, getSubmitter: true },
        error: { ...state.error, getSubmitter: null }
      };
    case TASKS.GET_SUBMITTER.SUCCESS:
      return {
        ...state,
        loading: {
          ...state.loading,
          getSubmitter: false
        },
        error: {
          ...state.error,
          getSubmitter: null
        },
        submitter: action.payload
      };
    case TASKS.GET_SUBMITTER.ERROR:
      return {
        ...state,
        loading: {
          ...state.loading,
          getSubmitter: false
        },
        error: {
          ...state.error,
          getSubmitter: action.payload
        }
      };

    default: {
      return state;
    }
  }
};

export default TaskReducer;
