import React, { ReactElement, useEffect, useState } from "react";
import SignupForm from "@socion-cordio/common/src/components/organisms/signup/signupForm";
import { ToastContainer, toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { SignupActions } from "@socion-cordio/common/src/modules/signup/actions/actions";
import { HTTP_STATUS_CODES } from "@socion-cordio/common/src/network/constants";
import Moment from "moment";
import AddTelemetryService from "@socion-cordio/common/src/services/telemetryService";
import { LocalStorage } from "@socion-cordio/common/src/services/storage/storageService";
import { signupEndPoints } from "@socion-cordio/common/src/repositories/endPoints";
import { ApiClient } from "@socion-cordio/common/src/network/apiClient";
import { allRoutesNames } from "navigation/allRouteNames";

interface IProps {
  history: any;
}

export function SignupPage(props: IProps): ReactElement {
  const [countryCode, setCountryCode] = useState(null);
  const [phoneNumber, setPhoneNumber] = useState(null);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [verificationType, setVerificationType] = useState("");
  const [isCaptchaDisabled, setIsCaptchaDisabled] = useState(true);
  const [selectedCountryObject, setSelectedCountryObject] = useState(null);
  const [emailVerification, setEmailVerification] = useState(false);
  const dispatch = useDispatch();
  const recaptchaRef = React.useRef(null);
  const signUpdata: any = useSelector((state) => state);

  // useEffect(() => {
  //   if (data?.signup?.signupData?.responseCode === HTTP_STATUS_CODES.ok) {
  //     toast.success("OTP sent successfully");
  //     if (emailVerification) {
  //       props.history.push("/signup/otp", {
  //         countryCode: countryCode,
  //         phoneNumber: phoneNumber,
  //         name: name,
  //         registerKey: data.signup.signupData.response,
  //         verificationType: "EMAIL_ID",
  //         emailId: email,
  //         emailVerification: emailVerification
  //       });
  //     } else {
  //       props.history.push("/signup/otp", {
  //         countryCode: countryCode,
  //         phoneNumber: phoneNumber,
  //         name: name,
  //         registerKey: data.signup.signupData.response,
  //         verificationType: "PHONE_NUMBER",
  //         emailId: email,
  //         emailVerification: emailVerification
  //       });
  //     }

  //     LocalStorage.setStorage("countryCode", +countryCode.replace("+", ""));
  //     LocalStorage.setStorage("countryCodeObj", selectedCountryObject);
  //   } else if (data?.signup?.signupData?.message && phoneNumber !== null) {
  //     recaptchaRef.current.reset();
  //     setIsCaptchaDisabled(true);
  //     toast.error(data.signup.signupData.message);
  //   } else if (data?.signup?.error?.signupUser && phoneNumber !== null) {
  //     recaptchaRef.current.reset();
  //     setIsCaptchaDisabled(true);
  //     toast.error("Error occured");
  //   }
  // }, [data?.signup?.signupData]);

  const submitSignup = async (data: {
    countryCode: number;
    phoneNumber: number;
    name: string;
    emailId: string;
  }) => {
    let signupData: any = null;
    if (emailVerification) {
      setCountryCode(selectedCountryObject.code.toString());
      setName(data.name);
      setEmail(data.emailId);
      setPhoneNumber(data.phoneNumber);
      setVerificationType("EMAIL_ID");
      // dispatch(SignupActions.signupUser(data));
      signupData = await ApiClient.post(signupEndPoints.signupUser(), data);
      updateTelemetry();
    } else {
      setCountryCode(data.countryCode);
      setPhoneNumber(data.phoneNumber);
      setName(data.name);
      setVerificationType("PHONE_NUMBER");
      // dispatch(SignupActions.signupUser(data));
      signupData = await ApiClient.post(signupEndPoints.signupUser(), data);
      updateTelemetry();
    }

    if (signupData?.responseCode === HTTP_STATUS_CODES.ok) {
      // toast.success("OTP sent successfully");
      if (emailVerification) {
        props.history.push(allRoutesNames.SIGNUPOTP, {
          countryCode: selectedCountryObject.code.toString(),
          phoneNumber: data.phoneNumber,
          name: data.name,
          registerKey: signupData.response,
          verificationType: "EMAIL_ID",
          emailId: data.emailId,
          emailVerification: emailVerification
        });
      } else {
        props.history.push(allRoutesNames.SIGNUPOTP, {
          countryCode: data.countryCode,
          phoneNumber: data.phoneNumber,
          name: data.name,
          registerKey: signupData.response,
          verificationType: "PHONE_NUMBER",
          emailId: data.emailId,
          emailVerification: emailVerification
        });
      }

      // if (verificationPriority == "SMS") {
      //   LocalStorage.setStorage("countryCode", +countryCode?.replace("+", ""));
      // } else {
      //   LocalStorage.setStorage("countryCode", +selectedCountryObject.code?.replace("+", ""));
      // }
      LocalStorage.setStorage("countryCode", +selectedCountryObject.code?.replace("+", ""));
      LocalStorage.setStorage("countryCodeObj", selectedCountryObject);
    } else if (signupData?.message && phoneNumber !== null) {
      recaptchaRef.current.reset();
      setIsCaptchaDisabled(true);
      toast.error(signupData.message);
    } else if (signUpdata?.signup?.error?.signupUser && phoneNumber !== null) {
      recaptchaRef.current.reset();
      setIsCaptchaDisabled(true);
      toast.error("Error occured");
    } else if (signupData?.responseCode === HTTP_STATUS_CODES.badRequest) {
      recaptchaRef.current.reset();
      setIsCaptchaDisabled(true);
      toast.error("This user is already registered in the system.");
    }
  };

  const updateTelemetry = async () => {
    const body = {
      timestamp: Moment().toLocaleString(),
      createdAt: Moment().toLocaleString(),
      deleted: "false",
      updatedAt: Moment().toLocaleString(),
      eventType: "Sign-up"
    };
    AddTelemetryService(body, undefined, undefined);
  };

  return (
    <React.Fragment>
      <SignupForm
        updateSignup={submitSignup}
        ref={recaptchaRef}
        isCaptchaDisabled={isCaptchaDisabled}
        setIsCaptchaDisabled={setIsCaptchaDisabled}
        setSelectedCountryObject={setSelectedCountryObject}
        setEmailVerification={setEmailVerification}
      />
      <ToastContainer />
    </React.Fragment>
  );
}

export default SignupPage;
