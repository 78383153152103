import Icon, { IconNames } from "@socion-cordio/common/src/components/atoms/icon";
import {
  Text,
  TextSize,
  FontWeight,
  FontFamily
} from "@socion-cordio/common/src/components/atoms/text";
import TextField from "@socion-cordio/common/src/components/atoms/textField";
import { colorPallete } from "@socion-cordio/common/src/assets/styles/colors";
import React, { ReactElement, useState, useEffect } from "react";
import { View, StyleSheet, StyleProp, ImageStyle, ViewStyle } from "react-native";
import { Checkbox, CheckboxType } from "@socion-cordio/common/src/components/atoms/checkBox";
import { programEndPoints } from "@socion-cordio/common/src/repositories/endPoints";
import { ApiClient } from "@socion-cordio/common/src/network/apiClient";
interface IProps {
  programData: any;
  userRoles: any;
  isSuperAdmin: any;
  programStatus?: Function;
}
export default function RolesPrivilegesbyAppDetails(props: IProps): ReactElement {
  const { programStatus } = props;
  const [roleList, setRoleList] = useState([]);
  const [programRoleMapping, setProgramRoleMapping] = useState([]);
  const [superAdminList, setSuperAdminList] = useState([]);
  const [superAdminListRoleMapping, setsuperAdminListMapping] = useState([]);

  useEffect(() => {
    getRolePrivileges();
    getSuperAdminPrivileges();
  }, []);

  const handleCheckboxChange = async (programRoleIndex: number, roleIndex: number) => {
    programRoleMapping[programRoleIndex].roles[roleIndex].access =
      !programRoleMapping[programRoleIndex].roles[roleIndex].access;
    setProgramRoleMapping([...programRoleMapping]);
    const {
      programData: { program }
    } = props;
    const payload = [
      {
        access: programRoleMapping[programRoleIndex].roles[roleIndex].access,
        applicationId: programRoleMapping[programRoleIndex].application.id,
        programId: program.id,
        roleId: programRoleMapping[programRoleIndex].roles[roleIndex].roleTitle.id
      }
    ];
    const rolePrivilegesResponse = await ApiClient.put(
      programEndPoints.updateRolePrivileges(),
      payload
    );
  };

  const handleSuperAdminCheckboxChange = async (appRole: number, appRoleIndex: number) => {
    superAdminListRoleMapping[appRoleIndex].superAdminAccess =
      !superAdminListRoleMapping[appRoleIndex].superAdminAccess;
    setsuperAdminListMapping([...superAdminListRoleMapping]);
    const {
      programData: { program }
    } = props;
    const payload = [
      {
        access: superAdminListRoleMapping[appRoleIndex].access,
        applicationId: appRole.application.id,
        programId: program.id,
        superAdminAccess: superAdminListRoleMapping[appRoleIndex].superAdminAccess
      }
    ];
    const SuperAdminPrivilegesResponse = await ApiClient.put(
      programEndPoints.updateSuperAdminPrivileges(),
      payload
    );
  };

  const handleProgramAdminCheckboxChange = async (appRole: number, appRoleIndex: number) => {
    superAdminListRoleMapping[appRoleIndex].access =
      !superAdminListRoleMapping[appRoleIndex].access;
    setsuperAdminListMapping([...superAdminListRoleMapping]);
    const {
      programData: { program }
    } = props;
    const payload = [
      {
        access: superAdminListRoleMapping[appRoleIndex].access,
        applicationId: appRole.application.id,
        programId: program.id,
        superAdminAccess: superAdminListRoleMapping[appRoleIndex].superAdminAccess
      }
    ];
    const SuperAdminPrivilegesResponse = await ApiClient.put(
      programEndPoints.updateSuperAdminPrivileges(),
      payload
    );
  };

  const getRolePrivileges = async () => {
    const {
      programData: { program }
    } = props;
    const rolePrivilegesResponse = await ApiClient.get(
      programEndPoints.getRolePrivileges(program.id)
    );
    setRoleList(rolePrivilegesResponse?.response?.roleList);
    setProgramRoleMapping(rolePrivilegesResponse?.response?.programRoleMapping);
  };

  const getSuperAdminPrivileges = async () => {
    const {
      programData: { program }
    } = props;
    const SuperAdminPrivilegesResponse = await ApiClient.get(
      programEndPoints.getSuperAdminPrivileges(program.id)
    );
    setSuperAdminList(SuperAdminPrivilegesResponse?.response);
    setsuperAdminListMapping(SuperAdminPrivilegesResponse?.response);
  };

  const capitalizeCheck = (data: any) => {
    const string = data.replace("_", " ").toLowerCase();
    return string;
  };
  return (
    <View>
      {!programStatus && (
        <View>
          {(props?.userRoles?.includes("PROGRAM_ADMIN") || props.isSuperAdmin) && (
            <View style={styles.container}>
              <View style={styles.headerContainer}>
                <Text
                  fontWeight={FontWeight.Light}
                  testId="headerText"
                  textSize={TextSize.Small}
                  textStyle={styles.headerText}
                >
                  Role privileges by application
                </Text>
                <Icon testID="edit" name={IconNames.about} customStyle={[styles.alignButton]} />
              </View>
              {props.isSuperAdmin ? (
                <View style={styles.subContainer}>
                  <View style={styles.subContainerOne}>
                    <View style={styles.subContainerColumnOne}></View>
                    <View style={styles.subContainerColumnTwo}>
                      <Text
                        fontWeight={FontWeight.Light}
                        testId="headerText"
                        textSize={TextSize.Small}
                        textStyle={[styles.headerText, styles.informationText]}
                      >
                        {capitalizeCheck("For Program")}
                      </Text>
                    </View>
                    <View style={styles.subContainerColumnTwo}>
                      <Text
                        fontWeight={FontWeight.Light}
                        testId="headerText"
                        textSize={TextSize.Small}
                        textStyle={[styles.headerText, styles.informationText]}
                      >
                        {capitalizeCheck("For Super Admin")}
                      </Text>
                    </View>
                  </View>
                  {superAdminList?.map((appRole: any, appRoleIndex: number) => (
                    <View style={styles.subContainerOne}>
                      <View style={styles.subContainerColumnOne}>
                        <Text
                          fontWeight={FontWeight.Light}
                          testId="headerText"
                          textSize={TextSize.Small}
                          textStyle={[styles.headerText, styles.informationText]}
                        >
                          {appRole.application?.applicationTitle}
                        </Text>
                      </View>
                      <View style={styles.subContainerColumnTwo}>
                        <Checkbox
                          testId={appRole.id}
                          onPress={() => handleProgramAdminCheckboxChange(appRole, appRoleIndex)}
                          selected={appRole.access}
                          type={CheckboxType.Square}
                          text=""
                          textSize={TextSize.Small}
                          textFontWeight={FontWeight.Regular}
                        />
                      </View>
                      <View style={styles.subContainerColumnTwo}>
                        <Checkbox
                          testId={appRole.id}
                          onPress={() => handleSuperAdminCheckboxChange(appRole, appRoleIndex)}
                          selected={appRole.superAdminAccess}
                          type={CheckboxType.Square}
                          text=""
                          textSize={TextSize.Small}
                          textFontWeight={FontWeight.Regular}
                        />
                      </View>
                    </View>
                  ))}
                </View>
              ) : (
                <view>
                  {props?.userRoles?.includes("PROGRAM_ADMIN") && (
                    <View style={styles.subContainer}>
                      <View style={styles.subContainerOne}>
                        <View style={styles.subContainerColumnOne}></View>
                        {roleList?.map((role: any) => (
                          <View style={styles.subContainerColumnTwo}>
                            <Text
                              fontWeight={FontWeight.Light}
                              testId="headerText"
                              textSize={TextSize.Small}
                              textStyle={[styles.headerText, styles.informationText]}
                            >
                              {capitalizeCheck(role.roleName)}
                            </Text>
                          </View>
                        ))}
                      </View>
                      {programRoleMapping?.map((programRole: any, programRoleIndex: number) => (
                        <View style={styles.subContainerOne}>
                          <View style={styles.subContainerColumnOne}>
                            <Text
                              fontWeight={FontWeight.Light}
                              testId="headerText"
                              textSize={TextSize.Small}
                              textStyle={[styles.headerText, styles.informationText]}
                            >
                              {programRole.application?.applicationTitle}
                            </Text>
                          </View>
                          {programRole?.roles.map((role: any, roleIndex: number) => (
                            <View style={styles.subContainerColumnTwo}>
                              <Checkbox
                                testId={role.id}
                                onPress={() => handleCheckboxChange(programRoleIndex, roleIndex)}
                                selected={role.access}
                                type={CheckboxType.Square}
                                text=""
                                textSize={TextSize.Small}
                                textFontWeight={FontWeight.Regular}
                              />
                            </View>
                          ))}
                        </View>
                      ))}
                    </View>
                  )}
                </view>
              )}
            </View>
          )}
        </View>
      )}
    </View>
  );
}
const styles = StyleSheet.create({
  container: {
    // borderColor: colorPallete.cordioTaupe,
    borderColor: "rgba(103, 88, 80,0.1)",
    borderStyle: "solid",
    borderWidth: 1,
    borderRadius: 10,
    padding: 15
  },
  headerContainer: {
    flexDirection: "row"
  },
  headerText: {
    color: colorPallete.textLight,
    fontSize: 12,
    fontFamily: FontFamily.Regular,
    lineHeight: 17
  },
  alignButton: {
    fontSize: 15,
    color: colorPallete.cordioTaupe,
    marginLeft: 15
    // marginLeft: 30
  },
  subContainer: {
    marginTop: 20,
    marginBottom: 30
  },
  subContainerOne: {
    flexDirection: "row",
    marginTop: 15,
    marginBottom: 10
    // marginBottom: 10
  },
  subContainerColumnOne: {
    flex: 36,
    justifyContent: "center"
  },
  subContainerColumnTwo: {
    flex: 32,
    alignItems: "center"
  },
  subContainerColumnThree: {
    flex: 32,
    alignItems: "center"
  },
  informationText: {
    color: colorPallete.textBlack,
    textTransform: "capitalize"
  },
  checkBoxContainer: {}
});
