// @ts-nocheck
import icomoon from "@socion-cordio/common/src/assets/fonts/icomoon.ttf";
import MontserratRegular from "@socion-cordio/common/src/assets/fonts/Montserrat-Regular.ttf";
import MontserratBold from "@socion-cordio/common/src/assets/fonts/Montserrat-Bold.ttf";
import MontserratMedium from "@socion-cordio/common/src/assets/fonts/Montserrat-Medium.ttf";
import NotoSansRegular from "@socion-cordio/common/src/assets/fonts/NotoSans-Regular.ttf";
import NotoSansBold from "@socion-cordio/common/src/assets/fonts/NotoSans-Bold.ttf";


const iconFontStyles = `
@font-face {
  src: url(${icomoon});
  font-family: icomoon;
}
@font-face {
  src: url(${MontserratBold});
  font-family: Montserrat-Bold;
}
@font-face {
  src: url(${MontserratRegular});
  font-family: Montserrat-Regular;
}
@font-face {
  src: url(${MontserratMedium});
  font-family: Montserrat-Medium;
}
@font-face {
  src: url(${NotoSansBold});
  font-family: NotoSans-Bold;
}
@font-face {
  src: url(${NotoSansRegular});
  font-family: NotoSans-Regular;
}
`;
// Create stylesheet
const style: any = document.createElement("style");
style.type = "text/css";
if (style.styleSheet) {
  style.styleSheet.cssText = iconFontStyles;
} else {
  style.appendChild(document.createTextNode(iconFontStyles));
}
// Inject stylesheet
document.head.appendChild(style);
