import { CONFIG } from "@socion-cordio/common/src/config/envConstant";
import { LocalStorage } from "@socion-cordio/common/src/services/storage/storageService";
import axios from "axios";

export const telemetryToTrackMayMyIndia = async (reqType: string, request: any) => {
  const user: any = await LocalStorage.getStorage("user");
  const userId = user?.userId || user?.userid;
  const payload = {
    appType: "ARTEFACT",
    url: reqType,
    requestPayload: JSON.stringify(request),
    userId: userId
  };
  const url = CONFIG.REACT_APP_API_URL + "/telemetry/track/map-my-india";
  let token = await LocalStorage.getStorage("accessToken");
  var config = {
    method: "POST",
    url: url,
    headers: {
      "access-token": token,
      "Content-Type": "application/json"
    },
    data: payload
  };
  console.log("configconfig", config);
  await axios.post(config.url, config.data, { headers: config.headers }).catch((err) => {
    console.log("config.headers", err);
  });
};
