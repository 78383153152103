import { ArtefactRepository } from "@socion-cordio/common/src/repositories/artefact/artefact";
import { snakeCase } from "lodash";
import { toast } from "react-toastify";
import { artefact_messages } from "../data/message";
import { addArtefactDocEvent, completeUploadEvent, startUploadEvent } from "./telemetryEvents";
import axios from "axios";
import { Subject } from "rxjs";

const CancelToken = axios.CancelToken;
let cancelTokenSource: any = null;
let fileUploadProgress = 0;
let fileUploadPercentage = 0;
let allFilesUploadProgress: any = [];
export let uploadPercentage$ = new Subject();

export const startUploadDoc = async (
  docs: any[],
  props: { id: string; programId: string; type: string; docsCount?: number },
  req: any,
  artefact: any,
  user: any
) => {
  const uploadedFiles = [];
  let uploadFilesProgress = [];
  allFilesUploadProgress = [];
  cancelTokenSource = CancelToken.source();
  for (let i = 0; i < docs.length; i++) {
    fileUploadPercentage = 0;
    const doc = docs[i];
    const file = getFileData(props, doc);
    let response = await ArtefactRepository.startUpload(file);
    if (response?.data) {
      startUploadEvent({ ...artefact, ...req }, props.docsCount);
      const uploadedFile = await uploadMultipartFile(
        file,
        doc,
        response?.data,
        req,
        artefact,
        user,
        props.docsCount,
        props.type
      );
      uploadedFiles.push(uploadedFile);
      uploadFilesProgress.push({ name: file.fName, progress: fileUploadPercentage });
      console.log("completedpercentage fileUploadPercentage", uploadFilesProgress);
      uploadPercentage$.next(uploadFilesProgress);
    }
  }
  allFilesUploadProgress = uploadFilesProgress;

  // if (allFilesUploadProgress?.length>0 && allFilesUploadProgress?.length === docs?.length) {
  //   uploadPercentage$.complete();
  // }
  return uploadedFiles;
};

export const uploadMultipartFile = async (
  file: any,
  selectedFile: any,
  uploadData: any,
  req: any,
  artefact: any,
  user: any,
  docsCount: number,
  type: string
) => {
  const CHUNK_SIZE = 10000000; // 10MB
  const fileSize = file.fileSize;
  const CHUNKS_COUNT = Math.floor(fileSize / CHUNK_SIZE) + 1;
  let promisesArray = [];
  let start, end, blob;
  let fileUploadPer = 0;
  for (let index = 1; index < CHUNKS_COUNT + 1; index++) {
    start = (index - 1) * CHUNK_SIZE;
    end = index * CHUNK_SIZE;
    blob = index < CHUNKS_COUNT ? selectedFile.slice(start, end) : selectedFile.slice(start);
    // Get presigned URL for each part
    let getUploadUrlResp = await ArtefactRepository.getSignedUrl({
      fileName: file.fileName,
      partNumber: index,
      uploadId: uploadData.UploadId
    });
    let { presignedUrl } = getUploadUrlResp["data"];
    let uploadResp = await uploadToS3(presignedUrl, blob, selectedFile, CHUNKS_COUNT);
    fileUploadPer = fileUploadPer + fileUploadProgress;
    promisesArray.push(uploadResp);
  }
  fileUploadPercentage = fileUploadPer;
  let resolvedArray = await Promise.all(promisesArray);
  let uploadPartsArray: any[] = [];

  resolvedArray.forEach((resolvedPromise: any, index) => {
    uploadPartsArray.push({
      ETag: resolvedPromise?.headers?.etag,
      PartNumber: index + 1
    });
  });
  return await completeUploadFile(
    file,
    uploadPartsArray,
    uploadData.UploadId,
    req,
    artefact,
    user,
    docsCount,
    type
  );
};

export const completeUploadFile = async (
  file: any,
  uploadPartsArray: any,
  uploadId: any,
  req: any,
  artefact: any,
  user: any,
  docsCount: number,
  type: string
) => {
  const complete = await ArtefactRepository.completeUpload({
    fileName: file.fileName,
    parts: uploadPartsArray,
    uploadId: uploadId
  });
  if (complete?.data !== null) {
    await completeUploadEvent({ ...artefact, ...req }, docsCount);
    if (type.toLowerCase() === "artefact") {
      await addDocs({ ...complete?.data, ...file }, req, artefact, user);
    } else {
      return complete?.data;
    }
  }
};

const addDocs = async (doc: any, updateReq: any, artefact: any, user: any) => {
  let docObj = {
    artefact_url: doc.Location,
    filename: doc.Key,
    mimetype: doc.mimetype || doc?.mimeType,
    display_filename: doc.display_filename || doc?.fName
  };
  const request = {
    ...artefact,
    ...updateReq,
    ...docObj,
    userId: user?.userId
  };
  const docRes = await ArtefactRepository.addArtefactDocs(request?.artefact_meta_id, request).catch(
    (err) => {
      toast.error("Error while adding docs to db.Please try again later...");
      // console.log("Add artefact docs Error: ", err);
    }
  );
  // const artefact = {
  //   ...artefactMetaData,
  //   ...updateReq
  // };
  toast.success(artefact_messages.success.m010);
  if (docRes?.data) {
    addArtefactDocEvent(request, 1, docRes?.data);
  }
};

export const uploadToS3 = async (
  presignedUrl: any,
  body: any,
  selectedFile: any,
  chunkCount: number
) => {
  let uploadProgress = 0;
  let chunkProgress = 0;
  let completedpercentage = 0;
  let uploadper = 0;
  return axios
    .put(presignedUrl, body, {
      headers: {
        "Content-Type": selectedFile.type,
        reportProgress: true
      },
      onUploadProgress: (progressEvent) => {
        if (chunkCount == 1) {
          completedpercentage = Math.round((progressEvent.loaded / selectedFile?.size) * 100);
          fileUploadProgress = completedpercentage;
        }
        progressEvent.currentTarget.onloadend = (e: any, ds: any) => {
          uploadper = ((uploadProgress + e.loaded) / selectedFile?.size) * 100;
          let completed = uploadper + uploadProgress;
          completedpercentage = Math.round(completed);
          fileUploadProgress = completedpercentage;
        };
        progressEvent.currentTarget.onprogress = (e: any) => {
          if (chunkProgress <= e.loaded) {
            uploadper = ((uploadProgress + e.loaded) / selectedFile.size) * 100;
            let completed = uploadper + uploadProgress;
            completedpercentage = Math.round(completed);
            fileUploadProgress = completedpercentage;
          }
        };
      },
      cancelToken: cancelTokenSource.token
    })
    .catch((error) => {
      if (axios.isCancel(error)) {
        // Handle cancellation request
        console.log("Upload canceled:", error.message);
      } else {
        // Handle other errors
        console.error("Upload error:", error);
      }
    });
};

export const cancelUpload = () => {
  console.log("cancelTokenSource", cancelTokenSource);
  if (cancelTokenSource) {
    // Call cancel on the cancel token source
    cancelTokenSource.cancel("Upload canceled by the user");
    // Reset the cancel token source to null
    cancelTokenSource = null;
  }
};

export const getUploadProgress = (docsToUpload: [], uploadedDocs: any = []) => {
  let uploadPercentage = 0;
  if (uploadedDocs?.length > 0) {
    uploadPercentage = (uploadedDocs.length / docsToUpload.length) * 100;
    return Math.round(uploadPercentage);
  }
  return uploadPercentage;
};

const getFileId = (props: any, doc: any) => {
  const splitFileName = doc?.name.split(".");
  let fileId =
    "program_" +
    props.programId +
    "/" +
    props.type +
    "/" +
    props.id +
    "/" +
    snakeCase(splitFileName[0]) +
    "_" +
    Date.now() +
    `.${getFileExtension(doc)}`;
  return fileId;
};

const getFileData = (props: any, doc: any) => {
  const newFile = {
    fileName: getFileId(props, doc),
    fName: doc?.name,
    mimeType: doc?.type,
    fileSize: doc?.size,
    modified: doc.lastModified,
    fileType: getFileExtension(doc)
  };

  console.log("newFilenewFile", newFile);

  return newFile;
};

const getFileExtension = (doc: any) => {
  const splitFileName = doc?.name.split(".");
  return splitFileName[splitFileName.length - 1];
};
