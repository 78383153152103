import { SupportedLanguages } from "./constants";

function format(value: number, format: string, lng: SupportedLanguages): string {
  if (format.startsWith("date")) {
    return formatDate(value, format, lng);
  }
  return value.toString();
}
function formatDate(value: number, format: string, lng: SupportedLanguages): string {
  let options = toOptions(format);
  // options = JSON.parse(toJsonString(format));
  return format === null ? value.toString() : new Intl.DateTimeFormat(lng, options).format(value);
}
function toOptions(format: string) {
  if (format.trim() === "date") {
    return {};
  } else {
    try {
      return JSON.parse(toJsonString(format));
    } catch (error) {
      console.error(error);
      return null;
    }
  }
}
function toJsonString(format: string) {
  const inner = format
    .trim()
    .replace("date", "")
    .replace("(", "")
    .replace(")", "")
    .split(";")
    .map((param) =>
      param
        .split(":")
        .map((name) => '"' + name.trim() + '"')
        .join(":")
    )
    .join(",");
  return "{" + inner + "}";
}
export default format;
