import React from "react";
import { IconNames } from "@socion-cordio/common/src/components/atoms/icon";
import List from "@socion-cordio/common/src/components/molecules/List";
import { LocalStorage } from "@socion-cordio/common/src/services/storage/storageService";
import { useHistory } from "react-router-dom";
import { allRoutesNames as routeNames } from "@socion-cordio/web/src/navigation/allRouteNames";
import { useDispatch } from "react-redux";
import Moment from "moment";
import AddTelemetryService from "@socion-cordio/common/src/services/telemetryService";

export default function ProfileNavbar(props: any) {
  const history: any = useHistory();
  const dispatch = useDispatch();
  const DATA = [
    {
      id: 1,
      title: "Personal Information",
      iconName: IconNames.personalInfo,
      path: `${routeNames.app}${routeNames.PROFILE}${routeNames.PERSONALINFO}`
    },
    {
      id: 2,
      title: "Change Password",
      iconName: IconNames.changePassword,
      path: `${routeNames.app}${routeNames.PROFILE}${routeNames.CHANGEPASSWORD}`
    },
    {
      id: 3,
      title: "About",
      iconName: IconNames.about,
      path: `${routeNames.app}${routeNames.PROFILE}${routeNames.ABOUT}`
    },
    {
      id: 4,
      title: "Privacy Policy",
      iconName: IconNames.privacyPolicy,
      path: `${routeNames.app}${routeNames.PROFILE}${routeNames.PROFILE_PRIVACYPOLICY}`
    },
    {
      id: 5,
      title: "Terms of Use",
      iconName: IconNames.termsOfUse,
      path: `${routeNames.app}${routeNames.PROFILE}${routeNames.PROFILE_TERMSOFUSE}`
    },
    // {
    //   id: 6,
    //   title: "Settings",
    //   iconName: IconNames.settings
    //   // path: `${routeNames.app}${routeNames.PROFILE}${routeNames.SETTINGS}`
    // },
    {
      id: 99,
      title: "Logout",
      iconName: IconNames.logOut
      // path: `${routeNames.IAM.root}${routeNames.IAM.login}`
    }
  ];

  const handleLogout = (item: number) => {
    if (item === 99) {
      updateTelemetry();
      history.push(routeNames.IAM.root + routeNames.IAM.login);
      LocalStorage.removeStoredKeys();
      dispatch({ type: "USER_LOGOUT" });
    }
  };

  const updateTelemetry = async () => {
    const user: any = await LocalStorage.getStorage("user");
    const body = {
      timestamp: Moment().toLocaleString(),
      createdAt: Moment().toLocaleString(),
      deleted: "false",
      updatedAt: Moment().toLocaleString(),
      eventType: "Logout"
    };
    AddTelemetryService(body, undefined, user);
  };

  return <List getSidePanelWidth={props.getSidePanelWidth} onLogout={handleLogout} data={DATA} />;
}
