import { Notifications } from "@socion-cordio/common/src/models/artefactNotifications";
import { NOTIFICATIONS } from "@socion-cordio/common/src/modules/notifications/actions/actionTypes";

export interface ITaskLoading {
  getNotifications: boolean;
}

export interface ITaskError {
  getNotifications: string;
}

export interface INotificationsState {
  notificationsList: Notifications;
  loading: ITaskLoading;
  error: ITaskError;
}

export const notificationsInitialState: INotificationsState = {
  loading: {
    getNotifications: false
  },
  error: {
    getNotifications: ""
  },
  notificationsList: null
};

const NotificationsReducer = (
  state = notificationsInitialState,
  action: { type: string; payload: any }
): INotificationsState => {
  switch (action.type) {
    case NOTIFICATIONS.GET_NOTIFICATIONS.LOADING:
      return {
        ...state,
        loading: { ...state.loading, getNotifications: true },
        error: { ...state.error, getNotifications: null }
      };
    case NOTIFICATIONS.GET_NOTIFICATIONS.SUCCESS:
      return {
        ...state,
        loading: {
          ...state.loading,
          getNotifications: false
        },
        error: {
          ...state.error,
          getNotifications: null
        },
        notificationsList: action.payload
      };
    case NOTIFICATIONS.GET_NOTIFICATIONS.ERROR:
      return {
        ...state,
        loading: {
          ...state.loading,
          getNotifications: false
        },
        error: {
          ...state.error,
          getNotifications: action.payload
        }
      };

    default: {
      return state;
    }
  }
};

export default NotificationsReducer;
