import React, { ReactElement, useEffect, useState } from "react";
import {
  StyleSheet,
  View,
  Modal as RNModal,
  TouchableOpacity,
  TouchableWithoutFeedback
} from "react-native";
import { Text, TextSize, FontWeight } from "@socion-cordio/common/src/components/atoms/text";
import Icon, { IconNames } from "@socion-cordio/common/src/components/atoms/icon";
import { Image } from "@socion-cordio/common/src/components/atoms/image";
import { Divider, Orientations } from "@socion-cordio/common/src/components/atoms/divider";
import { colorPallete } from "@socion-cordio/common/src/assets/styles/colors";
import NotificationModal from "@socion-cordio/common/src/components/organisms/notificationModal";
import { useHistory } from "react-router-dom";
import { allRoutesNames as routeNames } from "@socion-cordio/web/src/navigation/allRouteNames";
import { useSelector } from "react-redux";
import { LocalStorage } from "@socion-cordio/common/src/services/storage/storageService";
import { ApiClient } from "@socion-cordio/common/src/network/apiClient";
import { applicationAccessEndPoints } from "@socion-cordio/common/src/repositories/endPoints";
import { toast } from "react-toastify";
import ExternalApps from "@socion-cordio/common/src/components/organisms/externalApps";
import ArtefactNotificationModal from "./badges/artefactNotificationModal";
import { CONFIG } from "@socion-cordio/common/src/config/envConstant";

export default function Header(props: any): ReactElement {
  const { currentEntityName } = props;
  const [profile, setProfile] = useState(undefined);
  const [programDetails, setProgramDetails] = useState(null);
  const [isWorkspacePage, setIsWorkspacePage] = useState(false);
  const [viewButtonDetails, setViewButtonDetails] = useState(false);
  const history: any = useHistory();
  const user = JSON.parse(localStorage.getItem("user"));
  const updatedUserInfo = JSON.parse(localStorage.getItem("updatedProfileInfo"));
  const [name, setName] = useState(user?.name);
  const [showExternalApp, setShowExternalApp] = useState(false);
  const [isExternalVisible, setIsExternalVisible] = useState(false);
  const data: any = useSelector((state) => state);

  useEffect(() => {
    processProfile();
    processUserName();
  }, [updatedUserInfo, user, data?.profile?.userProfileData.response]);

  const processProfile = () => {
    // if (data?.profile?.userProfileData?.response?.photo === "") {
    //   setProfile(undefined);
    // }
    if (updatedUserInfo?.photo) {
      setProfile(updatedUserInfo?.photo);
    } else setProfile(user?.photo);
  };

  const processUserName = () => {
    if (updatedUserInfo?.name) {
      setName(updatedUserInfo?.name);
    } else setName(user?.name);
  };

  useEffect(() => {
    window.location.pathname == `${routeNames.app}${routeNames.workspace}`
      ? setIsWorkspacePage(false)
      : window.location.pathname.includes(`${routeNames.PROFILE}`)
      ? setIsWorkspacePage(false)
      : [setIsWorkspacePage(true), getProgramDetails()];
  }, [window.location.pathname]);

  const getProgramDetails = async () => {
    const programDetails: any = await LocalStorage.getStorage("programDetails");
    setProgramDetails(programDetails);
  };

  const handleExternalApp = () => {
    getDasboardAccess();
  };

  const getDasboardAccess = async () => {
    try {
      const payload = {
        programId: programDetails.program.id,
        applicationId: CONFIG.REACT_APP_DASHBOARD_ID
      };
      const dasboardAccessResponse = await ApiClient.get(
        applicationAccessEndPoints.getApplicationAccess(),
        payload
      );
      if (dasboardAccessResponse.length != 0) {
        setShowExternalApp(true);
      }
    } catch (error) {
      toast.error("Something went wrong");
    }
  };

  useEffect(() => {
    let currentUrl = window.location.href;
    if (currentUrl.includes("app/workspace") || currentUrl.includes("profile/personal-info"))
      setIsExternalVisible(false);
    else setIsExternalVisible(true);
  });

  return (
    <View style={styles.header}>
      <View>
        <Text fontWeight={FontWeight.Regular} testId="header" textSize={TextSize.Small}>
          <Icon
            name={IconNames.socion}
            customStyle={styles.logoStyle}
            onPress={() => history.push(`${routeNames.app}${routeNames.workspace}`)}
          />
          <>
            <Divider
              orientation={Orientations.Vertical}
              dividerStyle={{ height: 20, borderColor: colorPallete.cordioTaupe }}
            />
            <View style={styles.headerAlignment}>
              <Text
                fontWeight={FontWeight.Bold}
                testId="header"
                textSize={TextSize.Large}
                style={styles.socion}
              >
                {"Artefact Application"}
              </Text>
            </View>
          </>

          {/* {isWorkspacePage && (
            <>
              <Divider
                orientation={Orientations.Vertical}
                dividerStyle={{ height: 20, borderColor: colorPallete.cordioTaupe }}
              />
              <View style={styles.headerAlignment}>
                <Text
                  fontWeight={FontWeight.Bold}
                  testId="header"
                  textSize={TextSize.Large}
                  style={styles.socion}
                >
                  {currentEntityName || programDetails?.program?.entity?.name}
                </Text>
              </View>
            </>
          )} */}
        </Text>
      </View>
      <View style={styles.rightContent}>
        <Icon
          name={IconNames.help}
          customStyle={styles.iconStyle}
          onPress={() => {
            window.open(CONFIG.REACT_APP_HELP_DIRECTION, "_blank");
          }}
        />
        <Icon
          name={IconNames.notification}
          customStyle={viewButtonDetails ? [styles.iconStyle, styles.iconColor] : styles.iconStyle}
          onPress={() => setViewButtonDetails(true)}
        />
        {/* {isExternalVisible && (
          <Icon
            name={IconNames.externalApps}
            customStyle={styles.iconStyle}
            onPress={handleExternalApp}
          />
        )} */}
        <Divider orientation={Orientations.Vertical} dividerStyle={styles.dividerContainer} />
        <TouchableOpacity
          style={styles.nameContainer}
          onPress={() =>
            history.push(`${routeNames.app}${routeNames.PROFILE}${routeNames.PERSONALINFO}`)
          }
        >
          <Image
            testId="socion"
            imageStyle={styles.image}
            source={profile || require("@socion-cordio/common/src/assets/images/user_circle.svg")}
          />
          <Text
            fontWeight={FontWeight.Bold}
            testId="profileName"
            textSize={TextSize.Large}
            style={styles.profileName}
            onPress={() =>
              history.push(`${routeNames.app}${routeNames.PROFILE}${routeNames.PERSONALINFO}`)
            }
          >
            {name}
          </Text>
        </TouchableOpacity>
        <View style={styles.dummyContainer}></View>
        {viewButtonDetails && (
          <View style={styles.centeredView}>
            <RNModal
              animationType="fade"
              transparent={true}
              visible={viewButtonDetails}
              onRequestClose={() => {
                props.setModalVisible();
              }}
            >
              <TouchableOpacity
                style={styles.centeredView}
                activeOpacity={1}
                onPressOut={() => setViewButtonDetails(!viewButtonDetails)}
              >
                <TouchableWithoutFeedback>
                  <View style={styles.modalView}>
                    <ArtefactNotificationModal
                      onClose={() => {
                        setViewButtonDetails(false);
                      }}
                    />
                  </View>
                </TouchableWithoutFeedback>
              </TouchableOpacity>
            </RNModal>
          </View>
        )}
        {
          showExternalApp && (
            // <TouchableOpacity onPressOut={setShowExternalApp(false)}>
            <ExternalApps />
          )
          // </TouchableOpacity>
        }
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  rightContent: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center"
  },
  header: {
    // position: "sticky",
    position: "fixed",
    zIndex: 10,
    top: 0,
    left: 0,
    backgroundColor: colorPallete.white,
    height: 50,
    width: "100%",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    shadowColor: colorPallete.cordioTaupeLight3,
    shadowOffset: {
      width: 0,
      height: 2
    },
    shadowOpacity: 0.25
  },
  iconStyle: {
    color: colorPallete.cordioTaupe,
    paddingEnd: 35
  },
  iconColor: {
    backgroundColor: colorPallete.cordioTaupeLight4,
    marginRight: 26,
    paddingTop: 15,
    paddingLeft: 15,
    width: "49px",
    height: "50px"
  },
  logoStyle: {
    color: colorPallete.cordioRed,
    marginLeft: 16,
    marginRight: 16,
    borderColor: colorPallete.cordioTaupe
  },
  image: {
    width: 25,
    height: 25,
    borderRadius: 25 / 2,
    marginEnd: 10
  },
  dividerContainer: {
    height: 20,
    marginRight: 30,
    borderColor: colorPallete.cordioTaupe
  },
  profileName: {
    fontWeight: FontWeight.Bold
  },
  dummyContainer: {
    width: 100
  },
  socion: {
    fontWeight: FontWeight.Bold,
    color: colorPallete.textBlack,
    paddingStart: 16
  },
  headerAlignment: {
    top: -2
  },
  nameContainer: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center"
  },
  centeredView: {
    flex: 1,
    justifyContent: "flex-end",
    alignItems: "flex-end",
    marginTop: "50px",
    backgroundColor: "rgba(0,0,0,0.5)"
  },
  modalView: {
    height: "100%",
    width: 501,
    backgroundColor: colorPallete.white,
    borderBottomStartRadius: 10,
    alignItems: "flex-end",
    shadowColor: colorPallete.black,
    shadowOffset: {
      width: 0,
      height: 2
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5
  }
});
