import { ApiClient } from "@socion-cordio/common/src/network/apiClient";
import { taskEndPoints } from "@socion-cordio/common/src/repositories/endPoints";
import { TaskApiClient } from "@socion-cordio/common/src/network/taskApiClient";
import { Task, TaskResponse } from "@socion-cordio/common/src/models/task";

class TaskRepository {
  getTasks = async (body: { programId: number }): Promise<Task> => {
    let data = await TaskApiClient.post(taskEndPoints.getTaskList(), body);
    return data;
  };

  createTask = async (body: TaskResponse): Promise<any> => {
    let data = await TaskApiClient.post(taskEndPoints.createTask(), body);
    return data;
  };

  readTask = async (body: { taskId: string }): Promise<any> => {
    let data = await TaskApiClient.get(taskEndPoints.readTask(body.taskId));
    return data;
  };

  updateTask = async (body: { taskId: string; data: any }): Promise<any> => {
    let data = await TaskApiClient.put(taskEndPoints.updateTask(body.taskId), body.data);
    return data;
  };

  deleteTask = async (body: {
    taskId: string;
    updatedBy: string;
    topicId: string | number;
    status: string;
    source: string;
    startDate: any;
  }): Promise<any> => {
    let data = await TaskApiClient.delete(taskEndPoints.deleteTask(body.taskId), {
      status: body.status,
      topicId: body.topicId,
      updatedBy: body.updatedBy,
      source: body.source,
      startDate: body.startDate
    });
    return data;
  };

  getTaskById = async (body: { taskId: string }): Promise<any> => {
    let data = await TaskApiClient.get(taskEndPoints.taskById(body.taskId));
    return data;
  };

  uploadFiles = async (body: { data: any; params: {} }): Promise<any> => {
    let data = await TaskApiClient.post(taskEndPoints.uploadFiles(), body.data, body.params);
    return data;
  };

  getSubmissions = async (body: any): Promise<any> => {
    let data = await TaskApiClient.post(taskEndPoints.submissionList(), body);
    return data;
  };

  getSubmissionDataById = async (body: { submissionId: string }): Promise<any> => {
    let data = await TaskApiClient.get(taskEndPoints.submissionById(body.submissionId));
    return data;
  };

  getSubmitterById = async (body: { userId: string }): Promise<any> => {
    let data = await TaskApiClient.get(taskEndPoints.submitterById(body.userId));
    return data;
  };

  // createTaskEntity = async (body: any): Promise<any> => {
  //   let data = await ApiClient.post(taskEndPoints.taskEntity(), body);
  //   return data;
  // };

  // deleteTaskEntity = async (body: any): Promise<any> => {
  //   let data = await ApiClient.put(taskEndPoints.taskEntity(), body);
  //   return data;
  // };

  generatePdf = async (body: { fileToken?: string; fileUrl?: string }): Promise<any> => {
    let data = await TaskApiClient.post(taskEndPoints.generatePdf(), body);
    return data;
  };
}

const taskRepository = new TaskRepository();
export { taskRepository as TaskRepository };
