export const messages = {
  success: {
    m01: "successfully created Task: {name}",
    m02: "successfully updated Task: {name}",
    m03: "successfully deleted Task: {name}"
  },
  error: {
    m01: "Failed creating Task: {name}. Please try again later...",
    m02: "Failed updating Task: {name}. Please try again later...",
    m03: "Failed deleting Task: {name}. Please try again later...",
    m04: "Task {name} is already present in this topic",
    m05: "Processing the submission. Please wait for some time...",
    m06: "Unable to get submission data. Please try again later...",
    m07: "Please update the task end date, task end date should always be >= current date",
    m08: "Pdf is not available"
  },
  info: {
    m01: "Do you really want to delete this task? This information cant be recovered. Please confirm.",
    m02: "This action will make the task Inactive. Do you want to continue?",
    m03: "Are you sure you want to Publish this task"
  }
};

export const artefact_messages = {
  success: {
    m01: "Artefact {name} is created.",
    m02: "Artefact {name} is updated.",
    m03: "Artefact {name} is deleted",

    m04: "Artefact Type {name} is created",
    m05: "Artefact Type {name} is activated",
    m06: "Artefact Type {name} is updated",
    m07: "Artefact Type {name} deleted successfully",
    m08: "Artefact Type {name} is deactivated successfully",

    m09: "Submitter {name} is deleted from this Artefact",

    m010: "Document added to the Artefact",
    m011: "Document uploaded Succesfully.",
    m012: "Document deleted from this Artefact",

    m013: "Notification is deleted successfully",
    m014: "Deleted Template successfully",

    m015: "Artefact {name} is {status}"
  },
  error: {
    m01: "Failed creating artefact: {name}. Please try again later...",
    m02: "Failed updating artefact: {name}. Please try again later...",
    m03: "Failed deleting artefact: {name}. Please try again later...",
    m04: "Pdf is not available",
    m05: "Failed creating artefact type: {name}. Please try again later...",
    m06: "Failed updating artefact type: {name}. Please try again later...",
    m07: "Failed deleting artefact type: {name}. Please try again later...",
    m08: "Failed activating artefact type: {name}. Please try again later...",
    m09: "Failed deactivating artefact type: {name}. Please try again later...",
    m010: "Minimun one doc should be present",
    m011: "Please add only PDF files.",
    m012: "Something went wrong...!!!",
    m013: "Unable to delete Submitter. Please try again later...'",
    m014: "Unable to delete Notification. Please try again later...",
    m015: "Unauthorized Access. Please contact system administrator.",
    m016: "Unable to fetch Artefact Type details",
    m017: "Unable to get artefact: {name}. Please try again later...",
    m018: "Minimun one submitter should be present",
    m019: "Unable to delete doc. Please try again later...",
    m020: "Failed reviewing artefact: {name}. Please try again later...",
    m021: "Failed uploading document. Please try again later...",
    PDA_PROGRAM_ERROR: "The program you wanted isn't available, so we chose another one for you."
  },
  info: {
    m01: "\n\nAre you sure you want to delete this Artefact ?",
    m02: "Artefact with {type} and {location} already exists.\n \n \n Do you want to continue ?",
    m03: "Are you sure, You want to approve the artefact",
    m04: "Are you sure, You want to reject the artefact",
    m05: "Please add comments to reject the artefact.",
    m06: "Are you sure you want to delete this doc ?",
    m07: "Are you sure you want to delete this submitter ?",
    m08: "Are you sure you want to deactivate the Artefact Type ?",
    m09: "Are you sure you want to reactivate the Artefact Type ?"
  }
};
