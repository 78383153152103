import { CONFIG } from "@socion-cordio/common/src/config/envConstant";

export const profileEndPoints = {
  userProfile: () => `/profile`,
  uploadFile: () => `/upload`,
  changePassword: () => `/user/change-password`,
  getUserProfile: () => `/user/get-profile`,
  getUserRole: () => `/user/role`,
  getUserQualification: () => `/user/qualification`,
  updateUserProfile: () => `/user/update-profile`,
  sendOtp: (phoneNumber: number, typeOfOTP: string, countryCode: string) =>
    `/user/send-otp?phoneNumber=${phoneNumber}&typeOfOTP=${typeOfOTP}&countryCode=${countryCode}`,
  updateEmailValidateOtp: () => `/user/validate-otp`,
  updateEmail: (emailId: string) => `/user/update-email-id?emailId=${emailId}`,
  deleteUserQualification: (qualificationId: string) => `/user/qualification/${qualificationId}`,
  deleteUserRole: (roleId: string) => `/user/role/${roleId}`,
  getUserEligibleProgramsForRole: () => `/entity/program/qualification-role/user/enabled`,
  getUserEligibleProgramsForQualification: () => `/entity/program/qualification-role/user/enabled`,
  validateOtp: () => `/user/validate-otp`,
  sendOtpNewPhone: (phoneNumber: number, countryCode: string) =>
    `/user/v3/send-otp-new-phone?phoneNumber=${phoneNumber}&countryCode=${countryCode}`,
  fileUpload: () => `/user/file/upload`,
  addQualification: () => `/user/qualification/add`,
  addRole: () => `/user/role/add`,
  updateBaseLocation: () => `/user/location`,
  revokeLocationConsent: () => `/user/location`,
  updatePersonalInfo: () => `/user/update-pii/status`,
  generateProfileCard: () => `/user/generate/profile-card`,
  updatePhoto: () => `/user/update-photo`,
  placeAutoComplete: () => `/user/map/autocomplete`,
  placeDetails: () => `/user/map/details`,
  getSelectedUserDetails: (userId: number) => `/user/private/details/profile-role/${userId}`,
  getMapMyIndiaToekn: () => `/user/map/map-my-india/auth/token`
};

export const loginEndPoints = {
  postCredentials: () => `/user/v4/login`,
  getCountryCode: () => `/user/get-country-codes`,
  loginUser: () => `user/v4/login/otp`,
  validateLoginUserOtp: () => `user/v4/validate-otp/login`
};

export const forgotPasswordEndPoints = {
  requestForgotOtp: () => `user/v4/send-otp`,
  validateForgotPasswordOtp: () => `user/v4/validate-otp`,
  forgotPasswordUpdate: () => `user/v4/forgot-password`
};

export const topNavbarEndPoints = {
  postNotifications: (pageNumber: Number, pageSize: Number) =>
    `/session/notifications/v2/timezone?pageNumber=${pageNumber}&pageSize=${pageSize}`,
  deleteReadNotification: () => `/session/notifications/status`
};

export const signupEndPoints = {
  signupUser: () => `/user/v3/user-create-web`,
  resendOtp: () => "/user/v3/re-send-register-otp",
  validateOtp: () => `/user/v3/validate-otp`,
  setPassword: () => `/user/v3/set-password`,
  getverificationCode: () => `/user/v3/send-otp`,
  updateForgotPassword: () => `user/v3/forgot-password`,
  signupNewUser: () => `user/v4/create-user-mobile`,
  resendNewOtp: () => `/user/v4/re-send-register-otp`,
  validateNewOtp: () => `/user/v4/validate-otp`,
  setNewPassword: () => `/user/v4/set-password`,
  getNewVerificationCode: () => `/user/v4/send-otp`
  // updateForgotPassword: () => `/user/v4/forgot-password`
};

export const applicationAccessEndPoints = {
  getApplicationAccess: () => `/access/role-access/program/user`
};

export const springshedEndPoints = {
  getPrograms: () => `/entity/program`,
  getEntityDetails: (entityId: number) => `/entity/registrySearch/${entityId}`,
  getEntityRoles: (entityId: number) => `/entity/registrySearch/role/${entityId}`,
  getProfileFromPhone: () => `/user/get-profile-from-phoneNumber`,
  getProfileByUserId: (userId: number) => `/user/private/details/${userId}`,
  inviteEntity: () => `/entity/invite`,
  registerEntity: () => `/entity/registerEntity`,
  fileUpload: () => `/user/file/upload`,
  createProgram: () => `/entity/program`,
  encryptData: () => `/user/encrypt/data`,
  addEntityMember: () => `/entity/registerEntityRoles`,
  activateRemoveMember: () => `/entity/registryEntityRolesUpdate`,
  getUserDetails: (entityId: number, userId: string) =>
    `/entity/registryRolesSearch/${entityId}/${userId}`,
  getUserDataByQR: (userId: string) => `/user/private/details/profile-role/${userId}`,
  getAllUserPrograms: (userId: string) => `/entity/program/list/user/${userId}`,
  postProgramDocument: (programId: any) => `/entity/program/${programId}/document`,
  deleteProgramDocument: (programId: string, documentId: string) =>
    `/entity/program/${programId}/document?documentId=${documentId}`,
  updateEntityDetails: () => `/entity/registryEntityUpdate`,
  updateEntityDocuments: () => `/entity/registerEntityDocs`,
  deleteEntityDocuments: (docId: string, isDeleted: boolean) =>
    `entity/registryEntityDocsUpdate/${docId}/${isDeleted}`,
  uploadEntityDocument: (entityId: number) => `entity/${entityId}/documents`
};

export const topicEndPoints = {
  getTopics: () => `/entity/topic-details/metrics/v2`,
  getTopicDetails: () => `/entity/topic-details/metrics`,
  addTopicDetails: () => `/entity/topic`,
  updateTopicDetails: (topicId: number) => `/entity/topic/${topicId}`,
  fileUpload: () => `/user/file/upload`,
  fileUploadResponse: () => `/entity/content`,
  fileUploadVideo: () => `/entity/content/upload/vimeo`,
  deleteTopic: (topicId: number, entityId: number, programId: number) =>
    `/entity/topic/${topicId}?entityId=${entityId}&programId=${programId}`,
  addNewSubTopicLink: () => `/entity/topic-sub-content`,
  updateSubTopicLink: (contentId: number) => `/entity/topic-sub-content/${contentId}`,
  deleteSubTopicLink: (contentId: number) => `/entity/topic-sub-content/${contentId}`,
  deleteContentFile: (contentId: number) => `/entity/content/${contentId}`,
  addSurveyLink: () => `/entity/survey`,
  hardDeleteTopic: (topicId: number, entityId: number, programId: number) =>
    `/entity/topic/${topicId}/delete?programId=${programId}&entityId=${entityId}`,
  getParticipantList: () => `session/user-role/details`,
  getTrainersList: () => `session/user-role/details`,
  getParticipantListCSV: () => `session/user-role/details/csv`,
  getTrainersListCSV: () => `session/user-role/details/csv`
};

export const telemetryUploadEndPoints = {
  telemetryUpload: () => `/telemetry/telemetry`,
  telemetryBadgeUpload: () => `/entity/list/telemetry`
};

export const programEndPoints = {
  getProgramDetails: (programId: number) => `/entity/program/${programId}`,
  getProgramDocumentsById: (programId: number) => `/entity/program/${programId}/document`,
  getProgramMembers: (programId: number, entityId: number) =>
    `/entity/program/members?programId=${programId}&entityId=${entityId}`,
  getRolePrivileges: (programId: number) => `/access/role-access/program/${programId}`,
  getSuperAdminPrivileges: (programId: number) => `/access/application-access/program/${programId}`,
  updateSuperAdminPrivileges: () => `/access/application-access/program`,
  updateRolePrivileges: () => `/access/role-access/program`,
  getUserLicensesById: (programId: number) => `/entity/program/${programId}/licence/user`,
  updateDescription: (programId: number) => `/entity/program/${programId}/description`,
  updateSettings: (programId: number, key: string, value: boolean | string) =>
    `/entity/program/${programId}/settings?key=${key}&value=${value}&scanType=""`,
  updateSingleScanSettings: (programId: number, key: string, value: boolean | string) =>
    `/entity/program/${programId}/settings?key=${key}&value=${false}&scanType=${value}`,
  updateProgramDetails: (programId: number) => `/entity/program/${programId}`,
  getUserRolesProgram: () => `/entity/user/program-entity/roles`,
  getUserList: (programId: number) => `/entity/users/program/${programId}`,
  addProgramMember: () => `/entity/program/add-roles`,
  deactivateMember: (programId: string, userId: string, role: string) =>
    `/entity/program/${programId}/roles/${userId}/deactivate/${role}`,
  activateMember: (programId: string, userId: string, role: string) =>
    `/entity/program/${programId}/roles/${userId}/reactivate/${role}`,
  activateDetactivateProgram: (programId: string, deleted: boolean) =>
    `/entity/program/${programId}/status/${deleted}`,
  getPresignedUrl: (type: string, name: string) =>
    `/user/file/download?appType="pda"&fileType=${type}&fileName=${name}`,
  getSelectedMemberRole: (userId: number, programId: number) =>
    `/entity/program/${programId}/roles/user/${userId}`
};

export const verifyEndPoints = {
  getRolesVerification: (programId: number) => `/user/role/list/${programId}`,
  getQualificationVerification: (programId: number) => `/user/qualification/list/${programId}`,
  approveRejectRole: () => `/user/role/approve`,
  approveRejectQualification: () => `/user/qualification/approve`
};

export const badgesEndPoints = {
  getBadgesTable: (programId: number) => `/badge/program?id=${programId}`,
  addBadge: () => `/badge/add`,
  updateBadge: () => `/badge/update`,
  deleteBadge: (badgeId: number, value: boolean) =>
    `/badge/status/${badgeId}?activateBadge=${value}`,
  activateReactivateBadge: (badgeId: number) => `/badge/status/${badgeId}`,
  getBadgeUsersList: () => `/badge/assign/list`,
  previewBadgesUsers: () => `/badge/assign/preview`,
  assignBadgesUsers: () => `/badge/assign/add`,
  getAssigedBadgeUser: () => `/badge/user/list`,
  unassignBadgeUsers: (programId: number) => `/badge/assign/update/programId/${programId}`,
  downloadBadgeCsvList: (id: string) => `/badge/user/list/csv?badgeId=${id}`
};

export const qualificationPackEndPoints = {
  getQualificationPackTable: (programId: number) => `/bundle/program/${programId}`,
  addQualificationPack: () => `/bundle`,
  getBundleTopicList: () => `/entity/topic-details/metrics/v2`,
  updateQualificationPack: (bundleId: number) => `/bundle/${bundleId}`,
  getQualificationPackDetails: (bundleId: number) => `/bundle/${bundleId}/report/v2`,
  getQualificationPackBundleDetails: (bundleId: number) => `/bundle/${bundleId}/topic`,
  getQualificationParticipantMapping: (bundleId: number, selectionNo: number) =>
    `/bundle/${bundleId}/${selectionNo}/report/v2`,
  getFilterLocations: () => `/entity/program/location-details`,
  getBadgeRoles: () => `/badge/program`,
  getParticipantMappingCSV: (bundleId: number, selectionNo: number) =>
    `/bundle/${bundleId}/${selectionNo}/report/csv`
};

export const dashboardEndPoints = {
  getSessionsInfo: (programId: number) =>
    `/session/program/dashboard/metrics/count?programId=${programId}`,
  getBadgesInfo: (programId: number) => `/badge/private/metrics?programId=${programId}`,
  getRolesAndQualificationsInfo: () => `/entity/verification/metrics/count`,
  getQpInfo: (programId: number) => `/bundle/program/${programId}/matrix`,
  setToken: () => `/setToken`
};

export const sessionPackEndPoints = {
  getSessionDetails: () => `/session/session-details/metrics/v3`,
  updateSessionDetails: () => `/session/create`,
  getCompleteSessionDetails: (sessionId: number) =>
    `/session/get-complete-session-information/${sessionId}`,
  addSessionAddtionalLinks: (sessionId: number) => `/session/link/${sessionId}`,
  deleteAdditionalLink: (sessionId: number, linkId: number) =>
    `/session/link/${sessionId}/${linkId}`,
  addMemberToSession: () => `/session/member/add`,
  deleteSession: (sessionId: number) => `/session/delete/${sessionId}`,
  editSessionDetails: () => `/session/update`,
  getSessionParticipationList: () => `/session/user-role/details`,
  deleteSessionMember: (userId: string, sessionId: string) =>
    `/session/member/remove?userId=${userId}&sessionId=${sessionId}`,
  updateSessionMember: () => `/session/member/update`
};

export const participationDetailsEndPoints = {
  getParticipantList: () => `/session/user-role/details`,
  getTrainersList: () => `/session/user-role/details`,
  getParticipantAttestationList: (programId: string) =>
    `/telemetry/telemetry/program/${programId}/attestation-details`,
  getTrainerAttestationList: (programId: string) =>
    `/telemetry/telemetry/program/${programId}/attestation-details`,
  getTrainerByTopicList: (programId: string) => `/session/trainers-for-topics/program/${programId}`,
  getParticipantListCSV: () => `/session/user-role/details/csv`,
  getTrainersListCSV: () => `/session/user-role/details/csv`,
  getParticipantAttestationListCSV: (programId: string) =>
    `/telemetry/program/${programId}/csv/attestation-details`,
  getTrainerAttestationListCSV: (programId: string) =>
    `/telemetry/program/${programId}/csv/attestation-details`,
  getTrainerByTopicListCSV: (programId: string) =>
    `/session/trainers-for-topics/program/${programId}/csv`
};

export const taskEndPoints = {
  // getAllUserTaskPrograms: (userId: string) => `/entity/program/list/user/${userId}&applicationId=${process.env.REACT_APP_TASK_APPLICATION_ID}`,
  // getAllUserTaskPrograms: (userId: string) => `/entity/program/list/user/${userId}`,
  getAllUserTaskPrograms: (userId: string) =>
    `/access/role-access/user/all?userId=${userId}&applicationId=${CONFIG.REACT_APP_TASK_APPLICATION_ID}`,
  getTaskPrograms: () => `/entity/program`,
  getTaskList: () => `/taskservice/api/v1/task/list`,
  createTask: () => `/taskservice/api/v1/task/create`,
  readTask: (taskId: string) => `/taskservice/api/v1/task/read/${taskId}`,
  updateTask: (taskId: string) => `/taskservice/api/v1/task/update/${taskId}`,
  deleteTask: (taskId: string) => `/taskservice/api/v1/task/delete/${taskId}`,
  uploadFiles: () => `/taskservice/api/v1/upload/file`,
  taskById: (taskId: string) => `/taskservice/api/v1/task/read/${taskId}`,
  submissionList: () => `/taskservice/api/v1/submissions/list`,
  submissionById: (submissionId: string) => `/taskservice/api/v1/submissions/${submissionId}`,
  submitterById: (userId: string) => `/taskservice/api/v1/user/detail/${userId}`,
  taskEntity: () => `/entity/task`,
  generatePdf: () => `/taskservice/api/v1/upload/file/download`
};

export const artefactEndPoints = {
  getAllUserTaskPrograms: (userId: string) =>
    `/access/role-access/user/all?userId=${userId}&applicationId=${CONFIG.REACT_APP_ARTEFACT_APPLICATION_ID}`,
  getNotifications: () => `/api/v1/session/notifications`,
  getArtefactList: (userId?: string) =>
    userId ? `/api/v1/search?userid=${userId}` : `/api/v1/search`,
  getArtefactTypeList: (pid: number) => `/api/v1/artefacttype/${pid}/filters`,
  addArtefactType: (pid: number) => `/api/v1/artefacttype/${pid}`,
  editArtefactTypeDetails: (pid: number, artefactTypeId: string) =>
    `/api/v1/artefacttype/${pid}/${artefactTypeId}`,
  getArtefactTypeDetails: (pid: number, artefactTypeId: string) =>
    `/api/v1/artefacttype/${pid}/${artefactTypeId}`,
  deleteArtefactType: (pid: number, artefactTypeId: string) =>
    `/api/v1/artefacttype/${pid}/${artefactTypeId}`,
  deleteArtefactTypeDocs: (pid: number, docId: string) =>
    `/api/v1/artefacttype/${pid}/template/${docId}`,
  getArtefactDetails: (artefactID: string) => `/api/v1/artefactmetadata/${artefactID}`,
  getArtefactDocs: (artefactID: string) => `api/v1/artefactmetadata/${artefactID}/docs`,
  getArtefactWorkFlow: (artefactID: string) => `/api/v1/workflowhistory/${artefactID}`,
  deleteArtefactMetadata: (artefactID: string) => `/api/v1/artefactmetadata/${artefactID}`,
  startUpload: () => `/api/v1/upload/start`,
  signedUrl: () => `/api/v1/upload/getsignedurl`,
  completeUpload: () => `/api/v1/upload/complete`,
  searchUsers: () => `/api/v1/user/search`,
  createArtefact: () => `/api/v1/artefactmetadata`,
  updateArtefact: (artefactId: string) => `/api/v1/artefactmetadata/${artefactId}`,
  addArtefactDoc: (artefactId: string) => `/api/v1/artefactmetadata/${artefactId}/doc`,
  deleteArtefactDoc: (artefactId: string, docId: string) =>
    `/api/v1/artefactmetadata/${artefactId}/doc/${docId}`,
  deleteArtefactSubmitter: (artefactId: string, submitterId: string) =>
    `/api/v1/submitter/${artefactId}/${submitterId}`,
  addNotification: () => `/api/v1/session/notifications`,
  deleteNotification: (id: string) => `/api/v1/session/notifications/${id}`,
  deleteAllNotifications: (userId: string) => `/api/v1/session/notifications/${userId}?all=true`,
  readNotification: (notification_id: string) =>
    `/api/v1/session/notifications/read/${notification_id}`,
  addWorkflow: (artefactId: string) => `/api/v1/workflowhistory/${artefactId}`,
  telemetry: () => `/telemetry/artefact`,
  setCookie: () => `/api/v1/auth/artefact/setCookie`,
  validateToken: () => `/api/v1/auth/validate`,
  sessionNotification: () => `/session/notifications/add`,
  telemetryWrapper: () => `/api/v1/telemetry/artefact`
};
