import { Roles } from "@socion-cordio/common/src/constants/roles";
import { allRoutesNames as routeNames } from "@socion-cordio/web/src/navigation/allRouteNames";
import LoginModule from "@socion-cordio/web/src/modules/login";
import LoginPage from "../components/loginPage";
import { SignupPage } from "../components/signupPage";
const loginRoutes = () => {
  return [
    {
      path: routeNames.IAM.root,
      component: LoginModule,
      isExact: false,
      permission: [Roles.Admin, Roles.AreaManager, Roles.StoreAccess],
      children: [
        {
          path: routeNames.IAM.login,
          component: LoginPage,
          isExact: false,
          permission: [Roles.Admin, Roles.AreaManager, Roles.StoreAccess]
        },
        {
          path: routeNames.IAM.signup,
          component: SignupPage,
          isExact: false,
          permission: [Roles.Admin, Roles.AreaManager, Roles.StoreAccess]
        }
      ]
    }
  ];
};

const LoginRouter = () => {
  return loginRoutes();
};

export default LoginRouter;
