import React, { ReactElement, useEffect, useState } from "react";
import { StyleSheet, useWindowDimensions, View, TouchableOpacity } from "react-native";
import { colorPallete } from "@socion-cordio/common/src/assets/styles/colors";
import {
  Text,
  TextSize,
  FontWeight,
  FontFamily
} from "@socion-cordio/common/src/components/atoms/text";
import { DownloadQR } from "@socion-cordio/common/src/components/atoms/downloadQR";
import { GenerateQR } from "@socion-cordio/common/src/components/atoms/generateQR";
import Icon, { IconNames } from "@socion-cordio/common/src/components/atoms/icon";
import UserInputSecondary from "@socion-cordio/common/src/components/molecules/userInputSecondary";
import Button, { ButtonType } from "@socion-cordio/common/src/components/atoms/button";
import { Image } from "@socion-cordio/common/src/components/atoms/image";
import Moment from "moment";
import { toast } from "react-toastify";
import { ApiClient } from "@socion-cordio/common/src/network/apiClient";
import { sessionPackEndPoints } from "@socion-cordio/common/src/repositories/endPoints";
import { HTTP_STATUS_CODES } from "@socion-cordio/common/src/network/constants";
import RemoveLinkModal from "@socion-cordio/common/src/components/organisms/common-modals/removeLinkModal";
import { LocalStorage } from "@socion-cordio/common/src/services/storage/storageService";
import Loader from "@socion-cordio/common/src/components/atoms/loader";
import { allRoutesNames as routeNames } from "@socion-cordio/web/src/navigation/allRouteNames";
import { useLocation, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import AddTelemetryService from "@socion-cordio/common/src/services/telemetryService";
import { mediaHelper } from "@socion-cordio/common/src/utils/mediaHelperMethods";
import { ScrollContainer } from "@socion-cordio/common/src/components/organisms/scrollWrapper";
import SocionModal from "@socion-cordio/common/src/components/atoms/modal";
import ViewQrCodeDetails from "@socion-cordio/common/src/components/organisms/common-modals/viewQrCodeModal";

interface Props {
  onSubmit?: (data: { otp: number }) => void;
  sessionData?: any;
  sessionId?: number;
  isSessionCompleted?: boolean;
  isMember?: boolean;
}

export default function SessionSideBarPanelPage(props: Props): ReactElement {
  const { isMember = false } = props;
  const { t } = useTranslation();
  const { sessionData, sessionId, isSessionCompleted } = props;
  const [sessionStartDate, setSessionStartDate] = useState(null);
  const [sessionEndDate, setSessionEndDate] = useState(null);
  const [currentDateTime, setCurrentDateTime] = useState(null);
  const [isDateChanged, setIsDateChanged] = useState(false);
  const [sessionAddress, setSessionAddress] = useState(null);
  const [isDeleteSession, setIsDeteleSession] = useState(false);
  const [displayDeleteButton, setDisplayDeleteButton] = useState(false);
  const [sessionDetails, setSessionDetails] = useState(sessionData || null);
  const [isEndDateTimeCheck, setIsEndDateTimeCheck] = useState(false);
  const [isStartDateEditable, setIsStartDateEditable] = useState(false);
  const [isSessionCreator, setIsSessionCreator] = useState(false);
  const [user, setUser] = useState(null);
  const [failed, setFailed] = useState(false);
  const [loading, setLoading] = useState(false);
  const [carousel, setCarousel] = useState({ left: true, right: false });
  const [dateChanged, setDateChanged] = useState({ start: false, end: false });
  const [isEditable, setIsEditable] = useState(isMember);
  const [viewButtonDetails, setViewButtonDetails] = useState(false);
  const history: any = useHistory();
  const location: any = useLocation();

  useEffect(() => {
    setDetails();
    getUserDetails();
    setCurrentDateTime(Moment().format("YYYY-MM-DDTHH:mm"));
  }, []);

  const convertUtctoLocal = (date: string) => {
    let datetime = new Date(Moment.utc(date));
    let newDate = Moment(datetime).format("YYYY-MM-DDTHH:mm");
    return newDate;
  };

  useEffect(() => {
    if (isMember || isSessionCreator) setIsEditable(true);
    else setIsEditable(false);
  }, [isMember, isSessionCreator]);

  const getUserDetails = async () => {
    const user: any = await LocalStorage.getStorage("user");
    setUser(user);
    checkCanUserDeleteSession(user);
    checkCurrentUser(user);
  };

  const handleCopy = (data: string) => {
    navigator.clipboard.writeText(data);
    toast.success("Copied");
  };

  const setDetails = () => {
    //imp value/min/max format :: 2018-06-12T19:30
    const startDate = convertUtctoLocal(sessionDetails?.sessionStartDate);
    const endDate = convertUtctoLocal(sessionDetails?.sessionEndDate);
    setSessionStartDate(startDate);
    setSessionEndDate(endDate);
    const noAddress = "None";
    sessionDetails?.address === null ? setSessionAddress(noAddress) : sessionDetails?.address;
    setSessionAddress(sessionDetails?.address);
  };

  const checkCurrentUser = (user: any) => {
    const userId = user?.userId;
    const sessionCreatorId = sessionDetails?.sessionCreatorProfile.userId;
    if (userId === sessionCreatorId) {
      setIsSessionCreator(true);
    } else {
      setIsSessionCreator(false);
    }
  };

  const isDateChangedCheck = (date: any) => {
    //date can be start date, end date, or address/venue
    const startDate = Moment(sessionDetails.sessionStartDate).format("YYYY-MM-DDTHH:mm");
    const endDate = Moment(sessionDetails.sessionEndDate).format("YYYY-MM-DDTHH:mm");
    const address = sessionDetails?.address;
    if (date !== startDate || date !== endDate || date !== address) {
      // toast.error("date changed");
      setIsDateChanged(true);
    } else {
      setIsDateChanged(false);
    }
  };

  const isDateCorrectCheck = (date: any) => {
    const endDate = Moment(date).format("YYYY-MM-DDTHH:mm");
    const startDate = Moment(sessionStartDate).format("YYYY-MM-DDTHH:mm");
    if (endDate < startDate) {
      toast.error("End Date Time must be greater than Start Date Time");
      setIsEndDateTimeCheck(true);
    } else {
      setIsEndDateTimeCheck(false);
    }
  };

  const cancelDetailsHandler = () => {
    setDetails();
    setIsDateChanged(false);
  };

  const checkCanUserDeleteSession = (user: any) => {
    const userId = user?.userId;
    const sessionCreatorId = sessionDetails?.sessionCreatorProfile.userId;
    const sessionStartDate = convertUtctoLocal(sessionDetails?.sessionStartDate);
    const currentDateTime = Moment().format("YYYY-MM-DDTHH:mm");
    if (userId === sessionCreatorId && sessionStartDate > currentDateTime) {
      setDisplayDeleteButton(true);
    } else {
      setDisplayDeleteButton(false);
    }
    // to check if start time is greater than current time,
    // then only disable the edit date time.
    if (sessionStartDate > currentDateTime) {
      setIsStartDateEditable(false);
    } else {
      setIsStartDateEditable(true);
    }
  };

  const deleteSessionHandler = async () => {
    setIsDeteleSession(true);
  };

  const deleteHandler = async (value: any) => {
    try {
      if (value == true) {
        const sessionId = sessionDetails.sessionId;
        const response = await ApiClient.delete(sessionPackEndPoints.deleteSession(sessionId));
        if (response?.responseCode === 200) {
          toast.success(response?.message);
          updateTelemetryDeleteSession();
          navigate();
        } else {
          toast.error(response?.message);
          setIsDeteleSession(false);
          navigate();
        }
      }
    } catch (error) {
      toast.error("Something went wrong.");
      setIsDeteleSession(false);
    }
  };

  const updateTelemetryDeleteSession = async () => {
    const user: any = await LocalStorage.getStorage("user");
    const body = {
      deleted: "false",
      eventType: "Delete Session",
      timestamp: Moment().toLocaleString(),
      createdAt: Moment().toLocaleString(),
      updatedAt: Moment().toLocaleString(),
      sessionStartDate: sessionData.sessionStartDate,
      sessionEndDate: sessionData.sessionEndDate,
      sessionId: sessionData.sessionId,
      sessionName: sessionData.sessionName,
      venue: sessionData.address,
      topicId: sessionData.topicInfo.topic.id,
      topicName: sessionData.topicInfo.topic.name,
      programId: sessionData.topicInfo.topic.programId,
      programName: sessionData.topicInfo.program.name
      // establishmentName: user.establishmentName
    };
    AddTelemetryService(body, undefined, user, true);
  };

  const navigate = () => {
    history.push(`${routeNames.app}${routeNames.SESSION}`, {
      locationData: { pathname: "upcoming" }
    });
  };

  const saveSessionHandler = async () => {
    try {
      setLoading(true);
      let sessionDetailsCopy = { ...sessionDetails };
      sessionDetailsCopy["address"] = sessionAddress;
      sessionDetailsCopy["sessionStartDate"] = new Date(sessionStartDate)
        .toISOString()
        .replace("T", " ")
        .replace("Z", "");
      sessionDetailsCopy["sessionEndDate"] = new Date(sessionEndDate)
        .toISOString()
        .replace("T", " ")
        .replace("Z", "");
      // Confirm again later, we're sending the whole body.
      // may cause errors in future.
      // const newBody = {
      //   sessionStartDate: sessionDetailsCopy["sessionStartDate"],
      //   sessionEndDate: sessionDetailsCopy["sessionEndDate"],
      //   address: sessionAddress,
      //   sessionName: sessionDetailsCopy["sessionName"]
      // };
      const response = await ApiClient.put(
        sessionPackEndPoints.editSessionDetails(),
        sessionDetailsCopy
      );
      if (response?.responseCode === HTTP_STATUS_CODES.ok) {
        toast.success(response?.message);
        getSessionDetails();
        setIsDateChanged(false);
      } else {
        toast.error(response?.message);
      }
    } catch (error) {
      toast.error("Something went wrong.");
    }
  };

  const getSessionDetails = async () => {
    try {
      const response = await ApiClient.get(
        sessionPackEndPoints.getCompleteSessionDetails(sessionId)
      );
      if (response.responseCode === HTTP_STATUS_CODES.ok) {
        setSessionDetails(response?.response);
        setLoading(false);
        setFailed(false);
      } else {
        toast.error(response?.message);
        setLoading(false);
        setFailed(true);
      }
    } catch (error) {
      toast.error("Something went wrong.");
      setLoading(false);
      setFailed(true);
    }
  };

  const getFileName = (name: string) => {
    let data = name.split("/");
    return data[data.length - 1];
  };

  return (
    <View style={styles.container}>
      {loading ? (
        <Loader customLoadingContainer={{ width: 200, justifyContent: "center" }} />
      ) : failed ? (
        <View>Failed to load data</View>
      ) : (
        <View>
          <View style={styles.subContainer}>
            <View style={styles.alignContainer}>
              <Text
                fontWeight={FontWeight.Light}
                testId="text"
                textSize={TextSize.Small}
                textStyle={[styles.headerText, styles.headerTextTitleSupport]}
              >
                {sessionDetails?.sessionName}
              </Text>
            </View>
            <ScrollContainer
              height="100px"
              scrollable={sessionDetails?.sessionDescription?.length > 120 ? true : false}
            >
              <View style={[styles.alignContainer]}>
                <Text
                  onPress={() => {}}
                  fontWeight={FontWeight.Regular}
                  testId="text"
                  textSize={TextSize.Small}
                  textStyle={[
                    styles.headerText,
                    styles.headerTextSupport,
                    { opacity: 0.6, width: "30ch" }
                  ]}
                >
                  {sessionDetails?.sessionDescription}
                </Text>
              </View>
            </ScrollContainer>
            {!isSessionCompleted &&
              (location?.state?.completed == true ? null : (
                <>
                  <View
                    style={[
                      styles.alignContainer,
                      { marginTop: 25, marginBottom: 20, alignSelf: "center" }
                    ]}
                  >
                    <Text
                      fontWeight={FontWeight.Bold}
                      testId="text"
                      textSize={TextSize.Small}
                      textStyle={[
                        styles.headerText,
                        styles.headerTextSupport,
                        { fontWeight: "700", fontSize: 14, alignSelf: "center" }
                      ]}
                    >
                      {sessionDetails?.sessionScanMode === "SINGLE_SCAN"
                        ? "SINGLE SCAN"
                        : carousel.right
                        ? "End"
                        : "Start"}
                    </Text>

                    <View style={{ flexDirection: "row", marginTop: 15 }}>
                      <Text
                        fontWeight={FontWeight.Regular}
                        testId="text"
                        textSize={TextSize.Small}
                        textStyle={[
                          styles.headerText,
                          styles.headerTextSupport,
                          { opacity: 0.6, fontSize: 10 }
                        ]}
                      >
                        {t("common:uid")}
                        {" : "}
                      </Text>

                      <Text
                        fontWeight={FontWeight.Bold}
                        testId="text"
                        textSize={TextSize.Small}
                        textStyle={[
                          styles.headerText,
                          styles.headerTextSupport,
                          { fontWeight: "700", fontSize: 10 }
                        ]}
                      >
                        {carousel.right ? sessionDetails?.endUid : sessionDetails?.startUid}
                      </Text>
                    </View>
                  </View>

                  <View style={styles.qrContainer}>
                    <View style={styles.subQrContainer}>
                      {carousel.right && (
                        <TouchableOpacity
                          onPress={() => {
                            setCarousel({ left: true, right: false });
                          }}
                        >
                          <View style={styles.iconContainerHeader}>
                            <Icon
                              testID="down"
                              name={IconNames.down}
                              customStyle={[styles.titleIcon, styles.leftArrowIcon]}
                            />
                          </View>
                        </TouchableOpacity>
                      )}
                      <View>
                        <View style={styles.qrCode} nativeID="generateQR">
                          <View style={styles.displayQR} nativeID="displayQR">
                            {sessionDetails?.sessionScanMode === "SINGLE_SCAN" ? (
                              <>
                                UID: {sessionDetails?.startUid}
                                <br />
                                {sessionDetails?.sessionScanMode}
                                <br />
                                START-DATE: {convertUtctoLocal(sessionDetails?.sessionStartDate)}
                                <br />
                                END-Date: {sessionDetails?.sessionEndDate}
                                <br />
                                {convertUtctoLocal(sessionDetails?.sessionName)}
                              </>
                            ) : (
                              <>
                                UID:{" "}
                                {carousel.right ? sessionDetails?.endUid : sessionDetails?.startUid}
                                <br />
                                {carousel.right
                                  ? `END-DATE: ${convertUtctoLocal(sessionDetails?.sessionEndDate)}`
                                  : `START-DATE: ${convertUtctoLocal(
                                      sessionDetails?.sessionStartDate
                                    )}`}
                                <br />
                                {sessionDetails?.sessionName}
                              </>
                            )}
                          </View>
                          <View nativeID="divider"> </View>
                          <View nativeID="qrCodeContainer">
                            <Image
                              testId="sessionQR"
                              source={
                                carousel.right
                                  ? sessionDetails?.endQrImage
                                  : sessionDetails?.startQrImage
                              }
                            />
                          </View>
                        </View>
                      </View>
                      {carousel.left && sessionDetails?.sessionScanMode !== "SINGLE_SCAN" && (
                        <TouchableOpacity
                          onPress={() => {
                            setCarousel({ left: false, right: true });
                          }}
                        >
                          <View style={styles.iconContainerHeader}>
                            <Icon
                              testID="down"
                              name={IconNames.down}
                              customStyle={[styles.titleIcon, styles.alignArrowIcon]}
                            />
                          </View>
                        </TouchableOpacity>
                      )}
                    </View>
                    {/* <View style={styles.qrActionsContainer}>
                      <TouchableOpacity>
                        <View style={styles.iconContainer}>
                          <Icon
                            testID="close"
                            name={IconNames.share}
                            customStyle={styles.titleIcon}
                          />
                        </View>
                      </TouchableOpacity>
                      <TouchableOpacity
                        onPress={() =>
                          handleCopy(
                            carousel.right
                              ? `${sessionDetails?.endQrcode}`
                              : `${sessionDetails?.startQrcode}`
                          )
                        }
                      >
                        <View style={styles.iconContainer}>
                          <Icon
                            testID="close"
                            name={IconNames.link}
                            customStyle={styles.titleIcon}
                          />
                        </View>
                      </TouchableOpacity>
                      <TouchableOpacity
                        onPress={() =>
                          mediaHelper.mediaDownloadQRHandler(
                            "session-qr",
                            getFileName(
                              carousel.right
                                ? sessionDetails?.endQrcode
                                : sessionDetails?.startQrcode
                            )
                          )
                        }
                      >
                        <View style={styles.iconContainer}>
                          <Icon
                            testID="close"
                            name={IconNames.downloadList}
                            customStyle={styles.titleIcon}
                          />
                        </View>
                      </TouchableOpacity>
                    </View> */}
                    {/* </View> */}
                    <View style={styles.qrActionsContainer}>
                      <TouchableOpacity onPress={() => setViewButtonDetails(true)}>
                        <View style={styles.iconContainer}>
                          <Icon
                            testID="close"
                            name={IconNames.link}
                            customStyle={styles.titleIcon}
                          />
                        </View>
                      </TouchableOpacity>
                      <TouchableOpacity
                        onPress={() =>
                          handleCopy(
                            carousel.right
                              ? `${sessionDetails?.endQrcode}`
                              : `${sessionDetails?.startQrcode}`
                          )
                        }
                      >
                        <View style={styles.iconContainer}>
                          <Icon
                            testID="close"
                            name={IconNames.contentCopy}
                            customStyle={[styles.titleIcon, styles.alignCopyIcon]}
                          />
                        </View>
                      </TouchableOpacity>
                      <TouchableOpacity
                        onPress={() =>
                          mediaHelper.mediaDownloadQRHandler(
                            "session-qr",
                            getFileName(
                              carousel.right
                                ? sessionDetails?.endQrcode
                                : sessionDetails?.startQrcode
                            )
                          )
                        }
                      >
                        <View style={styles.iconContainer}>
                          <Icon
                            testID="close"
                            name={IconNames.downloadList}
                            customStyle={styles.titleIcon}
                          />
                        </View>
                      </TouchableOpacity>
                    </View>
                  </View>
                </>
              ))}
            <View style={styles.inputContainer}>
              <View>
                <View style={[styles.alignUserInputFields, { justifyContent: "space-between" }]}>
                  <View style={styles.alignSubTextLabel}>
                    <Text
                      fontWeight={FontWeight.Regular}
                      testId="internal"
                      textSize={TextSize.Small}
                      textStyle={[styles.subheaderText]}
                    >
                      {t("common:start")}
                    </Text>
                  </View>
                  <View style={styles.dateTimePickerContainer}>
                    <input
                      style={{
                        borderRadius: 5,
                        padding: 8,
                        fontFamily: FontFamily.Medium,
                        fontSize: 10,
                        borderColor: "#c2bcb9",
                        borderStyle: "solid",
                        borderWidth: 1,
                        backgroundColor: colorPallete.white,
                        width: 158
                      }}
                      type="datetime-local"
                      min={currentDateTime}
                      max="2022-12-31"
                      onChange={(date: any) => {
                        setSessionStartDate(date.target.value);
                        isDateChangedCheck(date.target.value);
                        setDateChanged({ ...dateChanged, start: true });
                      }}
                      // disabled={isStartDateEditable || !isSessionCreator || isSessionCompleted}
                      disabled={isStartDateEditable || isSessionCompleted || !isEditable}
                      // value={sessionStartDate}
                      value={sessionStartDate}
                      // name={name}
                      // id={id}
                    />
                  </View>
                </View>
              </View>
              <View>
                <View style={[styles.alignUserInputFields, { justifyContent: "space-between" }]}>
                  <View style={styles.alignSubTextLabel}>
                    <Text
                      fontWeight={FontWeight.Regular}
                      testId="internal"
                      textSize={TextSize.Small}
                      textStyle={[styles.subheaderText]}
                    >
                      {t("common:end")}
                    </Text>
                  </View>
                  <View style={styles.dateTimePickerContainer}>
                    <input
                      style={{
                        borderRadius: 5,
                        padding: 8,
                        fontFamily: FontFamily.Medium,
                        fontSize: 10,
                        borderColor: "#c2bcb9",
                        borderStyle: "solid",
                        borderWidth: 1,
                        backgroundColor: colorPallete.white,
                        // height: 21,
                        width: 158
                      }}
                      type="datetime-local"
                      min={currentDateTime}
                      max="2022-12-31"
                      onChange={(date: any) => {
                        setSessionEndDate(date.target.value);
                        isDateChangedCheck(date.target.value);
                        isDateCorrectCheck(date.target.value);
                        setDateChanged({ ...dateChanged, end: true });
                      }}
                      // disabled={!isSessionCreator || isSessionCompleted}
                      disabled={isSessionCompleted || !isEditable || location?.state?.completed}
                      // value={sessionEndDate}
                      value={sessionEndDate}

                      // name={name}
                      // id={id}
                    />
                  </View>
                </View>
              </View>
              <View>
                <UserInputSecondary
                  textValue={t("common:venue")}
                  handleChange={(address: any) => {
                    setSessionAddress(address);
                    isDateChangedCheck(address);
                  }}
                  handleBlur={() => {}}
                  value={sessionAddress}
                  // inputStyle={[styles.inputStyling, styles.inputStylingAlignment]}
                  placeholder={t("common:venuePlaceholder")}
                  name="Venue"
                  id="Venue"
                  userStyle={
                    isSessionCreator && !isSessionCompleted
                      ? [styles.alignTextFieldWidth]
                      : [styles.alignTextFieldWidth, { color: colorPallete.disabledGrey }]
                  }
                  userStyleText={styles.alignTextWidth}
                  titleInputContainerStyles={{ marginTop: 0 }}
                  // editable={isSessionCreator && !isSessionCompleted}
                  editable={
                    !isSessionCompleted &&
                    isEditable &&
                    (location?.state?.completed === true ? false : true)
                  }
                  noFormik
                />
              </View>
            </View>
          </View>
          <View style={styles.submitButtonContainer}>
            {!isDateChanged && displayDeleteButton && (
              <View>
                {" "}
                <Button
                  type={ButtonType.Primary}
                  buttonStyles={styles.submitbutton}
                  title={t("session:deleteSession")}
                  onPress={() => deleteSessionHandler()}
                  textStyles={{ fontSize: 12 }}
                />
              </View>
            )}
          </View>
          <View style={{}}>
            {isDateChanged && (
              <View style={{ flexDirection: "row", justifyContent: "space-evenly" }}>
                {" "}
                <Button
                  type={ButtonType.Secondary}
                  buttonStyles={styles.submitbuttonSecondary}
                  title={t("common:cancel")}
                  onPress={() => cancelDetailsHandler()}
                  textStyles={{ fontSize: 12 }}
                />
                <Button
                  type={ButtonType.Primary}
                  buttonStyles={styles.submitbuttonSecondary}
                  title={t("common:save")}
                  onPress={() => saveSessionHandler()}
                  textStyles={{ fontSize: 12 }}
                  disabled={isEndDateTimeCheck || sessionAddress === null || sessionAddress === ""}
                />{" "}
              </View>
            )}
          </View>
          {/* <View style={styles.duplicateButtonContainer}>
            {isSessionCompleted && (
              <View>
                {" "}
                <Button
                  type={ButtonType.Primary}
                  buttonStyles={styles.submitbutton}
                  title="Duplicate session"
                  onPress={() => {}}
                  textStyles={{ fontSize: 12 }}
                />
              </View>
            )}
          </View> */}
        </View>
      )}
      {isDeleteSession && (
        <RemoveLinkModal
          modalVisible={isDeleteSession}
          selectedData={deleteHandler}
          textValue={t("session:deleteSessionConfirm")}
          setModalVisible={() => setIsDeteleSession(!isDeleteSession)}
        />
      )}
      {viewButtonDetails && (
        <View>
          <SocionModal
            modalVisible={viewButtonDetails}
            setModalVisible={() => setViewButtonDetails(!viewButtonDetails)}
            component={
              <ViewQrCodeDetails
                onClose={() => {
                  setViewButtonDetails(false);
                }}
                sessionData={sessionDetails}
                carouselHandler={carousel}
              />
            }
          />
        </View>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    padding: 20,
    backgroundColor: colorPallete.cordioRedDark2
  },
  subContainer: {},
  alignContainer: {
    marginBottom: 15
  },
  headerText: {
    fontSize: 12,
    fontFamily: FontFamily.Medium,
    lineHeight: 17
  },
  headerTextTitleSupport: {
    fontWeight: "700",
    fontSize: 14,
    lineHeight: 20,
    color: colorPallete.white,
    width: "20ch"
  },
  headerTextSupport: {
    fontSize: 10,
    lineHeight: 15,
    color: colorPallete.white
  },
  qrContainer: {
    alignItems: "center"
  },
  qrCode: {
    flex: 100,
    maxWidth: 130,
    minHeight: 110
  },
  qrActionsContainer: {
    width: 120,
    marginTop: 20,
    marginVertical: 10,
    flexDirection: "row",
    justifyContent: "space-between"
  },
  iconContainer: {},
  titleIcon: {
    color: "#fff",
    fontSize: 15
  },
  alignCopyIcon: {
    fontSize: 18
  },
  inputContainer: {
    marginTop: 20
  },
  alignTextFieldWidth: {
    width: "100%",
    fontSize: 10,
    backgroundColor: colorPallete.white
  },
  alignTextWidth: {
    flex: 21.5,
    fontSize: 10,
    color: colorPallete.white
  },
  submitButtonContainer: {
    alignItems: "center",
    marginTop: 40
  },
  duplicateButtonContainer: {
    alignItems: "center"
  },
  submitbutton: {
    width: "140px",
    height: "50px",
    borderRadius: 10
  },
  submitbuttonSecondary: {
    width: "80px",
    height: "50px",
    borderRadius: 10
  },
  alignUserInputFields: {
    marginBottom: 10,
    flexDirection: "row",
    alignItems: "center"
  },
  subheaderText: {
    // flex: 12,
    fontWeight: "400",
    fontSize: 10,
    fontFamily: FontFamily.Regular,
    lineHeight: 17,
    color: colorPallete.white
    // marginTop: 13
  },
  alignSubTextLabel: {},
  dateTimePickerContainer: {
    // width:"100%"
  },
  subQrContainer: {
    display: "flex",
    flexDirection: "row"
  },
  iconContainerHeader: {},
  alignArrowIcon: {
    position: "absolute",
    fontSize: 9,
    transform: [{ rotate: "270deg" }],
    top: 55,
    left: 20
  },
  leftArrowIcon: {
    position: "absolute",
    fontSize: 9,
    transform: [{ rotate: "90deg" }],
    top: 55,
    left: -40
  },
  displayQR: {
    display: "none",
    color: colorPallete.textLight
  },
  descriptionContainer: {
    height: "100px",
    overflowY: "scroll"
  }
});
