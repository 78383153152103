import { Profile } from "@socion-cordio/common/src/models/profile";
import { UserProfile } from "@socion-cordio/common/src/models/userProfile";
import { UserRole } from "@socion-cordio/common/src/models/userRole";
import { UserQualification } from "@socion-cordio/common/src/models/userQualification";
import { IFluxStandardAction } from "@socion-cordio/common/src/store/interfaces";
import { PROFILE } from "@socion-cordio/common/src/modules/profile/actions/actionTypes";
import { EligibleProgramsForRole } from "@socion-cordio/common/src/models/eligibleProgramsForRole";
import { EligibleProgramsForQualification } from "@socion-cordio/common/src/models/eligibleProgramsForQualification";

const getProfile = (data: { emailId: string }): IFluxStandardAction<{ emailId: string }> => {
  return {
    type: PROFILE.GET.LOADING,
    payload: data
  };
};
const getProfileSuccess = (data: Profile): IFluxStandardAction<Profile> => {
  return {
    type: PROFILE.GET.SUCCESS,
    payload: data
  };
};
const getProfileError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: PROFILE.GET.ERROR,
    payload: error
  };
};
const getUserProfile = (): IFluxStandardAction<string> => {
  return {
    type: PROFILE.GET_USER_PROFILE.LOADING
  };
};
const getUserProfileSuccess = (data: UserProfile): IFluxStandardAction<UserProfile> => {
  return {
    type: PROFILE.GET_USER_PROFILE.SUCCESS,
    payload: data
  };
};
const getUserProfileError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: PROFILE.GET_USER_PROFILE.ERROR,
    payload: error
  };
};
const updateProfile = (profile: Profile): IFluxStandardAction<Profile> => {
  return {
    type: PROFILE.UPDATE.LOADING,
    payload: profile
  };
};
const updateProfileSuccess = (data: Profile): IFluxStandardAction<Profile> => {
  return {
    type: PROFILE.UPDATE.SUCCESS,
    payload: data
  };
};
const updateProfileError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: PROFILE.UPDATE.ERROR,
    payload: error
  };
};
const uploadFile = (file: FormData): IFluxStandardAction<FormData> => {
  return {
    type: PROFILE.FILE_UPLOAD.LOADING,
    payload: file
  };
};
const uploadFileSuccess = (link: string): IFluxStandardAction<string> => {
  return {
    type: PROFILE.FILE_UPLOAD.SUCCESS,
    payload: link
  };
};
const uploadFileError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: PROFILE.FILE_UPLOAD.ERROR,
    payload: error
  };
};

const getUserRole = (): IFluxStandardAction<string> => {
  return {
    type: PROFILE.GET_USER_ROLE.LOADING
  };
};
const getUserRoleSuccess = (data: UserRole): IFluxStandardAction<UserRole> => {
  return {
    type: PROFILE.GET_USER_ROLE.SUCCESS,
    payload: data
  };
};
const getUserRoleError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: PROFILE.GET_USER_ROLE.ERROR,
    payload: error
  };
};
const getUserQualification = (): IFluxStandardAction<string> => {
  return {
    type: PROFILE.GET_USER_QUALIFICATION.LOADING
  };
};
const getUserQualificationSuccess = (
  data: UserQualification
): IFluxStandardAction<UserQualification> => {
  return {
    type: PROFILE.GET_USER_QUALIFICATION.SUCCESS,
    payload: data
  };
};
const getUserQualificationError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: PROFILE.GET_USER_QUALIFICATION.ERROR,
    payload: error
  };
};
const getUserEligibleProgramsForRole = (data: {
  acceptingType: string;
}): IFluxStandardAction<{ acceptingType: string }> => {
  return {
    type: PROFILE.GET_USER_ELIGIBLE_PROGRAMS_FOR_ROLE.LOADING,
    payload: data
  };
};
const getUserEligibleProgramsForRoleSuccess = (
  data: EligibleProgramsForRole
): IFluxStandardAction<EligibleProgramsForRole> => {
  return {
    type: PROFILE.GET_USER_ELIGIBLE_PROGRAMS_FOR_ROLE.SUCCESS,
    payload: data
  };
};
const getUserEligibleProgramsForRoleError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: PROFILE.GET_USER_ELIGIBLE_PROGRAMS_FOR_ROLE.ERROR,
    payload: error
  };
};
const getUserEligibleProgramsForQualification = (data: {
  acceptingType: string;
}): IFluxStandardAction<{ acceptingType: string }> => {
  return {
    type: PROFILE.GET_USER_ELIGIBLE_PROGRAMS_FOR_QUALIFICATION.LOADING,
    payload: data
  };
};
const getUserEligibleProgramsForQualificationSuccess = (
  data: EligibleProgramsForQualification
): IFluxStandardAction<EligibleProgramsForQualification> => {
  return {
    type: PROFILE.GET_USER_ELIGIBLE_PROGRAMS_FOR_QUALIFICATION.SUCCESS,
    payload: data
  };
};
const getUserEligibleProgramsForQualificationError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: PROFILE.GET_USER_ELIGIBLE_PROGRAMS_FOR_QUALIFICATION.ERROR,
    payload: error
  };
};

export const ProfileActions = {
  getProfile,
  getProfileSuccess,
  getProfileError,
  getUserProfile,
  getUserProfileSuccess,
  getUserProfileError,
  updateProfile,
  updateProfileSuccess,
  updateProfileError,
  uploadFile,
  uploadFileSuccess,
  uploadFileError,
  getUserRole,
  getUserRoleSuccess,
  getUserRoleError,
  getUserQualification,
  getUserQualificationSuccess,
  getUserQualificationError,
  getUserEligibleProgramsForRole,
  getUserEligibleProgramsForRoleSuccess,
  getUserEligibleProgramsForRoleError,
  getUserEligibleProgramsForQualification,
  getUserEligibleProgramsForQualificationSuccess,
  getUserEligibleProgramsForQualificationError
};
