export const artefactMetaFields: any = {
  artefactId: "artefact_meta_id",
  artefactName: "artefact_name",
  artefactType: "artefact_type_title",
  artefactLatitude: "latitude",
  artefactLongitude: "longitude",
  artefactCountry: "country",
  artefactState: "state",
  artefactDistrict: "district",
  artefactSubDistrict: ["sub_district", "subDistrict"],
  artefactCity: "city",
  artefactVillage: "village",
  artefactStatus: "status",
  artefactActiveState: "active",
  artefactPlaceId: ["placeId", "placeid"],
  artefactSubmitter: "submitter_userid",
  artefactSubmittedDate: "submitted_date",
  artefactCreatedDate: "created_at",
  artefactCreatedBy: "created_by",
  artefactUpdatedBy: "updated_by",
  artefactUpdatedDate: "updated_at",
  remarks: "remarks",
  noOfDocs: "docs"
};

export const artefactDefaultMeta: any = [
  "artefactSubDistrict",
  "artefactPlaceId",
  "noOfDocs",
  "artefactSubmitter"
];

export const docMetaFields: any = {
  docId: "doc_id",
  fileName: "display_filename",
  fileUrl: "artefact_url"
};

export interface IFilters {
  filtersListData: {
    location: any[];
    locationSecondary: any[];
    type: any[];
    status: any[];
  };
  filterApplied: {
    location: boolean;
    type: boolean;
    submittedDate: boolean;
    uploadedDate: boolean;
    status: boolean;
  };
  selectedFilter: {
    location: any[];
    type: any[];
    submittedDate: any;
    uploadedDate: any;
    status: any[];
  };
  selectedFilterListBtn: {
    location: any[];
    type: any[];
    submittedDate: any[];
    uploadedDate: any[];
    status: any[];
  };
  notSelected: {
    location: any;
    type: any;
    submittedDate: any;
    uploadedDate: any;
    status: any;
  };
}

export const FiltersData: IFilters = {
  filtersListData: {
    location: [],
    locationSecondary: [],
    type: [],
    status: []
  },
  filterApplied: {
    location: false,
    type: false,
    submittedDate: false,
    uploadedDate: false,
    status: false
  },
  selectedFilter: {
    location: [],
    type: [],
    submittedDate: {
      fromdate: "",
      todate: ""
    },
    uploadedDate: {
      uploadedFromDate: "",
      uploadedToDate: ""
    },
    status: []
  },
  selectedFilterListBtn: {
    location: [],
    type: [],
    submittedDate: [],
    uploadedDate: [],
    status: []
  },
  notSelected: {
    location: false,
    type: false,
    submittedDate: false,
    uploadedDate: false,
    status: false
  }
};
