import React, { ReactElement, useEffect, useState, useMemo } from "react";
import { View, StyleSheet, TouchableOpacity } from "react-native";
import {
  Text,
  TextSize,
  FontWeight,
  FontFamily
} from "@socion-cordio/common/src/components/atoms/text";
import Table from "@socion-cordio/web/src/components/molecules/table/table";
import { useDispatch, useSelector } from "react-redux";
import { dataHelper } from "@socion-cordio/common/src/utils/dataHelper";
import { colorPallete } from "@socion-cordio/common/src/assets/styles/colors";
import Loader from "@socion-cordio/common/src/components/atoms/loader";
import { Image } from "@socion-cordio/common/src/components/atoms/image";
import { ParticipantDetailsActions } from "@socion-cordio/common/src/modules/participationDetails/actions/actions";
import { auxiliaryMethods } from "@socion-cordio/common/src/utils/auxiliaryMethods";
import Icon, { IconNames } from "@socion-cordio/common/src/components/atoms/icon";
import { ApiClient } from "@socion-cordio/common/src/network/apiClient";
import { participationDetailsEndPoints } from "@socion-cordio/common/src/repositories/endPoints";
import { DateRangePicker } from "rsuite";
import moment from "moment";
import Ranges from "@socion-cordio/common/src/constants/dateRange";
import SocionModal from "@socion-cordio/common/src/components/atoms/modal";
import ViewUserDetails from "@socion-cordio/common/src/components/organisms/common-modals/viewUserDetailsModal";
interface Props {
  programDetails: any;
}

export default function ParticipantAttestation(props: Props): ReactElement {
  const startDate = moment();
  const endDate = startDate.clone().subtract(7, "days");
  let dateValue: any[] = [];
  dateValue.push(endDate);
  dateValue.push(startDate);
  const [date, setDate] = useState(dateValue);
  const { programDetails } = props;
  const [loading, setLoading] = useState(true);
  const [failed, setFailed] = useState(false);
  const [viewButtonDetails, setViewButtonDetails] = useState(false);
  const [selectedUserDetails, setSelectedUserDetails] = useState(null);
  const [participantAttestationData, setParticipantAttestationData] = useState(null);

  const dispatch = useDispatch();
  let participationDetails: any = useSelector((state: any) => state?.participationDetails);

  useEffect(() => {
    getParticipantAttestationData();
  }, [date]);

  const participantAttestationDetailsCSV = async () => {
    const payload = {
      userType: "TRAINEE",
      startDate: dataHelper.formatDate(date[0], "YYYY-MM-DD"),
      endDate: dataHelper.formatDate(date[1], "YYYY-MM-DD")
    };
    const participantAttestationCSVResponse = await ApiClient.get(
      participationDetailsEndPoints.getParticipantAttestationListCSV(programDetails?.program?.id),
      payload
    );
    return participantAttestationCSVResponse;
  };

  const downloadCsvList = async () => {
    const data = await participantAttestationDetailsCSV();
    auxiliaryMethods.handleDownload(
      data,
      `${programDetails?.program?.name}-Participants-Attestations`
    );
    auxiliaryMethods.updateTelemetryEvent("Download Participant Attestation List");
  };

  const getParticipantAttestationData = () => {
    let startDate = new Date();
    startDate.setDate(startDate.getDate() - 45);
    const data = {
      userType: "TRAINEE",
      startDate: dataHelper.formatDate(date[0], "YYYY-MM-DD"),
      endDate: dataHelper.formatDate(date[1], "YYYY-MM-DD"),
      programId: programDetails?.program?.id
    };
    dispatch(ParticipantDetailsActions.getParticipantAttestation(data));
  };

  useEffect(() => {
    processParticipationAttestatinData();
  }, [participationDetails]);

  const processParticipationAttestatinData = () => {
    let data = participationDetails?.participantAttestationData?.response?.attestationDetails;
    data?.forEach((attestationData: any, index: number) => {
      attestationData.serialNo = `${index + 1}.`;
      attestationData.formattedBaseLocation = auxiliaryMethods.fromatBaseLocation(attestationData);
    });
    setParticipantAttestationData(data);
    setLoading(false);
  };

  const displayUserDetails = (data: any) => {
    setViewButtonDetails(true);
    setSelectedUserDetails(data);
  };

  const cellData = (testId: string, style: any, data: string, onPress?: Function) => (
    <Text
      fontWeight={FontWeight.Bold}
      testId={testId}
      textSize={TextSize.Small}
      style={[
        {
          fontSize: 12,
          fontFamily: FontFamily.Regular,
          fontWeight: FontFamily.Light,
          textSize: TextSize.Small,
          color: colorPallete.textBlack
        },
        style
      ]}
      onPress={onPress ? () => onPress() : null}
    >
      {data}
    </Text>
  );

  const columns = useMemo(
    () => [
      {
        Header: "Sr.No",
        accessor: "serialNo",
        width: 30,
        maxWidth: 30,
        disableSortBy: true,
        Cell: ({ row: { original: participantAttestation } }: { row: { original: any } }) => {
          const style = {};
          return cellData("serialNo", style, participantAttestation?.serialNo);
        }
      },
      {
        Header: "Issued Date",
        accessor: "issuedDate",
        width: 60,
        maxWidth: 60,
        Cell: ({ row: { original: participantAttestation } }: { row: { original: any } }) => {
          const style = {};
          const issuedDate = dataHelper.formatDate(
            participantAttestation?.attestationIssuedDate,
            "DD-MMM-YYYY"
          );
          return cellData("issuedDate", style, issuedDate);
        }
      },
      {
        Header: "Particpant Name",
        accessor: "participantName",
        width: 80,
        maxWidth: 80,
        Cell: ({ row: { original: participantAttestation } }: { row: { original: any } }) => {
          return (
            <Text
              fontWeight={FontWeight.Bold}
              testId="participantName"
              textSize={TextSize.Large}
              style={{
                fontSize: 12,
                fontFamily: FontFamily.Medium,
                color: colorPallete.textBlack
              }}
            >
              <TouchableOpacity onPress={() => displayUserDetails(participantAttestation)}>
                {participantAttestation?.name}
              </TouchableOpacity>
            </Text>
          );
        }
      },
      {
        Header: "Attestation Topic Name",
        accessor: "attestationTopicName",
        width: 80,
        maxWidth: 80,
        Cell: ({ row: { original: participantAttestation } }: { row: { original: any } }) => {
          const style = {};
          return cellData("attestationTopicName", style, participantAttestation?.topicName);
        }
      },
      {
        Header: "Base Location",
        accessor: "baseLocation",
        width: 65,
        maxWidth: 65,
        Cell: ({ row: { original: participantAttestation } }: { row: { original: any } }) => {
          const style = {};
          return cellData("baseLocation", style, participantAttestation?.formattedBaseLocation);
        }
      },
      {
        Header: "Establishment Name",
        accessor: "establishmentName",
        width: 65,
        maxWidth: 65,
        Cell: ({ row: { original: participantAttestation } }: { row: { original: any } }) => {
          const style = {};
          return cellData("establishmentName", style, participantAttestation?.establishmentName);
        }
      },
      {
        Header: "Verified Role",
        accessor: "verifiedRole",
        width: 60,
        maxWidth: 60,
        Cell: ({ row: { original: participant } }: { row: { original: any } }) => {
          const style = {};
          const verifiedRole = auxiliaryMethods.getVerifiedQualification(participant.roleDetails);
          return cellData("verifiedRole", style, verifiedRole);
        }
      },
      {
        Header: "Assigned Badges",
        accessor: "assignedBadges",
        width: 60,
        maxWidth: 60,
        Cell: ({ row: { original: participant } }: { row: { original: any } }) => {
          const style = {};
          const assignedBadges =
            auxiliaryMethods.getAssignedBadges(participant.badgeDetails) || "None";
          return cellData("assignedBadges", style, assignedBadges);
        }
      }
    ],
    []
  );

  return (
    <View>
      {loading ? (
        <Loader />
      ) : failed ? (
        <View>Failed to load data</View>
      ) : (participantAttestationData && participantAttestationData.length) >= 0 ? (
        <View>
          <Table
            columns={columns}
            data={participantAttestationData}
            dateRangePicker={
              <DateRangePicker
                size="lg"
                value={date}
                format="YYYY-MM-DD"
                onChange={setDate}
                ranges={Ranges}
              />
            }
            download={
              <TouchableOpacity onPress={() => downloadCsvList()}>
                <View style={{ flexDirection: "row" }}>
                  <Icon
                    name={IconNames.downloadList}
                    customStyle={styles.iconStyle}
                    onPress={() => downloadCsvList()}
                  />
                  <View>
                    <Text
                      testId="testId"
                      fontWeight={FontWeight.Bold}
                      textSize={TextSize.Small}
                      style={[
                        styles.textAlignment,
                        {
                          fontFamily: FontFamily.Regular,
                          fontSize: 12
                        }
                      ]}
                    >
                      {"Download"}
                    </Text>
                  </View>
                </View>
              </TouchableOpacity>
            }
          />
          {viewButtonDetails && (
            <View>
              <SocionModal
                modalVisible={viewButtonDetails}
                setModalVisible={() => setViewButtonDetails(!viewButtonDetails)}
                component={
                  <ViewUserDetails
                    onClose={() => {
                      setViewButtonDetails(false);
                    }}
                    selectedUserDetails={selectedUserDetails}
                    piiFilter={true}
                  />
                }
              />
            </View>
          )}
        </View>
      ) : (
        <>
          <Loader />
        </>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  sessionName: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center"
  },
  image: {
    width: 25,
    height: 25,
    borderRadius: 25 / 2,
    marginEnd: 10
  },
  status: {
    height: 8,
    width: 8,
    borderRadius: 50,
    marginRight: 8
  },
  active: {
    backgroundColor: colorPallete.cordioGreen
  },
  pending: {
    backgroundColor: colorPallete.cordioOrange
  },
  deactivated: {
    backgroundColor: colorPallete.cordioRedDark3
  },
  textAlign: {
    fontFamily: FontFamily.Regular,
    color: colorPallete.textBlack,
    fontSize: 10
  },
  statusContainer: {
    flexDirection: "row",
    alignItems: "center"
  },
  statusColor: {
    height: 8,
    width: 8,
    borderRadius: 50,
    marginRight: 8
  },
  approvedColor: {
    backgroundColor: colorPallete.cordioGreen
  },
  pendingColor: {
    backgroundColor: colorPallete.cordioOrange
  },
  declineColor: {
    backgroundColor: colorPallete.cordioRedDark3
  },
  iconStyle: {
    color: colorPallete.textBlack,
    fontSize: 17,
    marginRight: 10
  },
  textAlignment: {
    textDecorationLine: "underline"
  }
});
