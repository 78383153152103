import React, { ReactElement, useEffect, useState, useMemo } from "react";
import { View, StyleSheet, TouchableOpacity } from "react-native";
import { toast } from "react-toastify";
import { qualificationPackEndPoints } from "@socion-cordio/common/src/repositories/endPoints";
import { ApiClient } from "@socion-cordio/common/src/network/apiClient";
import {
  Text,
  TextSize,
  FontWeight,
  FontFamily
} from "@socion-cordio/common/src/components/atoms/text";
import Table from "@socion-cordio/web/src/components/molecules/table/table";
import { Image } from "@socion-cordio/common/src/components/atoms/image";
import Loader from "@socion-cordio/common/src/components/atoms/loader";
import SocionModal from "@socion-cordio/common/src/components/atoms/modal";
import QualificationPackDetailsModal from "@socion-cordio/common/src/components/organisms/qualificationPackDetailsModal";
import AddQualificationPackModal from "@socion-cordio/common/src/components/organisms/addQualificationPackModal";
import { colorPallete } from "@socion-cordio/common/src/assets/styles/colors";
import { dataHelper } from "@socion-cordio/common/src/utils/dataHelper";
import { useHistory } from "react-router-dom";
import { allRoutesNames as routeNames } from "@socion-cordio/web/src/navigation/allRouteNames";
import SearchFilterSeondary from "@socion-cordio/common/src/components/molecules/searchFilterSecondary";

interface Props {
  programDetails?: any;
  userRoles?: any;
}

export default function QualificationPackTable(props: Props): ReactElement {
  const [qualificationPack, setQualificationPack] = useState(null);
  const [loading, setLoading] = useState(true);
  const [failed, setFailed] = useState(false);
  const [addQualification, setAddQualification] = useState(false);
  const [packCompletion, setPackCompletion] = useState(false);
  const [bundleId, setBundleId] = useState(null);
  const [qualificationPackDetails, setQualificationPackDetails] = useState(null);
  const { programDetails, userRoles } = props;
  const history: any = useHistory();
  useEffect(() => {
    getBadgesTable();
  }, []);

  const getBadgesTable = async () => {
    try {
      const { program } = props?.programDetails;
      const qualificationPackResponse = await ApiClient.get(
        qualificationPackEndPoints.getQualificationPackTable(program.id)
      );
      const newList = sortHandler(qualificationPackResponse?.response);
      newList.forEach((qualiPack: any, index: number) => {
        qualiPack.serialNo = `${index + 1}.`;
      });
      setQualificationPack(qualificationPackResponse?.response);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setFailed(true);
    }
  };

  const sortHandler = (list: any) => {
    // sort by date
    list.sort((a: any, b: any) => {
      return b.bundle.createdAt - a.bundle.createdAt;
    });
    return list;
  };

  useEffect(() => {
    if (bundleId) getQualificationPackData();
  }, [bundleId]);

  const handleQualificationPack = (id: number) => {
    setPackCompletion(true);
    setBundleId(id);
  };

  const getQualificationPackData = async () => {
    const qualificationPackResponse = await ApiClient.get(
      qualificationPackEndPoints.getQualificationPackBundleDetails(bundleId)
    );
    try {
      setQualificationPackDetails(qualificationPackResponse?.response);
    } catch (error) {
      toast.error(qualificationPackResponse.message);
    }
  };
  const navigivateViewDetailsHandler = (qualificationPack: any) => {
    history.push(
      `${routeNames.app}${routeNames.QUALIFICATIONPACKS}${routeNames.VIEWQUALIFICATIONPACKS}`,
      {
        packDetails: qualificationPack
      }
    );
  };

  const cellData = (testId: string, style: any, data: string, onPress?: Function) => (
    <Text
      fontWeight={FontWeight.Bold}
      testId={testId}
      textSize={TextSize.Small}
      style={[
        {
          fontSize: 12,
          textSize: TextSize.Small,
          color: colorPallete.textBlack,
          fontFamily: FontFamily.Regular,
          fontWeight: FontFamily.Light
        },
        style
      ]}
      onPress={onPress ? () => onPress() : null}
    >
      {data}
    </Text>
  );

  const columns = useMemo(
    () => [
      {
        Header: "Sr.No",
        accessor: "serialNo",
        width: 30,
        maxWidth: 30,
        disableSortBy: true,
        Cell: ({ row: { original: qualiPack } }: { row: { original: any } }) => {
          const style = {};
          return cellData("serialNo", style, qualiPack?.serialNo);
        }
      },
      {
        Header: "Qualification Pack",
        accessor: "name",
        width: 225,
        maxWidth: 225,
        disableSortBy: true,
        Cell: ({ row: { original: qualiPack } }: { row: { original: any } }) => {
          const style = {
            fontFamily: FontFamily.Medium,
            fontWeight: FontFamily.Bold
          };
          return cellData("name", style, qualiPack?.bundle?.name, () => {
            handleQualificationPack(qualiPack?.bundle?.id);
          });
        }
      },
      {
        Header: "Date of creation",
        accessor: "createdAt",
        width: 85,
        maxWidth: 85,
        Cell: ({ row: { original: qualiPack } }: { row: { original: any } }) => {
          const style = {};

          return cellData("createdAt", style, dataHelper.formatDate(qualiPack?.bundle?.createdAt));
        }
      },
      {
        Header: "Selections",
        accessor: "selections",
        width: 75,
        maxWidth: 75,
        Cell: ({ row: { original: qualiPack } }: { row: { original: any } }) => {
          const style = {};

          return cellData("createdAt", style, qualiPack?.selectionCount);
        }
      },
      {
        Header: "Pack completion",
        accessor: "packCompletion",
        width: 85,
        maxWidth: 85,
        disableSortBy: true,
        Cell: ({ row: { original: qualiPack } }: { row: { original: any } }) => {
          const style = {
            fontFamily: FontFamily.Medium,
            fontWeight: FontFamily.Bold,
            textDecorationLine: "underline"
          };
          const onPress = () => {
            navigivateViewDetailsHandler(qualiPack);
          };
          const qpSelectionCount = qualiPack?.selectionCount;
          const viewDetails = qpSelectionCount !== 0 ? "View Details" : null;
          return cellData("name", style, viewDetails, onPress);
        }
      }
    ],
    []
  );

  const searchbarAndAddQp = () => (
    <View style={styles.mainSearchBarContainer}>
      <View style={styles.searchBarContainer}>
        <View pointerEvents={"none"}>
          <SearchFilterSeondary customWidthStyle={styles.customWidthStyle} editable={false} />
        </View>
        <View>
          {userRoles?.includes("PROGRAM_ADMIN") && (
            <TouchableOpacity onPress={() => setAddQualification(true)}>
              <Image
                testId="primaryAddImg"
                source={require("@socion-cordio/common/src/assets/images/primary_add.svg")}
                imageStyle={{
                  width: 30,
                  height: 30
                }}
              />
            </TouchableOpacity>
          )}
        </View>
      </View>
      <Text
        fontWeight={FontWeight.Thin}
        testId="headerText"
        textSize={TextSize.Small}
        textStyle={styles.headerText}
      >
        {"No Qualification Pack Present"}
      </Text>
    </View>
  );

  return (
    <View>
      {loading ? (
        <Loader />
      ) : failed ? (
        <View style={styles.noFailedDataContainer}>Failed to load data</View>
      ) : (
        <>
          {qualificationPack.length === 0 ? (
            <View>{searchbarAndAddQp()}</View>
          ) : (
            <View>
              <Table
                columns={columns}
                data={qualificationPack}
                addIcon={
                  userRoles?.includes("PROGRAM_ADMIN") && (
                    <TouchableOpacity onPress={() => setAddQualification(true)}>
                      <Image
                        testId="primaryAddImg"
                        source={require("@socion-cordio/common/src/assets/images/primary_add.svg")}
                        imageStyle={{
                          width: 30,
                          height: 30
                        }}
                      />
                    </TouchableOpacity>
                  )
                }
              />
            </View>
          )}
        </>
      )}

      {addQualification && (
        <View>
          <SocionModal
            modalVisible={addQualification}
            setModalVisible={() => setAddQualification(!addQualification)}
            component={
              <AddQualificationPackModal
                onClose={() => setAddQualification(false)}
                getBadgesTable={() => getBadgesTable()}
                qualificationPackList={qualificationPack}
              />
            }
          />
        </View>
      )}

      {packCompletion && (
        <View>
          <SocionModal
            modalVisible={packCompletion}
            setModalVisible={() => setPackCompletion(!packCompletion)}
            component={
              <QualificationPackDetailsModal
                onClose={() => [setPackCompletion(false)]}
                qualificationPackDetails={qualificationPackDetails}
                getBadgesTable={() => getBadgesTable()}
                setBundleId={setBundleId}
                qualificationPackList={qualificationPack}
                userRoles={userRoles}
              />
            }
          />
        </View>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  roleName: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center"
  },
  image: {
    width: 25,
    height: 25,
    borderRadius: 25 / 2,
    marginEnd: 10
  },
  mainHeaderText: {
    fontSize: 12,
    fontFamily: FontFamily.Bold,
    lineHeight: 17,
    fontWeight: "700",
    paddingLeft: 25,
    marginTop: 10,
    marginBottom: 10
  },
  addRoleButton: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    padding: 10
  },
  statusContainer: {
    flexDirection: "row",
    alignItems: "center"
  },
  statusColor: {
    height: 8,
    width: 8,
    borderRadius: 50,
    marginRight: 8
  },
  activeColor: {
    backgroundColor: colorPallete.cordioGreen
  },
  inactiveColor: {
    backgroundColor: colorPallete.cordioOrange
  },
  textAlign: {
    fontFamily: FontFamily.Regular,
    color: colorPallete.textBlack,
    fontSize: 10
  },
  noFailedDataContainer: {
    padding: 25
  },
  customFontAlign: {},

  // Search bar disabled
  mainSearchBarContainer: {
    height: "calc(100vh - 150px)",
    padding: 25
  },
  searchBarContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 25
  },
  customWidthStyle: {
    width: 400
  },
  headerText: {
    fontSize: 14,
    fontFamily: FontFamily.Medium,
    lineHeight: 19.5,
    fontWeight: "700"
  }
});
