import React, { ReactElement, useEffect, useMemo, useState } from "react";
import { StyleSheet, useWindowDimensions, View } from "react-native";
import { colorPallete } from "@socion-cordio/common/src/assets/styles/colors";
import {
  Text,
  TextSize,
  FontWeight,
  FontFamily
} from "@socion-cordio/common/src/components/atoms/text";
import Icon, { IconNames } from "@socion-cordio/common/src/components/atoms/icon";
import { useLocation, useHistory } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { ApiClient } from "@socion-cordio/common/src/network/apiClient";
import { badgesEndPoints } from "@socion-cordio/common/src/repositories/endPoints";
import { HTTP_STATUS_CODES } from "@socion-cordio/common/src/network/constants";
import Loader from "@socion-cordio/common/src/components/atoms/loader";
import AssignBadgeSideBarPanelPage from "@socion-cordio/common/src/components/organisms/badges/assignBadgeSideBarPanel";
import Table from "@socion-cordio/web/src/components/molecules/table/table";
import { auxiliaryMethods } from "@socion-cordio/common/src/utils/auxiliaryMethods";
import { dataHelper } from "@socion-cordio/common/src/utils/dataHelper";
import DatePicker from "@socion-cordio/common/src/components/molecules/datePicker";
import Button, { ButtonType } from "@socion-cordio/common/src/components/atoms/button";
import AssignBadgeModal from "@socion-cordio/common/src/components/organisms/badges/assignBadgeModal";
import SocionModal from "@socion-cordio/common/src/components/atoms/modal";
import Moment from "moment";
import IndeterminateCheckbox from "@socion-cordio/common/src/utils/tableHelperRowSelection";
import ViewUserDetails from "@socion-cordio/common/src/components/organisms/common-modals/viewUserDetailsModal";
import { allRoutesNames } from "@socion-cordio/web/src/navigation/allRouteNames";

interface Props {
  onSubmit?: (data: { otp: number }) => void;
}

export default function AssignBadgePage(props: Props): ReactElement {
  const [selectedBadge, setSelectedBadge] = useState(null);
  const [badgeUsersList, setBadgeUsersList] = useState(null);
  const [loading, setLoading] = useState(true);
  const [failed, setFailed] = useState(false);
  const [selectedRows, setSelectedRows] = useState(undefined);
  const [assignBadgeModal, setAssignBadgeModal] = useState(false);
  const [assignBadgeData, setAssignBadgeData] = useState(undefined);
  const [badgeDetailsState, setBadgeDetailsState] = useState(null);
  const [loadingBadgeModal, setLoadingBadgeModal] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [startDateValidity, setStartDateValidty] = useState(null);
  const [viewButtonDetails, setViewButtonDetails] = useState(false);
  const [selectedUserDetails, setSelectedUserDetails] = useState(null);
  const [dateFields, setDateFields] = useState({
    startDate: undefined,
    endDate: undefined
  });
  const history: any = useHistory();
  const location: any = useLocation();

  useEffect(() => {
    setSelectedBadge(location?.state?.selectedBadge);
    setBadgeDetailsState(location?.state);
    getBadgeUsersList();
  }, []);

  const getBadgeUsersList = async () => {
    try {
      scrollToTop();
      const queryParams = {
        badgeId: location?.state?.selectedBadge?.badgeId,
        userListWithoutFilter: true
      };
      const response = await ApiClient.get(badgesEndPoints.getBadgeUsersList(), queryParams);
      if (response.responseCode === HTTP_STATUS_CODES.ok) {
        const data = response?.response?.userDetails;
        data?.forEach((attestationData: any, index: number) => {
          attestationData.serialNo = `${index + 1}.`;
          attestationData.formattedBaseLocation =
            auxiliaryMethods.fromatBaseLocation(attestationData);
        });
        setBadgeUsersList(data);
      } else {
        toast.error("Something went wrong...!!!");
      }
      setLoading(false);
      setEndDate("YYYY-MM-DD");
      setStartDate("YYYY-MM-DD");
    } catch (error) {
      toast.error("Something went wrong...!!!");
      setLoading(false);
      setFailed(true);
    }
  };

  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  const displayUserDetails = (data: any) => {
    setViewButtonDetails(true);
    setSelectedUserDetails(data);
  };

  const cellData = (testId: string, style: any, data: string, onPress?: Function) => (
    <Text
      fontWeight={FontWeight.Bold}
      testId={testId}
      textSize={TextSize.Small}
      style={[
        {
          fontSize: 12,
          fontFamily: FontFamily.Regular,
          fontWeight: FontFamily.Light,
          textSize: TextSize.Small,
          color: colorPallete.textBlack
        },
        style
      ]}
      onPress={onPress ? () => onPress() : null}
    >
      {data}
    </Text>
  );

  const columns = useMemo(
    () => [
      {
        width: 30,
        maxWidth: 30,
        disableSortBy: true,
        id: "selection",
        Header: ({ getToggleAllPageRowsSelectedProps }: any) => (
          <div style={{ marginLeft: 8 }}>
            <IndeterminateCheckbox {...getToggleAllPageRowsSelectedProps()} />
          </div>
        ),
        Cell: ({ row }: any) => (
          <div style={{ marginLeft: 8 }}>
            <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
          </div>
        )
      },
      {
        Header: "Sr.No",
        accessor: "serialNo",
        width: 30,
        maxWidth: 30,
        disableSortBy: true,
        Cell: ({ row: { original: badge } }: { row: { original: any } }) => {
          const style = {};
          return cellData("serialNo", style, badge?.serialNo);
        }
      },
      {
        Header: "Name",
        accessor: "name",
        width: 100,
        maxWidth: 100,
        Cell: ({ row: { original: badge } }: { row: { original: any } }) => {
          const style = {
            fontFamily: FontFamily.Medium,
            fontWeight: FontFamily.Bold
          };
          return cellData("name", style, badge?.name, () => {
            displayUserDetails(badge);
          });
        }
      },
      {
        Header: "Assigned Badges",
        accessor: "assignedBadges",
        width: 90,
        maxWidth: 90,
        Cell: ({ row: { original: badge } }: { row: { original: any } }) => {
          const style = {};
          let badgeTitles = auxiliaryMethods.getVerifiedQualification(badge.badgeTitles);
          if (badgeTitles.length === 0) {
            badgeTitles = "None";
          }
          return cellData("assignedBadges", style, badgeTitles);
        }
      },
      {
        Header: "Base Location",
        accessor: "baseLocation",
        width: 150,
        maxWidth: 150,
        Cell: ({ row: { original: badge } }: { row: { original: any } }) => {
          const style = {};
          let location: string = badge.formattedBaseLocation;
          if (badge.formattedBaseLocation === "") {
            location = "None";
          }
          return cellData("baseLocation", style, location);
        }
      }
    ],
    []
  );

  const handleSelectedRows = (selectedRows: any) => {
    if (selectedRows) {
      setSelectedRows(selectedRows);
    }
  };

  const hanldeDateChange = (name: string, value: string) => {
    setDateFields({
      ...dateFields,
      [name]: dataHelper.formatDate(value, "YYYY-MM-DD")
    });
  };

  const formatSelectedRows = (rows: any) => {
    let newRows: any = [];
    rows.forEach((row: any) => {
      let rowObject: any = {};
      rowObject.userId = row.userId;
      rowObject.startDate = startDate;
      rowObject.endDate = endDate;
      newRows.push(rowObject);
    });
    return newRows;
  };

  const handleBadgeAssign = async () => {
    try {
      setLoadingBadgeModal(true);
      setAssignBadgeModal(!assignBadgeModal);
      const formattedSelectedRows = formatSelectedRows(selectedRows);
      const data = {
        badgeId: location?.state?.selectedBadge?.badgeId,
        programId: location?.state?.programId,
        badgeList: formattedSelectedRows
      };
      const response = await ApiClient.post(badgesEndPoints.previewBadgesUsers(), data);
      if (response.responseCode === HTTP_STATUS_CODES.ok) {
        setAssignBadgeData(response);
        setLoadingBadgeModal(false);
      } else {
        // toast.error(response.message);
        // conflict data here
        setAssignBadgeData(response);
        setLoadingBadgeModal(false);
      }
    } catch (error) {
      toast.error("Something went wrong...!!!");
      setLoadingBadgeModal(false);
    }
  };

  const checkStartDate = (date: any, days: any) => {
    const result = new Date(date);
    result.setDate(result.getDate() + days);
    const newDate = Moment(result).format("YYYY-MM-DD");
    setStartDateValidty(newDate);
  };

  return (
    <View>
      {loading ? (
        <Loader />
      ) : failed ? (
        <View>Failed to load data</View>
      ) : (
        <>
          <View style={styles.container}>
            <View style={styles.rightContainer}>
              <View style={styles.subContainer}>
                <View style={styles.subContainerOne}>
                  <View style={styles.headerContainer}>
                    <View style={styles.iconContainerHeader}>
                      <Icon
                        testID="down"
                        name={IconNames.down}
                        customStyle={styles.titleIcon}
                        onPress={() => {
                          history.goBack();
                        }}
                      />
                    </View>
                    <View>
                      <Text
                        fontWeight={FontWeight.Light}
                        testId="text"
                        textSize={TextSize.Small}
                        textStyle={[styles.headerText, styles.headerTextTitleSupport]}
                      >
                        {selectedBadge?.name}
                      </Text>
                    </View>
                    <View>
                      <Text
                        onPress={() => {}}
                        fontWeight={FontWeight.Regular}
                        testId="text"
                        textSize={TextSize.Small}
                        textStyle={[styles.headerText, { marginLeft: 10, marginRight: 0 }]}
                      >
                        {">"}
                      </Text>
                    </View>
                    <View>
                      <Text
                        onPress={() => {}}
                        fontWeight={FontWeight.Regular}
                        testId="text"
                        textSize={TextSize.Small}
                        textStyle={[styles.headerText, styles.headerTextSupport]}
                      >
                        Assign Badge
                      </Text>
                    </View>
                  </View>
                  <View>
                    {/* Table goes here */}
                    <View style={styles.tableContainer}>
                      <Table
                        columns={columns}
                        data={badgeUsersList}
                        handleSelectedRows={handleSelectedRows}
                      />
                    </View>
                  </View>
                </View>
              </View>
              <View style={styles.bottomContainer}>
                <View style={styles.dateContainer}>
                  <View>
                    <Text
                      fontWeight={FontWeight.Regular}
                      testId="addRoleTitleText"
                      textSize={TextSize.Small}
                      textStyle={styles.subheaderText}
                    >
                      Valid through
                    </Text>
                  </View>
                  <View style={styles.subdateContainer}>
                    <Text
                      fontWeight={FontWeight.Regular}
                      testId="addRoleTitleText"
                      textSize={TextSize.Small}
                      textStyle={[styles.subheaderText, styles.fontAlign]}
                    >
                      Start
                    </Text>
                    <View style={styles.datePicker}>
                      <DatePicker
                        // min="2019-01-01"
                        // max="2022-12-31"
                        handleBlur={() => {}}
                        onChange={(value: any) => [
                          // hanldeDateChange("startDate", value),
                          setStartDate(value.target.value),
                          setEndDate(""),
                          checkStartDate(value.target.value, 1)
                        ]}
                        value={startDate}
                        // value={formikProps.values.roleStartDate}
                        name="roleStartDate"
                        id="roleStartDate"
                        noFormik
                      />
                    </View>
                  </View>

                  <View style={styles.subdateContainer}>
                    <Text
                      fontWeight={FontWeight.Regular}
                      testId="addRoleTitleText"
                      textSize={TextSize.Small}
                      textStyle={[styles.subheaderText, styles.fontAlign]}
                    >
                      End
                    </Text>
                    <View style={styles.datePicker}>
                      <DatePicker
                        min={startDateValidity}
                        // max="2022-12-31"
                        handleBlur={() => {}}
                        onChange={(value: any) => [
                          // hanldeDateChange("endDate", value),
                          setEndDate(value.target.value)
                        ]}
                        value={endDate}
                        name="roleEndDate"
                        id="roleEndDate"
                        noFormik
                      />
                    </View>
                  </View>
                </View>

                <View style={styles.buttonContainer}>
                  <View>
                    <Button
                      type={ButtonType.Secondary}
                      buttonStyles={styles.button}
                      textStyles={styles.buttonFont}
                      title="Cancel"
                      onPress={() => history.push(allRoutesNames.app + allRoutesNames.BADGES)}
                    />
                  </View>
                  <View>
                    <Button
                      type={ButtonType.Primary}
                      buttonStyles={styles.button}
                      textStyles={styles.buttonFont}
                      title="Assign"
                      onPress={handleBadgeAssign}
                      disabled={
                        selectedRows?.length == 0 ||
                        startDate == "YYYY-MM-DD" ||
                        endDate == "YYYY-MM-DD" ||
                        startDate == null ||
                        startDate == "" ||
                        endDate == null ||
                        endDate == ""
                      }
                    />
                  </View>
                </View>
              </View>
            </View>
            <View style={styles.leftContainer}>
              <View style={{ marginTop: 60 }}>
                <AssignBadgeSideBarPanelPage selectedBadge={selectedBadge} />
              </View>
            </View>

            {assignBadgeModal && (
              <View>
                <SocionModal
                  modalVisible={assignBadgeModal}
                  setModalVisible={() => setAssignBadgeModal(!assignBadgeModal)}
                  customContainer={{ padding: 0 }}
                  component={
                    <>
                      {loadingBadgeModal ? (
                        <Loader customLoadingContainer={styles.badgesModalLoader} />
                      ) : (
                        <AssignBadgeModal
                          onClose={() => setAssignBadgeModal(false)}
                          assignBadgeData={assignBadgeData}
                          formatSelectedRows={formatSelectedRows}
                          badgeDetailsState={badgeDetailsState}
                          getBadgeUsersList={getBadgeUsersList}
                          calendarStartDate={setStartDate}
                          calendarEndDate={setEndDate}
                        />
                      )}
                    </>
                  }
                />
              </View>
            )}

            {viewButtonDetails && (
              <View>
                <SocionModal
                  modalVisible={viewButtonDetails}
                  setModalVisible={() => setViewButtonDetails(!viewButtonDetails)}
                  component={
                    <ViewUserDetails
                      onClose={() => {
                        setViewButtonDetails(false);
                      }}
                      selectedUserDetails={selectedUserDetails}
                      piiFilter={true}
                    />
                  }
                />
              </View>
            )}
          </View>
        </>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flexDirection: "row"
  },
  rightContainer: {
    display: "flex",
    padding: 20,
    paddingTop: 15,
    backgroundColor: "#F8F5F0",
    width: "calc(100% - 240px)"
  },
  leftContainer: {
    marginTop: -60,
    flex: 1,
    backgroundColor: colorPallete.cordioRedDark2,
    minHeight: "100vh"
  },
  header: {
    display: "flex",
    flexDirection: "row"
  },
  iconContainer: {
    marginTop: 10
  },
  titleIcon: {
    color: colorPallete.textBlack,
    fontSize: 9,
    transform: [{ rotate: "90deg" }]
  },
  headerText: {
    fontSize: 12,
    fontFamily: FontFamily.Medium,
    lineHeight: 17
  },
  subContainer: {
    backgroundColor: colorPallete.white,
    borderRadius: 10,
    shadowColor: colorPallete.cordioRedDark1,
    shadowOpacity: 0.1,
    shadowOffset: {
      height: 10,
      width: 5
    },
    shadowRadius: 10,
    marginBottom: 30
  },
  containerStyle: {
    width: "30%",
    marginLeft: 15
  },
  dropDownContainerStyle: {
    borderWidth: 0,
    borderTopRightRadius: 5,
    borderBottomRightRadius: 5
  },
  subContainerOne: {},

  //Header Container
  headerTextTitleSupport: {
    fontWeight: "700"
  },
  headerContainer: {
    flexDirection: "row",
    padding: 18,
    paddingBottom: 0,
    alignItems: "center"
  },
  headerTextSupport: {
    fontWeight: "400",
    marginLeft: 10
  },
  iconContainerHeader: {
    marginRight: 20
  },

  // collapse Container
  containerCollapse: {
    marginTop: 30,
    flexDirection: "column"
  },
  memberViewCollapseContainer: {
    flexDirection: "row"
  },
  collapseContainer: {
    marginBottom: 20
  },
  tableContainer: {},
  bottomContainer: {
    flexDirection: "row",
    width: "100%"
  },
  dateContainer: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    flex: 60
  },
  subdateContainer: {
    flexDirection: "row",
    alignItems: "center"
  },
  subheaderText: {
    fontWeight: "400",
    fontSize: 10,
    fontFamily: FontFamily.Regular,
    lineHeight: 17,
    color: colorPallete.textLight
  },
  fontAlign: {
    color: colorPallete.textBlack
  },
  datePicker: {
    marginLeft: 5
  },
  buttonContainer: {
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
    flex: 40,
    flexWrap: "wrap"
  },
  button: {
    width: "140px",
    height: "50px",
    borderRadius: 10,
    margin: 4,
    marginLeft: 10
  },
  badgesModalLoader: {
    width: 800,
    height: 300
  },
  buttonFont: {
    fontSize: 12
  }
});
