import React, { ReactElement, useEffect, useState } from "react";
import { StyleSheet, View } from "react-native";
import { colorPallete } from "@socion-cordio/common/src/assets/styles/colors";
import {
  Text,
  TextSize,
  FontWeight,
  FontFamily
} from "@socion-cordio/common/src/components/atoms/text";
import Icon, { IconNames } from "@socion-cordio/common/src/components/atoms/icon";
import { useLocation, useHistory } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { ApiClient } from "@socion-cordio/common/src/network/apiClient";
import { qualificationPackEndPoints } from "@socion-cordio/common/src/repositories/endPoints";
import Loader from "@socion-cordio/common/src/components/atoms/loader";
import { useTranslation } from "react-i18next";
import QualiParticipantMappingTable from "@socion-cordio/common/src/components/organisms/qualification-packs/qualiParticipantMappingTable";
import { auxiliaryMethods } from "@socion-cordio/common/src/utils/auxiliaryMethods";
import { LocalStorage } from "@socion-cordio/common/src/services/storage/storageService";
import AddTelemetryService from "@socion-cordio/common/src/services/telemetryService";
import Moment from "moment";
import _ from "lodash";
interface Props {
  onSubmit?: (data: { otp: number }) => void;
}

export default function QualificationPariticpantMappingPage(props: Props): ReactElement {
  const { t } = useTranslation();
  const [memberList, setMemberList] = useState([]);
  const [topicsList, setTopicsList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [csvLoader, setCsvLoader] = useState(false);
  const [failed, setFailed] = useState(false);
  const [sidePanelOpen, setSidePanelOpen] = useState(null);
  const [selectionListNumber, setSelectionListNumber] = useState(null);
  const [selectedBadgeNameList, setSelectedBadgeNameList] = useState([]);
  const [locationNameList, setLocationNameList] = useState([]);
  const [commonData, setCommonData] = useState({
    badgeIds: [],
    locations: [],
    isNoneLocationSet: null,
    isNoBadgeAssignSet: null
  });

  const location: any = useLocation();
  const history: any = useHistory();

  // const dispatch = useDispatch();
  // const {
  //   bundleData: bundles,
  //   loading: { getBundle: loading }
  // } = useSelector((state: any) => state.bundleData);

  useEffect(() => {
    getQualificationDetails();
  }, [location?.state, commonData]);

  useEffect(() => {
    getSidePanelCollapsedHandler();
  }, [LocalStorage.getStorage("isSidePanelCollapsed")]);

  const getSidePanelCollapsedHandler = async () => {
    let isSidePanelCollapsed = await LocalStorage.getStorage("isSidePanelCollapsed");
    setSidePanelOpen(isSidePanelCollapsed);
  };

  const capitalizeFirstLetter = (input: any) => {
    if (input !== "") {
      let words = input.split(" ");
      let CapitalizedWords: any = [];
      words.forEach((element: any) => {
        CapitalizedWords.push(element[0].toUpperCase() + element.slice(1, element.length));
      });
      return CapitalizedWords.join(" ");
    }
  };

  const formatLocation = (location: any) => {
    let newArray = location.map((i: any) => {
      return {
        ...i,
        city: capitalizeFirstLetter(i.city),
        district: capitalizeFirstLetter(i.district),
        state: capitalizeFirstLetter(i.state),
        subDistrict: capitalizeFirstLetter(i.subDistrict)
      };
    });
    return newArray;
  };

  const getQualificationDetails = async () => {
    try {
      const bundleId = location?.state?.qualificationPackDetails?.bundle?.id;
      const selectionNo = location?.state?.packDetails?.selectionNoUpdated;
      const body = {
        disableLocationFilter: commonData.isNoneLocationSet ? true : false,
        disableBadgeFilter: commonData.isNoBadgeAssignSet ? true : false,
        allUsers: location?.state?.isTotalParticipationEnabled ? true : false,
        badgeId: commonData.badgeIds,
        locations: formatLocation(commonData.locations)
      };
      const response = await ApiClient.post(
        qualificationPackEndPoints.getQualificationParticipantMapping(bundleId, selectionNo), //isme woh cheez bhejni hai
        body
      );
      let list = _.orderBy(response?.response, ["name"], ["asc"]);
      getQualiPackDetails();
      setMemberList(list);
      setLoading(false);
      setFailed(false);
    } catch (error) {
      toast.error("Something went wrong.");
      setFailed(true);
    }
  };

  const getQualiPackDetails = async () => {
    try {
      const bundleId = location?.state?.qualificationPackDetails?.bundle?.id;
      const response = await ApiClient.get(
        qualificationPackEndPoints.getQualificationPackBundleDetails(bundleId),
        {}
      );
      // const bundleList: any[] = response?.response?.bundleTopiMapping;
      // let list: any[] = bundleList.map(({ selectionId, topicName }) => ({
      //   selectionId,
      //   topicName
      // }));
      setTopicsList(response?.response?.bundleTopiMapping);
      setLoading(false);
      setFailed(false);
    } catch (error) {
      toast.error("Something went wrong.");
      setFailed(true);
    }
  };

  const filteredDataHandler = (data: any) => {
    convertBadgeIdsToNameHandler(data);
    setLocationNameList(data.locationNameList);
    setCommonData({
      badgeIds: data.badgeIds,
      locations: data.locations,
      isNoneLocationSet: data.isNoneLocationSet,
      isNoBadgeAssignSet: data.isNoBadgeAssignSet
    });
  };

  const convertBadgeIdsToNameHandler = (data: any) => {
    const badgeIdsList = data.badgeIds;
    const badgesList = data.badgeList;
    const badgeNamesList = badgesList
      ?.filter((item: any) => badgeIdsList?.includes(item.badgeId))
      .map((element: any) => element.name);
    setSelectedBadgeNameList(badgeNamesList);
  };

  const participantDetailsCSV = async () => {
    try {
      const bundleId = location?.state?.qualificationPackDetails?.bundle?.id;
      const selectionNo = location?.state?.packDetails?.selectionNoUpdated;
      // const locList = locationNameList.map((item: any) => `${item.city}, ${item.country}`);
      const requestPayload = {
        allUsers: location?.state?.isTotalParticipationEnabled ? true : false,
        disableLocationFilter: commonData.isNoneLocationSet ? true : false,
        disableBadgeFilter: commonData.isNoBadgeAssignSet ? true : false,
        badgeId: commonData.badgeIds,
        locations: formatLocation(commonData.locations),
        locationNames: commonData.isNoneLocationSet ? ["None"] : locationNameList,
        badgeNames: commonData.isNoBadgeAssignSet ? ["No Badge Assigned"] : selectedBadgeNameList
      };
      const participantListCSVResponse = await ApiClient.post(
        qualificationPackEndPoints.getParticipantMappingCSV(bundleId, selectionNo),
        requestPayload
      );
      // getQualificationDetails();
      // setLoading(false);
      setCsvLoader(false);
      return participantListCSVResponse;
    } catch (error) {
      setCsvLoader(false);
      toast.error("Something went wrong.");
    }
  };

  const downloadCsvList = async () => {
    // setLoading(true);
    setCsvLoader(true);
    const program: any = await LocalStorage.getStorage("programDetails");
    const { name } = program.program;
    const data = await participantDetailsCSV();
    let fileName = `${name}-Qualification-Pack-info`;
    auxiliaryMethods.handleDownload(data, fileName);
    const eventType: string = "Download Participant List";
    updateTelemetryEvent(eventType);
  };

  const updateTelemetryEvent = async (eventType: string) => {
    const programDetails: any = await LocalStorage.getStorage("programDetails");
    const user: any = await LocalStorage.getStorage("user");
    const selectionNo = location?.state?.packDetails?.selectionNoUpdated;
    const { bundle } = location?.state?.qualificationPackDetails;
    const body: any = {
      timestamp: Moment().toLocaleString(),
      createdAt: Moment().toLocaleString(),
      deleted: "false",
      updatedAt: Moment().toLocaleString(),
      eventType: eventType,
      noOfSelections: selectionListNumber.length,
      bundleId: bundle.id,
      bundleName: bundle.name,
      entityId: null,
      entityName: null
    };
    AddTelemetryService(body, programDetails, user, false);
  };

  return (
    <View>
      {loading ? (
        <View style={styles.loaderContainer}>
          <View style={styles.subLoaderContainer}>
            <Loader />
          </View>
        </View>
      ) : failed ? (
        <View style={styles.noFailedDataContainer}>Failed to load data</View>
      ) : (
        <View style={sidePanelOpen ? styles.wideContainer : styles.container}>
          <View style={styles.rightContainer}>
            <View style={styles.subContainer}>
              <View style={styles.headerContainer}>
                <View style={styles.iconContainerHeader}>
                  <Icon
                    testID="down"
                    name={IconNames.down}
                    customStyle={styles.titleIcon}
                    onPress={() => {
                      history.goBack();
                    }}
                  />
                </View>
                <View>
                  <Text
                    fontWeight={FontWeight.Light}
                    testId="text"
                    textSize={TextSize.Small}
                    textStyle={[styles.headerText, styles.headerTextTitleSupport]}
                  >
                    {location?.state?.qualificationPackDetails?.bundle?.name}
                  </Text>
                </View>
                <View>
                  <Text
                    onPress={() => {}}
                    fontWeight={FontWeight.Regular}
                    testId="text"
                    textSize={TextSize.Small}
                    textStyle={[styles.headerText, styles.headerTextTitleSupport, styles.alignIcon]}
                  >
                    {">"}
                  </Text>
                </View>
                <View>
                  <Text
                    fontWeight={FontWeight.Light}
                    testId="text"
                    textSize={TextSize.Small}
                    textStyle={[
                      styles.headerText,
                      styles.headerTextTitleSupport,
                      styles.alignHeader
                    ]}
                  >
                    {/* {qualiPackData?.bundle?.name} */}
                    {" Pack completion"}
                  </Text>
                </View>
                <View>
                  <Text
                    onPress={() => {}}
                    fontWeight={FontWeight.Regular}
                    testId="text"
                    textSize={TextSize.Small}
                    textStyle={[styles.headerText, styles.headerTextSupport, styles.alignIcon]}
                  >
                    {">"}
                  </Text>
                </View>
                <View>
                  <Text
                    onPress={() => {}}
                    fontWeight={FontWeight.Regular}
                    testId="text"
                    textSize={TextSize.Small}
                    textStyle={[styles.headerText, styles.headerTextSupport]}
                  >
                    {/* {sessionDetail?.name} */}
                    {"Participant mapping"}
                  </Text>
                </View>
              </View>
              <View>
                <QualiParticipantMappingTable
                  filteredDataHandler={filteredDataHandler}
                  downloadCsvList={downloadCsvList}
                  memberList={memberList}
                  topicsList={topicsList}
                  setSelectionListNumber={setSelectionListNumber}
                  csvLoader={csvLoader}
                />
              </View>
            </View>
          </View>
        </View>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    width: "calc(100vw - 235px)"
  },
  wideContainer: {
    flexDirection: "row",
    width: "calc(100vw - 75px)"
  },
  rightContainer: {
    display: "flex",
    padding: 20,
    paddingTop: 15,
    backgroundColor: "#F8F5F0",
    width: "100%"
  },
  loaderContainer: {
    padding: 20,
    width: "100%"
  },
  subLoaderContainer: {
    backgroundColor: colorPallete.white,
    borderRadius: 10,
    padding: 20
  },

  titleIcon: {
    color: colorPallete.textBlack,
    fontSize: 9,
    marginRight: 10,
    transform: [{ rotate: "90deg" }]
  },
  headerText: {
    fontSize: 12,
    fontFamily: FontFamily.Medium,
    lineHeight: 17
  },
  subContainer: {
    backgroundColor: colorPallete.white,
    borderRadius: 10,
    marginBottom: 30,
    shadowColor: colorPallete.cordioRedDark1,
    shadowOpacity: 0.1,
    shadowOffset: {
      height: 10,
      width: 5
    },
    shadowRadius: 10
  },

  //Header Container
  headerTextTitleSupport: {
    fontWeight: "700"
  },
  alignHeader: {
    marginLeft: 10
  },
  headerContainer: {
    flexDirection: "row",
    alignItems: "center",
    padding: 18,
    paddingBottom: 0
  },
  headerTextSupport: {
    // fontWeight: "400",
    fontFamily: FontFamily.Regular,
    marginLeft: 10
  },
  iconContainerHeader: {
    marginRight: 10
  },
  alignIcon: {
    marginLeft: 10,
    marginRight: 0,
    textDecorationLine: "none"
  },
  participantCountContainer: {
    flexDirection: "row",
    justifyContent: "flex-end"
  },
  totalCountText: {
    fontSize: 14,
    textDecorationLine: "none"
  },
  headerTextSupportCount: {
    marginLeft: 10,
    fontFamily: FontFamily.Bold,
    lineHeight: 17,
    fontWeight: "700"
  },
  noFailedDataContainer: {
    padding: 25
  }
});
