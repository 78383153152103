import { omit } from "lodash";
import { Artefact } from "@socion-cordio/common/src/models/artefact";
import { ArtefactApiClient } from "@socion-cordio/common/src/network/artefactApiClient";
import { artefactEndPoints } from "@socion-cordio/common/src/repositories/endPoints";
import { ArtefactTypes } from "@socion-cordio/common/src/models/artefactTypes";
import { Notifications } from "@socion-cordio/common/src/models/artefactNotifications";
import { ApiClient } from "@socion-cordio/common/src/network/apiClient";
import { CONFIG } from "@socion-cordio/common/src/config/envConstant";

class ArtefactRepository {
  getNotifications = async (): Promise<Notifications> => {
    console.log("get Notifications from repo");
    let data = await ArtefactApiClient.get(artefactEndPoints.getNotifications());
    console.log("not data : ", data);
    return data;
  };
  getArtefacts = async (body: {
    pid: number;
    artefact_type_id?: string;
    country?: string;
    state?: string;
    district?: string;
    city?: string;
    userId?: string;
  }): Promise<Artefact> => {
    const userId = body.userId;
    const req = omit(body, "userId");
    let data = await ArtefactApiClient.post(artefactEndPoints.getArtefactList(userId), req);
    return data;
  };

  getArtefactTypes = async (body: { pid: number }): Promise<ArtefactTypes> => {
    let data = await ArtefactApiClient.get(artefactEndPoints.getArtefactTypeList(body.pid));
    return data;
  };

  getArtefactTypeDetails = async (pid: number, artefactTypeId: string) => {
    let data = await ArtefactApiClient.get(
      artefactEndPoints.getArtefactTypeDetails(pid, artefactTypeId)
    );
    return data;
  };

  getArtefactDetails = async (artefactId: string) => {
    let data = await ArtefactApiClient.get(artefactEndPoints.getArtefactDetails(artefactId));
    return data;
  };

  getArtefactDocs = async (artefactId: string) => {
    let data = await ArtefactApiClient.get(artefactEndPoints.getArtefactDocs(artefactId));
    return data;
  };

  getArtefactWorkFlow = async (artefactId: string) => {
    let data = await ArtefactApiClient.get(artefactEndPoints.getArtefactWorkFlow(artefactId));
    return data;
  };

  addArtefactType = async (
    pid: number,
    request: {
      title: string;
      desc: string;
      active: number;
      created_by: string;
      templates: any[];
    }
  ) => {
    let res = await ArtefactApiClient.post(artefactEndPoints.addArtefactType(pid), request);
    //return res.pipe(map((response: { [x: string]: any; }) => response["data"]));
    return res;
  };

  startUpload = async (body: any) => {
    let res = await ArtefactApiClient.post(artefactEndPoints.startUpload(), body);
    return res;
  };

  getSignedUrl = async (body: any) => {
    let res = await ArtefactApiClient.post(artefactEndPoints.signedUrl(), body);
    return res;
  };

  completeUpload = async (body: any) => {
    let res = await ArtefactApiClient.post(artefactEndPoints.completeUpload(), body);
    return res;
  };

  searchUsers = async (body: any) => {
    let res = await ArtefactApiClient.post(artefactEndPoints.searchUsers(), body);
    return res;
  };

  addArtefact = async (body: any) => {
    let res = await ArtefactApiClient.post(artefactEndPoints.createArtefact(), body);
    return res;
  };

  updateArtefact = async (artefactId: string, body: any) => {
    let res = await ArtefactApiClient.put(artefactEndPoints.updateArtefact(artefactId), body);
    return res;
  };

  deleteArtefact = async (artefactId: string) => {
    let res = await ArtefactApiClient.delete(artefactEndPoints.deleteArtefactMetadata(artefactId));
    return res;
  };

  addArtefactDocs = async (artefactId: string, body: any) => {
    let res = await ArtefactApiClient.post(artefactEndPoints.addArtefactDoc(artefactId), body);
    return res;
  };

  addNotification = async (body: any) => {
    let res = await ArtefactApiClient.post(artefactEndPoints.addNotification(), body);
    return res;
  };

  addWorkflow = async (artefactId: string, body: any) => {
    let res = await ArtefactApiClient.post(artefactEndPoints.addWorkflow(artefactId), body);
    return res;
  };

  deleteDoc = async (artefactId: string, docId: string) => {
    let res = await ArtefactApiClient.delete(
      artefactEndPoints.deleteArtefactDoc(artefactId, docId)
    );
    return res;
  };

  deleteSubmitter = async (artefactId: string, submitterId: string) => {
    let res = await ArtefactApiClient.delete(
      artefactEndPoints.deleteArtefactSubmitter(artefactId, submitterId)
    );
    return res;
  };

  updateArtefactsTelemetry = async (body: any): Promise<any> => {
    const request = {
      url: CONFIG.REACT_APP_API_URL + artefactEndPoints.telemetry(),
      id: body?.artefactId,
      telemetryData: body
    };
    console.log("updateArtefactsTelemetry", request);
    const telemetryWrapper = artefactEndPoints.telemetryWrapper();
    let data = await ArtefactApiClient.post(telemetryWrapper, body);
    return data;
  };

  setCookie = async () => {
    let res = await ArtefactApiClient.post(artefactEndPoints.setCookie(), {});
    return res;
  };

  validateToken = async (token: any) => {
    let res = await ArtefactApiClient.get(artefactEndPoints.validateToken(), {}, { token });
    return res;
  };
  sendSessionNotification = async (body: any) => {
    let res = await ApiClient.post(artefactEndPoints.sessionNotification(), body);
    return res;
  };
}

const artefactRepository = new ArtefactRepository();
export { artefactRepository as ArtefactRepository };
