import React, { ReactElement } from "react";
import { StyleSheet, View } from "react-native";
import { colorPallete } from "@socion-cordio/common/src/assets/styles/colors";
import {
  Text,
  TextSize,
  FontWeight,
  FontFamily
} from "@socion-cordio/common/src/components/atoms/text";
import { dataHelper } from "@socion-cordio/common/src/utils/dataHelper";

interface Props {
  selectedBadge?: any;
}

export default function AssignBadgeSideBarPanelPage(props: Props): ReactElement {
  const { selectedBadge } = props;

  return (
    <View style={styles.container}>
      <View style={styles.subContainer}>
        <View style={styles.alignContainer}>
          <Text
            fontWeight={FontWeight.Light}
            testId="text"
            textSize={TextSize.Small}
            textStyle={[styles.headerText, styles.headerTextTitleSupport]}
          >
            {selectedBadge?.name}
          </Text>
        </View>

        <View style={(styles.badgeHeaderAlignmnet, styles.subHeaderAlignment)}>
          <View style={styles.dateCreatedContainer}>
            <Text
              fontWeight={FontWeight.Light}
              testId="addRoleText"
              textSize={TextSize.Small}
              textStyle={[
                styles.headerText,
                styles.headerAlignment,
                styles.fontSizeText,
                { marginRight: 10 }
              ]}
            >
              Created on
            </Text>
            <Text
              fontWeight={FontWeight.Light}
              testId="addRoleText"
              textSize={TextSize.Small}
              textStyle={[styles.headerText, styles.fontSizeText, styles.colorText]}
            >
              {dataHelper.formatDate(selectedBadge?.createdAt, "DD MMM YYYY")}
            </Text>
          </View>
        </View>

        <View>
          <View style={styles.badgeHeaderAlignmnet}>
            <Text
              fontWeight={FontWeight.Light}
              testId="addRoleText"
              textSize={TextSize.Small}
              textStyle={[styles.headerText, styles.headerAlignment]}
            >
              Description
            </Text>
          </View>
          <View style={styles.badgeHeaderAlignment}>
            <Text
              fontWeight={FontWeight.Light}
              testId="addRoleText"
              textSize={TextSize.Small}
              textStyle={[styles.headerText, styles.colorText]}
            >
              {selectedBadge?.description}
            </Text>
          </View>
        </View>

        <View>
          <View style={styles.badgeHeaderAlignmnet}>
            <Text
              fontWeight={FontWeight.Light}
              testId="addRoleText"
              textSize={TextSize.Small}
              textStyle={[styles.headerText, styles.headerAlignment]}
            >
              Awarding criteria
            </Text>
          </View>
          <View style={styles.badgeHeaderAlignment}>
            <Text
              fontWeight={FontWeight.Light}
              testId="addRoleText"
              textSize={TextSize.Small}
              textStyle={[styles.headerText, styles.colorText]}
            >
              {selectedBadge?.awardingCriteria}
            </Text>
          </View>
        </View>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    padding: 20
    // height:'calc(100vh - 200px)'
    // height:'100vh'
    // backgroundColor: colorPallete.cordioRedDark2
  },
  subContainer: {},
  alignContainer: {
    marginBottom: 15
  },
  headerText: {
    fontSize: 12,
    fontFamily: FontFamily.Medium,
    lineHeight: 17
  },
  headerTextTitleSupport: {
    fontWeight: "700",
    fontSize: 14,
    lineHeight: 20,
    color: colorPallete.white
  },
  badgeHeaderAlignmnet: {
    marginBottom: 0,
    flex: 20
  },
  subHeaderAlignment: {
    // flexDirection: "row",
    marginTop: 5,
    marginBottom: 10
  },
  dateCreatedContainer: { flexDirection: "row" },
  badgeHeaderAlignment: {
    marginBottom: 10
  },
  headerAlignment: {
    color: colorPallete.textLight,
    fontFamily: FontFamily.Regular
  },
  fontSizeText: {
    fontSize: 12
  },
  colorText: {
    color: colorPallete.white,
    fontFamily: FontFamily.Regular
  }
});
