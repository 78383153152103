import React, { ReactElement, useEffect, useState, useMemo } from "react";
import { View, StyleSheet, TouchableOpacity } from "react-native";
import {
  Text,
  TextSize,
  FontWeight,
  FontFamily
} from "@socion-cordio/common/src/components/atoms/text";
import Table from "@socion-cordio/web/src/components/molecules/table/table";
import { useDispatch, useSelector } from "react-redux";
import { colorPallete } from "@socion-cordio/common/src/assets/styles/colors";
import Loader from "@socion-cordio/common/src/components/atoms/loader";
import { Image } from "@socion-cordio/common/src/components/atoms/image";
import { ParticipantDetailsActions } from "@socion-cordio/common/src/modules/participationDetails/actions/actions";
import { auxiliaryMethods } from "@socion-cordio/common/src/utils/auxiliaryMethods";
import Icon, { IconNames } from "@socion-cordio/common/src/components/atoms/icon";
import { ApiClient } from "@socion-cordio/common/src/network/apiClient";
import { participationDetailsEndPoints } from "@socion-cordio/common/src/repositories/endPoints";
import SocionModal from "@socion-cordio/common/src/components/atoms/modal";
import ViewUserDetails from "@socion-cordio/common/src/components/organisms/common-modals/viewUserDetailsModal";

interface Props {
  programDetails: any;
}

export default function TrainerByTopic(props: Props): ReactElement {
  const { programDetails } = props;
  const [loading, setLoading] = useState(true);
  const [failed, setFailed] = useState(false);
  const [trainerByTopicData, setTrainerByTopicData] = useState(null);
  const [viewButtonDetails, setViewButtonDetails] = useState(false);
  const [selectedUserDetails, setSelectedUserDetails] = useState(null);
  const dispatch = useDispatch();
  let participationDetails: any = useSelector((state: any) => state?.participationDetails);

  useEffect(() => {
    // getTrainerAttestationData();
    dispatch(
      ParticipantDetailsActions.getTrainerByTopic({ programId: programDetails?.program?.id })
    );
  }, []);

  useEffect(() => {
    processTrainerAttestatinData();
  }, [participationDetails]);

  const trainerByTopicDetailsCSV = async () => {
    const trainerByTopicListCSVResponse = await ApiClient.post(
      participationDetailsEndPoints.getTrainerByTopicListCSV(programDetails?.program?.id),
      {}
    );
    return trainerByTopicListCSVResponse;
  };

  const downloadCsvList = async () => {
    const data = await trainerByTopicDetailsCSV();
    auxiliaryMethods.handleDownload(
      data,
      `${programDetails?.program?.name}-Trainer-TrainersByTopic`
    );
    auxiliaryMethods.updateTelemetryEvent("Download Trainer List");
  };

  const processTrainerAttestatinData = () => {
    let data = participationDetails?.trainerByTopicData?.response;
    data?.forEach((attestationData: any, index: number) => {
      attestationData.serialNo = `${index + 1}.`;
      attestationData.formattedBaseLocation = auxiliaryMethods.fromatBaseLocation(attestationData);
    });
    setTrainerByTopicData(data);
    setLoading(false);
  };

  const displayUserDetails = (data: any) => {
    setViewButtonDetails(true);
    setSelectedUserDetails(data);
  };

  const cellData = (testId: string, style: any, data: string, onPress?: Function) => (
    <Text
      fontWeight={FontWeight.Bold}
      testId={testId}
      textSize={TextSize.Small}
      style={[
        {
          fontSize: 12,
          fontFamily: FontFamily.Regular,
          fontWeight: FontFamily.Light,
          textSize: TextSize.Small,
          color: colorPallete.textBlack
        },
        style
      ]}
      onPress={onPress ? () => onPress() : null}
    >
      {data}
    </Text>
  );

  const columns = useMemo(
    () => [
      {
        Header: "Sr.No",
        accessor: "serialNo",
        width: 30,
        maxWidth: 30,
        disableSortBy: true,
        Cell: ({ row: { original: trainer } }: { row: { original: any } }) => {
          const style = {};
          return cellData("serialNo", style, trainer?.serialNo);
        }
      },

      {
        Header: "Trainer Name",
        accessor: "trainerName",
        width: 70,
        maxWidth: 70,
        Cell: ({ row: { original: trainer } }: { row: { original: any } }) => {
          return (
            <Text
              fontWeight={FontWeight.Bold}
              testId="participantName"
              textSize={TextSize.Large}
              style={{
                fontSize: 12,
                fontFamily: FontFamily.Regular,
                color: colorPallete.textBlack
              }}
            >
              <TouchableOpacity onPress={() => displayUserDetails(trainer)}>
                {trainer?.name}
              </TouchableOpacity>
            </Text>
          );
        }
      },
      {
        Header: "Base Location",
        accessor: "baseLocation",
        width: 90,
        maxWidth: 90,
        Cell: ({ row: { original: trainer } }: { row: { original: any } }) => {
          const style = {};
          let location: string = trainer?.formattedBaseLocation;
          if (trainer?.formattedBaseLocation === "") {
            location = "None";
          }
          return cellData("baseLocation", style, location);
        }
      },
      {
        Header: "Establishment Name",
        accessor: "establishmentName",
        width: 65,
        maxWidth: 65,
        Cell: ({ row: { original: trainer } }: { row: { original: any } }) => {
          const style = {};
          let establishmentName: string = trainer?.establishmentName;
          if (trainer?.establishmentName === "") {
            establishmentName = "None";
          }
          return cellData("establishmentName", style, establishmentName);
        }
      },
      {
        Header: "Verified Role",
        accessor: "verifiedRole",
        width: 60,
        maxWidth: 60,
        Cell: ({ row: { original: participant } }: { row: { original: any } }) => {
          const style = {};
          const verifiedRole = auxiliaryMethods.getVerifiedRole(participant.roleDetails);
          return cellData("verifiedRole", style, verifiedRole);
        }
      },
      {
        Header: "Verified Qualification",
        accessor: "verifiedQualification",
        width: 60,
        maxWidth: 60,
        Cell: ({ row: { original: participant } }: { row: { original: any } }) => {
          const style = {};
          const verifiedQualification = auxiliaryMethods.getVerifiedQualification(
            participant.qualificationTitle
          );
          return cellData("verifiedQualification", style, verifiedQualification);
        }
      },
      {
        Header: "Assigned Badges",
        accessor: "assignedBadges",
        width: 60,
        maxWidth: 60,
        Cell: ({ row: { original: participant } }: { row: { original: any } }) => {
          const style = {};
          const assignedBadges =
            auxiliaryMethods.getAssignedBadges(participant.badgeDetails) || "None";
          return cellData("assignedBadges", style, assignedBadges);
        }
      },
      {
        Header: "Topic Name",
        accessor: "topicName",
        width: 60,
        maxWidth: 60,
        Cell: ({ row: { original: participant } }: { row: { original: any } }) => {
          const style = {};
          const assignedBadges = auxiliaryMethods.getTopics(participant.topicData) || "None";
          return cellData("topicName", style, assignedBadges);
        }
      }
    ],
    []
  );

  return (
    <View>
      {loading ? (
        <Loader />
      ) : failed ? (
        <View>Failed to load data</View>
      ) : (trainerByTopicData && trainerByTopicData.length) > 0 ? (
        <View>
          <Table
            columns={columns}
            data={trainerByTopicData}
            download={
              <TouchableOpacity onPress={() => downloadCsvList()}>
                <View style={{ flexDirection: "row" }}>
                  <Icon
                    name={IconNames.downloadList}
                    customStyle={styles.iconStyle}
                    onPress={() => downloadCsvList()}
                  />
                  <View>
                    <Text
                      testId="testId"
                      fontWeight={FontWeight.Bold}
                      textSize={TextSize.Small}
                      style={[
                        styles.textAlignment,
                        {
                          fontFamily: FontFamily.Regular,
                          fontSize: 12
                        }
                      ]}
                    >
                      {"Download"}
                    </Text>
                  </View>
                </View>
              </TouchableOpacity>
            }
          />
          {viewButtonDetails && (
            <View>
              <SocionModal
                modalVisible={viewButtonDetails}
                setModalVisible={() => setViewButtonDetails(!viewButtonDetails)}
                component={
                  <ViewUserDetails
                    onClose={() => {
                      setViewButtonDetails(false);
                    }}
                    selectedUserDetails={selectedUserDetails}
                    piiFilter={false}
                  />
                }
              />
            </View>
          )}
        </View>
      ) : (
        <>
          <Loader />
        </>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  sessionName: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center"
  },
  image: {
    width: 25,
    height: 25,
    borderRadius: 25 / 2,
    marginEnd: 10
  },
  status: {
    height: 8,
    width: 8,
    borderRadius: 50,
    marginRight: 8
  },
  active: {
    backgroundColor: colorPallete.cordioGreen
  },
  pending: {
    backgroundColor: colorPallete.cordioOrange
  },
  deactivated: {
    backgroundColor: colorPallete.cordioRedDark3
  },
  textAlign: {
    fontFamily: FontFamily.Regular,
    color: colorPallete.textBlack,
    fontSize: 10
  },
  statusContainer: {
    flexDirection: "row",
    alignItems: "center"
  },
  statusColor: {
    height: 8,
    width: 8,
    borderRadius: 50,
    marginRight: 8
  },
  approvedColor: {
    backgroundColor: colorPallete.cordioGreen
  },
  pendingColor: {
    backgroundColor: colorPallete.cordioOrange
  },
  declineColor: {
    backgroundColor: colorPallete.cordioRedDark3
  },
  iconStyle: {
    color: colorPallete.textBlack,
    fontSize: 17,
    marginRight: 10
  },
  textAlignment: {
    textDecorationLine: "underline"
  }
});
