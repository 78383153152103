import { deserialize, serialize } from "@socion-cordio/common/src/mappers/apiResMapper";
// import { Topic } from "@socion-cordio/common/src/models/topic";
import { ApiClient } from "@socion-cordio/common/src/network/apiClient";
import { qualificationPackEndPoints } from "@socion-cordio/common/src/repositories/endPoints";
import { ViewBundle } from "@socion-cordio/common/src/models/qualificationPacks";

class QualificationPackRepository {
  getBundle = async (bundleId: number): Promise<ViewBundle> => {
    let data = await ApiClient.get(
      qualificationPackEndPoints.getQualificationPackBundleDetails(bundleId),
      {}
    );
    // const topicsData: Topic = deserialize(Topic, data);
    return data;
  };
}
const qualificationPackRepository = new QualificationPackRepository();
export { qualificationPackRepository as QualificationPackRepository };
