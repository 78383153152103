import { IFluxStandardAction } from "@socion-cordio/common/src/store/interfaces";
import { ViewSession } from "@socion-cordio/common/src/models/viewSession";
import { SESSION } from "@socion-cordio/common/src/modules/session/actions/actionTypes";

const getCompleteSession = (data: {
  sessionId: number;
}): IFluxStandardAction<{ sessionId: number }> => {
  return {
    type: SESSION.GET_COMPLETE_SESSION.LOADING,
    payload: data
  };
};

const getCompleteSessionSuccess = (data: ViewSession): IFluxStandardAction<ViewSession> => {
  // data.response.forEach((topic: any, index: number) => {
  //   topic.serialNo = `${index + 1}.`;
  // });
  return {
    type: SESSION.GET_COMPLETE_SESSION.SUCCESS,
    payload: data
  };
};

const getCompleteSessionError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: SESSION.GET_COMPLETE_SESSION.ERROR,
    payload: error
  };
};

export const SessionsActions = {
  getCompleteSession,
  getCompleteSessionSuccess,
  getCompleteSessionError
};
