import styled from "styled-components";

type ScrollProps = {
  height?: string;
  scrollable?: boolean;
};

export const ScrollContainer = styled.div<ScrollProps>`
  overflow-y: ${(props) => (props.scrollable ? "scroll" : "unset")};
  max-height: ${(props) => (props.height ? props.height : "300px")};
  overflow-x: hidden;
  /* margin-left: 5px; */
  &::-webkit-scrollbar {
    width: 5px;
  }
  /* Track */
  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
  }
  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: grey;
    border-radius: 10px;
  }
`;
