import React, { useEffect, useMemo, useState, Fragment } from "react";
import { View, StyleSheet, TouchableOpacity } from "react-native";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";
import Table from "@socion-cordio/web/src/components/molecules/table/table";
import {
  Text,
  TextSize,
  FontWeight,
  FontFamily
} from "@socion-cordio/common/src/components/atoms/text";
import { colorPallete } from "@socion-cordio/common/src/assets/styles/colors";
import { dataHelper } from "@socion-cordio/common/src/utils/dataHelper";
import Loader from "@socion-cordio/common/src/components/atoms/loader";
import { Image } from "@socion-cordio/common/src/components/atoms/image";
import SocionModal from "@socion-cordio/common/src/components/atoms/modal";
import {
  compact,
  findIndex,
  get,
  isEmpty,
  omit,
  orderBy,
  pick,
  replace,
  sortBy,
  startCase
} from "lodash";
import { allRoutesNames as routeNames } from "navigation/allRouteNames";
import _ from "lodash";
import { getSubmitter } from "utils/artefactUserHelper";
import {
  canAccessAll,
  canDelete,
  canOnlyView,
  canReview,
  canUpdate,
  isVerifier
} from "utils/artefactActionsHelper";
import { ArtefactRepository } from "@socion-cordio/common/src/repositories/artefact/artefact";
import CancelButton from "@socion-cordio/common/src/components/atoms/cancelButton";
import { LocalStorage } from "@socion-cordio/common/src/services/storage/storageService";
import ArtefactFiltersModal from "./artefactFiltersModal";
import SearchFilterSeondary from "@socion-cordio/common/src/components/molecules/searchFilterSecondary";
import { getDeviceBaseLocation } from "../helpers/telemetryHelper";
import ConfirmModal from "@socion-cordio/common/src/components/organisms/confirmModal";
import {
  delArtefact,
  deleteArtefactsToBeDeletedinLocal,
  getArtefactFilters,
  isAnyFilterApplied,
  isEmptySelectedFilter,
  isListBtnSelectionNotEmpty,
  isNotSelected,
  removeFilteredLocationList
} from "../helpers/artefactHelper";
import { FiltersData } from "../data/configData";
import { toast } from "react-toastify";
import { artefact_messages } from "../data/message";
import Tooltip from "react-tooltip-lite";
import { Link } from "react-router-dom";

enum STATUS {
  submitted = "SUBMITTED",
  approved = "APPROVED",
  rejected = "REJECTED",
  inprogress = "REVIEW_INPROGRESS"
}

const ArtefactStatus = [
  {
    name: "Submitted",
    isSelected: false
  },
  {
    name: "Approved",
    isSelected: false
  },
  {
    name: "Rejected",
    isSelected: false
  },
  {
    name: "Review in progress",
    isSelected: false
  }
];

const ArtefactList = (props: any) => {
  const programData: any = JSON.parse(localStorage.getItem("programDetails"));
  const user = JSON.parse(localStorage.getItem("user"));

  const [updatedArtefactsList, setUpdatedArtefactsList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [showFilterModal, setShowFilterModal] = useState(null);
  const [filters, setFilters] = useState(null);
  const location = useLocation();
  const [leftSidePanelCollapse, setLeftSidePanelCollapse] = useState(null);

  const [filtersListData, setFiltersListData] = useState(FiltersData.filtersListData);

  const [expandedList, setExpandedList] = useState([]);
  const [checkedList, setCheckedList] = useState([]);
  const [globalList, setGlobalList] = useState([]);

  const [selectedFilter, setSelectedFilter] = useState(FiltersData.selectedFilter);

  const [selectedFilterListBtn, setSelectedFilterListBtn] = useState(
    FiltersData.selectedFilterListBtn
  );

  const [filterApplied, setFilterApplied] = useState(FiltersData.filterApplied);

  const [notSelected, setNotSelected] = useState(FiltersData.notSelected);

  const [resultTypes, setResultTypes] = useState([]);
  const [allExpandedNodes, setAllExpandedNodes] = useState([]);
  const [updatedFinalLocation, setUpdatedFinalLocation] = useState({});
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [selectedArtefact, setSelectedArtefact] = useState(null);
  const [filteredCount, setFilteredCount] = useState(null);
  const [isSearched, setIsSearched] = useState(false);
  const [totalCount, setTotalCount] = useState(null);
  const [artefactList, setArtefactList] = useState(null);

  const {
    artefactList: artefacts,
    loading: { getArtefacts: loading },
    error: { getArtefacts: artefactError }
  } = useSelector((state: any) => state.artefacts);

  const history: any = useHistory();

  useEffect(() => {
    var query: any = [];
    if (location?.search.includes("?token=")) {
      query = location?.search.split("?token=");
    } else {
      query = location?.search.split("&token=");
    }
    getDeviceBaseLocation();
    deleteArtefactsToBeDeletedinLocal();
  }, []);

  useEffect(() => {
    if (!isEmpty(filters)) {
      setIsSearched(false);
      getFilterData();
      clearAllFilter(false);
    }
  }, [filters]);

  useEffect(() => {
    if (notSelected.location === true) {
      setSelectedFilterListBtn({ ...selectedFilterListBtn, location: [] });
    }

    if (notSelected.type === true) {
      setSelectedFilterListBtn({ ...selectedFilterListBtn, type: [] });
    }

    if (notSelected.submittedDate === true) {
      setSelectedFilterListBtn({ ...selectedFilterListBtn, submittedDate: [] });
    }

    if (notSelected.uploadedDate === true) {
      setSelectedFilterListBtn({ ...selectedFilterListBtn, uploadedDate: [] });
    }
    if (notSelected.status === true) {
      setSelectedFilterListBtn({ ...selectedFilterListBtn, status: [] });
    }
  }, [notSelected]);

  useEffect(() => {
    if (artefactError !== null) {
      setIsLoading(false);
      setUpdatedArtefactsList([]);
    }
  }, [artefactError]);

  useEffect(() => {
    if (loading !== null) {
      setIsLoading(loading);
    }
  }, [loading]);

  useEffect(() => {
    dispatchFiltersApiHandler(programData?.program?.id);
    setIsLoading(true);
    artefactListHandler(artefacts);
  }, [artefacts]);

  const artefactListHandler = (artefacts: any) => {
    if (artefacts !== null) {
      let sortedList = artefacts ? sortArtefactListHandler(artefacts?.data) : [];
      sortedList = orderBy(sortedList, ["created_at", "displayUploadedDate"], ["desc", "desc"]);
      setUpdatedArtefactsList(sortedList);
      setArtefactList(sortedList);
      setTotalCount(sortedList.length);
      setIsLoading(false);
    }
    if (isEmpty(programData)) {
      setIsLoading(false);
    }
  };

  const leftNavCollapseHandler = async () => {
    let isSidePanelCollapsed: boolean = await LocalStorage.getStorage("isSidePanelCollapsed");
    setLeftSidePanelCollapse(isSidePanelCollapsed);
  };

  useEffect(() => {
    leftNavCollapseHandler();
  });

  const dispatchFiltersApiHandler = async (pid: any) => {
    setIsLoading(true);
    // if (pid) {
    const filterData: any = await ArtefactRepository.getArtefactTypes({ pid });
    filterData.data.status = [...ArtefactStatus];
    setFilters(filterData?.data);
    // }
  };

  const sortArtefactListHandler = (list: any[]) => {
    list = orderBy(list, ["created_at", "displayUploadedDate"], ["desc", "desc"]);
    let artefacts: any[] = list;
    artefacts.forEach((artefact: any, index: number) => {
      artefact.key = artefact?.artefact_meta_id;
      artefact.serialNo = `${index + 1}.`;
      artefact.displayArtefactType = artefact.artefact_type_title;
      artefact.displayArtefactName = artefact.artefact_name;
      artefact.displayUploadedBy = artefact.created_by ? artefact.created_by : "NA";
      artefact.displayUploadedDate = artefact.created_at
        ? dataHelper.formatDate(artefact.created_at, "DD MMM YYYY")
        : "NA";
      const submitterName = getSubmitter(artefact.submitter_userid);
      artefact.displaySubmitterName = submitterName ? submitterName : "NA";
      artefact.displaySubmittedDate = artefact.submitted_date
        ? dataHelper.formatDateUtc(artefact.submitted_date)
        : artefact.created_at
        ? dataHelper.formatDateUtc(artefact.created_at)
        : "NA";

      // artefact.displaySubmittedDate =
      //   artefact.displaySubmittedDate.toLowerCase() === "invalid date"
      //     ? dataHelper.formatDateUtc(artefact.created_at)
      //     : artefact.displaySubmittedDate;

      artefact.displayReviewer = artefact.approved_by ? artefact.approved_by : "NA";
      artefact.displayReviewDate = artefact.approved_date
        ? dataHelper.formatDateUtc(artefact.approved_date)
        : "NA";
      let status = artefact?.status ? artefact?.status : "";
      artefact.displayStatus =
        status === STATUS.inprogress
          ? startCase("REVIEW IN PROGRESS".toLowerCase())
          : startCase(status.toLowerCase());
    });
    setIsLoading(false);
    artefacts = orderBy(list, ["created_at", "displayUploadedDate"], ["desc", "desc"]);
    return artefacts;
  };

  const cellData = (testId: string, style: any, data: string, onPress?: Function) => (
    <Text
      fontWeight={FontWeight.Bold}
      testId={testId}
      textSize={TextSize.Small}
      style={[
        {
          fontSize: 12,
          fontFamily: FontFamily.Regular,
          fontWeight: FontFamily.Light,
          textSize: TextSize.Small,
          color: colorPallete.textBlack
        },
        style
      ]}
      onPress={onPress ? () => onPress() : null}
    >
      {data}
    </Text>
  );

  const filterListToTrue = (list: any) => {
    return list.filter((x: any) => x.isSelected === true);
  };

  const clearLocation = () => {
    const locationList = [...filtersListData.location];
    const locList: any[] = locationList.map((element: any) => {
      element.isSelected = false;
      return element;
    });
    setExpandedList([]);
    setCheckedList([]);
    LocalStorage.setStorage("selectedLocation", {
      country: [],
      state: [],
      district: [],
      sub_district: [],
      city: []
    });
    LocalStorage.removeStorage("noneLocationListSet");

    return locList;
  };

  const clearTypes = (type: string) => {
    const typesList = type === "type" ? [...filtersListData.type] : [...filtersListData.status];
    const typeList: any[] = typesList.map((element: any) => {
      element.isSelected = false;
      return element;
    });
    type === "type"
      ? LocalStorage.removeStorage("noTypesSelected")
      : LocalStorage.removeStorage("noStatusSelected");
    return typeList;
  };

  const clearAllFilter = (isModalClosed: boolean) => {
    isModalClosed ? setIsLoading(true) : null;

    const locList = clearLocation();

    const typeList = clearTypes("type");
    const statusList = clearTypes("status");

    setFiltersListData({
      ...filtersListData,
      location: locList,
      type: typeList,
      status: statusList
    });

    setSelectedFilter({ ...FiltersData.selectedFilter });

    setSelectedFilterListBtn({ ...FiltersData.selectedFilterListBtn });

    setFilterApplied({ ...FiltersData.filterApplied });

    setNotSelected({ ...FiltersData.notSelected });
    LocalStorage.removeStorage("noSubDateSelected");
    LocalStorage.removeStorage("noUploadDateSelected");

    dispatchArtefactsApiHandler({ pid: programData?.program?.id });

    getFilterData();
  };

  const dispatchArtefactsApiHandler = async (request: any) => {
    const payload: any = {
      ...request
    };
    if (!canAccessAll() && !canOnlyView() && !isVerifier()) {
      payload.userId = user?.userid || user?.userId;
    }

    // if (request?.pid) {
    const res: any = await ArtefactRepository.getArtefacts(payload).catch((err) => {
      console.log("Error while fetching artefacts list", err);
      toast.error(artefact_messages.error.m012);
    });
    artefactListHandler(res);
    // }
  };

  const dataFilteredHandler = async (data: any) => {
    if (data.isFiltered === true) {
      data.isNoneLocationSet === null &&
      data.isNoTypesSet === null &&
      data.isNoSubDateSet === null &&
      data.isNoUploadDateSet === null &&
      data.isNoStatusSet === null
        ? [setFilterApplied({ ...FiltersData.filterApplied })]
        : null;

      const locList = [...filtersListData.location];
      let newList = filterListToTrue(locList);

      const typeList = [...filtersListData.type];
      let newTypeList = filterListToTrue(typeList);

      const statusList = [...filtersListData.status];
      let newStatusList = filterListToTrue(statusList);

      let subDate = "";

      if (!!selectedFilter.submittedDate.fromdate) {
        if (!!selectedFilter.submittedDate.todate) {
          subDate =
            "Submitted :" +
            selectedFilter.submittedDate.fromdate +
            " to " +
            selectedFilter.submittedDate.todate;
        } else {
          subDate = "Submitted :" + selectedFilter.submittedDate.fromdate;
        }
      } else if (!!selectedFilter.submittedDate.todate) {
        subDate = "Submitted :" + selectedFilter.submittedDate.todate;
      }

      let uploadDate = "";

      if (!!selectedFilter.uploadedDate.uploadedFromDate) {
        if (!!selectedFilter.uploadedDate.uploadedToDate) {
          uploadDate =
            "UploadDate :" +
            selectedFilter.uploadedDate.uploadedFromDate +
            " to " +
            selectedFilter.uploadedDate.uploadedToDate;
        } else {
          uploadDate = "UploadDate :" + selectedFilter.uploadedDate.uploadedFromDate;
        }
      } else if (!!selectedFilter.uploadedDate.uploadedToDate) {
        uploadDate = "UploadDate :" + selectedFilter.uploadedDate.uploadedToDate;
      }

      setFiltersListData({ ...filtersListData, locationSecondary: locList });

      const ids = newTypeList.map((x: any) => x.id);
      let status = [...selectedFilter.status];
      status = status.map((s: any) => (s.isSelected ? s.name.toUpperCase() : null));
      status = compact(status);

      setSelectedFilterListBtn({
        ...selectedFilterListBtn,
        location: globalList,
        type: newTypeList,
        submittedDate: subDate ? [subDate] : [],
        uploadedDate: uploadDate ? [uploadDate] : [],
        status: newStatusList
      });

      setNotSelected({
        location: data.isNoneLocationSet === null ? false : true,
        type: data.isNoTypeSet === null ? false : true,
        submittedDate: data.isNoSubDateSet === null ? false : true,
        uploadedDate: data.isNoUploadDateSet === null ? false : true,
        status: data.isNoStatusSet === null ? false : true
      });

      filteredDataHandler({
        locations: newList,
        types: ids,
        submittedDate: selectedFilter.submittedDate,
        uploadedDate: selectedFilter.uploadedDate,
        status: status
      });
      setIsLoading(true);
    }
  };

  const filteredDataHandler = async (data: any) => {
    const location: any = await LocalStorage.getStorage("selectedLocation");
    console.log("cbzxnbcxnzvbmxz", data.status);
    let status = data.status || [];
    if (status.length > 0 && data.status.includes("REVIEW IN PROGRESS")) {
      status = status.filter((s: any) => s !== "REVIEW IN PROGRESS");
      status.push("REVIEW_INPROGRESS");
    }

    const request = {
      ...location,
      pid: programData?.program?.id,
      ...data?.submittedDate,
      ...data?.uploadedDate,
      artefact_type_id: data?.types,
      status: status.length > 0 ? status : ""
    };
    dispatchArtefactsApiHandler(request);
  };

  const removeFilteredListButton = async (data: any, value: string) => {
    if (value === "type" || value === "status") {
      data.isSelected = !data.isSelected;
    }
    let resultType = filterListToTrue([...selectedFilterListBtn.type]);
    let statusList = filterListToTrue([...selectedFilterListBtn.status]);
    let finalStatusList = statusList.map((s: any) => s.name.toUpperCase());

    let resultFinalTypes = resultType.map((a: any) => a.id);

    if (value === "location") {
      const removedData = await removeFilteredLocationList(
        data,
        { ...filtersListData },
        checkedList,
        { ...selectedFilterListBtn }
      );

      setCheckedList([...removedData.checkedList]);
      setSelectedFilterListBtn({ ...removedData.selectedFilterListBtn });
      setUpdatedFinalLocation({ ...updatedFinalLocation, ...removedData.finalLocation });
      filteredDataHandler({
        locations: removedData.finalLocation,
        types: removedData.typeList,
        submittedDate: selectedFilter.submittedDate,
        uploadedDate: selectedFilter.uploadedDate,
        status: finalStatusList
      });
    }

    if (value === "type") {
      // Updating only when a type is removed from the filter list.
      let newListBtn = selectedFilterListBtn.type.filter(
        (x: any) => data.id !== x.id && x.isSelected === true
      );
      setSelectedFilterListBtn({ ...selectedFilterListBtn, type: newListBtn });
      resultType = newListBtn.map((a: any) => a.id);
      resultFinalTypes = resultType;
      setResultTypes(resultType);
      filteredDataHandler({
        ...updatedFinalLocation,
        types: resultFinalTypes,
        submittedDate: selectedFilter.submittedDate,
        uploadedDate: selectedFilter.uploadedDate,
        status: finalStatusList
      });
    }

    if (value === "submittedDate") {
      setSelectedFilterListBtn({ ...selectedFilterListBtn, submittedDate: [] });
      setSelectedFilter({
        ...selectedFilter,
        submittedDate: { fromdate: null, todate: null }
      });
      filteredDataHandler({
        ...updatedFinalLocation,
        types: resultFinalTypes,
        submittedDate: { fromdate: null, todate: null },
        uploadedDate: selectedFilter.uploadedDate,
        status: finalStatusList
      });
    }

    if (value === "uploadedDate") {
      // Updating only when a type is removed from the filter list.
      setSelectedFilterListBtn({ ...selectedFilterListBtn, uploadedDate: [] });

      setSelectedFilter({
        ...selectedFilter,
        uploadedDate: { uploadedFromDate: null, uploadedToDate: null }
      });
      filteredDataHandler({
        ...updatedFinalLocation,
        types: resultFinalTypes,
        submittedDate: selectedFilter.submittedDate,
        uploadedDate: { uploadedFromDate: null, uploadedToDate: null },
        status: finalStatusList
      });
    }

    if (value === "status") {
      let newStatusListBtn = selectedFilterListBtn.status.filter(
        (x: any) => data.name.toLowerCase() !== x.name.toLowerCase() && x.isSelected === true
      );

      setSelectedFilterListBtn({ ...selectedFilterListBtn, status: newStatusListBtn });

      filteredDataHandler({
        ...updatedFinalLocation,
        types: resultFinalTypes,
        submittedDate: selectedFilter.submittedDate,
        uploadedDate: selectedFilter.uploadedDate,
        status: finalStatusList
      });
    }
  };

  const updateFilterApplied = (value: string) => {
    switch (value) {
      case "location":
        setFilterApplied({ ...filterApplied, location: true });
        break;
      case "type":
        setFilterApplied({ ...filterApplied, type: true });
        break;
      case "submitteddate":
        setFilterApplied({ ...filterApplied, submittedDate: true });
        break;
      case "uploadeddate":
        setFilterApplied({ ...filterApplied, uploadedDate: true });
        break;
      case "status":
        setFilterApplied({ ...filterApplied, status: true });
        break;
      case "all":
        setFilterApplied({
          ...filterApplied,
          location: true,
          type: true,
          submittedDate: true,
          uploadedDate: true,
          status: true
        });
        break;
    }
  };

  const updateSelectedFilters = (type: string, data: any) => {
    switch (type) {
      case "location":
        const locNewList = data.filter((x: any) => x.isSelected === true);
        const locResult = locationDataConvert(locNewList);
        setSelectedFilter({ ...selectedFilter, location: locNewList });
        break;
      case "type":
        let typeNewList = data.filter((x: any) => x.isSelected === true);
        let result = typeNewList.map((a: any) => a.id);
        setSelectedFilter({ ...selectedFilter, type: typeNewList });
        break;
      case "submitteddate":
        setSelectedFilter({
          ...selectedFilter,
          submittedDate: { ...selectedFilter.submittedDate, ...data }
        });
        break;
      case "uploadeddate":
        setSelectedFilter({
          ...selectedFilter,
          uploadedDate: { ...selectedFilter.uploadedDate, ...data }
        });
        break;
      case "status":
        let statusNewList = data.filter((x: any) => x.isSelected === true);
        let statusResult = statusNewList.map((a: any) => a.name);
        setSelectedFilter({ ...selectedFilter, status: statusNewList });
        break;
    }
  };

  const locationDataConvert = (newList: any) => {
    return newList.map(({ city, subDistrict, district, state, country }: any) => ({
      city,
      subDistrict,
      district,
      state,
      country
    }));
  };

  const openFilterHandler = () => {
    setShowFilterModal(true);
    setIsSearched(false);
    if (isEmptySelectedFilter(selectedFilter)) {
      getFilterData();
    } else {
      setFiltersListData({
        ...filtersListData,
        location: [...filtersListData.location],
        type: [...filtersListData.type],
        status: [...filtersListData.status]
      });
    }
  };

  const getFilterData = () => {
    const data = getArtefactFilters(filters);
    setFiltersListData({
      ...filtersListData,
      location: data?.location,
      type: data?.types,
      status: data?.status
    });
  };

  const deleteArtefact = async () => {
    setIsLoading(true);
    const Id = await delArtefact(selectedArtefact);
    let tempArray = [...updatedArtefactsList];
    const index = findIndex(tempArray, { artefact_meta_id: selectedArtefact?.artefact_meta_id });
    tempArray.splice(index, 1);
    toast.success(
      replace(
        artefact_messages.success.m03,
        "{name}",
        `"${get(selectedArtefact, "artefact_name")}"`
      )
    );
    let list = sortArtefactListHandler(tempArray);
    list = orderBy(list, ["created_at", "displayUploadedDate"], ["desc", "desc"]);
    setTotalCount(list.length);
    setArtefactList(list);
    setUpdatedArtefactsList(list);
  };

  const getTooltip = (text: any, content: any) => {
    return (
      <View>
        <Tooltip
          styles={{ zIndex: 99 }}
          tipContentClassName=""
          distance={10}
          direction="down"
          background={colorPallete.cordioBeigeLight1}
          color="black"
          padding={10}
          // useDefaultStyles
          content={text}
        >
          {content}
          {/* <Icon testID="down" name={IconNames.email} customStyle={styles.messageIcon} /> */}
        </Tooltip>
      </View>
    );
  };

  const artefactColumns = [
    {
      Header: "Sr.No",
      accessor: "serialNo",
      width: 60,
      maxWidth: 60,
      disableSortBy: true,
      Cell: ({ row: { original: artefact } }: { row: { original: any } }) => {
        const style = {
          fontFamily: FontFamily.Regular,
          fontWeight: FontFamily.Light
        };
        return cellData("serialNo", style, artefact.serialNo);
      }
    },
    {
      Header: "Artefact Type",
      accessor: "displayArtefactType",
      width: 120,
      maxWidth: 120,
      Cell: ({ row: { original: artefact } }: { row: { original: any } }) => {
        const style = {
          fontFamily: FontFamily.Regular,
          fontWeight: FontFamily.Light
        };
        return cellData("displayArtefactType", style, artefact.displayArtefactType);
      }
    },
    {
      Header: "Artefact name",
      accessor: "displayArtefactName",
      width: 150,
      maxWidth: 150,
      Cell: ({ row: { original: artefact } }: { row: { original: any } }) => {
        const style = {
          fontFamily: FontFamily.Medium,
          fontWeight: FontFamily.Regular,
          textDecorationLine: "underline"
        };
        return (
          <TouchableOpacity
            onPress={() => {
              history.push({
                pathname: `${routeNames.app}${routeNames.VIEW_ARTEFACT}/${artefact.artefact_meta_id}`,
                search: `?query=${artefact.artefact_meta_id}`,
                state: { id: artefact.artefact_meta_id }
              });
            }}
          >
            {cellData("displayArtefactName", style, artefact.displayArtefactName)}
          </TouchableOpacity>
        );
      }
    },
    {
      Header: "Location",
      accessor: "location",
      width: 160,
      maxWidth: 160,
      Cell: ({ row: { original: artefact } }: { row: { original: any } }) => {
        const style = {
          fontFamily: FontFamily.Regular,
          fontWeight: FontFamily.Light
        };

        const location =
          artefact?.village ||
          artefact?.city ||
          artefact?.sub_district ||
          artefact?.subDistrict ||
          artefact?.subdistrict ||
          artefact?.district ||
          artefact?.state ||
          artefact?.country;

        return getTooltip(artefact?.location, cellData("location", style, location));
      }
    },
    {
      Header: "Uploaded By",
      accessor: "displayUploadedBy",
      width: 150,
      maxWidth: 150,
      Cell: ({ row: { original: artefact } }: { row: { original: any } }) => {
        const style = {
          fontFamily: FontFamily.Regular,
          fontWeight: FontFamily.Light
        };
        return cellData("displayUploadedBy", style, artefact.displayUploadedBy);
      }
    },
    {
      Header: "Uploaded Date",
      accessor: "displayUploadedDate",
      width: 100,
      maxWidth: 100,
      Cell: ({ row: { original: artefact } }: { row: { original: any } }) => {
        const style = {
          fontFamily: FontFamily.Regular,
          fontWeight: FontFamily.Light
        };
        return cellData("displayUploadedDate", style, artefact.displayUploadedDate);
      }
    },
    {
      Header: "Submitter Name",
      accessor: "displaySubmitterName",
      width: 150,
      maxWidth: 150,
      Cell: ({ row: { original: artefact } }: { row: { original: any } }) => {
        const style = {
          fontFamily: FontFamily.Regular,
          fontWeight: FontFamily.Light
        };
        return cellData("displaySubmitterName", style, artefact.displaySubmitterName);
      }
    },
    {
      Header: "Submitted Date",
      accessor: "displaySubmittedDate",
      width: 100,
      maxWidth: 100,
      Cell: ({ row: { original: artefact } }: { row: { original: any } }) => {
        const style = {
          fontFamily: FontFamily.Regular,
          fontWeight: FontFamily.Light
        };
        return cellData("displaySubmittedDate", style, artefact.displaySubmittedDate);
      }
    },
    {
      Header: "Reviewer",
      accessor: "displayReviewer",
      width: 100,
      maxWidth: 100,
      Cell: ({ row: { original: artefact } }: { row: { original: any } }) => {
        const style = {
          fontFamily: FontFamily.Regular,
          fontWeight: FontFamily.Light
        };
        return cellData("displayReviewer", style, artefact.displayReviewer);
      }
    },
    {
      Header: "Review Date",
      accessor: "displayReviewDate",
      width: 100,
      maxWidth: 100,
      Cell: ({ row: { original: artefact } }: { row: { original: any } }) => {
        const style = {
          fontFamily: FontFamily.Regular,
          fontWeight: FontFamily.Light
        };
        return cellData("displayReviewDate", style, artefact.displayReviewDate);
      }
    },
    {
      Header: "Status",
      accessor: "displayStatus",
      width: 120,
      maxWidth: 120,
      Cell: ({ row: { original: artefact } }: { row: { original: any } }) => {
        const style = {
          fontFamily: FontFamily.Regular,
          fontWeight: FontFamily.Light
        };
        return (
          <Text
            fontWeight={FontWeight.Light}
            testId="status"
            textSize={TextSize.Small}
            style={{
              fontSize: 12,
              fontFamily: FontFamily.Regular,
              color: colorPallete.textBlack
            }}
          >
            <View
              style={[
                styles.status,

                artefact.status === STATUS.approved
                  ? styles.active
                  : artefact.status === STATUS.rejected
                  ? styles.inActive
                  : artefact.status === STATUS.submitted
                  ? styles.upcoming
                  : artefact.status === STATUS.inprogress
                  ? styles.inReview
                  : null
              ]}
            />
            {artefact.displayStatus}
          </Text>
        );
      }
    },
    {
      Header: "Actions",
      accessor: "actions",
      width: 100,
      maxWidth: 100,
      disableSortBy: true,
      Cell: ({ row: { original: artefact } }: { row: { original: any } }) => {
        const style = {
          fontFamily: FontFamily.Regular,
          fontWeight: FontFamily.Light
        };

        const canDeleteArte =
          artefact.status.toLowerCase() === STATUS.inprogress.toLowerCase() ||
          artefact.status.toLowerCase() === STATUS.approved.toLowerCase();

        const isAllowedToEdit =
          artefact.status.toLowerCase() === STATUS.inprogress.toLowerCase()
            ? false
            : canUpdate(artefact);
        const isAllowedToDelete = canDeleteArte ? false : canDelete(artefact);
        const isAllowedToReview =
          artefact.status.toLowerCase() !== STATUS.approved.toLowerCase() &&
          artefact.status.toLowerCase() !== STATUS.rejected.toLowerCase() &&
          canReview(artefact);
        return (
          <View>
            <View style={styles.iconContainer}>
              {isAllowedToReview &&
                getTooltip(
                  "Review",
                  <Link
                    to={{
                      pathname: `${routeNames.app}${routeNames.REVIEW_ARTEFACT}/${artefact.artefact_meta_id}`,
                      search: `?query=${artefact.artefact_meta_id}`,
                      state: { id: artefact.artefact_meta_id }
                    }}
                    target="_blank"
                  >
                    <Image
                      testId="edit"
                      source={require("@socion-cordio/common/src/assets/images/review_in_progress.svg")}
                      imageStyle={{
                        width: 14,
                        height: 14
                      }}
                    />
                  </Link>
                )}
              {isAllowedToEdit &&
                getTooltip(
                  "Edit",
                  <TouchableOpacity
                    onPress={() => {
                      history.push({
                        pathname: `${routeNames.app}${routeNames.EDIT_ARTEFACT}/${artefact.artefact_meta_id}`,
                        search: `?query=${artefact.artefact_meta_id}`,
                        state: { id: artefact.artefact_meta_id }
                      });
                    }}
                  >
                    <Image
                      testId="edit"
                      source={require("@socion-cordio/common/src/assets/images/updateTask.svg")}
                      imageStyle={{
                        width: 14,
                        height: 14
                      }}
                    />
                  </TouchableOpacity>
                )}
              {isAllowedToDelete &&
                getTooltip(
                  "Delete",
                  <TouchableOpacity
                    onPress={() => {
                      setSelectedArtefact(artefact);
                      setShowConfirmModal(true);
                    }}
                  >
                    <Image
                      testId="delete"
                      source={require("@socion-cordio/common/src/assets/images/remove.svg")}
                      imageStyle={{
                        width: 14,
                        height: 14
                      }}
                    />
                  </TouchableOpacity>
                )}
            </View>
          </View>
        );
      }
    }
  ];

  const showDeleteModal = () => {
    return (
      <SocionModal
        modalVisible={showConfirmModal}
        setModalVisible={() => setShowConfirmModal(!showConfirmModal)}
        component={
          <ConfirmModal
            onClose={() => setShowConfirmModal(false)}
            onConfirm={async () => {
              setShowConfirmModal(false);
              deleteArtefact();
            }}
            msg={selectedArtefact?.artefact_name + artefact_messages.info.m01}
          ></ConfirmModal>
        }
      ></SocionModal>
    );
  };

  const filterArtefacts = () => {
    return (
      <>
        {showFilterModal && (
          <View>
            <SocionModal
              modalVisible={showFilterModal}
              setModalVisible={() => setShowFilterModal(!showFilterModal)}
              component={
                <ArtefactFiltersModal
                  modalVisible={showFilterModal}
                  locationList={filtersListData?.location}
                  typesList={filtersListData?.type}
                  statusList={filtersListData.status}
                  programDetails={programData}
                  dataFilteredHandler={dataFilteredHandler}
                  getSelectedFilters={(type: string, data: any) => {
                    updateSelectedFilters(type, data);
                  }}
                  selectedFilters={selectedFilter}
                  setModalVisible={() => setShowFilterModal(!showFilterModal)}
                  setExpandedList={setExpandedList}
                  expandedList={expandedList}
                  setCheckedList={setCheckedList}
                  checkedList={checkedList}
                  clearAllFilter={clearAllFilter}
                  setIsFilterApplied={(value: string) => {
                    updateFilterApplied(value);
                  }}
                  closeModalHandler={() => {}}
                  setGlobalList={setGlobalList}
                  globalList={globalList}
                  setAllExpandedNodes={setAllExpandedNodes}
                  allExpandedNodes={allExpandedNodes}
                />
              }
            />
          </View>
        )}
      </>
    );
  };

  const getCancelButton = (type: any, key: any, prop: any, textValue: any) => {
    return (
      <View key={key}>
        <CancelButton
          data={prop}
          functionOfButtonProp={type}
          functionOfButton={() => removeFilteredListButton(prop, type)}
          textValue={textValue}
        />
      </View>
    );
  };

  const filterButtonsList = () => {
    return (
      <>
        {isListBtnSelectionNotEmpty(selectedFilterListBtn) && (
          <View style={styles.filteredButtonsContainer}>
            {selectedFilterListBtn.location.length > 0 &&
              selectedFilterListBtn.location?.map((prop, key) => {
                return getCancelButton("location", key, prop, prop);
              })}
            {selectedFilterListBtn.type.length > 0 &&
              selectedFilterListBtn.type?.map((prop, key) => {
                return getCancelButton("type", key, prop, prop?.artefact_type_title);
              })}

            {selectedFilterListBtn.submittedDate.length > 0 &&
              selectedFilterListBtn.submittedDate?.map((prop, key) => {
                return getCancelButton("submittedDate", key, prop, prop);
              })}

            {selectedFilterListBtn.uploadedDate.length > 0 &&
              selectedFilterListBtn.uploadedDate?.map((prop, key) => {
                return getCancelButton("uploadedDate", key, prop, prop);
              })}
            {selectedFilterListBtn.status.length > 0 &&
              selectedFilterListBtn.status?.map((prop, key) => {
                return getCancelButton("status", key, prop, prop?.name);
              })}
            {isAnyFilterApplied(filterApplied) &&
              (isNotSelected(notSelected) || isListBtnSelectionNotEmpty(selectedFilterListBtn)) && (
                <TouchableOpacity onPress={() => clearAllFilter(true)}>
                  <Text
                    testId="testId"
                    fontWeight={FontWeight.Regular}
                    textSize={TextSize.Small}
                    style={[styles.clearAllButtonText]}
                  >
                    {"Clear All"}
                  </Text>
                </TouchableOpacity>
              )}
          </View>
        )}
      </>
    );
  };

  const filterList = (value: any) => {
    let str = value.toLowerCase();
    let newList: any[] = [];
    artefactList
      .filter((item: any) => {
        let submitter = getSubmitter(item.submitter_userid);
        submitter = submitter ? submitter : "";

        let reviewedBy = item.approved_by;
        reviewedBy = reviewedBy ? reviewedBy : "";

        const uploadedOn = item.created_at ? `${dataHelper.formatDate(item.created_at)}` : "";
        let reviewedOn = item.approved_date ? `${dataHelper.formatDate(item.approved_date)}` : "";

        const matched =
          item.artefact_type_title.toLowerCase().includes(str) ||
          item.artefact_name.toLowerCase().includes(str) ||
          item.location.toLowerCase().includes(str) ||
          item.created_by.toLowerCase().includes(str) ||
          item.status.toLowerCase().includes(str) ||
          uploadedOn.toLowerCase().includes(str) ||
          reviewedOn.includes(str) ||
          submitter.toLowerCase().includes(str) ||
          reviewedBy.toLowerCase().includes(str);

        if (matched) {
          return item;
        }
      })
      .map((name: any) => {
        return newList.push(name);
      });
    setTotalCount(artefactList.length);
    let sortedList = sortArtefactListHandler(newList);
    sortedList = orderBy(sortedList, ["created_at", "displayUploadedDate"], ["desc", "desc"]);
    setFilteredCount(sortedList.length || 0);
    setUpdatedArtefactsList(sortedList);
  };

  const searchbarAndAddArtefact = () => {
    return (
      <View style={styles.searchBarContainer}>
        <View style={{ display: "flex", flexDirection: "row", alignItems: "flex-end" }}>
          <View style={styles.searchBarContainer}>
            <SearchFilterSeondary
              searchBarContainerStyle={styles.searchBarContainerStyle}
              customWidthStyle={styles.customWidthStyle}
              filterList={(value: any) => {
                if (value) {
                  setIsSearched(true);
                } else {
                  setIsSearched(false);
                }
                filterList(value);
              }}
            />
          </View>

          {isSearched && !dataHelper.isEmptyArray(artefacts?.data) && (
            <Text
              fontWeight={FontWeight.Thin}
              testId="countText"
              textSize={TextSize.Small}
              textStyle={styles.countText}
            >
              {filteredCount + " of " + artefactList.length}
            </Text>
          )}

          {dataHelper.isEmptyArray(updatedArtefactsList) && artefacts?.data?.length > 0 && (
            <Text
              fontWeight={FontWeight.Bold}
              testId="totalcount"
              textSize={TextSize.Small}
              style={[styles.totalCount, { fontFamily: FontFamily.Bold }]}
            >
              {"Total: " + totalCount}
            </Text>
          )}
        </View>
      </View>
    );
  };

  return (
    <View style={styles.mainContainer}>
      <View style={styles.subContainer}>
        <View>
          {isLoading ? (
            <Loader />
          ) : (
            <View style={styles.tableSearchContainer}>
              <View
                style={
                  leftSidePanelCollapse ? styles.parentTableContainer : styles.parentTableContainer2
                }
              >
                <View style={styles.noDataContainer}>{searchbarAndAddArtefact()}</View>
                {dataHelper.isEmptyArray(updatedArtefactsList) ? (
                  <View style={styles.noData}>
                    {filterButtonsList()}
                    <Text
                      fontWeight={FontWeight.Thin}
                      testId="headerText"
                      textSize={TextSize.Small}
                      textStyle={styles.headerText}
                    >
                      {"No Artefacts found"}
                    </Text>
                  </View>
                ) : (
                  <View style={styles.tableWrapperStyle}>
                    <Table
                      totalCount={totalCount}
                      columns={artefactColumns}
                      data={updatedArtefactsList}
                      showSearchField={false}
                      showDownload={true}
                      filterIcon={
                        <View>
                          <TouchableOpacity onPress={() => openFilterHandler()}>
                            <Image
                              testId="filter"
                              source={require("@socion-cordio/common/src/assets/images/filter.svg")}
                              imageStyle={{
                                width: 28,
                                height: 28
                              }}
                            />
                          </TouchableOpacity>
                        </View>
                      }
                      filterButtons={filterButtonsList()}
                    />
                  </View>
                )}
                {showFilterModal && filterArtefacts()}
                {showConfirmModal && showDeleteModal()}
              </View>
            </View>
          )}
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  mainContainer: {
    paddingBottom: 20,
    paddingHorizontal: 20,

    backgroundColor: colorPallete.cordioBeigeLight2,
    minHeight: "calc(95vh - 50px)"
  },
  subContainer: {
    backgroundColor: colorPallete.white,
    borderRadius: 10,
    shadowColor: colorPallete.cordioRedDark1,
    shadowOpacity: 0.1,
    shadowOffset: {
      height: 10,
      width: 5
    },
    shadowRadius: 10
  },
  status: {
    height: 8,
    width: 8,
    borderRadius: 50,
    marginRight: 8
  },
  active: {
    backgroundColor: colorPallete.cordioGreen
  },
  upcoming: {
    backgroundColor: colorPallete.yellowOne
  },
  inActive: {
    backgroundColor: colorPallete.cordioRed
  },
  inReview: {
    backgroundColor: colorPallete.darkGrey
  },
  alignHeader: {
    marginBottom: 30
  },
  iconContainer: {
    display: "flex",
    flexDirection: "row",
    alignContent: "center",
    justifyContent: "space-evenly",
    fontSize: "12px"
  },
  parentTableContainer: {
    display: "flex",
    width: "calc(100vw - 100px)",
    paddingTop: 10
  },
  parentTableContainer2: {
    display: "flex",
    width: "calc(100vw - 262px)",
    paddingTop: 10
  },

  // Filter Buttons
  filteredButtonsContainer: {
    flexDirection: "row",
    alignItems: "center",
    flexWrap: "wrap",
    padding: 18,
    marginTop: 25
  },
  clearAllButtonText: {
    fontSize: 12,
    fontFamily: FontFamily.Medium,
    textDecorationLine: "underline"
  },
  tableSearchContainer: {
    // paddingVertical: 5,
    position: "relative",
    display: "flex",
    flexDirection: "column",
    alignItems: "baseline"
  },
  noData: {
    height: "calc(100vh - 150px)",
    paddingHorizontal: 20,
    marginTop: 80,
    marginBottom: -30,
    display: "flex",
    flexDirection: "column",
    alignItems: "baseline"
  },
  noDataContainer: {
    paddingStart: 20,
    position: "absolute",
    zIndex: 999
  },
  searchBarContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center"
  },
  searchBarContainerStyle: {
    borderRadius: 5,
    top: 10
  },
  customWidthStyle: {
    width: 450,
    height: 40,
    borderRadius: 5
  },
  headerText: {
    fontSize: 14,
    fontFamily: FontFamily.Medium,
    lineHeight: 19.5,
    fontWeight: "700"
  },
  countText: {
    fontSize: 14,
    fontFamily: FontFamily.Medium,
    lineHeight: 19.5,
    paddingHorizontal: 10
  },
  tableWrapperStyle: {
    marginTop: "20px"
    // marginLeft: 0
  },
  totalCount: {
    fontSize: 14,
    textAlign: "center",
    fontFamily: FontFamily.Regular
  }
});

export default ArtefactList;
