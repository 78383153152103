// Add all languages you wish to support here
export enum SupportedLanguages {
  English = "en",
  Kannada = "kn"
}

const supportedLangList = Object.values(SupportedLanguages);

/**
 * Add translation files here, one per language
 * key must be from among the above enum `supportedLanguages`
 */
const resources = {
  [SupportedLanguages.English]: require("@socion-cordio/common/src/assets/languages/en-US.json"),
  [SupportedLanguages.Kannada]: require("@socion-cordio/common/src/assets/languages/kn-IN.json")
};

// Add namespaces here
enum NameSpaces {
  common = "common",
  auth = "auth"
}

const namespaces = Object.values(NameSpaces);
const defaultNamespace = NameSpaces.common;
const fallback = SupportedLanguages.English;

export const LocaleConstants = {
  supportedLangList,
  defaultNamespace,
  namespaces,
  fallback,
  resources,
  NameSpaces
};
