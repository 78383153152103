import React from "react";
import {
  widthPercentageToDP as wp,
  heightPercentageToDP as hp
} from "react-native-responsive-screen";
import { Text as RNText, StyleSheet, StyleProp, TextStyle, TextProps } from "react-native";
import PlatformUtils from "@socion-cordio/common/src/utils/platformUtils";

export enum TextSize {
  Small = "small",
  Regular = "regular",
  Large = "large"
}
export enum FontFamily {
  Thin = "Montserrat-Light",
  Light = "Montserrat-Light",
  Regular = "Montserrat-Regular",
  Medium = "Montserrat-Medium",
  Bold = "Montserrat-Bold",
  ExtraBold = "Montserrat-Regular",
  Italic = "Montserrat-Italic",
  NotoSansRegular = "NotoSans-Regular",
  NotoSansBold = "NotoSans-Bold",
  NotoSansExtraBold = "NotoSans-Regular",
  NotoSansItalic = "NotoSans-Italic"
}
export enum FontWeight {
  Thin = "thin", // font weight 300
  Light = "light", // font weight 300
  Regular = "normal",
  Medium = "medium", // font weight 500
  Bold = "bold",
  ExtraBold = "extraBold", // font weight 800
  Italic = "italic",
  NotoSansRegular = "normal",
  NotoSansBold = "bold",
  NotoSansExtraBold = "extraBold",
  NotoSansItalic = "italic"
}
interface IStyle {
  textLarge: TextStyle;
  textRegular: TextStyle;
  textSmall: TextStyle;
  titleSmall: TextStyle;
  titleRegular: TextStyle;
  titleLarge: TextStyle;
  labelRegular: TextStyle;
  labelSmall: TextStyle;
  labelLarge: TextStyle;
}
interface IProps extends TextProps {
  textSize: TextSize;
  fontWeight: FontWeight;
  textStyle?: StyleProp<TextStyle>;
  testId: string;
  children: string | number | boolean | any;
}
const testIdPrefix = {
  label: "lbl",
  text: "txt",
  title: "ttl"
};
const getFontStyle = (weight: FontWeight): StyleProp<TextStyle> => {
  switch (weight) {
    case FontWeight.Thin:
      return { fontFamily: FontFamily.Thin, fontWeight: "300" };
    case FontWeight.Light:
      return { fontFamily: FontFamily.Light, fontWeight: "300" };
    case FontWeight.Medium:
      return { fontFamily: FontFamily.Medium, fontWeight: "500" };
    case FontWeight.Bold:
      return { fontFamily: FontFamily.Bold, fontWeight: "bold" };
    case FontWeight.ExtraBold:
      return { fontFamily: FontFamily.ExtraBold, fontWeight: "900" };
    case FontWeight.Italic:
      return { fontFamily: FontFamily.Italic, fontWeight: "normal" };
    case FontWeight.Regular:
      return { fontFamily: FontFamily.Regular, fontWeight: "normal" };
    default:
      return { fontFamily: FontFamily.Regular, fontWeight: "normal" };
  }
};
const Title = (props: IProps): React.ReactElement<RNText> => {
  const { fontWeight, textSize, textStyle, children, testId } = props;
  let defaultStyle: TextStyle = {};
  const fontStyle: StyleProp<TextStyle> = getFontStyle(fontWeight);
  switch (textSize) {
    case TextSize.Small:
      defaultStyle = styles.titleSmall;
      break;
    case TextSize.Large:
      defaultStyle = styles.titleLarge;
      break;
    case TextSize.Regular:
    default:
      defaultStyle = styles.titleRegular;
      break;
  }
  return (
    <RNText
      accessibilityLabel={`${testIdPrefix.title}-${testId}`}
      testID={`${testIdPrefix.title}-${testId}`}
      style={[defaultStyle, fontStyle, textStyle]}
      {...props}
    >
      {children}
    </RNText>
  );
};
const Text = (props: IProps): React.ReactElement => {
  const { fontWeight, textSize, textStyle, children, testId } = props;
  let defaultStyle: TextStyle = {};
  const fontStyle: StyleProp<TextStyle> = getFontStyle(fontWeight);
  switch (textSize) {
    case TextSize.Small:
      defaultStyle = styles.textSmall;
      break;
    case TextSize.Large:
      defaultStyle = styles.textLarge;
      break;
    case TextSize.Regular:
    default:
      defaultStyle = styles.textRegular;
      break;
  }
  return (
    <RNText
      accessibilityLabel={`${testIdPrefix.title}-${testId}`}
      testID={`${testIdPrefix.title}-${testId}`}
      style={[defaultStyle, fontStyle, textStyle]}
      {...props}
    >
      {children}
    </RNText>
  );
};
const Label = (props: IProps): React.ReactElement => {
  const { fontWeight, textSize, textStyle, children, testId } = props;
  let defaultStyle: TextStyle = {};
  const fontStyle: StyleProp<TextStyle> = getFontStyle(fontWeight);

  switch (textSize) {
    case TextSize.Small:
      defaultStyle = styles.labelSmall;
      break;
    case TextSize.Large:
      defaultStyle = styles.labelLarge;
      break;
    case TextSize.Regular:
    default:
      defaultStyle = styles.labelRegular;
      break;
  }
  return (
    <RNText
      accessibilityLabel={`${testIdPrefix.title}-${testId}`}
      testID={`${testIdPrefix.title}-${testId}`}
      style={[defaultStyle, fontStyle, textStyle]}
      {...props}
    >
      {children}
    </RNText>
  );
};

const styles: IStyle = StyleSheet.create({
  textLarge: {
    fontSize: PlatformUtils.isWeb() ? 24 : wp("4.15%"),
    lineHeight: PlatformUtils.isWeb() ? 30 : hp("3.5%")
  },
  textRegular: {
    fontSize: PlatformUtils.isWeb() ? 20 : wp("3.5%"),
    lineHeight: PlatformUtils.isWeb() ? 25 : hp("3%")
  },
  textSmall: {
    fontSize: PlatformUtils.isWeb() ? 18 : wp("3.15%"),
    lineHeight: PlatformUtils.isWeb() ? 23 : hp("2.7%")
  },
  titleLarge: {
    fontSize: PlatformUtils.isWeb() ? 42 : wp("7.36%"),
    lineHeight: PlatformUtils.isWeb() ? 55 : hp("6.24%")
  },
  titleSmall: {
    fontSize: PlatformUtils.isWeb() ? 32 : wp("5.61%"),
    lineHeight: PlatformUtils.isWeb() ? 36 : hp("4.7%")
  },
  titleRegular: {
    fontSize: PlatformUtils.isWeb() ? 28 : wp("6.32%"),
    lineHeight: PlatformUtils.isWeb() ? 36 : hp("5.3%")
  },
  labelLarge: {
    fontSize: PlatformUtils.isWeb() ? 16 : wp("2.8%"),
    lineHeight: PlatformUtils.isWeb() ? 20 : wp("3%")
  },
  labelSmall: {
    fontSize: PlatformUtils.isWeb() ? 16 : wp("2.1%"),
    lineHeight: PlatformUtils.isWeb() ? 32 : hp("1.8%")
  },
  labelRegular: {
    fontSize: PlatformUtils.isWeb() ? 14 : wp("2.46%"),
    lineHeight: PlatformUtils.isWeb() ? 18 : hp("2.12%")
  }
});

export { Label, Title, Text };
