import React, { ReactElement, useEffect, useRef, useState } from "react";
import { StyleSheet, TouchableOpacity, View } from "react-native";
import { colorPallete } from "@socion-cordio/common/src/assets/styles/colors";
import {
  Text,
  TextSize,
  FontWeight,
  FontFamily
} from "@socion-cordio/common/src/components/atoms/text";
import Icon, { IconNames } from "@socion-cordio/common/src/components/atoms/icon";
import { LocalStorage } from "@socion-cordio/common/src/services/storage/storageService";
import FilterLocationPage from "@socion-cordio/common/src/components/organisms/qualification-packs/filterLocationPage";
import Button, { ButtonType } from "@socion-cordio/common/src/components/atoms/button";
import FilterTypes from "./filterTypes";
import DatePicker from "@socion-cordio/common/src/components/molecules/datePicker";
import { Image } from "@socion-cordio/common/src/components/atoms/image";
import Moment from "moment";
import { ScrollContainer } from "@socion-cordio/common/src/components/organisms/scrollWrapper";
import { Checkbox, CheckboxType } from "@socion-cordio/common/src/components/atoms/checkBox";

const filterState = [
  {
    name: "Location",
    icon: require("@socion-cordio/common/src/assets/images/location.svg"),
    type: "location"
  },
  {
    name: "Artefact Type",
    icon: require("@socion-cordio/common/src/assets/images/artefactType.svg"),
    type: "artefacttype"
  },
  {
    name: "Submitted Date",
    icon: "calender",
    type: "submitteddate"
  },
  {
    name: "Uploaded Date",
    icon: "calender",
    type: "uploadeddate"
  },
  {
    name: "Status",
    icon: require("@socion-cordio/common/src/assets/images/inactiveStatus.svg"),
    icon2: require("@socion-cordio/common/src/assets/images/status.png"),
    type: "status"
  }
];

interface Props {
  modalVisible: boolean;
  setModalVisible?: Function;
  getSelectedFilters: Function;
  dataFilteredHandler: Function;
  locationList: any[];
  typesList: any[];
  programDetails: any;
  setExpandedList: Function;
  expandedList: any;
  setCheckedList: Function;
  checkedList: any;
  clearAllFilter: Function;
  setIsFilterApplied: Function;
  closeModalHandler: Function;
  setGlobalList?: Function;
  globalList: any;
  setAllExpandedNodes?: any;
  allExpandedNodes?: any;
  selectedFilters: any;
  statusList: any;
  // clearFilter: Function;
}
export default function ArtefactFiltersModal(props: Props): ReactElement {
  const {
    modalVisible,
    setModalVisible,
    locationList,
    getSelectedFilters,
    dataFilteredHandler,
    programDetails,
    typesList,
    setExpandedList,
    expandedList,
    setCheckedList,
    checkedList,
    clearAllFilter,
    setIsFilterApplied,
    closeModalHandler,
    setGlobalList,
    globalList,
    setAllExpandedNodes,
    allExpandedNodes,
    selectedFilters,
    statusList
    // clearFilter
  } = props;
  const [updatedLocationList, setUpdatedLocationList] = useState([]);
  const [updatedTypesList, setUpdatedTypesList] = useState([]);
  const [updatedStatusList, setUpdatedStatusList] = useState([]);
  const [filterType, setFilterType] = useState("location");
  const [subStartDateValidity, setSubStartDateValidty] = useState(null);
  const [subEndDateValidity, setSubEndDateValidty] = useState(null);
  const [uploadStartDateValidity, setUploadStartDateValidty] = useState(null);
  const [uploadEndDateValidity, setUploadEndDateValidty] = useState(null);

  const [submittedDate, setSubmittedDate] = useState({ fromdate: "", todate: "" });
  const [uploadedDate, setUploadedDate] = useState({
    uploadedFromDate: "",
    uploadedToDate: ""
  });

  useEffect(() => {
    if (selectedFilters.submittedDate.fromdate || selectedFilters.submittedDate.todate) {
      setSubmittedDate({ ...selectedFilters.submittedDate });
    }
    if (
      selectedFilters.uploadedDate.uploadedFromDate ||
      selectedFilters.uploadedDate.uploadedToDate
    ) {
      setUploadedDate({ ...selectedFilters.uploadedDate });
    }
  }, []);

  useEffect(() => {
    getSelectedFilters("submitteddate", submittedDate);
  }, [submittedDate]);

  useEffect(() => {
    getSelectedFilters("uploadeddate", uploadedDate);
  }, [uploadedDate]);

  const updatedFilteredLocationList = (locationList: any, bool: any) => {
    setUpdatedLocationList(locationList);
  };

  const updatedTypes = (TypesList: any) => {
    setUpdatedTypesList(TypesList);
  };

  const updatedStatus = (TypesList: any) => {
    setUpdatedStatusList(TypesList);
  };

  const applyFilterHandler = async () => {
    LocalStorage.setStorage("globalNameList", globalList);

    const isNoneLocationSet = await LocalStorage.getStorage("noneLocationListSet");
    const isNoTypesSet = await LocalStorage.getStorage("noTypesSelected");
    const isNoStatusSet = await LocalStorage.getStorage("noStatusSelected");
    const isNoSubDateSet = await LocalStorage.getStorage("noSubDateListSet");
    const isNoUploadDateSet = await LocalStorage.getStorage("noUploadDateListSet");

    dataFilteredHandler({
      isFiltered: true,
      isNoneLocationSet: isNoneLocationSet,
      isNoTypeSet: isNoTypesSet,
      isNoStatusSet: isNoStatusSet,
      isNoSubDateSet: isNoSubDateSet,
      isNoUploadDateSet: isNoUploadDateSet
    });
    setModalVisible();
    setIsFilterApplied("all");
  };

  const checkSubStartDate = (date: any, days: any) => {
    const result = new Date(date);
    result.setDate(result.getDate() + days);
    const newDate = Moment(result).format("YYYY-MM-DD");
    days === 0 ? setSubStartDateValidty(newDate) : setSubEndDateValidty(newDate);
  };

  const checkUploadStartDate = (date: any, days: any) => {
    const result = new Date(date);
    result.setDate(result.getDate() + days);
    const newDate = Moment(result).format("YYYY-MM-DD");
    days === 0 ? setUploadStartDateValidty(newDate) : setUploadEndDateValidty(newDate);
  };

  const isValidDate = (start: any, end: any) => {
    const newStartDate = Moment(start).format("YYYY-MM-DD");
    const newEndDate = Moment(end).format("YYYY-MM-DD");

    if (newStartDate >= newEndDate) {
      return false;
    }
    return true;
  };

  const updateDate = (type: string, date: any) => {
    switch (type) {
      case "submit-start":
        if (isValidDate(date, submittedDate.todate)) {
          checkSubStartDate(date, 1);
          setSubmittedDate({ ...submittedDate, fromdate: date });
        } else {
          checkSubStartDate(date, 1);
          setSubmittedDate({ fromdate: date, todate: "" });
        }
        setIsFilterApplied("submitteddate");

        break;
      case "submit-end":
        setSubmittedDate({ ...submittedDate, todate: date });
        setIsFilterApplied("submitteddate");

        break;
      case "upload-start":
        if (isValidDate(date, uploadedDate.uploadedToDate)) {
          checkUploadStartDate(date, 1);
          setUploadedDate({ ...uploadedDate, uploadedFromDate: date });
        } else {
          checkUploadStartDate(date, 1);
          setUploadedDate({ uploadedFromDate: date, uploadedToDate: "" });
        }
        setIsFilterApplied("uploadeddate");

        break;
      case "upload-end":
        setUploadedDate({ ...uploadedDate, uploadedToDate: date });
        setIsFilterApplied("uploadeddate");
        break;
    }
  };

  const clearFilter = (type: string) => {
    if (type === "submitteddate") {
      setSubmittedDate({ fromdate: "", todate: "" });
    }

    if (type === "uploadeddate") {
      setUploadedDate({ uploadedFromDate: "", uploadedToDate: "" });
    }
  };

  const getDate = (data: { key: string; id: string; title: string; value: any; minValue: any }) => {
    return (
      <View key={data.key}>
        <Text
          fontWeight={FontWeight.Thin}
          testId={data.key}
          textSize={TextSize.Regular}
          textStyle={styles.headerText}
          style={{ color: colorPallete.lightGreyTwo, paddingVertical: 15 }}
        >
          {data?.title}
        </Text>
        <DatePicker
          key={data.key + "-datepicker"}
          min={data.minValue}
          onChange={(e) => updateDate(data.key, e.target.value)}
          value={data.value || ""}
          name={data.id}
          id={data.id}
          noFormik
        />
      </View>
    );
  };

  const getDateButtons = (type: string) => {
    return (
      <View style={[styles.mainButtonContainer]}>
        <View style={styles.buttonContainer}>
          <Button
            type={ButtonType.Secondary}
            buttonStyles={[styles.button, styles.alignClearButton]}
            textStyles={styles.buttonFont}
            title="Clear"
            onPress={() => clearFilter(type)}
          />
        </View>
        <View style={styles.buttonContainer}>
          <Button
            type={ButtonType.Primary}
            buttonStyles={styles.button}
            textStyles={styles.buttonFont}
            title="Apply"
            onPress={() => applyFilterHandler()}
          />
        </View>
      </View>
    );
  };

  return (
    <View>
      <View style={styles.container}>
        <View style={styles.header}>
          <Text
            fontWeight={FontWeight.Bold}
            testId="addRoleText"
            textSize={TextSize.Small}
            textStyle={styles.headerText}
          >
            Filter
          </Text>
          <View>
            <Icon
              testID="close"
              name={IconNames.crossCircle}
              customStyle={styles.crossCircle}
              onPress={() => [setModalVisible(), closeModalHandler()]}
            />
          </View>
        </View>
        <View style={styles.subContainer}>
          <View style={styles.leftContainer}>
            <ScrollContainer>
              {filterState.map((state: any, key: any) => {
                return (
                  <TouchableOpacity
                    onPress={() => {
                      setFilterType(state.type);
                    }}
                    key={key + state.type + "key"}
                  >
                    <View style={styles.alignLeftContainer} key={state.type + "leftView"}>
                      <View style={{ width: "15%" }}>
                        {!(state.type == "artefacttype" || state.type == "status") && (
                          <Icon
                            testID="close"
                            name={
                              state.type == "location" ? IconNames.baseLocation : IconNames.calender
                            }
                            customStyle={
                              filterType === state.type
                                ? [styles.crossCircle]
                                : [styles.crossCircle, { color: colorPallete.lightGreyTwo }]
                            }
                          />
                        )}
                        {(state.type == "artefacttype" || state.type == "status") && state.icon && (
                          <Image
                            testId={state.type + "image"}
                            source={state.icon}
                            imageStyle={
                              state.type === filterType ? styles.imageStyle : styles.imageStyleGrey
                            }
                          />
                        )}
                      </View>

                      <Text
                        fontWeight={FontWeight.Light}
                        testId="addRoleText"
                        textSize={TextSize.Small}
                        textStyle={
                          filterType === state.type
                            ? filterType === "status"
                              ? [styles.headerText, styles.headerTextPrimary]
                              : [styles.headerText, styles.headerTextPrimary]
                            : [styles.headerTextSecondary]
                        }
                      >
                        {state.name}
                      </Text>
                    </View>
                  </TouchableOpacity>
                );
              })}
            </ScrollContainer>
            <View style={[styles.clearAllContainer]}>
              <TouchableOpacity>
                <Button
                  type={ButtonType.Secondary}
                  buttonStyles={[
                    styles.button,
                    styles.alignClearButton,
                    styles.alignLeftContainer,
                    { display: "flex", alignItems: "center", justifyContent: "center" }
                  ]}
                  textStyles={[
                    styles.buttonFont,
                    { display: "flex", alignItems: "center", justifyContent: "center" }
                  ]}
                  title="Clear All"
                  onPress={() => {
                    setSubmittedDate({ fromdate: "", todate: "" });
                    setUploadedDate({ uploadedFromDate: "", uploadedToDate: "" });
                    clearAllFilter();
                  }}
                />
              </TouchableOpacity>
            </View>
          </View>
          <View style={styles.dividerContainer}></View>
          <View style={styles.rightContainer} key={filterType + "RightView"}>
            {programDetails && filterType === "location" ? (
              <FilterLocationPage
                getSelectedLocationList={(data: any) => {
                  getSelectedFilters("location", data);
                }}
                locationList={locationList}
                programDetails={programDetails}
                setExpandedList={setExpandedList}
                expandedList={expandedList}
                setCheckedList={setCheckedList}
                checkedList={checkedList}
                dataFilteredHandler={dataFilteredHandler}
                setModalVisible={setModalVisible}
                setIsLocationFilterApplied={() => setIsFilterApplied("location")}
                updatedFilteredLocationList={updatedFilteredLocationList}
                setGlobalList={setGlobalList}
                globalList={globalList}
                setAllExpandedNodes={setAllExpandedNodes}
                allExpandedNodes={allExpandedNodes}
                applyFilterHandler={applyFilterHandler}
                isClear={true}
              />
            ) : filterType === "artefacttype" ? (
              <FilterTypes
                key={"type-filterType"}
                getSelectedTypesList={(data: any) => {
                  getSelectedFilters("type", data);
                }}
                type={"type"}
                typesList={typesList}
                programDetails={programDetails}
                dataFilteredHandler={dataFilteredHandler}
                setModalVisible={setModalVisible}
                setIsTypeFilterApplied={() => setIsFilterApplied("type")}
                updatedTypesList={updatedTypes}
                applyFilterHandler={applyFilterHandler}
              />
            ) : filterType === "submitteddate" ? (
              <View style={styles.dateMainContainer} key={"submittedstartend"}>
                <View style={styles.dateContainer}>
                  {getDate({
                    key: "submit-start",
                    id: "subStartDate",
                    title: "Start date",
                    value: submittedDate.fromdate,
                    minValue: subStartDateValidity
                  })}

                  {getDate({
                    key: "submit-end",
                    id: "subEndDate",
                    title: "End date",
                    value: submittedDate.todate,
                    minValue: subEndDateValidity
                  })}
                </View>
                {getDateButtons("submitteddate")}
              </View>
            ) : filterType === "uploadeddate" ? (
              <View style={styles.dateMainContainer} key={"uploadstartend"}>
                <View style={styles.dateContainer}>
                  {/* start date */}
                  {getDate({
                    key: "upload-start",
                    id: "uploadstartDate",
                    title: "Start date",
                    value: uploadedDate.uploadedFromDate,
                    minValue: uploadStartDateValidity
                  })}
                  {/* end date */}
                  {getDate({
                    key: "upload-end",
                    id: "uploadendDate",
                    title: "End date",
                    value: uploadedDate.uploadedToDate,
                    minValue: uploadEndDateValidity
                  })}
                </View>
                {/* date buttons */}
                {getDateButtons("uploadeddate")}
              </View>
            ) : filterType === "status" ? (
              <View style={styles.statusMainContainer} key={"artefact-status"}>
                <FilterTypes
                  key={"status-filterType"}
                  getSelectedTypesList={(data: any) => {
                    getSelectedFilters("status", data);
                  }}
                  type={"status"}
                  typesList={statusList}
                  programDetails={programDetails}
                  dataFilteredHandler={dataFilteredHandler}
                  setModalVisible={setModalVisible}
                  setIsTypeFilterApplied={() => setIsFilterApplied("status")}
                  updatedTypesList={updatedStatus}
                  applyFilterHandler={applyFilterHandler}
                />
              </View>
            ) : null}
          </View>
        </View>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    width: 655,
    height: 450
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row"
  },
  headerText: {
    fontSize: 12,
    fontFamily: FontFamily.Medium,
    lineHeight: 17
  },
  headerTextSecondary: {
    fontSize: 12,
    width: "85%",
    paddingHorizontal: 6,
    // marginLeft: 15,
    color: colorPallete.textLight,
    fontFamily: FontFamily.Regular
  },
  headerTextPrimary: {
    fontSize: 12,
    fontWeight: "600",
    width: "85%",
    paddingHorizontal: 6

    // marginLeft: 15
  },
  alignText: {
    color: colorPallete.textLight,
    marginTop: 10
  },
  textColor: {
    color: colorPallete.textBlack
  },
  crossCircle: {
    fontSize: 18
  },
  subContainer: {
    marginTop: 15,
    marginBottom: 15,
    flexDirection: "row"
  },

  //left container
  leftContainer: {
    display: "flex",
    padding: 10,
    justifyContent: "space-between",
    alignItems: "center",

    // flex: 23,
    paddingLeft: 0
  },
  alignLeftContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 25
  },
  // alignLabelContainer: {
  //   flexDirection: "column",
  //   justifyContent: "space-between",
  //   height: "95%"
  // },
  leftIconContainer: {
    fontSize: 16,
    color: colorPallete.textLight
  },
  leftIconContainerDark: {
    fontSize: 16,
    color: colorPallete.textBlack
  },

  //divider
  dividerContainer: {
    flex: 1,
    minHeight: 450,
    borderRightColor: colorPallete.cordioTaupe,
    borderRightWidth: 1,
    borderStyle: "solid",
    opacity: 0.1
  },

  //right container
  rightContainer: {
    flex: 77,
    padding: 10,
    paddingLeft: 25
  },
  //text field
  inputStyling: {
    height: 40,
    // outline: "none",
    borderRadius: 5,
    padding: 7,
    fontSize: 12,
    fontFamily: FontFamily.Regular,
    fontWeight: "400"
  },
  inputStylingAlignment: {},
  //checkBox
  checkBoxContainer: {
    marginTop: 27
  },
  textStyleCheckBox: {
    marginStart: 20
  },
  mainButtonContainer: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    justifyContent: "flex-end",
    alignItems: "center"
  },
  buttonContainer: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center"
  },
  button: {
    width: 100,
    height: 40,
    borderRadius: 10
  },
  buttonFont: {
    fontSize: 12
  },
  alignClearButton: {
    marginRight: 20
    // top: 12
  },
  dateMainContainer: {
    height: "100%",
    display: "flex",
    justifyContent: "space-between",
    paddingBottom: 25
    // alignItems: "center"
  },
  dateContainer: {
    height: "80%",
    fontFamily: FontFamily.Regular,
    color: colorPallete.textBlack
  },
  clearAllContainer: {
    display: "flex",
    width: "100%",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center"
    // height:"calc(100% - 290px)",
    // top: 140
  },
  statusMainContainer: {},

  imageStyleGrey: {
    width: 20,
    height: 20
  },
  imageStyle: {
    width: 20,
    height: 20,
    tintColor: colorPallete.black
  }
});
