import { programEndPoints } from "@socion-cordio/common/src/repositories/endPoints";
import { ApiClient } from "@socion-cordio/common/src/network/apiClient";
import { toast } from "react-toastify";

const mediaDownloadHandler = async (type: string, name: string) => {
  try {
    const validTypesImage = ["png", "jpg", "jpeg"];
    const validTypesDocs = ["pdf"];
    const validTypesVideo = ["mp4", "mpeg", "flv", "mov"];
    let content = null;
    if (type.toLowerCase() == "image" || validTypesImage.includes(type.toLowerCase())) {
      content = "image";
    }
    if (type.toLowerCase() == "document" || validTypesDocs.includes(type.toLowerCase())) {
      content = "document";
    }
    if (type.toLowerCase() == "video" || validTypesVideo.includes(type.toLowerCase())) {
      content = "video";
    }
    const response = await ApiClient.get(programEndPoints.getPresignedUrl(content, name));
    if (response) {
      var link = document.createElement("a");
      link.href = response;
      link.download = "Download.jpg";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      content = null;
    }
  } catch (error) {
    toast.error(error);
  }
};

const mediaTopicContentDownloadHandler = async (contentFile: any, type: string, name: string) => {
  try {
    const { contentUrl } = contentFile;
    const validTypesImage = ["png", "jpg", "jpeg"];
    const validTypesDocs = ["pdf"];
    const validTypesVideo = ["mp4", "mpeg", "flv", "mov"];
    let content = null;
    if (type.toLowerCase() == "image" || validTypesImage.includes(type.toLowerCase())) {
      contentUrl.includes("content/image") ? (content = "content/image") : (content = "image");
    }
    if (type.toLowerCase() == "document" || validTypesDocs.includes(type.toLowerCase())) {
      contentUrl.includes("content/document")
        ? (content = "content/document")
        : (content = "document");
    }
    if (type.toLowerCase() == "video" || validTypesVideo.includes(type.toLowerCase())) {
      contentUrl.includes("content/video") ? (content = "content/video") : (content = "video");
    }
    const response = await ApiClient.get(programEndPoints.getPresignedUrl(content, name));
    if (response) {
      var link = document.createElement("a");
      link.href = response;
      link.download = "Download.jpg";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      content = null;
    }
  } catch (error) {
    toast.error(error);
  }
};

const mediaDownloadEntityHandler = async (type: string, name: string) => {
  console.log(type, name);
  try {
    const validTypesImage = ["png", "jpg", "jpeg"];
    const validTypesDocs = ["pdf"];
    const validTypesVideo = ["mp4", "mpeg", "flv", "mov"];
    let content = type;
    if (type.toLowerCase() == "image" || validTypesImage.includes(type.toLowerCase())) {
      content = "entity-docs/image";
    }
    if (type.toLowerCase() == "document" || validTypesDocs.includes(type.toLowerCase())) {
      content = "entity-docs/document";
    }
    if (type.toLowerCase() == "video" || validTypesVideo.includes(type.toLowerCase())) {
      content = "entity-docs/Entity Videos";
    }
    const response = await ApiClient.get(programEndPoints.getPresignedUrl(content, name));
    if (response) {
      var link = document.createElement("a");
      link.href = response;
      link.download = "Download.jpg";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      // content = null;
    }
  } catch (error) {
    toast.error(error);
  }
};

const mediaDownloadVerify = async (type: string, name: string) => {
  try {
    const validTypesImage = ["png", "jpg", "jpeg"];
    const validTypesDocs = ["pdf"];
    const validTypesVideo = ["mp4", "mpeg", "flv", "mov"];
    let content = null;
    if (type.toLowerCase() == "image" || validTypesImage.includes(type.toLowerCase())) {
      content = "profile/Qualification-Docs";
    }
    if (type.toLowerCase() == "document" || validTypesDocs.includes(type.toLowerCase())) {
      content = "profile/Qualification-Docs";
    }
    if (type.toLowerCase() == "video" || validTypesVideo.includes(type.toLowerCase())) {
      content = "profile/Qualification-Videos";
    }
    const response = await ApiClient.get(programEndPoints.getPresignedUrl(content, name));
    if (response) {
      var link = document.createElement("a");
      link.href = response;
      link.download = `Download.jpg`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      content = null;
    }
  } catch (error) {
    toast.error(error);
  }
};

const mediaDownloadQRHandler = async (type: string, name: string) => {
  try {
    const response = await ApiClient.get(programEndPoints.getPresignedUrl(type, name));
    if (response) {
      var link = document.createElement("a");
      link.href = response;
      link.download = "Download.jpg";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  } catch (error) {
    toast.error(error);
  }
};

let imageTypes = ["png", "jpg", "jpeg", "image"];
let documentTypes = ["pdf", "document"];

const getIcons = (type: string) => {
  let tempType = type?.toLowerCase();
  let data = imageTypes.includes(tempType)
    ? "image"
    : documentTypes.includes(tempType)
    ? "document"
    : "video";
  return data;
};

export const mediaHelper = {
  mediaDownloadHandler,
  mediaDownloadVerify,
  mediaDownloadEntityHandler,
  mediaDownloadQRHandler,
  mediaTopicContentDownloadHandler,
  getIcons
};
