import { deserialize, serialize } from "@socion-cordio/common/src/mappers/apiResMapper";
import { ApiClient } from "@socion-cordio/common/src/network/apiClient";
import { ViewSession } from "@socion-cordio/common/src/models/viewSession";
import { sessionPackEndPoints } from "@socion-cordio/common/src/repositories/endPoints";

class SessionRepository {
  getSessionDetails = async (sessionId: number): Promise<ViewSession> => {
    let data = await ApiClient.get(sessionPackEndPoints.getCompleteSessionDetails(sessionId));
    // const topicsData: Topic = deserialize(Topic, data);
    return data;
  };
}
const sessionRepository = new SessionRepository();
export { sessionRepository as SessionRepository };
