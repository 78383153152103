import React, { ReactElement } from "react";
import { StyleSheet, View } from "react-native";
import { dataHelper } from "@socion-cordio/common/src/utils/dataHelper";
import LoaderCircle from "@socion-cordio/common/src/components/atoms/loaderCircle";
import FilePicker from "@socion-cordio/common/src/components/molecules/filePicker";
import TopicDetailsContentList from "@socion-cordio/common/src/components/organisms/topic/topicsDetailsContentList";

interface Props {
  handleCustomChange: Function;
  loading: Boolean;
  topicDetails: any;
  getTopicDetails: Function;
  updateTelemetryContentAdd: Function;
  status: Boolean;
  userRoles: any;
  isSuperAdmin: Boolean;
  subContentEnabled: Boolean;
  publishForTopicLibraryHandler?: Function;
  programStatus?: any;
}

export default function TopicDetailsContent(props: Props): ReactElement {
  const {
    handleCustomChange,
    loading,
    topicDetails,
    getTopicDetails,
    updateTelemetryContentAdd,
    status,
    userRoles,
    isSuperAdmin,
    subContentEnabled,
    publishForTopicLibraryHandler,
    programStatus
  } = props;

  return (
    <View>
      {((!isSuperAdmin && userRoles?.includes("PROGRAM_ADMIN")) ||
        userRoles?.includes("ENTITY_ADMIN") ||
        userRoles?.includes("CONTENT_ADMIN")) &&
        status &&
        !programStatus &&
        topicDetails?.contentUpload && (
          <>
            <View style={styles.fileUploaderContainer}>
              <View style={loading ? { width: "92%" } : { width: "100%" }}>
                <FilePicker
                  customDocsContainer={styles.customDocsContainer}
                  textStyles={styles.textStyles}
                  onChange={async (e) => {
                    await handleCustomChange(e);
                  }}
                />
              </View>
              {loading && (
                <View style={styles.customLoader}>
                  <LoaderCircle />
                </View>
              )}
            </View>
          </>
        )}

      {!dataHelper.isEmptyArray(topicDetails?.contentFiles) && (
        <>
          <TopicDetailsContentList
            getTopicDetails={getTopicDetails}
            updateTelemetryContentAdd={updateTelemetryContentAdd}
            topicDetails={topicDetails}
            status={status}
            userRoles={userRoles}
            subContentEnabled={subContentEnabled}
            publishForTopicLibraryHandler={publishForTopicLibraryHandler}
            programStatus={programStatus}
          />
        </>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  //File Uploader
  fileUploaderContainer: {
    flexDirection: "row",
    marginBottom: 5
  },
  customDocsContainer: {
    marginTop: 0
  },
  customLoader: {
    justifyContent: "center",
    alignItems: "center",
    marginTop: 11
  },
  textStyles: {
    fontSize: 14
  }
});
