import { TaskActions } from "@socion-cordio/common/src/modules/tasks/actions/action";
import { put, call, takeLatest } from "redux-saga/effects";
import { Task } from "@socion-cordio/common/src/models/task";
import { TaskRepository } from "@socion-cordio/common/src/repositories/tasks/task";
import { TASKS } from "@socion-cordio/common/src/modules/tasks/actions/actionTypes";
import { IFluxStandardAction } from "store/interfaces";

function* getTasksWorkersaga(value: IFluxStandardAction<{ programId: number }>) {
  try {
    const { payload } = value;
    const response: Task = yield call(TaskRepository.getTasks, payload);
    yield put(TaskActions.getTasksSuccess(response));
  } catch (error) {
    yield put(TaskActions.getTasksError(error));
  }
}

function* getTaskDataWorkersaga(value: IFluxStandardAction<any>) {
  try {
    const { payload } = value;
    const response: Task = yield call(TaskRepository.getTaskById, payload);
    yield put(TaskActions.getTaskDataSuccess(response));
  } catch (error) {
    yield put(TaskActions.getTaskDataError(error));
  }
}

// function* getTaskSubmissions(value: IFluxStandardAction<any>) {
//   try {
//     const { payload } = value;

//     const response: any[] = yield call(TaskRepository.getSubmissions, payload);

//     yield put(TaskActions.getTasksSubmissionSuccess(response));
//   } catch (error) {
//     yield put(TaskActions.getTasksSubmissionError(error));
//   }
// }

// function* getSubmitterById(value: IFluxStandardAction<any>) {
//   try {
//     const { payload } = value;
//     console.log("getSubmissions action saga", payload);

//     const response: any[] = yield call(TaskRepository.getSubmitterById, payload);

//     yield put(TaskActions.getSubmitterByIdSuccess(response));
//   } catch (error) {
//     yield put(TaskActions.getSubmitterByIdError(error));
//   }
// }

export default function* TasksWatcherSaga() {
  yield takeLatest(TASKS.GET_TASKS.LOADING, getTasksWorkersaga);
  yield takeLatest(TASKS.GET_TASK_DATA.LOADING, getTaskDataWorkersaga);
  // yield takeLatest(TASKS.GET_SUBMISSIONS.LOADING, getTaskSubmissions);
  // yield takeLatest(TASKS.GET_SUBMITTER.LOADING, getSubmitterById);
}
