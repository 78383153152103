import { Image } from "@socion-cordio/common/src/components/atoms/image";
import React, { useEffect, useState } from "react";
import { StyleSheet, TouchableOpacity, View } from "react-native";
import { artefact_messages } from "../data/message";
import { getJoinedLocation } from "../helpers/artefactHelper";
import { toast } from "react-toastify";
import { FontFamily } from "@socion-cordio/common/src/components/atoms/text";

const Submitters = (props: any) => {
  const [submittersList, setSubmittersList] = useState([]);

  useEffect(() => {
    setSubmittersList(props?.submitters || []);
  }, [props]);

  const handleDeleteSubmitter = (user: { submitter: any; index: number }) => {
    if (submittersList.length > 1) {
      let tempArray = [...submittersList];
      tempArray.splice(user?.index, 1);
      setSubmittersList(tempArray);
      props.deleteSubmitter(user);
    } else {
      toast.error(artefact_messages.error.m018);
    }
  };

  return (
    <>
      {submittersList.length > 0 &&
        submittersList.map(
          (submitter: any, index: number) =>
            submitter?.name && (
              <div className="submittersWrapper" key={`submitter-${index}`}>
                <View style={styles.submitterContainer}>
                  <div className="submitterImg">
                    <Image
                      imageStyle={styles.profilePicture}
                      testId="socionImg"
                      source={
                        submitter?.profilepicurl ||
                        require("@socion-cordio/common/src/assets/images/user_circle.svg")
                      }
                    />{" "}
                  </div>
                  <div>
                    <div
                      style={{
                        fontSize: 14,
                        fontFamily: FontFamily.Thin,
                        fontWeight: "700",
                        color: "#231F20",
                        lineHeight: "17px"
                      }}
                    >
                      {submitter?.name}
                    </div>
                    <div
                      style={{
                        fontSize: 11,
                        fontFamily: FontFamily.Thin,
                        fontWeight: "400",
                        color: "#7B7979",
                        paddingBottom: "2px",
                        lineHeight: "13px"
                      }}
                    >
                      {submitter?.phonenumber}
                    </div>
                    <div
                      style={{
                        fontSize: 12,
                        fontFamily: FontFamily.Thin,
                        fontWeight: "500",
                        color: "#7B7979",
                        lineHeight: "15px"
                      }}
                    >
                      {getJoinedLocation(submitter)}
                    </div>
                  </div>
                </View>
                <View style={styles.removeIcon}>
                  {!props.isView && (
                    <TouchableOpacity
                      onPress={() => {
                        handleDeleteSubmitter({ submitter, index });
                      }}
                    >
                      <Image
                        testId="remove"
                        source={require("@socion-cordio/common/src/assets/images/remove.svg")}
                        imageStyle={{
                          width: 14,
                          height: 14
                        }}
                      />
                    </TouchableOpacity>
                  )}
                </View>
              </div>
            )
        )}
    </>
  );
};
const styles = StyleSheet.create({
  profilePicture: {
    borderRadius: 54,
    width: "40px",
    height: "40px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  submitterContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignContent: "center",
    width: "95%"
  },
  removeIcon: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    width: "5%"
  }
});
export default Submitters;
