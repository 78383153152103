import { JsonConverter, JsonCustomConvert, JsonObject, JsonProperty } from "json2typescript";

// @JsonConverter
// class DateConverter implements JsonCustomConvert<Date> {
//   serialize(date: Date): any {
//     return date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate();
//   }
//   deserialize(date: any): Date {
//     return new Date(date);
//   }
// }

@JsonObject("QualificationPrograms")
export class QualificationPrograms {
  @JsonProperty("entityName", String)
  program: string = "";
  @JsonProperty("requestedDate", String)
  createdOn: string = "";
  @JsonProperty("status", String)
  status: string = "";
  @JsonProperty("verifierName", String)
  verifierName: string = "";
  @JsonProperty("verifierId", Number)
  verifierId: number = null;
  @JsonProperty("responseDate", String)
  responseDate: string = "";
  @JsonProperty("reason", String)
  reason: string = "";
  @JsonProperty("approverProfileData", Object)
  approverProfileData: object = null;
}

@JsonObject("Response")
export class Response {
  @JsonProperty("qualificationId", String)
  qualificationId: string = "";
  @JsonProperty("qualificationPrograms", [QualificationPrograms])
  qualificationPrograms: Array<QualificationPrograms> = null;
  @JsonProperty("documents", [String])
  documents: Array<string> = [];
  @JsonProperty("qualificationTitle", String)
  qualificationTitle: string = "";
  @JsonProperty("userId", String)
  userId: string = "";
  @JsonProperty("osid", String)
  osid: string = "";
}

@JsonObject("UserQualification")
export class UserQualification {
  @JsonProperty("response", [Response])
  response: Array<Response> = null;
  @JsonProperty("message", String)
  message: string = "";
  @JsonProperty("responseCode", Number)
  responseCode: number = null;
}
