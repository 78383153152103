import React, { ReactElement, useEffect, useState } from "react";
import { Form, Formik, FormikProps } from "formik";
import { StyleSheet, View, TouchableOpacity, FlatList } from "react-native";
import { colorPallete } from "@socion-cordio/common/src/assets/styles/colors";
import { Text, TextSize, FontWeight, Label } from "@socion-cordio/common/src/components/atoms/text";
import UserInput from "@socion-cordio/common/src/components/molecules/userInput";
import { IconNames } from "@socion-cordio/common/src/components/atoms/icon";
import * as Yup from "yup";
import MobileInput from "@socion-cordio/common/src/components/molecules/mobileInput";
import Button, { ButtonType } from "@socion-cordio/common/src/components/atoms/button";
import Socion from "@socion-cordio/common/src/components/organisms/Socion";
import { useHistory } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
import { toast } from "react-toastify";
import { LocalStorage } from "@socion-cordio/common/src/services/storage/storageService";
import Geolocation from "react-native-geolocation-service";
import axios from "axios";
import { ApiClient } from "@socion-cordio/common/src/network/apiClient";
import { loginEndPoints } from "@socion-cordio/common/src/repositories/endPoints";
import { allRoutesNames } from "@socion-cordio/web/src/navigation/allRouteNames";
import { telemetryToTrackMayMyIndia } from "@socion-cordio/common/src/utils/mayMyIndiaTelemetry";

interface Props {
  fetchSignup?: Function;
  // profileData: Profile;
  // loading: IProfileLoading;
  updateSignup?: Function;
  isCaptchaDisabled?: boolean;
  setIsCaptchaDisabled?: Function;
  setSelectedCountryObject?: Function;
  setEmailVerification?: Function;
  selectedCountryObject?: any;
}

export const SignupForm = React.forwardRef((props: Props, ref: any): ReactElement => {
  // const [mobile, setMobile] = useState("");
  // const validation = () => {
  //   if (mobile) {
  //     return true;
  //   } else {
  //     return false;
  //   }
  // };
  const { setIsCaptchaDisabled, isCaptchaDisabled } = props;
  const siteKey = "6Lc76q4ZAAAAAC9ar-nPZhWwrLcnNuZas1NHulv_";
  const [captchaValue, setCaptchaValue] = useState(null);
  const [validate, setValidate] = useState([
    {
      label: "+91",
      phoneNumberLength: 10,
      phoneNumberSizeMax: 10,
      phoneNumberSizeMin: 10,
      value: 91
    }
  ]);
  const [minLength, setMinLength] = useState(10);
  const [maxLength, setMaxLength] = useState(10);
  const [IslocationAccess, setIslocationAccess] = useState(false);
  const [countryCodeList, setCountryCodeList] = useState([]);
  const [selectedSequence, setSelectedSequence] = useState(null);
  const [showCountryCodeList, setShowCountryCodeList] = useState(false);
  const [buttonTitleValue, setbuttonTitleValue] = useState("Select your country");
  const [isEmailVerification, setisEmailVerification] = useState(false);
  const [isPhoneVerification, setisPhoneVerification] = useState(false);
  const [dropdownVal, setdropdownVal] = useState(0);
  const [updateddropdownCountryCode, setUpdateddropdownCountryCode] = useState(91);
  const [CountryObject, setCountryObject] = useState(null);
  const [mobileInputValue, setmobileInputValue] = useState("");

  const history: any = useHistory();
  const formValidaionSchema = Yup.object({
    name: Yup.string().required("Please enter username").nullable(),
    countryCode: Yup.number().required("Please select country code"),
    phoneNumber: Yup.number().required("The phone number should be of 10 digits.").nullable()
  });

  const saveSignup = (values: {
    name: string;
    countryCode: number;
    phoneNumber: number;
    email: string;
  }) => {
    let payload: any = {};
    if (isPhoneVerification) {
      payload.name = values.name;
      payload.countryCode = `+${values.countryCode}`;
      payload.phoneNumber = values.phoneNumber;
      payload.grecaptchaResponse = captchaValue;
      payload.verificationType = "PHONE_NUMBER";
      props.updateSignup(payload);
    } else {
      payload.name = values.name;
      payload.countryCode = `+${values.countryCode}`;
      payload.phoneNumber = values.phoneNumber;
      payload.grecaptchaResponse = captchaValue;
      payload.verificationType = "EMAIL_ID";
      payload.emailId = values.email;
      props.updateSignup(payload);
    }
  };

  useEffect(() => {
    async function fetchData() {
      let countryCode: any = await LocalStorage.getStorage("countryCode");
      let countryCodeObj: any = await LocalStorage.getStorage("countryCodeObj");

      countryCode === null || countryCodeObj === null
        ? [onPressForLocation(), console.log("No cc in local", countryCode)]
        : [
            serCountryCodeFromLocalStorage()
            // console.log("country code present in local", countryCode)
          ];
    }
    fetchData();
    // onPressForLocation();
  }, []);

  useEffect(() => {
    if (validate[0]?.phoneNumberSizeMax != validate[0]?.phoneNumberSizeMin) {
      setMinLength(validate[0]?.phoneNumberSizeMin);
      setMaxLength(validate[0]?.phoneNumberSizeMax);
    } else {
      setMinLength(10);
      setMaxLength(10);
    }
  }, [validate]);

  useEffect(() => {
    getCountryodeList();
  }, []);

  const serCountryCodeFromLocalStorage = async () => {
    let countryCodeObject: any = await LocalStorage.getStorage("countryCodeObj");
    let countryCodeList: any = await LocalStorage.getStorage("countryCodeList");
    const countryData = countryCodeList.filter(
      (element: any) => element?.sequence === countryCodeObject?.sequence
    );
    actionOnRow(countryData[0]);
  };

  const onPressForLocation = (toggle = false) => {
    navigator.permissions.query({ name: "geolocation" }).then(function (result) {
      if (navigator.permissions.query({ name: "geolocation" })) {
        if (result.state == "granted") {
          Geolocation.getCurrentPosition(
            (position: any) => {
              const params = {
                lat: position.coords.latitude,
                long: position.coords.longitude,
                lang: "en"
              };
              setIslocationAccess(true);
              setDefaultLocation(params);
            },
            (error: any) => {
              console.log(error);
            },
            {
              enableHighAccuracy: true,
              timeout: 15000,
              maximumAge: 10000,
              showLocationDialog: false
            }
          );
        } else if (result.state == "prompt") {
          navigator.geolocation.getCurrentPosition(revealPosition, positionDenied);
        } else if (result.state == "denied") {
          setIslocationAccess(false);
        } else {
          setIslocationAccess(false);
        }
      }
    });
  };

  const revealPosition = () => {
    onPressForLocation();
  };

  const positionDenied = () => {
    setIslocationAccess(false);
  };

  const setDefaultLocation = async (params: any) => {
    try {
      const response: any = await axios.get(
        `https://maps.googleapis.com/maps/api/geocode/json?key=AIzaSyDVf-To9hehz4ORteAxgsP-yzHxsahVcDo&latlng=${params.lat},${params.long}&language=${params.lang}`
      );
      const result = response.data.results[0];
      await telemetryToTrackMayMyIndia("REVERSE_GEO_CODE", {
        lat: params.lat,
        lng: params.long,
        language: params.lang
      });
      let countryCodeListResoponse = await ApiClient.get(loginEndPoints.getCountryCode());
      setCountryCodeList(countryCodeListResoponse.response);
      result.address_components.map((item: any) => {
        if (item.types[0] === "country") {
          const shortName = item.short_name;

          const IfCountryExist = countryCodeListResoponse.response.filter(
            (element: any) => element.isoShortCode === shortName
          );
          actionOnRow(IfCountryExist[0]);
        }
      });
    } catch (error) {}
  };

  const getCountryodeList = async () => {
    let countryCodeListResoponse = await ApiClient.get(loginEndPoints.getCountryCode());
    setCountryCodeList(countryCodeListResoponse.response);
    LocalStorage.setStorage("countryCodeList", countryCodeListResoponse.response);
    return countryCodeListResoponse;
  };

  const getCountry = (data: any, IslocationAccess: boolean) => {
    setIslocationAccess(IslocationAccess);
  };

  const actionOnRow = (item: any) => {
    if (item !== undefined) {
      let validationCountryCodeObject: any = [];
      validationCountryCodeObject.push(item);
      console.log("Selected Item :", item);
      setShowCountryCodeList(false);
      setbuttonTitleValue(`${item.country}(${item.code})`);
      if (item.verificationPriority == "SMS") {
        setisPhoneVerification(true);
        setisEmailVerification(false);
        props.setEmailVerification(false);
      } else {
        setisEmailVerification(true);
        props.setEmailVerification(true);
        setisPhoneVerification(false);
      }
      setValidate(validationCountryCodeObject);
      // setisEmailVerification(item.emailVerification);
      // props.setEmailVerification(item.emailVerification);
      // setisPhoneVerification(item.smsVerification);
      setIslocationAccess(true);
      props.setSelectedCountryObject(item);
      setdropdownVal(item.code.replace(/[\D]/g, ""));
      setUpdateddropdownCountryCode(item.code.replace(/[\D]/g, ""));
      setCountryObject(item);
    }
  };

  const validateEmail = (email: string) => {
    let re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  const handleValidate = (values: any) => {
    let errors: any = {};
    const regex = /^(0*[1-9][0-9]*(\.[0-9]*)?|0*\.[0-9]*[1-9][0-9]*)$/;
    if (isEmailVerification) {
      if (!values.email) {
        errors.email = "Email Id is required";
      }
      if (!validateEmail(values?.email) && values?.email) {
        errors.email = "Email entered is not valid";
      }
    } else {
      if (!values.countryCode) {
        errors.countryCode = "Country code required";
      }
      if (!values.phoneNumber) {
        errors.phoneNumber = "Please enter a valid mobile number";
      }
      if (values?.phoneNumber) {
        if (!regex.test(values?.phoneNumber) && values?.phoneNumber) {
          errors.phoneNumber = "Invalid characters";
        } else if (minLength == maxLength && values?.phoneNumber?.length > 10)
          errors.phoneNumber = "The phone number should be of 10 digits";
        else if (minLength !== maxLength && values?.phoneNumber?.length > 20)
          errors.phoneNumber = `The phone number should be between ${minLength} to ${maxLength} digits.`;
      }
      if (!values.name) {
        errors.name = `Please enter your name`;
      }
    }
    return errors;
  };

  const onChangeCaptchaValue = (value: any) => {
    setCaptchaValue(value);
    setIsCaptchaDisabled(false);
  };

  const captchaHandler = () => {
    // on error from captcha, disable button, display error
    setIsCaptchaDisabled(true);
    toast.error("Something went wrong.");
  };

  return (
    <View>
      {IslocationAccess ? (
        <View style={styles.mainContainer}>
          {/* <Socion /> */}
          <View style={isPhoneVerification ? styles.formContainer : styles.formContainerSecondary}>
            <Text
              fontWeight={FontWeight.Bold}
              testId="signup"
              textSize={TextSize.Large}
              textStyle={styles.heading}
            >
              Sign up
            </Text>
            <View style={styles.buttonContainer}>
              <Text
                fontWeight={FontWeight.Regular}
                testId="internal"
                textSize={TextSize.Small}
                style={[styles.textIcon]}
              >
                {"Select your country code"}
              </Text>
              <Button
                type={ButtonType.Secondary}
                buttonStyles={
                  !showCountryCodeList
                    ? styles.button
                    : [styles.button, { borderBottomRightRadius: 0, borderBottomLeftRadius: 0 }]
                }
                // textStyles={styles.buttonFont}
                title={buttonTitleValue}
                onPress={() => {
                  setShowCountryCodeList(!showCountryCodeList);
                }}
              />
              {showCountryCodeList && (
                <View style={styles.flatListContainer}>
                  <FlatList
                    data={countryCodeList}
                    renderItem={({ item }) => (
                      <TouchableOpacity onPress={() => actionOnRow(item)}>
                        <View style={styles.flatview}>
                          <Text
                            fontWeight={FontWeight.Regular}
                            testId="forgot"
                            textSize={TextSize.Small}
                            textStyle={styles.forgot}
                          >
                            {item.country}({item.code})
                          </Text>
                        </View>
                      </TouchableOpacity>
                    )}
                    showsVerticalScrollIndicator={false}
                    keyExtractor={(item) => item.sequence}
                    extraData={selectedSequence}
                    contentContainerStyle={{}}
                  />
                </View>
              )}
            </View>
            {/* Formik */}
            {/* Formik */}
            {/* Formik */}
            {/* Formik */}
            {isPhoneVerification ? (
              <View style={styles.subFormContainer}>
                <Formik
                  initialValues={{ name: null, countryCode: +91, phoneNumber: null }}
                  onSubmit={(values, { setSubmitting }) => {
                    saveSignup(values);
                    setSubmitting(false);
                  }}
                  // validationSchema={formValidaionSchema}
                  validate={handleValidate}
                  enableReinitialize={false}
                >
                  {(
                    formikProps: FormikProps<{
                      name: string;
                      countryCode: number;
                      phoneNumber: number;
                    }>
                  ) => (
                    <Form>
                      <View style={styles.actionContainer}>
                        <UserInput
                          label=""
                          handleChange={formikProps.handleChange("name")}
                          handleBlur={() => formikProps.setFieldTouched}
                          userStyle={styles.user}
                          icon={IconNames.userName}
                          textValue="Name"
                          name="name"
                          placeholder="Enter name"
                          value={formikProps.values.name}
                          id="name"
                          handleKeyPress={(e: any) => {
                            if (e.key === "Enter") {
                              formikProps.handleSubmit();
                            }
                          }}
                        />
                        <MobileInput
                          label=""
                          dropdownPlaceholder="+91"
                          handleChange={formikProps.handleChange("phoneNumber")}
                          onChangeValue={(value: number) => [
                            (formikProps.values.countryCode = value),
                            formikProps.handleChange("countryCode")
                          ]}
                          handleBlur={() => formikProps.setFieldTouched}
                          // inputStyle={styles.mobile}
                          icon={IconNames.mobile}
                          textValue="Mobile number"
                          name="phoneNumber"
                          placeholder="Enter number"
                          dropdownValue={formikProps.values.countryCode}
                          value={formikProps.values.phoneNumber}
                          id="phoneNumber"
                          handleKeyPress={(e: any) => {
                            if (e.key === "Enter") {
                              formikProps.handleSubmit();
                            }
                          }}
                          dropDownContainerStyle={styles.dropDownContainerStyle}
                          setValidate={setValidate}
                          disableDropDown={true}
                          dropdownVal={dropdownVal}
                          updateddropdownCountryCode={updateddropdownCountryCode}
                        />
                        <ReCAPTCHA
                          sitekey={siteKey}
                          ref={ref}
                          onChange={onChangeCaptchaValue}
                          onExpired={() => setIsCaptchaDisabled(true)}
                          onErrored={captchaHandler}
                        />
                        <Button
                          type={ButtonType.Primary}
                          buttonStyles={styles.signUpButton}
                          title="Get verification code"
                          onPress={() => {
                            formikProps.handleSubmit();
                          }}
                          disabled={isCaptchaDisabled}
                        />
                      </View>
                    </Form>
                  )}
                </Formik>
                <View style={styles.footer}>
                  <Text
                    fontWeight={FontWeight.Regular}
                    testId="forgot"
                    textSize={TextSize.Small}
                    textStyle={styles.forgot}
                  >
                    Already have an account?
                    {"  "}
                    <View>
                      <Text
                        fontWeight={FontWeight.Regular}
                        testId="login"
                        textSize={TextSize.Small}
                        textStyle={styles.login}
                        onPress={() =>
                          history.push(allRoutesNames.IAM.root + allRoutesNames.IAM.login)
                        }
                      >
                        Login
                      </Text>
                    </View>
                  </Text>
                </View>
              </View>
            ) : (
              <View style={styles.subFormContainer}>
                <Formik
                  initialValues={{
                    name: null,
                    countryCode: dropdownVal,
                    phoneNumber: null,
                    email: null
                  }}
                  onSubmit={(values, { setSubmitting }) => {
                    saveSignup(values);
                    setSubmitting(false);
                  }}
                  // validationSchema={formValidaionSchema}
                  validate={handleValidate}
                  enableReinitialize={false}
                >
                  {(
                    formikProps: FormikProps<{
                      name: string;
                      countryCode: number;
                      phoneNumber: number;
                      email: string;
                    }>
                  ) => (
                    <Form>
                      <View style={styles.actionContainer}>
                        <UserInput
                          label=""
                          handleChange={formikProps.handleChange("email")}
                          handleBlur={() => formikProps.setFieldTouched}
                          userStyle={styles.user}
                          icon={IconNames.email}
                          textValue="Email"
                          name="email"
                          placeholder="Enter Email"
                          value={formikProps.values.email}
                          id="Email"
                          handleKeyPress={(e: any) => {
                            if (e.key === "Enter") {
                              formikProps.handleSubmit();
                            }
                          }}
                        />
                        <UserInput
                          label=""
                          handleChange={formikProps.handleChange("name")}
                          handleBlur={() => formikProps.setFieldTouched}
                          userStyle={styles.user}
                          icon={IconNames.userName}
                          textValue="Name"
                          name="name"
                          placeholder="Enter name"
                          value={formikProps.values.name}
                          id="name"
                          handleKeyPress={(e: any) => {
                            if (e.key === "Enter") {
                              formikProps.handleSubmit();
                            }
                          }}
                        />
                        <MobileInput
                          label=""
                          dropdownPlaceholder="+91"
                          handleChange={formikProps.handleChange("phoneNumber")}
                          onChangeValue={(value: number) => [
                            (formikProps.values.countryCode = value),
                            formikProps.handleChange("countryCode")
                          ]}
                          handleBlur={() => formikProps.setFieldTouched}
                          // inputStyle={styles.mobile}
                          icon={IconNames.mobile}
                          textValue="Mobile number"
                          name="phoneNumber"
                          placeholder="Enter number"
                          dropdownValue={formikProps.values.countryCode}
                          value={formikProps.values.phoneNumber}
                          id="phoneNumber"
                          handleKeyPress={(e: any) => {
                            if (e.key === "Enter") {
                              formikProps.handleSubmit();
                            }
                          }}
                          dropDownContainerStyle={styles.dropDownContainerStyle}
                          setValidate={setValidate}
                          disableDropDown={true}
                          dropdownVal={dropdownVal}
                          updateddropdownCountryCode={updateddropdownCountryCode}
                        />
                        <ReCAPTCHA
                          sitekey={siteKey}
                          ref={ref}
                          onChange={onChangeCaptchaValue}
                          onExpired={() => setIsCaptchaDisabled(true)}
                          onErrored={captchaHandler}
                        />
                        <Button
                          type={ButtonType.Primary}
                          buttonStyles={styles.signUpButton}
                          title="Get verification code"
                          onPress={() => {
                            formikProps.handleSubmit();
                          }}
                          disabled={isCaptchaDisabled}
                        />
                      </View>
                    </Form>
                  )}
                </Formik>
                <View style={styles.footer}>
                  <Text
                    fontWeight={FontWeight.Regular}
                    testId="forgot"
                    textSize={TextSize.Small}
                    textStyle={styles.forgot}
                  >
                    Already have an account?
                    {"  "}
                    <View>
                      <Text
                        fontWeight={FontWeight.Regular}
                        testId="login"
                        textSize={TextSize.Small}
                        textStyle={styles.login}
                        onPress={() =>
                          history.push(allRoutesNames.IAM.root + allRoutesNames.IAM.login)
                        }
                      >
                        Login
                      </Text>
                    </View>
                  </Text>
                </View>
              </View>
            )}
          </View>
        </View>
      ) : (
        <View style={[styles.mainContainer, { height: "90vh" }]}>
          {/* <Socion /> */}
          <View style={isPhoneVerification ? styles.formContainer : styles.formContainerSecondary}>
            <Text
              fontWeight={FontWeight.Bold}
              testId="signup"
              textSize={TextSize.Large}
              textStyle={styles.heading}
            >
              Sign up
            </Text>
            <View style={styles.buttonContainer}>
              <Text
                fontWeight={FontWeight.Regular}
                testId="internal"
                textSize={TextSize.Small}
                style={[styles.textIcon]}
              >
                {"Select your country code"}
              </Text>
              <Button
                type={ButtonType.Secondary}
                buttonStyles={
                  !showCountryCodeList
                    ? styles.button
                    : [styles.button, { borderBottomRightRadius: 0, borderBottomLeftRadius: 0 }]
                }
                // textStyles={styles.buttonFont}
                title={buttonTitleValue}
                onPress={() => {
                  setShowCountryCodeList(!showCountryCodeList);
                }}
              />
              {showCountryCodeList && (
                <View style={styles.flatListContainer}>
                  <FlatList
                    data={countryCodeList}
                    renderItem={({ item }) => (
                      <TouchableOpacity onPress={() => actionOnRow(item)}>
                        <View style={styles.flatview}>
                          <Text
                            fontWeight={FontWeight.Regular}
                            testId="forgot"
                            textSize={TextSize.Small}
                            textStyle={styles.forgot}
                          >
                            {item.country}({item.code})
                          </Text>
                        </View>
                      </TouchableOpacity>
                    )}
                    showsVerticalScrollIndicator={false}
                    keyExtractor={(item) => item.sequence}
                    extraData={selectedSequence}
                    contentContainerStyle={{}}
                  />
                </View>
              )}
            </View>
          </View>
        </View>
      )}
    </View>
  );
});

const styles = StyleSheet.create({
  mainContainer: {
    display: "flex",
    alignItems: "center",
    marginTop: 30
  },
  formContainer: {
    width: "35%",
    height: "90vh",
    backgroundColor: colorPallete.white,
    position: "absolute",
    // right: 0,
    // borderBottomLeftRadius: 20,
    // borderTopLeftRadius: 20
    borderRadius: 20,
    border: "1px solid rgb(194, 188, 185)"
  },
  formContainerSecondary: {
    width: "35%",
    height: "110vh",
    backgroundColor: colorPallete.white,
    position: "absolute",
    // right: 0,
    // borderBottomLeftRadius: 20,
    // borderTopLeftRadius: 20
    borderRadius: 20,
    border: "1px solid rgb(194, 188, 185)"
  },
  heading: {
    marginTop: 50,
    marginLeft: 80,
    fontSize: 40
  },
  button: {
    marginTop: 0,
    height: 50,
    width: 304,
    zIndex: -1,
    borderRadius: 10
  },
  signUpButton: {
    marginTop: 15,
    height: 50,
    width: 304,
    zIndex: -1,
    borderRadius: 10
  },
  actionContainer: {
    marginTop: 50,
    marginLeft: 80
  },
  user: {
    marginBottom: 20
  },
  forgot: {
    fontWeight: "400",
    fontSize: 12,
    position: "relative",
    zIndex: -1
  },
  footer: {
    marginStart: 155,
    zIndex: -1,
    marginVertical: 40
  },
  login: {
    fontWeight: "400",
    fontSize: 12,
    position: "relative",
    zIndex: -1,
    textDecorationLine: "underline",
    color: colorPallete.cordioRed
  },
  subFormContainer: {
    zIndex: -1,
    marginTop: 50
  },
  dropDownContainerStyle: {
    marginTop: 10,
    width: 303,
    zIndex: 10
  },
  flatview: {
    paddingTop: 0,
    borderRadius: 2,
    paddingLeft: 10
  },
  buttonContainer: {
    marginLeft: 80,
    position: "absolute",
    top: 90
  },
  flatListContainer: {
    borderWidth: 1,
    height: 300,
    width: 304,
    borderTopWidth: 0,
    borderRadius: 0,
    top: -3,
    borderTopLeftRadius: 0,
    borderBottomRightRadius: 0,
    borderColor: colorPallete.cordioRed,
    backgroundColor: colorPallete.white
  },
  textIcon: {
    color: colorPallete.textLight,
    marginBottom: 10,
    marginTop: 5
  }
});
export default SignupForm;
