import { call, put, takeLatest } from "redux-saga/effects";
import { ARTEFACTS } from "@socion-cordio/common/src/modules/artefacts/actions/actionTypes";
import { IFluxStandardAction } from "store/interfaces";
import { Artefact } from "models/artefact";
import { ArtefactRepository } from "@socion-cordio/common/src/repositories/artefact/artefact";
import { ArtefactActions } from "../actions/action";

function* getArtefactWorkerSaga(value: IFluxStandardAction<{ pid: number }>) {
  try {
    const { payload } = value;
    const response: Artefact = yield call(ArtefactRepository.getArtefacts, payload);
    yield put(ArtefactActions.getArtefactssSuccess(response));
  } catch (error) {
    yield put(ArtefactActions.getArtefactsError(error));
  }
}

export default function* ArtefactWatcherSaga() {
  yield takeLatest(ARTEFACTS.GET_ARTEFACTS.LOADING, getArtefactWorkerSaga);
}
