import React, { ReactElement, useEffect, useState } from "react";
import { StyleSheet, View } from "react-native";
import { colorPallete } from "@socion-cordio/common/src/assets/styles/colors";
import {
  Text,
  TextSize,
  FontWeight,
  FontFamily
} from "@socion-cordio/common/src/components/atoms/text";
import { useSelector, useDispatch } from "react-redux";
import UserInput from "@socion-cordio/common/src/components/molecules/userInput";
import { IconNames } from "@socion-cordio/common/src/components/atoms/icon";
import ProfileConfirmationModal from "@socion-cordio/common/src/components/organisms/common-modals/profileConfirmationModal";
import SocionModal from "@socion-cordio/common/src/components/atoms/modal";
import { Switch } from "@socion-cordio/common/src/components/atoms/switches";
import Modal from "@socion-cordio/common/src/components/atoms/modal";
import AddBaseLocation from "@socion-cordio/common/src/components/organisms/addBaseLocation";
import { profileEndPoints } from "@socion-cordio/common/src/repositories/endPoints";
import { ApiClient } from "@socion-cordio/common/src/network/apiClient";
import { HTTP_STATUS_CODES } from "@socion-cordio/common/src/network/constants";
import { toast, ToastContainer } from "react-toastify";
import { ProfileActions } from "@socion-cordio/common/src/modules/profile/actions/actions";
import { auxiliaryMethods } from "@socion-cordio/common/src/utils/auxiliaryMethods";
import Moment from "moment";
import AddTelemetryService from "@socion-cordio/common/src/services/telemetryService";
import { LocalStorage } from "@socion-cordio/common/src/services/storage/storageService";
import axios from "axios";
import { CONFIG } from "@socion-cordio/common/src/config/envConstant";
type Props = {
  setIsLocationEnabled?: Function;
  isLocationEnabled?: boolean;
  setShowRightPanel?: Function;
};

export default function LocationInfo(props: Props): ReactElement {
  const { isLocationEnabled, setIsLocationEnabled, setShowRightPanel } = props;
  const [userProfileData, setUserProfileData] = useState(null);
  const [baseLocation, setBaseLocation] = useState("");
  const [toggled, setToggled] = useState(false);
  const dispatch = useDispatch();
  const data: any = useSelector((state) => state);
  const [baseLocationModalVisible, setBaseLocationModalVisible] = useState(false);
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [locationConsentOnToggle, setLocationConsentOnToggle] = useState(false);

  /**
   * Method to get auth token from map my india
   */
  const getAuthTokenFromMapMyIndia = async () => {
    // console.log(CONFIG.MAP_MY_INDIA_CLIENT_SECRET)
    const MAP_MY_INDIA_CLIENT_SECRET = CONFIG.REACT_APP_MAP_MY_INDIA_CLIENT_SECRET;
    const MAP_MY_INDIA_CLIENT_ID = CONFIG.REACT_APP_MAP_MY_INDIA_CLIENT_ID;
    const payload = `grant_type=client_credentials&client_id=${MAP_MY_INDIA_CLIENT_ID}&client_secret=${MAP_MY_INDIA_CLIENT_SECRET}`;
    const headers = {
      accept: "application/json",
      "Content-Type": "application/x-www-form-urlencoded"
    };

    // const getAuthResponse = await axios.post(
    //   "https://outpost.mapmyindia.com/api/security/oauth/token",
    //   payload,
    //   { headers: headers }
    // );
  };

  const loadScript = (token: string) => {
    const script = document.createElement("script");
    script.src = `https://apis.mapmyindia.com/advancedmaps/api/${token}/map_sdk_plugins`;
    script.async = true;
    document.body.appendChild(script);
  };

  const getAccessToken = async () => {
    try {
      const response = await ApiClient.get(profileEndPoints.getMapMyIndiaToekn());
      const { access_token } = response?.response;
      loadScript(access_token);
    } catch (error) {
      toast.error("Something went wrong.");
    }
  };

  useEffect(() => {
    getAuthTokenFromMapMyIndia();
    // loadScript();
    getAccessToken();
  }, []);

  const processUserProfileData = () => {
    if (data?.profile?.userProfileData?.responseCode === 200) {
      setUserProfileData(data?.profile?.userProfileData.response);
      const formattedBaseLocation = auxiliaryMethods.fromatBaseLocation(
        data?.profile?.userProfileData.response
      );
      setBaseLocation(formattedBaseLocation);
      setIsLocationEnabled(data?.profile?.userProfileData?.response?.baseLocation);
      setShowRightPanel(data?.profile?.userProfileData?.response?.baseLocation);
    }
  };

  const handleToggle = () => {
    updateTelemetryConsent(!isLocationEnabled);
    if (!isLocationEnabled === false) {
      setConfirmationModal(true);
    }
    !isLocationEnabled && setBaseLocationModalVisible(true);
  };

  const revokeLocationConsent = async () => {
    // api call to reovke consent
    const revokeLocationConsentResponse = await ApiClient.delete(
      profileEndPoints.revokeLocationConsent()
    );
    if (revokeLocationConsentResponse.responseCode === HTTP_STATUS_CODES.ok) {
      toast.success(revokeLocationConsentResponse.message);
      dispatch(ProfileActions.getUserProfile());
      console.log(revokeLocationConsentResponse);
      const user: any = await LocalStorage.getStorage("user");
      user.city = "";
      user.country = "";
      user.district = "";
      user.state = "";
      user.subDistrict = "";
      user.establishmentName = "";
      user.baseLocationLat = "";
      user.baseLocationLong = "";
      LocalStorage.setStorage("user", user);
    } else {
      toast.error(revokeLocationConsentResponse.message);
    }
  };

  const updateTelemetryConsent = async (isLocationEnabled: boolean) => {
    // let eventType = "";
    // isLocationEnabled === true
    //   ? (eventType = "Consent Location")
    //   : (eventType = "Withdraw Consent Location");
    const user: any = await LocalStorage.getStorage("user");
    isLocationEnabled === true
      ? setLocationConsentOnToggle(true)
      : setLocationConsentOnToggle(false);

    if (isLocationEnabled === false) {
      const body = {
        createdAt: Moment(),
        deleted: "false",
        updatedAt: Moment(),
        eventType: "Withdraw Consent Location",
        userId: user?.userId,
        baseLocationCity: "",
        baseLocationDistrict: "",
        baseLocationSubDistrict: "",
        baseLocationState: "",
        baseLocationCountry: "",
        baseLocationLatitude: "",
        baseLocationLongitude: "",
        establishmentName: ""
      };
      AddTelemetryService(body, undefined, undefined);
    }
  };

  const updateTelemetryBaseLocationChange = async () => {
    const user: any = await LocalStorage.getStorage("user");
    const body = {
      createdAt: Moment(),
      deleted: "false",
      updatedAt: Moment(),
      eventType: "Base Location Change"
    };
    AddTelemetryService(body, undefined, user);
  };

  useEffect(() => {
    processUserProfileData();
  }, [data?.profile?.userProfileData.response]);

  const handleConfirm = (): any => {
    setIsLocationEnabled((isLocationEnabled: boolean) => !isLocationEnabled);
    revokeLocationConsent();
    setConfirmationModal(false);
    isLocationEnabled && setShowRightPanel(false);
  };

  return (
    <>
      <View style={styles.location}>
        {/* <Text
          fontWeight={FontWeight.Bold}
          testId="signup"
          textSize={TextSize.Regular}
          style={styles.textStyle}
        >
          Location
        </Text> */}
        <View style={styles.headerContainer}>
          <Text
            fontWeight={FontWeight.Regular}
            testId="addRoleText"
            textSize={TextSize.Small}
            textStyle={styles.headerText}
          >
            Location
          </Text>
        </View>

        <View>
          <UserInput
            userStyle={styles.customInput}
            input={
              baseLocation.length > 71
                ? [styles.input, styles.inputSecondary]
                : [styles.input, styles.inputBaseLocation]
            }
            textIcon={styles.textIcon}
            // input={styles.input}
            view={styles.view}
            labelStyle={styles.label}
            textField={styles.textField}
            iconStyle={styles.icon}
            label=""
            handleChange={() => {}}
            handleBlur={() => {}}
            icon={IconNames.baseLocation}
            textValue="Base Location"
            name="baseLocation"
            placeholder="Base Location"
            value={baseLocation}
            id="baseLocation"
            noFormik
            editable={isLocationEnabled ? false : null}
            multiline={true}
            onPress={() => setBaseLocationModalVisible(true)}
          />

          {baseLocationModalVisible && (
            <Modal
              modalVisible={baseLocationModalVisible}
              setModalVisible={() => setBaseLocationModalVisible(!baseLocationModalVisible)}
              component={
                <View style={styles.modalContainer}>
                  <AddBaseLocation
                    currentBaseLocation={baseLocation}
                    onClose={() => setBaseLocationModalVisible(false)}
                    setShowRightPanel={setShowRightPanel}
                    setIsLocationEnabled={setIsLocationEnabled}
                    isLocationEnabled={isLocationEnabled}
                    locationConsentOnToggle={locationConsentOnToggle}
                  />
                </View>
              }
            />
          )}
        </View>
        {userProfileData?.establishmentName && (
          <View>
            <UserInput
              userStyle={styles.customInput}
              textIcon={styles.textIcon}
              // input={styles.input}
              input={
                baseLocation.length > 41
                  ? [styles.input, styles.inputEstablismentNameSecondary]
                  : [styles.input, styles.inputEstablismentName]
              }
              view={styles.view}
              labelStyle={styles.label}
              textField={styles.textField}
              iconStyle={styles.icon}
              label=""
              handleChange={() => {}}
              handleBlur={() => {}}
              icon={IconNames.establishment}
              textValue="Establishment"
              name="establishment"
              placeholder="Establishment"
              value={userProfileData?.establishmentName}
              id="establishment"
              multiline={true}
              noFormik
            />
          </View>
        )}
      </View>
      <View style={styles.consent}>
        <View style={{ flex: 30 }}>
          <Switch
            isOn={isLocationEnabled}
            onColor="#B7504A"
            offColor="#F1DCDB"
            labelStyle={{ color: "black", fontWeight: "900" }}
            size="medium"
            onToggle={handleToggle}
          />
        </View>
        <View style={{ flex: 72 }}>
          <Text
            fontWeight={FontWeight.Bold}
            testId="signup"
            textSize={TextSize.Regular}
            style={styles.consentText}
          >
            {isLocationEnabled
              ? "You can withdraw consent to share your base location information with the program(s) you are associated with."
              : "You can grant consent to share your base location information with the program(s) you are associated with."}
          </Text>
        </View>
      </View>
      {confirmationModal && (
        <View>
          <SocionModal
            modalVisible={confirmationModal}
            setModalVisible={() => setConfirmationModal(!confirmationModal)}
            component={
              <ProfileConfirmationModal
                onClose={() => setConfirmationModal(false)}
                handleConfirm={handleConfirm}
                message="Are you sure you want to withdraw your consent to share Base Location information?"
              />
            }
          />
        </View>
      )}
    </>
  );
}

const styles = StyleSheet.create({
  consent: {
    display: "flex",
    flexDirection: "row",
    // justifyContent: "space-between",
    marginTop: "1rem"
  },
  consentText: {
    flex: 0.8,
    color: colorPallete.textLight,
    fontFamily: FontFamily.Regular,
    fontSize: 12
  },
  location: {
    marginTop: 25
  },
  textStyle: {
    fontWeight: "700",
    fontSize: 14
  },
  customInput: {
    flexDirection: "row",
    flex: 100,
    display: "flex"
  },
  input: {
    marginRight: 8,
    fontFamily: FontFamily.Medium,
    fontSize: 12,
    // fontWeight: FontWeight.Bold,
    color: colorPallete.textBlack,
    width: 260,
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: colorPallete.lightGreyThree
  },
  inputSecondary: {
    paddingVertical: 10,
    minHeight: 70
  },
  inputBaseLocation: {
    paddingTop: 5
  },
  inputEstablismentName: {
    paddingVertical: 10,
    minHeight: 50
  },
  inputEstablismentNameSecondary: {
    paddingTop: 10
  },
  view: {
    zIndex: -1,
    display: "flex",
    flexDirection: "row",
    marginTop: "0.5rem",
    justifyContent: "space-between"
  },
  label: {
    flex: 30,
    marginBottom: 8,
    marginLeft: 0
  },
  textField: {
    flex: 72,
    flexDirection: "row"
  },
  icon: {
    flexDirection: "row",
    color: colorPallete.textLight,
    fontSize: 17
  },
  textIcon: {
    fontSize: 12,
    marginLeft: 10,
    fontFamily: FontFamily.Regular
  },
  headerText: {
    fontSize: 12,
    fontFamily: FontFamily.Bold,
    lineHeight: 17,
    fontWeight: "700"
  },
  headerContainer: {
    marginBottom: 5
  },
  modalContainer: {
    width: 655
  }
});
