import React, { useEffect, useState } from "react";
import { StyleSheet, TouchableOpacity, View } from "react-native";
import { colorPallete } from "@socion-cordio/common/src/assets/styles/colors";
import {
  FontFamily,
  FontWeight,
  Text,
  TextSize
} from "@socion-cordio/common/src/components/atoms/text";
import { Image } from "@socion-cordio/common/src/components/atoms/image";
import { getImage } from "../helpers/artefactHelper";
import { useHistory } from "react-router-dom";
import { allRoutesNames as routeNames } from "navigation/allRouteNames";
import Icon, { IconNames } from "@socion-cordio/common/src/components/atoms/icon";
import { ArtefactRepository } from "@socion-cordio/common/src/repositories/artefact/artefact";

const ArtefactDocs = (props: any) => {
  const [artefactDocs, setArtefactDocs] = useState([]);
  const history: any = useHistory();

  useEffect(() => {
    setCookie();
  }, []);

  const setCookie = async () => {
    const res = await ArtefactRepository.setCookie().catch((err) => {
      console.log("Error", res);
      // setIsLoading(false);
    });
  };

  useEffect(() => {
    setArtefactDocs(props?.docs || []);
  }, [props]);

  const handleDeleteDocument = (doc: any, index: number) => {
    props.deleteDoc({ doc, index });
  };

  const handleDownloadDocument = (doc: any) => {
    props.downloadDoc(doc);
  };

  const routePdfView = async (doc: any) => {
    const type =
      props?.type === "artefact" || props?.type === "reviewartefact"
        ? routeNames.VIEW_ARTEFACT_PDF
        : routeNames.VIEW_ARTEFACT_TYPE_PDF;
    // history.push({
    //   pathname: `${routeNames.app}${type}/${props.id}`,
    //   search: `?pdfUrl=${doc?.artefact_url || doc?.template_url}`
    // });

    window.open(
      `${routeNames.app}${type}/${props.id}?pdfUrl=${doc?.artefact_url || doc?.template_url}`,
      "_blank"
    );
  };

  const getFileName = (url: any) => {
    let name: any = url.split("/")[url.split("/").length - 1];
    name = name.split("_");
    name.shift();
    return name.join("_");
  };

  const getDocData = (doc: any, index: number, type: string, docs: Array<any>) => {
    return (
      <View
        style={
          props.isView && props.type !== "reviewartefact"
            ? [styles.qualificationContainer, { borderColor: colorPallete.cordioGreen }]
            : [styles.qualificationContainer]
        }
        key={index}
      >
        <View style={{ width: "90%" }}>
          <TouchableOpacity
          // onPress={() => {
          //   routePdfView(doc);
          // }}
          >
            <View style={styles.imageText}>
              {doc?.mimetype && (
                <Image
                  testId="edit"
                  source={getImage(doc?.mimetype)}
                  imageStyle={{
                    width: 14,
                    height: 14,
                    marginRight: "10px"
                  }}
                />
              )}
              {docs.length > 0 && (
                <a
                  href={doc?.artefact_url || doc?.template_url}
                  target="_blank"
                  // download={getFileName(doc?.artefact_url || doc?.template_url)}
                  // {(data: any) => {
                  //   console.log("fbdsngbdnsgbf nbfg", data)
                  // }}
                >
                  <Text
                    fontWeight={FontWeight.Regular}
                    testId="qualificationTitleText"
                    textSize={TextSize.Small}
                    textStyle={styles.qualificationTitle}
                    // onPress={(e: any) => {
                    //   routePdfView(doc);
                    // }}
                  >
                    {/* <img src={doc?.artefact_url || doc?.template_url}/> */}
                    {doc?.display_filename || doc?.fileName || doc?.display_name}
                  </Text>
                </a>
              )}
            </View>
          </TouchableOpacity>
        </View>
        <View
          style={{
            width: "10%",
            display: "flex",
            flexDirection: "row",
            alignItems: "flex-end",
            justifyContent: "space-around"
          }}
        >
          {props?.type === "artefactType" && (
            <TouchableOpacity
              onPress={() => {
                handleDownloadDocument(doc);
              }}
              style={{ padding: 3 }}
            >
              <Icon
                name={IconNames.download}
                customStyle={[styles.iconStyle, styles.alignIcon]}
                onPress={() => {
                  handleDownloadDocument(doc);
                }}
              />
            </TouchableOpacity>
          )}

          {!props.isView && (
            <TouchableOpacity
              onPress={() => {
                handleDeleteDocument(doc, index);
              }}
              style={{ padding: 3 }}
            >
              <Image
                testId="remove"
                source={require(`@socion-cordio/common/src/assets/images/remove.svg`)}
                imageStyle={{
                  width: 14,
                  height: 14
                }}
              />
            </TouchableOpacity>
          )}
        </View>
      </View>
    );
  };

  return (
    <>
      {artefactDocs &&
        artefactDocs.length > 0 &&
        artefactDocs.map((doc: any, index: number) => {
          return getDocData(doc, index, "doc", artefactDocs);
        })}
    </>
  );
};

const styles = StyleSheet.create({
  qualificationContainer: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row",
    alignItems: "center",
    borderRadius: 5,
    height: "64px",
    width: "100%",
    marginRight: "5px",
    padding: 15,
    marginTop: 20,
    borderColor: colorPallete.cordioTaupe,
    borderStyle: "solid",
    borderWidth: 1
  },
  qualificationTitle: {
    // fontWeight: "700",
    fontSize: 12,
    fontFamily: FontFamily.Light,
    color: colorPallete.blue,
    lineHeight: 17,
    textDecorationLine: "underline",
    width: "100%"
  },
  uploadHeaderText: {
    fontWeight: "400",
    fontSize: 12,
    fontFamily: FontFamily.Regular,
    lineHeight: 17,
    color: colorPallete.textLight
  },
  uploadDocIcon: {
    opacity: 0.5
  },
  imageText: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    width: "100%"
  },
  loaderContiner: {
    width: 655,
    height: 320
  },
  iconStyle: {
    fontSize: 17,
    color: colorPallete.cordioTaupe
  },
  alignIcon: {
    marginRight: 10
  }
});
export default ArtefactDocs;
