import { Program } from "@socion-cordio/common/src/models/program";
import { EntityInvite } from "@socion-cordio/common/src/models/entityInvite";
import {
  PROGRAMS,
  SAVE_PROGRAM_DETAILS
} from "@socion-cordio/common/src/modules/program/actions/actionTypes";

export interface IProgramLoading {
  getPrograms: boolean;
  getEntityInvite: boolean;
}

export interface IProgramError {
  getPrograms: string;
  getEntityInvite: string;
}
export interface IProgramState {
  programsData: Program;
  entityInviteData: EntityInvite;
  error: IProgramError;
  loading: IProgramLoading;
  programDetails: object;
}

export const programsInitialState: IProgramState = {
  programsData: null,
  entityInviteData: null,
  error: {
    getPrograms: "",
    getEntityInvite: ""
  },
  loading: { getPrograms: false, getEntityInvite: false },
  programDetails: null
};

const programsReducer = (
  state = programsInitialState,
  action: { payload: any; type: string }
): IProgramState => {
  switch (action.type) {
    case PROGRAMS.GET_PROGRAMS.LOADING:
      return {
        ...state,
        loading: { ...state.loading, getPrograms: true },
        error: { ...state.error, getPrograms: null }
      };
    case PROGRAMS.GET_PROGRAMS.SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, getPrograms: false },
        error: { ...state.error, getPrograms: null },
        programsData: action.payload
      };
    case PROGRAMS.GET_PROGRAMS.ERROR:
      return {
        ...state,
        loading: { ...state.loading, getPrograms: false },
        error: { ...state.error, getPrograms: action.payload }
      };
    case SAVE_PROGRAM_DETAILS:
      return {
        ...state,
        programDetails: action.payload
      };

    case PROGRAMS.GET_ENTITY_INVITE.LOADING:
      return {
        ...state,
        loading: { ...state.loading, getEntityInvite: true },
        error: { ...state.error, getEntityInvite: null }
      };
    case PROGRAMS.GET_ENTITY_INVITE.SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, getEntityInvite: false },
        error: { ...state.error, getEntityInvite: null },
        entityInviteData: action.payload
      };
    case PROGRAMS.GET_ENTITY_INVITE.ERROR:
      return {
        ...state,
        loading: { ...state.loading, getEntityInvite: false },
        error: { ...state.error, getEntityInvite: action.payload }
      };

    default:
      return state;
  }
};
export default programsReducer;
