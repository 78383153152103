import React, { ReactElement, useEffect, useState } from "react";
import { StyleSheet, View } from "react-native";
import { toast } from "react-toastify";
import {
  Text,
  TextSize,
  FontWeight,
  FontFamily
} from "@socion-cordio/common/src/components/atoms/text";
import Icon, { IconNames } from "@socion-cordio/common/src/components/atoms/icon";
import { HTTP_STATUS_CODES } from "@socion-cordio/common/src/network/constants";
import { ApiClient } from "@socion-cordio/common/src/network/apiClient";
import { qualificationPackEndPoints } from "@socion-cordio/common/src/repositories/endPoints";
import Button, { ButtonType } from "@socion-cordio/common/src/components/atoms/button";
import UserInputSecondary from "@socion-cordio/common/src/components/molecules/userInputSecondary";
import UserInputTextArea from "@socion-cordio/common/src/components/molecules/userInputTextArea";
import { colorPallete } from "@socion-cordio/common/src/assets/styles/colors";
import AddTelemetryService from "@socion-cordio/common/src/services/telemetryService";
import { LocalStorage } from "@socion-cordio/common/src/services/storage/storageService";
import Moment from "moment";
interface Props {
  onClose: Function;
  getBadgesTable: Function;
  qualificationPackList: any;
}

export default function AddQualificationPackModal(props: Props): ReactElement {
  const { qualificationPackList } = props;
  const [qualificationPackName, setQualificationPackName] = useState("");
  const [packDescription, setPackDescription] = useState("");
  const [isNameExist, setIsNameExist] = useState(false);
  const programDetails: any = JSON.parse(localStorage.getItem("programDetails"));

  const handleSubmit = async () => {
    try {
      const body = {
        name: qualificationPackName,
        description: packDescription,
        programId: programDetails.program.id
      };
      const addResponse = await ApiClient.post(
        qualificationPackEndPoints.addQualificationPack(),
        body
      );
      if (addResponse.responseCode === HTTP_STATUS_CODES.ok) {
        props.getBadgesTable();
        props.onClose();
        toast.success("Qualification Pack added successfully");
        updateTelemetry(addResponse?.response);
      }
    } catch (error) {
      toast.error("Something went wrong");
      setIsNameExist(false);
    }
  };

  const updateTelemetry = async (data: any) => {
    const programDetails: any = await LocalStorage.getStorage("programDetails");
    const user: any = await LocalStorage.getStorage("user");
    const body: any = {
      timestamp: Moment().toLocaleString(),
      createdAt: Moment().toLocaleString(),
      deleted: "false",
      updatedAt: Moment().toLocaleString(),
      eventType: "QP Added",
      noOfSelections: 0,
      bundleId: data.id,
      bundleName: data.name,
      entityId: null,
      entityName: null
    };
    AddTelemetryService(body, programDetails, user, true);
  };

  const submitHandlerCheck = () => {
    const qpNames = qualificationPackList?.map((item: any) => item.bundle.name);
    const value = qpNames.includes(qualificationPackName);
    setIsNameExist(value);
    !value ? handleSubmit() : null;
  };

  return (
    <View>
      <View style={styles.container}>
        <View style={styles.header}>
          <Text
            fontWeight={FontWeight.Bold}
            testId="addqualification"
            textSize={TextSize.Small}
            textStyle={styles.headerText}
          >
            Add qualification pack
          </Text>
          <View>
            <Icon
              testID="close"
              name={IconNames.crossCircle}
              customStyle={styles.crossCircle}
              onPress={() => {
                props.onClose();
              }}
            />
          </View>
        </View>
        <View style={styles.alignContainer}>
          <View style={styles.userInputContainer}>
            <UserInputSecondary
              textValue="Qualification pack name"
              handleChange={(qualificationPackName: string) => {
                [setQualificationPackName(qualificationPackName), setIsNameExist(false)];
              }}
              handleBlur={() => {}}
              value={qualificationPackName}
              placeholder="Enter name"
              name="enterQualificationName"
              id="enterQualificationName"
              noFormik
              userStyle={[styles.inputStylingAlignment, styles.fontSize12]}
              userStyleText={[styles.textHeaderField, { marginTop: 5 }]}
              textInputContainerStyles={styles.textInputContainerStyles}
            />
            {isNameExist && (
              <View style={styles.errorContainer}>
                <Text
                  fontWeight={FontWeight.Regular}
                  testId="internal"
                  textSize={TextSize.Small}
                  textStyle={[styles.subheaderText, styles.errorText]}
                >
                  {`Qualification Pack ${qualificationPackName} already exists!`}
                </Text>
              </View>
            )}
          </View>
        </View>

        <View style={styles.alignContainer}>
          <UserInputTextArea
            textValue="Description"
            label="Description"
            placeholder="Write a description"
            name="Description"
            id="description"
            handleChange={(description: string) => {
              setPackDescription(description);
            }}
            handleBlur={() => {}}
            value={packDescription}
            numberOfLines={5}
            multiline={true}
            noFormik
            userStyle={[styles.inputStylingAlignment]}
            userStyleText={styles.textHeaderField}
          />
        </View>
        <View style={[styles.alignButtonContainer]}>
          <Button
            type={ButtonType.Primary}
            buttonStyles={styles.submitbutton}
            title="Create"
            onPress={() => {
              submitHandlerCheck();
            }}
            textStyles={styles.fontSize12}
            disabled={qualificationPackName === "" || packDescription === "" || isNameExist}
          />
        </View>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    width: 655
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row"
  },
  headerText: {
    fontSize: 12,
    fontFamily: FontFamily.Medium,
    lineHeight: 17
  },
  fontSize12: {
    fontSize: 12
  },
  headerAlignment: {
    color: colorPallete.textLight,
    fontFamily: FontFamily.Regular
  },
  crossCircle: {
    fontSize: 15
  },
  alignContainer: {
    marginTop: 23
  },
  userInputContainer: {
    flexDirection: "column"
  },
  textHeaderField: {
    flex: 10,
    marginRight: 20,
    fontSize: 12
  },
  inputStylingAlignment: {
    width: "100%",
    fontSize: 12
  },
  textInputContainerStyles: {
    marginLeft: 6
  },
  alignTextWidth: {
    flex: 15
  },
  alignContent: {
    flexDirection: "row",
    alignItems: "center",
    marginTop: 20
  },
  alignInnerContent: {
    flexDirection: "row",
    flex: 50
  },
  alignButtonContainer: {
    marginTop: 30,
    marginBottom: 30,
    justifyContent: "center",
    alignSelf: "center"
  },
  submitbutton: {
    width: "140px",
    height: "50px",
    borderRadius: 10
  },
  alignButton: {
    fontSize: 15,
    color: colorPallete.cordioTaupe,
    marginLeft: 15
  },
  radioButtonContainer: {
    flex: 50
  },

  // Error container
  errorContainer: {
    // marginLeft: 90
    position: "absolute",
    top: 52,
    left: 105
  },
  subheaderText: {
    fontWeight: "400",
    fontSize: 12,
    fontFamily: FontFamily.Regular,
    lineHeight: 17,
    color: colorPallete.textLight
  },
  errorText: {
    marginTop: 2,
    justifyContent: "center",
    color: colorPallete.cordioRed,
    fontSize: 12,
    marginRight: 0
  }
});
