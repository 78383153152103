import { call, put, takeLatest } from "redux-saga/effects";
import { ARTEFACTS } from "@socion-cordio/common/src/modules/artefacts/actions/actionTypes";
import { IFluxStandardAction } from "store/interfaces";
import { ArtefactRepository } from "@socion-cordio/common/src/repositories/artefact/artefact";
import { ArtefactTypesActions } from "../actions/action";
import { ArtefactTypes } from "models/artefactTypes";
import { ARTEFACT_TYPES } from "../actions/actionTypes";

function* getArtefactTypesWorkerSaga(value: IFluxStandardAction<{ pid: number }>) {
  try {
    const { payload } = value;
    const response: ArtefactTypes = yield call(ArtefactRepository.getArtefactTypes, payload);
    yield put(ArtefactTypesActions.getArtefactTypesSuccess(response));
  } catch (error) {
    yield put(ArtefactTypesActions.getArtefactTypesError(error));
  }
}

export default function* ArtefactTypesWatcherSaga() {
  yield takeLatest(ARTEFACT_TYPES.GET_ARTEFACT_TYPES.LOADING, getArtefactTypesWorkerSaga);
}
