import React from "react";
import { StyleProp, StyleSheet, View, ViewStyle } from "react-native";
import { Image } from "@socion-cordio/common/src/components/atoms/image";

interface Props {
  customLoadingContainer?: StyleProp<ViewStyle>;
}

const Loader = (props: Props) => {
  const { customLoadingContainer } = props;
  return (
    <View style={[styles.loadingContainer, customLoadingContainer]}>
      <Image
        testId="loadingImg"
        source={require("@socion-cordio/common/src/assets/images/loader.svg")}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  loadingContainer: {
    height: "calc(100vh - 100px)",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center"
  }
});

export default Loader;
