import React, { ReactElement, useEffect, useState, useMemo, Fragment } from "react";
import { View, StyleSheet, TouchableOpacity } from "react-native";
import {
  Text,
  TextSize,
  FontWeight,
  FontFamily
} from "@socion-cordio/common/src/components/atoms/text";
import Table from "@socion-cordio/web/src/components/molecules/table/table";
import { useSelector } from "react-redux";
import { dataHelper } from "@socion-cordio/common/src/utils/dataHelper";
import { colorPallete } from "@socion-cordio/common/src/assets/styles/colors";
import SocionModal from "@socion-cordio/common/src/components/atoms/modal";
import CreateProgramsModal from "@socion-cordio/common/src/components/organisms/createProgramsModal";
import { Image } from "@socion-cordio/common/src/components/atoms/image";
import { useLocation, useHistory } from "react-router-dom";
import { allRoutesNames as routeNames } from "@socion-cordio/web/src/navigation/allRouteNames";
import { LocalStorage } from "@socion-cordio/common/src/services/storage/storageService";

interface Props {
  programDetails: any;
  entityStatus?: any;
  setProgramDetails?: Function;
}

export default function EntityPrograms(props: Props): ReactElement {
  const {
    programDetails: {
      entityData: { programs }
    },
    entityStatus,
    programDetails: { entityData },
    setProgramDetails,
    programDetails
  } = props;
  const isSuperAdmin = JSON.parse(localStorage.getItem("isSuperAdmin"));
  const [createProgramsModal, setCreateProgramsModal] = useState(false);

  const history: any = useHistory();

  const programRedirectHandler = async (selectedProgram: any) => {
    const programDetails: any = await LocalStorage.getStorage("programDetails");
    programDetails.program = selectedProgram;
    LocalStorage.setStorage("programDetails", programDetails);
    history.push(`${routeNames.app}${routeNames.PROGRAM}`);
    window.location.reload();
  };

  const cellData = (testId: string, style: any, data: string, onPress?: Function) => (
    <Text
      fontWeight={FontWeight.Bold}
      testId={testId}
      textSize={TextSize.Small}
      style={[style, styles.commonTextStyles, { fontWeight: FontFamily.Light }]}
      onPress={onPress ? () => onPress() : null}
    >
      {data}
    </Text>
  );

  const columns = useMemo(
    () => [
      {
        Header: "Sr.No",
        accessor: "serialNo",
        width: 30,
        maxWidth: 30,
        disableSortBy: true,
        Cell: ({ row: { original: program } }: { row: { original: any } }) => {
          const style = {};
          return cellData("serialNo", style, program.serialNo);
        }
      },
      {
        Header: "Program Name",
        accessor: "name",
        width: 255,
        maxWidth: 255,
        Cell: ({ row: { original: program } }: { row: { original: any } }) => {
          return (
            <Text
              fontWeight={FontWeight.Bold}
              testId="deleted"
              textSize={TextSize.Small}
              style={[styles.textContainer]}
            >
              <TouchableOpacity onPress={() => programRedirectHandler(program)}>
                {program.name}
              </TouchableOpacity>
            </Text>
          );
        }
      },
      {
        Header: "Date Starting",
        accessor: "startDate",
        width: 80,
        maxWidth: 80,
        Cell: ({ row: { original: program } }: { row: { original: any } }) => {
          const style = {};
          return cellData("creationDate", style, dataHelper.formatDate(program.startDate));
        }
      },
      {
        Header: "Date Ending",
        accessor: "endDate",
        width: 80,
        maxWidth: 80,
        Cell: ({ row: { original: program } }: { row: { original: any } }) => {
          const style = {};
          return cellData("endDate", style, dataHelper.formatDate(program.endDate));
        }
      },
      {
        Header: "Status",
        accessor: "deleted",
        width: 55,
        maxWidth: 55,
        Cell: ({ row: { original: program } }: { row: { original: any } }) => (
          <Text
            fontWeight={FontWeight.Light}
            testId="deleted"
            textSize={TextSize.Small}
            style={styles.statusAlignContainer}
          >
            <View style={[styles.status, program.deleted ? styles.deactivated : styles.active]} />
            {!program.deleted ? "Active" : "Deactivated"}
          </Text>
        )
      }
    ],
    []
  );

  return (
    <>
      {createProgramsModal && isSuperAdmin && (
        <View>
          <SocionModal
            modalVisible={createProgramsModal}
            setModalVisible={() => setCreateProgramsModal(!createProgramsModal)}
            component={
              <CreateProgramsModal
                entity={entityData.entity}
                onClose={() => setCreateProgramsModal(false)}
                isProgramListRequired={true}
                setProgramDetails={setProgramDetails}
                programDetails={programDetails}
              />
            }
          />
        </View>
      )}
      <View>
        {!programs ? (
          <View>Failed to load data</View>
        ) : (
          <Table
            handleCreateProgram={() => {
              setCreateProgramsModal(!createProgramsModal);
            }}
            isSuperAdmin={isSuperAdmin}
            columns={columns}
            data={programs}
            addIcon={
              <>
                {isSuperAdmin && !entityStatus && (
                  <TouchableOpacity onPress={() => setCreateProgramsModal(!createProgramsModal)}>
                    <Image
                      testId="primaryAddImg"
                      source={require("@socion-cordio/common/src/assets/images/primary_add.svg")}
                      imageStyle={{
                        width: 30,
                        height: 30
                      }}
                    />
                  </TouchableOpacity>
                )}
              </>
            }
          />
        )}
      </View>
    </>
  );
}

const styles = StyleSheet.create({
  roleName: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center"
  },
  image: {
    width: 25,
    height: 25,
    borderRadius: 25 / 2,
    marginEnd: 10
  },
  status: {
    height: 8,
    width: 8,
    borderRadius: 50,
    marginRight: 8
  },
  active: {
    backgroundColor: colorPallete.cordioGreen
  },
  pending: {
    backgroundColor: colorPallete.cordioOrange
  },
  deactivated: {
    backgroundColor: colorPallete.cordioRedDark3
  },
  commonTextStyles: {
    color: colorPallete.textBlack,
    textSize: TextSize.Small,
    fontFamily: FontFamily.Regular,
    fontSize: 12
  },
  textContainer: {
    fontSize: 12,
    fontFamily: FontFamily.Medium,
    textSize: TextSize.Small
  },
  statusAlignContainer: {
    fontSize: 12,
    fontFamily: FontFamily.Regular
  }
});
