import { PARTICIPATIONDETAILS } from "@socion-cordio/common/src/modules/participationDetails/actions/actionTypes";
import { IFluxStandardAction } from "@socion-cordio/common/src/store/interfaces";
const getParticipantList = (data: {
  programId: string;
  userType: string;
}): IFluxStandardAction<{ programId: string; userType: string }> => {
  return {
    type: PARTICIPATIONDETAILS.GET_PARTICIPANT_LIST.LOADING,
    payload: data
  };
};

const getParticipantListSucess = (data: any): IFluxStandardAction<any> => {
  return {
    type: PARTICIPATIONDETAILS.GET_PARTICIPANT_LIST.SUCCESS,
    payload: data
  };
};

const getParticipantListError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: PARTICIPATIONDETAILS.GET_PARTICIPANT_LIST.ERROR,
    payload: error
  };
};

const getTrainersList = (data: {
  programId: string;
  userType: string;
}): IFluxStandardAction<{ programId: string; userType: string }> => {
  return {
    type: PARTICIPATIONDETAILS.GET_TRAINERS_LIST.LOADING,
    payload: data
  };
};
const getTrainersListSucess = (data: any): IFluxStandardAction<any> => {
  return {
    type: PARTICIPATIONDETAILS.GET_TRAINERS_LIST.SUCCESS,
    payload: data
  };
};
const getTrainersListError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: PARTICIPATIONDETAILS.GET_TRAINERS_LIST.ERROR,
    payload: error
  };
};

const getParticipantAttestation = (data: {
  userType: string;
  startDate: string;
  endDate: string;
  programId: string;
}): IFluxStandardAction<{ programId: string; userType: string }> => {
  return {
    type: PARTICIPATIONDETAILS.GET_PARTICIPANT_ATTESTATION.LOADING,
    payload: data
  };
};
const getParticipantAttestationSucess = (data: any): IFluxStandardAction<any> => {
  return {
    type: PARTICIPATIONDETAILS.GET_PARTICIPANT_ATTESTATION.SUCCESS,
    payload: data
  };
};
const getParticipantAttestationError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: PARTICIPATIONDETAILS.GET_PARTICIPANT_ATTESTATION.ERROR,
    payload: error
  };
};

const getTrainerAttestation = (data: {
  userType: string;
  startDate: string;
  endDate: string;
  programId: string;
}): IFluxStandardAction<{
  userType: string;
  startDate: string;
  endDate: string;
  programId: string;
}> => {
  return {
    type: PARTICIPATIONDETAILS.GET_TRAINER_ATTESTATION.LOADING,
    payload: data
  };
};
const getTrainerAttestationSucess = (data: any): IFluxStandardAction<any> => {
  return {
    type: PARTICIPATIONDETAILS.GET_TRAINER_ATTESTATION.SUCCESS,
    payload: data
  };
};
const getTrainerAttestationError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: PARTICIPATIONDETAILS.GET_TRAINER_ATTESTATION.ERROR,
    payload: error
  };
};

const getTrainerByTopic = (data: {
  programId: string;
}): IFluxStandardAction<{ programId: string }> => {
  return {
    type: PARTICIPATIONDETAILS.GET_TRAINER_BY_TOPIC.LOADING,
    payload: data
  };
};
const getTrainerByTopicSucess = (data: any): IFluxStandardAction<any> => {
  return {
    type: PARTICIPATIONDETAILS.GET_TRAINER_BY_TOPIC.SUCCESS,
    payload: data
  };
};
const getTrainerByTopicError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: PARTICIPATIONDETAILS.GET_TRAINER_BY_TOPIC.ERROR,
    payload: error
  };
};

export const ParticipantDetailsActions = {
  getParticipantList,
  getParticipantListSucess,
  getParticipantListError,
  getTrainersList,
  getTrainersListSucess,
  getTrainersListError,
  getParticipantAttestation,
  getParticipantAttestationSucess,
  getParticipantAttestationError,
  getTrainerAttestation,
  getTrainerAttestationSucess,
  getTrainerAttestationError,
  getTrainerByTopic,
  getTrainerByTopicSucess,
  getTrainerByTopicError
};
