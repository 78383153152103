import { compact } from "lodash";
import { Task } from "@socion-cordio/common/src/models/task";
import { IFluxStandardAction } from "@socion-cordio/common/src/store/interfaces";
import { TASKS } from "@socion-cordio/common/src/modules/tasks/actions/actionTypes";

const getTasks = (data: { programId: number }): IFluxStandardAction<{ programId: number }> => {
  return {
    type: TASKS.GET_TASKS.LOADING,
    payload: data
  };
};

const getTasksSuccess = (data: Task): IFluxStandardAction<any> => {
  if (data?.result) {
    data.result.forEach((task: any, index: number) => {
      task.serialNo = `${index + 1}.`;
    });
  } else {
    data.result = compact(data.result);
  }
  return {
    type: TASKS.GET_TASKS.SUCCESS,
    payload: data
  };
};

const getTasksError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: TASKS.GET_TASKS.ERROR,
    payload: error
  };
};

const getTasksSubmission = (data: {
  source: string | string[];
  programId: string | number;
}): IFluxStandardAction<any> => {
  return {
    type: TASKS.GET_SUBMISSIONS.LOADING,
    payload: data
  };
};

const getTaskDataLoading = (data: { taskId: string }): IFluxStandardAction<any> => {
  return {
    type: TASKS.GET_TASK_DATA.LOADING,
    payload: data
  };
};

const getTaskDataSuccess = (data: any): IFluxStandardAction<any> => {
  return {
    type: TASKS.GET_TASK_DATA.SUCCESS,
    payload: data
  };
};

const getTaskDataError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: TASKS.GET_TASK_DATA.ERROR,
    payload: error
  };
};

// const getTasksSubmissionSuccess = (data: any): IFluxStandardAction<any> => {
//   return {
//     type: TASKS.GET_SUBMISSIONS.SUCCESS,
//     payload: data
//   };
// };

// const getTasksSubmissionError = (error: Error): IFluxStandardAction<Error> => {
//   return {
//     type: TASKS.GET_SUBMISSIONS.ERROR,
//     payload: error
//   };
// };

// const getSubmitterById = (data: { userId: string }): IFluxStandardAction<any> => {
//   return {
//     type: TASKS.GET_SUBMISSIONS.LOADING,
//     payload: data
//   };
// };

// const getSubmitterByIdSuccess = (data: any): IFluxStandardAction<any> => {
//   return {
//     type: TASKS.GET_SUBMISSIONS.SUCCESS,
//     payload: data
//   };
// };

// const getSubmitterByIdError = (error: Error): IFluxStandardAction<Error> => {
//   return {
//     type: TASKS.GET_SUBMISSIONS.ERROR,
//     payload: error
//   };
// };

export const TaskActions = {
  getTasks,
  getTasksSuccess,
  getTasksError,
  getTaskDataLoading,
  getTaskDataSuccess,
  getTaskDataError
};
