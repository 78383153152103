import Icon, { IconNames } from "@socion-cordio/common/src/components/atoms/icon";
import {
  Text,
  TextSize,
  FontWeight,
  FontFamily
} from "@socion-cordio/common/src/components/atoms/text";
import TextField from "@socion-cordio/common/src/components/atoms/textField";
import { colorPallete } from "@socion-cordio/common/src/assets/styles/colors";
import React, { ReactElement } from "react";
import * as Yup from "yup";
import { Form, Formik, FormikProps } from "formik";
import {
  View,
  StyleSheet,
  Animated,
  ImageSourcePropType,
  ImageResizeMode,
  StyleProp,
  ImageStyle,
  ViewStyle,
  TouchableOpacity,
  TextStyle
} from "react-native";
interface IProps {
  icon?: IconNames;
  label?: string;
  textValue?: string;
  name?: string;
  placeholder?: string;
  value?: string;
  id?: string;
  handleChange?: Function;
  handleBlur?: Function;
  handleKeyPress?: Function;
  userStyle?: StyleProp<ViewStyle>;
  textIcon?: StyleProp<TextStyle>;
  input?: StyleProp<ViewStyle>;
  view?: StyleProp<ViewStyle>;
  labelStyle?: StyleProp<ViewStyle>;
  textField?: StyleProp<ViewStyle>;
  iconStyle?: StyleProp<ViewStyle>;
  isSecure?: boolean;
  noFormik?: boolean;
  onPress?: any;
  editable?: boolean;
  setName?: Function;
  onsubmit?: any;
  showEditIcon?: boolean;
  multiline?: boolean;
  formikPropsTouched?: boolean;
  customChange?: Function;
  isNumeric?: boolean;
  onChange?: Function;
}
const styles = StyleSheet.create({
  textIcon: {
    color: colorPallete.textLight,
    fontFamily: FontFamily.Regular
  },
  input: {
    width: 304,
    height: 40,
    // borderColor: colorPallete.cordioTaupe,
    // opacity: 0.4,
    outline: "none",
    borderRadius: 5
  },
  label: {
    flex: 1,
    flexDirection: "row",
    alignItems: "center",
    marginBottom: 15
  },
  icon: {},
  view: {
    zIndex: -1
  },
  iconStyle: {
    color: colorPallete.cordioTaupe,
    fontSize: 17
  },
  iconContainer: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center"
  },
  alignIcon: {
    marginLeft: 5
  }
});
export default function UserInput(props: IProps): ReactElement {
  const {
    icon,
    label,
    placeholder,
    textValue,
    name,
    value,
    id,
    handleChange,
    handleBlur,
    userStyle,
    handleKeyPress,
    textIcon,
    input,
    view,
    labelStyle,
    textField,
    iconStyle,
    isSecure,
    noFormik,
    onPress,
    editable,
    onsubmit,
    multiline,
    showEditIcon = true,
    formikPropsTouched,
    customChange,
    isNumeric = false,
    onChange
  } = props;

  const handlePress = () => {
    props.onPress();
    props.setName();
  };

  const renderIcons = () => {
    if (props.editable) {
      return (
        showEditIcon && (
          <View style={styles.iconContainer}>
            <TouchableOpacity>
              <Icon name={IconNames.close} customStyle={styles.iconStyle} onPress={handlePress} />
            </TouchableOpacity>
            <TouchableOpacity style={styles.alignIcon}>
              <Icon name={IconNames.tick} customStyle={styles.iconStyle} onPress={onsubmit} />
            </TouchableOpacity>
          </View>
        )
      );
    } else if (props.editable === false) {
      return (
        <View style={styles.iconContainer}>
          {showEditIcon && (
            <TouchableOpacity>
              <Icon name={IconNames.edit} customStyle={styles.iconStyle} onPress={onPress} />
            </TouchableOpacity>
          )}
        </View>
      );
    }
  };

  return (
    <View style={[styles.view, userStyle, view]}>
      {textValue && (
        <View style={[styles.label, labelStyle]}>
          <Icon testID="home" name={icon} customStyle={[styles.icon, iconStyle]} />
          <Text
            fontWeight={FontWeight.Regular}
            testId="internal"
            textSize={TextSize.Small}
            style={[styles.textIcon, textIcon]}
          >
            {" "}
            {textValue}
            {""}
          </Text>
        </View>
      )}
      <View style={textField}>
        <TextField
          isSecure={isSecure}
          inputStyle={[styles.input, input]}
          name={name}
          placeholder={placeholder}
          label={label}
          value={value}
          id={id}
          handleChange={handleChange}
          handleBlur={handleBlur}
          handleKeyPress={handleKeyPress}
          noFormik={noFormik}
          editable={editable}
          multiline={multiline}
          formikPropsTouched={formikPropsTouched}
          customChange={customChange}
          isNumeric={isNumeric}
          onChange={onChange}
        ></TextField>
        {renderIcons()}
      </View>
    </View>
  );
}
