import React, { ReactElement, useEffect, useState, useMemo } from "react";
import { View, StyleSheet, TouchableOpacity } from "react-native";
import { badgesEndPoints } from "@socion-cordio/common/src/repositories/endPoints";
import { ApiClient } from "@socion-cordio/common/src/network/apiClient";
import {
  Text,
  TextSize,
  FontWeight,
  FontFamily
} from "@socion-cordio/common/src/components/atoms/text";
import Table from "@socion-cordio/web/src/components/molecules/table/table";
import { Image } from "@socion-cordio/common/src/components/atoms/image";
import Loader from "@socion-cordio/common/src/components/atoms/loader";
import { colorPallete } from "@socion-cordio/common/src/assets/styles/colors";
import { dataHelper } from "@socion-cordio/common/src/utils/dataHelper";
import AddBadgeModal from "@socion-cordio/common/src/components/organisms/badges/addBadgeModal";
import SocionModal from "@socion-cordio/common/src/components/atoms/modal";
import BadgeDetailsModal from "@socion-cordio/common/src/components/organisms/badges/badgeDetailsModals";
import { useHistory } from "react-router-dom";
import { allRoutesNames } from "../../../../../web/src/navigation/allRouteNames";
import SearchFilterSeondary from "@socion-cordio/common/src/components/molecules/searchFilterSecondary";

interface Props {
  programDetails?: any;
}

export default function BadgesTable(props: Props): ReactElement {
  const history: any = useHistory();
  const [badgesResponse, setBadgesResponse] = useState(null);
  const [loading, setLoading] = useState(true);
  const [failed, setFailed] = useState(false);
  const [addBadgeModal, setAddBadgeModal] = useState(false);
  const [viewBadgeModal, setViewBadgeModal] = useState(false);
  const [selectedBadge, setSelectedBadge] = useState(undefined);
  const { programDetails } = props;
  const isEntityEnabled = JSON.parse(localStorage.getItem("isEntityDeleted"));
  const isProgramEnabled = props?.programDetails?.program?.deleted;

  console.log("isEntityEnabled", isEntityEnabled);

  useEffect(() => {
    getBadgesTable();
  }, []);

  console.log(props?.programDetails?.program?.deleted);

  const getBadgesTable = async () => {
    try {
      const { program } = props?.programDetails;
      const badgesResponse = await ApiClient.get(badgesEndPoints.getBadgesTable(program.id));
      badgesResponse?.response?.[program.id].forEach((badge: any, index: number) => {
        badge.serialNo = `${index + 1}.`;
      });
      setBadgesResponse(badgesResponse?.response?.[program.id]);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setFailed(true);
    }
  };

  const unassignBadgeNavigateHandler = (data: any) => {
    history.push(`${allRoutesNames.app}${allRoutesNames.BADGES}${allRoutesNames.UNASSIGNBADGE}`, {
      selectedBadge: data,
      programId: programDetails?.program?.id
    });
  };

  const cellData = (testId: string, style: any, data: string, onPress?: Function) => (
    <Text
      fontWeight={FontWeight.Bold}
      testId={testId}
      textSize={TextSize.Small}
      style={[
        {
          fontSize: 12,
          fontFamily: FontFamily.Regular,
          fontWeight: FontFamily.Light,
          textSize: TextSize.Small,
          color: colorPallete.textBlack
        },
        style
      ]}
      onPress={onPress ? () => onPress() : null}
    >
      {data}
    </Text>
  );

  const columns = useMemo(
    () => [
      {
        Header: "Sr.No",
        accessor: "serialNo",
        width: 30,
        maxWidth: 30,
        disableSortBy: true,
        Cell: ({ row: { original: badge } }: { row: { original: any } }) => {
          const style = {};
          return cellData("serialNo", style, badge?.serialNo);
        }
      },
      {
        Header: "Name",
        accessor: "name",
        width: 175,
        maxWidth: 175,
        Cell: ({ row: { original: badge } }: { row: { original: any } }) => {
          const style = {
            fontFamily: FontFamily.Medium,
            fontWeight: FontFamily.Bold
          };
          const onPress = () => {
            handleRowClick(badge);
          };
          return cellData("name", style, badge?.name, onPress);
        }
      },
      {
        Header: "Type",
        accessor: "badgeTypeName",
        width: 60,
        maxWidth: 60,
        Cell: ({ row: { original: badge } }: { row: { original: any } }) => {
          const style = {};
          return cellData("badgeTypeName", style, badge.badgeTypeName);
        }
      },
      {
        Header: "Date of creation",
        accessor: "createdAt",
        width: 65,
        maxWidth: 65,
        disableSortBy: true,
        Cell: ({ row: { original: badge } }: { row: { original: any } }) => {
          const style = {};
          return cellData("createdAt", style, dataHelper.formatDate(badge.createdAt));
        }
      },
      {
        Header: "Status",
        accessor: "status",
        width: 50,
        maxWidth: 50,
        Cell: ({ row: { original: badge } }: { row: { original: any } }) => (
          <View>
            {badge.status == "ACTIVE" ? (
              <View style={styles.statusContainer}>
                <View style={[styles.statusColor, styles.activeColor]}></View>
                <Text
                  fontWeight={FontWeight.Light}
                  testId="testId"
                  textSize={TextSize.Small}
                  textStyle={styles.textAlign}
                  onPress={() => {}}
                >
                  {"Active"}
                </Text>
              </View>
            ) : badge.status == "DEACTIVATED" ? (
              <View style={styles.statusContainer}>
                <View style={[styles.statusColor, styles.inactiveColor]}></View>
                <Text
                  fontWeight={FontWeight.Light}
                  testId="testId"
                  textSize={TextSize.Small}
                  textStyle={styles.textAlign}
                  onPress={() => {}}
                >
                  {"Inactive"}
                </Text>
              </View>
            ) : null}
          </View>
        )
      },
      {
        Header: "No. issued",
        accessor: "badgeIssued",
        width: 55,
        maxWidth: 55,
        Cell: ({ row: { original: badge } }: { row: { original: any } }) => {
          const style = {
            fontFamily: FontFamily.Medium,
            fontWeight: FontFamily.Bold,
            textDecorationLine: "underline"
          };
          const onPress = () => {
            unassignBadgeNavigateHandler(badge);
          };
          return cellData("name", style, badge?.badgeIssued, onPress);
        }
      },
      {
        Header: "Assign badge",
        width: 63,
        maxWidth: 63,
        disableSortBy: true,
        Cell: ({ row: { original: badge } }: { row: { original: any } }) => (
          <View>
            <View style={styles.assignBadgeContainer}>
              {badge.status === "ACTIVE" && (
                <TouchableOpacity
                  onPress={() => {
                    history.push(
                      `${allRoutesNames.app}${allRoutesNames.BADGES}${allRoutesNames.ASSIGNBADGE}`,
                      {
                        selectedBadge: badge,
                        programId: programDetails?.program?.id
                      }
                    );
                  }}
                >
                  <Image
                    testId="secondaryAddImg"
                    source={require("@socion-cordio/common/src/assets/images/secondary_add.svg")}
                    imageStyle={{
                      width: 20,
                      height: 20
                    }}
                  />
                </TouchableOpacity>
              )}
            </View>
          </View>
        )
      }
    ],
    []
  );

  const handleRowClick = (data: any) => {
    setSelectedBadge(data);
    setViewBadgeModal(!viewBadgeModal);
  };

  const searchbarAndAddBadge = () => (
    <View>
      <View style={styles.searchBarContainer}>
        <View pointerEvents={"none"}>
          <SearchFilterSeondary customWidthStyle={styles.customWidthStyle} editable={false} />
        </View>
        <View>
          {!isEntityEnabled && !isProgramEnabled && (
            <TouchableOpacity
              style={styles.addButtonIcon}
              onPress={() => setAddBadgeModal(!addBadgeModal)}
            >
              <Image
                testId="secondaryAddImg"
                source={require("@socion-cordio/common/src/assets/images/primary_add.svg")}
                imageStyle={{
                  width: 30,
                  height: 30
                }}
              />
            </TouchableOpacity>
          )}
        </View>
      </View>
      <Text
        fontWeight={FontWeight.Thin}
        testId="headerText"
        textSize={TextSize.Small}
        textStyle={styles.headerText}
      >
        {"There are no Badges created for this program."}
      </Text>
    </View>
  );

  return (
    <View>
      {loading ? (
        <Loader />
      ) : failed ? (
        <View>Failed to load data</View>
      ) : (
        <View>
          {badgesResponse?.length === 0 ? (
            <View>{searchbarAndAddBadge()} </View>
          ) : (
            <Table
              columns={columns}
              data={badgesResponse}
              addIcon={
                !isEntityEnabled &&
                !isProgramEnabled && (
                  <TouchableOpacity onPress={() => setAddBadgeModal(!addBadgeModal)}>
                    <Image
                      testId="primaryAddImg"
                      source={require("@socion-cordio/common/src/assets/images/primary_add.svg")}
                      imageStyle={{
                        width: 30,
                        height: 30
                      }}
                    />
                  </TouchableOpacity>
                )
              }
            />
          )}

          {addBadgeModal && (
            <View>
              <SocionModal
                modalVisible={addBadgeModal}
                setModalVisible={() => setAddBadgeModal(!addBadgeModal)}
                component={
                  <AddBadgeModal
                    onClose={() => setAddBadgeModal(false)}
                    getBadges={getBadgesTable}
                    programDetails={programDetails}
                  />
                }
              />
            </View>
          )}

          {viewBadgeModal && (
            <View>
              <SocionModal
                modalVisible={viewBadgeModal}
                setModalVisible={() => setViewBadgeModal(!viewBadgeModal)}
                component={
                  <BadgeDetailsModal
                    onClose={() => setViewBadgeModal(false)}
                    selectedBadge={selectedBadge}
                    getBadges={getBadgesTable}
                    programDetails={programDetails}
                  />
                }
              />
            </View>
          )}
        </View>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  roleName: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center"
  },
  image: {
    width: 25,
    height: 25,
    borderRadius: 25 / 2,
    marginEnd: 10
  },
  mainHeaderText: {
    fontSize: 12,
    fontFamily: FontFamily.Bold,
    lineHeight: 17,
    fontWeight: "700",
    paddingLeft: 25,
    marginTop: 10,
    marginBottom: 10
  },
  addRoleButton: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    padding: 10
  },
  statusContainer: {
    flexDirection: "row",
    alignItems: "center"
  },
  statusColor: {
    height: 8,
    width: 8,
    borderRadius: 50,
    marginRight: 8
  },
  activeColor: {
    backgroundColor: colorPallete.cordioGreen
  },
  inactiveColor: {
    backgroundColor: colorPallete.yellowOne
  },
  textAlign: {
    fontFamily: FontFamily.Regular,
    color: colorPallete.textBlack,
    fontSize: 12
  },
  // Search bar disabled
  searchBarContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    padding: 25,
    marginBottom: 10
  },
  customWidthStyle: {
    width: 400
  },
  addButtonIcon: {},
  headerText: {
    fontSize: 14,
    fontFamily: FontFamily.Medium,
    lineHeight: 19.5,
    fontWeight: "700",
    paddingHorizontal: 25,
    marginBottom: 30
  },
  assignBadgeContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    marginRight: 10
  }
});
