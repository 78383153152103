import React, { ReactElement, useEffect, useState } from "react";
import { StyleSheet, View, Linking, TouchableOpacity } from "react-native";
import {
  Text,
  TextSize,
  FontWeight,
  FontFamily
} from "@socion-cordio/common/src/components/atoms/text";
import { Image } from "@socion-cordio/common/src/components/atoms/image";
import Loader from "@socion-cordio/common/src/components/atoms/loader";
import UserInputSecondary from "@socion-cordio/common/src/components/molecules/userInputSecondary";
import RemoveLinkModal from "@socion-cordio/common/src/components/organisms/common-modals/removeLinkModal";
import { colorPallete } from "@socion-cordio/common/src/assets/styles/colors";
import { ApiClient } from "@socion-cordio/common/src/network/apiClient";
import { topicEndPoints } from "@socion-cordio/common/src/repositories/endPoints";
import { toast } from "react-toastify";
import { HTTP_STATUS_CODES } from "@socion-cordio/common/src/network/constants";

interface Props {
  topicDetails: any;
  getTopicDetails: Function;
  userRoles: any[];
}

export default function TopicDetailsTasks(props: Props): ReactElement {
  const { topicDetails, getTopicDetails, userRoles } = props;
  const [surveyDtoLink, setSurveyDtoLink] = useState(null);
  const [surveyLink, setSurveyLink] = useState(null);
  const [isLinkDeleted, setIsLinkDeleted] = useState(false);
  const [topicStatus, setTopicStatus] = useState(false);
  const [loading, setLoading] = useState(false);
  const messageTextValue = "Are you sure you want to delete this task?";

  useEffect(() => {
    setSurveyDtoLink(topicDetails.surveyDTO);
    topicStatusHandler(topicDetails);
  }, [topicDetails.surveyDTO]);

  const topicStatusHandler = (topicDetails: any) => {
    let status = topicDetails.status === "ACTIVE" ? true : false;
    setTopicStatus(status);
  };

  const addSurveyLinkHandler = async () => {
    setLoading(true);
    const payload = {
      topicId: topicDetails.id,
      surveyUrl: surveyLink
    };
    const response = await ApiClient.post(topicEndPoints.addSurveyLink(), payload);
    if (response.responseCode === 200) {
      toast.success("Task created successfully");
    } else if (response.responseCode === 404) {
      toast.error(response.message);
    } else {
      toast.error("Something went Wrong.");
    }
    responseHandler(response);
  };

  const removeSurveyLinkHandler = async (isDeleted: boolean) => {
    if (isDeleted === true) {
      setIsLinkDeleted(false);
      setLoading(true);
      const payload = {
        surveyId: surveyDtoLink.surveyId,
        status: "DELETED"
      };
      const response = await ApiClient.put(topicEndPoints.addSurveyLink(), payload);
      if (response.responseCode === 200) {
        toast.success("Document deleted successfully");
      } else {
        toast.error("Something went Wrong.");
      }
      responseHandler(response);
    }
  };

  const responseHandler = (response: any) => {
    if (response.responseCode === HTTP_STATUS_CODES.ok) {
      getTopicDetails(topicDetails.id);
      setSurveyLink("");
      setLoading(false);
    } else {
      setSurveyLink("");
      setLoading(false);
    }
  };

  return (
    <View style={styles.mainContainer}>
      {(userRoles.includes("PROGRAM_ADMIN") || userRoles.includes("CONTENT_ADMIN")) && (
        <>
          {topicStatus && (
            <>
              {!surveyDtoLink && (
                <View style={styles.container}>
                  <View style={styles.subContainerOne}>
                    <UserInputSecondary
                      textValue="Task URL"
                      handleChange={(surveyLink: string) => {
                        setSurveyLink(surveyLink);
                      }}
                      handleBlur={() => {}}
                      value={surveyLink}
                      placeholder="Enter task URL"
                      name="enterUrl"
                      id="enterUrl"
                      noFormik
                      userStyle={styles.inputStylingAlignment}
                      userStyleText={styles.textHeaderField}
                      titleInputContainerStyles={styles.titleInputContainerStyles}
                    />
                  </View>
                  <View style={styles.subContainerTwo}>
                    {" "}
                    <TouchableOpacity onPress={() => addSurveyLinkHandler()}>
                      <Image
                        testId="addSubcontent"
                        source={require("@socion-cordio/common/src/assets/images/secondary_add.svg")}
                        imageStyle={[styles.alignContainerButton]}
                      />
                    </TouchableOpacity>
                  </View>
                </View>
              )}
            </>
          )}
        </>
      )}

      {loading && <Loader customLoadingContainer={styles.loader} />}

      {surveyDtoLink && (
        <View style={[styles.container, styles.alignTaskLinkContainer]}>
          <View style={styles.subContainerOne}>
            <View style={styles.createCustomTaskContainer}>
              <View style={styles.alignCreateTaskHeader}>
                <TouchableOpacity onPress={() => Linking.openURL(surveyDtoLink.surveyUrl)}>
                  <Text
                    fontWeight={FontWeight.Regular}
                    testId="addRoleText"
                    textSize={TextSize.Small}
                    textStyle={[styles.fontSize14, styles.headerTextBold, styles.alignText]}
                  >
                    {surveyDtoLink.surveyUrl}
                  </Text>
                </TouchableOpacity>
              </View>
            </View>
          </View>
          {(userRoles.includes("PROGRAM_ADMIN") || userRoles.includes("CONTENT_ADMIN")) && (
            <View style={styles.subContainerTwo}>
              <TouchableOpacity onPress={() => setIsLinkDeleted(true)}>
                <Image
                  testId="addSubcontent"
                  source={require("@socion-cordio/common/src/assets/images/remove.svg")}
                  imageStyle={[styles.alignContainerButton]}
                />
              </TouchableOpacity>
            </View>
          )}
        </View>
      )}

      {/* {topicStatus && (
        <>
          <View style={styles.orContainer}>
            <Text
              fontWeight={FontWeight.Regular}
              testId="addRoleText"
              textSize={TextSize.Small}
              textStyle={styles.headerText}
            >
              {"OR"}
            </Text>
          </View>

          <View style={styles.container}>
            <View style={styles.subContainerOne}>
              <View style={styles.createCustomTaskContainer}>
                <View style={styles.alignCreateTaskHeader}>
                  <Text
                    fontWeight={FontWeight.Regular}
                    testId="addRoleText"
                    textSize={TextSize.Small}
                    textStyle={[styles.fontSize14, styles.headerTextBold]}
                  >
                    {"Create Custom Task"}
                  </Text>
                </View>

                <TouchableOpacity>
                  <View style={styles.createTaskContainer}>
                    {" "}
                    <Text
                      fontWeight={FontWeight.Light}
                      testId="addRoleText"
                      textSize={TextSize.Small}
                      textStyle={[
                        styles.headerTextNormal,
                        styles.fontSize14,
                        styles.fontColorWhite
                      ]}
                    >
                      {"Create Task"}
                    </Text>
                  </View>
                </TouchableOpacity>
              </View>
            </View>
            <View style={styles.subContainerTwo}></View>
          </View>
        </>
      )} */}

      {isLinkDeleted && (
        <RemoveLinkModal
          modalVisible={isLinkDeleted}
          selectedData={removeSurveyLinkHandler}
          textValue={messageTextValue}
          setModalVisible={() => setIsLinkDeleted(!isLinkDeleted)}
        />
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  mainContainer: {},
  container: {
    display: "flex",
    flexDirection: "row"
  },
  subContainerOne: {
    flex: 93
  },
  subContainerTwo: {
    flex: 7,
    justifyContent: "center"
  },
  alignTaskLinkContainer: {
    marginTop: 15
  },

  // input field
  titleInputContainerStyles: {
    marginTop: 0
  },
  inputStylingAlignment: {
    width: "100%",
    fontSize: 14,
    fontWeight: FontWeight.Light,
    fontFamily: FontFamily.Regular
  },
  textHeaderField: {
    flex: 12,
    fontSize: 14
  },
  alignContainerButton: {
    fontSize: 17,
    color: colorPallete.cordioTaupe,
    marginRight: 5,
    marginLeft: 25,
    width: 18,
    height: 18
  },

  headerText: {
    fontSize: 12,
    fontFamily: FontFamily.Bold,
    lineHeight: 17,
    fontWeight: "700"
  },
  headerTextNormal: {
    fontFamily: FontFamily.Medium,
    lineHeight: 17
    // marginBottom: 5
  },

  // font sizes
  fontSize14: {
    fontSize: 14
  },

  // font colors
  fontColorWhite: {
    color: colorPallete.white
  },

  headerTextBold: {
    fontFamily: FontFamily.Bold
  },
  orContainer: {
    marginVertical: 20,
    alignSelf: "center"
  },
  alignText: {
    marginVertical: 5
  },

  // Create Custome Container
  createCustomTaskContainer: {
    minHeight: 57,
    borderColor: colorPallete.cordioTaupe,
    borderWidth: 1,
    borderStyle: "solid",
    borderRadius: 5,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center"
  },
  alignCreateTaskHeader: {
    paddingHorizontal: 20,
    flex: 1,
    flexWrap: "wrap"
  },
  createTaskContainer: {
    height: 49,
    width: 140,
    borderRadius: 5,
    backgroundColor: colorPallete.cordioRed,
    marginHorizontal: 4,
    justifyContent: "center",
    alignItems: "center"
  },

  // Loader
  loader: {
    height: 150
    // transform: [{ scale: 0.8 }]
  }
});
