export default function BrowserTypeFinder() {
  let browser_name = "";
  if ((navigator.userAgent.indexOf("Opera") || navigator.userAgent.indexOf("OPR")) != -1) {
    browser_name = "Opera";
  } else if (navigator.userAgent.indexOf("Chrome") != -1) {
    browser_name = "Chrome";
  } else if (navigator.userAgent.indexOf("Safari") != -1) {
    browser_name = "Safari";
  } else if (navigator.userAgent.indexOf("Firefox") != -1) {
    browser_name = "Firefox";
  } else if (
    navigator.userAgent.indexOf("MSIE") != -1
    // || (!!document.documentMode == true )
  ) {
    //IF IE > 10
    browser_name = "IE";
  } else {
    browser_name = "unknown";
  }
  return browser_name;
}
