// STAGE
export const CONFIG = {
  REACT_APP_API_URL: "https://pda-stage.socion.io",
  REACT_APP_API_BASE_URL: "https://pda-stage.socion.io",
  REACT_APP_SUPERSET_DASHBOARD_BASE_URL: "https://pda-stage.socion.io",
  REACT_APP_GOOGLE_MAP_API_BASEURL: "https://maps.googleapis.com/maps/api/geocode/",
  REACT_APP_GOOGLE_API_KEY: "AIzaSyDVf-To9hehz4ORteAxgsP-yzHxsahVcDo",
  REACT_APP_MAP_MY_INDIA_CLIENT_SECRET:
    "lrFxI-iSEg8ugz_-DhsZ-0eM-seVJKDa-ND_t_s2qp8BTZircx1RAkz2N38uLtNNtvrNtVV5GvoEFoOmpCFxkAsVI7md7BR3nMpWkuRXwA0ttm8VPh8VUCzoUMpq7I-r",
  REACT_APP_MAP_MY_INDIA_CLIENT_ID:
    "33OkryzDZsLgqMoIblYc703weXdYMO73w7wPq96wmDzcylNlblcNZ03zCi8dQQUZ79IoNlyCsSa24nwxVeD7HkHjYL-A1-sXtJl-11w3yV9M2Otw-RGbxQ==",
  REACT_APP_DASHBOARD_ID: "",
  REACT_APP_BUNDLE_ID: "1",
  REACT_APP_TASK_API_BASE_URL: "https://task-api-stage.socion.io",
  REACT_APP_TASK_APPLICATION_ID: "16",
  REACT_APP_ARTEFACT_API_BASE_URL: "https://pda-a.socion.io",
  // "http://localhost:3002",
  REACT_APP_ARTEFACT_APPLICATION_ID: "15",
  REACT_APP_HELP_DIRECTION: "https://help.pda.socion.io/additional-features/artefact-application"
};

// PROD
// export const CONFIG = {
//   REACT_APP_API_URL: "https://pda.socion.io",
//   REACT_APP_API_BASE_URL: "https://pda.socion.io",
//   REACT_APP_SUPERSET_DASHBOARD_BASE_URL: "https://pda.socion.io",
//   REACT_APP_GOOGLE_MAP_API_BASEURL: "https://maps.googleapis.com/maps/api/geocode/",
//   REACT_APP_GOOGLE_API_KEY: "AIzaSyDVf-To9hehz4ORteAxgsP-yzHxsahVcDo",
//   REACT_APP_MAP_MY_INDIA_CLIENT_ID:
//     "33OkryzDZsLgqMoIblYc703weXdYMO73w7wPq96wmDzcylNlblcNZ03zCi8dQQUZ79IoNlyCsSa24nwxVeD7HkHjYL-A1-sXtJl-11w3yV9M2Otw-RGbxQ==",
//   REACT_APP_MAP_MY_INDIA_CLIENT_SECRET:
//     "lrFxI-iSEg8ugz_-DhsZ-0eM-seVJKDa-ND_t_s2qp8BTZircx1RAkz2N38uLtNNtvrNtVV5GvoEFoOmpCFxkAsVI7md7BR3nMpWkuRXwA0ttm8VPh8VUCzoUMpq7I-r",
//   REACT_APP_DASHBOARD_ID: "",
//   REACT_APP_BUNDLE_ID: "1",
//   REACT_APP_ARTEFACT_API_BASE_URL: "https://artefacts.socion.io",
//   REACT_APP_ARTEFACT_APPLICATION_ID: "11",
//   REACT_APP_TASK_APPLICATION_ID: "",
//   REACT_APP_TASK_API_BASE_URL: "",
//   REACT_APP_HELP_DIRECTION: "https://help.pda.socion.io/additional-features/artefact-application"
// };

// export const CONFIG = {
//   REACT_APP_API_URL: process.env.REACT_APP_API_URL,
//   REACT_APP_API_BASE_URL: process.env.REACT_APP_API_BASE_URL,
//   REACT_APP_SUPERSET_DASHBOARD_BASE_URL: process.env.REACT_APP_SUPERSET_DASHBOARD_BASE_URL,
//   REACT_APP_GOOGLE_MAP_API_BASEURL: process.env.REACT_APP_GOOGLE_MAP_API_BASEURL,
//   REACT_APP_GOOGLE_API_KEY: process.env.REACT_APP_GOOGLE_API_KEY,
//   REACT_APP_MAP_MY_INDIA_CLIENT_SECRET: process.env.REACT_APP_MAP_MY_INDIA_CLIENT_SECRET,
//   REACT_APP_MAP_MY_INDIA_CLIENT_ID: process.env.REACT_APP_MAP_MY_INDIA_CLIENT_ID,
//   REACT_APP_DASHBOARD_ID: process.env.REACT_APP_DASHBOARD_ID,
//   REACT_APP_BUNDLE_ID: process.env.REACT_APP_BUNDLE_ID,
//   REACT_APP_TASK_API_BASE_URL: process.env.REACT_APP_TASK_API_BASE_URL,
//   REACT_APP_TASK_APPLICATION_ID: process.env.REACT_APP_TASK_APPLICATION_ID,
//   REACT_APP_ARTEFACT_API_BASE_URL: process.env.REACT_APP_ARTEFACT_API_BASE_URL,
//   REACT_APP_ARTEFACT_APPLICATION_ID: process.env.REACT_APP_ARTEFACT_APPLICATION_ID,
//   REACT_APP_HELP_DIRECTION: "https://help.pda.socion.io/additional-features/artefact-application"
// };
