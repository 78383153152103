// import { Login } from "@socion-cordio/common/src/models/login";
import { PARTICIPATIONDETAILS } from "@socion-cordio/common/src/modules/participationDetails/actions/actionTypes";

export interface IParticipationDetailsLoading {
  getParticipantList: boolean;
  getTrainersList: boolean;
  getParticipantAttestation: boolean;
  getTrainerAttestation: boolean;
  getTrainerByTopic: boolean;
}

export interface IParticipationDetailsError {
  getParticipantList: string;
  getTrainersList: string;
  getParticipantAttestation: string;
  getTrainerAttestation: string;
  getTrainerByTopic: string;
}
export interface IParticipationDetailsState {
  participantListData: any;
  trainersListData: any;
  participantAttestationData: any;
  trainerAttestationData: any;
  trainerByTopicData: any;
  error: IParticipationDetailsError;
  loading: IParticipationDetailsLoading;
}

export const participationDetailsInitialState: IParticipationDetailsState = {
  participantListData: null,
  trainersListData: null,
  participantAttestationData: null,
  trainerAttestationData: null,
  trainerByTopicData: null,
  error: {
    getParticipantList: "",
    getTrainersList: "",
    getParticipantAttestation: "",
    getTrainerAttestation: "",
    getTrainerByTopic: ""
  },
  loading: {
    getParticipantList: false,
    getTrainersList: false,
    getParticipantAttestation: false,
    getTrainerAttestation: false,
    getTrainerByTopic: false
  }
};

const participationDetailsReducer = (
  state = participationDetailsInitialState,
  action: { payload: any; type: string }
): IParticipationDetailsState => {
  switch (action.type) {
    case PARTICIPATIONDETAILS.GET_PARTICIPANT_LIST.LOADING:
      return {
        ...state,
        loading: { ...state.loading, getParticipantList: true },
        error: { ...state.error, getParticipantList: null }
      };
    case PARTICIPATIONDETAILS.GET_PARTICIPANT_LIST.SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, getParticipantList: false },
        error: { ...state.error, getParticipantList: null },
        participantListData: action.payload
      };
    case PARTICIPATIONDETAILS.GET_PARTICIPANT_LIST.ERROR:
      return {
        ...state,
        loading: { ...state.loading, getParticipantList: false },
        error: { ...state.error, getParticipantList: action.payload }
      };
    case PARTICIPATIONDETAILS.GET_TRAINERS_LIST.LOADING:
      return {
        ...state,
        loading: { ...state.loading, getTrainersList: true },
        error: { ...state.error, getTrainersList: null }
      };
    case PARTICIPATIONDETAILS.GET_TRAINERS_LIST.SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, getTrainersList: false },
        error: { ...state.error, getTrainersList: null },
        trainersListData: action.payload
      };
    case PARTICIPATIONDETAILS.GET_TRAINERS_LIST.ERROR:
      return {
        ...state,
        loading: { ...state.loading, getTrainersList: false },
        error: { ...state.error, getTrainersList: action.payload }
      };
    case PARTICIPATIONDETAILS.GET_PARTICIPANT_ATTESTATION.LOADING:
      return {
        ...state,
        loading: { ...state.loading, getParticipantAttestation: true },
        error: { ...state.error, getParticipantAttestation: null }
      };
    case PARTICIPATIONDETAILS.GET_PARTICIPANT_ATTESTATION.SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, getParticipantAttestation: false },
        error: { ...state.error, getParticipantAttestation: null },
        participantAttestationData: action.payload
      };
    case PARTICIPATIONDETAILS.GET_PARTICIPANT_ATTESTATION.ERROR:
      return {
        ...state,
        loading: { ...state.loading, getParticipantAttestation: false },
        error: { ...state.error, getParticipantAttestation: action.payload }
      };
    case PARTICIPATIONDETAILS.GET_TRAINER_ATTESTATION.LOADING:
      return {
        ...state,
        loading: { ...state.loading, getTrainerAttestation: true },
        error: { ...state.error, getTrainerAttestation: null }
      };
    case PARTICIPATIONDETAILS.GET_TRAINER_ATTESTATION.SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, getTrainerAttestation: false },
        error: { ...state.error, getTrainerAttestation: null },
        trainerAttestationData: action.payload
      };
    case PARTICIPATIONDETAILS.GET_TRAINER_ATTESTATION.ERROR:
      return {
        ...state,
        loading: { ...state.loading, getTrainerAttestation: false },
        error: { ...state.error, getTrainerAttestation: action.payload }
      };

    case PARTICIPATIONDETAILS.GET_TRAINER_BY_TOPIC.LOADING:
      return {
        ...state,
        loading: { ...state.loading, getTrainerByTopic: true },
        error: { ...state.error, getTrainerByTopic: null }
      };
    case PARTICIPATIONDETAILS.GET_TRAINER_BY_TOPIC.SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, getTrainerByTopic: false },
        error: { ...state.error, getTrainerByTopic: null },
        trainerByTopicData: action.payload
      };
    case PARTICIPATIONDETAILS.GET_TRAINER_BY_TOPIC.ERROR:
      return {
        ...state,
        loading: { ...state.loading, getTrainerByTopic: false },
        error: { ...state.error, getTrainerByTopic: action.payload }
      };

    default:
      return state;
  }
};
export default participationDetailsReducer;
