import Icon, { IconNames } from "@socion-cordio/common/src/components/atoms/icon";
import {
  Text,
  TextSize,
  FontWeight,
  FontFamily
} from "@socion-cordio/common/src/components/atoms/text";
import { colorPallete } from "@socion-cordio/common/src/assets/styles/colors";
import React, { ReactElement, useEffect, useState } from "react";
import { View, StyleSheet, ScrollView, TouchableOpacity, Linking } from "react-native";
import CollapseItem from "@socion-cordio/common/src/components/molecules/collapseItem";
import { useTranslation } from "react-i18next";
import { useLocation, useHistory } from "react-router-dom";
import { mediaHelper } from "@socion-cordio/common/src/utils/mediaHelperMethods";
import { dataHelper } from "@socion-cordio/common/src/utils/dataHelper";
import { ScrollContainer } from "@socion-cordio/common/src/components/organisms/scrollWrapper";
interface IProps {
  noFormik?: boolean;
  sessionDetails?: any;
  isSessionCompleted?: any;
}

export default function ViewContentPage(props: IProps): ReactElement {
  const { t } = useTranslation();
  const { sessionDetails, isSessionCompleted } = props;
  const [contentList, setContentList] = useState([]);
  const location: any = useLocation();

  useEffect(() => {
    if (sessionDetails !== undefined) {
      const { content } = sessionDetails?.topicInfo;
      setContentList(content);
    }
  }, []);

  const getFileName = (url: string) => {
    let name = url.split("/");
    return name[name.length - 1];
  };

  const validateSubContentUrl = (url: string) => {
    const urlCheck = url.includes("https://")
      ? Linking.openURL(url)
      : Linking.openURL(`https://${url}`);
  };

  return (
    <View style={styles.container}>
      <CollapseItem
        headerStyles={[styles.collapseHeaderContainer]}
        isExpanded={isSessionCompleted || location?.state?.completed == true ? false : true}
        headerComponent={t("session:viewContent")}
        children={
          <View style={styles.collapseContainer}>
            <View style={styles.contentListViewContainer}>
              <ScrollContainer height="300px" scrollable={contentList?.length > 3 ? true : false}>
                <div>
                  {contentList !== undefined &&
                    contentList.length !== 0 &&
                    contentList.map((prop, key: React.Key) => {
                      return (
                        <View key={key} style={styles.contentContainer}>
                          <View style={styles.miniContainer}>
                            <View style={styles.miniIconContainer}>
                              <Icon
                                testID="download"
                                name={
                                  mediaHelper.getIcons(prop?.contentType) === "image"
                                    ? IconNames.imageFile
                                    : mediaHelper.getIcons(prop?.contentType) === "video"
                                    ? IconNames.videoIcon
                                    : IconNames.documentFile
                                }
                                customStyle={[styles.alignContainerButton]}
                              />
                            </View>
                            <CollapseItem
                              headerStyles={[styles.subCollapseHeaderContainer]}
                              accordionStyles={styles.accordionStyles}
                              accordianIconStyle={styles.accordianIconStyle}
                              bodyStyles={styles.bodyStyles}
                              showAccordianIcon={prop.subContentList.length > 0 ? true : false}
                              isExpanded={false}
                              headerComponent={
                                <View style={styles.linkContainer}>
                                  <Text
                                    fontWeight={FontWeight.Medium}
                                    testId="headerText"
                                    textSize={TextSize.Small}
                                    textStyle={[
                                      styles.headerText,
                                      styles.miniContainerHeaderText,
                                      styles.headerAlign
                                    ]}
                                  >
                                    <TouchableOpacity
                                      onPress={() => {
                                        prop.vimeo_url === null
                                          ? Linking.openURL(prop.url)
                                          : Linking.openURL(prop.vimeo_url);
                                      }}
                                    >
                                      {prop.name}
                                    </TouchableOpacity>
                                  </Text>
                                </View>
                              }
                              children={
                                <View style={styles.subContentContainer}>
                                  {!dataHelper.isEmptyArray(prop?.subContentList) &&
                                    prop.subContentList.map((subContent: any) => (
                                      <Text
                                        fontWeight={FontWeight.Medium}
                                        testId="headerText"
                                        textSize={TextSize.Small}
                                        textStyle={[
                                          styles.headerText,
                                          // styles.miniContainerHeaderText,
                                          styles.headerAlign,
                                          styles.subContentTitleHeader
                                        ]}
                                      >
                                        <TouchableOpacity
                                          onPress={() => {
                                            validateSubContentUrl(subContent.url);
                                          }}
                                        >
                                          {subContent.title}
                                        </TouchableOpacity>
                                      </Text>
                                    ))}
                                </View>
                              }
                            />
                          </View>
                          <TouchableOpacity
                            onPress={() => {
                              mediaHelper.mediaDownloadHandler(
                                prop?.contentType,
                                getFileName(prop?.url)
                              );
                            }}
                          >
                            <View style={styles.miniIconContainer}>
                              <Icon
                                testID="download"
                                name={IconNames.downloadList}
                                customStyle={[styles.alignContainerButton]}
                              />
                            </View>
                          </TouchableOpacity>
                        </View>
                      );
                    })}
                </div>
              </ScrollContainer>

              {contentList.length === 0 && (
                <View>
                  <Text
                    fontWeight={FontWeight.Light}
                    testId="text"
                    textSize={TextSize.Small}
                    textStyle={[styles.headerText, styles.headerTextTitleSupport]}
                  >
                    {t("session:noContentAvailable")}
                  </Text>
                </View>
              )}
            </View>
          </View>
        }
      />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    shadowColor: colorPallete.cordioRedDark1,
    shadowOpacity: 0.1,
    shadowOffset: {
      height: 10,
      width: 5
    },
    shadowRadius: 10
  },
  collapseHeaderContainer: {},
  collapseContainer: {
    padding: 16
  },
  contentListViewContainer: {},
  contentContainer: {
    minHeight: 40,
    borderRadius: 5,
    marginBottom: 15,
    flexDirection: "row",
    justifyContent: "space-between",
    // alignItems: "center",
    paddingRight: 10,
    paddingLeft: 10,
    borderColor: colorPallete.cordioTaupe,
    borderStyle: "solid",
    borderWidth: 1,
    display: "flex",
    flexWrap: "wrap",
    marginRight: 5
  },
  headerText: {
    fontSize: 14,
    fontFamily: FontFamily.Medium,
    lineHeight: 17
  },
  headerTextTitleSupport: {
    fontWeight: "700"
  },
  headerAlign: {
    marginLeft: 5
  },
  miniContainer: {
    flexDirection: "row",
    flex: 70,
    // alignItems: "center",
    marginVertical: 3
  },
  miniContainerHeaderText: {
    color: colorPallete.black,
    fontSize: 12,
    lineHeight: 15
  },
  miniIconContainer: {
    marginTop: 8
  },
  alignContainerButton: {
    fontSize: 18,
    color: colorPallete.cordioTaupe,
    marginRight: 5,
    marginLeft: 5
  },
  subContentContainer: {
    marginTop: 5
  },
  linkContainer: {
    width: 225
  },
  subContentTitleHeader: {
    fontFamily: FontFamily.Regular,
    marginBottom: 5,
    textDecorationLine: "underline",
    fontSize: 12
  },

  subCollapseHeaderContainer: {
    padding: 0
  },
  accordionStyles: {
    shadowColor: "#ffffff0",
    backgroundColor: "#ffffff0",
    shadowOffset: {
      width: 0,
      height: 0
    },
    shadowOpacity: 0,
    shadowRadius: 0,
    borderRadius: 0,
    justifyContent: "center",
    width: "85%"
  },
  accordianIconStyle: {
    color: colorPallete.black,
    paddingHorizontal: 5,
    fontSize: 6,
    marginTop: -5
  },
  bodyStyles: {
    backgroundColor: colorPallete.white,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0
  }
});
