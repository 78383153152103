import { call, put, takeLatest } from "redux-saga/effects";
import { SIGNUP } from "@socion-cordio/common/src/modules/signup/actions/actionTypes";
import { SignupActions } from "@socion-cordio/common/src/modules/signup/actions/actions";
import { SignupRepository } from "@socion-cordio/common/src/repositories/signup/signup";
import { Signup } from "@socion-cordio/common/src/models/signup";
import { IFluxStandardAction } from "store/interfaces";

function* getSignupWorkerSaga(
  value: IFluxStandardAction<{ countryCode: number; phoneNumber: number; name: string }>
) {
  try {
    const { payload } = value;
    const response: Signup = yield call(SignupRepository.signupUser, payload);
    yield put(SignupActions.signupUserSucess(response));
  } catch (error) {
    yield put(SignupActions.signupUserError(error));
  }
}

export default function* signupWatcherSaga() {
  yield takeLatest(SIGNUP.SIGNUP_USER.LOADING, getSignupWorkerSaga);
}
