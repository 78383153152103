import { deserialize, serialize } from "@socion-cordio/common/src/mappers/apiResMapper";
// import { Topic } from "@socion-cordio/common/src/models/topic";
import { ApiClient } from "@socion-cordio/common/src/network/apiClient";
import { programEndPoints } from "@socion-cordio/common/src/repositories/endPoints";
import { UserRolesProgram } from "@socion-cordio/common/src/models/userRolesProgram";

class UserRolesProgramRepository {
  getUserRolesProgram = async (programId: number): Promise<UserRolesProgram> => {
    let data = await ApiClient.get(programEndPoints.getUserRolesProgram(), { programId });
    // const topicsData: Topic = deserialize(Topic, data);
    return data;
  };
}
const userRolesProgramRepository = new UserRolesProgramRepository();
export { userRolesProgramRepository as UserRolesProgramRepository };
