import Button, { ButtonType } from "@socion-cordio/common/src/components/atoms/button";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { StyleSheet, View } from "react-native";
import {
  FontFamily,
  FontWeight,
  Text,
  TextSize
} from "@socion-cordio/common/src/components/atoms/text";
import { colorPallete } from "@socion-cordio/common/src/assets/styles/colors";
import TextArea from "@socion-cordio/common/src/components/atoms/textArea";
import TextField from "@socion-cordio/common/src/components/atoms/textField";
import _, { replace, split } from "lodash";
import CancelSessionModal from "@socion-cordio/common/src/components/organisms/common-modals/cancelSessionModal";
import { allRoutesNames as routeNames } from "@socion-cordio/web/src/navigation/allRouteNames";
import { useHistory } from "react-router-dom";
import { ArtefactRepository } from "@socion-cordio/common/src/repositories/artefact/artefact";
import { displayname, getUserId } from "utils/artefactUserHelper";
import { Switch } from "@socion-cordio/common/src/components/atoms/switches";
import { artefactEndPoints } from "@socion-cordio/common/src/repositories/endPoints";
import { HTTP_STATUS_CODES } from "@socion-cordio/common/src/network/constants";
import { toast } from "react-toastify";
import { ArtefactApiClient } from "@socion-cordio/common/src/network/artefactApiClient";
import ArtefactDocs from "./artefactDocs";
import AddArtefactDocs from "./addArtefactDocs";
import RemoveLinkModal from "@socion-cordio/common/src/components/organisms/common-modals/removeLinkModal";
import Loader from "@socion-cordio/common/src/components/atoms/loader";
import SocionModal from "@socion-cordio/common/src/components/atoms/modal";
import ConfirmModal from "@socion-cordio/common/src/components/organisms/confirmModal";
import { artefact_messages } from "../data/message";
import { startUploadDoc } from "../helpers/docHelper";

const AddArtefactWrapper = styled.div`
  border-radius: 5px;
  margin: 30px;
  min-height: 75vh;
  position: relative;
  .detailsWrapper {
    padding: 20px;
    border-radius: 5px;
    border: 1px solid rgba(63, 162, 2, 0.6);
    form {
      margin: 0 0;
    }
    .formGroup {
      display: grid;
      grid-template-columns: 1fr 3fr;
      grid-gap: 40px;
      padding: 6px 0;
      label {
        font-family: "Montserrat-Thin";
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
      }
      input,
      textArea {
        font-family: "Montserrat-Thin";
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        border-radius: 5px;
        border: 1px solid #675850;
        height: 40px;
        color: #231f20;
        text-indent: 10px;
        &:hover,
        &:focus,
        &:focus-visible {
          border: 1px solid #231f20;
          outline: none;
        }
      }
      textArea {
        text-indent: 0px;
        padding-left: 10px;
        height: auto;
      }
      ::placeholder {
        font-weight: 400;
      }
    }
    .toggleButton {
      display: flex;
      justify-content: flex-end;
      padding-top: 22px;
      .switch {
        position: relative;
        display: inline-block;
        width: 30px;
        height: 16px;
      }
      .switch input {
        opacity: 0;
        width: 0;
        height: 0;
      }
      .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #ccc;
        -webkit-transition: 0.4s;
        transition: 0.4s;
      }
      .slider:before {
        position: absolute;
        content: "";
        height: 14px;
        width: 14px;
        left: 2px;
        bottom: 1px;
        background-color: white;
        -webkit-transition: 0.4s;
        transition: 0.4s;
      }
      input:checked + .slider {
        background-color: #b7504a;
      }
      input:focus + .slider {
        box-shadow: 0 0 1px #b7504a;
      }
      input:checked + .slider:before {
        -webkit-transform: translateX(13px);
        -ms-transform: translateX(13px);
        transform: translateX(13px);
      }
      /* Rounded sliders */
      .slider.round {
        border-radius: 34px;
      }
      .slider.round:before {
        border-radius: 50%;
      }
    }
  }
  .buttonWrapper {
    display: flex;
    align-items: center;
    position: absolute;
    right: 20px;
    bottom: 20px;
    button {
      margin-left: 20px;
    }
  }
  @media (min-width: 767px) and (max-width: 900px) {
    .detailsWrapper {
      grid-template-columns: 1fr;
      grid-gap: 30px;
    }
  }
  @media (max-width: 767px) {
    .detailsWrapper {
      grid-template-columns: 1fr;
      grid-gap: 30px;
      .formGroup {
        grid-template-columns: 1fr;
        grid-gap: 8px;
        padding-bottom: 12px;
      }
      .tagBtnWrapper {
        display: block;
        .tagBtn {
          margin-right: 0px;
          margin-bottom: 12px;
          width: fit-content;
        }
      }
    }
  }
`;
const EditArtefactType = () => {
  console.log("editArtefactType");

  const programData: any = JSON.parse(localStorage.getItem("programDetails"));
  const user = JSON.parse(localStorage.getItem("user"));

  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [template, setTemplate] = useState([]);
  const [isActive, setIsActive] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [updatedFields, setUpdatedFields] = useState(null);
  const [isFormUpdated, setIsFormUpdated] = useState(false);
  const [cancelAddArtefactTypeSession, setCancelAddArtefactTypeSession] = useState(false);
  const history: any = useHistory();
  const [artefactTypeId, setArtefactTypeId] = useState(location.pathname.split("/").pop());
  const [canDelete, setCanDelete] = useState(false);
  const [id, setId] = useState(null);
  const [deleteArtefactType, setDeleteArtefactType] = useState(false);
  const [isArtefactTypeEnabled, setIsArtefactTypeEnabled] = useState(false);
  const [uploadedDocs, setUploadedDocs] = useState([]);
  const DELETE_ARTEFACT_TYPE_MESSAGE = "Are you sure you want to delete this artefact type?";
  const [isLoading, setIsLoading] = useState(null);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [selectedDoc, setSelectedDoc] = useState(null);
  const [confirmMsg, setConfirmMsg] = useState(null);
  const [artefactTypeData, setArtefactTypeData] = useState(null);
  const [disabled, setDisabled] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    fetchArtefactTypeDetails();
  }, [artefactTypeId]);

  const fetchArtefactTypeDetails = async () => {
    const response = await ArtefactRepository.getArtefactTypeDetails(
      programData?.program.id,
      artefactTypeId
    )
      .then((res) => {
        const result = res.data.type;
        console.log("result : ", result);
        setArtefactTypeData(res?.data?.type);
        setTitle(result.artefact_type_title);
        setDescription(result.artefact_desc);
        setTemplate(result.templates);
        setId(result.id);
        setCanDelete(result.can_delete);
        if (result.active == "1") setIsActive(true);
        else setIsActive(false);
      })
      .catch(function (error) {
        toast.error(artefact_messages.error.m016);
      });
    setIsLoading(false);
    console.log("response : ", response);
  };

  const cancel = (value: any) => {
    if (value === true) {
      setCancelAddArtefactTypeSession(false);
      history.push(`${routeNames.app}${routeNames.ARTEFACT_TYPES}`);
      // history.push(`${routeNames.app}${routeNames.workspace}`);
    }
  };

  const cancelAddArtefactTypeHandler = () => {
    setCancelAddArtefactTypeSession(true);
  };

  const onSaveHandler = async () => {
    setDisabled(true);
    const payload = {
      active: isActive ? 1 : 0,
      desc: description,
      title: title,
      updated_at: new Date(),
      updated_by: displayname(),
      userId: getUserId()
    };
    await editArtefactType(payload);
  };

  const editArtefactType = async (payload: any, type = "") => {
    const docres = await uploadDocs(uploadedDocs, artefactTypeData);
    if (docres?.length > 0) {
      console.log("docresdocres", docres);
      payload.templates = docres;
    }

    let response = await ArtefactApiClient.put(
      artefactEndPoints.editArtefactTypeDetails(programData?.program.id, artefactTypeId),
      payload
    );
    console.log("response  onSaveHandler: ", response);
    if (response.code === HTTP_STATUS_CODES.created) {
      console.log("response in edit : save : ", response);
      const msg =
        type === "toggle"
          ? payload?.active == 0
            ? replace(artefact_messages.success.m08, "{name}", `"${title}"`)
            : replace(artefact_messages.success.m05, "{name}", `"${title}"`)
          : replace(artefact_messages.success.m06, "{name}", `"${title}"`);
      toast.success(msg);
      setDisabled(false);
      history.push(`${routeNames.app}${routeNames.ARTEFACT_TYPES}`);
    } else if (response.responseCode == HTTP_STATUS_CODES.conflict) {
      toast.error(response.message);
      setDisabled(false);
      //setDeleteArtefactType(false)
    } else if (response.responseCode == HTTP_STATUS_CODES.unauthorized) {
      toast.error(artefact_messages.error.m015);
      setDisabled(false);
      history.push(`${routeNames.IAM.root}${routeNames.IAM.login}`);
    } else {
      setDisabled(false);
      toast.error(artefact_messages.error.m012);
      //setDeleteArtefactType(false)
    }
  };

  const onDeleteDocHandler = async (templateId: string) => {
    let response = await ArtefactApiClient.delete(
      artefactEndPoints.deleteArtefactTypeDocs(programData?.program.id, templateId)
    );
    console.log("response  onSaveHandler: ", response);
    if (response.code === HTTP_STATUS_CODES.ok) {
      console.log("response in edit : save : ", response);
      toast.success(artefact_messages.success.m014);
      //history.push(`${routeNames.app}${routeNames.ARTEFACT_TYPES}`);
    } else if (response.responseCode == HTTP_STATUS_CODES.conflict) {
      toast.error(response.message);
    } else {
      toast.error(artefact_messages.error.m012);
    }
  };

  const uploadDocs = async (docs: any, request: any) => {
    try {
      if (docs?.length > 0) {
        const uploadRes = await startUploadDoc(
          uploadedDocs,
          {
            id: request?.id,
            programId: request?.pid,
            type: "artefact-type",
            docsCount: template.length
          },
          request,
          artefactTypeData,
          user
        );
        return updateTemplate(uploadRes);
      }
    } catch (err) {
      toast.error("Error while uploading docs.Please try again later...");
    }
  };

  const deleteDoc = (doc: any) => {
    console.log("gonna display modal");
    setShowConfirmModal(true);
    setSelectedDoc(doc);
    setConfirmMsg(artefact_messages.info.m06);
  };

  const deleteArtefactDoc = async (doc: any) => {
    setShowConfirmModal(false);

    console.log("template ---- ", template);
    console.log("doc --- ", doc);
    let tempArray = [...template];
    const template_id = tempArray[doc?.index].template_id;
    console.log("tempArray ---- ", tempArray[doc?.index].template_id);
    tempArray.splice(doc?.index, 1);
    setTemplate(tempArray);
    setIsFormUpdated(true);
    onDeleteDocHandler(template_id);
  };

  const updateDocs = (docs: any) => {
    setUploadedDocs(docs);
    // console.log(" setUpdatedFields : ", updatedFields);
    // setUploadedDocsToDb(uploadDocsList);
    // setUpdatedFields({
    //   ...updatedFields,
    //   templates: uploadDocs
    // });
    // console.log(" setUploadedDocs : ", uploadDocs);
    setIsFormUpdated(true);
  };

  const updateTemplate = (docs: any) => {
    console.log("bndsbfNDSFD edit docs-----", docs);

    const uploadDocsList = _.map(docs, (file) => {
      const fileNameAndType = split(_.get(file, "Key"), `${id}/`);
      let fileName = fileNameAndType[1];
      const lastIndex = fileName.lastIndexOf("_");
      const fileNameSplit = fileName.substring(0, lastIndex);
      const extension = split(fileName.substring(lastIndex, fileName.length), ".");
      return {
        template_url: _.get(file, "Key"),
        template_name: fileNameSplit,
        display_name: fileNameSplit,
        mimetype: extension[1]
      };
    });
    console.log(" docs : ", docs);
    console.log(" uploaded docs : ", uploadDocsList);
    return uploadDocsList;
  };

  const getDisabled = () => {
    if (!title || !isFormUpdated) {
      return true;
    }
    return false;
  };

  const handleToggle = () => {
    // if (isActive) {
    //setIsActive((isActive) => !isActive)
    const payload = {
      active: isActive ? 0 : 1,
      desc: description,
      title: title,
      updated_at: new Date(),
      updated_by: displayname(),
      userId: getUserId()
    };
    editArtefactType(payload, "toggle");
    // }
  };

  const deleteArtefactTypeHandler = async () => {
    let response = await ArtefactApiClient.delete(
      artefactEndPoints.deleteArtefactType(programData?.program.id, id)
    );
    console.log("response  onSaveHandler: ", response);
    if (response.code === HTTP_STATUS_CODES.ok) {
      console.log("response in edit : save : ", response);
      toast.success(replace(artefact_messages.success.m07, "{name}", `"${title}"`));
      history.push(`${routeNames.app}${routeNames.ARTEFACT_TYPES}`);
    } else if (response.responseCode == HTTP_STATUS_CODES.conflict) {
      toast.error(response.message);
    } else {
      toast.error(artefact_messages.error.m012);
    }
  };

  const showDeleteModal = () => {
    return (
      <SocionModal
        modalVisible={showConfirmModal}
        setModalVisible={() => setShowConfirmModal(!showConfirmModal)}
        component={
          <ConfirmModal
            onClose={() => setShowConfirmModal(false)}
            onConfirm={async () => {
              // deleteType === "doc"
              //   ? await deleteArtefactDoc(selectedDoc)
              //   : await deleteArtefactSubmitter(selectedSubmitter);
              await deleteArtefactDoc(selectedDoc);
            }}
            msg={confirmMsg}
          ></ConfirmModal>
        }
      ></SocionModal>
    );
  };

  const downloadDoc = async (doc: any) => {
    fetch(doc?.template_url).then((response) => {
      response
        .blob()
        .then((blob) => {
          let url = window.URL.createObjectURL(blob);
          let a = document.createElement("a");
          a.href = url;
          a.download = doc?.template_name;
          a.click();
        })
        .catch((error) => {
          console.log("Error while fetching doc url", error);
        });
      //window.location.href = response.url;
    });
  };

  const showError = (field: any, error: any) => {
    return (
      <Text
        testId={`${field}-error`}
        fontWeight={FontWeight.Regular}
        textSize={TextSize.Small}
        style={styles.errorMsg}
      >
        {error}
      </Text>
    );
  };

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <View style={styles.mainContainer}>
          <View style={styles.subContainer}>
            <AddArtefactWrapper>
              <div className="detailsWrapper">
                <div>
                  <div
                    style={{
                      fontSize: 14,
                      fontFamily: FontFamily.Bold,
                      color: "#231F20",
                      paddingBottom: "20px"
                    }}
                  >
                    Edit Artefact Type
                  </div>
                  <div className="formGroup">
                    <View style={styles.labelTextContainer}>
                      <Text
                        fontWeight={FontWeight.Regular}
                        testId="artefactType"
                        textSize={TextSize.Small}
                        textStyle={styles.subheaderText}
                      >
                        Artefact Type
                      </Text>
                    </View>
                    <View style={styles.inputTextContainer}>
                      <TextField
                        label=""
                        handleChange={(artefactType: string) => {
                          setTitle(artefactType);
                          setIsFormUpdated(true);
                        }}
                        handleBlur={() => {}}
                        value={title}
                        inputStyle={[styles.inputStylings, styles.inputStylingAlignment]}
                        placeholder="Enter Artefact Type Name"
                        name="artefactType"
                        id="artefactType"
                        editable={isActive}
                        noFormik
                      />
                      {!title && showError("title", "Please add artetype title")}
                    </View>
                  </div>

                  <div className="formGroup">
                    <View style={styles.labelTextContainer}>
                      <Text
                        fontWeight={FontWeight.Regular}
                        testId="artefactTypeName"
                        textSize={TextSize.Small}
                        textStyle={styles.subheaderText}
                      >
                        Description
                      </Text>
                    </View>
                    <TextArea
                      inputStyle={[styles.inputStyling]}
                      numberOfLines={4}
                      multiline={true}
                      label=""
                      handleChange={(description: string) => {
                        setDescription(description);
                        setIsFormUpdated(true);
                      }}
                      handleBlur={() => {}}
                      placeholder="Description"
                      name="description"
                      id="description"
                      value={description}
                      disabled={!isActive}
                      noFormik
                    />
                  </div>
                  {id && (
                    <div className="toggleButton">
                      <div
                        style={{
                          fontSize: 14,
                          fontFamily: FontFamily.Regular,
                          fontWeight: FontWeight.Regular,
                          color: colorPallete.textLight,
                          display: "flex",
                          alignItems: "center"
                        }}
                      >
                        <div style={{ paddingRight: "12px" }}>
                          {isActive ? "Active" : "Inactive"}
                        </div>
                        <label className="switch">
                          <Switch
                            isOn={isActive}
                            onColor="#B7504A"
                            offColor="#F1DCDB"
                            labelStyle={{ color: "black", fontWeight: "900" }}
                            size="medium"
                            onToggle={() => setIsArtefactTypeEnabled(true)}
                          />
                        </label>
                      </div>
                    </div>
                  )}

                  <div className="buttonWrapper">
                    <Button
                      type={ButtonType.Secondary}
                      buttonStyles={styles.button}
                      textStyles={styles.buttonFont}
                      title="Cancel"
                      onPress={() => cancelAddArtefactTypeHandler()}
                    />
                    {id && canDelete && (
                      <Button
                        type={ButtonType.Secondary}
                        buttonStyles={styles.button}
                        textStyles={styles.buttonFont}
                        title="Delete"
                        onPress={() => setDeleteArtefactType(true)}
                        disabled={isUploading}
                      />
                    )}
                    <Button
                      type={ButtonType.Primary}
                      buttonStyles={styles.button}
                      textStyles={styles.buttonFont}
                      title="Save"
                      disabled={disabled || getDisabled()}
                      onPress={() => onSaveHandler()}
                    />
                  </div>
                </div>
              </div>
              <div
                style={{
                  paddingBottom: "20px",
                  paddingRight: "20px",
                  width: "50%"
                }}
              >
                {isActive && template && (
                  <div
                    style={{
                      fontSize: 14,
                      fontFamily: FontFamily.Bold,
                      color: colorPallete.textBlack,
                      paddingTop: "20px"
                    }}
                  >
                    Add Template
                  </div>
                )}
                {isActive && (
                  <AddArtefactDocs
                    acceptedFormat={[".pdf", ".ppt", ".xls", ".txt", ".doc"]}
                    uploadedDocs={(docs: any) => updateDocs(docs)}
                    programId={programData?.program?.id}
                    type={"artefact-type"}
                    isUploading={(uploading: boolean) => {
                      setIsUploading(uploading);
                    }}
                  ></AddArtefactDocs>
                )}
                <ArtefactDocs
                  id={id}
                  docs={template}
                  isView={isActive ? false : true}
                  type="artefactType"
                  deleteDoc={(value: any) => deleteDoc(value)}
                  downloadDoc={(doc: any) => {
                    downloadDoc(doc);
                  }}
                ></ArtefactDocs>
              </div>
            </AddArtefactWrapper>
          </View>

          {cancelAddArtefactTypeSession && (
            <View>
              <CancelSessionModal
                modalVisible={cancelAddArtefactTypeSession}
                sendData={cancel}
                setModalVisible={() =>
                  setCancelAddArtefactTypeSession(!cancelAddArtefactTypeSession)
                }
              />
            </View>
          )}
          {showConfirmModal && showDeleteModal()}
          {deleteArtefactType && (
            <RemoveLinkModal
              modalVisible={deleteArtefactType}
              selectedData={deleteArtefactTypeHandler}
              textValue={DELETE_ARTEFACT_TYPE_MESSAGE}
              setModalVisible={() => setDeleteArtefactType(!deleteArtefactType)}
            />
          )}
          {isArtefactTypeEnabled && (
            <RemoveLinkModal
              modalVisible={isArtefactTypeEnabled}
              selectedData={handleToggle}
              textValue={
                isActive ? `${artefact_messages.info.m08}` : `${artefact_messages.info.m09}`
              }
              setModalVisible={() => setIsArtefactTypeEnabled(!isArtefactTypeEnabled)}
            />
          )}
        </View>
      )}
    </>
  );
};

const styles = StyleSheet.create({
  mainContainer: {
    // top: 55,
    paddingBottom: 20,
    paddingHorizontal: 20,
    // paddingTop: 15,
    backgroundColor: colorPallete.cordioBeigeLight2,
    minHeight: "calc(100vh - 50px)"
  },
  subContainer: {
    backgroundColor: colorPallete.white,
    borderRadius: 10,
    shadowColor: colorPallete.cordioRedDark1,
    shadowOpacity: 0.1,
    shadowOffset: {
      height: 10,
      width: 5
    },
    shadowRadius: 10
  },
  button: {
    width: "140px",
    height: "50px",
    borderRadius: 10,
    marginHorizontal: 10
  },
  buttonFont: {
    fontSize: 14
  },
  labelTextContainer: {
    flex: 18
  },
  subheaderText: {
    fontWeight: "400",
    fontSize: 12,
    fontFamily: FontFamily.Regular,
    lineHeight: 17,
    color: colorPallete.textLight,
    marginTop: 13
  },
  inputStyling: {
    outline: "none",
    borderRadius: 5,
    padding: 7,
    fontSize: 12,
    fontFamily: FontFamily.Medium,
    fontWeight: "400"
  },
  inputTextContainer: {
    marginRight: 5,
    flex: 35
  },
  inputStylings: {
    height: 25,
    borderRadius: 5,
    padding: 7,
    fontSize: 12,
    fontWeight: FontWeight.Light,
    fontFamily: FontFamily.Medium,
    width: "100%"
  },
  inputStylingAlignment: {},
  templateAndBtnContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center"
  },
  docsContainer: {
    width: "50%"
  },
  errorMsg: {
    fontSize: 12,
    color: "#FF0D10",
    padding: 5
  }
});

export default EditArtefactType;
