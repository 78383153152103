import moment from "moment";

const Ranges = [
  {
    label: "yesterday",
    value: [moment().subtract(1, "days"), moment().subtract(1, "days")]
  },
  {
    label: "last7Days",
    value: [moment().subtract(6, "days"), moment()]
  },
  {
    label: "Last 30 Days",
    value: [moment().subtract(29, "days"), moment()]
  },
  {
    label: "This Month",
    value: [moment().startOf("month"), moment().endOf("month")]
  },
  {
    label: "Last Month",
    value: [
      moment().subtract(1, "months").startOf("month"),
      moment().subtract(1, "months").endOf("month")
    ]
  }
];

export default Ranges;
