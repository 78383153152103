import React, { ReactElement } from "react";
import { View, StyleSheet } from "react-native";
import QRCode from "qrcode.react";

interface IProps {
  testId?: string;
  size?: number;
  value?: string;
  id?: any;
}

export const GenerateQR = (props: IProps): ReactElement => {
  const { testId, size = 100, value, id } = props;

  return (
    <View style={styles.container} testID={`qr${testId}`}>
      <QRCode id={id} value={value} size={size} level={"H"} includeMargin={true} />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center"
  }
});
